import moment from 'moment';
import { immutableClass } from '../bases.js';
import { CashFlowItem } from './cashflowitem.js';
import { dateConverter } from './dateconverter.js';
import { organisationStore } from '../organisation.js';

export const ChangeLogItem =
			@immutableClass({
				date: { type: "date" },
				project: { type: "object" },
				projectId: { type: "number" },
				phase: { type: "object", defaultValue: null },
				phaseId: { type: "number", defaultValue: null },
				invoice: { type: "object", defaultValue: null },
				expense: { type: "object", defaultValue: null },
				revenue: { type: "number" },
				expenses: { type: "number" },
				progress: { type: "number" },
				billingType: { type: "string", defaultValue: "agreedFee" },
				_title: { type: "string" }
			})
			class {
				get isEditable() {
					return this.invoice == null && this.expense == null;
				}

				static fromJSON(json) {
					return new this({
						date:
							json.date != null
								? moment(json.date, "YYYY-MM-DD")
								: null,

						// We would have liked to use organisationStore.get*ById() here but it turns out
						// we can't rely on those objects existing yet. So instead we reconcile the object
						// references in `organisationStore._addObjects`.
						projectId: json.project,
						phaseId: json.phase,
						invoiceId: json.invoice,

						//TODO-project_changelog this is particularly suspect
						expense: json.expense,
						revenue: json.revenue,
						expenses: json.expenses,
						progress: json.progress,
						_title: json.title,
						billingType: json.billingType || "agreedFee"
					});
				}

				serialize() {
					return {
						uuid: this.uuid, // Not used by server; used for diagnostics.
						date:
							this.date != null
								? this.date.format("YYYY-MM-DD")
								: null,
						project: this.project != null ? this.project.id || this.project.uuid : null,
						phase: this.phase != null ? this.phase.id || this.phase.uuid : null,
						invoice: this.invoice != null ? this.invoice.id || this.invoice.uuid : null,
						revenue: this.revenue,
						expenses: this.expenses,
						progress: this.progress,
						title: this._title,
						billingType: this.billingType || "agreedFee"
					};
				}

				get title() {
					if (this._title) return this._title;
					if (this.invoice != null) {
						if (this.phase != null) {
							return this.phase.getTitle();
						} else {
							return `${this.invoice.description} extra line items`;
						}
					} else if (this.expense != null) {
						return this.expense.name;
					} else {
						return this.phase != null ? "Change Log" : "Manual";
					}
				}

				set title(title) {
					this._title = title;
				}

				toCashFlowItem() {
					// The more this gets simplified the more it looks like `CashFlowItem` and
					// `ChangeLogItem` could maybe be merged.
					return new CashFlowItem({
						invoice: this.invoice,
						endDate:
							this.date != null
								? dateConverter.momentToInt(this.date)
								: null,
						title: this.title,
						project:
							this.project ||
							(this.phase != null ? this.phase.project : null),
						phase:
							this.phase ||
							organisationStore.getProjectPhaseById(
								this.phaseId
							) ||
							organisationStore.getProjectPhaseByUuid(this.phaseId),
						fee: this.revenue,
						isEditable:
							this.invoice != null && this.expense != null,
						percent: this.progress,
						spend: this.expenses,
						expense: this.expense,
						billingType: this.billingType || "agreedFee",
						isChangeLogItem: !this.invoice && !this.expense,
						changeLogItem: this,
					});
				}
			};
