import { organisationStore } from '../organisation.js';
import { invoiceErrorCodes } from './InvoicePageStore.js';


export function mixinAccountingSettingsMethods({store, actions, onSave}) {
  store.saveAccountingSystemSettings = function(accountingSystemId, settings, generalSettings, then) {
    let accounting = require('./AccountingSystemStore.js');
    accounting.saveSettings(accountingSystemId, settings, generalSettings).then(function({settings, generalSettings}) {
      actions.saveAccountingSystemSettingsSuccess(accountingSystemId, settings, generalSettings, then);
    }, function() {
      actions.error(invoiceErrorCodes.saveAccountingSystemSettings);
    });
  }.bind(store);

  store.saveAccountingSystemSettingsSuccess = function(accountingSystemId, settings, generalSettings, then) {
    let ac = organisationStore.getAccountingSystemByIdentifier(accountingSystemId);
    ac.data = settings.data;
    ac.settings = settings.settings;

    onSave(accountingSystemId, settings, generalSettings, then);

    organisationStore.organisation.accountingSystem = accountingSystemId;
    organisationStore.organisation.settings.invoice = generalSettings;
    organisationStore.emitChanged();
    this.emitChanged();
  }.bind(store);
}



