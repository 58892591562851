import moment from "moment";
import _ from "underscore";
import { myHttp } from "../myHttp.js";
import Immutable from "immutable";
import apiRequest from "../apiRequest.js";
import { organisationStore } from "../organisation.js";

export const TimesheetDataCache = class {
	constructor(onUpdate) {
		this._cache = {};
		this._promises = {};
		this.onUpdate = onUpdate;
	}

	getDateRange(dateRange) {
		return this._cache[this.getKey(dateRange)];
	}

	populateDateRange(dateRange) {
		let self = this;
		const key = this.getKey(dateRange);
		const val = this._cache[key];
		if (val == null) {
			this._cache[key] = "loading";
			const [start, end] = dateRange.getDates(moment());
			this._promises[key] = new Promise(function (resolve, reject) {
				apiRequest({
					url: `/api/v1/timesheet/current/totals`,
					method: "get",
					params: {
						startDate:
							start != null ? start.format("YYYY-MM-DD") : null,
						endDate: end != null ? end.format("YYYY-MM-DD") : null,
					},
				}).then(
					(data) => {
						self.populateDateRangeSuccess(dateRange, data);
						resolve();
					},
					(data) => reject()
				);
			});
			return this._promises[key];
		} else if (
			this._cache[key] === "loading" &&
			this._promises[key] != null
		) {
			return this._promises[key];
		} else {
			return new Promise(function (resolve) {
				resolve();
			});
		}
	}

	cancelRequests() {
		const self = this;
		_.mapObject(self._cache, function (val, key) {
			if (val === "loading") {
				self._promises[key].abort();
				self._cache[key] = null;
			}
		});
	}

	populateDateRangeSuccess(dateRange, data) {
		let self = this;
		const projectMap = Immutable.Map(
			data.projectHours.map(function (row) {
				const { projectId, ...data } = row;
				return [projectId, data];
			})
		);
		const projectPhaseMap = Immutable.Map(
			data.projectPhaseHours.map(function (row) {
				const { projectId, projectPhaseId, ...data } = row;
				if (projectPhaseId === -1) {
					const p = organisationStore.getProjectById(projectId);
					if (p)
						p.noPhase.staffMinutesLoggedToDate =
							data.numHoursToDate * 60;
				}
				return [
					self.getProjectPhaseMapKey(projectId, projectPhaseId),
					data,
				];
			})
		);
		const staffMemberMap = Immutable.Map(
			data.staffHours.map(function (row) {
				const { staffMemberId, ...data } = row;
				return [staffMemberId, data];
			})
		);
		const staffMemberBillableMap = Immutable.Map(
			data.staffHoursBillable.map(function (row) {
				const { staffMemberId, ...data } = row;
				return [staffMemberId, data];
			})
		);
		this._cache[this.getKey(dateRange)] = {
			projectMap: projectMap,
			projectPhaseMap: projectPhaseMap,
			staffMemberMap: staffMemberMap,
			staffMemberBillableMap: staffMemberBillableMap,
			invoicedTime: data.hoursInvoiced,
		};
		if (this.onUpdate != null) {
			this.onUpdate();
		}
	}

	getKey(dateRange) {
		function format(d) {
			return d != null ? d.format("YYYY-MM-DD") : "null";
		}

		const [start, end] = dateRange.getDates(moment());
		return `${format(start)}--${format(end)}`;
	}

	getProjectPhaseMapKey(projectId, projectPhaseId) {
		return projectId + "-" + (projectPhaseId || -1);
	}

	getInvoicedHoursInDateRange(
		dateRange,
		{ staffId, projectId, phaseId } = {}
	) {
		const invoicedTime = this.getDateRange(dateRange);
		return invoicedTime.filter(
			(it) =>
				(staffId ? it.staffMemberId === staffId : true) &&
				(projectId ? it.projectId === projectId : true) &&
				(phaseId ? it.projectPhaseId === phaseId : true)
		);
	}
};
