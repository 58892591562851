import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from '../organisation.js';
import { actions } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { StaffMemberCheckboxList } from './StaffMemberCheckboxList.js';
import { SaveButton } from '../widgets.js';
import PropTypes from "prop-types";


export var EditItemStaffMembersForm = CreateReactClass({
  propTypes: {
    modal: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        staffMembers: organisationStore.staffMembers,
      };
    })
  ],

  getInitialState: function() {
    return {
      selectedStaffMembers: this.props.item.allocation.getStaffMembers()
    };
  },

  render: function() {
    let self = this;

    return <div className="scheduler__staff-member-allocation-form">
      <div>
        <StaffMemberCheckboxList
          staffMembers={this.state.staffMembers}
          selectedStaffMembers={this.state.selectedStaffMembers}
          onChange={function(selectedStaffMembers) {
            self.setState({selectedStaffMembers: selectedStaffMembers});
          }}
        />
      </div>
      <div className="modal-footer">
        <button
            className="btn btn-default"
            onClick={this.handleCancelClick}>
          Cancel
        </button>
        <SaveButton
          text="Save staff members"
          onClick={this.handleSubmit}
        />
      </div>
    </div>;
  },

  handleSubmit: function() {
    actions.submitEditItemStaffMembers(
      this.props.modal,
      this.state.selectedStaffMembers,
      this.props.item
    );
  },

  handleCancelClick: function() {
    actions.closeModal(this.props.modal);
  }
});



