import React from 'react';
import CreateReactClass from 'create-react-class';
import { CoincraftPage } from './CoincraftPage.js';
import { router } from './router.js';

import PropTypes from "prop-types";

const messages = {
  project: {
    header: "Project deleted",
    message: "This project has been deleted",
    backButtonText: "Back to projects",
    backToPath: "/dashboard/projects"
  },
  staffMember: {
    header: "Staff member deleted",
    message: "This staff member has been deleted",
    backButtonText: "Back to staff members",
    backToPath: "/dashboard/staff"
  },
  costCentre: {
    header: "Cost centre deleted",
    message: "This cost centre has been deleted",
    backButtonText: "Back to cost centres",
    backToPath: "/dashboard/cost-centres"
  },
  invoice: {
    header: "Invoice deleted",
    message: "This invoice has been deleted",
    backButtonText: "Back to invoices",
    backToPath: "/dashboard/invoices"
  }
};


export var ItemDeletedPage = CreateReactClass({
  propTypes: {
    objectType: PropTypes.oneOf(['project', 'staffMember', 'costCentre', 'invoice']).isRequired
  },

  render: function() {
    const {header, message, backButtonText, backToPath} = messages[this.props.objectType];

    return <CoincraftPage
      heading={header}
      body={<div>
        <p>
          {message}
        </p>
        <button
            className="btn btn-primary btn-lg back-to-dashboard-button"
            onClick={() => router.history.replace({pathname: backToPath})}>
          {backButtonText}
        </button>
      </div>}
    />;
  }
});

