import React from 'react';
import CreateReactClass from 'create-react-class';
import { AccountingSystem } from './base.js';
import PropTypes from "prop-types";

export const NoAccountingSystem = class extends AccountingSystem {
  constructor(options) {
    super(options);
    this.identifier = "none";
    this.name = "None";
    this.shortName = "None";
    if (this.settings == null) {
      this.settings = this.getDefaultSettings();
    }
  }

  needsData() {
    return false;
  }

  getDefaultSettings() {
    return {
      defaultTaxRate: 10
    };
  }

  getDefaultTaxRate() {
    return this.settings.defaultTaxRate;
  }

  canSaveSettings(settings) {
    return settings.defaultTaxRate != null;
  }

  getSettingsComponent() {
    return NoAccountingSystemSettings;
  }

  getInvoiceUrl(invoice) {
    return null;
  }
}


var NoAccountingSystemSettings = CreateReactClass({
  propTypes: {
    settings: PropTypes.object.isRequired
  },

  render: function() {
    return <div className="flexbox-container flex-align-items-center">
      <div style={{fontWeight: 'bold', width: '30%'}}>
        Default tax rate:
      </div>
      <div style={{width: '70%'}}>
        <input
          type="number"
          value={this.props.settings.defaultTaxRate}
          onChange={this.handleDefaultTaxRateChange}
          style={{width: '4em', marginRight: '0.5em'}}
        />%
      </div>
    </div>;
  },

  handleDefaultTaxRateChange: function(event) {
    this.props.onChange({
      defaultTaxRate: parseInt(event.target.value)
    });
  }
});




