import { getContextReferenceObject } from "../ReferenceLookup";

export default (inputs, context) => {
	if (!context) {
		throw {
			value: "No context provided",
			type: "error",
			dependencies: [],
		};
	}
	const contextObj = getContextReferenceObject(context);
	return {
		type: contextObj.type,
		value: contextObj,
		primitive: true
	};
};