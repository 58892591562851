import React from "react";
const AccountingRejectedPage = (props) => {
	window.credentials = { verified: false };
	return (
		<div style={{ marginTop: "10%" }}>
			<section
				className="widget login-widget"
				style={{ padding: "30px", margin: "0 auto", width: "410px" }}
			>
				<header>
					<div
						className="logo2"
						style={{
							textAlign: "center",
							width: "100%",
							marginBottom: "10px",
						}}
					>
						<img
							src={process.env.PUBLIC_URL + "/coincraft_logo.png"}
							style={{ width: "auto", height: "60px" }}
						/>
					</div>

					<div
						className="alert alert-danger"
						style={{ marginTop: "12px", padding: "6px" }}
					>
						<h3 style={{ margin: 0 }}>
							<i
								class="fa fa-times"
								style={{ marginRight: "6px" }}
							></i>
							Connection to {props.params.accountingSystemId} was
							not successful.
						</h3>
					</div>
				</header>

				<div style={{ width: "400px", margin: "0 auto" }}>
					<p>
						Connecting to {props.params.accountingSystemId} wasn't
						successful. You can return to Coincraft and try again.
						If the problem persists, please contact us.
					</p>

					<p style={{ marginTop: "20px" }}>
						<button
							className="btn btn-danger"
							onClick={() => window.close()}
						>
							Close
						</button>
					</p>
				</div>
			</section>
		</div>
	);
};

export default AccountingRejectedPage;
