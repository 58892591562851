import { ProjectPhase } from './projectphase.js';


export const NoPhasePhaseId = -1;

export const NoPhasePhase = class extends ProjectPhase {
			constructor(options) {
				super(options);
				if (this.id == null) {
					this.id = NoPhasePhaseId;
				}
				if (this.name == null || this.name === "") {
					this.name = "(No phase)";
				}
			}

			getTitle() {
				return "No Phase";
			}

			get startMonthIndex() {
				return this.project.startMonthIndex;
			}

			get endMonthIndex() {
				return this.project.endMonthIndex;
			}

			get isRealPhase() {
				// As opposed to NoPhasePhase.
				return false;
			}
		};
