import nearley from 'nearley'
import grammar from './grammar/grammar'
import { FormulaNode } from './FormulaNode';

export const parseFormula = (string, context) => {
  if (!string) return;
  const parser = new nearley.Parser(
		nearley.Grammar.fromCompiled(grammar(context))
  );
  try {
      parser.feed(string)
      return parser.results[0]
    } catch (err) {
      //error breaks things for some reason
      console.log("Error at character " + err?.offset, err); // "Error at character 9"
      return {type: "error"}
    }
}
