import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { timesheetActions } from "./flux.js";
import PropTypes from "prop-types";


export var EditEntryNoteModal = CreateReactClass({
  propTypes: {
    storeUuid: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    timesheetEntryPath: PropTypes.object.isRequired
  },

  componentWillMount: function() {
    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      if (this.textInput) this.textInput.focus();
    };
  },

  getInitialState: function() {
    return {
      notes: this.props.entry.notes || ''
    };
  },

  componentDidMount: function() {
    this.focusTextInput();
  },

  render: function() {
    return <Modal>
      <ModalContent header="Entry Notes" width='45em'>
      <div style={{padding: '1em'}}>
          <div>
            Note:
        </div>
          <textarea
            value={this.state.notes}
            onChange={this.handleNoteChange}
            style={{ width: '100%', height: '10em' }}
            ref={this.setTextInputRef}
          />
          <div
            style={{
              borderTop: 'solid 1px #ccc',
              paddingTop: '1em',
              marginTop: '1.5em',
              textAlign: 'right'
            }}>
            <button
              className="btn btn-default submit-button"
              style={{ marginRight: '1em' }}
              onClick={this.handleCancelClick}>
              Cancel
          </button>
            <button
              className="btn btn-success submit-button"
              onClick={this.handleSubmitClick}>
              Save note
          </button>
          </div>
      </div>
      </ModalContent>
    </Modal>;
  },

  handleNoteChange: function(event) {
    this.setState({notes: event.target.value});
  },

  handleSubmitClick: function() {
    timesheetActions.saveEntryNote(
      this.props.modal,
      this.props.entry,
      this.props.timesheetEntryPath,
      this.state.notes
    );
  },

  handleCancelClick: function() {
    timesheetActions.closeModal(this.props.modal);
  }
});
