import { Methods } from "../Methods";

export default ({ object, method, args }, context) => {
	if (object.type !== "array") {
		if (!Methods[object.type]?.[method]) {
			throw {
				value: `${object.type} does not have method ${method}`,
				type: "error",
			};
		}
		return (
			Methods[object.type]?.[method]?.(object, args, context)
		);
	} else {
		if (!Methods[object.value[0].type]?.[method]) {
			throw {
				value: `${object.type} does not have method ${method}`,
				type: "error",
			};
		}
		const methArray = object.value.map(
			ob =>
				Methods[ob.type]?.[method]?.(ob, args, context)
		);
		return ({
			type: "array",
			value: methArray,
		});
	}
};
