import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from 'moment';
import { dateConverter } from '../../models.js';
import { makeMultipleStoreMixin } from '../../coincraftFlux.js';
import { store, actions, QUANTITY, TYPE, cellQuantity } from '../flux.js';
import { StaffMemberSelector, BasicMySelect2 } from '../../widgets.js';
import { permissions, requiresPermission } from '../../models/permissions.js';
import { organisationStore } from '../../organisation.js';
import { ProgressWidget } from './ProgressWidget.js';
import { userStore } from '../../user/flux.js';
import { ContextMenuProgressBars, ContextMenuMonthInput } from './ContextSections.js';
import { CashFlowItemTable } from './CashFlowItemTable.js';
import PropTypes from "prop-types";



// Proof of concept: use `getStateFromProps` (which uses `stateFromProps`) to
// cache values computed from props as state.
const stateFromProps = [
  {
    prop: 'monthIndex',
    state: 'momentMonth',
    getValue: function(monthIndex) {
      const startOfMonth = dateConverter.monthIndexToOffset(monthIndex);
      return dateConverter.intToMoment(startOfMonth);
    }
  }
];


function getStateFromProps(props, previousProps) {
  let stateDict = {};
  for (let {prop, state, getValue} of stateFromProps) {
    if (previousProps == null || props[prop] !== previousProps[prop]) {
      stateDict[state] = getValue(props[prop], props);
    }
  }
  return stateDict;
}


export var ContextMenu = requiresPermission(
  permissions.noRestrictions,
  {
    isRevenueViewable: permissions.financialVisibilityRevenue,
    isExpensesViewable: permissions.financialVisibilityExpenses,
    isRevenueEditable: ({project}) => permissions.canEditProjectRevenue(project),
    isHoursEditable: ({project}) => permissions.canEditProjectHours(project)
  },
  CreateReactClass({
    propTypes: {
      row: PropTypes.object,
      rowIndex: PropTypes.number,
      cellData: PropTypes.any,
      cellKey: PropTypes.number,
      cellType: PropTypes.oneOf([TYPE.REVENUE, TYPE.HOURS]).isRequired,

      project: PropTypes.object,
      phase: PropTypes.object,
      staffMember: PropTypes.object,
      monthIndex: PropTypes.number,
      selectedCellTense: PropTypes.string,

      user: PropTypes.object.isRequired,
      holidaysArray: PropTypes.array.isRequired,
    },

    mixins: [
      makeMultipleStoreMixin([store, organisationStore], function() {
        return {
          timesheetData: store.timesheetData,
          currencyFormatter: organisationStore.organisation.currencyFormatter
        };
      })
    ],

    getInitialState: function() {
      return {
        selectedTab: this.props.cellType === TYPE.REVENUE ? 'phaseRevenue' : 'staffPhaseHours',
        selectedStaffMember: this.props.row.get('staffMember') || null,
        ...getStateFromProps(this.props)
      };
    },

    componentWillReceiveProps: function(nextProps) {
      const newState = getStateFromProps(nextProps, this.props);
      if (!_.isEmpty(newState)) {
        this.setState(newState);
      }
    },

    quantity: function({quantityType, timePeriod, staffMember, project, phase}) {
      return cellQuantity({
        quantity: quantityType,
        project: project !== undefined ? project : this.props.project,
        phase: phase !== undefined ? phase : this.props.phase,
        staffMember: staffMember,
        timePeriod: timePeriod,
        currentMonthIndex: dateConverter.getMonthIndex(dateConverter.momentToInt(moment())),
        timesheetData: this.state.timesheetData,
        now: moment()
      });
    },

    render: function() {
      const phase = this.props.phase;
      const cellData = this.props.cellData;

      return <div
          className="context-menu"

          // Fix the width to the max-width of the sidebar so it does not change width
          // when you change the content
          style={{minWidth: '25em'}}>
        {phase != null ?
          <div>
            <div className="context-menu__section">
              <BasicMySelect2
                value={this.state.selectedTab}
                style={{display: 'block'}}
                options={[
                  ...(
                    this.props.isRevenueViewable ?
                      [{label: "Phase Revenue", value: 'phaseRevenue'}]
                    : []
                  ),
                  {label: "Phase Hours", value: 'phaseHours'},
                  {label: "Staff Hours", value: 'staffHours'},
                  {label: "Staff / Phase Hours", value: 'staffPhaseHours'},
                ]}
                onChange={(selectedTab) => this.setState({selectedTab: selectedTab})}
              />
              {this.state.selectedTab === 'staffHours' || this.state.selectedTab === 'staffPhaseHours' ?
                <StaffMemberSelector
                  value={this.state.selectedStaffMember}
                  style={{display: 'block', marginTop: '1em'}}
                  onChange={(staffMember) => this.setState({selectedStaffMember: staffMember})}
                  nullText="(Select Staff Member)"
                />
              : null}
            </div>
            {cellData != null ? this.renderCellContext() : null}
          </div>
        : null}
      </div>;
    },

    renderCellContext: function() {
      if (this.props.cellType === TYPE.HOURS) {
        if (!userStore.user.permissions.canEditProject(this.props.project)) {
          return <div>
            You do not have permission to edit this project's data.
          </div>;
        }
      }

      switch (this.state.selectedTab) {
        case 'phaseRevenue':
          return this.renderPhaseRevenueTab();
        case 'phaseHours':
          return this.renderPhaseHoursTab();
        case 'staffHours':
          return this.renderStaffHoursTab();
        case 'staffPhaseHours':
          return this.renderStaffPhaseHoursTab();
        default:
          throw new Error("Not implemented");
      }
    },

    renderPhaseRevenueTab: function() {
      const phase = this.props.phase;

      const monthIndex = this.props.monthIndex;
      const startOfMonth = dateConverter.monthIndexToOffset(monthIndex);
      const endOfMonth = dateConverter.endOfMonthOffset(startOfMonth);
      const targetRevenue = this.quantity({
        quantityType: QUANTITY.TARGET_REVENUE,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });
      const projectedRevenue = this.quantity({
        quantityType: QUANTITY.PROJECTED_REVENUE,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });
      const actualRevenue = this.quantity({
        quantityType: QUANTITY.ACTUAL_REVENUE,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });

      return <div>
        <div className="context-menu__section">
          <div className="context-menu__title-line--small">
            {this.state.momentMonth.format("MMM YYYY")}
          </div>
          <div className="context-menu__title-line--small">
            {phase.project.getTitle()}
          </div>
          <div className="context-menu__title-line--large">
            {phase.getTitle()}
          </div>
        </div>

        {phase.isRealPhase ?
          <div>
            <ContextMenuMonthInput
              unitName="Revenue"
              selectedCellTense={this.props.selectedCellTense}
              momentMonth={this.state.momentMonth}
              targetValue={targetRevenue}
              projectedValue={projectedRevenue}
              actualValue={actualRevenue}
              targetAction={actions.setContextMenuPhaseRevenue}
              targetActionArgs={[phase, monthIndex]}
              isEditable={this.props.isRevenueEditable}
              valueFormatter={val => this.state.currencyFormatter.formatAbbreviated(val)}
              actualValueDetails={<CashFlowItemTable
                cashFlowItems={phase.getCashFlowItems(
                  dateConverter.intToMoment(startOfMonth),
                  dateConverter.intToMoment(endOfMonth)
                )}
                currencyFormatter={this.state.currencyFormatter}
              />}
            />
            {this.renderPhaseRevenueSummary()}
          </div>
        : null}
      </div>;
    },

    renderPhaseHoursTab: function() {
      const monthIndex = this.props.monthIndex;
      const phase = this.props.phase;
      const staffTable = store.getStaffMemberLookup(this.props.phase, this.props.monthIndex);
      const momentMonth = this.state.momentMonth;

      const cellHoursData = store.getCellData({
        quantity: QUANTITY.PROJECTED_HOURS,
        phase: this.props.phase,
        monthIndex: this.props.monthIndex
      });
      const actualHours = this.quantity({
        quantityType: QUANTITY.ACTUAL_HOURS,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });
      const projectedHours = this.quantity({
        quantityType: QUANTITY.PROJECTED_HOURS,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });
      const targetHours = this.quantity({
        quantityType: QUANTITY.TARGET_HOURS,
        timePeriod: {startMonth: monthIndex, endMonth: monthIndex}
      });

      return <div>
        <div className="context-menu__section">
          <div className="context-menu__title-line--small">
            {momentMonth.format("MMM YYYY")}
          </div>
          <div className="context-menu__title-line--small">
            {phase.project.getTitle()}
          </div>
          <div className="context-menu__title-line--large">
            {phase.getTitle()}
          </div>
        </div>

        {phase.isRealPhase ?
          <ContextMenuMonthInput
            unitName="Hours"
            selectedCellTense={this.props.selectedCellTense}
            momentMonth={momentMonth}
            targetValue={targetHours}
            projectedValue={projectedHours}
            actualValue={actualHours}
            targetAction={actions.setTotalAllocationValue}
            targetActionArgs={[this.props.rowIndex, this.props.cellKey]}
            isEditable={this.props.isHoursEditable}
          />
        : null}

        <div className="context-menu__section">
          {phase.isRealPhase ?
            <ProgressWidget
              title={`Projected hourly budget use to ${this.state.momentMonth.format("MMM YYYY")}`}
              fromValue={cellHoursData.previousMonth}
              toValue={cellHoursData.toDate}
              targetValue={this.props.phase.manualHoursBudget}
            />
          : null}
          {phase.isRealPhase ?
            <ProgressWidget
              title={`Projected hourly budget use at completion`}
              toValue={cellHoursData.total}
              targetValue={this.props.phase.manualHoursBudget}
              style={{marginTop: '1.5em'}}
            />
          : null}
        </div>
        {!_.isEmpty(staffTable) ?
          <div className="context-menu__section">
            <h5>
              Staff hours to {momentMonth.format("MMM YYYY")}
            </h5>
            <div style={{marginTop: '1em'}}>
              {_.map(staffTable, function(data, staffId) {
                return <ProgressWidget
                  key={staffId}
                  title={`${data.staffMember.getFullName()} hourly budget use to ${momentMonth.format("MMM YYYY")}`}
                  fromValue={data.previousMonth}
                  toValue={data.toDate}
                  targetValue={data.target}
                  description={`${Math.round(data.toDate-data.previousMonth)} Hours`}
                  style={{margin: '1.5em 0'}}
                />;
              })}
            </div>
          </div>
        : null}
      </div>;
    },

    renderStaffHoursTab: function() {
      const staffMember = this.state.selectedStaffMember;
      const monthIndex = this.props.monthIndex;
      const momentMonth = this.state.momentMonth;

      let phaseTable, numHoursAvailableInMonth, staffProjectedHoursForMonth;

      if (staffMember != null) {
        phaseTable = store.getPhaseLookup(staffMember, monthIndex);
        staffProjectedHoursForMonth = this.quantity({
          project: null,
          phase: null,
          quantityType: QUANTITY.TARGET_HOURS,
          timePeriod: {startMonth: this.props.monthIndex, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        numHoursAvailableInMonth = getStaffMemberHoursAvailableInMonth(staffMember, this.props.monthIndex, this.props.holidaysArray);
      }

      return <div>
        {staffMember != null ?
          <div>
            <div className="context-menu__section">
              <div className="context-menu__title-line--small">
                {momentMonth.format("MMM YYYY")}
              </div>
              <div className="context-menu__title-line--large">
                {staffMember.getFullName()}
              </div>
            </div>
            <div className="context-menu__section">
              <ProgressWidget
                title={`Total Allocation for ${momentMonth.format("MMM YYYY")}`}
                toValue={staffProjectedHoursForMonth}
                targetValue={numHoursAvailableInMonth}
              />
            </div>
            {!_.isEmpty(phaseTable) ?
              <div className="context-menu__section">
                {_.map(phaseTable, function(data, phaseId) {
                  return <ProgressWidget
                    key={phaseId}
                    title={
                      <div>
                        <div>{data.phase.project.getTitle()}</div>
                        <div>{data.phase.getTitle()}</div>
                        <div>{`Hourly budget use to ${momentMonth.format("MMM YYYY")}`}</div>
                      </div>
                    }
                    fromValue={data.previousMonth}
                    toValue={data.toDate}
                    targetValue={data.target}
                    description={`${Math.round(data.toDate-data.previousMonth)} Hours`}
                    style={{margin: '1.5em 0'}}
                  />;
                })}
              </div>
            :null}
          </div>
        : null}
      </div>;
    },

    renderStaffPhaseHoursTab: function() {
      const phase = this.props.phase;
      const staffMember = this.state.selectedStaffMember;

      let staffProjectedHoursForPhaseForMonth, staffActualHoursForPhase, numHoursAvailableInMonth,
          staffProjectedHoursForMonth, staffTargetHoursForPhase, phaseStaffHoursToDate;

      if (staffMember != null) {
        staffProjectedHoursForPhaseForMonth = this.quantity({
          quantityType: QUANTITY.PROJECTED_HOURS,
          timePeriod: {startMonth: this.props.monthIndex, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        staffTargetHoursForPhase = this.quantity({
          quantityType: QUANTITY.TARGET_HOURS,
          timePeriod: {startMonth: this.props.monthIndex, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        staffActualHoursForPhase = this.quantity({
          quantityType: QUANTITY.ACTUAL_HOURS,
          timePeriod: {startMonth: this.props.monthIndex, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        staffProjectedHoursForMonth = this.quantity({
          project: null,
          phase: null,
          quantityType: QUANTITY.PROJECTED_HOURS,
          timePeriod: {startMonth: this.props.monthIndex, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        phaseStaffHoursToDate = this.quantity({
          quantityType: QUANTITY.PROJECTED_HOURS,
          timePeriod: {startMonth: null, endMonth: this.props.monthIndex},
          staffMember: staffMember
        });
        numHoursAvailableInMonth = getStaffMemberHoursAvailableInMonth(staffMember, this.props.monthIndex, this.props.holidaysArray);
      }

      return <div>
        {staffMember != null ?
          <div>
            <div className="context-menu__section">
              <div className="context-menu__title-line--small">
                {this.state.momentMonth.format("MMM YYYY")}
              </div>
              <div className="context-menu__title-line--large">
                {staffMember.getFullName()}
              </div>
              <div className="context-menu__title-line--small">
                {phase.project.getTitle()}
              </div>
              <div className="context-menu__title-line--small">
                {phase.getTitle()}
              </div>
            </div>
            {phase.isRealPhase ?
              <div>
                <ContextMenuMonthInput
                  unitName="Hours"
                  selectedCellTense={this.props.selectedCellTense}
                  momentMonth={this.state.momentMonth}
                  targetValue={staffTargetHoursForPhase}
                  projectedValue={staffProjectedHoursForPhaseForMonth}
                  actualValue={staffActualHoursForPhase}
                  targetAction={actions.setContextMenuPhaseStaffHours}
                  targetActionArgs={[phase, staffMember.id, this.props.monthIndex]}
                  isEditable={phase.isRealPhase && this.props.isHoursEditable}
                />
                <div className="context-menu__section">
                  {/**
                    * Show the staff member's utilisation for the month as a proportion of
                    * their availability, and also show this phase's contribution to that utilisation.
                    */}
                  <ProgressWidget
                    title={`Total Utilisation for ${this.state.momentMonth.format("MMM YYYY")}`}
                    fromValue={staffProjectedHoursForMonth - staffProjectedHoursForPhaseForMonth}
                    toValue={staffProjectedHoursForMonth}
                    targetValue={numHoursAvailableInMonth}
                  />
                </div>
                <div className="context-menu__section">
                  <ProgressWidget
                    title={`Phase Hourly Budget Use to ${this.state.momentMonth.format("MMM YYYY")}`}
                    fromValue={phaseStaffHoursToDate - staffProjectedHoursForPhaseForMonth}
                    toValue={phaseStaffHoursToDate}
                    targetValue={phase.getBudgetedHoursForStaffMember(staffMember)}
                  />
                </div>
              </div>
            : null}
          </div>
        : null}
      </div>;
    },

    renderPhaseRevenueSummary: function() {
      const cellRevenueData = store.getCellData({
        quantity: QUANTITY.PROJECTED_REVENUE,
        phase: this.props.phase,
        monthIndex: this.props.monthIndex
      });
      return <ContextMenuProgressBars
        currentMonthTitle={`Projected revenue to ${this.state.momentMonth.format("MMM YYYY")}`}
        totalTitle="Projected revenue at completion"
        previousMonth={cellRevenueData.previousMonth}
        toDate={cellRevenueData.toDate}
        allTime={cellRevenueData.total}
        target={this.props.phase.fee}
        valueFormatter={val => this.state.currencyFormatter.formatAbbreviated(val)}
      />;
    },

  })
);


function getStaffMemberHoursAvailableInMonth(staffMember, monthIndex, holidaysArray) {
  return staffMember.getNumHoursAvailableInMonth(monthIndex, holidaysArray);
}
