import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { TriStateSaveButton } from '../widgets.js';
import PropTypes from "prop-types";


export var ConfirmChangeCostCentreModal = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
    saveState: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  },

  render: function() {
    const [oldBillability, newBillability] = (this.props.project.costCentre.isBillable) ?
      ['non-billable', 'billable']
    : ['billable', 'non-billable'];

    return <Modal>
      <ModalContent header="Saving project" width='45em' bodyStyles={{padding: 0}}>
        <div style={{padding: "2em"}}>
          <p>
            {"You have changed this project's cost centre from one that is "}
            <em>{oldBillability}</em> to one that is <em>{newBillability}</em>.
          </p>

          <p>
            {"Saving will set this project's "}<strong>{'tasks & timesheets'}</strong>
          {' to '}<strong>{newBillability}</strong>.
          </p>
        </div>
        <div style={{borderTop: '1px solid #ccc', textAlign: 'right', padding: '1em'}}>
          <a
              href="javascript:void(0)"
              onClick={this.props.onCancel}>
            Cancel
          </a>

          <TriStateSaveButton
            text="Save"
            className="btn btn-default"
            onClick={this.props.onConfirm}
            state={this.props.saveState}
            style={{marginLeft: '1em'}}
          />
        </div>
      </ModalContent>
    </Modal>;
  }
});
