import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';
import { organisationStore } from '../organisation.js';
import _ from 'underscore';


export const ProjectExpenseAllocation = class extends DataObject {
			/**
			 * ProjectExpense objects aren't real objects in the database,
			 * they are just dicts in the `Project.expenses` list.
			 */

			defaults() {
				return {
					uuid: generateUUID(),
					date: null,
					amount: 0,
					projectId: -1,
					phaseId: -1,
					expenseUuid: null
				};
			}

			static fieldTypes() {
				return {
					uuid: "string",
					date: "date",
					amount: "number",
					projectId: "number",
					phaseId: "number",
					expenseUuid: "string"
				};
			}

			static fieldsForSerialize() {
				return ["uuid", "date", "amount"];
			}

			get expense() {
				return organisationStore
					.getProjectById(this.projectId)
					.getExpenseFromUuid(this.expenseUuid);
			}

			get totalCost() {
				return this.expense.cost;
			}

			get name() {
				return this.expense.name;
			}
		};


