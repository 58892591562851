import { AccountingSystem } from './base.js';
import { MyobSettings } from './MyobSettings.js';


export const MyobEssentialsAccountingSystem = class extends AccountingSystem {
  constructor(options) {
    super(options);
    this.identifier = "myob-essentials";
    this.name = "MYOB Essentials";
    this.shortName = "MYOB";
    this.invoiceNumberMaxLength = 13; // Limit enforced by MYOB
  }

  getDefaultSettings() {
    return {
      salesAccount: null,
      taxOnTaxType: null,
      taxOffTaxType: null
    };
  }

  canSaveSettings(settings) {
    return (settings != null
      && settings.salesAccount != null
      && settings.taxOnTaxType != null
      && settings.taxOffTaxType != null
    );
  }

  requiresContact() {
    return true;
  }

  getSettingsComponent() {
    return MyobSettings;
  }
}

