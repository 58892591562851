import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Modal, ModalContent } from "../modal";
import { TriStateSaveButton } from "../widgets";
import tokenFetcher from "./tokenFetcher";

export default observer(({ closeModal, staffMemberId }) => {
	useEffect(() => {
		tokenFetcher.fetchToken(staffMemberId);
	}, [staffMemberId]);
	return (
		<Modal>
			<ModalContent
				header="Get Invite"
				width="48em"
				bodyStyles={{ padding: 0 }}
			>
				<div
					style={{ padding: "2em", borderTop: "1px solid #eee" }}
					className="flexbox-container flex-align-items-center"
				>
					{["idle", "pending"].includes(tokenFetcher.fetchState) ? (
						<div>Fetching activation link...</div>
					) : tokenFetcher.fetchState === "success" ? (
						<div>
							Please send the following link to your staff member
							to activate their account.{" "}
							<a
								href={tokenFetcher.activationUrl}
								style={{ color: "#1890ff" }}
							>
								{tokenFetcher.activationUrl}
							</a>
						</div>
					) : (
						<div>
							There was an error retreiving an activation link for
							your staff member. Please contact us to resolve the
							issue.
						</div>
					)}
				</div>
				<div
					style={{
						borderTop: "1px solid #ccc",
						textAlign: "right",
						padding: "1em",
					}}
				>
					<a href="javascript:void(0)" onClick={closeModal}>
						Close
					</a>

					{["success"].includes(tokenFetcher.fetchState) ? (
						<TriStateSaveButton
							text="Email Token"
							className="btn btn-default sync-modal__save-button"
							onClick={tokenFetcher.emailToken}
							state={tokenFetcher.emailState}
							style={{ marginLeft: "1em" }}
						/>
					) : null}
				</div>
			</ModalContent>
		</Modal>
	);
});
