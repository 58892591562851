import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { organisationStore } from "../organisation.js";
import { makeMultipleStoreMixin } from '../coincraftFlux.js';


export var CurrencySymbol = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        currencyFormatter: organisationStore.organisation.currencyFormatter
      };
    })
  ],

  render: function() {
    return <span>{this.state.currencyFormatter.symbol}</span>;
  }
});


export var CurrencyIcon = CreateReactClass({
  /**
   * As a FontAwesome element
   */
  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        currencyFormatter: organisationStore.organisation.currencyFormatter
      };
    })
  ],

  render: function() {
    const {className, ...props} = this.props;
    return <i
      className={classNames("fa", this.state.currencyFormatter.fontAwesomeClass, className)}
      {...props}
    />;
  }
});

