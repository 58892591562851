import React from 'react';
import CreateReactClass from 'create-react-class';
import { dateConverter } from '../models.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { DateValue } from '../widgets.js';
import { actions } from './flux.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


export var ProjectDatesPanel = requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: ({project}) => permissions.canEditProject(project),
  },
  CreateReactClass({
    propTypes: {
      project: PropTypes.object.isRequired
    },

    render: function() {
      return <DatesPanel
        item={this.props.project}
        actions={{
          setStartDate: actions.setProjectSidebarProjectStartDate,
          setEndDate: actions.setProjectSidebarProjectEndDate
        }}
        isEditable={this.props.isEditable}
        className="project-sidebar"
      />;
    }
  })
);


export var PhaseDatesPanel = requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: ({phase}) => permissions.canEditProject(phase.project),
  },
  CreateReactClass({
    propTypes: {
      phase: PropTypes.object.isRequired
    },

    render: function() {
      return <DatesPanel
        item={this.props.phase}
        actions={{
          setStartDate: actions.setPhaseSidebarPhaseStartDate,
          setEndDate: actions.setPhaseSidebarPhaseEndDate
        }}
        isEditable={this.props.isEditable}
        className="phase-sidebar"
      />;
    }
  })
);


var DatesPanel = CreateReactClass({
  propTypes: {
    item: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      setStartDate: PropTypes.func.isRequired,
      setEndDate: PropTypes.func.isRequired,
    }).isRequired,
    isEditable: PropTypes.bool.isRequired,

    // Not really the class name but there are Python tests that rely on the
    // classnames of the DateValue elements being constructed from this.
    className: PropTypes.string.isRequired
  },

  render: function() {
    const item = this.props.item;

    return <div className="dashboard-widget info-panel" style={{padding: 0}}>
      <h3 className="title">{item.name}</h3>
      <div>
        <table className="input-table" style={{width: '100%'}}>
          <tbody>
            <tr>
              <td style={{borderRight: 'solid 1px #ccc'}}>
                Start Date
              </td>
              <td className={classNames("date", {"cell-input-field": this.props.isEditable})}>
                <DateValue
                  className={this.props.className + "__start-date-input"}
                  value={item.getStartDate != null ? item.getStartDate() : null}
                  action={this.props.actions.setStartDate}
                  isEditable={this.props.isEditable}
                  dropLeft={true}
                />
              </td>
            </tr>
            <tr>
              <td style={{borderRight: 'solid 1px #ccc'}}>
                End Date
              </td>
              <td className={classNames("date", {"cell-input-field": this.props.isEditable})}>
                <DateValue
                  className={this.props.className + "__end-date-input"}
                  value={item.getEndDate != null ? item.getEndDate() : null}
                  action={this.props.actions.setEndDate}
                  isEditable={this.props.isEditable}
                  dropLeft={true}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>;
  }
});
