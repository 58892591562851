import React from "react";
const AccountingConnectedPage = (props) => {
    window.credentials = props.location.query;
    return (
		<div style={{ marginTop: "10%" }}>
			<section
				className="widget login-widget"
				style={{ padding: "30px", margin: "0 auto", width: "410px" }}
			>
				<header>
					<div
						className="logo2"
						style={{
							textAlign: "center",
							width: "100%",
							marginBottom: "10px"
						}}
					>
						<img
							src={
								process.env.PUBLIC_URL +
								"/coincraft_logo.png"
							}
							style={{ width: "auto", height: "60px" }}
						/>
					</div>

					<div
						className="alert alert-success"
						style={{ marginTop: "12px", padding: "6px" }}
					>
						<h3 style={{ margin: 0 }}>
							<i
								class="fa fa-check"
								style={{ marginRight: "6px" }}
							></i>
							Successfully connected to{" "}
							{props.params.accountingSystemId}
						</h3>
					</div>
				</header>

				<div style={{ width: "400px", margin: "0 auto" }}>
					<p>
						You have successfully connected Coincraft to your{" "}
						{props.params.accountingSystemId} account. You can now
						close this window and get back to your Coincraft
						dashboard.
					</p>

					<p style={{ marginTop: "20px" }}>
						<button
							className="btn btn-success"
							onClick={() => window.close()}
						>
							Close
						</button>
					</p>
				</div>
			</section>
		</div>
	);
}

export default AccountingConnectedPage;