import { organisationStore } from "../organisation.js";
import { DataObject } from "./dataobject.js";

export const Contact = class extends DataObject {
	static getClassName() {
		return "Contact";
	}

	apiTypeName() {
		return "contact";
	}

	defaults() {
		return {
			id: null,
			firstName: "",
			lastName: "",
			contactOrganisationName: "",
			address: "",
			email: "",
			phone: "",
			accountingSystemId: null,
			accountingSystemContactId: null,
			isCoincraftContact: true,
			isCustomer: false,
			notes: "",
		};
	}

	static fieldTypes() {
		return {
			id: "int",
			firstName: "string",
			lastName: "string",
			contactOrganisationName: "string",
			address: "string",
			email: "string",
			phone: "string",
			accountingSystemId: "string",
			accountingSystemContactId: "string",
			isCoincraftContact: "bool",
			isCustomer: "bool",
			notes: "string",
		};
	}

	serialize() {
		return {
			id: this.id,
			firstName: this.firstName,
			lastName: this.lastName,
			address: this.address,
			email: this.email,
			phone: this.phone,
			contactOrganisationName: this.contactOrganisationName,
			notes: this.notes,
			...(this.accountingSystemId != null
				? { accountingSystemId: this.accountingSystemId }
				: null),
			...(this.accountingSystemContactId != null
				? {
						accountingSystemContactId:
							this.accountingSystemContactId,
				  }
				: null),
		};
	}

	static fieldsForSerialize() {
		throw new Error("Don't use this");
	}

	display() {
		return (
			(this.firstName ? `${this.firstName}` : "") +
			(this.lastName ? ` ${this.lastName}` : "") +
			(this.contactOrganisationName
				? ` (${this.contactOrganisationName})`
				: "") +
			(this.accountingSystemId ? ` (${this.accountingSystemId})` : "")
		);
	}

	get isEditable() {
		return true; // return this.accountingSystemContactId == null;
	}

	get xeroUrl() {
		if (this.xeroContactId != null) {
			return `https://go.xero.com/Contacts/View/${this.xeroContactId}`;
		} else {
			return null;
		}
	}
	get projects() {
		return organisationStore.projects.filter(
			(p) =>
				p.contactId &&
				(p.contact?.id === this.id || p.contactId === this.id)
		);
	}
	get invoices() {
		return organisationStore.objects.Invoice.list.filter(
			(i) =>
				i.contactId &&
				(i.contact?.id === this.id || i.contactId === this.id)
		);
	}
	get canDelete() {
		return !Boolean(this.projects.length || this.invoices.length);
	}
};
