import _ from 'underscore';
import { organisationStore } from "../organisation.js";
import { StoreBase, dispatcher, registerActions, handleAction } from '../coincraftFlux.js';
import Immutable from 'immutable';
import { StaffRole } from '../models/staffRole.js';
import { router } from '../router.js';



const staffRoleListActionDefinitions = [
	{ action: "newStaffRole", args: [] },
	{ action: "openStaffRole", args: ["staffRole"] },
	{ action: "toggleShowArchived", args: [] },
];

export const actions = registerActions("staff-role-list", staffRoleListActionDefinitions, dispatcher);

class StaffRoleListStore {
  constructor() {
    this.path = "staff-role-list";
    this._staffRoles = organisationStore ? organisationStore.staffRoles : [];
    this.actionDefinitions = staffRoleListActionDefinitions;
    this.showArchived = false;
  }

  handle(action) {
    handleAction(action, this);
  }

  get staffRoles() {
    if (this.showArchived) {
      return this._staffRoles
    } else {
      return this._staffRoles.filter(sr => !sr.isArchived);
    }
  }

  newStaffRole() {
    router.history.push('/dashboard/staff-roles/new');
  }

  openStaffRole(staffRole) {
    router.history.push(`/dashboard/staff-roles/${staffRole.id}`);
  }

  toggleShowArchived() {
    this.showArchived = !this.showArchived;
  }
x
}

export var staffRoleListStore = new StaffRoleListStore();
