import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { mergeProps } from '../utils.js';
import PropTypes from "prop-types";


/**
 * The following is an attempt at making a reusable table component using flex.
 * It currently assumes you want all cell contents vertically centered.
 */

// probably better if table was styled externally
const border = null;

export var FlexTable = CreateReactClass({
  propTypes: {
    columns: PropTypes.array
  },

  render: function() {
    let {children: _children, columns, ...props} = this.props;
    return <div {...props}
      className={classNames("flex-table", this.props.className)}>
      {React.Children.map(this.props.children, function(child, i) {
        if (child == null) {
          return null;
        }
        const style = (i === 0) ? {borderTop: border, borderBottom: border} : {borderBottom: border};
        return React.cloneElement(child, {
          style: {...child.props.style, ...style},
          columns: columns
        });
      })}
    </div>;
  }
});


export var FlexRow = CreateReactClass({
  propTypes: {
    columns: PropTypes.array,
    cellProps: PropTypes.object
  },

  render: function() {
    let self = this;

    return <div
        className={classNames("flexbox-container", "flex-align-items-stretch", "flex-table-row", this.props.className)}
        style={this.props.style}>
      {React.Children.map(this.props.children, function(child, i) {
        if (child == null) {
          return null;
        }

        const style = {
          borderLeft: (i === 0) ? border : null,
          borderRight: border,
        };

        let propsCollections = [
          self.props.columns != null ? self.props.columns[i] : null,
          self.props.cellProps,
          child.props
        ];

        let cellProps = mergeProps(...propsCollections);

        // Wrap the cells in an extra layer so the border covers the whole height of the row. Sigh.
        let outerStyle, innerStyle, outerClassName, innerClassName;
        if (cellProps.style != null && cellProps.style.width != null) {
          outerClassName = cellProps.className;
          outerStyle = {...cellProps.style, ...style};
          innerStyle = {...cellProps.style, ...style, width: '100%'};
        }
        else {
          innerClassName = cellProps.className;
          outerStyle = style;
          innerStyle = cellProps.style;
        }

        return <div
            className={classNames("flex-0-0-auto", outerClassName, "flex-table-cell")}
            style={outerStyle}>
          {React.cloneElement(child, {style: innerStyle, className: innerClassName})}
        </div>;
      })}
    </div>;
  }
});


export var FlexCell = CreateReactClass({
  render: function() {
    return <div
        className={classNames("flex-0-0-auto", this.props.className)}
        style={{padding: '0.2em 0.4em', ...this.props.style}}>
      {this.props.children}
    </div>;
  }
});
