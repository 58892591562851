import { generateUUID } from '../utils.js';
import Immutable from 'immutable';


export const Task = class extends Immutable.Record({
			uuid: "",
			name: "",
			isDeleted: false,
			isBillable: true,
			isVariation: false,
			isDefault: false
		}) {
			constructor(options = {}) {
				super({
					...options,
					uuid: options.uuid || generateUUID()
				});
			}

			copy() {
				var task = new Task({
					name: this.name,
					isDeleted: this.isDeleted,
					isBillable: this.isBillable,
					isVariation: this.isVariation,
					isDefault: this.isDefault
				});
				return task;
			}
		};
