import _ from "underscore";
import moment from "moment";
import React from "react";
import CreateReactClass from "create-react-class";
import { Modal2 } from "../modal.js";
import {
	organisationStore,
	actions as organisationActions,
} from "../organisation.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { LeaveHook } from "../utils.js";
import { invoiceActions, invoicePageStore } from "./InvoicePageStore.js";
import { AccountingSystemSyncPopup } from "./accountingComponents.js";
import { ErrorModal } from "./ErrorModal.js";
import { TimesheetLineItemForm } from "./TimesheetLineItemForm.js";
import { EditInvoiceDatesPopup } from "./EditInvoiceDatesPopup.js";
import { ExpensesPopup } from "./ExpensesPopup.js";
import { permissions } from "../models/permissions.js";
import { userStore } from "../user/flux.js";
import { router } from "../router.js";
import {
	Dropdown3,
	Dropdown3ListItem,
	DateValue,
	ContactSelector,
	NumberInputContainer,
	TriStateSaveButton,
	ErrorAlert,
	DeleteButton,
	Checkbox,
	PageLoadingSpinner,
} from "../widgets.js";
import { ErrorPopover, formatNumber2 } from "../utils.js";
import classNames from "classnames";
import { MyobAccountRightAuthForm } from "./accounting/myobAccountRight.js";
import { InvoicePhase } from "./InvoicePhase.js";
import { CoincraftPage } from "../CoincraftPage.js";
import { CoincraftPageHeader } from "../CoincraftPage.js";
import { projectStore } from "../project/flux.js";
import { ConfirmSyncModal } from "../project/ConfirmSyncModal.js";
import { actions as projectActions } from "../project/flux.js";
import UpdateInvoiceDataModal from "./UpdateInvoiceDataModal.js";
import { EditLineItemTimeEntriesPopup } from "./EditLineItemTimeEntriesPopup.js";

export var InvoicePage = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin(
			[organisationStore, invoicePageStore, userStore],
			function () {
				return {
					isReady: invoicePageStore.isReady,
					invoice: invoicePageStore.invoice,
					syncState: invoicePageStore.syncState,
					timeEntryState: invoicePageStore.timeEntryState,
					modals: invoicePageStore.modals,
					accountingSystemId:
						organisationStore.organisation.accountingSystem,
					accountingSystem:
						organisationStore.getCurrentAccountingSystem(),
					timesheetEntries: invoicePageStore.timesheetEntries,
					accountingSystemSettings:
						organisationStore.organisation.accountingSystemSettings,
					isValidationFailed: invoicePageStore.isValidationFailed,
					invoiceErrors: invoicePageStore.invoiceErrors,
					saveInvoiceOperation: invoicePageStore.saveInvoiceOperation,
					currencyFormatter:
						organisationStore.organisation.currencyFormatter,
					user: userStore.user,
					lockTimesheetsOnSave: invoicePageStore.lockTimesheetsOnSave,
					markTimesheetsAsInvoicedOnSave:
						invoicePageStore.markTimesheetsAsInvoicedOnSave,
					unlockTimesheetsOnDelete:
						invoicePageStore.unlockTimesheetsOnDelete,
					unmarkTimesheetsAsInvoicedOnDelete:
						invoicePageStore.unmarkTimesheetsAsInvoicedOnDelete,
					getNonLineItemTimesheets:
						invoicePageStore.getNonLineItemTimesheets,
					getLineItemTimesheets:
						invoicePageStore.getLineItemTimesheets,
					getTimesheets: invoicePageStore.getTimesheets,
					autoUpdateTimeEntries:
						invoicePageStore.autoUpdateTimeEntries,
				};
			}
		),
		LeaveHook,
	],

	getInitialState: function () {
		return {
			expensesToCopy: [],
			showAll: false,
		};
	},

	componentWillMount: function () {
		const invoiceId = this.props.params.invoiceId || "new";
		const projectId = this.props.params.projectId;

		if (invoiceId === "new") {
			invoicePageStore.startNewInvoice(
				parseInt(projectId),
				moment(this.props.location.query.startDate, "YYYY-MM-DD"),
				moment(this.props.location.query.endDate, "YYYY-MM-DD")
			);
		} else {
			invoicePageStore.loadInvoice(
				organisationStore.getInvoiceById(parseInt(invoiceId))
			);
		}
		const project =
			invoicePageStore.invoice?.project ||
			(projectId &&
				organisationStore.getProjectById(parseInt(projectId)));
		if (project) {
			projectStore.loadProject(project);
			projectStore.modifiedFee = true;
			projectStore.modifiedBudget = true;
			projectStore.updateForecastSelection = "revenueResource";
		}
	},

	shouldComponentUpdate: function (nextProps, nextState) {
		const projectId = this.props.params.projectId;
		const startDate = this.props.location.query.startDate;
		const endDate = this.props.location.query.endDate;
		const nextInvoiceId = nextProps.params.invoiceId || "new";
		const nextProjectId = nextProps.params.projectId;
		const nextStartDate = nextProps.location.query.startDate;
		const nextEndDate = nextProps.location.query.endDate;
		if (
			nextInvoiceId === "new" &&
			(projectId !== nextProjectId ||
				startDate !== nextStartDate ||
				endDate !== nextEndDate)
		) {
			invoicePageStore.startNewInvoice(
				parseInt(nextProjectId),
				moment(nextStartDate, "YYYY-MM-DD"),
				moment(nextEndDate, "YYYY-MM-DD")
			);
		}
		return true;
	},

	routerWillLeave: function (nextLocation) {
		if (invoicePageStore.isDirty) {
			return (
				"We haven't saved your changes to this invoice yet. " +
				"If you leave the invoice page without saving, you'll lose your changes."
			);
		}
	},

	render: function () {
		let self = this;
		if (!invoicePageStore.isReady) {
			return (
				<CoincraftPage
					heading={"Loading Invoice..."}
					body={
						<PageLoadingSpinner text={"Loading invoice data..."} />
					}
				/>
			);
		}

		const invoiceExternalUrl = this.state.accountingSystem?.getInvoiceUrl?.(
			this.state.invoice
		);

		return (
			<CoincraftPage
				key={this.state.invoice.id}
				header={
					<CoincraftPageHeader
						heading={`Invoice: ${this.state.invoice.description}`}
						leftButtons={[
							<button
								key={0}
								className="btn btn-default invoice-page__back-to-invoice-list-button"
								onClick={
									this.handleBackToInvoicesListButtonClick
								}
							>
								<i className="fa fa-chevron-left" /> Back to
								Invoice List
							</button>,
						]}
						rightButtons={
							<div
								style={{ float: "right" }}
								className="flexbox-container flex-align-items-center"
							>
								<TriStateSaveButton
									text="Save invoice"
									state={
										this.state.saveInvoiceOperation.state
									}
									onClick={() => invoiceActions.initSave()}
									className="flex-0-0-auto invoice__save-button"
								/>

								<button
									className="btn btn-primary invoice-page__sync-button flex-0-0-auto"
									onClick={() =>
										invoiceActions.initiateSync()
									}
									style={{ margin: "0 1em" }}
								>
									<i className="fa fa-exchange" />
									Sync
								</button>
								<button
									className="btn btn-default invoice-page__pdf-button flex-0-0-auto"
									onClick={() => invoiceActions.loadPdfView()}
									style={{ margin: "0 1em 0 0" }}
								>
									<i className="fa fa-print" />
									PDF (Beta)
								</button>
								<Dropdown3
									className="flex-0-0-auto invoice__settings-button"
									toggleElement={
										<button className="btn btn-default">
											<i
												style={{ margin: 0 }}
												className="fa fa-cog"
											/>
										</button>
									}
									dropLeft={true}
									contentStyle={{
										width: "16em",
										marginTop: "0.5em",
										color: "#444",
									}}
									containerStyle={{ float: "right" }}
								>
									<Dropdown3ListItem
										className="invoice__update-button"
										style={{
											padding: "0.5em 1em",
											height: "2.5em",
										}}
										onClick={() =>
											invoiceActions.updateInvoiceData()
										}
									>
										<i className="fa fa-refresh fa-fw" />
										Update Invoice Data
									</Dropdown3ListItem>
									{permissions.canEditAccountingSystemSettings.ok(
										this.state.user
									) ? (
										<Dropdown3ListItem
											className="invoice__accounting-system-settings-button"
											style={{
												padding: "0.5em 1em",
												height: "2.5em",
											}}
											onClick={() =>
												organisationActions.openAccountingSystemSettingsPopup()
											}
										>
											<div>
												<i className="fa fa-cog fa-fw" />
												Accounting Settings
											</div>
										</Dropdown3ListItem>
									) : null}
									<Dropdown3ListItem
										className="invoice__delete-button"
										style={{
											padding: "0.5em 1em",
											height: "2.5em",
										}}
										onClick={() =>
											invoiceActions.initiateDeleteInvoice()
										}
									>
										<i className="fa fa-times fa-fw" />
										Delete Invoice
									</Dropdown3ListItem>
								</Dropdown3>
							</div>
						}
						extraContent={
							this.state.isValidationFailed ? (
								<div style={{ marginTop: "1em" }}>
									<ErrorAlert>
										<p>
											<strong>
												Couldn't save your invoice
											</strong>
										</p>
										<p>
											Sorry, we couldn't save your invoice
											because of the following problems.
											Please fix them and try again.
										</p>

										<ul>
											{this.state.invoiceErrors
												.description ? (
												<li>
													The invoice needs an invoice
													number (up the top).
												</li>
											) : null}
											{this.state.invoiceErrors
												.duplicate ? (
												<li>
													Invoice number is already in
													use.
												</li>
											) : null}
											{this.state.invoiceErrors
												.nonzero ? (
												<li>
													{
														"The invoice total can't be negative or zero."
													}
												</li>
											) : null}
											{this.state.invoiceErrors
												.contactMissing ? (
												<li>
													To sync, your invoice needs
													a contact.
												</li>
											) : null}
											{this.state.invoiceErrors
												.wrongContactAccountingSystem ? (
												<li>
													This invoice needs to be
													addressed to a{" "}
													{
														this.state
															.accountingSystem
															.name
													}{" "}
													contact to sync to{" "}
													{
														this.state
															.accountingSystem
															.name
													}
													.
												</li>
											) : null}
											{this.state.invoiceErrors
												.invoiceNumberMaxLength !=
											null ? (
												<li>
													To sync to{" "}
													{
														this.state
															.accountingSystem
															.name
													}
													, your invoice number must
													be at most{" "}
													{
														this.state.invoiceErrors
															.invoiceNumberMaxLength
													}{" "}
													characters in length.
												</li>
											) : null}
										</ul>
									</ErrorAlert>
								</div>
							) : null
						}
					/>
				}
				body={
					<div>
						{this.state.modals.map(function (modal, i) {
							let closeOnClickOutside,
								width,
								header,
								content,
								modal2;

							switch (modal.type) {
								case "copyInvoice":
									header = "Sync Invoice";
									closeOnClickOutside = false;
									width = "50em";
									content = (
										<AccountingSystemSyncPopup
											accountingSystemId={
												self.state.accountingSystemId
											}
											accountingSystem={
												self.state.accountingSystem
											}
											invoice={self.state.invoice}
											syncState={self.state.syncState}
										/>
									);
									break;
								case "timesheetLineItem":
									header = "Add timesheet line item";
									closeOnClickOutside = false;
									width = "45em";
									content = (
										<TimesheetLineItemForm
											invoice={self.state.invoice}
											timesheetState={
												self.state.timeEntryState
											}
											getTimesheetEntries={() =>
												self.state.getNonLineItemTimesheets() ||
												[]
											} //TODO-better-invoices
											phaseId={modal.phaseId}
											onSubmit={function ({
												combinedType,
												staffMemberIds,
												task,
												isBillable,
												isVariation,
											}) {
												invoiceActions.submitAddTimesheetLineItem(
													modal.phaseId,
													combinedType,
													staffMemberIds,
													task,
													isBillable,
													isVariation
												);
											}}
										/>
									);
									break;
								case "editLineItemTimeEntries":
									header =
										"Billing for the following time entries.";
									closeOnClickOutside = true;
									width = "45em";
									content = (
										<EditLineItemTimeEntriesPopup
											phaseId={modal.phaseId}
											lineItem={modal.lineItem}
											usedTimesheetIds={
												new Set(
													self.state
														.getLineItemTimesheets()
														.filter(
															(te) =>
																te.projectPhase
																	.id ===
																	modal.phaseId &&
																(te.notes ||
																	te.numMinutes >
																		0)
														)
														.map((te) => te.id) ||
														[]
												)
											}
											allTimesheetEntries={
												self.state
													.getTimesheets()
													.filter(
														(te) =>
															te.projectPhase
																.id ===
																modal.phaseId &&
															(te.notes ||
																te.numMinutes >
																	0)
													) || []
											}
											invoice={self.state.invoice}
											setLineItemTimeEntries={(
												lineItemUuid,
												timeEntryIds
											) => {
												invoiceActions.setLineItemTimeEntries(
													lineItemUuid,
													timeEntryIds
												);
												invoiceActions.cancelModal(
													modal.type
												);
											}}
										/>
									);
									break;
								case "editInvoiceDates":
									header = "Billing for work carried out";
									closeOnClickOutside = true;
									width = "45em";
									content = (
										<EditInvoiceDatesPopup
											invoice={self.state.invoice}
											onSubmit={(startDate, endDate) =>
												invoiceActions.submitEditInvoiceDatesPopup(
													startDate,
													endDate
												)
											}
										/>
									);
									break;
								case "expenseSync":
									header = "Sync expenses";
									closeOnClickOutside = true;
									width = "45em";
									content = (
										<ExpensesPopup
											onSubmit={(
												expensesToAdd,
												expensesToRemove
											) =>
												invoiceActions.submitExpenseSyncForm(
													expensesToAdd,
													expensesToRemove
												)
											}
										/>
									);
									break;
								case "updateTimesheets":
									header = "Update Timesheets";
									closeOnClickOutside = true;
									width = "45em";
									content = (
										<div>
											<div style={{ padding: "2em" }}>
												<p
													style={{
														marginBottom: "2em",
													}}
												>
													Would you like to modify the
													timesheets for this invoice?
												</p>
												<div>
													<Checkbox
														label="Lock timesheets"
														value={
															self.state
																.lockTimesheetsOnSave
														}
														onChange={(
															lockTimesheetsOnSave
														) =>
															invoiceActions.updateLockTimesheetsOnSave(
																lockTimesheetsOnSave
															)
														}
													/>
												</div>
												<div>
													<Checkbox
														label="Mark timesheets as 'invoiced'"
														value={
															self.state
																.markTimesheetsAsInvoicedOnSave
														}
														onChange={(
															markTimesheetsAsInvoicedOnSave
														) =>
															invoiceActions.updateMarkTimesheetsAsInvoicedOnSave(
																markTimesheetsAsInvoicedOnSave
															)
														}
													/>
												</div>
											</div>
											<div
												style={{
													padding: "1em",
													borderTop: "solid 1px #ccc",
													textAlign: "right",
												}}
											>
												<Checkbox
													value={
														self.state
															.autoUpdateTimeEntries
													}
													onChange={function (value) {
														invoiceActions.changeAutoUpdateTimeEntries(
															value
														);
													}}
													label="Do this automatically in the future."
													style={{ float: "left" }}
												/>
												<a
													href="javascript:void(0)"
													style={{
														marginRight: "1em",
													}}
													onClick={() =>
														invoiceActions.cancelModal(
															modal.type
														)
													}
												>
													Cancel
												</a>
												<TriStateSaveButton
													text="Save invoice"
													state={
														self.state
															.saveInvoiceOperation
															.state
													}
													onClick={() =>
														invoiceActions.confirmTimeModal()
													}
													className="flex-0-0-auto invoice__save-button"
												/>
											</div>
										</div>
									);
									break;
								case "confirmDeleteInvoice":
									header = "Deleting invoice";
									closeOnClickOutside = true;
									width = "45em";
									content = (
										<div>
											<div style={{ padding: "2em" }}>
												<p
													style={{
														marginBottom: "2em",
													}}
												>
													Are you sure you want to
													delete this invoice?
												</p>
												<div>
													<Checkbox
														label="Unlock timesheets"
														value={
															self.state
																.unlockTimesheetsOnDelete
														}
														onChange={(
															unlockTimesheetsOnDelete
														) =>
															invoiceActions.updateUnlockTimesheetsOnDelete(
																unlockTimesheetsOnDelete
															)
														}
													/>
												</div>
												<div>
													<Checkbox
														label="Unmark timesheets as 'invoiced'"
														value={
															self.state
																.unmarkTimesheetsAsInvoicedOnDelete
														}
														onChange={(
															unmarkTimesheetsAsInvoicedOnDelete
														) =>
															invoiceActions.updateUnmarkTimesheetsAsInvoicedOnDelete(
																unmarkTimesheetsAsInvoicedOnDelete
															)
														}
													/>
												</div>
											</div>
											<div
												style={{
													padding: "1em",
													borderTop: "solid 1px #ccc",
													textAlign: "right",
												}}
											>
												<a
													href="javascript:void(0)"
													onClick={() =>
														invoiceActions.cancelModal(
															modal.type
														)
													}
												>
													Cancel
												</a>
												<DeleteButton
													className="delete-confirmation__delete-button"
													text="Delete invoice"
													onClick={() =>
														invoiceActions.confirmDeleteInvoice()
													}
													style={{
														marginLeft: "1em",
													}}
												/>
											</div>
										</div>
									);
									break;
								case "myobAccountRightAuthForm":
									header = "MYOB AccountRight Authentication";
									closeOnClickOutside = false;
									width = "40em";
									content = (
										<MyobAccountRightAuthForm
											onSubmit={(username, password) =>
												invoiceActions.setMyobAccountRightAuth(
													modal,
													username,
													password
												)
											}
											onDismiss={() =>
												invoiceActions.cancelModal(
													modal.type
												)
											}
											initialUsername={
												modal.initialData != null
													? modal.initialData.username
													: null
											}
											initialPassword={
												modal.initialData != null
													? modal.initialData.password
													: null
											}
										/>
									);
									break;
								case "error":
									modal2 = (
										<ErrorModal
											modal={modal}
											onDismiss={() =>
												invoiceActions.dismissError(
													modal.operation
												)
											}
											onCancel={() =>
												invoiceActions.cancelModal(
													modal.type
												)
											}
										/>
									);
									break;
								case "updateForecasts":
									modal2 = (
										<ConfirmSyncModal
											itemTypeName="project"
											project={projectStore.project}
											onConfirm={(data) => {
												projectActions.confirmSave(
													data
												);
												invoiceActions.cancelModal(
													"updateForecasts"
												);
											}}
											onCancel={() =>
												invoiceActions.cancelModal(
													"updateForecasts"
												)
											}
											saveState={projectStore.saveState}
										/>
									);
									break;
								case "updateInvoiceData":
									modal2 = (
										<UpdateInvoiceDataModal
											invoice={self.state.invoice}
											closeModal={() =>
												invoiceActions.cancelModal(
													"updateInvoiceData"
												)
											}
										/>
									);
									break;
								default:
									throw new Error("modal type");
							}

							return (
								<div key={i}>
									{modal2 != null ? (
										modal2
									) : (
										<Modal2
											key={i}
											header={header}
											closeOnClickOutside={
												closeOnClickOutside
											}
											width={width}
											onCancel={() =>
												invoiceActions.cancelModal(
													modal.type
												)
											}
										>
											{content}
										</Modal2>
									)}
								</div>
							);
						})}

						<div
							style={{
								display: "flex",
								padding: "1em",
								borderBottom: "solid 1px #e5e5e5",
							}}
						>
							<div className="flex-0-0-auto">
								<h3 style={{ marginTop: 0 }}>
									{this.state.invoice.project.getTitle()}
								</h3>
								<div>
									<span>{"For work carried out from "}</span>
									<strong>
										{self.state.invoice.startDate.format(
											"DD/MM/YYYY"
										)}
									</strong>
									{" to "}
									<strong>
										{self.state.invoice.endDate.format(
											"DD/MM/YYYY"
										)}
									</strong>
									<button
										className="btn btn-sm btn-tiny"
										onClick={
											this.handleDateChangeButtonClick
										}
										style={{ marginLeft: "1em" }}
									>
										Change...
									</button>
								</div>
							</div>
							<div className="flex-1-1-auto" />
							<div
								className="flex-0-0-auto"
								style={{ textAlign: "right" }}
							>
								<div
									style={{
										fontStyle: "italic",
										fontSize: "0.9em",
									}}
								>
									Created:{" "}
									{this.state.invoice.createdAt.format(
										"ddd DD MMMM, YYYY"
									)}
								</div>

								{invoiceExternalUrl != null ? (
									<a
										target="_blank"
										href={invoiceExternalUrl}
									>
										<button className="btn btn-xero btn-sm">
											View in{" "}
											{
												this.state.accountingSystem
													.shortName
											}
										</button>
									</a>
								) : null}
							</div>
						</div>

						<div style={{ clear: "both" }} />

						{this.renderInvoiceFields()}

						<div
							className="data-table data-table--no-hover invoice-line-items-table"
							style={{ width: "100%", padding: "1em" }}
						>
							{self.state.invoice
								.getSortedPhases()
								.map(function (invoicePhase, i) {
									return (
										<InvoicePhase
											key={i}
											invoice={self.state.invoice}
											invoicePhase={invoicePhase}
											timesheetEntries={
												self.state.timesheetEntries
											}
											startDate={self.state.startDate}
											endDate={self.state.endDate}
											onChange={function (invoice) {
												self.setState({
													invoice: invoice,
												});
											}}
										/>
									);
								})}
							<div
								style={{
									borderTop: "solid 1px #aaa",
									paddingTop: "1.5em",
								}}
							>
								<div style={{ textAlign: "right" }}>
									{/* TODO-invoice_improvements */}
									<div
										style={{
											display: "inline-block",
											textAlign: "right",
											margin: "1em",
											width: "30%",
										}}
									>
										<div className="flexbox-container flex-align-items-center flex-space-between">
											<div
												className="flex-0-0-auto"
												style={{ width: "35%" }}
											>
												Subtotal
											</div>
											<div
												className="flex-1-1-auto invoice__total-ex-tax"
												style={{ marginRight: "6%" }}
											>
												{this.state.currencyFormatter.formatWithCents(
													this.state.invoice
														.totalExTax
												)}
											</div>
										</div>
										<div className="flexbox-container flex-align-items-center flex-space-between">
											<div
												className="flex-0-0-auto"
												style={{ width: "35%" }}
											>
												Tax (
												{
													this.state.invoice
														.taxRatePercent
												}
												%)
											</div>
											<div
												className="flex-1-1-auto"
												style={{ marginRight: "6%" }}
											>
												{this.state.currencyFormatter.formatWithCents(
													this.state.invoice.totalTax
												)}
											</div>
										</div>
										<div
											className="flexbox-container flex-align-items-center flex-space-between"
											style={{
												fontSize: "1.3em",
												fontWeight: 600,
												marginTop: "1em",
												paddingTop: "0.5em",
												borderTop: "2px solid #888",
											}}
										>
											<div
												className="flex-0-0-auto"
												style={{ width: "35%" }}
											>
												Total
											</div>
											<div
												className="invoice__total-inc-tax flex-1-1-auto"
												style={{ marginRight: "6%" }}
											>
												{this.state.currencyFormatter.formatWithCents(
													this.state.invoice
														.totalIncTax
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Just so the intercom button doesn't go on top of the save button */}
						<div style={{ height: 50 }}>&nbsp;</div>
					</div>
				}
			/>
		);
	},

	renderInvoiceFields() {
		let self = this;
		let errors = this.state.invoice.errors || {};
		const org = organisationStore.organisation;
		const accountingSytemSettings =
			org.accountingSystemSettings[org.accountingSystem]?.settings;
		const showInvoiceRef =
			accountingSytemSettings?.useProjectNameAsReference;

		return (
			<div style={{ padding: "1em", borderBottom: "solid 1px #ccc" }}>
				<div className="coincraft-table">
					<div className="flexbox-container coincraft-table-row header">
						<div
							className="coincraft-table-cell"
							style={{ width: "24%" }}
						>
							Invoice Number
						</div>
						<div
							className="coincraft-table-cell"
							style={{ width: "20%" }}
						>
							Addressed to
						</div>
						<div
							className="coincraft-table-cell"
							style={{ width: "15%" }}
						>
							Issue Date
						</div>
						<div
							className="coincraft-table-cell"
							style={{ width: "10%" }}
						>
							Due in
						</div>
						<div
							className="coincraft-table-cell"
							style={{ width: "15%" }}
						>
							Due Date
						</div>
						<div
							className="coincraft-table-cell"
							style={{ width: "16%" }}
						>
							Tax Rate
						</div>
						{showInvoiceRef && (
							<div
								className="coincraft-table-cell"
								style={{ width: "16%" }}
							>
								Reference
							</div>
						)}
					</div>
					<div className="flexbox-container coincraft-table-row">
						<div
							className="coincraft-table-cell coincraft-table-cell--input"
							style={{ width: "24%" }}
						>
							<input
								type="text"
								className="coincraft-table-cell__input invoice-name-input"
								value={this.state.invoice.description}
								style={{ width: "100%", verticalAlign: "top" }}
								onChange={(event) =>
									invoiceActions.setInvoiceField(
										"description",
										event.target.value
									)
								}
							/>
							{!this.state.invoice.id && (
								<div
									style={{
										fontSize: "1.25em",
										lineHeight: "0.5em",
									}}
								>
									<i
										className="fa fa-caret-up"
										style={{
											cursor: "pointer",
											margin: 0,
											padding: 0,
											zIndex: 2,
										}}
										onClick={() =>
											invoiceActions.shiftInvNumUp()
										}
									/>
									<i
										className="fa fa-caret-down"
										style={{
											cursor: "pointer",
											margin: 0,
											padding: 0,
											marginTop: "-1em",
											zIndex: 1,
										}}
										onClick={() =>
											invoiceActions.shiftInvNumDown()
										}
									/>
								</div>
							)}
							{this.props.showErrors &&
							this.state.invoice.errors.description ? (
								<ErrorPopover
									orientation="right"
									message="This invoice needs an invoice number."
								/>
							) : null}
						</div>
						<div
							className="coincraft-table-cell coincraft-table-cell--contact"
							style={{ width: "20%", textAlign: "center" }}
						>
							<ContactSelector
								value={self.state.invoice.contact || null}
								path="invoice-page/contact-selector"
								context="invoice"
								style={{ width: "100%" }}
							/>
							{this.props.showErrors && errors.contactMissing ? (
								<ErrorPopover
									orientation="right"
									message="To sync, this invoice needs a contact."
								/>
							) : null}
						</div>
						<div
							className="coincraft-table-cell coincraft-table-cell--date"
							style={{ width: "15%" }}
						>
							<DateValue
								value={self.state.invoice.issuedOn}
								onChange={(date) =>
									invoiceActions.setInvoiceField(
										"issuedOn",
										date
									)
								}
							/>
						</div>
						<div
							className="coincraft-table-cell coincraft-table-cell--input"
							style={{ width: "5%" }}
						>
							<NumberInputContainer
								className="coincraft-table-cell__input"
								value={self.state.invoice.dueDate.diff(
									self.state.invoice.issuedOn,
									"days"
								)}
								style={{ width: "2.5em" }}
								formatFunc={(n) => n.toString()}
								onChange={(n) =>
									invoiceActions.setNumDaysTillDueDate(n)
								}
							/>
						</div>
						<div
							className="coincraft-table-cell flexbox-container flex-align-items-center"
							style={{ width: "5%" }}
						>
							<span>days</span>
						</div>
						<div
							className="coincraft-table-cell coincraft-table-cell--date"
							style={{ width: "15%" }}
						>
							<DateValue
								value={self.state.invoice.dueDate}
								onChange={(date) =>
									invoiceActions.setInvoiceField(
										"dueDate",
										date
									)
								}
							/>
						</div>
						<div
							className={classNames(
								"coincraft-table-cell",
								"invoice--text",
								{
									"coincraft-table-cell--input":
										this.state.accountingSystemId ===
										"none",
								}
							)}
							style={{ width: "16%" }}
						>
							{this.state.accountingSystemId === "none" ? (
								<div className="coincraft-table-cell--input__container invoice__tax-rate">
									<NumberInputContainer
										className="coincraft-table-cell__input"
										type="text"
										formatFunc={(n) => formatNumber2(n)}
										value={
											self.state.invoice.taxRatePercent
										}
										onChange={(r) =>
											invoiceActions.setInvoiceField(
												"taxRatePercent",
												r
											)
										}
										style={{
											marginLeft: 6,
											width: 60,
											textAlign: "right",
										}}
									/>
									<span className="coincraft-table-cell__symbol">
										%
									</span>
								</div>
							) : (
								<span className="invoice__tax-rate">
									{this.state.invoice.taxRatePercent}%
								</span>
							)}
						</div>
						{showInvoiceRef && (
							<div
								className="coincraft-table-cell coincraft-table-cell--input"
								style={{ width: "16%" }}
							>
								<input
									type="text"
									className="coincraft-table-cell__input invoice-ref-input"
									value={
										this.state.invoice.project.invoiceRef ??
										this.state.invoice.project.name
									}
									style={{
										width: "100%",
										verticalAlign: "top",
									}}
									onChange={(event) =>
										invoiceActions.setInvoiceRef(
											event.target.value
										)
									}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	},

	handleDateChangeButtonClick: function () {
		invoiceActions.openEditInvoiceDatesPopup();
	},

	handleBackToInvoicesListButtonClick: function () {
		router.history.push("/dashboard/invoices");
	},
});
