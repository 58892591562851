import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';


export const BusinessCategory = class extends DataObject {
  constructor(options) {
    super(options);
    if (this.uuid == null) {
      this.uuid = generateUUID();
    }
  }

  apiTypeName() {
    return "cost-centre";
  }

  static getClassName() {
    return "BusinessCategory";
  }

  defaults() {
    return {
      id: null,
      uuid: null,
      name: '',
      isBillable: true,
    };
  }

  matchesSearch(searchStr) {
    if (searchStr == null || searchStr === '') {
      return true;
    }
    const searchLower = searchStr.toLowerCase();
    return this.name.toLowerCase().indexOf(searchLower) !== -1;
  }
}


