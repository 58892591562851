import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';
import { dateConverter } from './dateconverter.js';
import xspans from 'xspans';
import moment from 'moment';


export const OrganisationHoliday = class extends DataObject {
			constructor(options) {
				super(options);
				if (this.uuid == null) {
					this.uuid = generateUUID();
				}
				if (this.startDate == null) {
					this.startDate = moment();
				}
				if (this.endDate == null) {
					this.endDate = moment();
				}
			}

			apiTypeName() {
				// needed?
				return "organisation-holiday";
			}

			static getClassName() {
				return "OrganisationHoliday";
			}

			defaults() {
				return {
					id: null,
					name: "",
					startDate: moment(),
					endDate: moment(),
					isDeleted: false
				};
			}

			static fieldTypes() {
				return {
					id: "int",
					name: "string",
					startDate: "date",
					endDate: "date",
					isDeleted: "bool"
				};
			}

			static fieldsForSerialize() {
				return [
					"id",
					"uuid",
					"name",
					"startDate",
					"endDate",
					"isDeleted"
				];
			}

			matchesSearch(searchStr) {
				if (searchStr == null || searchStr === "") {
					return true;
				}
				const searchLower = searchStr.toLowerCase();
				return this.name.toLowerCase().indexOf(searchLower) !== -1;
			}

			get intDateArray() {
				return [
					dateConverter.momentToInt(this.startDate) - 1,
					dateConverter.momentToInt(this.endDate) + 1
				];
			}

			get xspan() {
				return xspans(this.intDateArray);
			}
		};
