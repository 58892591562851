import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { organisationStore } from "../organisation.js";
import {
	Checkbox,
	Dropdown3,
	Dropdown3ListItem,
	SmallDeleteButton,
	BasicMySelect2,
	NumberInputContainer,
	CurrencySymbol,
} from "../widgets.js";
import {
	formatCurrencyWithCents,
	formatPercentage0,
	formatPercentage2,
	formatNumber2,
} from "../utils.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { invoiceActions } from "./InvoicePageStore.js";
import { TemplateEditor } from "./TemplateEditor.js";
import { InvoiceCodeInfo } from "./invoiceCodeInfo.js";
import classNames from "classnames";

import PropTypes from "prop-types";

export var InvoicePhase = CreateReactClass({
	propTypes: {
		invoice: PropTypes.object.isRequired,
		invoicePhase: PropTypes.object.isRequired,
		timesheetEntries: PropTypes.array.isRequired,

		onChange: PropTypes.func,

		startDate: PropTypes.object,
		endDate: PropTypes.object,
	},

	mixins: [
		makeMultipleStoreMixin([organisationStore], function () {
			return {
				currencyFormatter:
					organisationStore.organisation.currencyFormatter,
			};
		}),
	],

	getInitialState: function () {
		return {
			isPopupOpen: false,
			isDropdownExpanded: false,
		};
	},

	render: function () {
		let self = this;
		const phase =
			organisationStore.getProjectPhaseById(
				this.props.invoicePhase.phaseId
			) || organisationStore.getProjectPhaseById(-1);
		const hasProgressLine = phase.isRealPhase && phase.fee > 0;

		return (
			<div className="invoice__phase" data-phase-id={phase.id}>
				<div>
					<h4
						style={{
							fontSize: "1.8em",
							margin: hasProgressLine
								? "1em 0 0 0.5em"
								: "1em 0 0.5em 0.5em",
						}}
					>
						{phase.getTitle()}
						{hasProgressLine ? (
							<span>
								{" - "}
								{formatPercentage2(
									this.props.invoicePhase.phasePreviousPercent
								)}{" "}
								<i
									style={{
										margin: 0,
										fontSize: "0.6em",
										position: "relative",
										top: "-0.25em",
									}}
									className="fa fa-arrow-right"
								/>
								{formatPercentage2(
									this.props.invoicePhase.phasePercent
								)}
							</span>
						) : phase.isRealPhase ? (
							<span
								style={{
									marginLeft: "1em",
									fontSize: "0.6em",
									color: "#888",
									fontStyle: "italic",
								}}
							>
								(Phase has no fee set)
							</span>
						) : null}
					</h4>
					{hasProgressLine ? (
						<PhaseProgressLine
							fromPercentage={
								this.props.invoicePhase.phasePreviousPercent
							}
							toPercentage={this.props.invoicePhase.phasePercent}
						/>
					) : null}
				</div>
				<div>
					<div className="coincraft-table">
						{!this.props.invoicePhase.lineItems.isEmpty() ? (
							<div className="flexbox-container coincraft-table-row header">
								<div
									className="coincraft-table-cell"
									style={{ width: "14%" }}
								>
									Type
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "38%" }}
								>
									Description
									<InvoiceCodeInfo
										style={{ marginLeft: "0.5em" }}
									/>
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "8%" }}
								>
									QTY
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "12%" }}
								>
									Unit Cost
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "12%" }}
								>
									Amount ex. Tax
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "12%" }}
								>
									Tax
								</div>
								<div
									className="coincraft-table-cell"
									style={{ width: "4%" }}
								></div>
							</div>
						) : null}
						{this.props.invoicePhase.lineItems.map(function (
							lineItem
						) {
							return (
								<div
									className="flexbox-container coincraft-table-row invoice__line-item"
									key={lineItem.uuid}
								>
									<div
										className={classNames(
											"coincraft-table-cell",
											{
												"coincraft-table-cell--dropdown":
													lineItem.billingType !==
													"note",
												"coincraft-table-cell--text":
													lineItem.billingType ===
													"note",
											}
										)}
										style={{ width: "14%" }}
									>
										{lineItem.billingType === "note" ? (
											<em>(Note)</em>
										) : (
											<BasicMySelect2
												value={lineItem.billingType}
												onChange={(billingType) =>
													invoiceActions.setLineItemField(
														lineItem.uuid,
														"billingType",
														billingType
													)
												}
												options={[
													{
														label: "Agreed Fee",
														value: "agreedFee",
													},
													{
														label: "Variation",
														value: "variation",
													},
													{
														label: "Reimbursement",
														value: "reimbursement",
													},
												]}
											/>
										)}
									</div>
									<div
										className="coincraft-table-cell coincraft-table-cell--textarea"
										style={{ width: "38%" }}
									>
										<TemplateEditor
											className="invoice-line-item__description"
											sourceValue={
												lineItem.descriptionSource
											}
											renderedValue={lineItem.description}
											onChange={(event) =>
												invoiceActions.setLineItemField(
													lineItem.uuid,
													"descriptionSource",
													event.target.value
												)
											}
											openTimeModal={(e) => {
												invoiceActions.openLineItemTimeEntryModal(
													lineItem,
													phase.id
												);
											}}
										/>
									</div>
									<div
										className={classNames(
											"coincraft-table-cell",
											{
												"coincraft-table-cell--input":
													lineItem.lineItemType !==
													"note",
											}
										)}
										style={{ width: "8%" }}
									>
										{_.include(
											["progress", "projectProgress"],
											lineItem.lineItemType
										) ? (
											<div
												className="flex-1-0-auto flexbox-container flex-align-items-center"
												style={{ width: "100%" }}
											>
												<NumberInputContainer
													type="number"
													className="coincraft-table-cell__input no-arrows invoice__line-item__quantity"
													value={
														lineItem.phasePercent
													}
													style={{
														textAlign: "right",
													}}
													formatFunc={(n) =>
														formatNumber2(n || 0)
													}
													onChange={(value) =>
														invoiceActions.setLineItemField(
															lineItem.uuid,
															"phasePercent",
															Math.round(
																value * 100
															) / 100
														)
													}
												/>
												<span className="coincraft-table-cell__symbol">
													%
												</span>
											</div>
										) : _.include(
												[
													"timesheets",
													"fixed",
													"expense",
													"previouslyBilled",
													"projectPreviouslyBilled",
												],
												lineItem.lineItemType
										  ) ? (
											<div
												className="flex-1-0-auto flexbox-container flex-align-items-center"
												style={{ width: "100%" }}
											>
												<NumberInputContainer
													type="number"
													className="coincraft-table-cell__input no-arrows invoice__line-item__quantity"
													style={{
														textAlign: "right",
													}}
													value={
														lineItem.unitQuantity
													}
													onChange={(value) =>
														invoiceActions.setLineItemField(
															lineItem.uuid,
															"unitQuantity",
															Math.round(
																value * 100
															) / 100
														)
													}
												/>
											</div>
										) : null}
									</div>
									<div
										className={classNames(
											"coincraft-table-cell",
											{
												"coincraft-table-cell--input":
													_.include(
														[
															"timesheets",
															"fixed",
															"expense",
															"previouslyBilled",
															"projectPreviouslyBilled",
														],
														lineItem.lineItemType
													),
											},
											{
												"coincraft-table-cell--currency":
													_.include(
														[
															"progress",
															"projectProgress",
														],
														lineItem.lineItemType
													),
											}
										)}
										style={{ width: "12%" }}
									>
										{_.include(
											["progress", "projectProgress"],
											lineItem.lineItemType
										) ? (
											<div
												className="flex-1-0-auto flexbox-container flex-align-items-center"
												style={{ width: "100%" }}
											>
												<span className="coincraft-table-cell__symbol">
													<CurrencySymbol />
												</span>
												<span className="coincraft-table-cell__value invoice__line-item__unit-cost">
													{formatCurrencyWithCents(
														self.props.invoicePhase
															.phaseFee ||
															lineItem.unitCost
													)}
												</span>
											</div>
										) : _.include(
												[
													"timesheets",
													"fixed",
													"expense",
													"previouslyBilled",
													"projectPreviouslyBilled",
												],
												lineItem.lineItemType
										  ) ? (
											<div
												className="flex-1-0-auto flexbox-container flex-align-items-center"
												style={{ width: "100%" }}
											>
												<span className="coincraft-table-cell__symbol">
													<CurrencySymbol />
												</span>
												<NumberInputContainer
													type="number"
													className="coincraft-table-cell__input no-arrows invoice__line-item__unit-cost"
													style={{
														textAlign: "right",
													}}
													value={lineItem.unitCost}
													onChange={(value) =>
														invoiceActions.setLineItemField(
															lineItem.uuid,
															"unitCost",
															Math.round(
																value * 100
															) / 100
														)
													}
													allowNegative={true}
												/>
											</div>
										) : null}
									</div>
									<div
										className="coincraft-table-cell coincraft-table-cell--currency"
										style={{ width: "12%" }}
									>
										{lineItem.lineItemType !== "note" ? (
											<div className="coincraft-table-cell--currency__container">
												<span className="coincraft-table-cell__symbol">
													<CurrencySymbol />
												</span>
												<span className="coincraft-table-cell__value invoice__line-item__line-total">
													{formatCurrencyWithCents(
														lineItem.lineTotalExTax
													)}
												</span>
											</div>
										) : null}
									</div>
									<div
										className="coincraft-table-cell coincraft-table-cell--currency"
										style={{ width: "12%" }}
									>
										{lineItem.lineItemType !== "note" ? (
											<div className="coincraft-table-cell--currency__container">
												<span className="coincraft-table-cell__symbol">
													<CurrencySymbol />
												</span>
												<span className="coincraft-table-cell__value">
													{formatCurrencyWithCents(
														lineItem.lineTotalTax
													)}
												</span>
												<Checkbox
													className="coincraft-table-cell__symbol invoice__line-item__tax-input"
													value={lineItem.isTaxed}
													onChange={(isTaxed) =>
														invoiceActions.setLineItemField(
															lineItem.uuid,
															"isTaxed",
															isTaxed
														)
													}
												/>
											</div>
										) : null}
									</div>
									<div
										className="coincraft-table-cell coincraft-table-cell--btn"
										style={{ width: "4%" }}
									>
										<SmallDeleteButton
											onClick={() =>
												invoiceActions.deleteLineItem(
													self.props.invoicePhase
														.phaseId,
													lineItem.uuid
												)
											}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div
						style={{ textAlign: "right" }}
						className="flexbox-container flex-align-items-center flex-space-between"
					>
						<div
							style={{ display: "inline-block", padding: "1em" }}
						>
							<DropdownButton
								className="add-line-item-button"
								buttonText={
									<span>
										<i
											className="fa fa-plus"
											style={{ margin: 0 }}
										/>
										&nbsp;Add Line Item
									</span>
								}
								options={[
									...(phase.isRealPhase
										? [
												{
													label: "Phase Progress",
													value: "progress",
													...(phase.fee == null ||
													phase.fee === 0
														? {
																disabled: true,
																title: "Set a fee for this phase to create progress line items",
														  }
														: {}),
												},
										  ]
										: [
												{
													label: "Project Progress",
													value: "projectProgress",
												},
												{
													label: "Less Project Previously Billed",
													value: "projectPreviouslyBilled",
												},
										  ]),
									...[
										{
											label: "Timesheets",
											value: "timesheets",
										},
										{
											label: "Fixed Amount",
											value: "fixed",
										},
										{ label: "Expense", value: "expense" },
										{
											label: "Less Phase Previously Billed",
											value: "previouslyBilled",
										},
										{ label: "Note", value: "note" },
									],
								]}
								onItemClick={(lineItemType) =>
									invoiceActions.addNewLineItem(
										self.props.invoicePhase.phaseId,
										lineItemType
									)
								}
							/>

							{this.props.invoicePhase.phaseId === -1 ? (
								<button
									className="btn btn-default"
									onClick={() =>
										invoiceActions.openExpenseSyncPopup()
									}
									style={{ marginLeft: "2em" }}
								>
									Sync expenses
								</button>
							) : null}
						</div>
						<div>
							{this.props.invoicePhase.lineItems.size > 0 ? (
								<div
									style={{
										fontSize: "1.3em",
										display: "inline-block",
										padding: "0.5em 4em",
										margin: "1em 0.5em 0.5em 0.5em",
										borderTop: "solid 2px #777",
									}}
								>
									{this.state.currencyFormatter.formatWithCents(
										self.props.invoicePhase.phaseTotalExTax
									)}
									{" + "}
									{this.state.currencyFormatter.formatWithCents(
										self.props.invoicePhase.phaseTotalTax
									)}{" "}
									tax
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	},

	handleProgressMenuItemClick: function () {
		// Can only have one percentage line item per phase.
		let self = this;
		let phase = this.props.phase;
		if (
			!_.any(
				this.props.invoice.lineItems,
				(li) => li.phase === phase && li.lineItemType === "percentage"
			)
		) {
			this.props.invoice.makeProgressLineItem(phase).then(function () {
				self.props.onChange(self.props.invoice);
			});
			this.setState({ isDropdownExpanded: false });
		}
	},

	handleTimesheetMenuItemClick: function () {
		this.setState({
			isPopupOpen: true,
			isDropdownExpanded: false,
		});
	},

	handleCustomMenuItemClick: function () {
		let self = this;
		this.props.invoice
			.makeCustomLineItem(this.props.phase)
			.then(function () {
				self.props.onChange(self.props.invoice);
			});
		this.setState({ isDropdownExpanded: false });
	},
});

var PhaseProgressLine = CreateReactClass({
	propTypes: {
		fromPercentage: PropTypes.number.isRequired,
		toPercentage: PropTypes.number.isRequired,
	},

	render: function () {
		return (
			<div
				style={{
					width: "100%",
					height: "0.5em",
					backgroundColor: "#aaa",
					position: "relative",
					margin: "0.5em 0 1em",
				}}
			>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: Math.min(this.props.fromPercentage, 100) + "%",
						height: "100%",
						backgroundColor:
							this.props.fromPercentage <= 100
								? "gold"
								: "#f05a28",
					}}
				/>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: this.props.fromPercentage + "%",
						width:
							Math.min(
								this.props.toPercentage -
									this.props.fromPercentage,
								100 - this.props.fromPercentage
							) + "%",
						height: "100%",
						background:
							this.props.toPercentage <= 100
								? "repeating-linear-gradient(135deg, gold, gold 3px, #aaa 3px, #aaa 6px)"
								: "repeating-linear-gradient(135deg, gold, gold 3px, #f05a28 3px, #f05a28 6px)",
						borderRight:
							this.props.toPercentage <= 100
								? "solid 2px gold"
								: "solid 2px #f05a28",
					}}
				/>
			</div>
		);
	},
});

var DropdownButton = CreateReactClass({
	propTypes: {
		className: PropTypes.any,
		buttonText: PropTypes.any,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.any,
				value: PropTypes.any,
				disabled: PropTypes.bool,
				title: PropTypes.string,
			})
		),
		onItemClick: PropTypes.func.isRequired,
	},

	getInitialState: function () {
		return {
			isExpanded: false,
		};
	},

	render: function () {
		const { buttonText, options, ...props } = this.props;
		return (
			<Dropdown3
				isExpanded={this.state.isExpanded}
				onToggle={(isExpanded) =>
					this.setState({ isExpanded: isExpanded })
				}
				toggleElement={
					<button className="btn btn-default invoice__phase__dropdown-toggle">
						{buttonText}
					</button>
				}
				contentStyle={{ width: 200 }}
				{...props}
			>
				{options.map(function ({ label, value, disabled, title }, i) {
					return (
						<Dropdown3ListItem
							className="dropdown-button__menu-item"
							key={i}
							identifier={value}
							disabled={disabled}
							title={title}
						>
							{label}
						</Dropdown3ListItem>
					);
				})}
			</Dropdown3>
		);
	},
});
