import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from 'moment';
import { StoreBase, dispatcher, ActionCollection } from '../coincraftFlux.js';
import { ReportType } from '../reports/Report.js';
import { userStore } from '../user/flux.js';
import { itemColumn, MyEditor, RevenueCellEditor,
  NAVIGATION_COLUMN_WIDTH, CONTENT_WIDTH } from './Spreadsheet.js';
import { sum, imap, chain, range, areSameDbObjects, isNumber } from '../utils.js';
import { dateConverter, Milestone, NoProjectProject, NoPhasePhase } from '../models.js';
import { getAllocationSpreadsheetData } from './allocation.js';
import { getRevenueSpreadsheetData } from './revenue.js';
import { ProjectPermissionChecker } from '../models/permissions.js';
import { getProjectReportMatcher, getStaffReportMatcher } from '../reports/reportMatchers.js';
import { PermissionLevel } from '../models/permissions.js';
import { store as milestonesStore } from '../milestones/flux.js';
import { organisationStore } from '../organisation.js';
import { jsonHttp2 } from '../jsonHttp.js';
import Immutable from 'immutable';
import { QUALIFIER, TYPE, QUANTITY } from "./types.js";
import apiRequest from '../apiRequest.js';
export { QUALIFIER, TYPE, QUANTITY } from "./types.js";


export const Store = class extends StoreBase {
  constructor() {
    super();
    this.isReady = false;
  }

  initialize() {
    // Start at, say, three months before the current month.
    const todaysMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment()));
    this.startMonth = todaysMonthIndex - 3;
    this.endMonth = this.startMonth + 11;

    // Spec: row display logic.
    /**
     * `editedRevenueRowKeys` is used for two purposes:
     *
     * 1. To keep a list of keys that have been rendered (so they don't
     * disappear if the user sets the row to all-zero).
     *
     * 2. To keep a list of rows that the user has added in this session via
     * the 'add row' form, so they are rendered too even if they are all-zero.
     */
    this.editedRevenueRowKeys = Immutable.Set(); // Set([<project id>, <phase id>])

    /**
     * Analogous to #1 above.
     */
    this.existingAllocationRowKeys = Immutable.Set(); // Set([<project id>, <phase id>, <staff id>])

    /**
     * Analogous to #2 above. They're separate for the case of allocation
     * because of how `getAllocationSpreadsheet` differs from
     * `getRevenueSpreadsheet`.
     */
    this.addedAllocationRowKeys = Immutable.Set(); // Set([<project id>, <phase id>, <staff id>])

    this.timesheetData = null;
    this.timesheetDataError = false;
    this.modals = Immutable.List();

    const report = _.find(organisationStore.reports, r => r.reportType === ReportType.project && r.filters.isEmpty());
    this.hasAllProjectsReport = (report != null);
    this.projectReportUuid = (report != null) ? report.uuid : null;
    this.staffReportUuid = null;

    this.isReady = false;

    this.selectedProcessedRow = null;
    this.selectedRowIndex = null;
    this.selectedCellIndex = null;

    this.getTimesheetData();
    this.emitChanged();
  }

  selectRow(rowIndex, row) {
    this.selectedRowIndex = rowIndex;
    this.selectedProcessedRow = row;
    this.emitChanged();
  }

  selectCell(rowIndex, cellIndex) {
    this.selectedRowIndex = rowIndex;
    this.selectedCellIndex = cellIndex;
    this.emitChanged();
  }

  getTimesheetData() {
    let self = this;
    if (this.timesheetData != null) {
      return new Promise(function(resolve, reject) {
        resolve(self.timesheetData);
      });
    }
    else {
      return getTimesheetData().then(function(data) {
        self.timesheetData = data;
      });
    }
  }

  getCellData({quantity, phase, monthIndex, currentMonthIndex = null}) {
    const baseArgs = {
      quantity: quantity,
      project: phase.project,
      phase: phase,
      timesheetData: this.timesheetData,
      currentMonthIndex: currentMonthIndex || dateConverter.getMonthIndex(dateConverter.momentToInt(moment()))
    };

    return {
      previousMonth: cellQuantity({
        ...baseArgs,
        timePeriod: {startMonth: null, endMonth: monthIndex - 1},
      }),
      toDate: cellQuantity({
        ...baseArgs,
        timePeriod: {startMonth: null, endMonth: monthIndex},
      }),
      total: cellQuantity({
        ...baseArgs,
        timePeriod: {startMonth: null, endMonth: null}
      })
    };
  }

  getStaffMemberLookup(phase, monthIndex) {
    let lookup = {};

    function initStaffMember(staffMemberId) {
      if (lookup[staffMemberId] == null) {
        const staffMember = organisationStore.getStaffMemberById(staffMemberId);
        const budgetedHoursOb = _.find(phase.staffMemberBudgetedHours, sb => sb.staffMember.id === staffMember.id);
        const budgetedHours = (budgetedHoursOb != null) ? budgetedHoursOb.hours : 0;
        lookup[staffMemberId] = {
          staffMember: staffMember,
          previousMonth: 0,
          toDate: 0,
          target: budgetedHours
        };
      }
    }

    const startOfMonth = dateConverter.monthIndexToOffset(monthIndex);
    const endOfMonth = dateConverter.endOfMonthOffset(startOfMonth);
    for (let [, , _ra, staffId, hours] of milestonesStore.iterItems(null, phase, null, null, -Infinity, startOfMonth - 1)) {
      initStaffMember(staffId);
      lookup[staffId].previousMonth += hours;
    }
    for (let [, , _ra, staffId, hours] of milestonesStore.iterItems(null, phase, null, null, -Infinity, endOfMonth)) {
      initStaffMember(staffId);
      lookup[staffId].toDate += hours;
    }

    _.each(lookup, function(data, phaseId) {
      if (data.toDate === data.previousMonth) {
        delete lookup[phaseId];
      }
    });

    return lookup;
  }

  getPhaseLookup(staffMember, monthIndex) {
    let lookup = {};

    function initPhase(phaseId) {
      if (lookup[phaseId] == null) {
        const phase = organisationStore.getProjectPhaseById(phaseId);
        const budgetedHoursOb = _.find(phase.staffMemberBudgetedHours, sb => sb.staffMember.id === staffMember.id);
        const budgetedHours = (budgetedHoursOb != null) ? budgetedHoursOb.hours : 0;
        lookup[phaseId] = {
          phase: phase,
          previousMonth: 0,
          toDate: 0,
          target: budgetedHours
        };
      }
    }

    const startOfMonth = dateConverter.monthIndexToOffset(monthIndex);
    const endOfMonth = dateConverter.endOfMonthOffset(startOfMonth);
    for (let [, pp, _ra, , hours] of milestonesStore.iterItems(null, null, null, staffMember, -Infinity, startOfMonth - 1)) {
      initPhase(pp.id);
      lookup[pp.id].previousMonth += hours;
    }
    for (let [, pp, _ra, , hours] of milestonesStore.iterItems(null, null, null, staffMember, -Infinity, endOfMonth)) {
      initPhase(pp.id);
      lookup[pp.id].toDate += hours;
    }

    _.each(lookup, function(data, phaseId) {
      if (data.toDate === data.previousMonth) {
        delete lookup[phaseId];
      }
    });

    return lookup;
  }

  setSpreadsheetType(type, allocationGroupBy = null) {
    let self = this;

    if (type === 'revenue') {
      this.groupedColumns = ['project'];
      this.leafColumn = 'phase';
    }
    else if (allocationGroupBy === 'staff') {
      this.allocationGroupBy = 'staff';
      this.groupedColumns = ['staffMember', 'project'];
      this.leafColumn = 'phase';
    }
    else {
      this.allocationGroupBy = 'project';
      this.groupedColumns = ['project', 'phase'];
      this.leafColumn = 'staffMember';
    }

    this.isReady = false;
    this._resetRowSelection();

    this.spreadsheetType = type;
    this._reloadSheet().then(function() {
      self.isReady = true;
      self.spreadsheetType = type;
      self.emitChanged();
    });
  }

  _reloadSheet() {
    if (this.spreadsheetType === 'revenue') {
      return this._reloadRevenueSheet();
    }
    else {
      return this._reloadAllocationSheet();
    }
  }

  _reloadRevenueSheet() {
    let self = this;
    const now = moment();

    if (!milestonesStore.isReady) {
      milestonesStore.initializePage();
    }

    const checker = new ProjectPermissionChecker(userStore.user, organisationStore.projects);

    return getProjectReportMatcher(organisationStore.getReportByUuid(this.projectReportUuid)).then(function(projectMatcher) {
      const projects = (organisationStore.getSavedProjects()
        .filter(p => PermissionLevel.isAtLeast(checker.checkProject(p), PermissionLevel.view) && projectMatcher(p))
      );

      self.navigatorColumns = navigatorColumns(
        self.startMonth,
        self.endMonth,
        now,
        self.groupedColumns,
        self.spreadsheetType,
        checker
      );

      let {rows, graph, columnHeaders} = getRevenueSpreadsheetData({
        projects: projects,
        startMonth: self.startMonth,
        endMonth: self.endMonth
      });

      self.spreadsheet = {
        rows: rows,
        graph: graph,
        columnHeaders: columnHeaders,
        columns: getRevenueColumns(self.startMonth, self.endMonth, now, checker)
      };
    });
  }

  _reloadAllocationSheet() {
    let self = this;
    const now = moment();

    const checker = new ProjectPermissionChecker(userStore.user, organisationStore.projects);

    return new Promise(function(resolve, reject) {
      self.getTimesheetData().then(function() {
        getProjectReportMatcher(organisationStore.getReportByUuid(self.projectReportUuid)).then(function(projectMatcher) {
          getStaffReportMatcher(organisationStore.getReportByUuid(self.staffReportUuid)).then(function(staffMatcher) {
            self.navigatorColumns = navigatorColumns(
              self.startMonth,
              self.endMonth,
              now,
              self.groupedColumns,
              self.spreadsheetType,
              checker
            );

            let {existingAllocationRowKeys, rows, graph, columnHeaders} = getAllocationSpreadsheetData({
              milestonesStore: milestonesStore,
              organisationStore: organisationStore,
              timesheetData: self.timesheetData,
              startMonth: self.startMonth,
              endMonth: self.endMonth,
              groupedColumns: self.groupedColumns,
              leafColumn: self.leafColumn,
              projectMatcher: projectMatcher,
              staffMatcher: staffMatcher,
              existingAllocationRowKeys: self.existingAllocationRowKeys,
              addedAllocationRowKeys: self.addedAllocationRowKeys
            });

            self.existingAllocationRowKeys = existingAllocationRowKeys;

            self.spreadsheet = {
              rows: rows,
              graph: graph,
              columnHeaders: columnHeaders,
              columns: getAllocationColumns(self.startMonth, self.endMonth, now, checker),
            };

            resolve();
          });
        });
      }, function() {
        self.timesheetDataError = true;
        self.emitChanged();
      });
    });
  }

  setMonths(startMonth, endMonth) {
    this.startMonth = startMonth;
    this.endMonth = endMonth;
    this._reloadSheet().then(() => this.emitChanged());
  }

  setProjectReport(reportUuid) {
    // `reportUuid`: string or null.
    this.projectReportUuid = reportUuid;

    // We want projects to actually disappear from the list if selecting a new
    // report deselects them.
    this.existingAllocationRowKeys = Immutable.Set();

    this._resetRowSelection();

    this._reloadSheet().then(() => this.emitChanged());
  }

  setStaffReport(reportUuid) {
    // `reportUuid`: string or null.
    this.staffReportUuid = reportUuid;

    // We want projects to actually disappear from the list if selecting a new
    // report deselects them.
    this.existingAllocationRowKeys = Immutable.Set();

    this._resetRowSelection();

    this._reloadSheet().then(() => this.emitChanged());
  }

  _resetRowSelection() {
    this.selectedProcessedRow = null;
    this.selectedRowIndex = null;
    this.selectedProcessedRow = null;
  }

  _setPhaseMonthRevenue(phase, monthIndex, revenue) {
    phase.isRevenueSpreadsheetModified = true;
    phase.setMilestones(getUpdatedMilestones(phase.milestones, monthIndex, revenue, phase.fee));
  }

  setContextMenuPhaseRevenue(phase, monthIndex, revenue) {
    this._setPhaseMonthRevenue(phase, monthIndex, revenue);

    if (this.spreadsheetType === 'revenue') {
      this._reloadSheet().then(() => this.emitChanged());
    }

    milestonesStore.setDirty(phase);
    milestonesStore.emitChanged();
  }

  setRevenueCellValue(rowIndex, cellKey, newValue) {
    let row = this.spreadsheet.rows.get(rowIndex);
    let phase = row.get('phase');
    this._setPhaseMonthRevenue(phase, cellKey, newValue);

    const key = Immutable.List([phase.project.id, phase.id]);
    this.editedRevenueRowKeys = this.editedRevenueRowKeys.add(key);

    //TODO-next_scheduler_fiddling we don't really need to reload the whole sheet,
    //just the row.  (We need to reload that because if the user entered a
    //number that's less than the combined cash flow items for the cell, we
    //need to replace the number with that total).
    this._reloadSheet().then(() => this.emitChanged());

    milestonesStore.setDirty(phase);
    milestonesStore.emitChanged();
  }

  setAllocationCellValue(rowIndex, monthIndex, hours) {
    const row = this.spreadsheet.rows.get(rowIndex);
    const staffMember = row.get('staffMember');
    const phase = row.get('phase');

    const startDate = dateConverter.monthIndexToOffset(monthIndex);
    const endDate = dateConverter.endOfMonthOffset(startDate);

    phase.setStaffMemberHours(
      staffMember,
      startDate,
      endDate,
      hours
    );

    //TODO-next_scheduler_fiddling we don't really need to reload the whole sheet, just the row.
    this._reloadSheet().then(() => this.emitChanged());

    milestonesStore.setDirty(phase);
    milestonesStore._update();
  }

  setTotalAllocationValue(rowIndex, monthIndex, hours) {
    this.setPhaseStaffAllocationValue(rowIndex, monthIndex, null, hours);
  }

  _setPhaseStaffAllocationValue(phase, staffId, monthIndex, hours) {
    const startDate = dateConverter.monthIndexToOffset(monthIndex);
    const endDate = dateConverter.endOfMonthOffset(startDate);
    const staffMember = (staffId != null) ? organisationStore.getStaffMemberById(staffId) : null;
    phase.setStaffMemberHours(staffMember, startDate, endDate, hours);
  }

  setPhaseStaffAllocationValue(rowIndex, monthIndex, staffId, hours) {
    const row = this.spreadsheet.rows.get(rowIndex);
    const phase = row.get('phase');

    this._setPhaseStaffAllocationValue(phase, staffId, monthIndex, hours);

    this._reloadSheet().then(() => this.emitChanged());

    milestonesStore.setDirty(phase);
    milestonesStore._update();
  }

  setContextMenuPhaseStaffHours(phase, staffId, monthIndex, hours) {
    this._setPhaseStaffAllocationValue(phase, staffId, monthIndex, hours);

    if (this.spreadsheetType === 'allocation') {
      this._reloadSheet().then(() => this.emitChanged());
    }

    milestonesStore.setDirty(phase);
    milestonesStore._update();
  }

  moveBy(numMonths) {
    this.startMonth += numMonths;
    this.endMonth += numMonths;
    this._reloadSheet().then(() => this.emitChanged());
  }

  moveLeft() {
    this.moveBy(-1);
  }

  moveRight() {
    this.moveBy(+1);
  }

  getColumnHeaders(columns, rows, mode) {
    return this.spreadsheet.columnHeaders;
  }

  getMonthColumns(columns) {
    return columns.filter(function(column) {
      return (column.key !== 'item' && column.key !== 'moveLeftColumn' && column.key !== 'moveRightColumn');
    });
  }

  submitAddRevenueRowForm(modal, project, phase) {
    this.editedRevenueRowKeys = this.editedRevenueRowKeys.add(Immutable.List([project.id, phase.id]));
    this.modals = this.modals.remove(this.modals.indexOf(modal));
    this._reloadSheet().then(() => this.emitChanged());
  }

  submitAddAllocationRowForm(modal, staffMember, project, phase) {
    const key = Immutable.List([project.id, phase.id, staffMember.id]);
    this.addedAllocationRowKeys = this.addedAllocationRowKeys.add(key);
    this.modals = this.modals.remove(this.modals.indexOf(modal));
    this._reloadSheet().then(() => this.emitChanged());
  }

  addRevenueRow(project) {
    this.modals = this.modals.push({type: 'addRevenueRow', project: project});
    this.emitChanged();
  }

  addAllocationRow(staffMember, project) {
    this.modals = this.modals.push({type: 'addAllocationRow', staffMember: staffMember, project: project});
    this.emitChanged();
  }

  cancelModal(modal) {
    this.modals = this.modals.remove(this.modals.indexOf(modal));
    this.emitChanged();
  }
}


export function getArchivedStaffAvailability(cells) {
  // dict(month index, dict(staff member id, total availability for archived staff for that month))
  let availability = {};

  for (let te of cells) {
    if (te.staffMember.isArchived) {
      if (availability[te.monthIndex] == null) {
        availability[te.monthIndex] = {};
      }
      // Only count a staff member at most once per month.. don't double up if
      // they have multiple cells (ie. multiple projects/phases for the month).
      if (availability[te.monthIndex][te.staffMember.id] == null) {
        const availableHours = te.staffMember.getNumHoursAvailableInMonth(te.monthIndex, organisationStore.getHolidaysXspans().data);
        availability[te.monthIndex][te.staffMember.id] = availableHours;
      }
    }
  }

  return availability;
}



export const MonthlyTimesheetTotals = class {
			constructor(cells) {
				this.cells = cells;
				this.archivedStaffAvailability = getArchivedStaffAvailability(
					cells
				);
			}

			*iterate() {
				yield* this.cells;
			}
		};


function getTimesheetData() {
  /**
   * Usage:
   *
      getTimesheetData().then(function(data) {
        for (let {monthIndex, staffMember, project, projectPhase, hours} of data.iterate()) {
          // ...
        }
      });
   */
  return new Promise(function(resolve, reject) {
    apiRequest({
      url: `/api/v1/timesheet/spreadsheet`,
      method: "get",
      success: data => {
        let cells = [];
      for (let {
        month,
        staffMemberId,
        projectId,
        phaseId,
        hours
      } of data.data) {
        const project =
          projectId !== -1
            ? organisationStore.getProjectById(projectId)
            : new NoProjectProject();
        const phase =
          phaseId !== -1
            ? organisationStore.getProjectPhaseById(phaseId)
            : new NoPhasePhase({ project: project });

        // Defensive.
        // There is one edge case where this can happen: if the user has entered
        // a timesheet for a phase for which all of their permissions have
        // subsequently been removed. Then we will have the timesheet entry data
        // but not the phase data. We've decided to wontfix this for the time
        // being.
        if (project == null || phase == null) {
          continue;
        }

        cells.push({
          monthIndex: dateConverter.getMonthIndex(
            dateConverter.stringToInt(month)
          ),
          project: project,
          projectPhase: phase,
          staffMember: organisationStore.getStaffMemberById(
            staffMemberId
          ),
          hours: hours
        });
      }

      resolve(new MonthlyTimesheetTotals(cells));
      },
      error: data => reject()
    });
  });
}


export let store = new Store();

export let actions = new ActionCollection(
  "SPREADSHEET_",
  store,
  [
    {name: 'selectRow', args: ['rowIndex', 'row'], callback: 'default'},
    {name: 'selectCell', args: ['rowIndex', 'cellIndex'], callback: 'default'},
    {name: 'setProjectReport', args: ['reportUuid'], callback: 'default'},
    {name: 'setStaffReport', args: ['reportUuid'], callback: 'default'},
    {name: 'setRevenueCellValue', args: ['rowIndex', 'cellKey', 'newValue'], callback: 'default'},
    {name: 'setAllocationCellValue', args: ['rowIndex', 'cellKey', 'newValue'], callback: 'default'},
    {name: 'setTotalAllocationValue', args: ['rowIndex', 'monthIndex', 'newValue'], callback: 'default'},
    {name: 'setPhaseStaffAllocationValue', args: ['rowIndex', 'monthIndex', 'staffId', 'newValue'], callback: 'default'},
    {name: 'setSpreadsheetType', args: ['spreadsheetType', 'allocationGroupBy'], callback: 'default'},
    {name: 'setContextMenuPhaseRevenue', args: ['phase', 'monthIndex', 'revenue'], callback: 'default'},
    {name: 'setContextMenuPhaseStaffHours', args: ['phase', 'staffMemberId', 'monthIndex', 'hours'], callback: 'default'},
    {name: 'moveLeft', args: [], callback: 'default'},
    {name: 'moveRight', args: [], callback: 'default'},
    {name: 'submitAddRevenueRowForm', args: ['modal', 'project', 'phase'], callback: 'default'},
    {name: 'submitAddAllocationRowForm', args: ['modal', 'staffMember', 'project', 'phase'], callback: 'default'},
    {name: 'addRevenueRow', args: ['project'], callback: 'default'},
    {name: 'addAllocationRow', args: ['staffMember', 'project'], callback: 'default'},
    {name: 'cancelModal', args: ['modal'], callback: 'default'}
  ],
  dispatcher,
  function(action) {
  }
).actionsDict;




export function getUpdatedMilestones(currentMilestones, newValueMonthIndex, newValue, phaseFee) {
  /**
   * Return a new array of milestones, taking the array of milestones `currentMilestones`
   * and modifying the milestones in `newValueMonthIndex` so the total revenue for that month
   * is `newValue`.
   *
   * For each month:
   *
   *    If that month has milestones:
   *       Scale the revenue for those milestones to equal the new total
   *       (or divide evenly if the previous revenue for the milestones was zero).
   *    Else:
   *       Create a new milestone at the end of the month with the specified revenue.
   */

  let milestones = [];
  let cumulativeRevenue = 0;

  let monthToMilestoneLookup = {};

  // `revenueValues` is a mapping from month indexes to total milestone revenue
  // for the respective months. We start by calculating it from the existing
  // phase milestones and then set the value for `newValueMonthIndex` to
  // `newValue`.
  let revenueValues = {};
  let totalRevenue = 0;
  for (let m of currentMilestones) {
    let monthIndex = dateConverter.getMonthIndex(m.endDate);
    if (monthToMilestoneLookup[monthIndex] == null) {
      monthToMilestoneLookup[monthIndex] = [];
      revenueValues[monthIndex] = 0;
    }
    monthToMilestoneLookup[monthIndex].push(m);
    revenueValues[monthIndex] += m.revenue;
    totalRevenue += m.revenue;
  }

  revenueValues[newValueMonthIndex] = newValue;

  let months = Object.keys(revenueValues).map(k => parseInt(k));
  months.sort();

  const total = phaseFee || totalRevenue;

  for (let monthIndex of months) {
    const revenue = revenueValues[monthIndex];

    if (revenue !== 0) {
      // Assuming we aren't allowing negative revenue then we can only get here
      // if `totalRevenue > 0` so we don't need to worry about zero-dividing by
      // `totalRevenue`.
      let currentMonthMilestones = monthToMilestoneLookup[monthIndex];
      if (currentMonthMilestones != null) {
        let currentMonthRevenue = sum(currentMonthMilestones.map(m => m.revenue));
        let factor = revenue / currentMonthRevenue;
        for (let m of currentMonthMilestones) {
          let newRevenue = (currentMonthRevenue !== 0) ? (m.revenue * factor) : (revenue / currentMonthMilestones.length);
          cumulativeRevenue += newRevenue;
          milestones.push(new Milestone({
            endDate: m.endDate,
            percent: (cumulativeRevenue / total) * 100,
            revenue: newRevenue
          }));
        }
      }
      else {
        cumulativeRevenue += revenue;
        milestones.push(new Milestone({
          endDate: dateConverter.endOfMonthOffset(dateConverter.monthIndexToOffset(monthIndex)),
          percent: (cumulativeRevenue / total) * 100,
          revenue: revenue
        }));
      }
    }
  }

  return milestones;
}



export function cellQuantity(options) {
  /**
   * `quantity`: one of the keys of the `QUANTITY` object.
   * `timePeriod`: `null` or {startMonth: int | null, endMonth: int | null}.
   *
   * Uses organisationStore and milestoneStore global variables.
   */

  const {
    quantity: [quantityType, quantityQualifier],
    project = null,
    phase = null,
    staffMember = null,
    timePeriod = null,
    currentMonthIndex,
    timesheetData,
    now = moment()
  } = options;

  if (quantityQualifier === QUALIFIER.PROJECTED) {
    // startMonth -> current month - 1: use actuals.

    const actuals = (timePeriod.startMonth == null || timePeriod.startMonth < currentMonthIndex) ?
      cellQuantity({
        ...options,
        quantity: [quantityType, QUALIFIER.ACTUAL],
        timePeriod: {
          startMonth: timePeriod.startMonth,
          endMonth: timePeriod.endMonth != null ?
            Math.min(timePeriod.endMonth, currentMonthIndex - 1)
          : currentMonthIndex - 1
        },
      })
    : 0;

    // current month: use actuals or target, whichever is bigger.
    let thisMonth;
    if ((timePeriod.startMonth == null || timePeriod.startMonth <= currentMonthIndex)
        && (timePeriod.endMonth == null || timePeriod.endMonth >= currentMonthIndex)) {
      const tp = {startMonth: currentMonthIndex, endMonth: currentMonthIndex};
      thisMonth = Math.max(
        cellQuantity({...options, quantity: [quantityType, QUALIFIER.TARGET], timePeriod: tp}),
        cellQuantity({...options, quantity: [quantityType, QUALIFIER.ACTUAL], timePeriod: tp}),
      );
    }
    else {
      thisMonth = 0;
    }

    // current month + 1 -> end month: use target.
    const projected = (timePeriod.endMonth == null || timePeriod.endMonth >= currentMonthIndex) ?
      cellQuantity({
        ...options,
        quantity: [quantityType, QUALIFIER.TARGET],
        timePeriod: {
          startMonth: timePeriod.startMonth != null ?
            Math.max(currentMonthIndex + 1, timePeriod.startMonth)
          : currentMonthIndex + 1,
          endMonth: timePeriod.endMonth
        }
      })
    : 0;

    return actuals + thisMonth + projected;
  }

  if (quantityQualifier === QUALIFIER.VARIANCE) {
    const actuals = cellQuantity({...options, quantity: [quantityType, QUALIFIER.ACTUAL]});
    const target = cellQuantity({...options, quantity: [quantityType, QUALIFIER.TARGET]});
    return actuals - target;
  }

  if (quantityType === TYPE.REVENUE) {
    let total = 0;
    for (let cfi of iterRevenueItems(organisationStore, now, project, phase)) {
      const cfiMonthIndex = dateConverter.getMonthIndex(cfi.endDate);
      if ((quantityQualifier === QUALIFIER.TARGET && cfi.milestone == null)
          || (quantityQualifier === QUALIFIER.TARGET && cfi.project != null && cfi.milestone != null && !cfi.project.showProjections)
          || (quantityQualifier === QUALIFIER.ACTUAL && cfi.milestone != null)
          || (timePeriod.startMonth != null && cfiMonthIndex < timePeriod.startMonth)
          || (timePeriod.endMonth != null && cfiMonthIndex > timePeriod.endMonth)
          || (cfi.billingType !== "agreedFee")) {
        continue;
      }
      else {
        total += cfi.net;
      }
    }
    return total;
  }
  else {
    if (quantityQualifier === QUALIFIER.TARGET) {
      const startDate = (timePeriod.startMonth != null) ?
        dateConverter.monthIndexToOffset(timePeriod.startMonth)
      : -Infinity;

      const endDate = (timePeriod.endMonth != null) ?
        dateConverter.endOfMonthOffset(dateConverter.monthIndexToOffset(timePeriod.endMonth))
      : Infinity;

      let totalHours = 0;
      for (let [p, , , staffId, hours] of milestonesStore.iterItems(project, phase, null, staffMember, startDate, endDate)) {
        if (p.showProjections && !organisationStore.getStaffMemberById(staffId).isArchived) {
          totalHours += hours;
        }
      }
      return totalHours;
    }
    else {
      let totalHours = 0;
      for (let {monthIndex, staffMember: sm, project: p, projectPhase: pp, hours} of timesheetData.iterate()) {
        if ((staffMember == null || areSameDbObjects(staffMember, sm))
            && (project == null || areSameDbObjects(project, p))
            && (phase == null || areSameDbObjects(phase, pp))
            && (timePeriod.startMonth == null || monthIndex >= timePeriod.startMonth)
            && (timePeriod.endMonth == null || monthIndex <= timePeriod.endMonth)) {
          totalHours += hours;
        }
      }
      return totalHours;
    }
  }
}


export function * iterRevenueItems(organisationStore, now, project = null, phase = null) {
  /**
   * Yields a sequence of `CashFlowItem`s.
   *
   * This method doesn't care about the state of the projects in question. It's
   * up to the caller to filter out the `CashFlowItem`s it doesn't want based
   * on the project states.
   */
  let changeLogs, phaseLists;
  if (project != null && phase != null && phase.id != null && phase.id !== -1) {
    changeLogs = [phase.project.changeLog];
    phaseLists = [[phase]];
  }
  else if (project != null) {
    changeLogs = [project.changeLog];
    phaseLists = [];
  }
  else {
    changeLogs = chain(organisationStore.projects.map(p => p.changeLog));
    phaseLists = chain(organisationStore.projects.map(p => p.getVisiblePhases()));
  }

  for (let changeLog of changeLogs) {
    for (let cli of changeLog) {
      if (cli.revenue <= 0
          || (phase != null && phase.id != null && (cli.phase == null || cli.phase.id !== phase.id))
          //TODO-next_scheduler_fiddling clean this up
          || (project != null && phase != null && phase.id == null && (cli.phase != null && cli.phase.id != null))
          || (project != null && (cli.project == null || cli.project.id !== project.id))) {
        continue;
      }
      yield cli.toCashFlowItem();
    }
  }

  for (let phaseList of phaseLists) {
    for (let p of phaseList) {
      for (let m of p.milestones) {
        yield m.toCashFlowItem();
      }
    }
  }
}


export function getRevenueCell(row, cellIndex, startMonth) {
  /**
   * `cellIndex` is a cell index as reported by `handleCellSelected` (0-based,
   * where the 0th column is the title column.
   */
  return row.getCell(cellIndex);
}


function makeRevenueCellFormatter(styles) {
  const style = {textAlign: 'right'};
  return function({row, column, value}) {
    //const isEditable = column.editable(row);
    return <div style={{...style, ...styles}}>
      {Math.round(value.total)}
    </div>;
  };
}

const pastMonthCellFormatter = makeRevenueCellFormatter({opacity: 0.8, fontStyle: 'italic'});
const currentMonthCellFormatter = makeRevenueCellFormatter({fontWeight: 'bold'});
const futureMonthCellFormatter = makeRevenueCellFormatter();


function allocationCellFormatter({row, column, value}) {
  //const isEditable = column.editable(row);
  return <div style={{textAlign: 'right'}}>
    {Math.round(value)}
  </div>;
}


function myHeaderRenderer({column}) {
  function makeButton(direction) {
    return <button
        className="btn btn-sm btn-default"
        style={{padding: '1px 7px'}}
        onClick={() => store.moveBy(direction === 'left' ? -1 : +1)}>
      {direction === 'left' ?
        <i className="fa fa-chevron-left" style={{margin: 0}} />
      : <i className="fa fa-chevron-right" style={{margin: 0}} />
      }
    </button>;
  }

  return <div
      style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}>
    {column.name === 'moveLeftColumn' ?
      makeButton('left')
    : column.name === 'moveRightColumn' ?
      makeButton('right')
    :
      column.name
    }
  </div>;
}


export function monthColumns(startMonth, endMonth, now, mode, checker) {
  const startDate = dateConverter.monthIndexToMoment(startMonth);
  const currentMonth = dateConverter.getMonthIndex(dateConverter.momentToInt(now));
  const numMonths = endMonth - startMonth + 1;
  const isAllocation = (mode === 'allocation');

  return Array.from(
    imap(range(numMonths), function(i) {
      const monthIndex = startMonth + i;

      return {
        key: monthIndex,
        name: moment(startDate.clone().add(i, 'months')).format("MMM YY"),
        width: parseInt(CONTENT_WIDTH) / 12 + '%',

        headerRenderer: myHeaderRenderer,
        formatter: isAllocation ?
          allocationCellFormatter
        : (
          (monthIndex > currentMonth) ?
            futureMonthCellFormatter
          : (monthIndex === currentMonth) ?
            currentMonthCellFormatter
          : pastMonthCellFormatter
        ),
        editor: isAllocation ? MyEditor : RevenueCellEditor,
        editable: function(row) {
          /**
           * All cells require at least project manager permission to be editable. In addition,
           * revenue cells are only editable if they're not in the past.
           */
          const phase = row.get('phase');
          if (phase == null || phase.id == null || phase.id === -1) {
            return false;
          }
          if (!isAllocation && monthIndex < currentMonth) {
            return false;
          }
          return PermissionLevel.isAtLeast(checker.checkProject(row.get('project')), PermissionLevel.projectManager);
        }
      };
    })
  );
}


export function navigatorColumns(startMonth, endMonth, now, groupedColumns, mode, checker) {
  function makeNavigationColumn(key) {
    return {
      key: key,
      name: key,
      formatter: function() { return null; },
      headerRenderer: myHeaderRenderer,
      width: NAVIGATION_COLUMN_WIDTH
    };
  }

  return Array.from(chain(
    [
      itemColumn(groupedColumns),
      makeNavigationColumn('moveLeftColumn')
    ],
    monthColumns(startMonth, endMonth, now, mode, checker),
    [
      makeNavigationColumn('moveRightColumn')
    ]
  ));
}


function bodyColumns(startMonth, endMonth, now, groupedColumns, mode, checker) {
  return Array.from(chain(
    [
      itemColumn(groupedColumns),
    ],
    monthColumns(startMonth, endMonth, now, mode, checker),
  ));
}


function getRevenueColumns(startMonth, endMonth, now, checker) {
  return bodyColumns(startMonth, endMonth, now, ['project'], 'revenue', checker);
}


function getAllocationColumns(startMonth, endMonth, now, checker) {
  return bodyColumns(startMonth, endMonth, now, ['project', 'phase'], 'allocation', checker);
}
