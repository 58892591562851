import { Operations } from "./Operations";
import is from "@sindresorhus/is/dist";

export const FormulaNode = (type, inputs, matchString, contextRef) => {
			let value, dependencies;
			try {
				value = Operations[type](inputs, contextRef);
			} catch (err) {
				if (is.error(err)) console.error(err);
				value = err;
			}
			if (!value.dependencies?.size) {
				let depArray = [];
				Object.values(inputs).forEach((i) => {
					i?.dependencies && depArray.push(...i.dependencies);
				});
				dependencies = new Map(depArray)
			}
			const node = {
				op: type,
				params: inputs,
				matchString: matchString,
				...value,
				dependencies:
					value.dependencies?.size ? value.dependencies : dependencies,
			};
			Object.values(inputs).forEach(i => is.plainObject(i) && (i.parent = node))
			return node;
		}