import React from "react";
// import CreateReactClass from "create-react-class";
// import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { Modal, ModalContent } from "../modal.js";
// import { TriStateSaveButton } from "../widgets.js";
// import { BasicMySelect2, DateValue } from "../widgets/generic.js";
// import { NumberInputContainer } from "../widgets/NumberInputContainer.js";
// import { projectStore, actions } from "./flux.js";

export const AutoAdjustRevenueModal = ({ onConfirm, onCancel }) => {
	return (
		<Modal className="sync-modal">
			<ModalContent
				header="Saving project"
				width="48em"
				bodyStyles={{ padding: 0 }}
			>
				<div style={{ padding: "2em" }}>
					<p>
						This will auto adjust your projected revenue by taking
						the remaining fee (fee - invoices) and spreading it out
						between now and the end date of each project phase.
					</p>
					<p>
						Note, you will need to save after adjusting the
						forecast.
					</p>
				</div>
				<div
					style={{
						borderTop: "1px solid #ccc",
						textAlign: "right",
						padding: "1em",
					}}
				>
					<a href="javascript:void(0)" onClick={onCancel}>
						Cancel
					</a>

					<button
						className="btn btn-primary"
						onClick={onConfirm}
						style={{ marginLeft: "1em" }}
					>
						Adjust
					</button>
				</div>
			</ModalContent>
		</Modal>
	);
};
