import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { dateConverter } from '../models/dateconverter.js';
import { sum } from '../utils.js';
import { NumberInputContainer, ProjectPhaseSelector } from '../widgets.js';
import { Link } from 'react-router';
import classNames from 'classnames';
import { spreadsheetStore, actions } from './SpreadsheetStore.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { ProgressBar } from './ProgressBar.js';
import { organisationStore } from '../organisation.js';
import { permissions, requiresPermission, OperationLevel, UserLevel } from '../models/permissions.js';
import { userStore } from '../user.js';


export var ProjectHistoryMenu = requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: ({project}) => permissions.canEditProject(project),
    financialsPermission: ({project}) => permissions.projectFinancialsBudgets(project),
    hoursPermission: ({project}) => permissions.editProjectBudgets(project),
    deletePermission: ({project}) => permissions.projectAdmin(project),
    isAtLeastAdmin: ({project}) => permissions.projectAdmin(project),
    isAtLeastPM: ({project}) => permissions.projectManager(project),
  },
  CreateReactClass({
    mixins: [
      makeMultipleStoreMixin([spreadsheetStore, userStore], function() {
        return {
          cell: spreadsheetStore.selectedCell,
          cashflowItems: spreadsheetStore.getSelectedCashflowItems(),
          projectData: spreadsheetStore.projectData,
          currentMonth: spreadsheetStore.currentMonthIndex,
          displayedData: spreadsheetStore.dataType,
          selectedPhases: {},
          currencyFormatter: organisationStore.organisation.currencyFormatter,
        };
      })
    ],

    componentWillMount() {
      this.timelineItems = {}
    },
    
    componentDidUpdate(prevProps, prevState) {
      const thisMonth = this.state.cell.monthIndex;
      this.timelineItems[thisMonth].scrollIntoView(true);
    },

    render() {
      const currentMonth = this.state.currentMonth;
      const cell = this.state.cell;
      const selectedMonth = cell.monthIndex;
      const isPhase = cell.projectPhaseUuid !== undefined;
      const uuid = cell.projectPhaseUuid ? cell.projectPhaseUuid : cell.projectUuid ? cell.projectUuid : null;
      const data = this.state.projectData.get(uuid);

      const project = data.project ? data.project : null;
      const phase = data && data.phase ? data.phase : null;

      const item = phase || project;

      if (item) {
        let months = data.revenueData.cfiMonths.sort((a,b) => a-b);
        const minMonth = Math.min(selectedMonth, item.startMonthIndex, _.min(months))
        const maxMonth = Math.max(selectedMonth, item.endMonthIndex, _.max(months))

        this.monthInputs = {}
        let history = {}
        let agreedFeeToDate = 0;
        let allMonths = []
        for (let monthIndex = minMonth; monthIndex <= maxMonth; monthIndex++) {
          allMonths.push(monthIndex)
          agreedFeeToDate = data.revenueData[monthIndex] ? data.revenueData[monthIndex].agreedFeeToDate : agreedFeeToDate;
          history[monthIndex] = this.renderCfiMonth(monthIndex, data.revenueData[monthIndex] ? data.revenueData[monthIndex].cashflowItems : [], data.revenueData[monthIndex], item, agreedFeeToDate);
        }
        return (
          <div className="project-history-menu">
            <div className="project-history-menu__header" style={{borderBottom: '1px solid #ccc'}}>
              <div style={{fontSize: '1.5em'}}>{project.getTitle()}</div>
              {isPhase ? <div>{phase.getTitle()}</div> : null}
              {false && this.props.isEditable && (project.status === "prospective" || project.status === "onHold") ?
                <div className="flexbox-container" style={{marginTop: '0.5em'}}>
                  <span>Percentage Likelihood:</span>
                  <NumberInputContainer
                    value={project.likelihood}
                    isEditable={true}
                    onBlur={(inputText) => actions.changeLikelihood(project.uuid, inputText)}
                    style={{fontSize: '0.8em', minHeight: '0.8em', width: '5em', marginLeft: '0.5em'}}
                  />
                </div>
              : null}
            </div>
            <div className="project-history-menu__timeline">
              {/*<TimelineItem past={maxMonth+1 < currentMonth} style={{boxShadow: 'inset 0px 20px 25px -5px #fff'}}>
                <button onClick={() => actions.moveRight()}>Next Month...</button>
              </TimelineItem>*/}
              {allMonths.sort((a,b) => b-a).map(m => history[m])}
              {/*<TimelineItem past={minMonth-1 < currentMonth} style={{boxShadow: 'inset 0px -10px 25px -5px #fff'}}>
                <button onClick={() => actions.moveLeft()}>Previous Month...</button>
              </TimelineItem>*/}
            </div>
          </div>
        );
      } else {
        return <div>error</div>
      }
    },

    renderCfiMonth(monthIndex, cfis=[], data, item, agreedFeeToDate) {
      const currencyFormatter = this.state.currencyFormatter;
      const cell = this.state.cell;
      const project = item.project || item;

      const selectedMonth = cell.monthIndex;
      const isPhase = cell.projectPhaseUuid !== undefined;
      const displayedData = this.state.displayedData;
      const currentMonth = this.state.currentMonth;

      const phases = _.uniq(cfis.map(cfi => cfi.phase ? cfi.phase.uuid : 0));
      const phaseHistory = phases.map(phaseUuid => {
        const phaseCfis = cfis.filter(cfi => cfi.phase ? cfi.phase.uuid === phaseUuid : phaseUuid === 0);
        const phase = phaseCfis[0].phase ? phaseCfis[0].phase : project.getProjectNoPhase();
        return this.renderCfiPhase(phase, phaseCfis, monthIndex);
      })

      const monthString = dateConverter.monthIndexToMoment(monthIndex).format('MMM YY');
      const currentProgress = (agreedFeeToDate/item.fee)*100;
      const prevProgress = ((agreedFeeToDate-(data ? data.agreedFeeTotal : 0))/item.fee)*100;

      const revenueString = `${data ? currencyFormatter.format(data.total) : '$0'}`;
      const progressString = Number.isFinite(currentProgress) ? `(${Math.round(currentProgress)}%)`:'';

      return <TimelineItem
        selected={monthIndex == selectedMonth}
        past={monthIndex < currentMonth}
        refFunc={el => this.timelineItems[monthIndex] = el}
      >
        <div>
          <div>
            <h4 style={{display: 'inline-block', marginRight: '0.5em'}}>{`${monthString}: ${revenueString}`}</h4>
            <h4 style={{display: 'inline-block', color: currentProgress > 100 ? '#f25a2a' : 'inherit' }}>{` ${progressString}`}</h4>
          </div>
          {Number.isFinite(prevProgress) && Number.isFinite(currentProgress) ?
            <div style={{paddingLeft: '1em'}}><ProgressBar fromPercentage={prevProgress} toPercentage={currentProgress} height="0.4em" /></div>
          : null}
          <div style={{padding: '0 2em'}}>{isPhase ? this.renderCfiPhase(item, cfis || [], monthIndex, false) : phaseHistory}</div>
          {this.props.isEditable && !isPhase && (displayedData === "projected" || (displayedData === "actualsProjected" && monthIndex >= currentMonth)) ?
            <div className="flexbox-container" style={{margin: '1em 0 0 2em'}} >
            <ProjectPhaseSelector
              style={{fontSize: '0.8em', marginRight: '0.5em', maxWidth: '16em'}}
              project={project}
              phase={this.state.selectedPhases[monthIndex]}
              allowNull={false}
              placeholder="(Select Phase)"
              onChange={(phase) => {
                let selectedPhases = this.state.selectedPhases;
                this.state.selectedPhases[monthIndex] = phase;
                this.setState({selectedPhases: selectedPhases})
              }}
            />
            <button
              className="btn btn-default"
              style={{fontSize: '0.8em', padding: '0.4em 0.8em'}}
              onClick={() => actions.addSidebarPhase(this.state.selectedPhases[monthIndex].uuid, monthIndex)}
            >
              + Add
            </button>
            </div>
          : null}
        </div>
      </TimelineItem>
    },

    renderCfiPhase(phase, cfis, monthIndex, showTitle=true) {
      const currencyFormatter = this.state.currencyFormatter;
      const displayedData = this.state.displayedData;
      const projectData = this.state.projectData;
      const currentMonth = this.state.currentMonth;
      const revData = projectData.get(phase.uuid) ? projectData.get(phase.uuid).revenueData[monthIndex] : null;
      const phaseCfis = revData ? revData.cashflowItems : [];

      const prevPercentProgress = phase && phase.fee && revData ? Math.round(((revData.agreedFeeToDate - revData.agreedFeeTotal) / phase.fee)*100) : null;
      const currentPercentProgress = phase && phase.fee && revData ? Math.round((revData.agreedFeeToDate / phase.fee)*100) : null;

      const error = currentPercentProgress > 100;

      const milestones = cfis.filter(cfi => cfi.milestone);
      const invoices = _.uniq(cfis.filter(cfi => cfi.invoice).map(cfi => cfi.invoice.id));
      const expenses = cfis.filter(cfi => cfi.expense).map(cfi => cfi.expense.uuid);

      const cfiMilestones = this.renderCfiMilestone(milestones, monthIndex, phase ? phase.uuid : -1);

      const cfiInvoices = invoices.map(iId => {
        return this.renderCfiInvoice(cfis.filter(cfi => cfi.invoice && cfi.invoice.id === iId));
      })
      const cfiExpenses = expenses.map(eId => {
        return this.renderCfiExpense(cfis.filter(cfi => cfi.expense && cfi.expense.uuid === eId));
      })
      const cfiChangeLog = this.renderCfiChangeLog(cfis.filter(cfi => cfi.isChangeLogItem));
      const displayItem = displayedData === "projected" || (displayedData === "actualsProjected" && monthIndex >= currentMonth) || ((revData ? revData.total : 0) !== 0);
      if (displayItem) {
        return <div style={{marginTop: '1em'}}>
          {showTitle ?
            <div>
              <span style={{fontWeight: 'bold'}}>{phase.getTitle()}:</span>
              <span>{` ${currencyFormatter.format(revData ? revData.total : 0)} `}</span>
              <span className={classNames({error: error})}>{currentPercentProgress ? `(${currentPercentProgress}%)` : ''}</span>
              {Number.isFinite(prevPercentProgress) && Number.isFinite(currentPercentProgress) ?
                <ProgressBar fromPercentage={prevPercentProgress} toPercentage={currentPercentProgress} height='0.25em' />
              : null}
            </div>
          : null}
          {phase && phase.id > 0 && (displayedData === "projected" || (displayedData === "actualsProjected" && monthIndex >= currentMonth)) ? cfiMilestones : null}
          {cfiInvoices}
          {cfiChangeLog}
          {cfiExpenses}
        </div>
      }
    },

    renderCfiMilestone(cfis, monthIndex, uuid) {
      const currencyFormatter = this.state.currencyFormatter;
      const displayedData = this.state.displayedData;
      const currentMonth = this.state.currentMonth;
      const cfiTotal = cfis.length ? sum(cfis.map(cfi => cfi.fee)) : 0;
      return <div style={{marginLeft: '1em'}}>
          <span>Projected: </span>
        {this.props.isEditable && (displayedData === "projected" || (displayedData === "actualsProjected" && monthIndex >= currentMonth)) ?
            <span>
              <NumberInputContainer
              value={Math.round(cfiTotal)}
              isEditable={true}
              onBlur={(inputText) => actions.adjustSidebarMilestone(uuid, monthIndex, inputText)}
              style={{fontSize: '0.8em', minHeight: '0.8em', width: '8em', marginRight: '0.5em'}}
              />
              <button
                onClick={() => actions.removePhaseMilestonesInMonth(uuid, monthIndex)}
                style={{fontWeight: 'bold', fontSize: '0.8em', padding: '0.2em 0.7em'}}
              >
                x
              </button>
            </span>
          : `${currencyFormatter.formatWithCents(cfiTotal)}`}
        </div>
    },
    renderCfiInvoice(cfis) {
      const currencyFormatter = this.state.currencyFormatter;
      const invoice = cfis[0].invoice;
      const agreedFee = sum(cfis.filter(cfi => cfi.billingType === 'agreedFee').map(cfi => cfi.fee - cfi.spend));
      const variation = sum(cfis.filter(cfi => cfi.billingType === 'variation').map(cfi => cfi.fee - cfi.spend));
      const reimbursement = sum(cfis.filter(cfi => cfi.billingType === 'reimbursement').map(cfi => cfi.fee - cfi.spend));
      const value = agreedFee + variation + reimbursement;
      if (value !== 0) {
        return <div style={{marginLeft: '1em'}}>
          <div><Link to={invoice.path}>{`Invoice: ${invoice.description}`}</Link></div>
          {agreedFee ? <div style={{marginLeft: '1em'}}>{`Agreed Fee: ${currencyFormatter.formatWithCents(agreedFee)}`}</div> : null}
          {variation ? <div style={{marginLeft: '1em'}}>{`Variation: ${currencyFormatter.formatWithCents(variation)}`}</div> : null}
          {reimbursement ? <div style={{marginLeft: '1em'}}>{`Reimbursement: ${currencyFormatter.formatWithCents(reimbursement)}`}</div> : null}
        </div>
      }
    },
    renderCfiExpense(cfis) {
      const currencyFormatter = this.state.currencyFormatter;
      return cfis.map(cfi => {
        const value = cfi.fee-cfi.spend;
        if (value !== 0) {
          return <div style={{marginLeft: '1em'}}>
            {`Expense: ${currencyFormatter.formatWithCents(cfi.fee-cfi.spend)}`}
          </div>
        }
      });
    },

    renderCfiChangeLog(cfis) {
      const currencyFormatter = this.state.currencyFormatter
      return cfis.map(cfi => {
        const value = cfi.fee-cfi.spend;
        if (value !== 0) {
          return <div style={{marginLeft: '1em'}}>
            {`ChangeLog: ${currencyFormatter.formatWithCents(cfi.fee-cfi.spend)}`}
          </div>
        }
      });
    },
  })
);

var TimelineItem = CreateReactClass({
  render: function() {
    const hasTitle = this.props.title;
    return <div style={{
        padding: '0 1em'
      }}
      ref={this.props.refFunc}
      className={classNames('timeline-item', {'selected': this.props.selected}, {'past': this.props.past})}>
      <div style={{borderLeft: '4px #a7a7a7 solid', padding: '1em 0', ...this.props.style}}>
        <div style={{padding: '2em 0 2em 1em', marginLeft: '-1em'}}>
          <i className="fa fa-circle" style={{float: 'left', marginLeft: '-0.55em', marginTop: '0.2em'}} />
          <div>{this.props.children}</div>
        </div>
      </div>
    </div>;
  }
});
