import moment from 'moment';
import _ from 'underscore';


export const DataObject = class {
			constructor(options) {
				options = options || {};
				var defaults = this.defaults();
				for (var k in defaults) {
					this[k] =
						options[k] !== undefined ? options[k] : defaults[k];
				}
			}

			apiTypeName() {
				throw new Error("Not implemented");
			}

			defaults() {
				return {
					id: null
				};
			}

			static fieldTypes() {
				return null;
			}

			isSaved() {
				return this.id != null;
			}

			setup(data) {
				throw new Error("Not implemented");
			}

			getData() {
				/**
				 * Return a JSON object to be sent to the server to save this object.
				 */
				throw new Error("Not implemented");
			}

			static transformArgs(objectData, organisation) {
				var fields = this.fieldTypes();
				if (fields != null) {
					var args = {};
					_.each(objectData, function(v, k) {
						if (v != null) {
							var fieldType = fields[k];

							if (fieldType === "date") {
								args[k] = moment(v, "YYYY-MM-DD");
							} else if (fieldType === "datetime") {
								args[k] = moment(v, "YYYY-MM-DDTHH:mm:ss");
							} else if (fieldType === "duration") {
								args[k] = v;
							} else if (
								k.match(/Id$/) &&
								fields[k.replace(/Id$/, "")] === "Project"
							) {
								args["project"] = organisation.getProjectById(
									v
								);
							} else if (
								k.match(/Id$/) &&
								fields[k.replace(/Id$/, "")] === "ProjectPhase"
							) {
								args[
									"phase"
								] = organisation.getProjectPhaseById(v);
							} else if (
								k.match(/Id$/) &&
								fields[k.replace(/Id$/, "")] === "Contact"
							) {
								args["contact"] = organisation.getContactById(
									v
								);
							} else if (_.isArray(fieldType)) {
								var itemType = fieldType[0];
								if (itemType === "Milestone") {
									let Milestone = require("./milestone.js")
										.Milestone;
									itemType = Milestone;
								}
								args[k] = v.map(item =>
									itemType.fromJson(item, organisation)
								);
							} else {
								args[k] = v;
							}
						} else {
							args[k] = null;
						}
					});
				} else {
					args = objectData;
				}

				return args;
			}

			static fromJson(jsonObject, organisation) {
				// Remember that in a static method `this` refers to the class.
				return new this(this.transformArgs(jsonObject, organisation));
			}

			/**
			 * Subclasses should provide:
			 *
			 * `copy({cloneIdentity = true} = {})`:
			 *
			 *    `copy()` -- returns a copy of the object, including identity properties,
			 *      so that saving the copy to the server is equivalent to saving the original
			 *      to the server.
			 *
			 *    `copy({cloneIdentity: false})` -- returns a copy of the object with blank
			 *      identity properties, so saving the copy to the server will create a new
			 *      object identical to the original object.
			 *
			 *    `clone()` -- equivalent to `copy({cloneIdentity: true})`.
			 *
			 * Currently `DataObject` base class doesn't really help with this.
			 *
			 * Also this (now historical) semantics is kinda dumb; clone is entirely
			 * redundant, it's just copy but without the possibility of overriding
			 * `cloneIdentity`. Maybe fix this at some point.
			 */

			copy() {
				var self = this;
				var cpy = new this.constructor();

				for (var prop in self) {
					cpy[prop] = _.clone(self[prop]);
				}
				return cpy;
			}

			serialize() {
				var self = this;
				var args = {};
				var fieldTypes = this.constructor.fieldTypes();
				_.each(this.constructor.fieldsForSerialize(), function(
					fieldName
				) {
					var v = self[fieldName];
					if (v == null && !fieldName.match(/Id$/)) {
						args[fieldName] = null;
					} else {
						var fieldType = fieldTypes[fieldName];

						if (fieldName.match(/Id$/)) {
							var val =
								self[fieldName.substr(0, fieldName.length - 2)];
							args[fieldName] = val != null ? val.id : null;
						} else if (fieldType === "date") {
							args[fieldName] =
								v != null ? v.format("YYYY-MM-DD") : null;
						} else if (fieldType === "datetime") {
							args[fieldName] =
								v != null
									? v.format("YYYY-MM-DDTHH:mm:ss")
									: null;
						} else if (
							fieldType === "int" ||
							fieldType === "number"
						) {
							args[fieldName] = serializeDecimal(v);
						} else if (
							fieldType === "Project" ||
							fieldType === "ProjectPhase" ||
							fieldType === "Contact"
						) {
							args[fieldName + "Id"] = v.id;
						} else if (_.isArray(fieldType)) {
							args[fieldName] = v.map(item => item.serialize());
						} else {
							args[fieldName] = v;
						}
					}
				});
				return args;
			}

			setFieldValidity(field, isValid) {
				if (isValid) {
					delete this.errors[field];
				} else {
					this.errors[field] = true;
				}
				return isValid;
			}

			refersToSameObject(other) {
				return (
					this.id != null &&
					other.constructor === other.constructor &&
					other.id === this.id
				);
			}
		};



function serializeDecimal(val) {
  if (_.isString(val)) {
    return val.replace(/,/g, '');
  }
  else {
    return val;
  }
};
