
import React from 'react';
import CreateReactClass from 'create-react-class';
import moment from "moment";
import { organisationStore } from '../../../organisation.js';
import { dateConverter } from '../../../models/dateconverter.js';
import { projectForecastsStore, actions } from '../ProjectForecastsStore';
import { Cell } from '../../../newSpreadsheet/Spreadsheet';
import classNames from 'classnames'
import { getGradient } from '../../../spreadsheet/gradient.js';

export const TimesheetCell = class extends React.Component {

    shouldComponentUpdate(nextProps) {
        if (
            this.props.isEditing != nextProps.isEditing
            || this.props.isSelected != nextProps.isSelected
            || this.props.cell.inputText != nextProps.cell.inputText
            || this.props.cell.value != nextProps.cell.value
            || this.props.column.monthIndex != nextProps.column.monthIndex
            || this.props.cell.visible != nextProps.cell.visible
            || this.props.cell.expanded != nextProps.cell.expanded
            || this.props.cell.expandable != nextProps.cell.expandable
            || this.props.cell.inRange != nextProps.cell.inRange
        ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment()));
        const isRowHeader = this.props.cell.isRowHeader;
        const cellType = this.props.cell.cellType;
        const cell = this.props.cell;
        const isColumnHeader = this.props.cell.isColumnHeader;
        const value = this.props.cell.value;
        const numValue = this.props.cell.numValue;
        const isEditable = !((this.props.cell.isEditable === false) || isRowHeader || isColumnHeader);

        let displayValue;
        if (this.props.isEditing) {
            displayValue = this.props.cell.inputText;
        }
        else {
            if (value != null && value.toFixed != null) {
                displayValue = value.toFixed(0);
            }
            else if (cellType === 'title') {
                displayValue = <span>
                    {cell.addAllocationButton ?
                        <i
                            className={`fa fa-plus`}
                            onClick={() => actions.clickAddAllocationButton(cell.row)} />
                        : null}
                    {cell.hideable ?
                        <i
                            className={`fa fa-eye${!cell.visible ? '-slash' : ''}`}
                            onClick={() => actions.toggleStaffVisibility(cell.uuid)} />
                        : null}
                    {cell.expandable ?
                        <i
                            className={`fa fa-chevron${!cell.expanded ? '-right' : '-down'}`}
                            onClick={() => actions.toggleStaffExpand(cell.uuid)} />
                        : null}
                    {/*<span>{p.likelihood}</span>*/}
                    <span>{value}</span>
                </span>
            }
            else {
                displayValue = value;
            }
        }
        return <Cell
            ref="input"
            {...this.props}
            value={displayValue}
            cellProps={{
                className: classNames({
                    editable: isEditable,
                    rowheader: isRowHeader,
                    columnheader: isColumnHeader,
                    pasttime: this.props.column.monthIndex && this.props.column.monthIndex < currentMonthIndex,
                    currenttime: this.props.column.monthIndex && this.props.column.monthIndex === currentMonthIndex,
                    futuretime: this.props.column.monthIndex && this.props.column.monthIndex > currentMonthIndex,
                    negative: this.props.cell.error,
                    // neutral: numValue === 0,
                    notvisible: this.props.cell.visible === false,
                    project: this.props.cell.isProject,
                    scheduleinrange: this.props.cell.inRange,
                }),
                style: {
                    ...(cell.percent > 0 ? getGradient(cell.percent / 100) : {}),
                    ...{ mixBlendMode: this.props.isSelected ? 'normal' : 'multiply' },
                }
            }}
        />;
    }

    get input() {
        return this.refs.input.input;
    }
}