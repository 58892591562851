import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { actions as organisationActions } from '../organisation.js';
import { organisationStore } from '../organisation.js';
import { store } from './flux.js';
import { MilestonesPage } from './MilestonesPage.js';
import { PageLoadingSpinner } from '../widgets.js';

import PropTypes from "prop-types";
export { MilestonesPage } from "./MilestonesPage.js";


export var MilestonesPageDemoWrapper = CreateReactClass({
  /**
   * `MilestonesPage` is designed to be inside `Dashboard`, but in the demo it isn't,
   * so we have a different wrapper for that.
   */
  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {organisation: organisationStore};
    })
  ],

  render: function() {
    if (!this.state.organisation.isReady) {
      return null;
    }
    else {
      return (
        <div className="content container new-dashboard">
          <MilestonesPage organisation={this.state.organisation} />
        </div>
      );
    }
  }
});


export var MilestonesStoreWrapper = CreateReactClass({
  propTypes: {
    component: PropTypes.func.isRequired,
    loadingMessage: PropTypes.string
  },

  mixins: [
    makeMultipleStoreMixin([store, organisationStore], function() {
      return {
        isReady: (
          organisationStore.organisation != null
          && organisationStore.organisation.settings != null
          && store.isReady
        )
      };
    })
  ],

  componentWillMount: function() {
    if (store.isReady && store.graphMode === 'actuals') {
      store.setGraphMode('projected', {update: false});
    }
  },

  render: function() {
    if (!this.state.isReady) {
      if (this.props.loadingMessage != null) {
        return (
          <div style={{marginTop: '7em'}}>
            <div className="flex-1-0-auto" style={{textAlign: 'center'}}>
              <PageLoadingSpinner text={this.props.loadingMessage} />
            </div>
          </div>
        );
      }
      else {
        return null;
      }
    }
    else {
      return React.createElement(this.props.component);
    }
  }
});








