import { invoiceErrorCodes } from './InvoicePageStore.js';


export const errorMessages = {
  [invoiceErrorCodes.loadInvoice]: 'loading this invoice',
  [invoiceErrorCodes.accountingSystemAuthorise]: 'connecting to your accounting system',
  [invoiceErrorCodes.retrieveTimesheetEntries]: 'retrieving these timesheet entries',
  [invoiceErrorCodes.saveTimesheetEntries]: 'saving these timesheet entries',
  [invoiceErrorCodes.saveInvoice]: 'saving this invoice',
  [invoiceErrorCodes.deleteInvoice]: 'deleting this invoice',
  [invoiceErrorCodes.syncInvoice]: 'copying this invoice to your accounting system',
  [invoiceErrorCodes.getAccountingSystemData]: 'retrieving data from your accounting system',
  [invoiceErrorCodes.saveAccountingSystemSettings]: 'saving your accounting settings',
  [invoiceErrorCodes.disconnect]: 'disconnecting from your accounting system'
};

