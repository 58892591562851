import React from 'react';
import CreateReactClass from 'create-react-class';
import PropTypes from "prop-types";


export var ProgressBar = CreateReactClass({
  propTypes: {
    fromPercentage: PropTypes.number,
    toPercentage: PropTypes.number,
  },

  render: function() {
    const fromPercentage = this.props.fromPercentage || 0;
    const toPercentage = this.props.toPercentage != null ? this.props.toPercentage : this.props.fromPercentage;

    return (
      <div style={{width: '100%', height: "0.5em", backgroundColor: '#aaa', position: 'relative'}}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: Math.min(fromPercentage, 100) + '%',
          height: '100%',
          backgroundColor: fromPercentage <= 100 ?  'gold' : '#f05a28'
        }} />
        {fromPercentage < 100 && toPercentage > fromPercentage ?
          <div style={{
            position: 'absolute',
            top: 0,
            left: fromPercentage + '%',
            width: Math.min((toPercentage - fromPercentage), 100 - fromPercentage) + '%',
            height: '100%',
            background: toPercentage <= 100 ?
              'repeating-linear-gradient(135deg, gold, gold 3px, #aaa 3px, #aaa 6px)'
            : 'repeating-linear-gradient(135deg, gold, gold 3px, #f05a28 3px, #f05a28 6px)',
            borderRight: toPercentage <= 100 ?
              'solid 2px gold'
            : 'solid 2px #f05a28'
          }} />
        : null}
      </div>
    );
  }
});
