import { multiplyDurationBy, simplifyDuration, multiplyRateBy, simplifyRate, multiplyRateByDuration, multiplyDurations } from "@ryki/datemath";

export default ({ left, right }, context) => {
	const multiplierId = left.type + right.type;
	if (!multiplierValues[multiplierId]) {
		throw {
			value: `Cannot multiply a ${left.type} with a ${right.type}.`,
			type: "error",
		};
	}
	return ({
		type: multiplierTypes[multiplierId],
		value: multiplierValues[multiplierId](left, right),
	});
};

const multiplierTypes = {
	numbernumber: "number",
	textnumber: "text",
	numbertext: "text",
	numberduration: "duration",
	durationnumber: "duration",
	numberrate: "rate",
	ratenumber: "rate",
	durationrate: "number",
	rateduration: "number",
	durationduration: "powerDuration",
};

const multiplierValues = {
	numbernumber: (left, right) => left.value * right.value,
	textnumber: (left, right) => left.value.repeat(right.value),
	numbertext: (left, right) => right.value.repeat(left.value),
	numberduration: (left, right) => {
		return simplifyDuration(multiplyDurationBy(right.value, left.value));
	},
	durationnumber: (left, right) => {
		return simplifyDuration(multiplyDurationBy(left.value, right.value));
	},
	numberrate: (left, right) => {
		return simplifyRate(multiplyRateBy(right.value, left.value));
	},
	ratenumber: (left, right) => {
		return simplifyRate(multiplyRateBy(left.value, right.value));
	},
	durationrate: (left, right) => {
		return multiplyRateByDuration(right.value, left.value);
	},
	rateduration: (left, right) => {
		return multiplyRateByDuration(left.value, right.value);
	},
	durationduration: (left, right) => {
		return multiplyDurations(left.value, right.value);
	},
};