import _ from 'underscore';
import { store as milestonesStore } from './milestones/flux.js';
import { projectStore } from './project/flux.js';
import { projectsPageStore } from './project/ProjectsPageStore.js';
import { staffMembersPageStore, staffStore } from './staff/flux.js';
import { billingPageStore } from './billing/BillingPageStore';
import { timesheetReportStore } from './timesheets/TimesheetReportStore.js';
import { invoicePageStore } from './invoices/InvoicePageStore.js';
import { StoreBase, dispatcher } from './coincraftFlux.js';
import { invoicesStore } from './invoices/flux.js';
import { contactsPageStore } from './contacts/flux.js';
import { organisationStore } from './organisation.js';
import { TimesheetStore } from './timesheets/flux.js';
import { organisationHolidaysStore } from './organisationHolidays/organisationHolidaysStore.js';
import { descriptionTemplatesStore } from "./invoices/descriptionTemplatesStore.js";
import { staffRoleListStore } from './staffRoles/staffRoleListStore.js';
import { staffRoleStore } from './staffRoles/staffRoleStore.js';



class RootStore extends StoreBase {
  constructor({mixpanel} = {}) {
    super();

    this.mixpanel = mixpanel;

    this.stores = {
		organisation: organisationStore,
		"projects-page": projectsPageStore,
		"timesheet-reports-page": timesheetReportStore,
		"project-page": projectStore,
		"staff-members-page": staffMembersPageStore,
		"staff-page": staffStore,
		"invoice-page": invoicePageStore,
		"invoices-page": invoicesStore,
		milestones: milestonesStore,
		"contacts-page": contactsPageStore,
		"organisation-holidays": organisationHolidaysStore,
		"description-templates": descriptionTemplatesStore,
		"staff-role-list": staffRoleListStore,
		"staff-role-page": staffRoleStore,
		"billing-page": billingPageStore
	};

    let self = this;
    _.each(this.stores, function(v, k) {
      self[k] = v;
    });
  }

  trackMixpanelEvent(eventName, eventProperties) {
    if (this.mixpanel != null) {
      this.mixpanel.track(eventName, eventProperties);
    }
  }

  get isDirty() {
    /**
     * This isn't a complete list of the things that can be dirty but it's a complete
     * list of things that can be dirty when the project / staff tables are open, and
     * that's enough for now.
     */
    return !!this['milestones'].isDirty
      || !!this['projects-page'].reportStore.isDirty
      || !!this['staff-members-page'].reportStore.isDirty;
  }

  loadTimesheets(date) {
    this.stores['my-timesheets'] = new TimesheetStore({autosave: true});
    if (date != null) {
      this.stores['my-timesheets'].loadWeek(date);
    }
  }

  handle(action) {
    const store = this.stores[action.path.split("/")[0]];
    if (store != null && store.handle != null) {
      store.handle(action);

      if (action.type === 'organisation/newProject') {
        this.trackMixpanelEvent('createProjectBegin');
      }
      else if (action.type === 'project-page/saveSuccess') {
        this.trackMixpanelEvent('createProjectSuccess');
      }
      else if (action.type=== 'organisation/newStaffMember') {
        this.trackMixpanelEvent('createStaffBegin');
      }
      this.emitChanged();
    }
  }

  getStore(path) {
    let store = this;
    for (let p of path.split("/")) {
      store = store.stores[p];
    }
    return store;
  }
}


dispatcher.register(function(action) {
  if (action.path != null) {
    rootStore.handle(action);
  }
});



export let rootStore = new RootStore({mixpanel: window.mixpanel});
