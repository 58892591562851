import React from 'react';
import CreateReactClass from 'create-react-class';
import { LoadingSpinner } from '../widgets/LoadingSpinner.js';
import { InfoAlert } from '../widgets/alerts.js';


export function RetrievingContactsFromAccountingSystem({accountingSystem}) {
  return <InfoAlert>
    <div>
      <span style={{marginRight: '1em'}}>
        Retrieving contacts from {accountingSystem.name} (this can take a while)
      </span>
      <LoadingSpinner />
    </div>
  </InfoAlert>;
};

