import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { TriStateSaveButton, DateValue, SmallDeleteButton, SaveBar  } from '../widgets.js';
import { userStore } from '../user.js';
import { Table, Column } from '../table.js';
import { rootStore } from '../RootStore.js';
import { OrganisationHoliday } from '../models/organisationHoliday.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { organisationHolidaysStore, actions } from './organisationHolidaysStore.js';

export var OrganisationHolidaysPage = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([userStore, rootStore], function() {
      return {
        isAdmin: userStore.isAdmin(),
        organisationHolidays: organisationHolidaysStore.organisationHolidays,
        saveState: organisationHolidaysStore.saveOperation.state,
        isDirty: organisationHolidaysStore.isDirty,
      };
    })
  ],

  render: function() {
    return <CoincraftPage
      header={
        <div>
          <div>
            <div className="heading">
              Organisation Holidays
            </div>
          </div>
          {this.state.isAdmin ?
            <div>
              <button
                  className="page-header__button--primary add-org-hol-button"
                  onClick={() => actions.addOrganisationHoliday()}
                  style={{marginTop: '2em'}}>
                + Add Holiday
              </button>
            </div>
          : null}
        </div>
      }
      body={
        <div style={{maxWidth: '60em'}}>
          <OrganisationHolidaysTable
            organisationHolidays={this.state.organisationHolidays}
            />
        </div>
      }
      saveBar={
        <SaveBar
          unsavedChangesMessage="Your holidays have unsaved changes."
          isDirty={this.state.isAdmin && this.state.isDirty}
          saveState={this.state.saveState}
          onSaveClick={() => actions.save()}
        />
      }
    />;
  }
});



var OrganisationHolidaysTable = CreateReactClass({

  getInitialState: function() {
    let self = this;
    return {
      columns: [
        new Column({
          identifier: 'name',
          header: "Name",
          width: '50%',
          data: oh => oh.name,
          content: function(oh, i, stack, data) {
            return <div>
                <input
                  type="text"
                  value={data}
                  style={{width: '100%'}}
                  onChange={e => {
                    actions.changeOrganisationHolidayName(oh, e.target.value)
                  }}
                />
            </div>;
          },
          type: 'string',
        }),
        new Column({
          identifier: 'startDate',
          header: "Start Date",
          width: '23%',
          data: oh => oh.startDate,
          content: function(oh, i, stack, data) {
            return <div>
              <DateValue
                value={data}
                isEditable={true}
                onChange={date => actions.changeOrganisationHolidayStartDate(oh, date)}
              />
            </div>;
          },
          type: 'date',
        }),
        new Column({
          identifier: 'endDate',
          header: "End Date",
          width: '23%',
          data: oh => oh.endDate,
          content: function(oh, i, stack, data) {
            return <div>
              <DateValue
                value={data}
                isEditable={true}
                onChange={date => actions.changeOrganisationHolidayEndDate(oh, date)}
              />
            </div>;
          },
          type: 'date',
        }),
        new Column({
          header: null,
          width: '4%',
          content: function(oh) {
            return <SmallDeleteButton
              onClick={() => actions.deleteOrganisationHoliday(oh)}
            />
          },
          type: null
        })
      ]
    };
  },

  render: function() {
    if (this.props.organisationHolidays == null) {
      return null;
    }
    return <div style={{maxWidth: '65em'}}>
      <Table
        rows={this.props.organisationHolidays.filter(oh => !oh.isDeleted)}
        columns={this.state.columns}
        rowComponent={organisationHolidayRow}
        style={{padding: 0}}
      />
    </div>;
  },

  handleSelectedColumnsChange: function(selectedColumns) {
    this.setState({selectedColumns: selectedColumns});
  }
});

var organisationHolidayRow = CreateReactClass({

  render: function() {
    return <div>
      {this.props.children}
    </div>;
  }
});
