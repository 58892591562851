export const QUALIFIER = {
  TARGET: 'TARGET',       // From scheduler/spreadsheet
  PROJECTED: 'PROJECTED', // Combination of actuals for past + projected for future
  ACTUAL: 'ACTUAL',       // Actuals
  VARIANCE: 'VARIANCE'    // Actuals - estimated
};


export const TYPE = {
  REVENUE: 'REVENUE',
  HOURS: 'HOURS'
};

export const QUANTITY = {
  TARGET_REVENUE: [TYPE.REVENUE, QUALIFIER.TARGET],
  ACTUAL_REVENUE: [TYPE.REVENUE, QUALIFIER.ACTUAL],
  PROJECTED_REVENUE: [TYPE.REVENUE, QUALIFIER.PROJECTED],
  REVENUE_VARIANCE: [TYPE.REVENUE, QUALIFIER.VARIANCE],

  TARGET_HOURS: [TYPE.HOURS, QUALIFIER.TARGET],
  ACTUAL_HOURS: [TYPE.HOURS, QUALIFIER.ACTUAL],
  PROJECTED_HOURS: [TYPE.HOURS, QUALIFIER.PROJECTED],
  HOURS_VARIANCE: [TYPE.HOURS, QUALIFIER.VARIANCE],
};


