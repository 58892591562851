import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { MySelect2 } from './generic.js';
import { makeMultipleStoreMixin, dispatcher } from '../coincraftFlux.js';
import { ContactFormContainer } from '../contacts/ContactFormContainer.js';
import { ContactSelectorStore } from '../widgets/ContactSelectorStore.js';
import { organisationStore } from "../organisation.js";
import { rootStore } from '../RootStore.js';
import { generateUUID, caseInsensitiveContains } from '../utils.js';
import { Modal, ModalContent } from '../modal.js';
import PropTypes from "prop-types";


export var ContactSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    path: PropTypes.string,
    onChange: PropTypes.func,
    context: PropTypes.oneOf(['project', 'invoice']).isRequired
  },

  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      let component = this;
      let store, path;
      if (this.state != null && this.state.store != null) {
        store = this.state.store;
        path = this.state.path;
      }
      else if (this.props.path == null) {
        path = generateUUID();
        store = new ContactSelectorStore(path, {onSave: function(contactData) {
          component.props.onChange(organisationStore.getContactById(contactData.id));
        }});
        rootStore.stores[path] = store;
      }
      else {
        path = this.props.path;
        store = rootStore.getStore(this.props.path);
      }

      return {
        path: path,
        store: store,
        isPopupOpen: store.isPopupOpen,
        contact: store.contact
      };
    })
  ],

  render: function() {
    return (
      <div
        style={{display: 'inline-block', height: 32, textAlign: 'left', ...this.props.style}}
        className={this.props.className}>
        {this.state.isPopupOpen ?
          <Modal className="contact-modal">
            <ModalContent header="New contact" width="60%">
              <ContactFormContainer
                path={this.state.path != null ? `${this.state.path}/contact-form` : null}
                context={this.props.context}
              />
            </ModalContent>
          </Modal>
        : null}
        <div className="flexbox-container flex-align-items-stretch" style={{width: '100%'}}>
          <div className="flex-0-0-auto" style={{zIndex: 2, width: '10%'}}>
            <button
                className="btn btn-sm btn-default contact-selector__add-contact-button"
                onClick={this.handleNewContactButtonClick}
                style={{
                  borderRadius: 0,
                  marginRight: '-0.2em',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  height: '100%'
                }}>
              <i className="fa fa-plus" style={{ margin: 0}}/>
            </button>
          </div>
          <div className="flex-1-1-auto" style={{ width: '90%' }}>
            <ContactDropdown
              value={this.props.value}
              onChange={this.handleSelectChange}
              style={{width: "100%"}}
            />
          </div>
        </div>
      </div>
    );
  },

  handleSelectChange: function(contact) {
    dispatcher.dispatch({
      type: "contact/saveSuccess",
      path: this.state.path + '/contact-form',
      contactData: contact
    });
    if (this.props.onChange != null) {
      this.props.onChange(contact);
    }
  },

  handleNewContactButtonClick: function() {
    dispatcher.dispatch({
      type: "contact-selector/newContact",
      path: this.state.path,
      context: this.props.context
    });
  }
});


export var ContactDropdown = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func,
    filter: PropTypes.oneOf(['selectable', 'xero', 'quickbooks', 'myob-accountright', 'myob-essentials'])
  },

  getDefaultProps: function() {
    return {
      filter: 'selectable'
    };
  },

  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      let contacts = organisationStore.contacts;
      if (this.props.filter === 'selectable') {
        contacts = contacts.filter(c => c.isCoincraftContact);
      }
      else {
        contacts = contacts.filter(c => c.accountingSystemId === this.props.filter);
      }

      return {
        contacts: contacts.sort(function(c1, c2) {
          return c1.display().localeCompare(c2.display());
        })
      };
    })
  ],

  render: function() {
    return <MySelect2
      className="contact-selector__dropdown"

      // `inline-block` (default for `MySelect2`) breaks our flex vertical
      // centering because of `line-height` issues so use `inline` instead.
      style={{display: 'inline', ...this.props.style}}
      value={this.props.value}
      options={this.state.contacts}
      filter={function(dataItem, searchTerm) {
        return caseInsensitiveContains(dataItem.display(), searchTerm);
      }}
      onChange={this.props.onChange}
      getObjectLabel={function(c) {
        if (c == null) {
          return <span>(No contact)</span>;
        }
        else {
          return <span>{c.display()}</span>;
        }
      }}
    />;
  }
});
