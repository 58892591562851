import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { FilterTextBox } from '../widgets/generic.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


export var VerticalCheckboxList = CreateReactClass({
  propTypes: {
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool
    })).isRequired,
    selectedOptionValues: PropTypes.array.isRequired,
    innerHeight: PropTypes.any,
    filterPlaceholder: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      innerHeight: '40em',
      filterPlaceholder: 'Filter'
    };
  },

  getInitialState: function() {
    return {
      filterText: '',
    };
  },

  render: function() {
    let self = this;
    const filterTextLower = this.state.filterText.toLowerCase();
    return <div className="flexbox-container flex-direction-column" style={{height: '100%'}}>
      <div className="flex-0-0-auto" style={{padding: '0.75em'}}>
        <FilterTextBox
          value={this.state.filterText}
          onChange={(text) => this.setState({filterText: text})}
          placeholder={this.props.filterPlaceholder}
        />
      </div>
      <div className="flex-1-0-auto" style={{height: this.props.innerHeight, overflowY: 'auto', margin: '0 1em'}}>
        <div>
          {this.props.options.map(function(co, i) {
            if (filterTextLower !== '' && co.label.toLowerCase().indexOf(filterTextLower) === -1) {
              return null;
            }
            const isSelected = _.find(self.props.selectedOptionValues, c => c === co.value) != null;
            return (
              <label
                  key={i}
                  className={classNames(
                    "column-selector__column",
                    "flexbox_container",
                    "flex-align-items-center",
                    "column-select-list-item", {
                      selected: isSelected,
                      disabled: co.disabled
                    }
                  )}>
                <input
                  type="checkbox"
                  className="flex-0-0-auto"
                  checked={isSelected}
                  disabled={co.disabled}
                  onChange={event => self.props.onCheckboxChange(co.value, event.target.checked)}
                />
                <span style={{marginLeft: '1em'}} className="flex-0-0-auto">
                  {co.label}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    </div>;
  }
});



