import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { ProjectNote } from '../models/projectnote.js';
import { TextValue, DateValue, SmallDeleteButton} from '../widgets.js';
import { dateConverter, ProjectPhase } from '../models.js';
import { formatCurrencyWithCents, formatPercentage0, formatNumber2, sum } from "../utils.js";
import PropTypes from "prop-types";


export var ProjectHistoryList = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
  },

  render: function() {
    return (
      <div style={{margin: '3em 2em'}}>

        <h4 style={{fontSize: '1.8em', margin: '1em 1em 0.5em 0.5em', display: 'inline-block'}}>
          Event History
        </h4>

        <ProjectHistoryTable
          events={this.props.project.eventHistory}
        />


      </div>
    );
  }

})


var ProjectHistoryTable = CreateReactClass({
  propTypes: {
    events: PropTypes.object.isRequired,
  },

  render: function() {
    let self = this;
    const events = this.props.events.sort( (a,b) => {
      if(a.moment === null || a.moment.isAfter(b.moment)){
        return -1;
      }
      else if(b.moment === null || a.moment.isBefore(b.moment)){
        return 1;
      }
      else if(a.moment === b.moment || a.moment.isSame(b.moment)){
        return 0;
      }
      else {
        return 0;
      }
    });
    return <div className="coincraft-table" style={{width:'60em', margin: '1em'}}>
      <div className="flexbox-container coincraft-table-row header">
        <div className="coincraft-table-cell" style={{ width: '10em' }}>Date</div>
        <div className="coincraft-table-cell" style={{width:'50em'}}>Event</div>
      </div>
      {events.map(function(event, i) {
        return <EventRow
          key={i}
          event={event}
        />;
      })}
    </div>;
  }
});


var EventRow = CreateReactClass({
  propTypes: {
    event: PropTypes.object.isRequired,
  },

  render: function() {
    const event = this.props.event;

    return <div className="flexbox-container coincraft-table-row">
      <div className="coincraft-table-cell" style={{ width: '10em' }}>
        {`${event.moment.format('DD MMM YYYY')}`}
      </div>
      <div className="coincraft-table-cell" style={{width:'50em'}}>
        <span><b>{`${event.staffName}`}</b>{` changed `}<b>{`${event.phaseTitle}'s ${event.field}`}</b>{` from `}<b>{`${event.fromValue}`}</b>{` to `}<b>{`${event.toValue}`}</b>{`.`}</span>
      </div>
    </div>;
  }
});
