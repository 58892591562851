import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { TriStateSaveButton } from '../widgets.js';
import moment from 'moment';
import { FormSection, FormRow, FormLabel } from '../forms.js';
import { DateValue, EditItemControls } from '../widgets.js';
import { ErrorPopover } from '../utils.js';
import { actions } from "./flux.js";
import PropTypes from "prop-types";


export var CopyProjectModal = CreateReactClass({
  propTypes: {
    itemTypeName: PropTypes.string.isRequired,
    saveState: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired
  },

  getInitialState: function() {
    return {
      name: '',
      jobCode: '',
      startDate: this.props.project.getStartDate() ? moment() : null,
      isNameValid: false,
      hasAttemptedSubmit: false
    };
  },

  componentDidMount: function() {
    this.refs.firstInput.focus();
  },

  render: function() {
    const labelWidth = '7em';
    return <Modal>
      <ModalContent header="Copy Project" width='45em' bodyStyles={{padding: 0}}>
        <div
            className="copy-project-form"
            style={{padding: '2em'}}>
          <p>
            You are creating a new project using <em>{this.props.project.getTitle()}</em>
            {' as a template.'}
          </p>

          <div>
            <FormSection>
              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Project Name:
                </FormLabel>
                <div style={{position: 'relative', display: 'inline-block', width: '17em'}}>
                  <input
                    type="text"
                    ref="firstInput"
                    className="project-name-input lg-input"
                    style={{width: '16em'}}
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                  {this.state.hasAttemptedSubmit && !this.state.isNameValid ?
                    <ErrorPopover
                      orientation="right"
                      message="Please enter a name for the new project"
                      style={{position: 'absolute', left: '100%', width: 300}}
                    />
                  : null}
                </div>
              </FormRow>
              <FormRow>
                <FormLabel style={{width: labelWidth}}>
                  Job Code:
                </FormLabel>
                <input
                  type="text"
                  className="job-code-input sm-input"
                  value={this.state.jobCode}
                  onChange={this.handleJobCodeChange}
                  style={{width: '7em'}}
                />
              </FormRow>
              {this.props.project.getStartDate() ?
                <FormRow>
                  <FormLabel style={{width: labelWidth}}>
                    Start Date:
                  </FormLabel>
                  <DateValue
                    className="start-date-input"
                    style={{width: '16em', display: 'inline-block'}}
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                  />
                </FormRow>
              : null}
            </FormSection>
          </div>
        </div>
        <div style={{borderTop: '1px solid #ccc', textAlign: 'right', padding: '1em'}}>
          <a
              href="javascript:void(0)"
              onClick={this.props.onCancel}>
            Cancel
          </a>

          <TriStateSaveButton
            text="Save"
            className="btn btn-default"
            onClick={() => this.props.onConfirm({
                project: this.props.project,
                name: this.state.name,
                jobCode: this.state.jobCode,
                startDate: this.state.startDate
              }
            )}
            state={this.props.saveState}
            style={{marginLeft: '1em'}}
          />
        </div>
      </ModalContent>
    </Modal>;
  },
  handleCancelClick: function() {
    actions.copyProjectModalCancel(this.props.modal);
  },

  handleSaveClick: function() {
    if (this.state.name !== '') {
      actions.copyProjectModalSubmit({
        modal: this.props.modal,
        project: this.props.project,
        name: this.state.name,
        jobCode: this.state.jobCode,
        startDate: this.state.startDate
      });
    }
    else {
      this.setState({hasAttemptedSubmit: true});
    }
  },

  handleNameChange: function(event) {
    this.setState({
      name: event.target.value,
      isNameValid: event.target.value !== ''
    });
  },

  handleJobCodeChange: function(event) {
    this.setState({jobCode: event.target.value});
  },

  handleStartDateChange: function(startDate) {
    this.setState({startDate: startDate});
  }
});
