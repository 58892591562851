import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { EditItemTasks } from './EditProjectTasks.js';
import { userStore } from '../user/flux.js';
import { ProjectPhaseTasks } from '../project/ProjectPhaseTasks.js';
import { dispatcher } from '../coincraftFlux.js';

import PropTypes from "prop-types";

export var EditPhaseTasks = CreateReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired
  },

  render: function() {
    let self = this;
    return <EditItemTasks
      modal={this.props.modal}
      item={this.props.phase}
      itemTypeName='phase'
      editorComponent={function({item}) {
        return <ProjectPhaseTasks
          phase={item}
          isEditable={userStore.user.permissions.canEditProject(self.props.phase.project)}
          actions={{
            addTask: () => dispatcher.dispatch({
              type: "tasks/addTask",
              path: "milestones/phaseTasks",
            }),
            deleteTask: (taskIndex) => dispatcher.dispatch({
              type: "tasks/deleteTask",
              path: "milestones/phaseTasks",
              taskIndex: taskIndex
            }),
            setTaskText: (taskIndex, text) => dispatcher.dispatch({
              type: "tasks/setTaskText",
              path: "milestones/phaseTasks",
              taskIndex: taskIndex,
              text: text
            }),
            setTaskIsBillable: (taskIndex, isBillable) => dispatcher.dispatch({
              type: "tasks/setTaskIsBillable",
              path: "milestones/phaseTasks",
              taskIndex: taskIndex,
              isBillable: isBillable
            }),
            setTaskIsVariation: (taskIndex, isVariation) => dispatcher.dispatch({
              type: "tasks/setTaskIsVariation",
              path: "milestones/phaseTasks",
              taskIndex: taskIndex,
              isVariation: isVariation
            }),
          }}
        />;
      }}
      onSave={(modal, origItem, newItem) => actions.editPhaseTasksModalSave(modal, origItem, newItem)}
      onCancel={(modal) => actions.editPhaseTasksModalCancel(modal)}
    />;
  }
});

