import _ from 'underscore';
import React from 'react';
import { Dispatcher } from 'flux';
import { EventEmitter } from 'events';
import { camelCaseToAllCaps, enumerate } from './utils.js';
import CreateReactClass from "create-react-class";


export var dispatcher = new Dispatcher();

dispatcher.register(function(action) {
  // Log all actions.
  const {password: _password, ...actionArgs} = action;


  /* eslint-disable no-console */
  console.log(action.type, actionArgs);
  /* eslint-enable no-console */

  if (process.env.REACT_APP_ENABLE_ACTION_LOGGING) {
		if (window.coincraftActions == null) {
			window.coincraftActions = [];
		}
		window.coincraftActions.push(action);
  }
});


function copyActions(index1, index2) {
  /**
   * This is just a developer function to copy the actions to the clipboard (to
   * be run from the dev tools). `copy` is a function defined by the dev tools.
   *
   * >>> copyActions()     // copy all actions
   * >>> copyActions(2)    // copy all actions except the first two
   * >>> copyActions(2, 4) // copy the third and fourth actions
   */
  /* eslint-disable no-undef */
  copy(JSON.stringify(window.coincraftActions.slice(index1, index2), null, 4));
  /* eslint-enable no-undef */
}
window.copyActions = copyActions;


function executeActions(actions) {
  let i = 0;

  function executeAction() {
    setTimeout(function() {
      dispatcher.dispatch(actions[i++]);
      if (i < actions.length) {
        executeAction();
      }
    }, 100);
  }

  executeAction();
}
window.executeActions = executeActions;



const CHANGE_EVENT = 'change';


export const StoreBase = class {
  constructor() {
    this.eventEmitter = new EventEmitter();

    // Avoid warning when we add 11.
    this.eventEmitter.setMaxListeners(15);
    this.modals = [];
  }

  emitChanged() {
    this.eventEmitter.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.eventEmitter.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.eventEmitter.removeListener(CHANGE_EVENT, callback);
  }

  closeModal(modal, {emitChanged = true} = {}) {
    this.modals = _.without(this.modals, modal);
    if (emitChanged) {
      this.emitChanged();
    }
  }
}

/**
 * Syntactic sugar for the action->dispatcher call boilerplate.
 */
export function addAction(actionsDict, name, actionArgNames, prefix = '') {
  actionsDict[name] = function(...args) {
    var dispatchArgs = {
      type: prefix + camelCaseToAllCaps(name)
    };
    _.zip(actionArgNames, args).forEach(function([argName, argValue]) {
      dispatchArgs[argName] = argValue;
    });
    dispatcher.dispatch(dispatchArgs);
  };
};

export function addActions(actionsDict, prefix, actions) {
  actions.forEach(function(action) {
    addAction(actionsDict, action.name, action.args || [], prefix);
  });
};


export const ActionCollection = class {
  constructor(prefix, defaultStore = null, actions = [], dispatcher = null, fallbackHandler = null) {
    this.prefix = prefix;
    this.defaultStore = defaultStore;
    this.actionsDict = {};
    this.callbacks = {};

    this.addActions(actions);

    if (dispatcher != null) {
      this.register(dispatcher, fallbackHandler || function() { });
    }
  }

  addActions(actions) {
    /**
      let actionCollection = new ActionCollection("MILESTONES_", store);
      actionCollection.addActions([
        {
          name: 'setMilestoneFee',
          args: ['milestone', 'fee'],
          callback: 'default'
        }
      ]);

    // Is shorthand for:

      let actionCollection = new ActionCollection("MILESTONES_", store);
      actionCollection.addActions([
        {
          name: 'setMilestoneFee',
          args: ['milestone', 'fee'],
          callback: function(action) {
            store.setMilestoneFee(action.milestone, action.fee);
          }
        }
      ]);
      */

    let self = this;
    addActions(this.actionsDict, this.prefix, actions);
    for (let action of actions) {
      if (action.callback != null) {
        if (action.callback === 'default') {
          let storeFunc = self.defaultStore[action.name];
          if (storeFunc == null) {
            throw new Error("Missing store func " + action.name);
          }
          action.callback = function(action, a) {
            storeFunc.apply(self.defaultStore, (action.args || []).map(arg => a[arg]));
          }.bind(self, action);
        }

        self.callbacks[self.prefix + camelCaseToAllCaps(action.name)] = action.callback;
      }
    }
  }

  register(dispatcher, fallback) {
    let self = this;
    return dispatcher.register(function(action) {
      if (self.callbacks[action.type] != null) {
        self.callbacks[action.type](action);
      }
      else {
        fallback(action);
      }
    });
  }
}


class MultipleStoreMixin {
  constructor(stores, getStateFunc) {
    this.stores = stores;
    this.getStateFunc = getStateFunc;
    this.boundFuncs = [];
  }

  mixin() {
    // The reason this is so convoluted is because mixins are only instantiated once
    // when the component is defined. So if we create multiple instances of components
    // using the mixin, the mixin has to keep track of which handlers are associated
    // with which component. (It's hard for the component to do this because a
    // component may have multiple mixins).
    //
    // That said, maybe we can remove this complexity if we mandate using
    // `makeMultipleStoreMixin` rather than multiple instances of
    // `makeStoreMixin`.
    let self = this;

    let stores = this.stores;
    let getStateFunc = this.getStateFunc;

    function _update() {
      this.setState(getStateFunc.bind(this)());
    };

    return {
      getInitialState: function() {
        return getStateFunc.bind(this)();
      },

      componentWillMount: function() {
        let boundFunc = _update.bind(this);
        self.boundFuncs.push({
          func: boundFunc,
          component: this
        });

        for (let s of stores) {
          if (s == null) {
            throw new Error(`Store not found in setting up ${this.displayName}`);
          }
          if (s.addChangeListener == null) {
            throw new Error(`Non-store passed in setting up ${this.displayName}`);
          }
          s.addChangeListener(boundFunc);
        }

        // Force call here just in case the data changed in between
        // `getInitialState` and `componentWillMount` (not sure if that is even
        // possible).
        boundFunc();
      },

      componentWillUnmount: function() {
        self.boundFuncs.filter(bf => bf.component === this).forEach(function(bf) {
          stores.forEach(function(s) {
            s.removeChangeListener(bf.func);
          });
        });
        self.boundFuncs = _.reject(self.boundFuncs, bf => bf.component === this);
      }
    };
  }
}

export function makeStoreMixin(store, getStateFunc) {
  return new MultipleStoreMixin([store], getStateFunc).mixin();
};

export function makeMultipleStoreMixin(stores, getStateFunc) {
  /**

  // It would be nice to assume this but the timesheet app imports widgets
  // which require the organisation store, which doesn't exist in the timesheet app.
  // This isn't a problem because the timesheet app doesn't use those widgets so it's a
  // false negative.

  for (let s of stores) {
    if (s == null) {
      throw new Error("undefined store");
    }
  }
  */

  return new MultipleStoreMixin(stores, getStateFunc).mixin();
};


export const Saver = class {
  constructor(onChange) {
    this.onChange = onChange;
  }

  addToActionCollection(actions, prefix) {
    addActions(actions, prefix, [
      {name: 'saveSuccess', args: ['data']},
      {name: 'saveSuccessTimeoutExpired', args: []},
      {name: 'saveFailure', args: ['error']},
    ]);
    this.actions = actions;
  }

  handleAction(action, prefix) {
    switch (action.type) {
      case prefix + 'SAVE_SUCCESS':
        this.saveSuccess();
        break;
      case prefix + 'SAVE_FAILURE':
        this.saveFailed();
        break;
      case prefix + 'SAVE_SUCCESS_TIMEOUT_EXPIRED':
        this.saveSuccessTimeoutExpired();
        break;
    }
  }

  reset() {
    this.saveState = null;
    this.emitChanged();
  }

  save(saveFunc) {
    let self = this;
    this.saveState = 'saving';
    this.emitChanged();

    saveFunc().then(function(data) {
      if (data.status === 'ok') {
        self.actions.saveSuccess(data);
      }
      else {
        self.actions.saveFailure(data.error);
      }
    }, function(error) {
      self.actions.saveFailure(error);
    });
  }

  saveSuccess() {
    let self = this;
    this.saveState = 'saved';
    this.emitChanged();
    setTimeout(function() {
      self.actions.saveSuccessTimeoutExpired();
    }, 2000);
  }

  saveFailed() {
    this.saveState = 'failed';
    this.emitChanged();
  }

  saveSuccessTimeoutExpired() {
    this.saveState = null;
    this.emitChanged();
  }

  dismissSaveError() {
    if (this.saveState === 'failed') {
      this.saveState = null;
      this.emitChanged();
    }
  }

  emitChanged() {
    this.onChange(this.saveState);
  }
}



export function connect(
  component,
  store,
  mapStoreToProps,
  mapActionsToProps = () => ({}),
  other = {}
) {
  /**
   * Vaguely analogous to react-redux's `connect` method.
   */
  return CreateReactClass({
		mixins: [makeMultipleStoreMixin([store], mapStoreToProps)],

		componentWillMount: function() {
			this.actions = mapActionsToProps.bind(this)();
		},

		render: function() {
			return React.createElement(component, {
				ref: "inner",
				...this.state,
				...this.actions,
				...this.props
			});
		},

		...other
  });
}


class BaseActions {
  constructor() {
    this.path = null;
    this.dispatcher = null;
  }

  bindPath(path) {
    this.path = path;
    return this;
  }

  bindDispatcher(dispatcher) {
    this.dispatcher = dispatcher;
    return this;
  }
}


function actionCreatorClass(prefix, definitions) {
  let klass = class extends BaseActions { };

  for (let {action, args: actionArgs} of definitions) {
    if (_.include(actionArgs, 'type')) {
      throw new Error("You can't have an arg called 'type'");
    }

    klass.prototype[action] = function(...args) {
      let payload = {
        type: `${prefix}/${action}`,
      };
      if (this.path != null) {
        payload.path = this.path;
      }
      for (let [i, a] of enumerate(args)) {
        payload[actionArgs[i]] = a;
      }
      if (this.dispatcher != null) {
        this.dispatcher.dispatch(payload);
      }
      return payload;
    };
  }

  return klass;
}



export function handleAction(action, store) {
  const method = action.type.substr(_.lastIndexOf(action.type, '/') + 1);
  const actionArgs = _.find(store.actionDefinitions, d => d.action === method).args;
  return store[method](...actionArgs.map(k => action[k]));
}


export function registerActions(prefix, actionDefinitions, dispatcher, path = prefix) {
  let c = new (actionCreatorClass(prefix, actionDefinitions))();
  c.dispatcher = dispatcher;
  c.path = path;
  return c;
}

