import React from "react";
import { Tooltip } from "antd";

const ToolTip = ({ title, body, placement }) => {
	return (
		<Tooltip
			title={
				<div>
					<h4 style={{ color: "white" }}>{title}</h4>
					<div style={{ color: "white" }}>{body}</div>
				</div>
			}
			placement={placement}
		>
			<i className="fa fa-question-circle" />
		</Tooltip>
	);
};

export default ToolTip;

export const PayRateToolTip = ({ placement }) => {
	return (
		<ToolTip
			title={"Pay Rate"}
			body={"The amount you pay staff per hour."}
			placement={placement}
		/>
	);
};
export const OvertimeRateToolTip = ({ placement }) => {
	return (
		<ToolTip
			title={"Overtime Rate"}
			body={
				"The amount you pay staff per hour for overtime. You can set this to be the same as your pay rate if you don't have an overtime rate."
			}
			placement={placement}
		/>
	);
};
export const CostRateToolTip = ({ placement }) => {
	return (
		<ToolTip
			title={"Cost Rate"}
			body={
				"The amount of cost your orginisation incurs per hour of project work + the amount you pay staff per hour."
			}
			placement={placement}
		/>
	);
};
export const ChargeOutRateToolTip = ({ placement }) => {
	return (
		<ToolTip
			title={"Charge-Out Rate"}
			body={
				"The amount you charge the client for an hour of the staff member's time."
			}
			placement={placement}
		/>
	);
};
