import moment from "moment";
import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { userStore } from "../user.js";
import { organisationStore } from "../organisation.js";
import { Link } from "react-router";
import {
	DateValue,
	NumberInputContainer,
	SmallDeleteButton,
	ProjectPhaseSelector,
	OptionallyEditableValue,
} from "../widgets.js";
import { formatNumber2 } from "../utils.js";
import { Table, Column } from "../table.js";
import { actions } from "./flux.js";
import { projectStore } from "./flux.js";
import { TextValue } from "../widgets/generic.js";

export var ProjectChangeLog = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([projectStore, userStore], function () {
			return {
				project: projectStore.project,
				changeLog: projectStore.project.changeLog,
				isEditable: userStore.user.permissions.canEditProject(
					projectStore.project
				),
				currencyFormatter:
					organisationStore.organisation.currencyFormatter,
			};
		}),
	],

	shouldComponentUpdate: function (nextProps, nextState) {
		return nextState.changeLog !== this.state.changeLog;
	},

	render: function () {
		let self = this;
		return (
			<div>
				<Table
					columns={[
						new Column({
							header: "Date",
							width: "16%",
							data: (e) => e.date,
							total: <strong>Total</strong>,
							content: function (
								item,
								i,
								stack,
								data,
								{ isEditable }
							) {
								return (
									<span>
										<DateValue
											value={data}
											max={moment()
												.startOf("day")
												.toDate()}
											isEditable={isEditable}
											onChange={function (date) {
												self.handleDateChange(i, date);
											}}
										/>
									</span>
								);
							},
							type: "moment",
						}),
						new Column({
							header: "Title",
							width: "16%",
							data: (e) => e.title,
							content: function (
								item,
								i,
								stack,
								data,
								{ isEditable }
							) {
								if (item.invoice != null) {
									return (
										<Link to={item.invoice.path}>
											Invoice: {item.invoice.description}
										</Link>
									);
								} else if (item.expense != null) {
									return <span>{item.expense.name}</span>;
								} else {
									return (
										<TextValue
											isEditable={isEditable}
											value={item._title}
											onChange={(e) =>
												self.handleTitleChange(
													i,
													e.target.value
												)
											}
										/>
									);
								}
							},
						}),
						new Column({
							header: "Phase",
							width: "16%",
							data: (e) => e.phase,
							content: function (
								item,
								i,
								stack,
								phase,
								{ isEditable }
							) {
								return (
									<span>
										<OptionallyEditableValue
											value={
												phase != null
													? phase.name
													: "(No phase)"
											}
											isEditable={isEditable}
										>
											<ProjectPhaseSelector
												project={self.state.project}
												phase={phase}
												style={{ width: "100%" }}
												onChange={function (phase) {
													self.handlePhaseChange(
														i,
														phase
													);
												}}
											/>
										</OptionallyEditableValue>
									</span>
								);
							},
							compare: function (
								{ data: phase1 },
								{ data: phase2 }
							) {
								return (
									phase1 != null ? phase1.name : ""
								).localeCompare(
									phase2 != null ? phase2.name : ""
								);
							},
						}),
						new Column({
							header: "Revenue",
							width: "16%",
							data: (e) => e.revenue,
							// paddingRight: 6 to line up with text boxes
							total: (
								<strong style={{ paddingRight: 6 }}>
									{this.state.currencyFormatter.format(
										this.state.changeLog
											.map((cli) => cli.revenue || 0)
											.reduce((a, b) => a + b, 0)
									)}
								</strong>
							),
							content: function (
								item,
								i,
								stack,
								revenue,
								{ isTotal, isEditable }
							) {
								return (
									<span>
										<NumberInputContainer
											value={revenue}
											isEditable={isEditable}
											formatFunc={(n) =>
												n != null
													? formatNumber2(n)
													: ""
											}
											onChange={function (revenue) {
												self.handleRevenueChange(
													i,
													parseFloat(revenue)
												);
											}}
										/>
									</span>
								);
							},
							type: "number",
						}),
						new Column({
							header: "Expenses",
							width: "16%",
							data: (e) => e.expenses,
							// paddingRight: 6 to line up with text boxes
							total: (
								<strong style={{ paddingRight: 6 }}>
									{this.state.currencyFormatter.format(
										this.state.changeLog
											.map((cli) => cli.expenses || 0)
											.reduce((a, b) => a + b, 0)
									)}
								</strong>
							),
							content: function (
								item,
								i,
								stack,
								expenses,
								{ isEditable }
							) {
								return (
									<span>
										<NumberInputContainer
											value={expenses}
											isEditable={isEditable}
											formatFunc={(n) =>
												n != null
													? formatNumber2(n)
													: ""
											}
											onChange={function (expenses) {
												self.handleExpensesChange(
													i,
													parseFloat(expenses)
												);
											}}
										/>
									</span>
								);
							},
							type: "number",
						}),
						new Column({
							header: "Progress (%)",
							width: "16%",
							data: (e) => e.progress,
							content: function (
								item,
								i,
								stack,
								progress,
								{ isEditable }
							) {
								return (
									<span>
										<NumberInputContainer
											value={progress}
											isEditable={isEditable}
											formatFunc={(n) =>
												n != null
													? formatNumber2(n)
													: ""
											}
											onChange={function (progress) {
												self.handleProgressChange(
													i,
													parseFloat(progress)
												);
											}}
										/>
									</span>
								);
							},
							type: "number",
						}),
						new Column({
							header: null,
							width: "4%",
							content: function (
								item,
								i,
								stack,
								data,
								{ isEditable }
							) {
								return isEditable ? (
									<SmallDeleteButton
										onClick={function () {
											self.handleDeleteButtonClick(i);
										}}
									/>
								) : null;
							},
							type: null,
						}),
					]}
					rows={
						this.state.changeLog
						// filters fuck up editing because it selects change log items by index
						// 	.filter(
						// 	(cli) =>
						// 		!cli.isDeleted &&
						// 		(cli.isEditable || cli.revenue || cli.expenses)
						// )
					}
					rowComponent={ProjectChangeLogRow}
					rowData={function (changeLogItem, i, stack) {
						return {
							isEditable:
								self.state.isEditable &&
								changeLogItem.isEditable,
						};
					}}
				/>

				{this.state.isEditable ? (
					<div style={{ marginTop: "1em" }}>
						<button
							className="btn btn-default"
							onClick={this.handleAddItemButtonClick}
						>
							+Add item
						</button>
					</div>
				) : null}
			</div>
		);
	},

	handleAddItemButtonClick() {
		actions.addChangeLogItem(this.state.project);
	},

	handleDateChange(index, date) {
		actions.setChangeLogItemProp(this.state.project, index, "date", date);
	},

	handleTitleChange(index, title) {
		actions.setChangeLogItemProp(
			this.state.project,
			index,
			"_title",
			title
		);
	},

	handlePhaseChange(index, phase) {
		actions.setChangeLogItemProp(this.state.project, index, "phase", phase);
	},

	handleRevenueChange(index, text) {
		actions.setChangeLogItemProp(
			this.state.project,
			index,
			"revenue",
			text
		);
	},

	handleExpensesChange(index, text) {
		actions.setChangeLogItemProp(
			this.state.project,
			index,
			"expenses",
			text
		);
	},

	handleProgressChange(index, text) {
		actions.setChangeLogItemProp(
			this.state.project,
			index,
			"progress",
			text
		);
	},

	handleDeleteButtonClick(index) {
		actions.deleteChangeLogItem(this.state.project, index);
	},
});

var ProjectChangeLogRow = CreateReactClass({
	shouldComponentUpdate: function (nextProps, nextState) {
		return nextProps.row !== this.props.row;
	},

	render: function () {
		return <div>{this.props.children}</div>;
	},
});
