import apiRequest from "./apiRequest.js";
import { makeObservable, observable, computed, action } from "mobx";
import localforage from "localforage";
import { add, formatISO, parseISO } from "date-fns";

class LoginModalStore {
	@observable needsLogin = false;
	@observable status = "idle";
	@observable callback = null;
	constructor() {
		makeObservable(this);
	}
	@action.bound
	openLogin(callback) {
		this.callback = callback;
		this.needsLogin = true;
	}
	@action.bound
	login(email, password) {
		this.status = "loading";
		apiRequest({
			path: "/user/login",
			method: "post",
			data: {
				email: email,
				password: password,
				newApi: true,
			},
			success: (data) => {
				localforage
					.setItem(
						"session-expires",
						formatISO(
							add(new Date(), {
								days: 13,
								hours: 23,
								minutes: 50,
							})
						)
					)
					.then(() => {
						this.status = "idle";
						this.needsLogin = false;
						if (this.callback) {
							this.callback();
							this.callback = null;
						}
					});
			},
			error: (error) => {
				this.status = "error";
			},
		});
	}
	@action.bound
	checkLogin(callback) {
		return localforage.getItem("session-expires").then((val) => {
			if (!val || parseISO(val) <= Date.now()) {
				this.openLogin(callback);
			} else {
				callback && callback();
			}
		});
		// return apiRequest({
		// 	path: "/user/logged-in",
		// 	method: "get",
		// 	params: { newApi: true },
		// 	success: (data) => {
		// 		if (!data.loggedIn) {
		// 			this.openLogin(callback);
		// 		} else {
		// 			callback && callback();
		// 		}
		// 	},
		// });
	}
}

export const loginModalStore = new LoginModalStore();
