import { dispatcher, handleAction } from './coincraftFlux.js';

export const AjaxOperation = class {
  constructor(store, {resetOnSuccess = true} = {}) {
    this.state = null;
    this.store = store;
    this.resetOnSuccess = resetOnSuccess;
  }

  execute(request, state = 'executing') {
    let self = this;
    this._setState(state);

    request.then(function(data, textStatus, jqXHR) {
      self.setSuccess();

      if (self.resetOnSuccess) {
        setTimeout(function() {
          self.reset();
        }, 2500);
      }
    }, function() {
      self.setError();
    });
  }

  _setState(state) {
    this.state = state;
    this.store.emitChanged();
  }

  reset() {
    this._setState(null);
  }

  setSuccess() {
    this._setState('success');
  }

  setError() {
    this._setState('error');
  }

  get isUntouched() {
    return this.state == null;
  }

  get isExecuting() {
    return this.state === 'executing';
  }

  get hasSucceeded() {
    return this.state === 'success';
  }

  get hasFailed() {
    return this.state === 'error';
  }
}


const ajaxOperation2Definitions = [
  {action: 'success', args: ['data']},
  {action: 'successTimeoutExpired', args: []},
  {action: 'error', args: ['error']},
];


export const AjaxOperation2 = class {
  constructor(path, {resetOnSuccess = true} = {}) {
    this.state = null;
    this.path = path;
    this.resetOnSuccess = resetOnSuccess;

    this.actionDefinitions = ajaxOperation2Definitions;
  }

  execute(request) {
    let self = this;

    this.state = 'executing';

    request.then(function(data, textStatus, jqXHR) {
      dispatcher.dispatch({
        type: 'ajax/success',
        path: self.path,
        data: data
      });

      if (self.resetOnSuccess) {
        setTimeout(function() {
          dispatcher.dispatch({
            type: 'ajax/successTimeoutExpired',
            path: self.path
          });
        }, 2500);
      }
    }, function(error) {
      dispatcher.dispatch({
        type: 'ajax/error',
        path: self.path,
        error: error
      });
    });
  }

  reset() {
    this.state = null;
  }

  success(data) {
    this.state = 'success';
  }

  setError(error) {
    this.state = 'error';
  }

  successTimeoutExpired() {
    this.state = null;
  }

  handle(action) {
    handleAction(action, this);
  }

  get isUntouched() {
    return this.state == null;
  }

  get isExecuting() {
    return this.state === 'executing';
  }

  get hasSucceeded() {
    return this.state === 'success';
  }

  get hasFailed() {
    return this.state === 'error';
  }
}


