import { Select, Switch, Input, Progress, Button, Alert } from "antd";
import { observer } from "mobx-react";
import React, { useRef, useEffect, useCallback, useState } from "react";
import { PermissionLevel } from "../models/permissions";
import { projectStatusOptions } from "../models/project";
import { organisationStore } from "../organisation";
import {
	camelCaseToSentence,
	numberTimeStringToMinutes,
	numMinutesToTimeString,
} from "../utils";
import { HoursInput } from "../widgets/HoursInput";
import { getProjectOptions } from "../widgets/ProjectSelector";
import calendarState from "./calendarState";

const { Option, OptGroup } = Select;
const { TextArea } = Input;
export const budgetOptions = [
	"phase",
	"role",
	"staff",
	"monthlyPhase",
	"monthlyRole",
	"monthlyStaff",
];
export const budgetNames = {
	phase: "Phase Budget for Team",
	role: "Phase Budget for Role",
	staff: "Phase Budget for Staff Member",
	monthlyPhase: "Monthly Budget for Team",
	monthlyRole: "Monthly Budget for Role",
	monthlyStaff: "Monthly Budget for Staff Member",
};

export const CalendarSidebar = observer(({ mode }) => {
	const settings = organisationStore.organisation.settings;
	const disabled =
		calendarState.selectedItem &&
		calendarState.mode !== calendarState.selectedItemType;
	let progressGradient = { "0%": "#108ee9" };
	progressGradient[
		`${Math.round((0.5 / calendarState.budget.percent) * 100)}%`
	] = "#87d068";
	if (calendarState.budget.percent > 0.8) {
		progressGradient[
			`${Math.round((0.8 / calendarState.budget.percent) * 100)}%`
		] = "#ffc200";
	}
	if (calendarState.budget.percent > 1) {
		progressGradient[
			`${Math.round((1 / calendarState.budget.percent) * 100)}%`
		] = "#ff5800";
	}
	return (
		<div style={{ width: "24rem", padding: "1rem" }}>
			{!disabled && calendarState.selectedItem && (
				<Alert
					message="Editing selected item."
					type="info"
					showIcon
					style={{ fontSize: "1.2rem" }}
				/>
			)}
			<Select
				optionFilterProp="children"
				style={{
					zIndex: 100,
					width: "100%",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
				value={calendarState.budgetType}
				status="active"
				onChange={(newType) => calendarState.changeBudgetType(newType)}
				className={"budget-selector"}
			>
				{budgetOptions.map((bOpt) => {
					return (
						<Option key={bOpt} value={bOpt}>
							{budgetNames[bOpt]}
						</Option>
					);
				})}
			</Select>
			<div
				style={{
					margin: "0.5rem 0 -0.75rem 0",
					padding: "0 0.75rem",
				}}
			>
				{`${Math.round(calendarState.budget.use * 4) / 4} / ${
					Math.round(calendarState.budget.budget * 4) / 4
				} (${Math.round(calendarState.budget.percent * 100)}%)`}
			</div>
			<Progress
				percent={calendarState.budget.percent * 100}
				showInfo={false}
				strokeColor={progressGradient}
				trailColor="#dddddd"
				style={{ marginBottom: "1.5rem", padding: "0 0.75rem" }}
			/>
			<ProjectSelector
				value={calendarState.selectedValues.projectId}
				onChange={(projectId) => {
					calendarState.updateSelectedValues({ projectId });
				}}
				style={{ zIndex: 100, width: "100%", fontWeight: "bold" }}
				permissions={
					calendarState.mode === "allocation"
						? PermissionLevel.projectManager
						: PermissionLevel.timesheet
				}
				disabled={disabled}
			/>
			<ProjectPhaseSelector
				value={calendarState.selectedValues.projectPhaseId}
				onChange={(projectPhaseId) => {
					calendarState.updateSelectedValues({ projectPhaseId });
				}}
				style={{
					zIndex: 100,
					width: "100%",
					fontWeight: "bold",
					fontSize: "1.2rem",
				}}
				projectId={calendarState.selectedValues.projectId}
				disabled={disabled}
			/>
			{settings.useTasks && (
				<TaskSelector
					value={calendarState.selectedValues.taskUuid}
					onChange={(taskUuid) => {
						calendarState.updateSelectedValues({ taskUuid });
					}}
					style={{
						zIndex: 100,
						width: "100%",
						fontSize: "1.2rem",
					}}
					projectPhaseId={calendarState.selectedValues.projectPhaseId}
					disabled={disabled}
				/>
			)}
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					textAlign: "center",
					margin: "2rem 0",
					fontSize: "1.2rem",
				}}
			>
				{settings.timeEntryFlags.includes("billable") && (
					<div>
						<Switch
							checked={calendarState.selectedValues.isBillable}
							onChange={(isBillable) => {
								calendarState.updateSelectedValues({
									isBillable,
								});
							}}
							disabled={disabled}
						/>
						<div>Billable</div>
					</div>
				)}
				{settings.timeEntryFlags.includes("variation") && (
					<div>
						<Switch
							checked={calendarState.selectedValues.isVariation}
							onChange={(isVariation) => {
								calendarState.updateSelectedValues({
									isVariation,
								});
							}}
							disabled={disabled}
						/>
						<div>Variation</div>
					</div>
				)}
				{settings.timeEntryFlags.includes("overtime") && (
					<div>
						<Switch
							checked={calendarState.selectedValues.isOvertime}
							onChange={(isOvertime) => {
								calendarState.updateSelectedValues({
									isOvertime,
								});
							}}
							disabled={disabled}
						/>
						<div>Overtime</div>
					</div>
				)}
				{settings.timeEntryFlags.includes("remote") && (
					<div>
						<Switch
							checked={calendarState.selectedValues.remote}
							onChange={(remote) => {
								calendarState.updateSelectedValues({
									remote,
								});
							}}
							disabled={disabled}
						/>
						<div>Remote</div>
					</div>
				)}
				{settings.timeEntryFlags.includes("flexi") && (
					<div>
						<Switch
							checked={calendarState.selectedValues.flexi}
							onChange={(flexi) => {
								calendarState.updateSelectedValues({
									flexi,
								});
							}}
							disabled={disabled}
						/>
						<div>Flexi</div>
					</div>
				)}
			</div>
			{calendarState.selectedItem ? (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<div
							type="text"
							style={{
								flex: "1 1 auto",
								width: "70%",
								margin: "0.25em",
								padding: "0.25em",
								textAlign: "right",
							}}
						>
							Hours:
						</div>
						<HoursInput
							style={{
								flex: "1 1 auto",
								width: "30%",
								margin: "0.25em",
								textAlign: "right",
							}}
							value={calendarState.selectedItem.numMinutes}
							onChange={(numMins) =>
								calendarState.editSelectedItem({
									numMinutes: numMins,
								})
							}
						/>
					</div>
					<div style={{ display: "flex" }}>
						<TimeInput
							style={{
								flex: "1 1 auto",
								width: "50%",
								margin: "0.25em",
								textAlign: "right",
							}}
							numMins={calendarState.selectedItem.startMinutes}
							onChange={(mins) =>
								calendarState.editSelectedItem({
									startMinutes: mins,
								})
							}
						/>
						<TimeInput
							style={{
								flex: "1 1 auto",
								width: "50%",
								margin: "0.25em",
								textAlign: "right",
							}}
							numMins={calendarState.selectedItem.endMinutes}
							onChange={(mins) =>
								calendarState.editSelectedItem({
									numMinutes:
										mins -
										calendarState.selectedItem.startMinutes,
								})
							}
						/>
					</div>
				</>
			) : null}
			<TextArea
				value={calendarState.selectedValues.notes}
				onChange={(e) => {
					calendarState.updateSelectedValues({
						notes: e.target.value,
					});
				}}
				style={{ fontSize: "1.2rem" }}
				placeholder="Enter some notes..."
				autoSize={{ minRows: 5 }}
				allowClear
				disabled={disabled}
			/>
			{!disabled && calendarState.selectedItem && (
				<Button
					danger
					type="primary"
					style={{ marginTop: "2rem", width: "100%" }}
					disabled={calendarState.deletingSelectedItem}
					onClick={calendarState.deleteSelectedItem}
				>
					{calendarState.deletingSelectedItem
						? `Deleting in ${calendarState.deleteCountdown}...`
						: "Delete"}
				</Button>
			)}
			{calendarState.deletingSelectedItem && (
				<Button
					danger
					style={{ marginTop: "1rem", width: "100%" }}
					onClick={calendarState.undoDeleteSelectedItem}
				>
					Undo
				</Button>
			)}
		</div>
	);
});

export const ProjectSelector = observer(
	({
		value,
		onChange,
		style,
		className,
		dropdownClassName,
		permissions,
		bordered,
		disabled,
	}) => {
		const opts = () =>
			projectStatusOptions
				.filter((s) =>
					organisationStore.organisation.settings.timeEntryStatus.includes(
						s
					)
				)
				.map((s) => {
					return (
						s && (
							<OptGroup
								label={camelCaseToSentence(s + "Projects")}
							>
								{getProjectOptions(permissions).map(
									(project) => {
										return (
											(project.status === s && (
												<Option
													key={project.id}
													value={project.id}
												>
													{project.getTitle()}
												</Option>
											)) ||
											null
										);
									}
								)}
							</OptGroup>
						)
					);
				});
		return (
			<Select
				showSearch
				optionFilterProp="children"
				value={value}
				onChange={onChange}
				style={style}
				className={className}
				dropdownClassName={dropdownClassName}
				bordered
				disabled={disabled}
			>
				{opts()}
			</Select>
		);
	}
);

export const ProjectPhaseSelector = observer(
	({
		value,
		onChange,
		style,
		className,
		dropdownClassName,
		projectId,
		bordered,
		disabled,
	}) => {
		const settings = organisationStore.organisation.settings;
		const [project, setProject] = useState(
			organisationStore.getProjectById(projectId)
		);
		const [phases, setPhases] = useState(project?.getVisiblePhases() || []);
		useEffect(() => {
			const proj = organisationStore.getProjectById(projectId);
			setProject(proj);
			setPhases(proj?.getVisiblePhases() || []);
		}, [projectId]);
		return (
			<Select
				showSearch
				optionFilterProp="children"
				value={value}
				onChange={onChange}
				style={style}
				className={className}
				dropdownClassName={dropdownClassName}
				bordered
				disabled={disabled}
			>
				{project && [
					...projectStatusOptions
						.filter((s) =>
							organisationStore.organisation.settings.timeEntryStatus.includes(
								s
							)
						)
						.map((s) => {
							return (
								s && (
									<OptGroup
										label={camelCaseToSentence(
											s + "Phases"
										)}
									>
										{phases.map((phase) => {
											return (
												(phase.status === s && (
													<Option
														key={phase.id}
														value={phase.id}
													>
														{phase.getTitle()}
													</Option>
												)) ||
												null
											);
										})}
									</OptGroup>
								)
							);
						}),
					settings.allowNoPhase && (
						<Option key={-1} value={-1}>
							{project.getProjectNoPhase().getTitle()}
						</Option>
					),
				]}
			</Select>
		);
	}
);

export const TaskSelector = observer(
	({
		value,
		onChange,
		style,
		className,
		dropdownClassName,
		projectPhaseId,
		bordered,
		disabled,
	}) => {
		return (
			<Select
				showSearch
				optionFilterProp="children"
				value={value}
				onChange={onChange}
				style={style}
				className={className}
				dropdownClassName={dropdownClassName}
				bordered
				disabled={disabled}
			>
				{projectPhaseId &&
					organisationStore
						.getProjectPhaseById(projectPhaseId)
						?.tasks.map((task) => {
							return (
								<Option key={task.uuid} value={task.uuid}>
									{task.name}
								</Option>
							);
						})}
			</Select>
		);
	}
);

export const TimeInput = observer(({ numMins, style, onChange, disabled }) => {
	const [val, setVal] = useState(numMinutesToTimeString(numMins));
	useEffect(() => {
		setVal(numMinutesToTimeString(numMins));
	}, [numMins]);
	return (
		<input
			type="text"
			style={style}
			value={val}
			onChange={(e) => setVal(e.target.value)}
			onBlur={(e) => onChange(numberTimeStringToMinutes(e.target.value))}
			disabled={disabled}
		/>
	);
});
