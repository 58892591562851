import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeStoreMixin } from './coincraftFlux.js';
import { organisationStore, actions as organisationActions } from "./organisation.js";
import { router } from './router.js';
import { SubscriptionForm } from './SubscriptionForm.js';


export var TrialExpired = CreateReactClass({
  mixins: [
    makeStoreMixin(organisationStore, function() {
      return {organisationStore: organisationStore};
    })
  ],

  componentWillMount: function() {
    setTimeout(function() {
      organisationActions.initializeOrganisation();
    }, 0);
  },

  render: function() {
    if (this.state.organisationStore.organisation == null) {
      return null;
    }
    return <div className="content container new-dashboard">
      <div className="new-dashboard__panel new-dashboard__panel__inner">
        <h1>Trial expired</h1>
        <p>
          Your Coincraft trial has expired.
        </p>
        <p>
          If you'd like to continue using Coincraft, purchase a subscription
          and you'll be able to continue from where you left off.
        </p>
        <p>
          <strong>You will be able to keep all your data.</strong>
        </p>
        <SubscriptionForm
          organisation={this.state.organisationStore}
          onFinish={this.backToCoincraft}
          finishButtonText="Proceed to Coincraft"
        />
      </div>
    </div>;
  },

  backToCoincraft: function() {
    router.history.replace({pathname: '/dashboard'});
  }
});


