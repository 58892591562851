import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import Immutable from 'immutable';
import Mousetrap from 'mousetrap';
import classNames from 'classnames';
import { mergeProps } from '../utils.js';
import PropTypes from "prop-types";


/**

  Behaviour (basically copying behaviour of Google Sheets)

  - While editing:
    - Enter commits cell
    - Esc rolls back cell
      - This should work properly regardless of whether we started editing the
        cell by double clicking, pressing enter, or typing a character.
    - Arrow keys work as normal in a text box; they do not navigate outside the current cell
    - Tab / Shift-tab commit cell
    - Clicking outside cell commits cell

  - While not editing:
    - Enter or double click begins editing cell, cursor begins at the end of the text but text is not selected.

*/


export const Cell = class extends React.Component {
  render() {
    return (
      <div
          {...mergeProps(
            {
              className: classNames("coincraft-table-cell", 'spreadsheet', {selected: this.props.isSelected}),
              onClick: () => this.props.onClick(),
              onDoubleClick: () => this.props.onDoubleClick(),
            },
            this.props.cellProps
          )}>
        {this.props.isEditable ?
          <CellInput
            {...mergeProps(
              {
                ref: "input",
                isEditing: this.props.isEditing,
                value: this.props.value,
                onChange: this.props.onChange,
              },
              this.props.inputProps
            )}
          />
        : null}
        <div {...mergeProps({className: 'content'}, this.props.contentProps)}>
          {this.props.value}
        </div>
      </div>
    );
  }

  get input() {
    return this.refs.input.input;
  }
}

Cell.defaultProps = {
  isEditable: true,
  onClick: () => null,
  onDoubleClick: () => null
};


class SpreadsheetMousetrap {
  /**
   * Just keep one set of keyboard listeners regardless of how many
   * spreadsheets are on the page. When we get a key event we'll figure
   * out which spreadsheet component to forward it to, if any.
   */
  constructor() {
    let self = this;
    this.mousetrap = new Mousetrap();

    const allChars = `~!@#$%^&*()_+QWERTYUIOP{}|ASDFGHJKL:"ZXCVBNM<>?1234567890-=qwertyuiop[]\\asdfghjkl;'zxcvbnm,./`.split('');
    this.mousetrap.bind(allChars, function(event, key) {
      self.fire('character', event);
    });
    this.mousetrap.bind('escape', function(event) {
      self.fire('escape', event);
    });
    this.mousetrap.bind(['enter'], function(event) {
      self.fire('enter', event);
    });
    this.mousetrap.bind(['tab', 'shift+tab', 'up', 'down', 'left', 'right', 'esc'], function(event, keys) {
      self.fire('navigation', event, keys);
    });

    this.elementLookup = new Map();
  }

  register(element, component) {
    this.elementLookup.set(element, component);
  }

  unregister(element) {
    this.elementLookup.delete(element);
  }

  fire(type, event, keys) {
    let el = event.target;
    while (el != null) {
      let component = this.elementLookup.get(el);
      if (component != null) {
        component.handleKeyEvent(type, event, keys);

        // Without this, tab/shift-tab will focus elements outside the spreadsheet.
        if (keys === 'tab' || keys === 'shift+tab') {
          event.preventDefault();
          event.stopPropagation();
        }

        break;
      }
      el = el.parentNode;
    }
  }
}

const spreadsheetMousetrap = new SpreadsheetMousetrap();



export const Spreadsheet = class extends React.Component {
			constructor(props) {
				super();
				this.state = {
					selection: null,
					isEditing: false
				};
			}

			componentDidMount() {
				spreadsheetMousetrap.register(this.refs.self, this);
			}

			componentWillUnmount() {
				spreadsheetMousetrap.unregister(this.refs.self);
			}

			render() {
				let self = this;

				return (
					<div
						ref="self"
						// So we can call this.focusSelf(). See documentation for that method as to why.
						tabIndex={0}
						style={this.props.style}
						className="coincraft-table spreadsheet"
					>
						<div>
							{this.props.rows.map(function(row, rowIndex) {
								return (
									<CellRow
										key={rowIndex}
										ref={`row_${rowIndex}`}
										row={row}
										rowIndex={rowIndex}
										columns={self.props.columns}
										cellComponent={self.props.cellComponent}
										selection={self.state.selection}
										isEditing={self.state.isEditing}
										handleCellContentChange={(
											cell,
											value
										) =>
											self.handleCellContentChange(
												cell,
												value
											)
										}
										handleCellClick={(
											rowIndex,
											columnIndex
										) =>
											self.handleCellClick(
												rowIndex,
												columnIndex
											)
										}
										handleCellDoubleClick={(
											rowIndex,
											columnIndex
										) =>
											self.handleCellDoubleClick(
												rowIndex,
												columnIndex
											)
										}
									/>
								);
							})}
						</div>
					</div>
				);
			}

			getInput(rowIndex, columnIndex) {
				return this.refs[`row_${this.state.selection.rowIndex}`].refs[
					`cell_${this.state.selection.rowIndex}_${this.state.selection.columnIndex}`
				].input;
			}

			getCurrentInput() {
				if (this.state.selection == null) {
					return null;
				} else {
					return this.getInput(
						this.state.selection.rowIndex,
						this.state.selection.columnIndex
					);
				}
			}

			handleKeyEvent(type, event, keys) {
				if (type === "character") {
					if (!this.state.isEditing && this.state.selection != null) {
						this.beginEditingCell(
							this.state.selection.rowIndex,
							this.state.selection.columnIndex,
							() => this.getCurrentInput().focus(),
							{ clear: true }
						);
					}
				} else if (type === "escape") {
					if (this.state.isEditing) {
						this.rollbackCurrentCell();
					}
				} else if (type === "enter") {
					if (this.state.isEditing) {
						this.commitCurrentCell();
					} else {
						this.beginEditingCell(
							this.state.selection.rowIndex,
							this.state.selection.columnIndex,
							() => this.getCurrentInput().focus()
						);
					}
				} else if (type === "navigation") {
					const keysLookup = {
						left: { offsets: [0, -1] },
						"shift+tab": { offsets: [0, -1], whileEditing: true },
						right: { offsets: [0, 1] },
						tab: { offsets: [0, 1], whileEditing: true },
						up: { offsets: [-1, 0] },
						down: { offsets: [1, 0] }
					};

					const keyData = keysLookup[keys];
					if (
						keyData != null &&
						(!this.state.isEditing || keyData.whileEditing)
					) {
						this.commitCurrentCell();
						this.navigateBy(...keyData.offsets, keys);
					}
				}
			}

			handleCellContentChange(cell, value) {
				this.props.onSetInputText(cell, value);
			}

			handleCellClick(rowIndex, columnIndex) {
				// TODO - handle null
				const cell = this.props.rows[rowIndex].cells[columnIndex];
				const isEditable = !(cell.isEditable === false);
				if (
					this.state.selection == null ||
					this.state.selection.rowIndex !== rowIndex ||
					this.state.selection.columnIndex !== columnIndex
				) {
					this.commitCurrentCell();
					if (isEditable) {
						this.setState({ selection: { rowIndex, columnIndex } });
						this.props.selectCell(cell);
					}
				}
			}

			handleCellDoubleClick(rowIndex, columnIndex) {
				const isEditable = !(
					this.props.rows[rowIndex].cells[columnIndex].isEditable ===
					false
				);
				if (isEditable) {
					this.beginEditingCell(rowIndex, columnIndex, () =>
						this.getInput(rowIndex, columnIndex).focus()
					);
				}
			}

			navigateBy(rowOffset, columnOffset, keys) {
				if (this.state.selection != null) {
					const rowIndex = this.state.selection.rowIndex + rowOffset;
					const columnIndex =
						this.state.selection.columnIndex + columnOffset;
					const nextRow = this.props.rows[rowIndex];
					const nextCol = this.props.columns[columnIndex];
					const nextCell =
						nextRow && nextCol
							? nextRow.cells[columnIndex]
							: undefined;

					if (
						nextCell &&
						!nextCell.isRowHeader &&
						!nextCell.isColumnHeader
					) {
						if (nextCell.isEditable !== false) {
							this.setState({
								selection: {
									rowIndex: rowIndex,
									columnIndex: columnIndex
								},
								isEditing: false
							});
							this.props.selectCell(nextCell);
						}
					} else {
						this.props.edgeKeyPress(keys);
					}
				}
			}

			beginEditingCell(
				rowIndex,
				columnIndex,
				callback,
				{ clear = false } = {}
			) {
				let self = this;
				const row = this.props.rows[this.state.selection.rowIndex];

				this.props
					.onSetInputText(
						row.cells[columnIndex],
						clear
							? ""
							: this.props.valueToInputText(
									row.cells[this.state.selection.columnIndex]
										.value
							  )
					)
					.then(function() {
						self.setState({ isEditing: true }, callback);
					});
			}

			commitCurrentCell() {
				let self = this;
				if (this.state.isEditing) {
					const row = this.props.rows[this.state.selection.rowIndex];
					this.props
						.onCommitCell(
							row.cells[this.state.selection.columnIndex],
							row.cells[this.state.selection.columnIndex]
								.inputText
						)
						.then(function() {
							self.setState({ isEditing: false });
							self.focusSelf();
						});
				}
			}

			rollbackCurrentCell() {
				let self = this;
				this.props
					.onRollbackCell(
						this.props.rows[this.state.selection.rowIndex].cells[
							this.state.selection.columnIndex
						]
					)
					.then(function() {
						self.setState({ isEditing: false });
						self.focusSelf();
					});
			}

			focusSelf() {
				// On committing / rolling back a cell, the input element we were typing in is hidden
				// and the focus goes back to body. This breaks subsequent keyboard navigation since
				// the spreadsheet no longer has focus.
				this.refs.self.focus();
			}
		};

Spreadsheet.propTypes = {
  /**
   * List of Lists of Maps that have at least a 'value' attribute.
   */
  rows: PropTypes.instanceOf(Immutable.List).isRequired,

  columns: PropTypes.instanceOf(Immutable.List).isRequired,

  /**
   * Must be a component with an `input` property that returns a ref of an HTML
   * <input> element. See the `Cell` class for an example.
   */
  cellComponent: PropTypes.any,

  valueToInputText: PropTypes.func,

  // function(rows, rowIndex, columnIndex, inputText)
  // Must return a promise.
  onCommitCell: PropTypes.func.isRequired,

  // function(rows, rowIndex, columnIndex)
  // Must return a promise.
  onRollbackCell: PropTypes.func.isRequired,

  // function(rows, rowIndex, columnIndex, inputText)
  // Must return a promise.
  onSetInputText: PropTypes.func.isRequired,

  // for when arrow keys pressed at edge of spreadsheet
  edgeKeyPress: PropTypes.func
};


Spreadsheet.defaultProps = {
  cellComponent: Cell,
  valueToInputText: val => val.toString(),
  edgeKeyPress: () => null,
  selectCell: () => null,
};


function clamp(val, min, max) {
  if (val < min) {
    return min;
  }
  else if (val > max) {
    return max;
  }
  else {
    return val;
  }
}


class CellRow extends React.Component {

  render() {
    let self = this;
    let {row, rowIndex,
      columns, selection, handleCellContentChange,
      handleCellClick, handleCellDoubleClick} = self.props;
    const Cell = self.props.cellComponent;
    const rowType = self.props.row.rowType || '';

    return <div key={rowIndex} className={`coincraft-table-row spreadsheet ${rowType}`} tabIndex="-1">
      {columns.map(function(column, columnIndex) {
        const isSelected = (selection != null
          && rowIndex === selection.rowIndex
          && columnIndex === selection.columnIndex
        );
        const isEditing = isSelected && self.props.isEditing;

        return <Cell
          key={columnIndex}
          ref={`cell_${rowIndex}_${columnIndex}`}
          row={row}
          rowIndex={rowIndex}
          column={column}
          columnIndex={columnIndex}
          cell={row.cells[columnIndex]}
          isSelected={isSelected}
          isEditing={isEditing}
          onChange={(value) => handleCellContentChange(row.cells[columnIndex], value)}
          onClick={() => handleCellClick(rowIndex, columnIndex)}
          onDoubleClick={() => handleCellDoubleClick(rowIndex, columnIndex)}
        />;
      })}
    </div>
  }
}



class CellInput extends React.Component {
  render() {
    return <input
      ref="input"

      // This is required in order to make keys like esc and tab hit our
      // mousetrap events.
      className="mousetrap"

      // Why use display: none rather than just making the input not exist
      // when it's not displayed?  Because when the user presses the
      // initial key that causes the input to be displayed, we need to be
      // able to call focus() on the input element so it can receive that key. So
      // the input needs to already be there, even if hidden.
      style={!this.props.isEditing ? {display: 'none'} : null}
      value={this.props.value || ''}
      onChange={e => this.props.onChange(e.target.value)}
    />;
  }

  get input() {
    return this.refs.input;
  }
}
