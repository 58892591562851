import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { Budgets } from './SidebarUtils.js';
import { permissions, requiresPermission, OperationLevel } from '../models/permissions.js';

import PropTypes from "prop-types";

export var ProjectBudgetedExpensesPanel = requiresPermission(
  ({project}) => permissions.projectFinancialsBudgets(project),
  {},
  CreateReactClass({
    propTypes: {
      project: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired // Provided by `requiresPermission`
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Budgeted Expenses</h3>
          <Budgets
            isEditable={this.props.permissionLevel === OperationLevel.edit}
            item={this.props.project}
            budgetType='expenses'
            getItemBudget={(item) => item.getBudget()}
            actions={{
              calculate: actions.projectSidebarCalculateExpenses,
              setManualBudget: actions.projectSidebarSetProjectManualBudget,
              setPhaseBudget: actions.projectSidebarSetPhaseManualBudget
            }}
          />
        </div>
      );
    }
  }
));


export var PhaseBudgetedExpensesPanel = requiresPermission(
  ({phase}) => permissions.projectFinancialsBudgets(phase.project),
  {},
  CreateReactClass({
    propTypes: {
      phase: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired // Provided by `requiresPermission`
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Budgeted Expenses</h3>
          <Budgets
            isEditable={this.props.permissionLevel === OperationLevel.edit}
            item={this.props.phase}
            user={this.props.user}
            budgetType='expenses'
            getItemBudget={(item) => item.manualBudget}
            actions={{
              calculate: actions.phaseSidebarCalculateExpenses,
              setManualBudget: actions.phaseSidebarSetPhaseManualBudget
            }}
          />
        </div>
      );
    }
  })
);
