import _ from "underscore";
import moment from "moment";
import { getDateRangeById } from "../reports/DateRanges.js";
import { getBudget, getHoursBudget } from "../organisationStore.js";
import { createSelector } from "reselect";
import { groupRows } from "../groupRows.js";
import { fieldTypeToMatcherTypeLookup } from "../widgets/filterwidget.js";
import { Checkbox } from "../widgets/generic.js";
import { izip, sum, compareMultiple } from "../utils.js";
import { dispatcher, handleAction, registerActions } from "../coincraftFlux.js";
import { ReportStore } from "../reports/flux.js";
import { bulkUpdateEntries } from "./flux.js";
import { Column } from "../table.js";
import { rootStore } from "../RootStore.js";
import { Report, parseFilter } from "../reports/Report.js";
import { router } from "../router.js";
import { userStore } from "../user/flux.js";
import { getEntries } from "./ReportQuery.js";
import {
	isRowChecked,
	isRowIndeterminate,
	isRowUnlocked,
} from "../groupRows.js";
import Immutable from "immutable";
import React from "react";
import CreateReactClass from "create-react-class";
import { NoPhasePhase } from "../models/nophasephase.js";
import { dateConverter } from "../models/dateconverter.js";
import { FinancialsVisibility } from "../models/permissions.js";

/*
TODO-filter_by_tasks refresh on project planner
*/
// needs to exist on timesheet entry
export const groupingOptions = [
	{ id: "project", label: "Project" },
	{ id: "projectPhase", label: "Phase" },
	{ id: "task", label: "Task" },
	{ id: "staffMember", label: "Staff Member" },
	{ id: "staffRole", label: "Staff Role" },
	{ id: "date", label: "Date" },
	{ id: "monthIndex", label: "Month" },
	{ id: "costCentre", label: "Cost Centre" },
	{ id: "projectOwner", label: "Project Owner" },
];

export const columnRequirements = {
	costCentre: null,
	staffCostCentre: null,
	project: null,
	projectPhase: null,
	projectCode: null,
	projectPhaseCode: null,
	staffMember: null,
	staffMemberFirstName: null,
	staffMemberLastName: null,
	staffMemberRole: null,
	date: null,
	monthIndex: null,
	hasNotes: null,
	projectOwner: null,
	task: null,
	isBillable: null,
	isVariation: null,
	isOvertime: null,
	flexi: null,
	remote: null,
	isLocked: null,
	beenInvoiced: null,
	budget: FinancialsVisibility.allExceptPay,
	hoursBudget: null,
	numMinutes: null,
	startMinutes: null,
	endMinutes: null,
	labourExpense: FinancialsVisibility.all,
	cost: FinancialsVisibility.allExceptPay,
	chargeOut: FinancialsVisibility.allExceptPay,
	chargeOutRate: FinancialsVisibility.allExceptPay,
	notes: null,
};

const actionDefinitions = [
	{ action: "editSelectedEntries", args: [] },
	{ action: "toggleAllChecked", args: ["groups"] },
	{
		action: "batchEditEntries",
		args: [
			"modal",
			"entries",
			"project",
			"projectPhase",
			"task",
			"isBillable",
			"isVariation",
			"isOvertime",
			"isLocked",
			"beenInvoiced",
			"flexi",
			"remote",
		],
	},
	{ action: "batchEditEntriesSaveSuccess", args: ["modal"] },
	{ action: "batchEditEntriesSaveFailure", args: ["modal"] },
	{ action: "closeModal", args: ["modal"] },
	{ action: "changeExpandToGroup", args: ["groupId", "groups"] },
	{ action: "expandAllGroups", args: ["groups"] },
	{ action: "collapseAllGroups", args: [] },
];

function batchEditDefaults() {
	return {
		isSaving: false,
		isError: false,
	};
}

export function getGroupFee({
	project,
	projectPhase,
	staffRole,
	staffMember,
	task,
	date,
	monthIndex,
	projectOwner,
	costCentre,
}) {
	if (
		staffRole ||
		staffMember ||
		task ||
		date ||
		monthIndex ||
		costCentre ||
		projectOwner
	) {
		return null;
	} else if (projectPhase === undefined && project != null) {
		return project.getFee();
	} else if (projectPhase != null) {
		return projectPhase.fee;
	} else if (projectPhase === null) {
		return 0;
	} else {
		return null;
	}
}

export function getGroupRevenue(
	{
		project,
		projectPhase,
		staffRole,
		staffMember,
		task,
		date,
		monthIndex,
		costCentre,
		projectOwner,
	},
	dateRange
) {
	if (
		(staffRole ||
			staffMember ||
			task ||
			date ||
			monthIndex ||
			costCentre ||
			projectOwner) &&
		!project &&
		!projectPhase
	) {
		return null;
	} else if (projectPhase === undefined && project != null) {
		return project.getRevenueInDateRange(dateRange);
	} else if (projectPhase != null) {
		return projectPhase.getRevenueInDateRange(dateRange);
	} else if (projectPhase === null) {
		let phase = new NoPhasePhase({ project: project });
		return phase.getRevenueInDateRange(dateRange);
	} else {
		return null;
	}
}

class TimesheetReportStore {
	constructor() {
		let self = this;

		this.checkboxColumn = new Column({
			width: "4%",
			data: () => null,
			content: function (row) {
				if (isRowUnlocked(row) || userStore.isAdmin()) {
					return (
						<Checkbox
							value={isRowChecked(
								row,
								self.reportStore.selectedItems
							)}
							indeterminate={isRowIndeterminate(
								row,
								self.reportStore.selectedItems
							)}
							onChange={function (value) {
								self.reportStore.actions.toggleRowCheck(row);
							}}
							onClick={function (event) {
								event.stopPropagation();
							}}
							className="dont-print"
						/>
					);
				} else {
					return (
						<i
							className="fa fa-lock fa-fw"
							style={{ color: "#888" }}
						/>
					);
				}
			},
			type: "checkbox",
			canFilter: false,
			canSort: false,
		});

		const columns = [
			new Column({
				identifier: "costCentre",
				header: "Cost centre",
				width: "15%",
				data: (row) => row.costCentre,
				content: (row, i, stack, data) =>
					data != null ? data.name : "",
				type: "costCentre",
			}),
			new Column({
				identifier: "staffCostCentre",
				header: "Staff Cost centre",
				width: "15%",
				data: (row) =>
					row.staffMember != null ? row.staffMember.costCentre : null,
				content: (row, i, stack, data) =>
					data != null ? data.name : "",
				type: "costCentre",
			}),
			new Column({
				identifier: "project",
				header: "Project",
				width: "15%",
				data: (row) => row.project,
				content: (row, i, stack, data) =>
					data != null ? data.getTitle() : "(No project)",
				type: "project",
			}),
			new Column({
				identifier: "projectOwner",
				header: "Project Owner",
				width: "15%",
				data: (row) =>
					row.projectOwner || row.project?.owner || row.owner,
				content: (row, i, stack, staffMember) => {
					return staffMember != null
						? staffMember.getFullName()
						: "(No Project Owner)";
				},
				type: "staffMember",
			}),
			new Column({
				identifier: "projectContact",
				header: "Project Client",
				width: "15%",
				data: (row) => row.project?.contact,
				content: (row, i, stack, contact) => {
					return contact != null ? contact.display() : null;
				},
				type: "contact",
			}),
			new Column({
				identifier: "projectInvoiceContact",
				header: "Project Primary Contact",
				width: "15%",
				data: (row) => row.project?.invoiceContact,
				content: (row, i, stack, contact) => {
					return contact != null ? contact.display() : null;
				},
				type: "contact",
			}),
			new Column({
				identifier: "projectPhase",
				header: "Phase",
				width: "15%",
				data: (row) => row.projectPhase,
				content: (row, i, stack, data) =>
					data != null ? data.getTitle() : "(No phase)",
				type: "projectPhase",
			}),
			new Column({
				identifier: "projectCode",
				header: "Project Code",
				width: "5%",
				data: (row) => row.project,
				content: (row, i, stack, data) =>
					data && data.jobCode ? data.jobCode : "-",
				type: "string",
			}),
			new Column({
				identifier: "projectPhaseCode",
				header: "Phase Code",
				width: "5%",
				data: (row) => row.projectPhase,
				content: (row, i, stack, data) =>
					data && data.jobCode ? data.jobCode : "-",
				type: "string",
			}),
			new Column({
				identifier: "staffMember",
				header: "Staff Member",
				width: "15%",
				data: (row) => row.staffMember,
				content: (row, i, stack, staffMember) =>
					staffMember != null ? staffMember.getFullName() : null,
				type: "staffMember",
			}),
			new Column({
				identifier: "staffMemberFirstName",
				header: "Staff Member First Name",
				width: "10%",
				data: (row) => row.staffMember,
				content: (row, i, stack, staffMember) =>
					staffMember && staffMember.firstName
						? staffMember.firstName
						: "-",
				type: "string",
			}),
			new Column({
				identifier: "staffMemberLastName",
				header: "Staff Member Last Name",
				width: "10%",
				data: (row) => row.staffMember,
				content: (row, i, stack, staffMember) =>
					staffMember && staffMember.lastName
						? staffMember.lastName
						: "-",
				type: "string",
			}),
			new Column({
				identifier: "staffRole",
				header: "Staff Role",
				width: "10%",
				data: (row) => row.staffRole,
				content: (row, i, stack, staffRole) =>
					staffRole != null ? staffRole.name : null,
				type: "staffRole",
			}),
			new Column({
				identifier: "date",
				header: "Date",
				width: "15%",
				data: (row) => row.date,
				content: (row) =>
					row.date != null ? row.date.format("DD/MM/YYYY") : null,
				type: "moment",
				canFilter: false, // Because we already have the 'use data from:' filter
			}),
			new Column({
				identifier: "monthIndex",
				header: "Month",
				width: "15%",
				data: (row) => (row.monthIndex != null ? row.monthIndex : null),
				content: (row) =>
					row.monthIndex != null
						? dateConverter
								.monthIndexToMoment(row.monthIndex)
								.format("MMM YY")
						: null,
				type: "month",
				canFilter: false, // Because we already have the 'use data from:' filter
			}),
			new Column({
				identifier: "hasNotes",
				header: "Has Notes",
				width: "10%",
				data: (row) => row.hasNotes,
				type: "bool",
			}),
			new Column({
				identifier: "task",
				header: "Task",
				width: "15%",
				data: (row) => row.task,
				content: function (item, i, stack, task) {
					if (task != null) {
						return task.name;
					} else if (
						stack.length < self.reportStore.report.groupBy.length
					) {
						// If the task is null only because we're in a header row which doesn't make sense
						// for tasks, don't say anything.
						return null;
					} else {
						return "(No task)";
					}
				},
				type: "task",
			}),
			new Column({
				identifier: "isBillable",
				header: "Is Billable",
				width: "10%",
				data: (row) => row.isBillable,
				type: "bool",
			}),
			new Column({
				identifier: "isVariation",
				header: "Is Variation",
				width: "10%",
				data: (row) => row.isVariation,
				type: "bool",
			}),
			new Column({
				identifier: "isOvertime",
				header: "Is Overtime",
				width: "10%",
				data: (row) => row.isOvertime,
				type: "bool",
			}),
			new Column({
				identifier: "flexi",
				header: "Flexi",
				width: "10%",
				data: (row) => row.flexi,
				type: "bool",
			}),
			new Column({
				identifier: "remote",
				header: "Remote",
				width: "10%",
				data: (row) => row.remote,
				type: "bool",
			}),
			new Column({
				identifier: "isLocked",
				header: "Is Locked",
				width: "10%",
				data: (row) => row.isLocked,
				type: "bool",
			}),
			new Column({
				identifier: "beenInvoiced",
				header: "Been Invoiced",
				width: "10%",
				data: (row) => row.beenInvoiced,
				type: "bool",
			}),
			new Column({
				identifier: "fee",
				header: "Fee",
				width: "10%",
				data: (row) => row.fee || null,
				type: "number",
				canFilter: false,
			}),
			new Column({
				identifier: "revenue",
				header: "Revenue",
				width: "10%",
				data: (row) => {
					return row.revenue || null;
				},
				type: "number",
				canFilter: false,
			}),
			new Column({
				identifier: "remainingFee",
				header: "Remaining Fee",
				width: "10%",
				data: groupFieldData(
					() => self.reportStore.report.groupBy,
					(options) =>
						(getGroupFee(options) || 0) -
						(getGroupRevenue(
							options,
							this.reportStore.report.dateRange
						) || 0)
				),
				type: "number",
				canFilter: false,
			}),
			new Column({
				identifier: "budget",
				header: "Budget",
				width: "10%",
				data: groupFieldData(
					() => self.reportStore.report.groupBy,
					getBudget
				),
				type: "number",
				canFilter: false,
			}),
			new Column({
				identifier: "hoursBudget",
				header: "Hours budget",
				width: "10%",
				data: groupFieldData(
					() => self.reportStore.report.groupBy,
					getHoursBudget
				),
				type: "number",
				canFilter: false,
			}),
			new Column({
				identifier: "numMinutes",
				header: "Hours",
				width: "10%",
				data: (row) => row.numMinutes / 60,
				type: "number",
			}),
			new Column({
				identifier: "startMinutes",
				header: "Start Time",
				width: "10%",
				data: (row) => row.startMinutes / 60,
				type: "numberTime",
			}),
			new Column({
				identifier: "endMinutes",
				header: "End Time",
				width: "10%",
				data: (row) => (row.startMinutes + row.numMinutes) / 60,
				type: "numberTime",
			}),
			new Column({
				identifier: "labourExpense",
				header: "Labour expense",
				width: "10%",
				data: (row) => row.pay,
				type: "number",
			}),
			new Column({
				identifier: "cost",
				header: "Expense (cost)",
				width: "10%",
				data: (row) => row.cost,
				type: "number",
			}),
			new Column({
				identifier: "chargeOut",
				header: "Charge-out",
				width: "10%",
				data: (row) => row.chargeOut,
				type: "number",
			}),
			new Column({
				identifier: "chargeOutRate",
				header: "Charge-out rate",
				width: "10%",
				data: (row) => row.chargeOutRate,
				type: "number",
			}),
			new Column({
				identifier: "notes",
				header: "Notes",
				width: "20%",
				content: (row) => row.notes,
				type: "string",
			}),
			new Column({
				identifier: "projectStatus",
				header: "Project Status",
				width: "10%",
				data: (item) => item.project.status,
				type: "projectStatus",
			}),
			new Column({
				identifier: "phaseStatus",
				header: "Phase Status",
				width: "10%",
				data: (item) => item.projectPhase.status,
				type: "projectStatus",
			}),
		];

		this.state = null; // `null`, 'loading', 'loaded'
		this.entries = null;

		this.batchEdit = batchEditDefaults();

		this.reportStore = new ReportStore({
			path: "timesheet-reports-page/report",
			columns: columns,
		});
		this.reportStore.sortBy = {
			columnIdentifier: "date",
			direction: "asc",
		};

		this.reportStore.isExpanded = true;

		this.reloadEntriesSelector = createSelector(
			[
				(args) => args.startDate,
				(args) => args.endDate,
				(args) => args.filters,
				(args) => args.columns,
				(args) => args.groupBy,
			],
			function () {
				return self._reloadEntries();
			}
		);

		this.getMatchingEntriesSelector = createSelector(
			[(args) => args.entries, (args) => args.filters],
			function (entries, filters) {
				return self._getMatchingEntries(entries);
			}
		);

		this.actionDefinitions = actionDefinitions;
		this.modals = [];

		this.groupRows = this.makeGroupRowsSelector();
	}

	makeGroupRowsSelector() {
		return createSelector(
			[
				(args) => args.items,
				(args) => args.groupers,
				(args) => args.expandedGroups,
				(args) => args.selectedItems,
				(args) => args.sortBy,
				(args) => args.report,
				(args) => args,
			],
			function (
				items,
				groupers,
				expandedGroups,
				selectedItems,
				sortBy,
				report,
				args
			) {
				const aggregates = {
					budget: projectOrPhaseAggregate(
						groupers,
						(ob) => ob.manualBudget
					),
					hoursBudget: projectOrPhaseAggregate(
						groupers,
						(ob) => ob.manualHoursBudget
					),
					revenue: (tes) => {
						const phases = [
							...new Set(tes.map((t) => t.projectPhase)),
						];
						const revenues = phases.map(
							(ph) =>
								ph?.getRevenueInDateRange?.(report.dateRange) ||
								0
						);
						return sum(revenues);
					},
					fee: (tes) => {
						return sum(
							[...new Set(tes.map((t) => t.projectPhase))].map(
								(ph) => ph?.fee || 0
							)
						);
					},
					numMinutes: (tes) => sum(tes.map((te) => te.numMinutes)),
					pay: (tes) => sum(tes.map((te) => te.pay)),
					cost: (tes) => sum(tes.map((te) => te.cost)),
					chargeOut: (tes) => sum(tes.map((te) => te.chargeOut)),
					chargeOutRate: (tes) =>
						sum(tes.map((te) => te.chargeOut)) /
						(sum(tes.map((te) => te.numMinutes)) / 60),
				};
				const aggregateColumns = Object.keys(aggregates);

				// First, sort the ungrouped entries by the group columns and additionally the sorted column.
				let columns = groupers.map((g) =>
					timesheetReportStore.reportStore.getColumnById(g)
				);
				let comparator;

				let baseComparator = compareMultiple(
					...columns.map(
						(c, i) =>
							function (a, b) {
								return c.compare(a[i], b[i]);
							}
					)
				);

				if (sortBy != null) {
					if (sortBy.columnIdentifier === "grouper") {
						if (sortBy.direction === "desc") {
							comparator = (a, b) => -baseComparator(a, b);
						} else {
							comparator = baseComparator;
						}
					} else {
						const sortColumn =
							timesheetReportStore.reportStore.getColumnById(
								sortBy.columnIdentifier
							);
						columns.push(sortColumn);
						const multiple = sortBy.direction === "asc" ? 1 : -1;
						const sortComparator = (a, b) =>
							multiple * sortColumn.compare(_.last(a), _.last(b));
						comparator = compareMultiple(
							baseComparator,
							sortComparator
						);
					}
				} else {
					comparator = baseComparator;
				}

				const rowsAndData = items.map(function (r) {
					return columns.map((c) => ({ row: r, data: c.data(r) }));
				});
				items = rowsAndData.sort(comparator).map((r) => r[0].row);

				let r = groupRows({
					items: items,
					groupers: groupers,
					expandedGroups: expandedGroups,
					selectedItems: selectedItems,
					sortBy: sortBy,
					aggregates: aggregates,
					idFuncs: {
						project: (p) => (p != null ? p.id : 0),
						projectPhase: (p) =>
							p != null ? p.getTitle() : "(No Phase)",
						task: (t) => (t != null ? t.name : 0),
						staffMember: (sm) => (sm != null ? sm.id : 0),
						staffRole: (sr) => (sr != null ? sr.id : 0),
						costCentre: (cc) =>
							cc != null ? cc.name : "(No Cost Centre)",
					},
				});

				// On these columns we filter by the aggregate value for the top-level group.
				const aggregateFilterColumns = [
					"numMinutes",
					"cost",
					"chargeOut",
					"chargeOutRate",
				];
				const matcher = timesheetReportStore.reportStore.getItemMatcher(
					report.update("filters", (fs) =>
						fs.filter((f) =>
							_.include(aggregateFilterColumns, f.get("columnId"))
						)
					)
				);
				r = r.filter(matcher);

				// Then, if we have sorted by an aggregate column, sort the groups by
				// that aggregate column.  Eg. if we sort by numMinutes, then sort the
				// projects by their total numMinutes values. Note that this means we
				// have sorted twice by the same column: groups are sorted by their
				// total numMinutes, and the entries within the groups are also sorted
				// by numMinutes.
				if (
					sortBy != null &&
					_.include(aggregateColumns, sortBy.columnIdentifier)
				) {
					const multiple = sortBy.direction === "asc" ? 1 : -1;
					r.sort(
						(a, b) =>
							multiple *
							(a[sortBy.columnIdentifier] -
								b[sortBy.columnIdentifier])
					);
				}

				return r;
			}
		);
	}

	emitChanged() {
		rootStore.emitChanged();
	}

	handle(action) {
		let self = this;
		if (action.type.startsWith("report/")) {
			if (action.type === "report/refreshTable") {
				this.reloadEntries().then(function () {
					self.reportStore.handle(action);
					self.emitChanged();
				});
			} else {
				this.reportStore.handle(action);
				this.emitChanged();
			}
		} else {
			handleAction(action, this);
		}
	}

	isColumnVisible(id) {
		if (id == null) {
			throw new Error("ValueError");
		}
		const requirement = columnRequirements[id];
		return (
			requirement == null ||
			FinancialsVisibility.isAtLeast(
				userStore.user.permissions.financialsVisibility,
				requirement
			)
		);
	}

	get visibleColumns() {
		return this.columns.filter((c) => this.isColumnVisible(c));
	}

	get selectedVisibleColumns() {
		return this.reportStore.report.columns
			.filter((c) => this.isColumnVisible(c))
			.map((c) => this.reportStore.getColumnById(c));
	}

	newReport() {
		this.reportStore.setReport(
			this.fixValues(
				new Report({
					name: "New report",
					reportType: "timesheet",

					dateRange: getDateRangeById("this_month"),
					columns: [
						"staffMember",
						"project",
						"projectPhase",
						"date",
						"task",
						"numMinutes",
						"cost",
						"chargeOut",
						"notes",
					],
					groupBy: ["project", "projectPhase"],
					filters: Immutable.fromJS([
						parseFilter({
							columnId: "project",
							matcher: {
								type: "project",
								value: [],
								operation: "any",
							},
						}),
						parseFilter({
							columnId: "staffMember",
							matcher: {
								type: "staffMember",
								value: [],
								operation: "any",
							},
						}),
					]),
				})
			)
		);
	}

	refresh() {
		this.reloadEntries();
		this.emitChanged();
	}

	reloadEntries() {
		const [start, end] = this.reportStore.report.dateRange.getDates(
			moment()
		);
		this.reportStore.selectedItems = [];
		return this.reloadEntriesSelector({
			filters: this.reportStore.report.filters,
			startDate: start,
			endDate: end,
			columns: this.reportStore.report.columns,
			groupBy: this.reportStore.report.groupBy,
		});
	}

	_reloadEntries(startDate, endDate) {
		let self = this;
		this.state = "loading";
		this.emitChanged();
		return getEntries(this.reportStore.report).then(function ({ entries }) {
			self.entries = entries.filter(function (te) {
				return (
					te.numMinutes > 0 || (te.notes != null && te.notes !== "")
				);
			});
			self.state = "loaded";
			self.expandToGroup =
				self.reportStore?.report?.groupBy[
					self.reportStore?.report?.groupBy?.length - 1
				];
			self.emitChanged();
		});
	}

	fixValues(report) {
		if (userStore.user.permissions.isAtLeastViewer) {
			return report;
		} else {
			// If the user is a timesheet user, show them that they are only
			// retrieving their own entries. We don't strictly need to do this as the
			// server will do this anyway; this is just a UI issue in being explicit
			// about it. Also we don't currently do anyting special to prevent the
			// user modifying or deleting this filter; though their modifications
			// won't have any effect.
			return report.fixValues({
				staffMember: () =>
					new fieldTypeToMatcherTypeLookup.staffMember({
						operation: "any",
						type: "staffMember",
						value: [userStore.user.id],
					}),
			});
		}
	}

	loadReport(report) {
		if (report == null) {
			this.newReport();
		} else {
			this.reportStore.report = this.fixValues(report);
		}

		this.reloadEntries();
		this.emitChanged();
	}

	getMatchingEntries() {
		return this.getMatchingEntriesSelector({
			filters: this.reportStore.report.filters,
			entries: this.entries,
			columns: this.reportStore.report.columns,
			dateRange: this.reportStore.report.dateRange,
		});
	}

	_getMatchingEntries(entries) {
		// We no longer use the report store's client-side matchers from here since:
		// 1. We now get all our results from the server
		// 2. We don't want to re-filter every time the user types a keystroke. We
		// have a refresh button now that the user can use when they want to.
		return entries || [];
	}

	toDefaultPage() {
		router.history.push("/dashboard/timesheet");
	}

	editSelectedEntries() {
		this.batchEdit = batchEditDefaults();
		this.modals = [
			...this.modals,
			{
				type: "editSelectedEntries",
				// Filter out selected groups because the modal doesn't understand them. We just
				// want the list of entries.
				entries: this.reportStore.selectedItems.filter(
					(i) => i.children == null
				),
			},
		];
		this.emitChanged();
	}

	isAllChecked(groups) {
		return groups.every((g) =>
			isRowChecked(g, this.reportStore.selectedItems)
		);
	}

	isAllIndeterminate(groups) {
		return isRowIndeterminate(
			{ children: groups },
			this.reportStore.selectedItems
		);
	}

	toggleAllChecked(groups) {
		const isChecked = this.isAllChecked(groups);
		for (let g of groups) {
			this.reportStore.setRowChecked(g, !isChecked);
		}
		this.emitChanged();
	}

	batchEditEntries(
		modal,
		entries,
		project,
		projectPhase,
		task,
		isBillable,
		isVariation,
		isOvertime,
		isLocked,
		beenInvoiced,
		flexi,
		remote
	) {
		const updateStaffMinutes = () => {
			entries.forEach((te) => {
				if (te.projectPhase)
					te.projectPhase.staffMinutes[te.staffMember.id] -=
						te.numMinutes;
				if (
					projectPhase &&
					te.projectPhase &&
					!te.projectPhase.staffMinutes[te.staffMember.id]
				) {
					delete te.projectPhase.staffMinutes[te.staffMember.id];
				}
				if (projectPhase) {
					projectPhase.staffMinutes[te.staffMember.id] =
						projectPhase.staffMinutes[te.staffMember.id] || 0;
					projectPhase.staffMinutes[te.staffMember.id] +=
						te.numMinutes;
				}
			});
		};
		this.batchEdit.isError = false;
		this.batchEdit.isSaving = true;
		this.emitChanged();

		bulkUpdateEntries({
			report: this.reportStore.report,
			timesheetEntries: entries,
			project: project,
			projectPhase: projectPhase,
			task: task,
			isBillable: isBillable,
			isVariation: isVariation,
			isOvertime: isOvertime,
			isLocked: isLocked,
			beenInvoiced: beenInvoiced,
		}).then(
			function () {
				updateStaffMinutes();
				timesheetReportActions.batchEditEntriesSaveSuccess(modal);
			},
			function (err) {
				timesheetReportActions.batchEditEntriesSaveFailure(modal);
			}
		);
	}

	batchEditEntriesSaveSuccess(modal) {
		this.batchEdit.isSaving = false;
		this.reportStore.selectedItems = [];
		this._reloadEntries();
		this.closeModal(modal);
	}

	batchEditEntriesSaveFailure(modal) {
		this.batchEdit.isSaving = false;
		this.batchEdit.isError = true;
	}

	closeModal(modal, { emitChanged = true } = {}) {
		this.modals = _.without(this.modals, modal);
		this.emitChanged();
	}

	changeExpandToGroup(groupId, groups) {
		this.expandToGroup = groupId;
		this.expandAllGroups(groups);
		this.emitChanged();
	}

	expandAllGroups(groups) {
		let expandedGroups = [];
		let groupBy = this.reportStore.report.groupBy;
		let index = groupBy.length;
		for (const [i, g] of groupBy.entries()) {
			if (g === this.expandToGroup) {
				index = i;
			}
		}
		const expandItem = (item, path = []) => {
			const i = path.length;
			if (index === i) return;
			const newPath = [...path, item[groupBy[i]]];
			expandedGroups.push([...newPath]);
			item.children.forEach((c) => expandItem(c, [...newPath]));
		};
		groups.forEach((g) => expandItem(g, []));
		this.reportStore.expandedGroups = expandedGroups;
		this.emitChanged();
	}

	collapseAllGroups() {
		this.reportStore.expandedGroups = [];
		this.emitChanged();
	}
}

export let timesheetReportStore = new TimesheetReportStore();
export let timesheetReportActions = registerActions(
	"timesheet-reports-page",
	actionDefinitions,
	dispatcher
);

function groupFieldData(getGroupBy, getValue) {
	/**
	 * A field whose value is derived from a group of entries, not a single entry.
	 * The group is identified by one or more values (eg. a project, a project + a phase,
	 * a staff member, a staff member + a project + a phase, etc.) and the value
	 * will depend on some subset of these values, or it may be that for a particular
	 * set of group values there is no derived value that makes sense.
	 */
	return (te, i, stack) => {
		// sometimes it wont accept the 3rd stack argument
		// it's impossible to understand why
		stack ||= i;
		const groupBy = getGroupBy();

		if (
			stack != null &&
			stack.length !== undefined &&
			stack.length < groupBy.length
		) {
			let d = {};
			for (let [columnName, group] of izip(groupBy, stack)) {
				d[columnName] = group[columnName];
			}
			d[groupBy[stack.length]] = te[groupBy[stack.length]];
			return getValue(d);
		}
		return null;
	};
}

function projectOrPhaseAggregate(groupers, getValue) {
	return function (tes, groupStack) {
		const headerColumn = groupers[groupStack.length - 1];
		if (headerColumn === "project" || headerColumn === "projectPhase") {
			const ob = tes[0][headerColumn];
			return ob != null ? getValue(ob) : null;
		} else {
			return null;
		}
	};
}
