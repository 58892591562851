import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import moment from "moment";
import {
	StoreBase,
	dispatcher,
	ActionCollection,
} from "../../coincraftFlux.js";
import { sum, formatCurrency } from "../../utils.js";
import { dateConverter, NoProjectProject, NoPhasePhase } from "../../models.js";
import { ProjectPermissionChecker } from "../../models/permissions.js";
import { PermissionLevel } from "../../models/permissions.js";
import { organisationStore } from "../../organisation.js";
import { jsonHttp } from "../../jsonHttp.js";
import Immutable from "immutable";
import { userStore } from "../../user.js";
import { permissions, requiresPermission } from "../../models/permissions.js";
import { generateUUID } from "../../utils.js";
import { AjaxOperation } from "../../AjaxOperation.js";
import { jsonHttp2 } from "../../jsonHttp.js";
import { RevenueRow } from "./rows/RevenueRow.js";
import { TimesheetRow } from "./rows/TimesheetRow.js";
import { ExpenseRow } from "./rows/ExpenseRow.js";
import { ProjectPhase } from "../../models/projectphase.js";
import { ProjectExpense } from "../../models/projectexpense.js";
import { projectStore } from "../flux.js";
import apiRequest from "../../apiRequest.js";

class ProjectForecastsStore extends StoreBase {
	constructor() {
		super();
		this.isReady = false;
		// Start at, say, three months before the current month.
		this.currentMonthIndex = dateConverter.momentToMonthIndex(moment());
		this.startMonth = this.currentMonthIndex - 3;
		this.endMonth = this.startMonth + 11;

		this.showSidebar = false;

		this.selectedRowId = null;
		this.selectedCellInputText = null;
		this.selectedMonthIndex = null;

		this.project = null;
		this.selectedStaff = null;
		this.selectedPhase = null;

		this.selectedRevenueRowId = null;
		this.selectedRevenueCellInputText = null;
		this.selectedRevenueMonthIndex = null;
		this.selectedRevenueFeeUseInputText = null;

		this.selectedHoursRowId = null;
		this.selectedHoursMonthIndex = null;
		this.selectedHoursInputText = null;
		this.selectedCostInputText = null;
		this.selectedHoursBudgetInputText = null;
		this.selectedCostBudgetInputText = null;
		this.selectedHoursUtilisationInputText = null;

		this.selectedExpenseRowId = null;
		this.selectedExpenseCellInputText = null;
		this.selectedExpenseMonthIndex = null;

		this.menuType = null;

		this.dateColumns = this.getDateColumns();

		this.staffAvailability = {};
		this.roleAvailability = {};
	}

	initialize(project) {
		this.project = project;

		this.revenueRows = Immutable.Map({});
		this.hoursRows = Immutable.Map({});
		this.expenseRows = Immutable.Map({});
		this.expandedStaff = [];
		this.expandedExpenses = [];

		this.getRevenueData();
		this.getExpenseData();

		this.getTimesheetData((data) => {
			this.moveMonths = 0;
			this.hoursRows = Immutable.Map(
				_.mapObject(
					data.rows,
					(val, key) => new TimesheetRow(this, val)
				)
			);
			this.addMissingTimesheetPhases();
			this.isReady = true;
			this.emitChanged();
		});

		this.modal = null;
	}

	get isPrinting() {
		return projectStore.isPrinting;
	}

	get forecastType() {
		return projectStore.forecastType;
	}

	get dataType() {
		return projectStore.forecastDataType;
	}

	get displayedProjectPhase() {
		return projectStore.displayedProjectPhase;
	}

	get displayRevenue() {
		return ["revenueVsExpenses", "revenueVsExpensesMonthly"].includes(
			this.forecastType
		);
	}

	get displayExpenses() {
		return !this.forecastType.includes("hours");
	}

	get displayedProjectPhaseId() {
		if (this.displayedProjectPhase.project) {
			return (
				this.displayedProjectPhase.project.id.toString() +
				(
					this.displayedProjectPhase.id ||
					this.displayedProjectPhase.uuid
				).toString()
			);
		} else {
			return (
				this.displayedProjectPhase.id || this.displayedProjectPhase.uuid
			).toString();
		}
	}

	get selectedRevenueRow() {
		return this.revenueRows.get(this.selectedRevenueRowId);
	}

	get selectedHoursRow() {
		return this.hoursRows.get(this.selectedHoursRowId);
	}

	get selectedExpenseRow() {
		return this.expenseRows.get(this.selectedExpenseRowId);
	}

	addMissingTimesheetPhases() {
		if (!this.hoursRows.get(this.project.id.toString())) {
			this.hoursRows = this.hoursRows.set(
				this.project.id.toString(),
				this.createProjectTimesheetRow()
			);
		}
		this.project.getVisiblePhases().forEach((ph) => {
			const phaseId =
				this.project.id.toString() + (ph.id || ph.uuid).toString();
			let phaseRow = this.hoursRows.get(phaseId);
			if (!phaseRow) {
				this.hoursRows = this.hoursRows.set(
					phaseId,
					this.createPhaseTimesheetRow(ph)
				);
				phaseRow = this.hoursRows.get(phaseId);
				this.hoursRows
					.get(this.project.id.toString())
					.childrenIds.add(phaseId);
			}
			ph.staffRoleBudgetedHours.forEach((srh) => {
				const srId = phaseId + srh.staffRole.id.toString();
				let srRow = this.hoursRows.get(srId);
				if (!srRow) {
					this.hoursRows = this.hoursRows.set(
						srId,
						this.createRoleTimesheetRow(srId, srh)
					);
					srRow = this.hoursRows.get(srId);
					phaseRow.childrenIds.add(srId);
				}
				const srId2 = srId + "--no-staff";
				let srRow2 = this.hoursRows.get(srId2);
				if (!srRow2) {
					this.hoursRows = this.hoursRows.set(
						srId2,
						this.createRoleTimesheetRow(srId2, srh, {
							parentId: srId,
							level: 4,
							groupType: "staff",
							rowType: "staff",
						})
					);
					srRow2 = this.hoursRows.get(srId2);
					srRow.childrenIds.add(srId2);
				}
			});
			ph.staffMemberBudgetedHours.forEach((smh) => {
				const srId =
					phaseId +
					(smh.staffMember.role?.id?.toString?.() || "--no-role");
				let srRow = this.hoursRows.get(srId);
				if (!srRow) {
					this.hoursRows = this.hoursRows.set(
						srId,
						this.createRoleTimesheetRow(srId, {
							staffRole: smh.staffMember.role,
							phase: ph,
						})
					);
					srRow = this.hoursRows.get(srId);
					phaseRow.childrenIds.add(srId);
				}
				const smId = srId + smh.staffMember.id.toString();
				let smRow = this.hoursRows.get(smId);
				if (!smRow) {
					this.hoursRows = this.hoursRows.set(
						smId,
						this.createStaffTimesheetRow(smId, srId, smh)
					);
					smRow = this.hoursRows.get(smId);
					srRow.childrenIds.add(smId);
				}
			});
		});
	}

	createStaffTimesheetRow(id, parentId, staffBudget) {
		return new TimesheetRow(this, {
			uuid: id,
			parentId: parentId,
			childrenIds: [],
			level: 4,
			title: staffBudget.staffMember.getFullName(),
			groupType: "staff",
			rowType: "staff",
			project: this.project,
			phase: staffBudget.phase,
			staff: staffBudget.staffMember,
			role: staffBudget.staffMember.role,
		});
	}

	createRoleTimesheetRow(id, roleBudget, options = {}) {
		return new TimesheetRow(this, {
			uuid: id,
			parentId:
				this.project.id.toString() +
				(roleBudget.phase.id || roleBudget.phase.uuid).toString(),
			childrenIds: [],
			level: 3,
			title: roleBudget.staffRole?.name || "No Role",
			groupType: "role",
			rowType: "role",
			project: this.project,
			phase: roleBudget.phase,
			role: roleBudget.staffRole,
			...options,
		});
	}

	createPhaseTimesheetRow(phase) {
		return new TimesheetRow(this, {
			uuid:
				this.project.id.toString() +
				(phase.id || phase.uuid).toString(),
			parentId: this.project.id.toString(),
			childrenIds: [],
			level: 2,
			title: phase.getTitle(),
			groupType: "staff",
			rowType: "phase",
			project: this.project,
			phase: phase,
		});
	}

	createProjectTimesheetRow() {
		return new TimesheetRow(this, {
			uuid: this.project.id.toString(),
			level: 0,
			title: this.project.getTitle(),
			groupType: "staff",
			rowType: "project",
			project: this.project,
		});
	}

	getRevenueData() {
		this.revenueRows = Immutable.Map({});
		const cfis = this.project.getCashFlowItems().filter((cfi) => cfi.fee);
		this.revenueRows = this.revenueRows.set(
			this.project.id.toString(),
			this.createRevenueRow(this.project)
		);
		this.project
			.getVisiblePhases()
			.forEach(
				(ph) =>
					(this.revenueRows = this.revenueRows.set(
						this.project.id.toString() +
							(ph.id || ph.uuid).toString(),
						this.createRevenueRow(this.project, ph)
					))
			);
		cfis.forEach((cfi) => {
			const revRow = this.revenueRows.get(
				cfi.project.id.toString() +
					(cfi.phase ? cfi.phase.id || cfi.phase.uuid : -1).toString()
			);
			if (revRow) revRow.addCfi(cfi);
		});
	}

	createRevenueRow(project, phase) {
		return new RevenueRow(this, {
			uuid: phase
				? project.id.toString() + (phase.id || phase.uuid).toString()
				: project.id.toString(),
			parentId: phase ? project.id.toString() : null,
			childrenIds: phase
				? []
				: project
						.getVisiblePhases()
						.map(
							(ph) =>
								project.id.toString() +
								(ph.id || ph.uuid).toString()
						),
			level: phase ? 1 : 0,
			title: phase ? phase.getTitle() : project.getTitle(),
			groupType: "revenue",
			rowType: phase ? "phase" : "project",
			project: project,
			phase: phase,
		});
	}

	getExpenseData() {
		this.expenseRows = Immutable.Map({});
		const cfis = this.project
			.getCashFlowItems()
			.filter((cfi) => cfi.spend || (cfi.expense && cfi.title?.length));
		const showNoPhase = cfis
			.map((cfi) => cfi.phase?.id || cfi.phase?.uuid || -1)
			.includes(-1);
		this.expenseRows = this.expenseRows.set(
			this.project.id.toString(),
			this.createExpenseRow(this.project)
		);
		const phases = [
			...this.project.getVisiblePhases(),
			showNoPhase ? this.project.getProjectNoPhase() : null,
		].filter((v) => v);
		phases.forEach((ph) => {
			const phaseId =
				this.project.id.toString() + (ph.id || ph.uuid).toString();
			this.expenseRows = this.expenseRows.set(
				phaseId,
				this.createExpenseRow(this.project, ph)
			);
		});
		cfis.forEach((cfi) => {
			const eId = this.getExpenseItemId(cfi);
			let eRow = this.expenseRows.get(eId);
			if (!eRow) {
				this.expenseRows = this.expenseRows.set(
					eId,
					this.createExpenseRow(cfi.project, cfi.phase, cfi.title)
				);
				this.expenseRows
					.get(
						cfi.project.id.toString() +
							(cfi.phase
								? (cfi.phase.id || cfi.phase.uuid).toString()
								: "-1")
					)
					.childrenIds.add(eId);
				eRow = this.expenseRows.get(eId);
			}
			eRow.addCfi(cfi);
		});
		this.project.expenses.forEach((exp) => {
			const eId = this.getExpenseNameId(exp);
			let eRow = this.expenseRows.get(eId);
			if (!eRow) {
				this.expenseRows = this.expenseRows.set(
					eId,
					this.createExpenseRow(exp.project, exp.phase, exp.name)
				);
				const row = this.expenseRows.get(
					exp.project.id.toString() +
						(exp.phase
							? (exp.phase.id || exp.phase.uuid).toString()
							: "-1")
				);
				if (row?.childrenIds) {
					row.childrenIds.add(eId);
				}
				eRow = this.expenseRows.get(eId);
			}
		});
	}

	getExpenseItemId(cfi) {
		return (
			cfi.project.id.toString() +
			(cfi.phase ? (cfi.phase.id || cfi.phase.uuid).toString() : "-1") +
			cfi.title
		);
	}

	getExpenseNameId(expense) {
		return (
			expense.project.id.toString() +
			(expense.phase
				? (expense.phase.id || expense.phase.uuid).toString()
				: "-1") +
			expense.name
		);
	}

	createExpenseRow(project, phase, expenseTitle) {
		const cfis = this.project
			.getCashFlowItems()
			.filter((cfi) => cfi.spend || (cfi.expense && cfi.title?.length));
		const showNoPhase = cfis
			.map((cfi) => cfi.phase?.id || cfi.phase?.uuid || -1)
			.includes(-1);
		const prId = project.id.toString();
		const phId = phase
			? prId + (phase.id || phase.uuid).toString()
			: undefined;
		const eId = expenseTitle
			? (phId || prId + "-1") + expenseTitle
			: undefined;
		const uuid = eId || phId || prId;
		return new ExpenseRow(this, {
			uuid: uuid,
			parentId: expenseTitle
				? phId || prId + "-1"
				: phase
				? prId
				: undefined,
			childrenIds:
				phase || expenseTitle
					? []
					: [
							...project
								.getVisiblePhases()
								.map(
									(ph) => prId + (ph.id || ph.uuid).toString()
								),
							showNoPhase ? prId + "-1" : null,
					  ].filter((v) => v),
			level: expenseTitle ? 2 : phase ? 1 : 0,
			title: expenseTitle
				? expenseTitle
				: phase
				? phase.getTitle()
				: project.getTitle(),
			groupType: "expense",
			rowType: expenseTitle ? "expense" : phase ? "phase" : "project",
			project: project,
			phase: phase,
		});
	}

	getTimesheetData(callback) {
		let self = this;
		apiRequest({
			url: `/api/v1/project-schedule/hours`,
			method: "get",
			params: { data: { projectId: this.project.id } },
		}).then(callback);
	}

	getDateColumns() {
		let columns = [];
		let monthIndex = this.startMonth;
		while (monthIndex <= this.endMonth) {
			let date = dateConverter.monthIndexToMoment(monthIndex); //has funny results
			columns.push({
				date: date,
				dateInt: dateConverter.monthIndexToOffset(monthIndex),
				string: date.format("MMM YY"),
				value: date.format("MMM YY"),
				monthIndex: monthIndex,
				isColumnHeader: true,
				isEditable: false,
			});
			monthIndex += 1;
		}
		return columns;
	}

	getColumns() {
		const rowHeadings = [
			"Title",
			<div style={{ width: "100%" }} className="flexbox-container">
				<div className="flex-1-0-auto">Total</div>
				<div className="flex-0-1-auto" style={{ fontSize: "1.6em" }}>
					<i
						className={`fa fa-caret-left`}
						style={{ cursor: "pointer" }}
						onClick={() => actions.moveLeft()}
					/>
					<i
						className={`fa fa-caret-right`}
						style={{ marginRight: 0, cursor: "pointer" }}
						onClick={() => actions.moveRight()}
					/>
				</div>
			</div>,
		];
		const columns = [
			...rowHeadings.map((v) => {
				return {
					value: v,
					isColumnHeader: true,
					isRowHeader: true,
					isEditable: false,
				};
			}),
			...this.dateColumns,
		];
		return columns;
	}

	get revenueSpreadsheet() {
		return this.getSpreadsheet(
			this.revenueRows,
			this.displayedProjectPhaseId
		);
	}

	get timeSpreadsheet() {
		return this.getSpreadsheet(
			this.hoursRows,
			this.displayedProjectPhaseId
		);
	}

	get expenseSpreadsheet() {
		return this.getSpreadsheet(
			this.expenseRows,
			this.displayedProjectPhaseId
		);
	}

	getSpreadsheet(rows, projectId) {
		const columns = this.getColumns();
		let sRows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			...this.getSpresheetRows(rows.get(projectId)),
		];
		return sRows;
	}

	getSpresheetRows(row, shadow = false) {
		let rows = [];
		if (!row) return rows;
		rows.push(row.generateSpreasheetRow(this.dateColumns, shadow));
		if ((!row.expandable || row.expanded) && row.childrenIds.size > 0) {
			// can't get index when iterating through set()
			// so convert to array
			[...row.children]
				.filter((childRow) => childRow.isDisplayed)
				.forEach((childRow, i) => {
					rows = [
						...rows,
						...this.getSpresheetRows(childRow, i === 0),
					];
				});
		}
		return rows;
	}

	moveLeft() {
		this.moveBy(-1);
	}

	moveRight() {
		this.moveBy(+1);
	}

	moveBy(numMonths) {
		this.moveMonths += numMonths;
		_.debounce(() => {
			if (!this.moveMonths) return true;
			this.startMonth += this.moveMonths;
			this.endMonth += this.moveMonths;
			this.dateColumns = this.getDateColumns();
			this.moveMonths = 0;
			this.emitChanged();
		}, 300)();
	}

	toggleRevenueVisibility(uuid) {
		const itemRow = this.revenueRows.get(uuid);
		itemRow.toggleVisibility();
		//TODO make more effecient
		// this.generateSpreadsheetRows();
		this.emitChanged();
	}

	toggleStaffExpand(uuid) {
		if (!this.expandedStaff.includes(uuid)) {
			this.expandedStaff.push(uuid);
		} else {
			this.expandedStaff = _.without(this.expandedStaff, uuid);
		}
		// this.generateSpreadsheetRows();
		this.emitChanged();
	}

	toggleStaffVisibility(uuid) {
		const itemRow = this.hoursRows.get(uuid);
		itemRow.toggleVisibility();
		//TODO make more effecient
		// this.generateSpreadsheetRows();
		this.emitChanged();
	}

	toggleExpenseExpand(uuid) {
		if (!this.expandedExpenses.includes(uuid)) {
			this.expandedExpenses.push(uuid);
		} else {
			this.expandedExpenses = _.without(this.expandedExpenses, uuid);
		}
		// this.generateSpreadsheetRows();
		this.emitChanged();
	}

	toggleExpenseVisibility(uuid) {
		const itemRow = this.expenseRows.get(uuid);
		itemRow.toggleVisibility();
		//TODO make more effecient
		// this.generateSpreadsheetRows();
		this.emitChanged();
	}

	get graphData() {
		return this.dateColumns.map((dc, i) => {
			const incomeRow = this.revenueRows.get(
				this.displayedProjectPhaseId
			);
			const spendRow = this.hoursRows.get(this.displayedProjectPhaseId);
			const expenseRow = this.expenseRows.get(
				this.displayedProjectPhaseId
			);
			let income = 0;
			let spend = 0;
			switch (this.forecastType) {
				case "revenueVsExpenses":
					income = incomeRow
						? incomeRow.getDisplayedRevenueToDateMonthIndex(
								dc.monthIndex
						  ) +
						  expenseRow.getDisplayedRevenueToDateMonthIndex(
								dc.monthIndex
						  )
						: 0;
					spend = spendRow
						? spendRow.getDisplayedCostToDateMonthIndex(
								dc.monthIndex
						  ) +
						  expenseRow.getDisplayedExpenseToDateMonthIndex(
								dc.monthIndex
						  )
						: 0;
					break;
				case "expenseBudget":
					income = this.displayedProjectPhase.getBudget();
					spend = spendRow
						? spendRow.getDisplayedCostToDateMonthIndex(
								dc.monthIndex
						  ) +
						  expenseRow.getDisplayedExpenseToDateMonthIndex(
								dc.monthIndex
						  )
						: 0;
					break;
				case "hoursBudget":
					income = this.displayedProjectPhase.manualHoursBudget;
					spend = spendRow
						? spendRow.getDisplayedHoursToDateMonthIndex(
								dc.monthIndex
						  )
						: 0;
					break;
				case "revenueVsExpensesMonthly":
					income = incomeRow
						? incomeRow.getDisplayedRevenueMonthIndex(
								dc.monthIndex
						  ) +
						  expenseRow.getDisplayedRevenueMonthIndex(
								dc.monthIndex
						  )
						: 0;
					spend = spendRow
						? spendRow.getDisplayedCostMonthIndex(dc.monthIndex) +
						  expenseRow.getDisplayedExpenseMonthIndex(
								dc.monthIndex
						  )
						: 0;
					break;
				case "expenseBudgetMonthly":
					income =
						this.displayedProjectPhase.getBudgetedExpenseInMonthIndex(
							dc.monthIndex
						);
					spend = spendRow
						? spendRow.getDisplayedCostMonthIndex(dc.monthIndex) +
						  expenseRow.getDisplayedExpenseMonthIndex(
								dc.monthIndex
						  )
						: 0;
					break;
				case "hoursBudgetMonthly":
					income =
						this.displayedProjectPhase.getBudgetedHoursInMonthIndex(
							dc.monthIndex
						);
					spend = spendRow
						? spendRow.getDisplayedHoursMonthIndex(dc.monthIndex)
						: 0;
					break;
			}
			return {
				date: dc.dateInt,
				monthIndex: dc.monthIndex,
				income: income,
				spend: spend,
			};
		});
	}

	setRevenueInputText(cell, text) {
		this.selectedRevenueCellInputText = text.replace(/\D/g, "");
		this.emitChanged();
	}

	rollbackRevenueInputText(cell) {
		this.selectedRevenueCellInputText = null;
		this.emitChanged();
	}

	commitRevenueInputText(cell, text) {
		projectStore.setProjectModifiedProp(this.selectedRevenueRow.project, [
			"milestones",
			"allocations",
		]);
		let settings = organisationStore.organisation.settings;
		if (cell.monthIndex >= this.currentMonthIndex) {
			let row = cell.row;
			let revenue = parseFloat(text);
			if (row.rowType === "phase") {
				row.phase.setTotalMilestoneRevenueInMonth(
					revenue,
					cell.monthIndex
				);
				if (settings.updateHoursFromRevenue) {
					row.phase.adjustAllocationsFromRevenueMonthIndex(
						cell.monthIndex
					);
					this.hoursRows
						.get(row.uuid)
						.finalDescendants.forEach((row) => {
							const item =
								row.rowType === "staff"
									? row.staffMember
									: row.rowType === "role"
									? row.staffrole
									: null;
							const hoursFunc =
								row.rowType === "staff"
									? "getStaffAllocatedHoursInMonthIndex"
									: row.rowType === "role"
									? "getRoleAllocatedHoursInMonthIndex"
									: null;
							if (item && hoursFunc) {
								row.setHoursMonthIndex(
									this.selectedRevenueMonthIndex,
									row.phase[hoursFunc](
										item,
										this.selectedRevenueMonthIndex
									)
								);
							}
						});
				}
			} else if (row.rowType === "project") {
				row.project.setTotalMilestoneRevenueInMonth(
					revenue,
					cell.monthIndex
				);
				if (settings.updateHoursFromRevenue) {
					row.project.getVisiblePhases().forEach((ph) => {
						ph.adjustAllocationsFromRevenueMonthIndex(
							cell.monthIndex
						);

						this.hoursRows
							.get(row.uuid + ph.id)
							.finalDescendants.forEach((row) => {
								const item =
									row.rowType === "staff"
										? row.staffMember
										: row.rowType === "role"
										? row.staffrole
										: null;
								const hoursFunc =
									row.rowType === "staff"
										? "getStaffAllocatedHoursInMonthIndex"
										: row.rowType === "role"
										? "getRoleAllocatedHoursInMonthIndex"
										: null;
								if (item && hoursFunc) {
									row.setHoursMonthIndex(
										this.selectedRevenueMonthIndex,
										row.phase[hoursFunc](
											item,
											this.selectedRevenueMonthIndex
										)
									);
								}
							});
					});
					this.hoursRows.get(row.uuid);
				}
			}
			//TODO - probably should just update the row
			this.getRevenueData();
			this.emitChanged();
		}
	}
	selectRevenueCell(cell) {
		this.selectedRevenueRowId = cell ? cell.uuid : null;
		this.selectRevenueMonthIndex(cell.monthIndex);
	}
	selectRevenueMonthIndex(monthIndex) {
		this.selectedRevenueMonthIndex = monthIndex;
		this.selectedRevenueCellInputText = Math.round(
			this.selectedRevenueRow.getDisplayedRevenueMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedRevenueFeeUseInputText = Math.round(
			this.selectedRevenueRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.menuType = "revenue";
		this.emitChanged();
	}

	selectHoursMonthIndex(monthIndex) {
		this.selectedHoursMonthIndex = monthIndex;
		this.selectedHoursInputText = Math.round(
			this.selectedHoursRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursUtilisationInputText = Math.round(
			this.selectedHoursRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.menuType = "hours";
		this.emitChanged();
	}

	selectExpenseMonthIndex(monthIndex) {
		this.selectedExpenseMonthIndex = monthIndex;
		this.selectedExpenseCellInputText = Math.round(
			this.selectedExpenseRow.getDisplayedExpenseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.menuType = "expenses";
		this.emitChanged();
	}

	setMenuType(menuType) {
		this.menuType = menuType;
		this.emitChanged();
	}

	setHoursInputText(cell, text) {
		this.selectedHoursCellInputText = text.replace(/\D/g, "");
		this.emitChanged();
	}
	rollbackHoursInputText(cell) {
		this.selectedHoursCellInputText = null;
		this.emitChanged();
	}
	commitHoursInputText(cell, text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		const row = cell.row;
		const newTotal = parseFloat(text);
		row.setDisplayedMonthTotal(cell.monthIndex, newTotal);
		this.selectedRevenueCellInputText = null;
		this.selectedRevenueFeeUseInputText = null;
		let settings = organisationStore.organisation.settings;
		if (settings.updateRevenueFromHours) {
			this.getRevenueData();
		}
		this.emitChanged();
	}
	selectHoursCell(cell) {
		this.selectedHoursRowId = cell ? cell.uuid : null;
		this.selectedHoursMonthIndex = cell ? cell.monthIndex : null;
		this.selectedHoursCellInputText = Math.round(
			this.selectedHoursRow.getDisplayedMonthTotal(
				this.selectedMonthIndex
			)
		);
		this.menuType = "hours";
		this.emitChanged();
	}

	setExpenseInputText(cell, text) {
		this.selectedExpenseCellInputText = text.replace(/\D/g, "");
		this.emitChanged();
	}
	rollbackExpenseInputText(cell) {
		this.selectedExpenseCellInputText = null;
		this.emitChanged();
	}
	commitExpenseInputText(cell, text) {
		projectStore.setProjectModifiedProp(this.selectedExpenseRow.project, [
			"expenses",
			"changeLog",
		]);
		let row = cell.row;
		let newTotal = parseFloat(text);
		row.setExpenseMonthIndex(cell.monthIndex, newTotal);
		// this.getExpenseData();
		this.emitChanged();
	}
	selectExpenseCell(cell) {
		this.selectedExpenseRowId = cell ? cell.uuid : null;
		this.selectedExpenseMonthIndex = cell ? cell.monthIndex : null;
		this.selectedExpenseCellInputText = Math.round(
			this.selectedExpenseRow.getDisplayedExpenseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.menuType = "expenses";
		this.emitChanged();
	}

	getStaffAvailabilityInMonth(staffMember, monthIndex) {
		this.staffAvailability[staffMember.uuid] =
			this.staffAvailability[staffMember.uuid] || {};
		if (this.staffAvailability[staffMember.uuid][monthIndex])
			return this.staffAvailability[staffMember.uuid][monthIndex];
		this.staffAvailability[staffMember.uuid][monthIndex] =
			staffMember.getNumHoursAvailableInMonth(
				monthIndex,
				organisationStore.getHolidaysXspans().data
			);
		return this.staffAvailability[staffMember.uuid][monthIndex];
	}

	getRoleAvailabilityInMonth(staffRole, monthIndex) {
		const uuid = staffRole ? staffRole.uuid : "noRole";
		this.roleAvailability[uuid] = this.roleAvailability[uuid] || {};
		if (this.roleAvailability[uuid][monthIndex])
			return this.roleAvailability[uuid][monthIndex];
		this.roleAvailability[uuid][monthIndex] = sum(
			this.getRoleStaffMembers(staffRole, true).map((sm) => {
				return this.getStaffAvailabilityInMonth(sm, monthIndex);
			})
		);
		return this.roleAvailability[uuid][monthIndex];
	}

	getRoleStaffMembers(staffRole, filterDisplayed) {
		if (staffRole) {
			return organisationStore.staffMembers.filter(
				(sm) => sm.role && sm.role.id === staffRole.id
			);
		} else {
			return organisationStore.staffMembers.filter((sm) => !sm.role);
		}
	}

	getAllStaffAvailabilityInMonth(monthIndex) {
		this.roleAvailability["allStaff"] =
			this.roleAvailability["allStaff"] || {};
		if (this.roleAvailability["allStaff"][monthIndex])
			return this.roleAvailability["allStaff"][monthIndex];
		this.roleAvailability["allStaff"][monthIndex] = sum(
			organisationStore.staffMembers.map((sm) => {
				return this.getStaffAvailabilityInMonth(sm, monthIndex);
			})
		);
		return this.roleAvailability["allStaff"][monthIndex];
	}

	closeModal() {
		this.modal = null;
		this.emitChanged();
	}

	clickAddPhaseButton() {
		this.modal = "addPhase";
		this.emitChanged();
	}

	addPhase(name) {
		let pp = new ProjectPhase({ project: this.project, name: name });
		pp.createDefaultTask();
		this.project.phases.push(pp);
		this.getRevenueData();
		this.getExpenseData();
		this.addMissingTimesheetPhases();
		this.modal = null;
		this.emitChanged();
	}

	clickAddExpenseButton(phase) {
		this.addExpensePhase = phase;
		this.modal = "addExpense";
		this.emitChanged();
	}

	addExpense(name) {
		let newExpenseObjs = this.project.expenses.map((e) => e.copy());
		//TODO wont work with new phases without ids
		//change to uuid
		let newExpenseObj = new ProjectExpense({
			name: name,
			projectId: this.addExpensePhase
				? this.addExpensePhase.project.id
				: -1,
			_project: this.addExpensePhase ? this.addExpensePhase.project : -1,
			phaseId: this.addExpensePhase ? this.addExpensePhase.id : -1,
			phaseUuid: this.addExpensePhase ? this.addExpensePhase.uuid : null,
			date: dateConverter.monthIndexToMoment(this.currentMonthIndex),
			unitCost: 0,
			quantity: 1,
		});
		newExpenseObjs.push(newExpenseObj);
		this.project.setExpenses(newExpenseObjs);
		this.getExpenseData();
		this.modal = null;
		this.emitChanged();
	}

	clickAddAllocationButton(row) {
		this.addAllocationRow = row;
		this.modal = "addAllocation";
		this.emitChanged();
	}

	addAllocations(staff) {
		this.addAllocationRow.addChildStaff(staff);
		this.modal = null;
		this.emitChanged();
	}

	changeFee(item, fee) {
		item.fee = parseFloat(fee);
		this.emitChanged();
	}

	setExpenseBillability(row, isBillable) {
		row.setBillability(isBillable);
		this.emitChanged();
	}

	setSelectedRevenueCellRevenue(text) {
		let settings = organisationStore.organisation.settings;
		if (this.selectedRevenueMonthIndex >= this.currentMonthIndex) {
			let row = this.selectedRevenueRow;
			let revenue = isFinite(parseFloat(text.replace(/\D/g, "")))
				? parseFloat(text.replace(/\D/g, ""))
				: 0;
			this.selectedRevenueCellInputText = text.replace(/\D/g, "");
			if (row.rowType === "phase") {
				projectStore.setProjectPhaseModifiedProp(row.phase, [
					"milestones",
					"allocations",
				]);
				row.phase.setTotalMilestoneRevenueInMonth(
					revenue,
					this.selectedRevenueMonthIndex
				);
				if (settings.updateHoursFromRevenue) {
					row.phase.adjustAllocationsFromRevenueMonthIndex(
						this.selectedRevenueMonthIndex
					);
					this.hoursRows
						.get(row.uuid)
						.finalDescendants.forEach((row) => {
							const item =
								row.rowType === "staff"
									? row.staffMember
									: row.rowType === "role"
									? row.staffrole
									: null;
							const hoursFunc =
								row.rowType === "staff"
									? "getStaffAllocatedHoursInMonthIndex"
									: row.rowType === "role"
									? "getRoleAllocatedHoursInMonthIndex"
									: null;
							if (item && hoursFunc) {
								row.setHoursMonthIndex(
									this.selectedRevenueMonthIndex,
									row.phase[hoursFunc](
										item,
										this.selectedRevenueMonthIndex
									)
								);
							}
						});
				}
			} else if (row.rowType === "project") {
				projectStore.setProjectModifiedProp(row.project, [
					"milestones",
					"allocations",
				]);
				row.project.setTotalMilestoneRevenueInMonth(
					revenue,
					this.selectedRevenueMonthIndex
				);
				if (settings.updateHoursFromRevenue) {
					row.project.getVisiblePhases().forEach((ph) => {
						ph.adjustAllocationsFromRevenueMonthIndex(
							this.selectedRevenueMonthIndex
						);
						this.hoursRows
							.get(row.uuid + ph.id)
							.finalDescendants.forEach((row) => {
								const item =
									row.rowType === "staff"
										? row.staffMember
										: row.rowType === "role"
										? row.staffrole
										: null;
								const hoursFunc =
									row.rowType === "staff"
										? "getStaffAllocatedHoursInMonthIndex"
										: row.rowType === "role"
										? "getRoleAllocatedHoursInMonthIndex"
										: null;
								if (item && hoursFunc) {
									row.setHoursMonthIndex(
										this.selectedRevenueMonthIndex,
										row.phase[hoursFunc](
											item,
											this.selectedRevenueMonthIndex
										)
									);
								}
							});
					});
					this.hoursRows.get(row.uuid);
				}
			}
			//TODO - probably should just update the row
			this.getRevenueData();
			this.selectedRevenueFeeUseInputText = Math.round(
				this.selectedRevenueRow.getDisplayedBudgetUseMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.emitChanged();
		}
	}

	setSelectedRevenueCellFeeUse(percentFeeUse) {
		let settings = organisationStore.organisation.settings;
		const feeUse = isFinite(parseFloat(percentFeeUse))
			? parseFloat(percentFeeUse)
			: 0;
		const row = this.selectedRevenueRow;
		const fee = row.totalFee;
		const revenueLastMonthToDate = row.getDisplayedRevenueToDateMonthIndex(
			this.selectedRevenueMonthIndex - 1
		);
		const revenue = fee * (feeUse / 100) - revenueLastMonthToDate;
		if (row.rowType === "phase") {
			projectStore.setProjectPhaseModifiedProp(row.phase, [
				"milestones",
				"allocations",
			]);
			row.phase.setTotalMilestoneRevenueInMonth(
				revenue,
				this.selectedRevenueMonthIndex
			);
		} else if (row.rowType === "project") {
			projectStore.setProjectModifiedProp(row.project, [
				"milestones",
				"allocations",
			]);
			row.project.setTotalMilestoneRevenueInMonth(
				revenue,
				this.selectedRevenueMonthIndex
			);
		}
		this.getRevenueData();
		this.selectedRevenueCellInputText = Math.round(
			this.selectedRevenueRow.getDisplayedRevenueMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedRevenueFeeUseInputText = percentFeeUse;
		const hoursRow = this.hoursRows.get(this.selectedRevenueRowId);
		if (settings.updateHoursFromRevenue && hoursRow) {
			hoursRow.finalDescendants.forEach((row) => {
				row.setCostBudgetUseMonthIndex(
					this.selectedRevenueMonthIndex,
					feeUse
				);
			});
		}
		this.emitChanged();
	}

	get profitSpreadsheet() {
		const columns = this.getColumns();
		const revenueRow = this.revenueRows.get(this.displayedProjectPhaseId);
		const hoursRow = this.hoursRows.get(this.displayedProjectPhaseId);
		const expenseRow = this.expenseRows.get(this.displayedProjectPhaseId);
		let totalRevenue = 0;
		let totalExpense = 0;
		let profitTitle = "";
		switch (this.forecastType) {
			case "revenueVsExpenses":
				totalRevenue =
					revenueRow.totalProjectedRevenue +
					expenseRow.totalProjectedRevenue;
				totalExpense =
					totalRevenue -
					(hoursRow.totalProjectedCost +
						expenseRow.totalProjectedExpense);
				profitTitle = "Profit";
				break;
			case "expenseBudget":
				totalRevenue = this.displayedProjectPhase.getBudget();
				totalExpense =
					hoursRow.totalProjectedCost +
					expenseRow.totalProjectedExpense;
				profitTitle = "Remaining Budget";
				break;
			case "hoursBudget":
				totalRevenue = this.displayedProjectPhase.manualHoursBudget;
				totalExpense = hoursRow.totalProjectedHours;
				profitTitle = "Remaining Budget";
				break;
			case "revenueVsExpensesMonthly":
				totalRevenue =
					revenueRow.totalProjectedRevenue +
					expenseRow.totalProjectedRevenue;
				totalExpense =
					totalRevenue -
					(hoursRow.totalProjectedCost +
						expenseRow.totalProjectedExpense);
				profitTitle = "Monthly Profit";
				break;
			case "expenseBudgetMonthly":
				totalRevenue = this.displayedProjectPhase.getBudget();
				totalExpense =
					hoursRow.totalProjectedCost +
					expenseRow.totalProjectedExpense;
				profitTitle = "Remaining Monthly Budget";
				break;
			case "hoursBudgetMonthly":
				totalRevenue = this.displayedProjectPhase.manualHoursBudget;
				totalExpense = hoursRow.totalProjectedHours;
				profitTitle = "Remaining Monthly Budget";
				break;
		}
		const profitMargin = Math.round(
			((totalRevenue - totalExpense) / totalRevenue) * 100
		);
		const profitPercentString =
			isFinite(profitMargin) && Math.abs(profitMargin) < 1000
				? ` (${profitMargin}%)`
				: ``;
		const projectedString =
			(!this.forecastType.includes("hours") ? `$` : ``) +
			`${formatCurrency(Math.round(totalExpense))}`;
		const budgetString =
			(!this.forecastType.includes("hours") ? `$` : ``) +
			`${formatCurrency(Math.round(totalRevenue))}`;
		const totalString =
			`${projectedString} / ${budgetString}` + profitPercentString;
		let pRows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			{
				rowType: "sub-total",
				cells: [
					{
						value: profitTitle,
						cellType: "title",
						isRowHeader: true,
						isEditable: false,
						expandable: false,
						hideable: false,
						expanded: false,
						visible: true,
						row: this,
					},
					{
						value: totalString,
						isRowHeader: true,
						isEditable: false,
						visible: true,
					},
					...this.graphData.map((graphData) =>
						this.getProfitMonthIndexCell(graphData)
					),
				],
			},
		];
		return pRows;
	}

	getProfitMonthIndexCell(graphData) {
		const total = graphData.income - graphData.spend;
		const profitMargin = (total / graphData.income) * 100;
		const profitMarginText =
			isFinite(profitMargin) && Math.abs(profitMargin) < 1000
				? ` (${Math.round(profitMargin)}%)`
				: ``;
		const value =
			(!this.forecastType.includes("hours") ? `$` : ``) +
			`${formatCurrency(Math.round(total))}`;
		return {
			value: value + profitMarginText,
			numValue: total,
			inputText: null,
			monthIndex: graphData.monthIndex,
			visible: true,
			isEditable: false,
			error: total < 0,
		};
	}

	setSelectedExpenseCellExpense(text) {
		projectStore.setProjectModifiedProp(this.selectedExpenseRow.project, [
			"expenses",
			"changeLog",
		]);
		let row = this.selectedExpenseRow;
		let expense = parseFloat(text.replace(/\D/g, ""));
		this.selectedExpenseCellInputText = text.replace(/\D/g, "");
		row.setExpenseMonthIndex(this.selectedExpenseMonthIndex, expense);
		this.getExpenseData();
		this.emitChanged();
	}

	setSelectedHoursCellHours(text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		let newHours = parseFloat(text.replace(/\D/g, ""));
		this.selectedHoursInputText = text.replace(/\D/g, "");
		this.selectedHoursRow.setHoursMonthIndex(
			this.selectedHoursMonthIndex,
			newHours
		);
		this.selectedCostInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursUtilisationInputText = Math.round(
			this.selectedHoursRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}
	setSelectedHoursCellCost(text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		let newHours = parseFloat(text.replace(/\D/g, ""));
		this.selectedCostInputText = text.replace(/\D/g, "");
		this.selectedHoursRow.setCostMonthIndex(
			this.selectedHoursMonthIndex,
			newHours
		);
		this.selectedHoursInputText = Math.round(
			this.selectedHoursRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursUtilisationInputText = Math.round(
			this.selectedHoursRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}
	setSelectedHoursCellCostBudget(text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		let newHours = parseFloat(text.replace(/\D/g, ""));
		this.selectedCostBudgetInputText = text.replace(/\D/g, "");
		this.selectedHoursRow.setCostBudgetUseMonthIndex(
			this.selectedHoursMonthIndex,
			newHours
		);
		this.selectedHoursInputText = Math.round(
			this.selectedHoursRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursUtilisationInputText = Math.round(
			this.selectedHoursRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}
	setSelectedHoursCellHoursBudget(text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		let newHours = parseFloat(text.replace(/\D/g, ""));
		this.selectedHoursBudgetInputText = text.replace(/\D/g, "");
		this.selectedHoursRow.setBudgetUseMonthIndex(
			this.selectedHoursMonthIndex,
			newHours
		);
		this.selectedHoursInputText = Math.round(
			this.selectedHoursRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursUtilisationInputText = Math.round(
			this.selectedHoursRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}
	setSelectedHoursCellUtilisation(text) {
		projectStore.setProjectModifiedProp(this.selectedHoursRow.project, [
			"milestones",
			"allocations",
		]);
		let newHours = parseFloat(text.replace(/\D/g, ""));
		this.selectedHoursUtilisationInputText = text.replace(/\D/g, "");
		this.selectedHoursRow.setUtilisationMonthIndex(
			this.selectedHoursMonthIndex,
			newHours
		);
		this.selectedHoursInputText = Math.round(
			this.selectedHoursRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedCostBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedCostBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedHoursBudgetInputText = Math.round(
			this.selectedHoursRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}

	setIsReady(isReady) {
		this.isReady = isReady;
	}
}

export let projectForecastsStore = new ProjectForecastsStore();

export let actions = new ActionCollection(
	"PROJECT_FORECASTS_",
	projectForecastsStore,
	[
		{ name: "moveLeft", args: [], callback: "default" },
		{ name: "moveRight", args: [], callback: "default" },
		{ name: "toggleStaffExpand", args: ["uuid"], callback: "default" },
		{ name: "toggleStaffVisibility", args: ["uuid"], callback: "default" },
		{
			name: "toggleRevenueVisibility",
			args: ["uuid"],
			callback: "default",
		},
		{ name: "toggleExpenseExpand", args: ["uuid"], callback: "default" },
		{
			name: "toggleExpenseVisibility",
			args: ["uuid"],
			callback: "default",
		},

		{
			name: "setRevenueInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{
			name: "rollbackRevenueInputText",
			args: ["cell"],
			callback: "default",
		},
		{
			name: "commitRevenueInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{ name: "selectRevenueCell", args: ["cell"], callback: "default" },

		{
			name: "setHoursInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{ name: "rollbackHoursInputText", args: ["cell"], callback: "default" },
		{
			name: "commitHoursInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{ name: "selectHoursCell", args: ["cell"], callback: "default" },

		{
			name: "setExpenseInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{
			name: "rollbackExpenseInputText",
			args: ["cell"],
			callback: "default",
		},
		{
			name: "commitExpenseInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{ name: "selectExpenseCell", args: ["cell"], callback: "default" },

		{ name: "closeModal", args: ["cell"], callback: "default" },
		{ name: "clickAddPhaseButton", args: [], callback: "default" },
		{ name: "addPhase", args: ["name"], callback: "default" },
		{ name: "clickAddExpenseButton", args: ["phase"], callback: "default" },
		{ name: "addExpense", args: ["name"], callback: "default" },
		{
			name: "clickAddAllocationButton",
			args: ["row"],
			callback: "default",
		},
		{ name: "addAllocations", args: ["options"], callback: "default" },

		{ name: "changeFee", args: ["item", "fee"], callback: "default" },
		{
			name: "setExpenseBillability",
			args: ["row", "isBillable"],
			callback: "default",
		},

		{
			name: "selectRevenueMonthIndex",
			args: ["monthIndex"],
			callback: "default",
		},
		{
			name: "selectHoursMonthIndex",
			args: ["monthIndex"],
			callback: "default",
		},
		{
			name: "selectExpenseMonthIndex",
			args: ["monthIndex"],
			callback: "default",
		},
		{
			name: "setSelectedRevenueCellRevenue",
			args: ["text"],
			callback: "default",
		},
		{
			name: "setSelectedRevenueCellFeeUse",
			args: ["percentFeeUse"],
			callback: "default",
		},
		{ name: "setMenuType", args: ["menuType"], callback: "default" },
		{
			name: "setSelectedExpenseCellExpense",
			args: ["text"],
			callback: "default",
		},

		{
			name: "setSelectedHoursCellHours",
			args: ["text"],
			callback: "default",
		},
		{
			name: "setSelectedHoursCellCost",
			args: ["text"],
			callback: "default",
		},
		{
			name: "setSelectedHoursCellCostBudget",
			args: ["text"],
			callback: "default",
		},
		{
			name: "setSelectedHoursCellHoursBudget",
			args: ["text"],
			callback: "default",
		},
		{
			name: "setSelectedHoursCellUtilisation",
			args: ["text"],
			callback: "default",
		},

		{ name: "setIsReady", args: ["isReady"], callback: "default" },
	],
	dispatcher,
	function (action) {}
).actionsDict;
