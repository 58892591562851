import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import classNames from "classnames";
import { Project, ProjectPhase } from "../models.js";
import { organisationStore } from "../organisation.js";
import { Table } from "../table.js";
import { getOnboardingData } from "../organisationStore.js";
import {
	projectsPageStore,
	actions as projectTableActions,
} from "./ProjectsPageStore.js";
import { userStore } from "../user.js";
import {
	ReportStoreWidget,
	ReportStoreSaveBar,
} from "../reports/ReportWidget.js";
import { CoincraftPage } from "../CoincraftPage.js";
import { IncompleteSetupPage } from "../IncompleteSetupPage.js";
import { columnPresentation } from "./columnPresentation.js";
import { rootStore } from "../RootStore.js";
import { wrapUserStore } from "../user/utils.js";
import { reportsPageWrapper } from "../reports/reportsPageWrapper.js";
import { permissions, requiresPermission } from "../models/permissions.js";
import { ReportType } from "../reports/Report.js";
import { Checkbox } from "../widgets.js";

export var ProjectsPage = wrapUserStore(
	requiresPermission(
		permissions.noRestrictions,
		{
			canCreateProject: permissions.canCreateProject,
		},
		CreateReactClass({
			mixins: [
				makeMultipleStoreMixin(
					[
						rootStore,
						projectsPageStore,
						organisationStore,
						userStore,
					],
					function () {
						const reportStore = projectsPageStore.reportStore;
						const hasPhaseFilters =
							reportStore.report.filters
								.toJS()
								.filter((f) => f.options?.applyPhases).length >
							0;
						return {
							user: userStore.user,
							onboardingData: getOnboardingData(
								organisationStore,
								permissions,
								userStore
							),
							matchingProjects: reportStore
								.getMatchingItems(
									organisationStore.projects,
									hasPhaseFilters &&
										((filter) => {
											return !filter.options?.applyPhases;
										})
								)
								.filter((p) =>
									permissions
										.canViewProject(p)
										.ok(userStore.user) && hasPhaseFilters
										? p.getPhasesForProjectTable(
												reportStore
										  ).length
										: true
								)
								.sort((a, b) =>
									a.getTitle()?.localeCompare(b.getTitle())
								),
							expandedProjects:
								projectsPageStore.expandedProjects,
							selectedVisibleColumns:
								projectsPageStore.selectedVisibleColumns,
							reportStore: reportStore,
							reports: organisationStore.reports.filter(
								(r) => r.reportType === ReportType.project
							),
							isDirty: rootStore.isDirty,
							projectsPageStore: projectsPageStore,
						};
					}
				),
			],

			render: function () {
				let self = this;

				if (!this.state.onboardingData.hasProjects) {
					return (
						<div>
							<IncompleteSetupPage
								header={<strong>No projects yet</strong>}
								onboardingData={this.state.onboardingData}
							/>
						</div>
					);
				} else {
					return (
						<CoincraftPage
							className="projects-page"
							header={
								<ReportStoreWidget
									heading="Projects"
									user={this.state.user}
									items={this.state.matchingProjects}
									reports={this.state.reports}
									reportStore={this.state.reportStore}
									columnPresentation={columnPresentation}
									filterInputPlaceholder="Filter by project name or job code"
									canRefresh={!this.state.isDirty}
									buttons={[
										this.props.canCreateProject ? (
											<button
												key={0}
												className="project-list__add-new-project-button page-header__button--primary"
												onClick={() =>
													projectTableActions.newProject()
												}
											>
												+ New Project
											</button>
										) : null,
									]}
									filterOptions={(filter, filterIndex) => {
										return (
											<Checkbox
												className="filter-row__show-column-checkbox"
												label="Apply to Phases"
												labelPosition="right"
												value={
													filter.toJS().options
														?.applyPhases || false
												}
												onChange={(isSelected) => {
													self.state.reportStore.setFilterOptions(
														filterIndex,
														{
															applyPhases:
																isSelected,
														}
													);
													//dirty but works
													self.state.projectsPageStore.emitChanged();
												}}
											/>
										);
									}}
								/>
							}
							body={
								<div style={{ height: "100%" }}>
									<div
										className="project-table new-dashboard__project-list"
										style={{
											textAlign: "left",
											height: "100%",
											minHeight: "30em",
										}}
									>
										<button
											className="btn btn-default dont-print"
											style={{ margin: "-1em 1em 1em 0" }}
											onClick={() => {
												projectTableActions.expandProjects(
													self.state.matchingProjects
												);
											}}
										>
											<i
												className="fa fa-plus-square"
												style={{ margin: "0.5em" }}
											/>
											Expand All
										</button>
										<button
											className="btn btn-default dont-print"
											style={{ margin: "-1em 0 1em 0" }}
											onClick={() => {
												projectTableActions.collapseProjects(
													self.state.matchingProjects
												);
											}}
										>
											<i
												className="fa fa-minus-square"
												style={{ margin: "0.5em" }}
											/>
											Collapse All
										</button>
										<Table
											scroll={true}
											rows={this.state.matchingProjects}
											columns={
												this.state
													.selectedVisibleColumns
											}
											rowProps={function (
												item,
												i,
												stack
											) {
												return {
													className: classNames(
														"new-dashboard__project-list__row",
														"new-dashboard__project-list__item",
														"project-table__row",
														self.props.className
													),
													style:
														item.constructor !==
														Project //TODO - don't apply the following to last phase item
															? {
																	borderBottom:
																		"1px solid #efefef",
															  }
															: {},
													onClick: function (event) {
														// Only care about clicking on projects.. clicking on phases doesn't do anything.
														if (
															item.constructor ===
															Project
														) {
															projectTableActions.toggleProjectExpanded(
																item
															);
														}
													},
												};
											}}
											tableData={function () {
												return {
													expandedProjects:
														self.state
															.expandedProjects,
												};
											}}
											getRowChildren={function (item) {
												if (
													_.include(
														self.state
															.expandedProjects,
														item
													)
												) {
													return item.getPhasesForProjectTable(
														self.state.reportStore
													);
												} else {
													return [];
												}
											}}
											getCellProps={function (item) {
												return {
													style: {
														padding:
															item.constructor ===
															ProjectPhase
																? "0.4em 0.6em 0"
																: "0.6em 0.6em 0",
														fontSize:
															item.constructor ===
															ProjectPhase
																? ".95em"
																: "1em",
													},
												};
											}}
											columnPresentation={
												columnPresentation
											}
											initialSort={{
												columnIdentifier: "name",
												direction: "asc",
												isAlreadySorted: true,
											}}
										/>
									</div>
								</div>
							}
						/>
					);
				}
			},
		})
	)
);

export var ProjectsPageWrapper = reportsPageWrapper(
	projectsPageStore,
	ProjectsPage
);
