import _ from 'underscore';
import { groupBy, izip } from './utils.js';


export function groupRows({
    items,
    groupers = [],
    expandedGroups = [],
    aggregates = {},
    idFuncs = {},
    sortBy = null
  }) {
  function doGroupLevel(items, levelIndex, groupStack) {
    function getItemId(item, itemType) {
      const idFunc = idFuncs[itemType];
      return (idFunc != null) ? idFunc(item) : item;
    }

    if (levelIndex >= groupers.length) {
      return items;
    }
    else {
      let grouperName = groupers[levelIndex];
      return groupBy(
        items,
        item => item[grouperName],
        item => getItemId(item, grouperName)
      ).map(function({grouper, items}) {
        let row = {};
        let nextGroupStack = [...groupStack, grouper];

        _.each(aggregates, function(v, k) {
          row[k] = v(items, nextGroupStack);
        });

        for (let [grouperName, grouperValue] of izip(groupers, groupStack)) {
          row[grouperName] = grouperValue;
        }

        row[grouperName] = grouper;
        row.children = doGroupLevel(items, levelIndex + 1, nextGroupStack);

        if (levelIndex < expandedGroups.length) {
          if (expandedGroups === 'all'
              || _.any(expandedGroups, eg => doGroupsMatch(eg, nextGroupStack, groupers, getItemId))) {
            row.expanded = (row.children.length > 0);
          }
        }
        return row;
      });
    }
  }

  return doGroupLevel(items, 0, []);
}


export function * iterRowDescendants(row) {
  yield row;
  if (row.children != null) {
    for (let child of row.children) {
      yield * iterRowDescendants(child);
    }
  }
}


export function * iterRowLeafDescendants(row) {
  // Use leaf descendants for the methods below because if we regroup (eg. by
  // selecting or deselecting a row), then the groups are regenerated so we
  // can't use === to match against the selected items list.
  for (let d of iterRowDescendants(row)) {
    if (d.children == null) {
      yield d;
    }
  }
}

export function isRowChecked(row, selectedItems) {
  let foundItem = false;
  for (let d of iterRowLeafDescendants(row)) {
    if (!_.include(selectedItems, d)) {
      return false;
    }
    foundItem = true;
  }
  return foundItem;
}

export function isRowUnlocked(row) {
  let lockFound = false;
  for (let d of iterRowLeafDescendants(row)) {
    if (d.isLocked) {
      lockFound = true;
    }
  }
  return !lockFound;
}


export function isRowIndeterminate(row, selectedItems) {
  let trueFound = false, falseFound = false;
  for (let d of iterRowLeafDescendants(row)) {
    if (_.include(selectedItems, d)) {
      trueFound = true;
    }
    else {
      falseFound = true;
    }
    if (trueFound && falseFound) {
      return true;
    }
  }
  return false;
}


function doGroupsMatch(g1, g2, groupers, getItemId) {
  if (g1.length !== g2.length) {
    return false;
  }

  for (let [g1Item, g2Item, grouperName] of izip(g1, g2, groupers)) {
    if (getItemId(g1Item, grouperName) !== getItemId(g2Item, grouperName)) {
      return false;
    }
  }

  return true;
}
