import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { trueKeys } from '../utils.js';
import { Checkbox } from '../widgets.js';
import { organisationStore } from '../organisation.js';
import PropTypes from "prop-types";


export var StaffMemberCheckboxList = CreateReactClass({
  /**
   * Staff members will appear in this list if they're active, or if they're
   * already in the `selectedStaffMembers` (or `allocation`) sets. If an
   * archived staff member is initially selected, and the user deselects it, it
   * won't immediately disappear. But if the user saves that result, the staff
   * member won't appear the next time the form is opened.
   */
  propTypes: {
    staffMembers: PropTypes.array.isRequired,

    // One of these. We can either pass a list of selected staff members as
    // `selectedStaffMembers`, or a dict mapping staff member ids to boolean
    // values (nonexistent / non-truthy values are false) as `allocation`. On
    // change the `onChange` callback will be called with an argument of the
    // same type as whichever of `selectedStaffMembers` and `allocation` is
    // provided.
    selectedStaffMembers: PropTypes.array,
    allocation: PropTypes.object,

    onChange: PropTypes.func
  },

  getInitialState: function() {
    return {
      initialAllocation: this.getAllocationDict()
    };
  },

  render: function() {
    let self = this;
    const allocation = this.getAllocationDict();

    return (
      <ul
          className="staff-list"
          style={{listStyleType: 'none', ...this.props.style}}>
        {this.props.staffMembers.map(function(staffMember, i) {
          const isChecked = (allocation[staffMember.id] === true);
          if (staffMember.isArchived && !isChecked && !self.state.initialAllocation[staffMember.id]) {
            return null;
          }
          return <li key={i} style={{width: '25em', display: 'inline-block', margin: '1em 0'}}>
            <Checkbox
              className="staff-member-name"
              value={isChecked}
              label={<span>{staffMember.firstName} {staffMember.lastName}</span>}
              onChange={function(checked) {
                allocation[staffMember.id] = checked;
                if (self.props.allocation != null) {
                  self.props.onChange(allocation);
                }
                else {
                  self.props.onChange(trueKeys(allocation).map(id => organisationStore.getStaffMemberById(parseInt(id))));
                }
              }}
            />
          </li>;
        })}
      </ul>
    );
  },

  getAllocationDict() {
    if (this.props.allocation != null) {
      return _.clone(this.props.allocation);
    }
    else {
      let allocation = {};
      this.props.selectedStaffMembers.forEach(function(sm) {
        allocation[sm.id] = true;
      });
      return allocation;
    }
  }
});



