import React from "react";
import CreateReactClass from "create-react-class";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_NEW_CLIENT_URL) {
	const baseUrl = process.env.REACT_APP_NEW_CLIENT_URL;

	// Get the current path, search, and hash from the window location
	const path = window.location.pathname;
	const search = window.location.search;
	const hash = window.location.hash;

	// Construct the new URL using the base URL and the current path, search, and hash
	window.location.href = `${baseUrl}${path}${search}${hash}`;
} else {
	// Render the React application if no redirection is needed
	ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
