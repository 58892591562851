import _ from 'underscore';
import { rangeIntersection, isNumber } from '../utils.js';
import { dateConverter } from './dateconverter.js';
import { organisationStore } from '../organisation.js';
import { TimesheetItem } from './timesheetitem.js';


export const RangeAllocation = class {
  constructor({startDate, endDate, staffMember, staffRole, hours, phase}) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.staffMember = staffMember;
    this.staffRole = staffRole;
    this.hours = hours;
    this.durationUnit = 'weeks';
    this.phase = phase;
  }

  get staffMember() {
    return this._staffMember;
  }

  set staffMember(s) {
    this._staffMember = Number.isInteger(s) ? organisationStore.getStaffMemberById(s) : s;
  }

  get staffRole() {
    return this._staffRole;
  }

  set staffRole(s) {
    this._staffRole = Number.isInteger(s) ? organisationStore.getStaffRoleById(s) : s;
  }

  serialize() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      staffMemberId: this.staffMember ? this.staffMember.id : null,
      staffRoleId: this.staffRole ? this.staffRole.id : null,
      hours: this.hours || 0
    };
  }

  toString() {
    return '<' + [
      this.staffMember ? this.staffMember.getFullName() : "",
      dateConverter.intToString(this.startDate),
      dateConverter.intToString(this.endDate),
      this.hours.toFixed(2)
    ].join(":") + '>';
  }

  get length() {
    return this.endDate - this.startDate + 1;
  }

  copy() {
    return new RangeAllocation({
      startDate: this.startDate,
      endDate: this.endDate,
      staffMember: this.staffMember ? this.staffMember.id : null,
      staffRole: this.staffRole ? this.staffRole.id : null,
      hours: this.hours
    });
  }

  clone() {
    // `RangeAllocation`s are value objects so clone is the same as copy.
    return this.copy();
  }

  getHoursForStaffMemberInDateRange(staffMember = null, startDate, endDate) {
    /**
     * If `staffMember == null`, return all hours in date range.
     */
    if (!this.staffMember) return 0
    if (staffMember != null && staffMember.id !== this.staffMember.id) {
      return 0;
    }
    let numWeekdays = dateConverter.numWeekdaysBetween(this.startDate, this.endDate);
    if (numWeekdays === 0) {
      return 0;
    }
    let intersection = rangeIntersection([this.startDate, this.endDate], [startDate, endDate]);
    if (intersection == null) {
      return 0;
    }
    let rangeWeekdays = dateConverter.numWeekdaysBetween(intersection[0], intersection[1]);
    return this.hours * (rangeWeekdays / numWeekdays);
  }

  getHoursForStaffRoleInDateRange(staffRole = null, startDate, endDate) {
    /**
     * If `staffMember == null`, return all hours in date range.
     */
    if (!this.staffRole) return 0
    if (staffRole != null && staffRole.id !== this.staffRole.id) {
      return 0;
    }
    let numWeekdays = dateConverter.numWeekdaysBetween(this.startDate, this.endDate);
    if (numWeekdays === 0) {
      return 0;
    }
    let intersection = rangeIntersection([this.startDate, this.endDate], [startDate, endDate]);
    if (intersection == null) {
      return 0;
    }
    let rangeWeekdays = dateConverter.numWeekdaysBetween(intersection[0], intersection[1]);
    return this.hours * (rangeWeekdays / numWeekdays);
  }

  getTotalHoursInDateRange(startDate, endDate, {nullIfNoIntersection = false} = {}) {
    let numWeekdays = dateConverter.numWeekdaysBetween(this.startDate, this.endDate);
    let intersection = rangeIntersection([this.startDate, this.endDate], [startDate, endDate]);
    if (intersection == null) {
      if (nullIfNoIntersection) {
        return null;
      }
      else {
        return 0;
      }
    }
    if (this.hours === 0 || numWeekdays === 0) {
      return 0;
    }
    let rangeWeekdays = dateConverter.numWeekdaysBetween(intersection[0], intersection[1]);
    return this.hours * (rangeWeekdays / numWeekdays);
  }

  getTotalHoursInMonthIndex(monthIndex, { nullIfNoIntersection = false } = {}) {
    const startMoment = dateConverter.monthIndexToMoment(monthIndex);
    const startDateInt = dateConverter.momentToInt(startMoment);
    const endDateInt = dateConverter.momentToInt(startMoment.clone().endOf('month'));
    return this.getTotalHoursInDateRange(startDateInt, endDateInt, {nullIfNoIntersection: nullIfNoIntersection})
  }

  splitDateRange(startDate, endDate) {
    /**
     * `startDate`: int
     * `endDate`: int
     * `factor`: number
     *
     * Multiplies the hours in the range from `startDate` to `endDate`
     * inclusive by `factor`, and returns the shortest list of
     * `RangeIntersection`s that satisfy this.
     */

    if (endDate < this.startDate || startDate > this.endDate) {
      return {
        sections: [this.clone()],
        intersectingSection: null
      };
    }
    else {
      let sections, intersectingSection;

      if (startDate > this.startDate && endDate < this.endDate) {
        let p = this.split(startDate - 1);
        let q = p[1].split(endDate);
        sections = [p[0], q[0], q[1]];
        intersectingSection = sections[1];
      }
      else if (startDate > this.startDate) {
        sections = this.split(startDate - 1);
        intersectingSection = sections[1];
      }
      else if (endDate < this.endDate) {
        sections = this.split(endDate);
        intersectingSection = sections[0];
      }
      else {
        sections = [this.clone()];
        intersectingSection = sections[0];
      }

      return {
        sections: sections,
        intersectingSection: intersectingSection
      };
    }
  }

  scaleSectionByFactor(startDate, endDate, factor) {
    /**
     * `startDate`: int
     * `endDate`: int
     * `factor`: number
     *
     * Multiplies the hours in the range from `startDate` to `endDate`
     * inclusive by `factor`, and returns the shortest list of
     * `RangeIntersection`s that satisfy this.
     */

    let {sections, intersectingSection} = this.splitDateRange(startDate, endDate);

    if (intersectingSection != null) {
      intersectingSection.hours *= factor;
    }

    return sections;
  }

  scaleRangeByFactor(startDate, endDate, factor) {
    /**
     * `startDate`: int
     * `endDate`: int
     * `factor`: number
     *
     * Scales the range from `startDate` to `endDate` (inclusive) horizontally by `factor`,
     * and returns the shortest list of `RangeIntersection`s that satisfy this.
     */

    let {sections, intersectingSection} = this.splitDateRange(startDate, endDate);

    if (intersectingSection != null) {
      let expansion = intersectingSection.length * factor - intersectingSection.length;
      intersectingSection.endDate += expansion;

      let intersectingIndex = _.findIndex(sections, intersectingSection);
      for (let i = intersectingIndex + 1; i < sections.length; i++) {
        let s = sections[i];
        s.startDate += expansion;
        s.endDate += expansion;
      }
    }

    return sections;
  }

  split(date) {
    /**
     * `date`: int
     *
     * Returns two `RangeAllocation`s, the first being from the original start
     * date to `date`, inclusive, the second being from the day after `date` to
     * the original end date.
     */

    let numWeekdays = dateConverter.numWeekdaysBetween(this.startDate, this.endDate);
    let firstRangeWeekdays = dateConverter.numWeekdaysBetween(this.startDate, date);
    let secondRangeWeekdays = numWeekdays - firstRangeWeekdays;

    let firstFactor = firstRangeWeekdays / numWeekdays;
    let secondFactor = secondRangeWeekdays / numWeekdays;

    return [
      new RangeAllocation({
        startDate: this.startDate,
        endDate: date,
        staffMember: this.staffMember,
        staffRole: this.staffRole,
        hours: this.hours * firstFactor,
        phase: this.phase
      }),
      new RangeAllocation({
        startDate: date + 1,
        endDate: this.endDate,
        staffMember: this.staffMember,
        staffRole: this.staffRole,
        hours: this.hours * secondFactor,
        phase: this.phase
      })
    ];
  }

  splitStaffMember(staffMember) {
    return [this.clone()];
  }

  splitStaffRole(staffRole) {
    return [this.clone()];
  }

  splitStaffMemberAndDateRange(staffMember, startDate, endDate) {
    let s = this.splitStaffMember(staffMember);
    if (s[0].staffMember.id === staffMember.id) {
      let {sections, intersectingSection} = s[0].splitDateRange(startDate, endDate);
      let dateIntersectingSection = null;
      if (intersectingSection != null) {
        let intersectingSectionIndex = _.findIndex(sections, intersectingSection);

        let {
          sections: dateSections,
          intersectingSection: _dateIntersectingSection
        } = intersectingSection.splitDateRange(startDate, endDate);
        sections[intersectingSectionIndex] = dateSections;
        sections = _.flatten(sections);

        dateIntersectingSection = _dateIntersectingSection;
      }
      return {
        sections: [...sections, ...s.splice(1)],
        intersectingSection: dateIntersectingSection
      };
    }
    else {
      return {
        sections: [this.clone()],
        intersectingSection: null
      };
    }
  }

  splitStaffRoleAndDateRange(staffRole, startDate, endDate) {
    let s = this.splitStaffRole(staffRole);
    if (s[0].staffRole.id === staffRole.id) {
      let { sections, intersectingSection } = s[0].splitDateRange(startDate, endDate);
      let dateIntersectingSection = null;
      if (intersectingSection != null) {
        let intersectingSectionIndex = _.findIndex(sections, intersectingSection);

        let {
          sections: dateSections,
          intersectingSection: _dateIntersectingSection
        } = intersectingSection.splitDateRange(startDate, endDate);
        sections[intersectingSectionIndex] = dateSections;
        sections = _.flatten(sections);

        dateIntersectingSection = _dateIntersectingSection;
      }
      return {
        sections: [...sections, ...s.splice(1)],
        intersectingSection: dateIntersectingSection
      };
    }
    else {
      return {
        sections: [this.clone()],
        intersectingSection: null
      };
    }
  }

  scaleStaffMemberAndDateRange(staffMember, startDate, endDate, factor) {
    let {sections, intersectingSection} = this.splitStaffMemberAndDateRange(staffMember, startDate, endDate);
    if (intersectingSection != null) {
      intersectingSection.hours *= factor;
    }
    return sections;
  }

  scaleStaffRoleAndDateRange(staffRole, startDate, endDate, factor) {
    let { sections, intersectingSection } = this.splitStaffRoleAndDateRange(staffRole, startDate, endDate);
    if (intersectingSection != null) {
      intersectingSection.hours *= factor;
    }
    return sections;
  }

  setStaffMemberAndDateHours(staffMember, startDate, endDate, hours) {
    let {sections, intersectingSection} = this.splitStaffMemberAndDateRange(staffMember, startDate, endDate);
    if (intersectingSection != null) {
      intersectingSection.hours = hours;
    }
    return sections;
  }

  setStaffRoleAndDateHours(staffRole, startDate, endDate, hours) {
    let { sections, intersectingSection } = this.splitStaffRoleAndDateRange(staffRole, startDate, endDate);
    if (intersectingSection != null) {
      intersectingSection.hours = hours;
    }
    return sections;
  }

  getStartMoment() {
    if (this.startDate == null) {
      return null;
    }
    if (!isNumber(this.startDate)) {
      throw new Error("startDate should have been a number");
    }
    return dateConverter.intToMoment(this.startDate);
  }

  getEndMoment() {
    if (this.endDate == null) {
      return null;
    }
    if (!isNumber(this.endDate)) {
      throw new Error("startDate should have been a number");
    }
    return dateConverter.intToMoment(this.endDate);
  }

  getDuration() {
    if(this.durationUnit==="months") {
      return this.getEndMoment().diff(this.getStartMoment(), 'months', true);
    } else if(this.durationUnit==="weeks") {
      return (this.endDate - this.startDate)/7;
    } else {
      return this.endDate - this.startDate;
    }
  }

  getTimesheetItems() {
    if (!this.staffRole && !this.staffMember) return []
    const startMonthIndex = dateConverter.getMonthIndex(this.startDate)
    const endMonthIndex = dateConverter.getMonthIndex(this.endDate)
    if (startMonthIndex === endMonthIndex) {
      return [new TimesheetItem({
        project: this.phase.project,
        phase: this.phase,
        staffMember: this.staffMember,
        staffRole: this.staffRole || this.staffMember.role,
        hours: this.hours,
        allocation: this,
        type: this.staffMember ? "staffAllocation" : "roleAllocation",
        monthIndex: startMonthIndex,
      })]
    } else {
      return _.range(startMonthIndex, endMonthIndex+1).map(monthIndex => {
        return new TimesheetItem({
          project: this.phase.project,
          phase: this.phase,
          staffMember: this.staffMember,
          staffRole: this.staffRole || this.staffMember.role,
          hours: this.getTotalHoursInDateRange(
            dateConverter.monthIndexToOffset(monthIndex), 
            dateConverter.monthIndexToEndOfMonthOffset(monthIndex)
          ),
          allocation: this,
          type: this.staffMember ? "staffAllocation" : "roleAllocation",
          monthIndex: monthIndex,
        })
      })
    }
  }
}
