import _ from "lodash";
import {
	durationToUnit,
	simplifyDuration,
	simplifyRate,
	ratePerUnit,
} from "@ryki/datemath";
import numeral from "numeral";
import { format as formatDate } from "date-fns";

export let Functions = {
	abs: {
		number: (args, context) => ({
			type: args[0].type,
			value: Math.abs(args[0].value),
		}),
	},
	round: {
		number: (args, context) => ({
			type: args[0].type,
			value: _.round(...args.map((a) => a.value)),
		}),
	},
	max: {
		number: (args, context) => ({
			type: args[0].type,
			value: _.max(args.map((a) => a.value).filter((v) => v)),
		}),
		dateTime: (args, context) => ({
			type: args[0].type,
			value: _.max(args.map((a) => a.value).filter((v) => v)),
		}),
		duration: (args, context) => ({
			type: args[0].type,
			value: simplifyDuration({
				milliseconds: _.max(
					args
						.map((a) => durationToUnit(a.value, "milliseconds"))
						.filter((v) => v)
				),
			}),
		}),
	},
	min: {
		number: (args, context) => ({
			type: args[0].type,
			value: _.min(args.map((a) => a.value).filter((v) => v)),
		}),
		dateTime: (args, context) => ({
			type: args[0].type,
			value: _.min(args.map((a) => a.value).filter((v) => v)),
		}),
		duration: (args, context) => ({
			type: args[0].type,
			value: simplifyDuration({
				milliseconds: _.min(
					args
						.map((a) => durationToUnit(a.value, "milliseconds"))
						.filter((v) => v)
				),
			}),
		}),
	},
	sum: {
		number: (args, context) => ({
			type: args[0].type,
			value: _.sum(args.map((a) => a.value).filter((v) => v)),
		}),
		duration: (args, context) => {
			return {
				type: args[0].type,
				value: simplifyDuration({
					milliseconds: _.sum(
						args
							.map((a) => durationToUnit(a.value, "milliseconds"))
							.filter((v) => v)
					),
				}),
			};
		},
	},
	avg: {
		number: (args, context) => ({
			type: args[0].type,
			value: _.mean(args.map((a) => a.value).filter((v) => v)),
		}),
		duration: (args, context) => ({
			type: args[0].type,
			value: simplifyDuration({
				milliseconds: _.mean(
					args
						.map((a) => durationToUnit(a.value, "milliseconds"))
						.filter((v) => v)
				),
			}),
		}),
		rate: (args, context) => ({
			type: args[0].type,
			value: simplifyRate({
				amount: _.mean(
					args.map((a) => ratePerUnit(a.value, "milliseconds").amount)
				),
				per: { milliseconds: 1 },
			}),
		}),
	},
	format: {
		number: (args, context) => ({
			type: "text",
			value: numeral(args[0].value).format(args[1].value),
		}),
		dateTime: (args, context) => ({
			type: "text",
			value: formatDate(args[0].value, args[1].value),
		}),
	},
};

export const addFunctions = (newConsts) => {
	Functions = {
		...Functions,
		...newConsts,
	};
};
