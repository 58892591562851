export const labelStyle = {lineHeight: '30px', verticalAlign: 'middle', width: '8.5em', display: 'inline-block'};
export const indentStyle = {marginLeft: '3em'};
export const quietInfoPanel = {
  backgroundColor: '#e7f6fd',
  fontSize: '0.95em',
  lineHeight: '1.4',
  padding: '1em'
};
export const tabContentStyle = {marginTop: '1.5em'};


