import React from 'react';
import CreateReactClass from 'create-react-class';
import { invoiceActions } from './invoices/InvoicePageStore.js';
import { InvoiceSettings } from './invoices/InvoiceSettings.js';
import { NewInvoicePopup } from './invoices/NewInvoicePopupContent.js';
import { MyobAccountRightAuthForm } from './invoices/accounting/myobAccountRight.js';
import * as modalTypes from './modalTypes.js';
import { DecriptionTemplatesModal } from './invoices/descriptionTemplatesModal.js';


export const modals = {
			[modalTypes.newInvoice]: function(modal) {
				return {
					header: "New invoice",
					closeOnClickOutside: true,
					width: "35em",
					content: <NewInvoicePopup />
				};
			},
			[modalTypes.descriptionTemplates]: function(modal) {
				return {
					header: "Invoice Templates",
					closeOnClickOutside: true,
					width: "55em",
					content: <DecriptionTemplatesModal />
				};
			},
			[modalTypes.accountingSystemSettings]: function(modal) {
				return {
					header: "Invoice settings",
					closeOnClickOutside: true,
					width: "50em",
					content: (
						<InvoiceSettings
							onSave={function(
								accountingSystemId,
								settings,
								generalSettings
							) {
								invoiceActions.saveAccountingSystemSettings(
									accountingSystemId,
									settings,
									generalSettings,
									modal.then
								);
							}}
							then={modal.then}
						/>
					)
				};
			},
			[modalTypes.myobAccountRightAuthForm]: function(modal) {
				return {
					header: "MYOB AccountRight Authentication",
					closeOnClickOutside: false,
					width: "40em",
					content: (
						<MyobAccountRightAuthForm
							onSubmit={(username, password) =>
								invoiceActions.setMyobAccountRightAuth(
									modal,
									username,
									password
								)
							}
							onDismiss={() =>
								invoiceActions.cancelModal(modal.type)
							}
							initialUsername={
								modal.initialData != null
									? modal.initialData.username
									: null
							}
							initialPassword={
								modal.initialData != null
									? modal.initialData.password
									: null
							}
						/>
					)
				};
			}
		};

