import React from "react";
import CreateReactClass from "create-react-class";
import { actions } from "./flux.js";
import { permissions, requiresPermission } from "../models/permissions.js";
import { ProjectNote } from "../models/projectnote.js";
import { TextValue, DateValue, SmallDeleteButton } from "../widgets.js";
import { dateConverter, ProjectPhase } from "../models.js";
import {
	formatCurrencyWithCents,
	formatPercentage0,
	formatNumber2,
	sum,
} from "../utils.js";
import PropTypes from "prop-types";
import { ProjectPhaseSelector } from "../widgets/ProjectPhaseSelector.js";
import { organisationStore } from "../organisation.js";

export var ProjectNoteList = CreateReactClass({
	propTypes: {
		project: PropTypes.object.isRequired,
		editable: PropTypes.bool.isRequired,
	},

	render: function () {
		if (this.props.project.notes.length === 0) {
			let newNote = new ProjectNote();
			this.props.project.notes.unshift(newNote);
		}
		return (
			<div style={{ margin: "3em 2em" }}>
				<h4
					style={{
						fontSize: "1.8em",
						margin: "1em 1em 0.5em 0.5em",
						display: "inline-block",
					}}
				>
					Notes
				</h4>

				{this.props.editable ? (
					<button
						onClick={() => actions.createNote()}
						className="btn btn-default"
					>
						<i
							className="fa fa-plus fa-fw"
							style={{ marginRight: 0, fontSize: ".9em" }}
						/>{" "}
						Add Note
					</button>
				) : null}

				<ProjectNoteTable
					notes={this.props.project.notes}
					editable={this.props.editable}
					project={this.props.project}
				/>
			</div>
		);
	},
});

var ProjectNoteTable = CreateReactClass({
	propTypes: {
		notes: PropTypes.object.isRequired,
		projects: PropTypes.object.isRequired,
		editable: PropTypes.bool.isRequired,
	},

	render: function () {
		let self = this;
		const notes = this.props.notes.sort((a, b) => {
			// Handling null values separately
			if (a.date === null && b.date === null) {
				// Both dates are null, compare by uuid
				return a.uuid < b.uuid ? -1 : 1;
			} else if (a.date === null) {
				// a.date is null and b.date is not, so a comes before b
				return -1;
			} else if (b.date === null) {
				// b.date is null and a.date is not, so a comes after b
				return 1;
			}

			// Both dates are not null, so we can compare them
			if (a.date.isAfter(b.date)) {
				return -1;
			} else if (a.date.isBefore(b.date)) {
				return 1;
			}

			// If both dates are same, then sort based on uuid
			return a.uuid < b.uuid ? -1 : 1;
		});
		return (
			<div
				className="coincraft-table"
				style={{ width: "56em", margin: "1em" }}
			>
				<div className="flexbox-container coincraft-table-row header">
					<div
						className="coincraft-table-cell"
						style={{ width: "15em" }}
					>
						Date
					</div>
					<div
						className="coincraft-table-cell"
						style={{ width: "35em" }}
					>
						Note
					</div>
					{this.props.editable ? (
						<div
							className="coincraft-table-cell"
							style={{ width: "3em" }}
						></div>
					) : null}
				</div>
				{notes.map(function (note, i) {
					return (
						<NoteRow
							key={note.uuid}
							editable={self.props.editable}
							note={note}
							project={self.props.project}
						/>
					);
				})}
			</div>
		);
	},
});

var NoteRow = CreateReactClass({
	propTypes: {
		note: PropTypes.object.isRequired,
		project: PropTypes.object.isRequired,
		editable: PropTypes.bool.isRequired,
	},

	render: function () {
		const note = this.props.note;

		return (
			<div className="flexbox-container coincraft-table-row">
				<div
					className="coincraft-table-cell coincraft-table-cell--date"
					style={{ width: "15em" }}
				>
					<DateValue
						value={note.date}
						isEditable={this.props.editable}
						onChange={(date) => actions.setNoteDate(note, date)}
					/>
				</div>
				<div
					className="coincraft-table-cell coincraft-table-cell--dropdown"
					style={{ width: "20%" }}
				>
					<ProjectPhaseSelector
						project={this.props.project}
						phase={organisationStore.getProjectPhaseByUuid(
							note.phaseUuid
						)}
						allowNull={true}
						placeholder="(No Phase)"
						onChange={(phase) => {
							actions.setNotePhase(note, phase);
						}}
					/>
				</div>
				<div
					className="coincraft-table-cell coincraft-table-cell--textarea"
					style={{ width: "45em" }}
				>
					<textarea
						rows={2}
						style={{ width: "100%" }}
						value={note.description}
						disabled={!this.props.editable}
						onChange={(event) =>
							actions.setNoteDescription(note, event.target.value)
						}
					/>
				</div>
				{this.props.editable ? (
					<div
						className="coincraft-table-cell coincraft-table-cell--btn"
						style={{ width: "3em" }}
					>
						<SmallDeleteButton
							onClick={() => actions.deleteNote(note)}
						/>
					</div>
				) : null}
			</div>
		);
	},
});
