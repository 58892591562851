import React from 'react';
import CreateReactClass from 'create-react-class';
import { projectForecastsStore, actions } from './ProjectForecastsStore';
import { projectStore } from '../flux';
import { makeMultipleStoreMixin } from '../../coincraftFlux';
import { rootStore } from '../../RootStore';
import { organisationStore } from '../../organisation';
import { TimesheetMenu } from './menu/TimesheetMenu';
import { RevenueMenu } from './menu/RevenueMenu';
import { ExpenseMenu } from './menu/ExpenseMenu';

export var ProjectForecastsMenu = CreateReactClass({

    mixins: [
        makeMultipleStoreMixin([rootStore, organisationStore, projectStore, projectForecastsStore], function () {
            return {
                menuType: projectForecastsStore.menuType
            };
        })
    ],

    render() {
        const menu = {
            revenue: <div style={{ minWidth: "25em" }}>
                <RevenueMenu isEditable={this.props.isEditable} onChange={this.props.onChange}/>
            </div>,
            hours: <div style={{ minWidth: "25em" }}>
                <TimesheetMenu isEditable={this.props.isEditable} onChange={this.props.onChange} viewFinancials={this.props.viewFinancials} viewExpenses={this.props.viewExpenses}/>
            </div>,
            expenses: <div style={{ minWidth: "25em" }}>
                <ExpenseMenu isEditable={this.props.isEditable} onChange={this.props.onChange}/>
            </div>
        }
        if (menu[this.state.menuType]) {
            return menu[this.state.menuType]
        } else {
            return <div></div>
        }
    },

});