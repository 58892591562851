import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { AccountingSystem } from './base.js';
import { Checkbox } from '../../widgets/Checkbox.js';
import { AccountSelectors } from './AccountSelectors.js';
import { makeMultipleStoreMixin } from '../../coincraftFlux.js';

import PropTypes from "prop-types";

export const XeroAccountingSystem = class extends AccountingSystem {
  constructor(options) {
    super(options);
    this.identifier = "xero";
    this.name = "Xero";
    this.shortName = "Xero";
  }

  getDefaultSettings() {
    return {
      salesAccount: null,
      taxOnTaxType: null,
      taxOffTaxType: null
    };
  }

  canSaveSettings(settings) {
    return (settings != null
      && settings.salesAccount != null
      && settings.taxOnTaxType != null
      && settings.taxOffTaxType != null
    );
  }

  get requiresLinkedContact() {
    return false;
  }

  getSettingsComponent() {
    return XeroSettings;
  }

  getInvoiceUrl(invoice) {
    return `https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${invoice.accountingSystemInvoiceId}`;
  }
}


var XeroSettings = CreateReactClass({
  propTypes: {
    data: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  },

  render: function() {
    return <div>
      <div>
        <AccountSelectors
          accountingSystemShortName="Xero"
          data={this.props.data}
          settings={this.props.settings}
          onChange={(settings) => this.props.onChange(settings)}
        />
        <div>
          <Checkbox
            value={this.props.settings.usePhaseCodesAsItemCodes || false}
            onChange={this.handleUsePhaseCodesAsItemCodesChange}
            style={{margin: '1em 0'}}
            label="Use Phase Codes as Xero Item Codes"
          />
        </div>
        <div>
          <Checkbox
            value={this.props.settings.useProjectNameAsReference || false}
            onChange={this.handleUseProjectNameAsReference}
            label="Use Project Name as Xero Invoice Reference"
          />
        </div>
      </div>
    </div>;
  },

  handleUsePhaseCodesAsItemCodesChange: function(value) {
    this.props.onChange({
      ...this.props.settings,
      usePhaseCodesAsItemCodes: value
    });
  },

  handleUseProjectNameAsReference: function(value) {
    this.props.onChange({
      ...this.props.settings,
      useProjectNameAsReference: value
    });
  }
});



