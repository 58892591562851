import _ from 'underscore';
import { generateUUID, sum } from '../utils.js';
import { DataObject } from './dataobject.js';
import { Allocation } from './allocation.js';
import { ProjectPhase } from './projectphase.js';
import { CashFlowItem } from './cashflowitem.js';
import { dateConverter } from './dateconverter.js';



export const Milestone = class extends DataObject {
			constructor(options) {
				super(options);

				if (this.uuid == null) {
					this.uuid = generateUUID();
				}
				this.prevMilestone = null;
				this.nextMilestone = null;
			}

			static transformArgs(objectData, organisation) {
				let args = super.transformArgs(objectData, organisation);
				args.allocation = new Allocation(
					objectData.allocation
				).mapHours(function(hours) {
					// Defensive: there shouldn't be null values in this dict but if there are,
					// turn them into zeros.
					if (hours == null) {
						return 0;
					} else {
						return hours;
					}
				});
				return args;
			}

			getProject() {
				return this.phase.project;
			}

			getEndDate() {
				return dateConverter.intToMoment(this.endDate);
			}

			/**
			 * All of these methods are to appease the interface the <Feed> component assumes.
			 */
			getFee() {
				return this.revenue;
			}

			isEditable() {
				return true;
			}

			defaults() {
				return {
					id: null,
					uuid: generateUUID(),
					offsetDays: null,
					endDate: null,
					percent: null,
					allocation: new Allocation(),
					phase: null,
					milestoneIndex: null,
					revenue: null
				};
			}

			static fieldTypes() {
				return {
					id: "int",
					uuid: "string",
					offsetDays: "int",
					percent: "number",
					allocation: "dict",
					phase: ProjectPhase,
					milestoneIndex: "int",
					revenue: "number"
				};
			}

			getTitle() {
				return this.phase.getTitle();
			}

			getPercent() {
				if (this._percent != null && isNaN(this._percent)) {
					return 0;
				} else {
					return this.percent;
				}
			}

			getDisplayPercent() {
				if (this._percent != null && isNaN(this._percent)) {
					return "";
				} else {
					return Math.round(this.percent);
				}
			}

			getStaffCost(store) {
				var cost = 0;
				// I think there is some issue with this.
				_.each(this.getAllocation(), function(hours, staffMemberId) {
					cost +=
						hours * store.getStaffMemberById(staffMemberId).payRate;
				});
				return cost;
			}

			getOffsetDays() {
				return this.endDate - this.phase.startDate;
			}

			setOffsetDays(offsetDays) {
				this.offsetDays = offsetDays;
				this.endDate = this.phase.startDate + offsetDays;
			}

			setEndDate(endDateInt) {
				this.endDate = endDateInt;
				this.offsetDays = endDateInt - this.phase.startDate;
			}

			setPercent(percent) {
				this.percent = percent;
				if (
					this.prevMilestone &&
					this.prevMilestone.percent > percent
				) {
					this.prevMilestone.setPercent(percent);
				}
				if (
					this.nextMilestone &&
					this.nextMilestone.percent < percent
				) {
					this.nextMilestone.setPercent(percent);
				}
			}

			setRevenue(revenue) {
				this.revenue = revenue;
			}

			getRevenueMinusActuals() {
				// we don't want this to become negative cancel out real revenue
				return Math.max(this.revenue - this.getActuals(), 0);
			}

			getActuals() {
				if (this.prevMilestone && this.endDate) {
					return sum(
						this.phase
							.getCashFlowItems(
								this.prevMilestone.getEndDate(),
								this.getEndDate()
							)
							.toJS()
							.map(cli => cli.fee)
					);
				} else if (this.phase.startDate && this.endDate) {
					return sum(
						this.phase
							.getCashFlowItems(
								this.phase.getStartDate(),
								this.getEndDate()
							)
							.map(cli => cli.fee)
					);
				} else {
					return 0;
				}
			}

			updatePercentBasedOnRevenue() {
				this.percent =
					(this.totalRevenueToDate() / this.phase.fee) * 100;
			}

			updateRevenueBasedOnPercent() {
				if (this.prevMilestone) {
					this.revenue =
						((this.percent - this.prevMilestone.percent) / 100) *
						this.phase.fee;
				} else {
					this.revenue = (this.percent / 100) * this.phase.fee;
				}
			}

			distanceToPrevMilestone() {
				if (this.prevMilestone) {
					return this.offsetDays - this.prevMilestone.offsetDays;
				}
			}

			distanceToNextMilestone() {
				if (this.nextMilestone) {
					return this.nextMilestone.offsetDays - this.offsetDays;
				}
			}

			totalRevenueToDate() {
				if (this.prevMilestone) {
					return (
						this.revenue + this.prevMilestone.totalRevenueToDate()
					);
				} else {
					return this.revenue;
				}
			}

			copy({ cloneIdentity = true } = {}) {
				return new Milestone({
					id: cloneIdentity ? this.id : null,
					uuid: cloneIdentity ? this.uuid : null,
					phase: this.phase,
					milestoneIndex: this.milestoneIndex,
					offsetDays: this.offsetDays,
					endDate: this.endDate,
					percent: this.percent,
					allocation: this.allocation.clone(),
					revenue: this.revenue
				});
			}

			clone() {
				return this.copy({ cloneIdentity: false });
			}

			static fieldsForSerialize() {
				return [
					"id",
					"uuid",
					"milestoneIndex",
					"offsetDays",
					"percent",
					"allocation",
					"revenue"
				];
			}

			serialize() {
				return {
					offsetDays: this.endDate - this.phase.startDate,
					percent: this.percent,
					allocation: this.allocation.serialize(),
					uuid: this.uuid,
					revenue: this.revenue
				};
			}

			toCashFlowItem() {
				return new CashFlowItem({
					invoice: null,
					endDate: this.endDate,
					title: this.phase.getTitle(),
					project: this.phase.project,
					phase: this.phase,
					fee: this.revenue,
					isEditable: true,
					percent: this.percent,
					milestone: this
				});
			}
		};



export function getDefaultMilestoneDates(startDate, endDate, {gracePeriodDays = null} = {}) {
  /**
    >>> getDefaultMilestoneDates(moment("2015-11-15"), moment("2015-12-15")).map(d => d.format("YYYY-MM-DD"))
    ["2015-11-30", "2015-12-15"]

    >>> getDefaultMilestoneDates(moment("2015-11-15"), moment("2015-11-30")).map(d => d.format("YYYY-MM-DD"))
    ["2015-11-30"]

    >>> getDefaultMilestoneDates(moment("2015-11-15"), moment("2016-06-30")).map(d => d.format("YYYY-MM-DD"))
    ["2015-11-30", "2015-12-31", "2016-01-31", "2016-02-29", "2016-03-31", "2016-04-30", "2016-05-31", "2016-06-30"]
  */

  var milestoneDates = [];

  var m = startDate.clone().add(1, 'month').startOf('month').subtract(1, 'day');
  var i = 0;
  while (!m.isAfter(endDate)) {
    if (gracePeriodDays == null || i > 0 || m.diff(startDate, 'days') > gracePeriodDays) {
      milestoneDates.push(m);
    }
    m = m.clone().add(1, 'day').add(1, 'month').subtract(1, 'day');
    i++;
  }
  if (milestoneDates.length === 0 || !milestoneDates[milestoneDates.length - 1].isSame(endDate)) {
    milestoneDates.push(endDate.clone());
  }
  return milestoneDates;
}
