import { Properties } from "../Properties";

export default ({ object, prop }, context) => {
	if (object.type !== "array") {
		let val = Properties[object.type]?.(prop, object, context);
		if (!val) {
			throw {
				value: `${object.type} does not have property ${prop}`,
				type: "error",
			};
		}
		return val;
	} else {
		let propArray = [];
		object.value.forEach((ob) => {
			let propVal = Properties[ob.type]?.(prop, ob, context);
			if (propVal && propVal.type === "array") {
				propArray.push(...propVal.value);
			} else if (propVal) {
				propArray.push(propVal);
			}
		});
		if (propArray.length && !propArray[0]) {
			throw {
				value: `${object.value[0].type} does not have property ${prop}`,
				type: "error",
			};
		}
		let dependencies = new Map();
		propArray.forEach((p) =>
			[...(p.dependencies || [])].forEach(([dId, dData]) => {
				const existingDepData = dependencies.get(dId);
				dependencies.set(dId, {
					...(existingDepData || {}),
					...dData,
					inherited:
						existingDepData && !existingDepData.inherited
							? existingDepData.inherited
							: dData.inherited,
				});
			})
		);
		return {
			type: "array",
			value: propArray,
			dependencies: dependencies,
		};
	}
};
