import moment from "moment";
import { getProjectPermissionLevel } from "../models/permissions";
import { organisationStore } from "../organisation";
import { settingsStore } from "../settings/settingsStore";
import { userStore } from "../user";

export function autoAdjustRevenue({ projects, options } = {}) {
	projects = projects || organisationStore.projects;
	options = { ...settingsStore.settings.autoUpdateRevenue, ...options };
	const user = userStore.user;
	let modifiedProjects = [];
	projects.forEach((pr) => {
		if (
			isItemForecastingValues(pr) &&
			(user.isAdmin || getProjectPermissionLevel(pr, user) != null)
		) {
			pr.getVisiblePhases().forEach((ph) => {
				if (!isItemForecastingValues(ph)) return null;
				let fee =
					options.budget === "remaining"
						? ph.remainingRevenue
						: options.budget === "total"
						? ph.fee
						: 0;
				let startDate = phaseRevenueDateMap[options.start](ph);
				let endDate = phaseRevenueDateMap[options.end](ph);
				if (startDate && endDate) {
					if (endDate < startDate) fee = 0;
					ph.syncMilestones(fee, startDate, endDate);
				}
			});
			modifiedProjects.push(pr);
		}
	});
	return modifiedProjects;
}

export function autoAdjustHours({ projects, staff, roles, options } = {}) {
	projects = projects || organisationStore.projects;
	staff = staff || organisationStore.staffMembers;
	roles = roles || organisationStore.staffRoles;
	options = { ...settingsStore.settings.autoUpdateHours, ...options };
	const user = userStore.user;
	let modifiedProjects = [];
	projects.forEach((pr) => {
		if (
			isItemForecastingValues(pr) &&
			(user.isAdmin || getProjectPermissionLevel(pr, user) != null)
		) {
			pr.getVisiblePhases().forEach((ph) => {
				//staff
				const staffWithAllocations = ph
					.getStaffMemberAllocations()
					.filter((sa) => sa.hours)
					.map((sa) => sa.staffMember);
				const staffWithBudgets = ph.staffMemberBudgetedHours
					.filter((sb) => sb.hours)
					.map((sb) => sb.staffMember);
				if (!isItemForecastingValues(ph)) return null;
				staff.forEach((sm) => {
					const hasAllocations = staffWithAllocations.includes(sm);
					const hasBudgets = staffWithBudgets.includes(sm);
					if (!hasAllocations && !hasBudgets) return;
					if (!hasBudgets || sm.isArchived) {
						ph.setStaffAllocation(sm, 0);
					} else {
						let hours =
							options.budget === "remaining"
								? ph.getRemainingStaffHours(sm)
								: options.budget === "total"
								? ph.getBudgetedHoursForStaffMember(sm)
								: 0;
						let startDate = phaseStaffDateMap[options.start](
							ph,
							sm
						);
						let endDate = phaseStaffDateMap[options.end](ph, sm);
						if (endDate < startDate) hours = 0;
						ph.syncStaffMemberAllocations(
							sm,
							hours,
							startDate,
							endDate
						);
					}
				});
				//roles
				const rolesWithAllocations = ph
					.getStaffRoleAllocations()
					.filter((sa) => sa.hours)
					.map((sa) => sa.staffRole);
				const rolesWithBudgets = ph.staffRoleBudgetedHours
					.filter((sb) => sb.hours)
					.map((sb) => sb.staffRole);
				if (!isItemForecastingValues(ph)) return null;
				roles.forEach((sr) => {
					const hasAllocations = rolesWithAllocations.includes(sr);
					const hasBudgets = rolesWithBudgets.includes(sr);
					if (!hasAllocations && !hasBudgets) return;
					if (!hasBudgets || sr.isArchived) {
						ph.setRoleAllocation(sr, 0);
					} else {
						let hours =
							options.budget === "remaining"
								? ph.getRemainingRoleHours(sr)
								: options.budget === "total"
								? ph.getBudgetedHoursForStaffRole(sr)
								: 0;
						let startDate = phaseRoleDateMap[options.start](ph, sr);
						let endDate = phaseRoleDateMap[options.end](ph, sr);
						if (endDate < startDate) hours = 0;
						ph.syncStaffRoleAllocations(
							sr,
							hours,
							startDate,
							endDate
						);
					}
				});
			});
			modifiedProjects.push(pr);
		}
	});
	return modifiedProjects;
}

export function isItemForecastingValues(item) {
	return (
		item.status !== "archived" &&
		item.hasDates &&
		item.getEndDate().isAfter(moment().startOf("month"))
	);
}

export const phaseRevenueDateMap = {
	startDate: (phase) => phase.getStartDate(),
	endDate: (phase) => phase.getEndDate(),
	now: (phase) => phase.remainingRevenueStartDate,
};

export const phaseStaffDateMap = {
	startDate: (phase, staffMember) => phase.getStartDate(),
	endDate: (phase, staffMember) => phase.getEndDate(),
	now: (phase, staffMember) =>
		phase.getRemainingStaffHoursStartDate(staffMember),
};

export const phaseRoleDateMap = {
	startDate: (phase, staffRole) => phase.getStartDate(),
	endDate: (phase, staffRole) => phase.getEndDate(),
	now: (phase, staffRole) => phase.getRemainingRoleHoursStartDate(staffRole),
};
