import _ from "underscore";
import { organisationStore } from "../organisation.js";
import {
	StoreBase,
	dispatcher,
	registerActions,
	handleAction
} from "../coincraftFlux.js";
import { DescriptionTemplate } from "../models/descriptionTemplate.js";
import apiRequest from "../apiRequest";

const descriptionTemplateActionDefinitions = [
	{ action: "selectTemplate", args: ["template"] },
	{ action: "editTemplate", args: [] },
	{ action: "cancelEditTemplate", args: [] },
	{ action: "changeTemplateName", args: ["name"] },
	{ action: "changeTemplateDescription", args: ["description"] },
	{ action: "saveTemplate", args: [] },
	{ action: "saveSuccess", args: ["data"] },
	{ action: "deleteTemplate", args: [] },
	{ action: "deleteSuccess", args: ["data"] },
	{ action: "newTemplate", args: [] }
];

export const actions = registerActions(
	"description-templates",
	descriptionTemplateActionDefinitions,
	dispatcher
);

class DescriptionTemplatesStore {
	constructor() {
		this.path = "description-templates";
		this.actionDefinitions = descriptionTemplateActionDefinitions;

		this.templates = organisationStore
			? organisationStore.descriptionTemplates.sort(dt => dt.name)
			: [];
		this.selectedTemplate = null;
		this.preEditName = null;
		this.preEditDescription = null;
		this.state = "display";
		this.saveState = null;
		this.deleteState = null;
	}

	handle(action) {
		if (action.path === this.path + "/save") {
			this.saveOperation.handle(action);
			if (action.type === "ajax/success") {
				this.saveSuccess(action.data);
			}
		} else {
			handleAction(action, this);
		}
	}

	selectTemplate(template) {
		this.selectedTemplate = template;
	}

	editTemplate() {
		this.state = "edit";
		this.preEditName = this.selectedTemplate.name;
		this.preEditDescription = this.selectedTemplate.description;
	}

	cancelEditTemplate() {
		this.state = "display";
		this.selectedTemplate.name = this.preEditName;
		this.selectedTemplate.description = this.preEditDescription;
		this.saveState = null;
		this.deleteState = null;
		if (!this.selectedTemplate.id) {
			this.selectedTemplate = null;
		}
	}

	changeTemplateName(name) {
		this.selectedTemplate.name = name;
	}

	changeTemplateDescription(description) {
		this.selectedTemplate.description = description;
	}

	saveTemplate() {
		this.saveState = "saving";
		apiRequest({
			path: `/organisation/${organisationStore.id}/description-template/`,
			method: "post",
			data: {
				descriptionTemplate: this.selectedTemplate.serialize()
			},
			success: data => {
				actions.saveSuccess(data);
			}
		});
	}

	saveSuccess(data) {
		if (!this.selectedTemplate.id) {
			this.templates.push(this.selectedTemplate);
		}
		this.selectedTemplate.id = data.descriptionTemplate.id;
		this.state = "display";
		this.saveState = null;
	}

	deleteTemplate() {
		this.deleteState = "deleting";
		apiRequest({
			path: `/organisation/${organisationStore.id}/description-template/${this.selectedTemplate.id}`,
			method: "delete",
			success: data => {
				actions.deleteSuccess(data);
			}
		});
	}

	deleteSuccess(data) {
		this.templates = this.templates.filter(
			t => t.id !== this.selectedTemplate.id
		);
		this.selectedTemplate = null;
		this.state = "display";
		this.deleteState = null;
	}

	newTemplate() {
		const newTemplate = new DescriptionTemplate({
			name: "New Template",
			description: "Template description..."
		});
		this.selectedTemplate = newTemplate;
		this.editTemplate();
	}
}

export var descriptionTemplatesStore = new DescriptionTemplatesStore();
