import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { dateConverter } from '../../../models/dateconverter.js';
import classNames from 'classnames';
import { projectForecastsStore, actions } from '../ProjectForecastsStore.js';
import { makeMultipleStoreMixin } from '../../../coincraftFlux.js';
import { permissions, requiresPermission, OperationLevel, UserLevel } from '../../../models/permissions.js';
import { userStore } from '../../../user.js';
import { ProgressBar } from '../../../newSpreadsheet/ProgressBar.js';


export var TimesheetMenu = CreateReactClass({
    mixins: [
        makeMultipleStoreMixin([projectForecastsStore, userStore], function () {
            return {
                cell: projectForecastsStore.selectedHoursCell,
                user: userStore.user,
                selectedRowId: projectForecastsStore.selectedHoursRowId,
                selectedRow: projectForecastsStore.selectedHoursRow,
                selectedMonthIndex: projectForecastsStore.selectedHoursMonthIndex,
                currentMonthIndex: projectForecastsStore.currentMonthIndex,
                selectedHoursInputText: projectForecastsStore.selectedHoursInputText,
                selectedCostInputText: projectForecastsStore.selectedCostInputText,
                selectedHoursBudgetInputText: projectForecastsStore.selectedHoursBudgetInputText,
                selectedCostBudgetInputText: projectForecastsStore.selectedCostBudgetInputText,
                selectedUtilisationInputText: projectForecastsStore.selectedHoursUtilisationInputText,
            };
        })
    ],

    componentWillMount() {
        this.timelineItems = {}
        this.timeline = null
    },

    componentDidUpdate(prevProps, prevState) {
        const thisMonth = this.state.selectedMonthIndex;
        const timeline = this.timeline
        const timecard = this.timelineItems[thisMonth]
        if (
            (timecard.getBoundingClientRect().top <= (timeline.getBoundingClientRect().top - timecard.offsetHeight*2))
            || (timecard.getBoundingClientRect().bottom >= (timeline.getBoundingClientRect().bottom + timecard.offsetHeight*2))
        ) {
            timecard.scrollIntoView(true);
            timeline.scrollTop -= timeline.offsetHeight / 2 - timecard.offsetHeight / 2;
        } else if (timecard.getBoundingClientRect().top <= (timeline.getBoundingClientRect().top + timecard.offsetHeight / 2)) {
            timecard.scrollIntoView(true);
            timeline.scrollTop -= timecard.offsetHeight / 2;
        } else if (timecard.getBoundingClientRect().bottom >= (timeline.getBoundingClientRect().bottom - timecard.offsetHeight / 2)) {
            timecard.scrollIntoView(false);
            timeline.scrollTop += timecard.offsetHeight / 2;
        }
    },

    render() {
        const { 
            selectedRow, 
            selectedMonthIndex, 
            currentMonthIndex, 
            selectedRowId, 
            selectedHoursInputText,
            selectedCostInputText,
            selectedHoursBudgetInputText,
            selectedCostBudgetInputText,
            selectedUtilisationInputText,
        } = this.state
        const { viewFinancials, viewExpenses } = this.props
        return (
            <div className="spreadsheet-history-menu">
                <div className="spreadsheet-history-menu__header" style={{ borderBottom: '1px solid #ccc' }}>
                    <div className="title">{selectedRow.title}</div>
                </div>
                <div className="spreadsheet-history-menu__timeline" ref={el => this.timeline = el}>
                    {selectedRow.monthIndexArray.reverse().map(monthIndex => {
                        const selected = monthIndex === selectedMonthIndex
                        const past = monthIndex < currentMonthIndex
                        const editable = selected && !past && this.props.isEditable
                        return <TimeCard
                            key={monthIndex}
                            selected={selected}
                            past={past}
                            onClick={!selected ? () => actions.selectHoursMonthIndex(monthIndex) : undefined}
                            refFunc={el => this.timelineItems[monthIndex] = el}
                        >
                            {viewExpenses && <div>
                                <TimeCardTitle
                                    monthIndex={monthIndex}
                                    label={"Cost"}
                                    inputStyle={{ width: "5em" }}
                                    editable={editable}
                                    value={Math.round(selectedRow.getDisplayedCostMonthIndex(monthIndex))}
                                    onChangeValue={e => {
                                        actions.setSelectedHoursCellCost(e.target.value)
                                        this.props.onChange()
                                    }}
                                    inputText={selectedCostInputText}
                                />
                                <TimeCardProgress
                                    title={"Cost Budget Use"}
                                    value={Math.round(selectedRow.getDisplayedCostMonthIndex(monthIndex))}
                                    percent={Math.round(selectedRow.getDisplayedCostBudgetUseMonthIndex(monthIndex))}
                                    prevPercent={Math.round(selectedRow.getDisplayedCostBudgetUseMonthIndex(monthIndex - 1))}
                                    editable={editable}
                                    onChangePercent={e => {
                                        actions.setSelectedHoursCellCostBudget(e.target.value)
                                        this.props.onChange()
                                    }}
                                    inputText={selectedCostBudgetInputText}
                                />
                            </div>}
                            <TimeCardTitle
                                monthIndex={viewExpenses ? undefined : monthIndex}
                                style={viewExpenses ? {marginTop: "2em"} : {}}
                                label={"Hours"}
                                editable={editable}
                                value={Math.round(selectedRow.getDisplayedHoursMonthIndex(monthIndex))}
                                onChangeValue={e => {
                                    actions.setSelectedHoursCellHours(e.target.value)
                                    this.props.onChange()
                                }}
                                inputText={selectedHoursInputText}
                            />
                            <TimeCardProgress
                                title={"Hours Budget Use"}
                                value={Math.round(selectedRow.getDisplayedHoursMonthIndex(monthIndex))}
                                percent={Math.round(selectedRow.getDisplayedBudgetUseMonthIndex(monthIndex))}
                                prevPercent={Math.round(selectedRow.getDisplayedBudgetUseMonthIndex(monthIndex - 1))}
                                editable={editable}
                                onChangePercent={e => actions.setSelectedHoursCellHoursBudget(e.target.value)}
                                inputText={selectedHoursBudgetInputText}
                            />
                            <TimeCardProgress
                                title={"Utilisation"}
                                value={Math.round(selectedRow.getDisplayedHoursMonthIndex(monthIndex))}
                                percent={Math.round(selectedRow.getDisplayedPercentUtilisationMonthIndex(monthIndex))}
                                prevPercent={0}
                                editable={editable}
                                onChangePercent={e => {
                                    actions.setSelectedHoursCellUtilisation(e.target.value)
                                    this.props.onChange()
                                }}
                                inputText={selectedUtilisationInputText}
                            />
                            
                        </TimeCard>
                    })}
                </div>
            </div>
        );
    },
});

var TimeCard = CreateReactClass({
    render: function () {
        const { selected, past, children, onClick } = this.props
        return <div
            ref={this.props.refFunc}
            className={classNames('spreadsheet-history-menu__timecard', { 'selected': selected }, { 'past': past })}
            onClick={onClick}
            style={onClick ? {cusor: 'pointer'} : {}}
        >
            {children}
        </div>;
    }
});

var TimeCardTitle = CreateReactClass({
    render: function () {
        const { monthIndex, editable, value, onChangeValue, inputText, inputStyle, style, label } = this.props
        return <div className="date-title" style={style}>
            <div className="date">{monthIndex ? dateConverter.monthIndexToMoment(monthIndex).format('MMM YY') : ""}</div>
            <div className="hours">{editable
                ? <span>{label}: <input style={inputStyle} type="text" value={inputText || value} onChange={onChangeValue} /></span>
                : <span>{label}: {value}</span>
            }</div>
        </div>;
    }
});

var TimeCardProgress = CreateReactClass({
    render: function () {
        const { title, percent, editable, prevPercent, onChangePercent, inputText } = this.props
        return <div className="timecard-progress">
            <div className="timecard-text">
                <div className="title-val">
                    <div className="title">{title}</div>
                </div>
                <div className="percent">
                    {editable
                        ? <span><input type="text" value={inputText || percent} onChange={onChangePercent} />%</span>
                        : <span>{`${percent}%`}</span>
                    }
                </div>
            </div>
            <ProgressBar className="progress-bar" fromPercentage={prevPercent} toPercentage={percent} height="0.4em" />
        </div>;
    }
});