import * as HUSL from 'husl';


//copied from http://refactorman.com/2015/08/08/better-color-gradients-with-husl/
function linearTween(start, stop) {
  return function tween(i) { return (stop - start) * i + start; };
};

function degreesToRadians(d) {
  return d * Math.PI / 180;
}

function radiansToDegrees(r) {
  return r * 180 / Math.PI;
}

function circularTween(start, stop) {
  start = degreesToRadians(start);
  stop = degreesToRadians(stop);
  let delta = Math.atan2(Math.sin(stop - start), Math.cos(stop - start));
  return function tween(i) {
    return (radiansToDegrees(start + delta * i) + 360) % 360;
  };
}


let bgStartColor = HUSL.fromHex("#fdf3d2");
let bgMidColor = HUSL.fromHex("#ffc800");
let bgEndColor = HUSL.fromHex("#f15a29");

let bgHue1 = circularTween(bgStartColor[0], bgMidColor[0]);
let bgSaturation1 = linearTween(bgStartColor[1], bgMidColor[1]);
let bgLightness1 = linearTween(bgStartColor[2], bgMidColor[2]);

let bgHue2 = circularTween(bgMidColor[0], bgEndColor[0]);
let bgSaturation2 = linearTween(bgMidColor[1], bgEndColor[1]);
let bgLightness2 = linearTween(bgMidColor[2], bgEndColor[2]);


export function getGradient(val) {
  /**
   * Returns styles to render a cell with value `val` (from 0 to 1) where 0 is
   * white and 1 is bright red.
   */
  if (val > 1) {
    val = 1;
  }
  if (val < 0) {
    val = 0;
  }
  return {
    color: val === 1 ? '#ffffff' : 'inherit',
    backgroundColor: (val > 0.8) ?
        HUSL.toHex(bgHue2((val - 0.8) / 0.2), bgSaturation2((val - 0.8) / 0.2), bgLightness2((val - 0.8) / 0.2))
      : (val >= 0) ?
        HUSL.toHex(bgHue1(val / 0.8), bgSaturation1(val / 0.8), bgLightness1(val / 0.8))
      : null
  };
}
