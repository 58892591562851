import React from 'react';
import CreateReactClass from 'create-react-class';
import { Checkbox, SmallDeleteButton } from '../widgets/generic.js';

import PropTypes from "prop-types";

export var ProjectPhaseTasks = CreateReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
  },

  componentDidUpdate: function() {
    if (this.refs.lastInput != null) {
      this.refs.lastInput.focus();
    }
  },

  render: function() {
    let self = this;

    let hasJustAddedTask = this.props.phase.getAndResetHasJustAddedTask();
    let taskCount = this.props.phase.getVisibleTasks().count();

    return (
      <div style={{margin: '0 0 4em 0'}}>
        <div style={{margin: '1em 0 2em 0'}}>
          {!this.props.phase.getVisibleTasks().isEmpty() ?
            <div>
              {this.props.phase.getVisibleTasks().map(function(task, taskIndex) {
                return (
                  <div
                      style={{padding: '0.5em', borderBottom: 'solid 1px #ddd'}}
                      className="flexbox-container flex-align-items-center flex-space-between"
                      key={task.uuid}>
                    <div>
                      {self.props.isEditable && !task.isDefault ?
                        <input
                          type="text"
                          placeholder="task name"
                          ref={hasJustAddedTask && taskIndex === taskCount - 1 ? "lastInput" : null}
                          value={task.name}
                          onChange={(event) => self.props.actions.setTaskText(task.uuid, event.target.value)}
                          style={{width: '18em'}}
                        />
                      :
                        <div style={{display: 'inline-block', width: '18em'}} >{task.name}</div>
                      }
                      <div style={{display: 'inline-block', marginLeft: '1em'}}>
                        <Checkbox
                          className="staff-member-name"
                          value={task.isBillable}
                          label="Billable"
                          disabled={!self.props.isEditable}
                          onChange={(checked) => self.props.actions.setTaskIsBillable(task.uuid, checked)}
                        />
                      </div>
                      <div style={{display: 'inline-block', marginLeft: '1em'}}>
                        <Checkbox
                          className="staff-member-name"
                          value={task.isVariation}
                          label="Variation"
                          disabled={!self.props.isEditable}
                          onChange={(checked) => self.props.actions.setTaskIsVariation(task.uuid, checked)}
                        />
                      </div>
                    </div>
                    <SmallDeleteButton
                      onClick={function() { self.handleDeleteTaskButtonClick(task.uuid); }}
                      disabled={!self.props.isEditable || task.isDefault}
                    />
                  </div>
                );
              })}
            </div>
          : null}

          {self.props.isEditable ?
            <div style={{padding: '0.5em'}}>
              <button
                  style={{padding: '0.5em 1.5em', fontSize: '0.85em', marginTop: '0.5em'}}
                  className="btn btn-default"
                  onClick={this.handleAddTaskButtonClick}>
                + Add Task
              </button>
            </div>
          : null}
        </div>
      </div>
    );
  },

  handleAddTaskButtonClick: function() {
    this.props.actions.addTask();
  },

  handleDeleteTaskButtonClick: function(taskUuid) {
    this.props.actions.deleteTask(taskUuid);
  }
});
