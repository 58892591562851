import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';
import { organisationStore } from '../organisation.js';
import { dateConverter } from './dateconverter.js';
import moment from 'moment';

export const ProjectEvent = class extends DataObject {
  /**
   * ProjectExpense objects aren't real objects in the database,
   * they are just dicts in the `Project.expenses` list.
   */
  defaults() {
    return {
      uuid: generateUUID(),
      time: null,
      userId: null,
      phaseId: null,
      field: null,
      fromValue: null,
      toValue: null,
    };
  }

  static fieldTypes() {
    return {
      uuid: 'string',
      time: 'string', // seems there's a bug w/ serialising dates
      userId: 'string',
      phaseId: 'string',
      field: 'string',
      fromValue: 'string',
      toValue: 'string',
    };
  }

  static fieldsForSerialize() {
    return ['uuid', 'time', 'userId', 'phaseId', 'field', 'fromValue', 'toValue'];
  }

  get staffMember() {
    return organisationStore.getStaffMemberById(this.userId)
  }

  get staffName() {
    return this.staffMember.getFullName()
  }

  get phase() {
    return organisationStore.getProjectPhaseById(this.phaseId)
  }

  get phaseTitle() {
    return this.phase ? this.phase.getTitle() : '(No Phase)'
  }

  get moment() {
    return moment(this.time, "YYYY-MM-DD hh:mm:ss A")
  }

  get dateInt() {
    return dateConverter.momentToInt(this.moment)
  }


}
