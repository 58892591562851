import _ from "underscore";
import { organisationStore } from "../../../organisation.js";
import { ProjectExpense } from "../../../models/projectexpense.js";
import { dateConverter } from "../../../models/dateconverter.js";
import { sum, formatCurrency, compareMultiple } from "../../../utils.js";
import { ProjectExpenseAllocation } from "../../../models/projectexpenseallocation.js";

const displayTypes = {
	0: "sub-total",
	1: "",
	2: "child",
};

const expandable = {
	0: false,
	1: true,
	2: false,
};

const hideable = {
	0: false,
	1: true,
	2: true,
};

const defaultExpanded = {
	0: true,
	1: false,
	2: false,
};

export const ExpenseRow = class {
	constructor(spreadsheetStore, rowData) {
		this.uuid = rowData.uuid;
		this.cfis = {};
		this.parentId = rowData.parentId;
		this.childrenIds = new Set(rowData.childrenIds);
		this.visible = true;
		this.level = rowData.level;
		this.title = rowData.title || "";
		this.spreadsheetStore = spreadsheetStore;
		this.groupType = rowData.groupType;
		this.rowType = rowData.rowType;
		this.editable = rowData.editable === false ? false : true;
		this.project =
			rowData.project ||
			organisationStore.getProjectById(rowData.projectId);
		this.phase =
			rowData.phase ||
			organisationStore.getProjectPhaseById(rowData.phaseId);

		this.expenses = [];

		// cached expenses
		this._projectedExpenseCache = rowData.projectedExpense || {};
		this._actualExpenseCache = rowData.actualExpense || {};
		this._combinedExpenseCache = rowData.combinedExpense || {};
		this._projectedExpenseToDateCache =
			rowData.projectedExpenseToDate || {};
		this._actualExpenseToDateCache = rowData.actualExpenseToDate || {};
		this._combinedExpenseToDateCache = rowData.combinedExpenseToDate || {};
		this._projectedRevenueCache = {};
		this._actualRevenueCache = {};
		this._combinedRevenueCache = {};
		this._projectedRevenueToDateCache = {};
		this._actualRevenueToDateCache = {};
		this._combinedRevenueToDateCache = {};
		this._actualMinMonthIndex =
			rowData.actualMinMonthIndex || spreadsheetStore.currentMonthIndex;
		this._actualMaxMonthIndex =
			rowData.actualMaxMonthIndex || spreadsheetStore.currentMonthIndex;
		this._projectedMinMonthIndex =
			rowData.projectedMinMonthIndex ||
			spreadsheetStore.currentMonthIndex;
		this._projectedMaxMonthIndex =
			rowData.projectedMaxMonthIndex ||
			spreadsheetStore.currentMonthIndex;
		this._combinedMinMonthIndex =
			rowData.combinedMinMonthIndex || spreadsheetStore.currentMonthIndex;
		this._combinedMaxMonthIndex =
			rowData.combinedMaxMonthIndex || spreadsheetStore.currentMonthIndex;
	}

	get addExpenseButton() {
		return this.rowType === "phase";
	}

	get parent() {
		return this.spreadsheetStore.expenseRows.get(this.parentId);
	}

	get children() {
		return [...this.childrenIds]
			.map((cId) => this.spreadsheetStore.expenseRows.get(cId))
			.sort(
				compareMultiple(
					(a, b) =>
						(a.rowType === "phase" && a.phase && a.phase.startDate
							? a.phase.startDate
							: Infinity) -
						(b.rowType === "phase" && b.phase && b.phase.startDate
							? b.phase.startDate
							: Infinity),
					(a, b) => a.title.localeCompare(b.title)
				)
			);
	}

	get isDisplayed() {
		return true;
	}

	get rowDisplayType() {
		return displayTypes[this.level];
	}

	get expandable() {
		return expandable[this.level];
	}

	get hideable() {
		return hideable[this.level];
	}

	get expanded() {
		return (
			defaultExpanded[this.level] ||
			this.spreadsheetStore.expandedExpenses.includes(this.uuid)
		);
	}

	get selected() {
		return this.spreadsheetStore.selectedExpenseRowId === this.uuid;
	}

	get minMonthIndex() {
		return this._combinedMinMonthIndex;
	}

	get maxMonthIndex() {
		return this._combinedMaxMonthIndex;
	}

	get isBillable() {
		return _.some(this.expenses, (e) => e.isInvoiceable);
	}

	setBillability(isBillable) {
		this.expenses.forEach((e) => (e.isInvoiceable = isBillable));
		this.clearRevenueCache();
	}

	clearRevenueCache() {
		this._projectedRevenueCache = {};
		this._actualRevenueCache = {};
		this._combinedRevenueCache = {};
		this._projectedRevenueToDateCache = {};
		this._actualRevenueToDateCache = {};
		this._combinedRevenueToDateCache = {};
		if (this.parent) this.parent.clearRevenueCache();
	}

	toggleVisibility() {
		this.visible = !this.visible;
		_.range(this.minMonthIndex, this.maxMonthIndex + 1).forEach((mi) => {
			let multiplyer = this.visible ? 1 : -1;
			this.parent.addActualExpense(
				this.getActualExpenseMonthIndex(mi) * multiplyer,
				mi
			);
			this.parent.addProjectedExpense(
				this.getProjectedExpenseMonthIndex(mi) * multiplyer,
				mi
			);
			this.parent.addCombinedExpense(
				this.getCombinedExpenseMonthIndex(mi) * multiplyer,
				mi
			);
		});
	}

	addCfi(cfi) {
		const { currentMonthIndex } = this.spreadsheetStore;
		this.cfis[cfi.monthIndex] = this.cfis[cfi.monthIndex] || [];
		this.cfis[cfi.monthIndex].push(cfi);
		if (cfi.expense) this.expenses.push(cfi.expense);
		this.addActualExpense(cfi.spend, cfi.monthIndex);
		this.addProjectedExpense(cfi.spend, cfi.monthIndex);
	}
	addProjectedExpense(expense, monthIndex) {
		const { currentMonthIndex } = this.spreadsheetStore;
		if (expense == undefined) return false;
		if (monthIndex > this._projectedMaxMonthIndex)
			this._projectedMaxMonthIndex = monthIndex;
		if (monthIndex < this._projectedMinMonthIndex)
			this._projectedMinMonthIndex = monthIndex;
		this._projectedExpenseCache[monthIndex] =
			this._projectedExpenseCache[monthIndex] || 0;
		this._projectedExpenseCache[monthIndex] += expense;
		_.range(monthIndex, this._projectedMaxMonthIndex + 1).forEach(
			(mi, i) => {
				if (
					i == 0 ||
					this._projectedExpenseToDateCache[mi] != undefined
				) {
					this._projectedExpenseToDateCache[mi] ??= 0;
					this._projectedExpenseToDateCache[mi] += expense;
				} else {
					this._projectedExpenseToDateCache[mi] =
						this.getProjectedExpenseToDateMonthIndex(mi);
				}
			}
		);
		if (this.parent) this.parent.addProjectedExpense(expense, monthIndex);
		if (this.childrenIds.size === 0) {
			if (
				monthIndex > currentMonthIndex ||
				(monthIndex == currentMonthIndex &&
					(this._projectedExpenseCache[monthIndex] || 0) >=
						(this._actualExpenseCache[monthIndex] || 0))
			) {
				const changeInExpense =
					(this._projectedExpenseCache[monthIndex] || 0) -
					(this._combinedExpenseCache[monthIndex] || 0);
				this.addCombinedExpense(changeInExpense, monthIndex);
			}
		}
		this._projectedRevenueCache = {};
		this._actualRevenueCache = {};
		this._combinedRevenueCache = {};
		this._projectedRevenueToDateCache = {};
		this._actualRevenueToDateCache = {};
		this._combinedRevenueToDateCache = {};
	}
	addActualExpense(expense, monthIndex) {
		const { currentMonthIndex } = this.spreadsheetStore;
		if (expense == undefined) return false;
		if (monthIndex > this._actualMaxMonthIndex)
			this._actualMaxMonthIndex = monthIndex;
		if (monthIndex < this._actualMinMonthIndex)
			this._actualMinMonthIndex = monthIndex;
		this._actualExpenseCache[monthIndex] =
			this._actualExpenseCache[monthIndex] || 0;
		this._actualExpenseCache[monthIndex] += expense;
		_.range(monthIndex, this._actualMaxMonthIndex + 1).forEach((mi, i) => {
			if (i == 0 || this._actualExpenseToDateCache[mi] != undefined) {
				this._actualExpenseToDateCache[mi] ??= 0;
				this._actualExpenseToDateCache[mi] += expense;
			} else {
				this._actualExpenseToDateCache[mi] =
					this.getActualExpenseToDateMonthIndex(mi);
			}
		});
		if (this.parent) this.parent.addActualExpense(expense, monthIndex);
		if (this.childrenIds.size === 0) {
			if (
				monthIndex < currentMonthIndex ||
				(monthIndex == currentMonthIndex &&
					(this._actualExpenseCache[monthIndex] || 0) >=
						(this._projectedExpenseCache[monthIndex] || 0))
			) {
				const changeInExpense =
					(this._actualExpenseCache[monthIndex] || 0) -
					(this._combinedExpenseCache[monthIndex] || 0);
				this.addCombinedExpense(changeInExpense, monthIndex);
			}
		}
		this._projectedRevenueCache = {};
		this._actualRevenueCache = {};
		this._combinedRevenueCache = {};
		this._projectedRevenueToDateCache = {};
		this._actualRevenueToDateCache = {};
		this._combinedRevenueToDateCache = {};
	}
	addCombinedExpense(expense, monthIndex) {
		if (expense == undefined) return true;
		if (monthIndex > this._combinedMaxMonthIndex)
			this._combinedMaxMonthIndex = monthIndex;
		if (monthIndex < this._combinedMinMonthIndex)
			this._combinedMinMonthIndex = monthIndex;
		this._combinedExpenseCache[monthIndex] =
			this._combinedExpenseCache[monthIndex] || 0;
		this._combinedExpenseCache[monthIndex] += expense;

		_.range(monthIndex, this._combinedMaxMonthIndex + 1).forEach(
			(mi, i) => {
				if (i === 0 || this._combinedExpenseToDateCache[mi]) {
					this._combinedExpenseToDateCache[mi] ??= 0;
					this._combinedExpenseToDateCache[mi] += expense;
				} else {
					this._combinedExpenseToDateCache[mi] =
						this.getCombinedExpenseToDateMonthIndex(mi);
				}
			}
		);
		if (this.parent) this.parent.addCombinedExpense(expense, monthIndex);
	}

	getProjectedExpenseMonthIndex(monthIndex) {
		return this._projectedExpenseCache[monthIndex] || 0;
	}

	getActualExpenseMonthIndex(monthIndex) {
		return this._actualExpenseCache[monthIndex] || 0;
	}

	getCombinedExpenseMonthIndex(monthIndex) {
		return this._combinedExpenseCache[monthIndex] || 0;
	}

	getDisplayedExpenseMonthIndex(monthIndex) {
		const { dataType } = this.spreadsheetStore;
		switch (dataType) {
			case "projected":
				return this.getProjectedExpenseMonthIndex(monthIndex);
			case "actuals":
				return this.getActualExpenseMonthIndex(monthIndex);
			default:
				// actualsProjected
				return this.getCombinedExpenseMonthIndex(monthIndex);
		}
	}

	getProjectedExpenseToDateMonthIndex(monthIndex) {
		if (
			!this._projectedMinMonthIndex ||
			monthIndex < this._projectedMinMonthIndex
		) {
			return 0;
		} else if (monthIndex > this._projectedMaxMonthIndex) {
			return this.getProjectedExpenseToDateMonthIndex(
				this._projectedMaxMonthIndex
			);
		} else {
			return (
				this._projectedExpenseToDateCache[monthIndex] ||
				this.getProjectedExpenseToDateMonthIndex(monthIndex - 1)
			);
		}
	}

	getActualExpenseToDateMonthIndex(monthIndex) {
		if (
			!this._actualMinMonthIndex ||
			monthIndex < this._actualMinMonthIndex
		) {
			return 0;
		} else if (monthIndex > this._actualMaxMonthIndex) {
			return this.getActualExpenseToDateMonthIndex(
				this._actualMaxMonthIndex
			);
		} else {
			return (
				this._actualExpenseToDateCache[monthIndex] ||
				this.getActualExpenseToDateMonthIndex(monthIndex - 1)
			);
		}
	}

	getCombinedExpenseToDateMonthIndex(monthIndex) {
		if (
			!this._combinedMinMonthIndex ||
			monthIndex < this._combinedMinMonthIndex
		) {
			return 0;
		} else if (monthIndex > this._combinedMaxMonthIndex) {
			return this.getCombinedExpenseToDateMonthIndex(
				this._combinedMaxMonthIndex
			);
		} else {
			return (
				this._combinedExpenseToDateCache[monthIndex] ||
				this.getCombinedExpenseToDateMonthIndex(monthIndex - 1)
			);
		}
	}

	getDisplayedExpenseToDateMonthIndex(monthIndex) {
		const { dataType } = this.spreadsheetStore;
		switch (dataType) {
			case "projected":
				return this.getProjectedExpenseToDateMonthIndex(monthIndex);
			case "actuals":
				return this.getActualExpenseToDateMonthIndex(monthIndex);
			default:
				// actualsProjected
				return this.getCombinedExpenseToDateMonthIndex(monthIndex);
		}
	}

	getProjectedRevenueMonthIndex(monthIndex) {
		if (this._projectedRevenueCache[monthIndex] != undefined)
			return this._projectedRevenueCache[monthIndex];
		if (this.children) {
			this._projectedRevenueCache[monthIndex] = sum(
				this.children
					.filter((c) => c.isBillable)
					.map((c) => c.getProjectedRevenueMonthIndex(monthIndex))
			);
		} else {
			if (this.isBillable) {
				this._projectedRevenueCache[monthIndex] =
					this.getProjectedExpenseMonthIndex(monthIndex);
			} else {
				this._projectedRevenueCache[monthIndex] = 0;
			}
		}
		return this._projectedRevenueCache[monthIndex];
	}

	getActualRevenueMonthIndex(monthIndex) {
		if (this._actualRevenueCache[monthIndex] != undefined)
			return this._actualRevenueCache[monthIndex];
		if (this.children) {
			this._actualRevenueCache[monthIndex] = sum(
				this.children
					.filter((c) => c.isBillable)
					.map((c) => c.getActualRevenueMonthIndex(monthIndex))
			);
		} else {
			if (this.isBillable) {
				this._actualRevenueCache[monthIndex] =
					this.getActualExpenseMonthIndex(monthIndex);
			} else {
				this._actualRevenueCache[monthIndex] = 0;
			}
		}
		return this._actualRevenueCache[monthIndex];
	}

	getCombinedRevenueMonthIndex(monthIndex) {
		if (this._combinedRevenueCache[monthIndex] != undefined)
			return this._combinedRevenueCache[monthIndex];
		if (this.children.length > 0) {
			this._combinedRevenueCache[monthIndex] = sum(
				this.children.map((c) =>
					c.getCombinedRevenueMonthIndex(monthIndex)
				)
			);
		} else {
			if (this.isBillable) {
				this._combinedRevenueCache[monthIndex] =
					this.getCombinedExpenseMonthIndex(monthIndex);
			} else {
				this._combinedRevenueCache[monthIndex] = 0;
			}
		}
		return this._combinedRevenueCache[monthIndex];
	}

	getDisplayedRevenueMonthIndex(monthIndex) {
		const { dataType } = this.spreadsheetStore;
		switch (dataType) {
			case "projected":
				return this.getProjectedRevenueMonthIndex(monthIndex);
			case "actuals":
				return this.getActualRevenueMonthIndex(monthIndex);
			default:
				// actualsProjected
				return this.getCombinedRevenueMonthIndex(monthIndex);
		}
	}

	getProjectedRevenueToDateMonthIndex(monthIndex) {
		if (this._projectedRevenueToDateCache[monthIndex] != undefined)
			return this._projectedRevenueToDateCache[monthIndex];
		if (this.children) {
			this._projectedRevenueToDateCache[monthIndex] = sum(
				this.children
					.filter((c) => c.isBillable)
					.map((c) =>
						c.getProjectedRevenueToDateMonthIndex(monthIndex)
					)
			);
		} else {
			if (this.isBillable) {
				this._projectedRevenueToDateCache[monthIndex] =
					this.getProjectedExpenseToDateMonthIndex(monthIndex);
			} else {
				this._projectedRevenueToDateCache[monthIndex] = 0;
			}
		}
		return this._projectedRevenueToDateCache[monthIndex];
	}

	getActualRevenueToDateMonthIndex(monthIndex) {
		if (this._actualRevenueToDateCache[monthIndex] != undefined)
			return this._actualRevenueToDateCache[monthIndex];
		if (this.children) {
			this._actualRevenueToDateCache[monthIndex] = sum(
				this.children
					.filter((c) => c.isBillable)
					.map((c) => c.getActualRevenueToDateMonthIndex(monthIndex))
			);
		} else {
			if (this.isBillable) {
				this._actualRevenueToDateCache[monthIndex] =
					this.getActualExpenseToDateMonthIndex(monthIndex);
			} else {
				this._actualRevenueToDateCache[monthIndex] = 0;
			}
		}
		return this._actualRevenueToDateCache[monthIndex];
	}

	getCombinedRevenueToDateMonthIndex(monthIndex) {
		if (this._combinedRevenueToDateCache[monthIndex] != undefined)
			return this._combinedRevenueToDateCache[monthIndex];
		if (this.children.length > 0) {
			this._combinedRevenueToDateCache[monthIndex] = sum(
				this.children.map((c) =>
					c.getCombinedRevenueToDateMonthIndex(monthIndex)
				)
			);
		} else {
			if (this.isBillable) {
				this._combinedRevenueToDateCache[monthIndex] =
					this.getCombinedExpenseToDateMonthIndex(monthIndex);
			} else {
				this._combinedRevenueToDateCache[monthIndex] = 0;
			}
		}
		return this._combinedRevenueToDateCache[monthIndex];
	}

	getDisplayedRevenueToDateMonthIndex(monthIndex) {
		const { dataType } = this.spreadsheetStore;
		switch (dataType) {
			case "projected":
				return this.getProjectedRevenueToDateMonthIndex(monthIndex);
			case "actuals":
				return this.getActualRevenueToDateMonthIndex(monthIndex);
			default:
				// actualsProjected
				return this.getCombinedRevenueToDateMonthIndex(monthIndex);
		}
	}

	setExpenseMonthIndex(monthIndex, newExpense) {
		const oldProjectedExpense =
			this.getProjectedExpenseMonthIndex(monthIndex);
		const oldDisplayedExpense =
			this.getDisplayedExpenseMonthIndex(monthIndex);
		if (!this.editable || newExpense === oldProjectedExpense) return true;
		if (this.children.length > 0) {
			const expenseRatio = oldDisplayedExpense
				? newExpense / oldDisplayedExpense
				: newExpense / this.children.length;
			this.children.forEach((childRow) => {
				const oldChildExpense =
					childRow.getDisplayedExpenseMonthIndex(monthIndex);
				childRow.setExpenseMonthIndex(
					monthIndex,
					oldDisplayedExpense
						? oldChildExpense * expenseRatio
						: expenseRatio
				);
			});
		} else {
			const expense = newExpense - oldProjectedExpense;
			this.addProjectedExpense(expense, monthIndex);
			this.addActualExpense(expense, monthIndex);
			const startDate = dateConverter.monthIndexToOffset(monthIndex);
			const endDate = dateConverter.endOfMonthOffset(startDate);
			if (this.cfis[monthIndex] && this.cfis[monthIndex].length > 0) {
				this.cfis[monthIndex].forEach((cfi) => {
					const expenseRatio = newExpense / oldProjectedExpense;
					if (cfi.expense) {
						cfi.expense.amount *= expenseRatio;
						this.project.mergeChangeLog();
					} else if (cfi.changeLogItem) {
						const index = this.project.changeLog.indexOf(
							cfi.changeLogItem
						);
						const newExpense =
							cfi.changeLogItem.expenses * expenseRatio;
						this.project.changeLog =
							this.project.changeLog.updateIn([index], (item) =>
								item.set({ ["expenses"]: newExpense })
							);
						this.project.mergeChangeLog();
					}
				});
				this.project.changeLogEdited = true;
			} else {
				let newExpenseObjs = this.project.expenses.map((e) => e.copy());
				let newExpenseItem = newExpenseObjs.find(
					(e) => e.name === this.title
				);
				let newAllocation = new ProjectExpenseAllocation({
					date: dateConverter.monthIndexToMoment(monthIndex),
					amount: newExpense,
					projectId: this.project.id,
					phaseId: this.phase ? this.phase.id : -1,
				});
				if (!newExpenseItem) {
					newExpenseItem = new ProjectExpense({
						name: "Untitled",
						projectId: this.project.id,
						phaseId: this.phase ? this.phase.id : -1,
						phaseUuid: this.phase ? this.phase.uuid : "",
						startDate: dateConverter.monthIndexToMoment(monthIndex),
						endDate: dateConverter.monthIndexToMoment(monthIndex),
						cost: newExpense,
					});
					newExpenseObjs.push(newExpenseItem);
				} else {
					newAllocation.expenseUuid = newExpenseItem.uuid;
					newExpenseItem.allocations = [
						newAllocation,
						...newExpenseItem.allocations,
					];
				}
				this.project.setExpenses(newExpenseObjs);
			}
		}
	}

	get totalProjectedExpense() {
		return this.getDisplayedExpenseToDateMonthIndex(this.maxMonthIndex);
	}

	get totalProjectedRevenue() {
		return this.getDisplayedRevenueToDateMonthIndex(this.maxMonthIndex);
	}

	get monthIndexArray() {
		return _.range(
			Math.min(
				this.minMonthIndex,
				this.spreadsheetStore.selectedExpenseMonthIndex
			),
			Math.max(
				this.maxMonthIndex,
				this.spreadsheetStore.selectedExpenseMonthIndex
			) + 1
		);
	}

	getProjectedBudgetUseMonthIndex(monthIndex) {
		return (
			(this.getProjectedExpenseToDateMonthIndex(monthIndex) /
				this.totalProjectedExpense) *
			100
		);
	}

	getActualBudgetUseMonthIndex(monthIndex) {
		return (
			(this.getActualExpenseToDateMonthIndex(monthIndex) /
				this.totalProjectedExpense) *
			100
		);
	}

	getDisplayedBudgetUseMonthIndex(monthIndex) {
		return (
			(this.getDisplayedExpenseToDateMonthIndex(monthIndex) /
				this.totalProjectedExpense) *
			100
		);
	}

	generateSpreasheetRow(dateColumns, shadow = false) {
		let totalString = `$${formatCurrency(
			Math.round(this.totalProjectedExpense)
		)}`;

		let error = false;
		return {
			rowType: this.rowDisplayType + (shadow ? " shadow" : ""),
			cells: [
				{
					value: this.title,
					uuid: this.uuid,
					cellType: "title",
					isRowHeader: true,
					isEditable: false,
					expandable: this.expandable && this.childrenIds.size > 0,
					hideable: this.hideable,
					expanded: this.expanded,
					visible: this.visible,
					addExpenseButton: this.addExpenseButton,
					row: this,
				},
				{
					value: totalString,
					isRowHeader: true,
					isEditable: false,
					error: error,
					visible: this.visible,
					row: this,
				},
				...dateColumns.map((d) =>
					this.getMonthIndexCell(this, d.monthIndex)
				),
			],
		};
	}

	getMonthIndexCell(row, monthIndex) {
		if (!monthIndex) return null;
		const selectedCell =
			this.selected &&
			this.spreadsheetStore.selectedExpenseMonthIndex === monthIndex;
		const monthInput = selectedCell
			? this.spreadsheetStore.selectedExpenseCellInputText
			: "";
		const monthTotal = Math.round(
			this.getDisplayedExpenseMonthIndex(monthIndex)
		);
		return {
			value: `$${formatCurrency(monthTotal)}`,
			numValue: monthTotal,
			uuid: this.uuid,
			inputText: monthInput,
			monthIndex: monthIndex,
			row: row,
			visible: this.visible,
			inRange:
				this.startMonthIndex &&
				this.endMonthIndex &&
				monthIndex >= this.startMonthIndex &&
				monthIndex <= this.endMonthIndex,
			isProject: true,
			isEditable: this.editable,
			error: false,
		};
	}
};
