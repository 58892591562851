import React, { useState } from "react";
import { Modal, ModalContent } from "../modal";
import { LoadingSpinner } from "../widgets";
import { observer } from "mobx-react";
import { makeObservable, observable, computed, action } from "mobx";
import apiRequest from "../apiRequest";
import { organisationStore } from "../organisation.js";
import { InvoicePhase } from "./InvoicePhase";
import { invoicePageStore } from "./InvoicePageStore";
import { Invoice } from "./flux";

class UpdateInvoiceStore {
	@observable invoice = null;
	@observable status = "idle";
	constructor(invoice) {
		makeObservable(this);
		this.invoice = invoice;
	}

	@action.bound
	initUpdate() {
		this.status = "loading";
		invoicePageStore.save(this.updateData);
	}
	@action.bound
	updateData() {
		apiRequest({
			path: `/organisation/${organisationStore.organisation.id}/invoices/project/${this.invoice.projectId}/new`,
			method: "post",
			data:
				this.invoice.startDate.format("YYYY-MM-DD") &&
				this.invoice.endDate.format("YYYY-MM-DD")
					? {
							startDate:
								this.invoice.startDate.format("YYYY-MM-DD"),
							endDate: this.invoice.endDate.format("YYYY-MM-DD"),
					  }
					: {},
			success: (data) => {
				const oldInvData = this.invoice.serialize();
				const newInvData = data.invoice;
				oldInvData.projectPreviousBilled =
					newInvData.projectPreviousBilled;
				newInvData.phases.forEach((newInvPhase) => {
					const oldInvPhase = oldInvData.phases.filter(
						(ph) => ph.phaseId === newInvPhase.phaseId
					)[0];
					if (oldInvPhase) {
						const existingAgreedFee = this.invoice
							.getIn(
								this.invoice._getPhasePathFromPhaseId(
									newInvPhase.phaseId
								)
							)
							.getPhaseTotal({
								billingType: "agreedFee",
								amount: true,
								tax: false,
							});
						oldInvPhase.lineItems.forEach((oldLi) => {
							if (oldLi.lineItemType === "progress") {
								const newPercent =
									(oldLi.lineTotalExTax /
										newInvPhase.phaseFee) *
									100;
								oldLi.phasePercent = newPercent;
							}
						});
						oldInvPhase.phasePreviousPercent =
							newInvPhase.phasePreviousPercent -
							(existingAgreedFee / newInvPhase.phaseFee) * 100;
						oldInvPhase.phasePreviousBilled =
							newInvPhase.phasePreviousBilled - existingAgreedFee;
						oldInvPhase.phaseToDateBilled =
							newInvPhase.phaseToDateBilled;
						oldInvPhase.phasePercent = newInvPhase.phasePercent;
						oldInvPhase.phaseName = newInvPhase.phaseName;
						oldInvPhase.projectName = newInvPhase.projectName;
						oldInvPhase.phaseCode = newInvPhase.phaseCode;
						oldInvPhase.projectCode = newInvPhase.projectCode;
						oldInvPhase.phaseFee = newInvPhase.phaseFee;
					} else {
						oldInvData.phases.push(newInvPhase);
					}
				});
				let updatedInvoice = Invoice.fromJson(oldInvData);
				updatedInvoice = updatedInvoice.update(
					"phases",
					function (phases) {
						return phases.map((ph) => ph.updateDescriptions());
					}
				);
				invoicePageStore.setInvoice(updatedInvoice);
				this.status = "success";
			},
			error: (data) => {
				this.status = "error";
			},
		});
	}
}

export default observer(({ invoice, closeModal }) => {
	const [store, setStore] = useState(new UpdateInvoiceStore(invoice));
	const getContent = () => {
		switch (store.status) {
			case "idle":
				return (
					<>
						<div style={{ margin: "1.5em", textAlign: "left" }}>
							Updating the invoice data will bring in data from
							any new phases and changes to fees. Line item
							amounts will stay the same, this will affect your
							percentage progress if the fee has changed.
						</div>
						<div style={{ margin: "1.5em", textAlign: "left" }}>
							<button
								className="btn btn-primary"
								onClick={store.initUpdate}
							>
								I would like to update my invoice data.
							</button>
						</div>
					</>
				);
			case "loading":
				return <LoadingSpinner style={{ fontSize: "3em" }} />;
			case "success":
				return <div>You invoice has been successfully updated!</div>;
			case "error":
				return (
					<div>
						There was an error updating your invoice. Please contact
						us to resolve the issue.
					</div>
				);
			default:
				return null;
		}
	};
	return (
		<Modal className="sync-modal">
			<ModalContent header="Updating Invoice Data..." width="48em">
				<div style={{ padding: "2em", textAlign: "center" }}>
					{getContent()}
				</div>
				<div
					style={{
						borderTop: "1px solid #ccc",
						textAlign: "right",
						padding: "1em",
					}}
				>
					<a href="javascript:void(0)" onClick={closeModal}>
						Close
					</a>
				</div>
			</ModalContent>
		</Modal>
	);
});
