import _ from 'underscore';
import { ProjectColumns } from '../project/ProjectsPageStore.js';
import { StaffColumns } from '../staff/flux.js';
import { TimesheetDataCache } from '../reports/TimesheetDataCache.js';


const timesheetDataCache = new TimesheetDataCache();


function getReportMatcher(columnsClass, report) {
  let dataSource = {};
  let columns = new columnsClass(dataSource);

  return new Promise(function(resolve, reject) {
    // We only need timesheet data for the matcher if it includes a *filter*
    // that requires it. Selected columns don't matter.
    if (report != null) {
      if (report.filters.some(f => _.include(columns.getColumnById(f.get('columnId')).requires, 'timesheetCache'))) {
        timesheetDataCache.populateDateRange(report.dateRange).then(function() {
          dataSource.dateRange = report.dateRange;
          dataSource.timesheetDataCache = timesheetDataCache;
          resolve(report.getItemMatcher(columns.columns));
        });
      }
      else {
        resolve(report.getItemMatcher(columns.columns));
      }
    }
    else {
      resolve(() => true);
    }
  });
};


export function getProjectReportMatcher(report) {
  return getReportMatcher(ProjectColumns, report);
}


export function getStaffReportMatcher(report) {
  return getReportMatcher(StaffColumns, report);
}

