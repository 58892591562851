import moment from "moment";
import _ from "underscore";
import { dateConverter } from "../models.js";
import { parseMoment } from "../utils.js";

class DateRange {
	constructor(id) {
		this.id = id;
	}

	getDates(now) {
		/**
		 * Should return [start:moment, end:moment].
		 */
		throw new Error("Not implemented");
	}

	getDatesObject(now) {
		const [start, end] = this.getDates(now);
		return { start, end };
	}

	containsDate(date) {
		const [start, end] = this.getDates(moment());

		if (start == null && end == null) {
			return true;
		}

		if (_.isNumber(date)) {
			date = dateConverter.intToMoment(date);
		}

		return (
			(start == null || !start.isAfter(date)) &&
			(end == null || !end.isBefore(date))
		);
	}

	serialize() {
		const [start, end] = this.getDates(moment());
		function format(d) {
			return d != null ? d.format("YYYY-MM-DD") : null;
		}
		if (this.id) return { id: this.id };
		if (start && end) {
			return {
				id: "custom",
				start: format(start),
				end: format(end),
			};
		}
		return { id: this.id };
	}
}

export const CustomDateRange = class extends DateRange {
	constructor(start = null, end = null) {
		super("custom");
		this.start = start;
		this.end = end;
	}

	getDates() {
		return [this.start, this.end];
	}

	serialize() {
		function format(d) {
			return d != null ? d.format("YYYY-MM-DD") : null;
		}

		return {
			...super.serialize(),
			start: format(this.start),
			end: format(this.end),
		};
	}
};

class AllTimeRange extends DateRange {
	constructor() {
		super("all_time");
	}

	getDates(_) {
		return [null, null];
	}
}

export const dateRangeOptions = [
	{ id: "all_time", label: "all time", create: () => new AllTimeRange() },
	{
		id: "custom",
		label: "custom range",
		create: ({ start, end } = {}) => new CustomDateRange(start, end),
	},
	{ id: "today", label: "today", create: () => new Today() },
	{
		id: "this_week",
		label: "this week",
		create: () => new RegularRange("this", "week"),
	},
	{
		id: "this_fortnight",
		label: "this fortnight",
		create: ({ fortnightType = "a" } = {}) =>
			new FortnightRange("this", fortnightType),
	},
	{
		id: "this_month",
		label: "this month",
		create: () => new RegularRange("this", "month"),
	},
	{
		id: "this_quarter",
		label: "this quarter",
		create: () => new RegularRange("this", "quarter"),
	},
	{
		id: "this_year",
		label: "this year",
		create: () => new RegularRange("this", "year"),
	},
	{
		id: "last_week",
		label: "last week",
		create: () => new RegularRange("last", "week"),
	},
	{
		id: "last_fortnight",
		label: "last fortnight",
		create: ({ fortnightType = "a" } = {}) =>
			new FortnightRange("last", fortnightType),
	},
	{
		id: "last_month",
		label: "last month",
		create: () => new RegularRange("last", "month"),
	},
	{
		id: "last_quarter",
		label: "last quarter",
		create: () => new RegularRange("last", "quarter"),
	},
	{
		id: "last_year",
		label: "last year",
		create: () => new RegularRange("last", "year"),
	},
	{
		id: "next_week",
		label: "next week",
		create: () => new RegularRange("next", "week"),
	},
	{
		id: "next_month",
		label: "next month",
		create: () => new RegularRange("next", "month"),
	},
	{
		id: "next_quarter",
		label: "next quarter",
		create: () => new RegularRange("next", "quarter"),
	},
	{
		id: "next_year",
		label: "next year",
		create: () => new RegularRange("next", "year"),
	},
	{
		id: "inLast_week",
		label: "the week prior to now",
		create: () => new RegularRange("inLast", "week"),
	},
	{
		id: "inLast_month",
		label: "the month prior to now",
		create: () => new RegularRange("inLast", "month"),
	},
	{
		id: "inLast_quarter",
		label: "the quarter prior to now",
		create: () => new RegularRange("inLast", "quarter"),
	},
	{
		id: "inLast_year",
		label: "the year prior to now",
		create: () => new RegularRange("inLast", "year"),
	},
	{
		id: "inNext_week",
		label: "one week from now",
		create: () => new RegularRange("inNext", "week"),
	},
	{
		id: "inNext_month",
		label: "one month from now",
		create: () => new RegularRange("inNext", "month"),
	},
	{
		id: "inNext_quarter",
		label: "one quarter from now",
		create: () => new RegularRange("inNext", "quarter"),
	},
	{
		id: "inNext_year",
		label: "one year from now",
		create: () => new RegularRange("inNext", "year"),
	},
];

export const AllTime = dateRangeOptions[0].create();

class Today extends DateRange {
	constructor() {
		super("today");
	}

	getDates(now) {
		return [now.clone(), now.clone()];
	}
}

class RegularRange extends DateRange {
	constructor(which, unit) {
		super(which + "_" + unit);
		this.which = which;
		this.unit = unit;
	}

	getDates(now) {
		if (this.which === "this") {
			const unit = new unitLookup[this.unit](this);
			return [unit.start(now), unit.end(now)];
		} else if (this.which === "last") {
			const unit = new unitLookup[this.unit](this);
			const start = unit.subtractOne(unit.start(now));
			return [start, unit.end(start)];
		} else if (this.which === "inLast") {
			const unit = new unitLookup[this.unit](this);
			const start = unit.subtractOne(now);
			return [start, now];
		} else if (this.which === "next") {
			const unit = new unitLookup[this.unit](this);
			const start = unit.addOne(unit.start(now));
			return [start, unit.end(start)];
		} else if (this.which === "inNext") {
			const unit = new unitLookup[this.unit](this);
			const end = unit.addOne(now);
			return [now, end];
		} else {
			throw new Error(`this.which: ${this.which}`);
		}
	}
}

class FortnightRange extends RegularRange {
	constructor(which, fortnightType) {
		super(which, "fortnight");
		this.fortnightType = fortnightType;
	}

	setFortnightType(fortnightType) {
		return new FortnightRange(this.which, fortnightType);
	}

	serialize() {
		return {
			...super.serialize(),
			fortnightType: this.fortnightType,
		};
	}
}

class Week {
	start(now) {
		return now.clone().startOf("isoWeek");
	}

	end(now) {
		return now.clone().endOf("isoWeek");
	}

	subtractOne(now) {
		return now.clone().subtract(1, "week");
	}

	addOne(now) {
		return now.clone().add(1, "week");
	}
}

class Fortnight {
	constructor({ fortnightType }) {
		this.fortnightType = fortnightType;
		this.initialOffset =
			fortnightType === "a"
				? 4 // First monday after epoch
				: 11; // Second monday after epoch;
	}

	start(now) {
		const n = dateConverter.momentToInt(now);
		const o = this.initialOffset;
		return dateConverter.intToMoment(n - o - ((n - o) % 14) + o);
	}

	end(now) {
		return this.start(now).clone().add(13, "days");
	}

	subtractOne(now) {
		return now.clone().subtract(14, "days");
	}

	addOne(now) {
		return now.clone().add(14, "days");
	}
}

class Month {
	start(now) {
		return now.clone().startOf("month");
	}

	end(now) {
		return now.clone().endOf("month");
	}

	subtractOne(now) {
		return now.clone().subtract(1, "month");
	}

	addOne(now) {
		return now.clone().add(1, "month");
	}
}

class Quarter {
	start(now) {
		return getStartOfQuarter(now);
	}

	end(now) {
		return getEndOfQuarter(now);
	}

	subtractOne(now) {
		return now.clone().subtract(3, "months");
	}

	addOne(now) {
		return now.clone().add(3, "months");
	}
}

class Year {
	start(now) {
		return now.clone().startOf("year");
	}

	end(now) {
		return now.clone().endOf("year");
	}

	subtractOne(now) {
		return now.clone().subtract(1, "year");
	}

	addOne(now) {
		return now.clone().add(1, "year");
	}
}

const unitLookup = {
	week: Week,
	fortnight: Fortnight,
	month: Month,
	quarter: Quarter,
	year: Year,
};

function getStartOfQuarter(d) {
	let month = d.month();
	let startOfQuarter = month - (month % 3);
	return d.clone().startOf("month").set("months", startOfQuarter);
}

function getEndOfQuarter(d) {
	let monthsToAdd = 2 - (d.month() % 3);
	return d.clone().add(monthsToAdd, "months").endOf("month");
}

let dateRangeLookup = {};
for (let dr of dateRangeOptions) {
	dateRangeLookup[dr.id] = dr;
}

export function getDateRangeById(id) {
	return dateRangeLookup[id].create();
}

export function parseDateRange(dateRangeData) {
	if (dateRangeData.id === "custom") {
		dateRangeData = {
			...dateRangeData,
			start: parseMoment(dateRangeData.start),
			end: parseMoment(dateRangeData.end),
		};
	}
	return dateRangeLookup[dateRangeData.id].create(dateRangeData);
}
