// Generated automatically by nearley, version 2.19.3
// http://github.com/Hardmath123/nearley
export default function (context) {
function id(x) { return x[0]; }


const FormulaNode = require('../FormulaNode').FormulaNode
const makeString = d => {
    return d.map(e => (e?.matchString ?? (e) ?? "")).join("")
}
const modifyString = (objects, opVal) => {
    const str = makeString(objects)
    opVal.matchString = str;
    return opVal
}
const op = (name, objects, inputs) => {
    return FormulaNode(name, inputs, makeString(objects), context);
}

var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "value", "symbols": ["expression"], "postprocess": d => d[0]},
    {"name": "function", "symbols": ["P", {"literal":"."}, "string", {"literal":"("}, "array", {"literal":")"}], "postprocess": d => op("method", d, {object: d[0], method: d[2], args: d[4]})},
    {"name": "function", "symbols": ["P", {"literal":"."}, "string"], "postprocess": d => op("property", d, {object: d[0], prop: d[2]})},
    {"name": "function", "symbols": ["context", "string"], "postprocess": d => op("property", d, {object: d[0], prop: d[1]})},
    {"name": "function", "symbols": ["string", {"literal":"("}, "array", {"literal":")"}], "postprocess": d => op("function", d, {funcName: d[0], args: d[2]})},
    {"name": "function", "symbols": ["basicObject"], "postprocess": d => d[0]},
    {"name": "array", "symbols": ["condition", "_", {"literal":","}, "_", "array"], "postprocess": d => op("array", d, {array: [d[0], ...(d[4].type === "array" ? d[4].value : [d[4]])] })},
    {"name": "array", "symbols": ["condition"], "postprocess": d => d[0]},
    {"name": "basicObject$subexpression$1", "symbols": ["range"]},
    {"name": "basicObject$subexpression$1", "symbols": ["context"]},
    {"name": "basicObject$subexpression$1", "symbols": ["dateTime"]},
    {"name": "basicObject$subexpression$1", "symbols": ["time"]},
    {"name": "basicObject$subexpression$1", "symbols": ["hashRef"]},
    {"name": "basicObject$subexpression$1", "symbols": ["atRef"]},
    {"name": "basicObject$subexpression$1", "symbols": ["text"]},
    {"name": "basicObject$subexpression$1", "symbols": ["percent"]},
    {"name": "basicObject$subexpression$1", "symbols": ["duration"]},
    {"name": "basicObject$subexpression$1", "symbols": ["float"]},
    {"name": "basicObject", "symbols": ["basicObject$subexpression$1"], "postprocess": d => d[0][0]},
    {"name": "expression", "symbols": ["_", "array", "_"], "postprocess": d => d[1].length === 1 ? d[1][0] : d[1]},
    {"name": "condition$subexpression$1", "symbols": [/[iI]/, /[fF]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "condition$subexpression$2", "symbols": [/[tT]/, /[hH]/, /[eE]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "condition$subexpression$3", "symbols": [/[eE]/, /[lL]/, /[sS]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "condition", "symbols": ["condition$subexpression$1", "__", "condition", "__", "condition$subexpression$2", "__", "condition", "__", "condition$subexpression$3", "__", "condition"], "postprocess": d => op("ifElse", d, {if: d[2], then: d[6], else: d[10]})},
    {"name": "condition", "symbols": ["condition", "_", {"literal":"?"}, "_", "condition", "_", {"literal":":"}, "_", "condition"], "postprocess": d => op("ifElse", d, {if: d[0], then: d[4], else: d[8]})},
    {"name": "condition$subexpression$4", "symbols": [/[iI]/, /[fF]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "condition$subexpression$5", "symbols": [/[eE]/, /[lL]/, /[sS]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "condition", "symbols": ["condition", "_", "condition$subexpression$4", "_", "condition", "_", "condition$subexpression$5", "_", "condition"], "postprocess": d => op("ifElse", d, {if: d[4], then: d[0], else: d[8]})},
    {"name": "condition", "symbols": ["comparison"], "postprocess": d => d[0]},
    {"name": "comparison", "symbols": ["comparison", "_", {"literal":"="}, "_", "logic"], "postprocess": d => op("equal", d, {left: d[0], right: d[4]})},
    {"name": "comparison$string$1", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparison", "symbols": ["comparison", "_", "comparison$string$1", "_", "logic"], "postprocess": d => op("greaterThanEqual", d, {left: d[0], right: d[4]})},
    {"name": "comparison$string$2", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparison", "symbols": ["comparison", "_", "comparison$string$2", "_", "logic"], "postprocess": d => op("lessThanEqual", d, {left: d[0], right: d[4]})},
    {"name": "comparison", "symbols": ["comparison", "_", {"literal":">"}, "_", "logic"], "postprocess": d => op("greaterThan", d, {left: d[0], right: d[4]})},
    {"name": "comparison", "symbols": ["comparison", "_", {"literal":"<"}, "_", "logic"], "postprocess": d => op("lessThan", d, {left: d[0], right: d[4]})},
    {"name": "comparison", "symbols": ["logic"], "postprocess": d => d[0]},
    {"name": "logic$subexpression$1", "symbols": [/[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "logic", "symbols": ["logic", "__", "logic$subexpression$1", "__", "AS"], "postprocess": d => op("and", d, {left: d[0], right: d[4]})},
    {"name": "logic", "symbols": ["logic", "_", {"literal":"&"}, "_", "AS"], "postprocess": d => op("and", d, {left: d[0], right: d[4]})},
    {"name": "logic$subexpression$2", "symbols": [/[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "logic", "symbols": ["logic", "__", "logic$subexpression$2", "__", "AS"], "postprocess": d => op("or", d, {left: d[0], right: d[4]})},
    {"name": "logic", "symbols": ["logic", "_", {"literal":"|"}, "_", "AS"], "postprocess": d => op("or", d, {left: d[0], right: d[4]})},
    {"name": "logic", "symbols": ["AS"], "postprocess": d => d[0]},
    {"name": "P", "symbols": [{"literal":"("}, "_", "value", "_", {"literal":")"}], "postprocess": d => modifyString(d, d[2])},
    {"name": "P", "symbols": ["function"], "postprocess": d => d[0]},
    {"name": "not$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "not", "symbols": ["not$subexpression$1", "__", "not"], "postprocess": d => op("not", d, {val: {...d[2]}})},
    {"name": "not", "symbols": [{"literal":"!"}, "_", "not"], "postprocess": d => op("not", d, {val: {...d[2]}})},
    {"name": "not", "symbols": ["P"], "postprocess": d => d[0]},
    {"name": "E", "symbols": ["not", "_", {"literal":"^"}, "_", "E"], "postprocess": d => op("power", d, {base: d[0], power: d[4]})},
    {"name": "E", "symbols": ["not"], "postprocess": d => d[0]},
    {"name": "MD", "symbols": ["MD", "_", {"literal":"*"}, "_", "E"], "postprocess": d => op("multiply", d, {left: d[0], right: d[4]})},
    {"name": "MD", "symbols": ["MD", "_", {"literal":"/"}, "_", "E"], "postprocess": d => op("divide", d, {left: d[0], right: d[4]})},
    {"name": "MD", "symbols": ["E"], "postprocess": d => d[0]},
    {"name": "AS", "symbols": ["AS", "_", {"literal":"+"}, "_", "MD"], "postprocess": d => op("add", d, {left: d[0], right: d[4]})},
    {"name": "AS", "symbols": ["AS", "_", {"literal":"-"}, "_", "MD"], "postprocess": d => op("subtract", d, {left: d[0], right: d[4]})},
    {"name": "AS", "symbols": ["MD"], "postprocess": d => d[0]},
    {"name": "context", "symbols": [{"literal":"$"}], "postprocess": d => op("context", d, {context: context})},
    {"name": "hashRef", "symbols": [{"literal":"#"}, "ref"], "postprocess": d => op("hashRef", d, {context: context, ref: d[1]})},
    {"name": "atRef", "symbols": [{"literal":"@"}, "ref"], "postprocess": d => op("atRef", d, {context: context, ref: d[1]})},
    {"name": "range$string$1", "symbols": [{"literal":"-"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "range", "symbols": ["P", "_", "range$string$1", "_", "P"], "postprocess": d => op("range", d, {start: d[0], end: d[4]})},
    {"name": "duration", "symbols": ["P", "__", "durationUnit"], "postprocess": d => op("duration", d, {amount: d[0], unit: d[2]})},
    {"name": "duration", "symbols": ["P", "__", "durationUnitSingular"], "postprocess": d => op("duration", d, {amount: d[0], unit: `${d[2]}s`})},
    {"name": "durationUnit$subexpression$1$subexpression$1", "symbols": [/[mM]/, /[iI]/, /[lL]/, /[lL]/, /[iI]/, /[sS]/, /[eE]/, /[cC]/, /[oO]/, /[nN]/, /[dD]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$1"]},
    {"name": "durationUnit$subexpression$1$subexpression$2", "symbols": [/[sS]/, /[eE]/, /[cC]/, /[oO]/, /[nN]/, /[dD]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$2"]},
    {"name": "durationUnit$subexpression$1$subexpression$3", "symbols": [/[mM]/, /[iI]/, /[nN]/, /[uU]/, /[tT]/, /[eE]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$3"]},
    {"name": "durationUnit$subexpression$1$subexpression$4", "symbols": [/[hH]/, /[oO]/, /[uU]/, /[rR]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$4"]},
    {"name": "durationUnit$subexpression$1$subexpression$5", "symbols": [/[dD]/, /[aA]/, /[yY]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$5"]},
    {"name": "durationUnit$subexpression$1$subexpression$6", "symbols": [/[wW]/, /[eE]/, /[eE]/, /[kK]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$6"]},
    {"name": "durationUnit$subexpression$1$subexpression$7", "symbols": [/[mM]/, /[oO]/, /[nN]/, /[tT]/, /[hH]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$7"]},
    {"name": "durationUnit$subexpression$1$subexpression$8", "symbols": [/[yY]/, /[eE]/, /[aA]/, /[rR]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnit$subexpression$1", "symbols": ["durationUnit$subexpression$1$subexpression$8"]},
    {"name": "durationUnit", "symbols": ["durationUnit$subexpression$1"], "postprocess": d => d[0][0]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$1", "symbols": [/[mM]/, /[iI]/, /[lL]/, /[lL]/, /[iI]/, /[sS]/, /[eE]/, /[cC]/, /[oO]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$1"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$2", "symbols": [/[sS]/, /[eE]/, /[cC]/, /[oO]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$2"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$3", "symbols": [/[mM]/, /[iI]/, /[nN]/, /[uU]/, /[tT]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$3"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$4", "symbols": [/[hH]/, /[oO]/, /[uU]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$4"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$5", "symbols": [/[dD]/, /[aA]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$5"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$6", "symbols": [/[wW]/, /[eE]/, /[eE]/, /[kK]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$6"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$7", "symbols": [/[mM]/, /[oO]/, /[nN]/, /[tT]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$7"]},
    {"name": "durationUnitSingular$subexpression$1$subexpression$8", "symbols": [/[yY]/, /[eE]/, /[aA]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "durationUnitSingular$subexpression$1", "symbols": ["durationUnitSingular$subexpression$1$subexpression$8"]},
    {"name": "durationUnitSingular", "symbols": ["durationUnitSingular$subexpression$1"], "postprocess": d => d[0][0]},
    {"name": "dateTime", "symbols": ["date", "__", "time"], "postprocess": d => op("dateTime", d, {date: d[0], time: d[2]})},
    {"name": "dateTime", "symbols": ["time", "__", "date"], "postprocess": d => op("dateTime", d, {date: d[2], time: d[0]})},
    {"name": "dateTime", "symbols": ["date"], "postprocess": d => op("dateTime", d, {date: d[0]})},
    {"name": "date", "symbols": ["int", {"literal":"."}, "int", {"literal":"."}, "int"], "postprocess": d => op("date", d, {day:d[0], month:d[2], year:d[4]})},
    {"name": "time$ebnf$1$subexpression$1$subexpression$1", "symbols": [/[aA]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$ebnf$1$subexpression$1", "symbols": ["time$ebnf$1$subexpression$1$subexpression$1"]},
    {"name": "time$ebnf$1$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$ebnf$1$subexpression$1", "symbols": ["time$ebnf$1$subexpression$1$subexpression$2"]},
    {"name": "time$ebnf$1", "symbols": ["time$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "time$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "time", "symbols": ["int", {"literal":":"}, "int", {"literal":":"}, "int", "_", "time$ebnf$1"], "postprocess": d => op("time", d, {hours: d[0], minutes: d[2], seconds: d[4], ampm: d[6]?.[0]})},
    {"name": "time$ebnf$2$subexpression$1$subexpression$1", "symbols": [/[aA]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$ebnf$2$subexpression$1", "symbols": ["time$ebnf$2$subexpression$1$subexpression$1"]},
    {"name": "time$ebnf$2$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$ebnf$2$subexpression$1", "symbols": ["time$ebnf$2$subexpression$1$subexpression$2"]},
    {"name": "time$ebnf$2", "symbols": ["time$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "time$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "time", "symbols": ["int", {"literal":":"}, "int", "_", "time$ebnf$2"], "postprocess": d => op("time", d, {hours: d[0], minutes: d[2], seconds: 0, ampm: d[4]?.[0]})},
    {"name": "time$subexpression$1$subexpression$1", "symbols": [/[aA]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$subexpression$1", "symbols": ["time$subexpression$1$subexpression$1"]},
    {"name": "time$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "time$subexpression$1", "symbols": ["time$subexpression$1$subexpression$2"]},
    {"name": "time", "symbols": ["int", "_", "time$subexpression$1"], "postprocess": d => op("time", d, {hours: d[0], minutes: 0 , seconds: 0, ampm: d[2]?.[0]})},
    {"name": "percent", "symbols": ["int", "_", {"literal":"%"}], "postprocess": d => op("percent", d, {val: d[0]})},
    {"name": "float$ebnf$1", "symbols": []},
    {"name": "float$ebnf$1", "symbols": ["float$ebnf$1", /[-]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "float", "symbols": ["float$ebnf$1", "int", {"literal":"."}, "int"], "postprocess": d => op("number", [d.join("")], {val: d.join("")})},
    {"name": "float$ebnf$2", "symbols": []},
    {"name": "float$ebnf$2", "symbols": ["float$ebnf$2", /[-]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "float", "symbols": ["float$ebnf$2", "int"], "postprocess": d => op("number", [d.join("")], {val: d.join("")})},
    {"name": "int$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "int$ebnf$1", "symbols": ["int$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "int", "symbols": ["int$ebnf$1"], "postprocess": d => d[0].join("")},
    {"name": "text", "symbols": [{"literal":"\""}, "quotedString", {"literal":"\""}], "postprocess": d => op("quoteText", d, {string: d[1]})},
    {"name": "text", "symbols": ["string"], "postprocess": d => op("text", d, {string: d[0]})},
    {"name": "ref", "symbols": [{"literal":"\""}, "quotedString", {"literal":"\""}], "postprocess": d => op("quoteText", d, {string: d[1]})},
    {"name": "ref", "symbols": ["refString"], "postprocess": d => op("text", d, {string: d[0]})},
    {"name": "string$subexpression$1$subexpression$1$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "string$subexpression$1$subexpression$1$ebnf$1", "symbols": ["string$subexpression$1$subexpression$1$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "string$subexpression$1$subexpression$1", "symbols": ["string$subexpression$1$subexpression$1$ebnf$1", /[a-zA-Z_]/]},
    {"name": "string$subexpression$1", "symbols": ["string$subexpression$1$subexpression$1"]},
    {"name": "string$subexpression$1", "symbols": [/[a-zA-Z_]/]},
    {"name": "string$ebnf$1", "symbols": []},
    {"name": "string$ebnf$1", "symbols": ["string$ebnf$1", /[a-zA-Z_0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "string", "symbols": ["string$subexpression$1", "string$ebnf$1"], "postprocess": d => d.toString().replace(/,/g,"")},
    {"name": "quotedString$ebnf$1", "symbols": [/[a-zA-Z0-9\s.,\/?:;\[\]+=\-_~!@#$%^&*()]/]},
    {"name": "quotedString$ebnf$1", "symbols": ["quotedString$ebnf$1", /[a-zA-Z0-9\s.,\/?:;\[\]+=\-_~!@#$%^&*()]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "quotedString", "symbols": ["quotedString$ebnf$1"], "postprocess": d => d[0].join("")},
    {"name": "refString$ebnf$1", "symbols": [/[a-zA-Z_0-9:]/]},
    {"name": "refString$ebnf$1", "symbols": ["refString$ebnf$1", /[a-zA-Z_0-9:]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "refString", "symbols": ["refString$ebnf$1"], "postprocess": d => d[0].join("")},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return d[0].join("") }},
    {"name": "__$ebnf$1", "symbols": [/[\s]/]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", /[\s]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return d[0].join("") }}
]
  , ParserStart: "value"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   return grammar;
} else {
   return grammar;
}
};
