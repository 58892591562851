import React from 'react';
import CreateReactClass from 'create-react-class';

import PropTypes from "prop-types";

export var FilterTextBox = CreateReactClass({
  propTypes: {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  },

  render: function() {
    return (
      <div
          style={{position: 'relative', ...this.props.style}}
          className="flexbox-container flex-align-items-center">
        <i className="fa fa-search flex-0-0-auto" />
        <input
          type="search"
          className="flex-1-0-auto"
          value={this.props.value}
          onChange={this.handleInputChange}
          placeholder={this.props.placeholder}
        />
        {this.props.value !== '' ?
          <button
              className="btn btn-default btn-sm"
              style={{position: 'absolute', right: 6, top: 3, padding: '1px 6px'}}
              onClick={this.handleClearButtonClick}>
            Clear
          </button>
        : null}
      </div>
    );
  },

  handleInputChange: function(event) {
    this.props.onChange(event.target.value);
  },

  handleClearButtonClick: function() {
    this.props.onChange('');
  },
});




