export let Constants = {
	true: () => ({ type: "logic", value: true }),
	false: () => ({ type: "logic", value: false }),
	pi: () => ({ type: "number", value: Math.PI }),
	e: () => ({ type: "number", value: Math.E }),
	millisecond: () => ({ type: "duration", value: { milliseconds: 1 } }),
	second: () => ({ type: "duration", value: { seconds: 1 } }),
	minute: () => ({ type: "duration", value: { minutes: 1 } }),
	hour: () => ({ type: "duration", value: { hours: 1 } }),
	day: () => ({ type: "duration", value: { days: 1 } }),
	week: () => ({ type: "duration", value: { weeks: 1 } }),
	month: () => ({ type: "duration", value: { months: 1 } }),
	year: () => ({ type: "duration", value: { years: 1 } }),
	now: () => ({
		type: "dateTime",
		value: Date.now(),
		primitive: true,
	}),
	currentDate: () => ({
		type: "dateTime",
		value: Date.now(),
		primitive: true,
	}),
};

export const addConstants = (newConsts) => {
	Constants = {
		...Constants,
		...newConsts,
	};
};
