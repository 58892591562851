export function processString(tags, s, context) {
  return s.replace(/\[([^\]]+)\]/g, function(m, tag) {
    let func = tags[tag];
    if (func != null) {
      return func(context);
    }
    else {
      return m;
    }
  });
};

