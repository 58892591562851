import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { DateValue, NumberInputContainer, CurrencyIcon } from '../widgets.js';
import { dateConverter, ProjectPhase } from '../models.js';
import PropTypes from "prop-types";


export var PhaseMilestonesPanel = requiresPermission(
  ({phase}) => permissions.projectMilestones(phase.project),
  {},
  CreateReactClass({
    propTypes: {
      phase: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired,
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Milestones</h3>

          <PhaseMilestoneTable
            phase={this.props.phase}
          />

          <button
              style={{paddingTop: 4, border: 'solid 1px #ddd'}}
              onClick={() => actions.addMilestoneToPhase(this.props.phase)}
              className="button-100">
            <i className="fa fa-plus fa-fw" style={{marginRight: 0, fontSize: '.9em'}} /> Add Milestone
          </button>
        </div>
      );
    }
  })
);


var PhaseMilestoneTable = CreateReactClass({
  propTypes: {
    phase: PropTypes.instanceOf(ProjectPhase).isRequired,
  },

  render: function() {
    let self = this;
    const milestones = this.props.phase.getVisibleMilestones();
    return <div className="input-table">
      {milestones.map(function(milestone, i) {
        return <MilestoneRow
          key={i}
          milestone={milestone}
          min={i > 0 ?
            dateConverter.intToMoment(milestones[i - 1].endDate).toDate()
          : dateConverter.intToMoment(self.props.phase.startDate).toDate()}
          max={i < milestones.length - 1 ? dateConverter.intToMoment(milestones[i + 1].endDate).toDate() : null}
        />;
      })}
    </div>;
  }
});


var MilestoneRow = CreateReactClass({
  propTypes: {
    milestone: PropTypes.object.isRequired,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  },

  render: function() {
    const milestone = this.props.milestone;

    return <div>
      <div
          className="date cell-input-field"
          style={{display: 'inline-block', width: '38%', borderRight: 'solid 1px #ccc'}}>
        <DateValue
          value={dateConverter.intToMoment(milestone.endDate)}
          action={actions.setPhaseSidebarMilestoneEndDate}
          actionArgs={[milestone]}
          isEditable={true}
          min={this.props.min}
          max={this.props.max}
        />
      </div>
      <div
          className="percent cell-input-field"
          style={{
            display: 'inline-block',
            width: '26%',
            borderRight: 'solid 1px #ccc',
            padding: '0 0.5em'
          }}>
        <div className="flexbox-container flex-align-items-center" style={{height: '100%'}}>
          <NumberInputContainer
            className="flex-1-0-auto"
            value={milestone.percent}
            action={actions.setMilestonePercent}
            actionArgs={[milestone]}
            executeActionOn='blur'
          />
          <div className="flex-0-0-auto" style={{fontWeight: 600}}>
            %
          </div>
        </div>
      </div>
      <div
          className="dollar cell-input-field"
          style={{
            display: 'inline-block',
            width: '26%',
            borderRight: 'solid 1px #ccc',
            padding: '0 0.5em'
          }}>
        <div className="flexbox-container flex-align-items-center" style={{height: '100%'}}>
          <CurrencyIcon className="flex-0-0-auto" />
          <NumberInputContainer
            value={milestone.revenue}
            action={actions.setMilestoneFee}
            actionArgs={[milestone]}
            executeActionOn='blur'
          />
        </div>
      </div>
      {milestone.phase.getVisibleMilestones().length > 1 ?
        <div
            className="cell-input-field"
            style={{
              display: 'inline-block',
              width: '10%',
              borderLeft:'1px solid #ccc',
              textAlign: 'center'
            }}>
          <button
              className="btn btn-default"
              style={{padding: "2px 6px", fontWeight:'bold'}}
              onClick={()=> actions.removeMilestoneFromPhase(milestone.phase, milestone)}>
            x
          </button>
        </div>
      : null}
    </div>;
  }
});
