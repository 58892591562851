import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';

import PropTypes from "prop-types";


export var Alert = CreateReactClass({
  /**
   * An `Alert` without an `alertType` is just a plain `div`; this is useful if
   * you have some content that might be inside an alert or might not.
   */
  propTypes: {
    alertType: PropTypes.oneOf(['danger', 'warning', 'info', 'success'])
  },

  render: function() {
    let {className, children, alertType, ...props} = this.props;

    const actualClassName = (alertType != null) ?
      classNames("alert", "alert-" + alertType, className)
    : className;

    return (
      <div className={actualClassName} {...props}>
        {children}
      </div>
    );
  }
});


export var ErrorAlert = CreateReactClass({
  render: function() {
    return <Alert alertType="danger" {...this.props} />;
  }
});

export var WarningAlert = CreateReactClass({
  render: function() {
    return <Alert alertType="warning" {...this.props} />;
  }
});

export var InfoAlert = CreateReactClass({
  render: function() {
    return <Alert alertType="info" {...this.props} />;
  }
});

export var SuccessAlert = CreateReactClass({
  render: function() {
    return <Alert alertType="success" {...this.props} />;
  }
});
