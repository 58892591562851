import React from "react";
import CreateReactClass from "create-react-class";
import { Router, IndexRoute, Route, browserHistory } from "react-router";
import { render } from "react-dom";
import { makeStoreMixin } from "./coincraftFlux.js";
import { authenticationStore, actions } from "./authenticationService.js";
import { LoginComponent, ForgottenPasswordComponent } from "./login.js";
import {
	TimesheetsPageWrapper,
	TimesheetReportPageWrapper,
} from "./timesheets/timesheet.js";
import { DashboardWrapper, DashboardHomePageWrapper } from "./dashboard.js";
import { TrialPage } from "./trialDashboard.js";
import { ExpensesPage } from "./expenses.js";
import {
	MilestonesPage,
	MilestonesStoreWrapper,
	MilestonesPageDemoWrapper,
} from "./milestones/milestones.js";
import { ProjectPage, ProjectsPageWrapper } from "./project.js";
import { ContactsPageWrapper } from "./contacts.js";
import {
	CostCentresPage,
	CostCentrePageWrapper,
} from "./costCentres/costCentres.js";
import { BillingPage } from "./billing/BillingPage";
import { UserDetailsComponent } from "./user/userDetails.js";
import { TrialExpired } from "./trialExpired.js";
import { StaffMemberPage, StaffMembersPageWrapper } from "./staff/staff.js";
import { ItemDeletedPage } from "./ItemDeletedPage.js";
import { NewSpreadsheetPage } from "./newSpreadsheet/NewSpreadsheetPage.js";
import { NewSchedulePage } from "./newSpreadsheet/NewSchedulePage.js";
import { SpreadsheetPage } from "./spreadsheet";
import { organisationStore } from "./organisation.js";
import { OrganisationHolidaysPage } from "./organisationHolidays/organisationHolidays.js";
import { StaffRoleList } from "./staffRoles/staffRoleList.js";
import { StaffRolePage } from "./staffRoles/staffRolePage.js";
import { SettingsPage } from "./settings/settingsPage.js";
import { InvoicesPageWrapper, InvoicePage } from "./invoices/invoices.js";
import { PageLoadingSpinner } from "./widgets/PageLoadingSpinner.js";
import AccountingConnectedPage from "./accounting/AccountingConnectedPage.js";
import AccountingRejectedPage from "./accounting/AccountingRejectedPage.js";
import TimeEntryCalendar from "./calendar/TimeEntryCalendar.js";
import AllocationCalendar from "./calendar/AllocationCalendar.js";
import PdfPreview from "./invoices/pdf/PdfPreview.js";
import UpdateAllForecasts from "./project/UpdateAllForecasts.js";

export const CoincraftRouter = class {
	constructor() {}

	setup() {
		let routes = null;
		if (false) {
			routes = <Route path="/" component={MilestonesPageDemoWrapper} />;
		} else {
			/**
			 * Make sure all the URLs here are served by the index view (views.py).
			 */
			routes = (
				<Route path="/">
					<Route
						name="login"
						path="login"
						component={LoginComponent}
					/>
					<Route
						name="register"
						path="register"
						component={LoginComponent}
					/>
					<Route
						path="user/forgotten-password"
						component={ForgottenPasswordComponent}
					/>
					<Route
						path="accounting/:accountingSystemId/connected"
						component={AccountingConnectedPage}
					/>
					<Route
						path="accounting/:accountingSystemId/rejected"
						component={AccountingRejectedPage}
					/>

					<Route component={RequireLogin}>
						<Route
							path="user/details"
							component={UserDetailsComponent}
						/>

						<Route
							path="timesheet/:date"
							component={function (props) {
								return (
									<DashboardWrapper {...props}>
										<TimesheetsPageWrapper
											routeParams={props.routeParams}
										/>
									</DashboardWrapper>
								);
							}}
						/>

						<Route path="trials" component={TrialPage} />
						<Route path="billing" component={BillingPage} />

						<Route path="dashboard" component={DashboardWrapper}>
							<Route
								path="revenue-forecast"
								component={NewSpreadsheetPage}
							/>
							<Route
								path="resource-schedule"
								component={NewSchedulePage}
							/>
							<Route
								path="weekly-planning"
								component={AllocationCalendar}
							/>

							<Route
								path="revenue"
								component={function () {
									return (
										<SpreadsheetPage spreadsheetType="revenue" />
									);
								}}
							/>
							<Route
								path="staff-allocation"
								component={function () {
									return (
										<SpreadsheetPage spreadsheetType="allocation" />
									);
								}}
							/>

							<Route
								path="projects"
								component={ProjectsPageWrapper}
							/>
							<Route
								path="projects/report/:reportUuid"
								component={ProjectsPageWrapper}
							/>
							<Route path="expenses" component={ExpensesPage} />

							<Route
								path="cost-centres"
								component={CostCentresPage}
							/>
							<Route
								path="cost-centres/:costCentreId"
								component={CostCentrePageWrapper}
							/>

							<Route
								path="invoices/report/:reportUuid"
								component={InvoicesPageWrapper}
							/>
							<Route
								path="invoices"
								component={InvoicesPageWrapper}
							/>
							<Route
								path="invoices/template"
								component={PdfPreview}
							/>
							<Route
								path="invoices/:invoiceId/pdf"
								component={PdfPreview}
							/>
							<Route
								path="invoices/new/project/:projectId"
								component={InvoicePage}
							/>
							<Route
								path="invoices/:invoiceId"
								component={InvoicePage}
							/>

							<Route
								path="staff"
								component={StaffMembersPageWrapper}
							/>
							<Route
								path="staff/report/:reportUuid"
								component={StaffMembersPageWrapper}
							/>
							<Route
								path="staff/:staffId"
								component={StaffMemberPage}
							/>

							<Route
								path="milestones"
								component={function () {
									return (
										<MilestonesStoreWrapper
											component={MilestonesPage}
										/>
									);
								}}
							/>
							<Route
								path="contacts"
								component={ContactsPageWrapper}
							/>
							<Route
								path="org-hols"
								component={OrganisationHolidaysPage}
							/>
							<Route
								path="staff-roles"
								component={StaffRoleList}
							/>
							<Route
								path="staff-roles/:roleId"
								component={StaffRolePage}
							/>

							<Route
								path="timesheet/calendar"
								component={TimeEntryCalendar}
							/>

							<Route
								path="timesheet/:date"
								component={TimesheetsPageWrapper}
							/>

							<Route
								path="timesheet/:date"
								component={TimesheetsPageWrapper}
							/>
							<Route
								path="timesheet/report/:reportUuid"
								component={TimesheetReportPageWrapper}
							/>
							<Route
								path="timesheet"
								component={TimesheetReportPageWrapper}
							/>

							<Route
								path="project/:projectId"
								component={ProjectPage}
							/>

							<Route path="settings" component={SettingsPage} />

							<Route
								path="project-deleted"
								component={() => (
									<ItemDeletedPage objectType="project" />
								)}
							/>
							<Route
								path="staff-deleted"
								component={() => (
									<ItemDeletedPage objectType="staffMember" />
								)}
							/>
							<Route
								path="cost-centre-deleted"
								component={() => (
									<ItemDeletedPage objectType="costCentre" />
								)}
							/>
							<Route
								path="invoice-deleted"
								component={() => (
									<ItemDeletedPage objectType="invoice" />
								)}
							/>
							<Route
								path="update-forecasts"
								component={() => <UpdateAllForecasts />}
							/>

							<IndexRoute
								component={function () {
									return (
										<MilestonesStoreWrapper
											component={DashboardHomePageWrapper}
											loadingMessage="Loading Revenue Forecast"
										/>
									);
								}}
							/>
						</Route>
						<Route path="/trial-expired" component={TrialExpired} />

						{/* We need this `DoNothing` route otherwise the `/` route will for
                some reason go into `RequireOrganisationAdmin` which will
                redirect a logged-in non-dashboard user from `/` to `/login` when
                they should actually be redirected to `/timesheet/current` by
                the `authenticationService` */}
						<IndexRoute component={DoNothing} />
					</Route>
					<Route path="*" component={NoMatch} />
				</Route>
			);
		}

		this.history = browserHistory;
		this.router = <Router history={this.history}>{routes}</Router>;
	}

	render() {
		var appElement = document.getElementById("react_app_root");
		this.renderedRouter = render(this.router, appElement);
	}
};

var DoNothing = CreateReactClass({
	/**
	 * Dummy react-router Route component that helps nested routes match when
	 * they otherwise wouldn't.
	 */
	render: function () {
		return null;
	},
});

var RequireLogin = CreateReactClass({
	mixins: [
		makeStoreMixin(authenticationStore, function () {
			return {
				user: authenticationStore.getUser(),
				isReady: authenticationStore.isReady,
			};
		}),
	],

	render: function () {
		if (this.state.user != null) {
			return this.props.children;
		} else if (this.state.isReady) {
			actions.setDestinationUrl(
				router.history.getCurrentLocation().pathname
			);
			router.history.replace({ pathname: "/login" });
		} else {
			return (
				<div>
					<div
						style={{
							position: "fixed",
							left: 0,
							right: 0,
							top: "30%",
							height: "10em",
						}}
					>
						<PageLoadingSpinner text="Loading Coincraft" />
					</div>
				</div>
			);
		}
	},
});

var NoMatch = CreateReactClass({
	render: function () {
		return <h2>404</h2>;
	},
});

export var router = new CoincraftRouter();
