import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import {
	hoverState,
	mergeProps,
	prefixFlex,
	sum,
	isNumber,
	compareMultiple,
} from "./utils.js";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { ItemExpandedState } from "./widgets/generic.js";
import { dateConverter } from "./models.js";
import PropTypes from "prop-types";

const fields = {
	/**
   * The keys in this dictionary are the keys of the arguments to the `Column`
   * constructor and the values are the properties in the `Column` that will
   * hold the values passed in. When they don't correspond it's because the `Column`
   * object has a method with the same name as one of the argument names.
   *
   * Eg. we have a `content` argument, and the `Column` class has a `content`
   * method which returns either the `content` argument if it's specified,
   * otherwise the `data` argument.
   *
   * `identifier` is used to identify a column for use with the `Table`'s
   * `initialSort` prop.
   *
   * `data` refers to the raw data corresponding to a cell, `content` refers
   * to the presentation/rendered content of a cell. You can specify both
   * `data` and `content` callbacks, or just a `content` callback in which
   * case the data and the content are the same.
   *
   * The `data` callback is called in two contexts:
   *
   * 1. During sorting. In this case it is called with two arguments: the first
   * being the row object and the second is the return value of the
   * `sharedData` function (which is called only once for each column at the
   * start of rendering of the table).
   * 2. During rendering. In this case it is called with three arguments: the
   * first two being the same two as in (1) above and the third being the
   * `stack` argument (described below).
   *
   * The `content` callback is called with five arguments:

       content(row, i, stack, data, rowData, tableData)

   * Where:
   * - `row` is the same row as for the `data` callback
   * - `i` is the row index. The first row is index 0, second is index 1, etc.
   *   Indexes are assigned by order of appearance in the table, ie:
   *
   *     Row 1            <-- index 0
   *     Row 1 child 1    <-- index 1
   *     Row 1 child 2    <-- index 2
   *     Row 2            <-- index 3
   *
   * - `stack` is a list of all the ancestor rows of the current row (not including
   *   the current row). So for top-level rows, `stack` will be an empty list.
   * - `data` is the return value of the `data` callback. Will be `null` if there
   *   is no `data` callback.
   * - `rowData` is the return value of the `rowData` callback (which is called
   *   with `(row, i, stack)` once for each row. If the callback is not provided,
   *   `rowData` is `null`.
   * - `tableData` is the return value of the `tableData` callback (which is called
   *   once, with no arguments. If the callback is not provided, `tableData` is
   *   `null`.
   *
   * The `getCellProps` callback is called with six arguments:

      getCellProps(row, column, i, stack, data, tableData)

     Where `row`, `i`, `stack` and `data` are as above and `column` is the `Column`
     instance itself.
   */

	type: "type",
	identifier: "identifier",

	// Usually you only need `header`. But if `header` is not plain text, you need
	// to specify a plain-text version as `headerText` so the filtering and column
	// selection widgets can use it.
	header: "header",
	headerText: "headerText",
	props: "_props",
	getCellProps: "getCellProps",
	width: "width",

	content: "content",
	contentText: "contentText",
	exportText: "exportText",
	data: "_data",
	compare: "_compare",

	total: "total",
	sharedData: "sharedData",

	rowData: "rowData",
	tableData: "tableData",

	requires: "requires",
	isMandatory: "isMandatory", // Column can't be excluded from report output

	canFilter: "canFilter",
	canShow: "canShow",
	canSort: "_canSort",
};

const defaults = {
	props: () => ({}),
	sharedData: () => () => null,
	requires: () => [],
	canFilter: true,
	canShow: true,
};

export const Column = class {
	constructor(props) {
		this.update(props);
	}

	update(props, { useDefaults = true } = {}) {
		/**
		 * If `useDefaults` is true, any items that are not specified in `props`
		 * will be set to their default values (ie. use this in the constructor).
		 * Otherwise, items not specified in `props` will remain unchanged (ie. use
		 * this if you want to update just a couple of fields).
		 */
		let self = this;
		_.each(fields, function (v, k) {
			if (props[k] !== undefined) {
				self[v] = props[k];
			} else if (useDefaults && defaults[k] !== null) {
				if (_.isFunction(defaults[k])) {
					self[v] = defaults[k]();
				} else {
					self[v] = defaults[k];
				}
			}
		});

		return this;
	}

	copy() {
		return new Column({
			type: this.type,
			identifier: this.identifier,
			header: this.header,
			headerText: this.headerText,
			props: this._props,
			getCellProps: this.getCellProps,
			width: this.width,
			content: this.content,
			contentText: this.contentText,
			exportText: this.exportText,
			data: this._data,
			compare: this._compare,
			total: this.total,
			sharedData: this.sharedData,
			rowData: this.rowData,
			tableData: this.tableData,
			requires: _.clone(this.requires),
			isMandatory: this.isMandatory,
			canFilter: this._canFilter,
			canSort: this._canSort,
		});
	}

	get props() {
		let ps = _.clone(this._props);
		if (ps.style == null) {
			ps.style = {};
		}
		if (this.width != null) {
			ps.style.width = this.width;
		}
		if (ps.style.textAlign == null && this.type === "number") {
			ps.style.textAlign = "right";
		}
		return ps;
	}

	get name() {
		return this.headerText || this.header;
	}

	typeTextFunc() {
		/**
		 * If the column's `type` has an associated text func, return it, otherwise
		 * return `null`.
		 */
		const columnType = columnTypes[this.type];
		if (columnType != null) {
			return columnType.text;
		} else {
			return null;
		}
	}

	text(row, i, stack, data, rowData) {
		const func =
			this.contentText ||
			this.content ||
			this.typeTextFunc() ||
			this._data;
		return func(row, i, stack, data, rowData);
	}

	exportCSVText(row, i, stack, data, rowData) {
		const func =
			this.exportText ||
			this.contentText ||
			this.content ||
			this.typeTextFunc() ||
			this._data;
		return func(row, i, stack, data, rowData);
	}

	data(row, sharedData) {
		return this._data != null
			? this._data(row, sharedData)
			: this.content(row);
	}

	dataOnly(row, sharedData, stack) {
		return this._data != null ? this._data(row, sharedData, stack) : null;
	}

	canSort() {
		if (this._canSort != null) {
			return this._canSort;
		} else {
			return (
				(this.type != null && columnTypes[this.type] != null) ||
				this._compare != null
			);
		}
	}

	compare(row1, row2) {
		/**
		 * `row1`, `row2`: {row: <row>, data: <data>}
		 */
		if (this._compare != null) {
			return this._compare(row1, row2);
		} else {
			const columnType = columnTypes[this.type];
			if (columnType != null) {
				return columnType.comparator(row1, row2);
			} else {
				throw new Error("Not implemented");
			}
		}
	}
};

function fixNaN(n) {
	return isNaN(n) ? -Infinity : n;
}

function fixNull(n) {
	return isNumber(n) ? n : "-";
}

const columnTypes = {
	string: {
		comparator: (row1, row2) =>
			(row1.data || "").localeCompare(row2.data || ""),
		text: (row1, i, stack, data) => data,
	},
	moment: {
		comparator: compareMultiple(
			nullsLast,
			(row1, row2) => +row1.data.toDate() - +row2.data.toDate()
		),
		text: (row1, i, stack, data) =>
			data != null ? data.format("YYYY-MM-DD") : "",
	},
	month: {
		comparator: compareMultiple(
			nullsLast,
			(row1, row2) => fixNaN(row1.data) - fixNaN(row2.data)
		),
		text: (row1, i, stack, data) =>
			data != null
				? dateConverter.monthIndexToMoment(data).format("MMM YY")
				: "",
	},
	number: {
		comparator: (row1, row2) => fixNaN(row1.data) - fixNaN(row2.data),
		text: (row1, i, stack, data) => data,
	},
	intDate: {
		comparator: (row1, row2) => fixNaN(row1.data) - fixNaN(row2.data),
		text: (row1, i, stack, data) =>
			data != null ? dateConverter.intToString(data) : "",
	},
	rational: {
		comparator: (row1, row2) =>
			row1.data != null
				? fixNaN(row1.data.numerator / row1.data.denominator) -
				  fixNaN(row2.data.numerator / row2.data.denominator)
				: null,
		text: (row1, i, stack, data) =>
			data != null
				? `${fixNull(data.numerator)} / ${fixNull(data.denominator)}`
				: "",
	},
	bool: {
		comparator: (row1, row2) => (row1.data ? 1 : 0) - (row2.data ? 1 : 0),
		text: (row1, i, stack, data) => (data ? "Yes" : "No"),
	},
	project: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.getTitle().localeCompare(row2.data.getTitle())
		),
		text: (row1, i, stack, data) =>
			row1.data != null ? row1.data.getTitle() : "(No project)",
	},
	projectPhase: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.getTitle().localeCompare(row2.data.getTitle())
		),
		text: (row1, i, stack, data) =>
			row1.data != null ? row1.data.getTitle() : "(No phase)",
	},
	staffMember: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.getFullName().localeCompare(row2.data.getFullName())
		),
		text: (row1, i, stack, data) =>
			row1.data != null ? row1.data.getFullName() : "(No staff member)",
	},
	staffRole: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.name.localeCompare(row2.data.name)
		),
		text: (row1, i, stack, data) =>
			row1.data != null ? row1.data.name : "(No Role)",
	},
	task: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.name.localeCompare(row2.data.name)
		),
		text: (row1, i, stack, task) =>
			task != null ? task.name : "(No task)",
	},
	costCentre: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.name.localeCompare(row2.data.name)
		),
		text: (row1, i, stack, costCentre) =>
			costCentre != null ? costCentre.name : "(No cost centre)",
		exportText: (row1, i, stack, data) => row1.text(row1, i, stack, data),
	},
	contact: {
		comparator: compareMultiple(nullsLast, (row1, row2) =>
			row1.data.display().localeCompare(row2.data.display())
		),
		text: (row1, i, stack, contact) =>
			contact != null ? contact.display() : "(No contact)",
	},
};

function nullsLast(row1, row2) {
	if (row1.data == null && row2.data == null) {
		return null;
	} else {
		return (row1.data != null ? 0 : 1) - (row2.data != null ? 0 : 1);
	}
}

export let Table = CreateReactClass({
	propTypes: {
		columns: PropTypes.array.isRequired,
		rows: PropTypes.any.isRequired, // Array or Immutable.List

		/**
     * You can use this to separate the logic and presentation of columns.
     * Eg:

       <Table
         columns={columns} // Got from a store somewhere
         columnPresentation={{
           projects: { // 'projects' is a `Column` `identifier`
             // Can override any properties of the associated `Column` object here.
             content: function(sm, i, stack, projects) {
               return <span>{JSON.stringify(projects)}</span>;
             }
           }
         }}
       />
     */
		columnPresentation: PropTypes.object,

		/**
		 * Optional list of ids of columns that match column ids from the `column`
		 * prop.  Columns included in the `groupBy` list are not shown in the
		 * table. If `groupBy` is a nonempty list, a column is prepended to the
		 * `columns` list to show the group items.
		 */
		groupBy: PropTypes.arrayOf(PropTypes.string),

		/**
     * If you want to eg. make sure a checkbox is always the first column, regardless of user
     * column selections / grouping settings, use this.
     * Eg.

        <Table
          {...}
          transformColumnsAfterGrouping={(columns) => [myCheckboxColumn, ...columns]}
        />
     */
		transformColumnsAfterGrouping: PropTypes.func,

		tableProps: PropTypes.object,
		rowProps: PropTypes.func,
		rowData: PropTypes.func,
		tableData: PropTypes.func,
		getCellProps: PropTypes.func,
		getRowChildren: PropTypes.func,
		rowComponent: PropTypes.func,

		shouldHandleSorting: PropTypes.bool,
		sortedBy: PropTypes.object,
		onSort: PropTypes.func, // function({columnIdentifier, direction})

		initialSort: PropTypes.shape({
			columnIdentifier: PropTypes.any.isRequired,
			direction: PropTypes.string.isRequired,
			isAlreadySorted: PropTypes.bool,
		}),
	},

	getDefaultProps: function () {
		return {
			groupBy: [],
			rowProps: (row, i, stack) => ({}),
			rowData: (row, i, stack) => null,
			tableData: () => null,
			getRowChildren: (row) => [],
			getCellProps: (row, column, i, stack, data) => ({}),
			shouldHandleSorting: true,
			onSort: ({ columnIdentifier, direction }) => null,
			scroll: false,
		};
	},

	mixins: [PureRenderMixin],

	rowComponent() {
		return this.props.rowComponent || Row;
	},

	getInitialState: function () {
		let rows = this.props.rows;
		let sortedBy = null;
		if (this.props.initialSort != null) {
			sortedBy = {
				columnIdentifier: this.props.initialSort.columnIdentifier,
				direction: this.props.initialSort.direction,
			};
			if (
				!this.props.initialSort.isAlreadySorted &&
				this.props.shouldHandleSorting
			) {
				rows = this.sort(rows, sortedBy);
			}
		} else if (this.props.sortedBy != null) {
			sortedBy = this.props.sortedBy;
		}
		return {
			rows: rows,
			sortedBy: sortedBy,
		};
	},

	componentWillReceiveProps: function (newProps) {
		let newState = {};
		if (newProps.shouldHandleSorting) {
			newState.rows = this.sort(newProps.rows, this.state.sortedBy);
		} else if (newProps.rows !== this.props.rows) {
			newState.rows = newProps.rows;
		}

		if (newProps.sortedBy != null) {
			newState.sortedBy = newProps.sortedBy;
		}

		this.setState(newState);
	},

	render: function () {
		let self = this;
		const rows = this.state.rows;
		const columnsIncludingGroups = mergeColumnDefinitions(
			this.props.columns.filter((c) => c),
			this.props.columnPresentation
		);
		let columns = columnsIncludingGroups.filter(
			(c) => !_.include(this.props.groupBy, c.identifier)
		);

		let columnLookup = {};
		for (let c of columnsIncludingGroups) {
			columnLookup[c.identifier] = c;
		}

		let sharedData = {};
		for (let c of columnsIncludingGroups) {
			sharedData[c.identifier] = c.sharedData();
		}

		if (this.props.groupBy.length > 0) {
			/**
			 * If we are doing grouping, make a column whose value is what would be
			 * rendered in the row's lowest level expanded group.
			 *
			 * Eg. if we're grouping by project and phase, if a row has been expanded
			 * into a phase, it will show the phase, otherwise it'll show the
			 * project.
			 */
			columns.unshift(
				new Column({
					identifier: "grouper",
					header: columnLookup[this.props.groupBy[0]].header,
					width: "25%",
					content: function (
						row,
						i,
						stack,
						data,
						rowData,
						tableData
					) {
						let innerContent;

						if (stack.length < self.props.groupBy.length) {
							const groupField = self.props.groupBy[stack.length];
							const column = columnLookup[groupField];
							if (column == null) {
								throw new Error(
									`Unrecognised group field ${groupField}`
								);
							}

							const data = column.dataOnly(
								row,
								sharedData[column.identifier],
								stack
							);
							innerContent = column.content(
								row,
								i,
								stack,
								data,
								rowData,
								tableData
							);
						} else {
							innerContent = null;
						}

						return (
							<span
								style={{
									fontWeight: stack.length === 0 ? 800 : 400,
								}}
							>
								<div
									style={{
										display: "inline-block",
										width: stack.length * 1.5 + "em",
									}}
								/>
								{stack.length < self.props.groupBy.length ? (
									<ItemExpandedState
										isExpanded={row.expanded}
									/>
								) : null}
								{innerContent}
							</span>
						);
					},
					type: "string",
				})
			);
		}

		if (this.props.transformColumnsAfterGrouping) {
			columns = this.props.transformColumnsAfterGrouping(columns);
		}

		/**
		 * If we have column widths that don't add up to 100% then the cells will work
		 * fine but the border will still take the whole width of the table so you
		 * have a border that is wider than the content which looks silly.
		 *
		 * So set the width of the table to the sum of the column widths, and then
		 * scale the column widths themselves so their percentages add up to 100%
		 * of the table width.
		 *
		 * When the table width is greater than 100%, we want to scale the font
		 * size based on the table width, but only while printing. So we calculate
		 * what the font size would be for printing and always apply it, then use
		 * media queries to let it pass through when media=print, but override it
		 * so it has no effect when media=screen. When the table width is <= 100%
		 * we don't need to do anything with the font size.
		 */

		// When printing the table scales its width to equal 100%
		// We can then use that ratio to scale the font size when printing
		// So if 120rem table width scales to be 100% wide
		// Then the font size should become 100/120 * defaultFontSize

		let totalWidth = sum(
			columns.map((c) => parseFloat(c.props.style.width))
		);
		let tableWidth = null,
			columnWidths = null,
			defaultFontSize = 1.3,
			fontSize;
		tableWidth = totalWidth + "rem";
		// adjust font size because everything scales down to 100%
		// don't make font bigger though because this is ugly when printing
		// small widthed tables
		fontSize =
			Math.min(defaultFontSize, defaultFontSize * (100 / totalWidth)) +
			"rem";
		columnWidths = columns.map(function (c) {
			return parseFloat(c.props.style.width) + "rem";
		});

		const defaultCellProps = {
			className: "table-widget__cell flex-0-1-auto",
			style: {
				display: "inline-block",
				padding: "0.6em",
				verticalAlign: "top",
			},
		};

		let totalRow = null;
		if (_.any(columns, (c) => c.total != null)) {
			totalRow = columns.map((c) => c.total);
		}

		let tableData = this.props.tableData();

		let i = 0;
		function makeRow(row, stack) {
			let { key, ...rowProps } = self.props.rowProps(row, i, stack);
			let rowData = self.props.rowData(row, i, stack);
			if (key == null) {
				key = i;
			}

			let r = (
				<div key={key}>
					{React.createElement(
						self.rowComponent(),
						{
							index: i,
							row: row,
							...rowProps,
						},
						columns.map(function (column, j) {
							let data = column.dataOnly(
								row,
								sharedData[column.identifier],
								stack
							);

							let mergedProps = mergeProps(
								defaultCellProps,
								column.props,
								self.props.getCellProps(
									row,
									column,
									i,
									stack,
									data
								),
								tableWidth != null
									? { style: { width: columnWidths[j] } }
									: null,
								column.getCellProps != null
									? column.getCellProps(
											row,
											column,
											i,
											stack,
											data
									  )
									: null,
								{ style: { color: data < 0 ? "red" : "#444" } }
							);

							return (
								<div key={j} {...mergedProps}>
									{column.content(
										row,
										i,
										stack,
										data,
										rowData,
										tableData
									)}
								</div>
							);
						})
					)}
					{self.props.getRowChildren(row).map(function (childRow) {
						return makeRow(childRow, [...stack, row]);
					})}
				</div>
			);

			i++;
			return r;
		}

		return (
			<div
				className="table-widget"
				style={{
					width: tableWidth,
					// This only has effect for printing; for screen the stylesheet for
					// `.table__print-font-adjuster` will override it.
					fontSize: fontSize,
					textAlign: "left",
					height: "100%",
				}}
				{...this.props.tableProps}
			>
				<div
					className="table__print-font-adjuster"
					style={{ height: "100%" }}
				>
					<div
						className="flexbox-container flex-direction-column"
						style={{ height: "100%" }}
					>
						<div className="table-widget__header-row flexbox-container flex-align-item-stretch flex-0-0-auto">
							{columns.map(function (column, j) {
								let direction;
								if (
									self.state.sortedBy != null &&
									column?.identifier ===
										self.state.sortedBy.columnIdentifier
								) {
									direction = self.state.sortedBy.direction;
								}
								return (
									<HeaderCell
										key={j}
										column={column}
										width={
											tableWidth != null
												? columnWidths[j]
												: null
										}
										sortDirection={direction}
										onClick={self.handleHeaderClick}
									/>
								);
							})}
						</div>
						<div
							className="table-rows flex-1-1-auto"
							style={{
								overflowY: this.props.scroll
									? "auto"
									: "visible",
								height: "100%",
								minHeight: this.props.scroll ? "30em" : "0",
							}}
						>
							{rows.map(function (row) {
								return makeRow(row, []);
							})}
						</div>
					</div>
					{totalRow != null ? (
						<div
							style={{
								borderTop: "solid 1px #999",
								marginTop: "1em",
							}}
						>
							{totalRow.map(function (cell, i) {
								let column = columns[i];
								let mergedProps = mergeProps(
									defaultCellProps,
									column.props
								);
								return (
									<div key={i} {...mergedProps}>
										{cell}
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</div>
		);
	},

	getSortedByColumn: function (sortedBy) {
		/**
		 * If we're using the identifier, make sure to look up the right column object
		 * so the === operator will work even after successive renders where the Column objects
		 * passed in as props may not be identical objects.
		 */
		if (sortedBy == null) {
			return null;
		} else {
			return _.find(
				this.props.columns.filter((c) => c),
				(c) => c.identifier === sortedBy.columnIdentifier
			);
		}
	},

	sortBy: function (rows, column, direction) {
		const multiplier = direction === "asc" ? 1 : -1;
		const comparator = (a, b) => multiplier * column.compare(a, b);
		const sharedData = column.sharedData();

		// Precalculate the data once so the comparator doesn't have to calculate
		// the data for each comparison.
		const rowsAndData = rows.map(function (r) {
			return {
				row: r,
				data: column.data(r, sharedData),
			};
		});

		return _.clone(rowsAndData)
			.sort(comparator)
			.map((r) => r.row);
	},

	sort: function (rows, sortedBy) {
		if (sortedBy != null) {
			return this.sortBy(
				rows,
				this.getSortedByColumn(sortedBy),
				sortedBy.direction
			);
		} else {
			return rows;
		}
	},

	handleHeaderClick: function (column) {
		if (column.canSort()) {
			let sortedBy = this.state.sortedBy;
			let direction;
			if (
				sortedBy != null &&
				sortedBy.columnIdentifier === column.identifier &&
				sortedBy.direction === "asc"
			) {
				direction = "desc";
			} else {
				direction = "asc";
			}
			const newSortedBy = {
				columnIdentifier: column.identifier,
				direction: direction,
			};

			if (this.props.shouldHandleSorting) {
				this.setState({
					rows: this.sortBy(this.state.rows, column, direction),
					sortedBy: newSortedBy,
				});
			} else {
				this.props.onSort(newSortedBy);
			}
		}
	},
});

export function mergeColumnDefinitions(columns, overrides) {
	if (!_.isEmpty(overrides)) {
		return columns.map(function (column) {
			const override = overrides[column.identifier];
			if (override != null) {
				return column.copy().update(override, { useDefaults: false });
			} else {
				return column;
			}
		});
	} else {
		return columns;
	}
}

let HeaderCell = CreateReactClass({
	propTypes: {
		column: PropTypes.object.isRequired,
		sortDirection: PropTypes.string,
		onClick: PropTypes.func.isRequired,

		// Optionally override the column width
		width: PropTypes.string,
	},

	mixins: [hoverState()],

	render: function () {
		let column = this.props.column;
		let direction, caret, innerContent;

		if (this.state.isHovered || this.props.sortDirection != null) {
			direction = this.props.sortDirection;
			direction =
				this.state.isHovered && this.props.sortDirection === "asc"
					? "desc"
					: "asc";
			caret = (
				<i
					className={`fa fa-caret-${
						direction === "asc" ? "down" : "up"
					}`}
					style={{
						marginLeft: "0.5em",
						fontSize: "1.4em",
						position: "relative",
						top: "0.1em",
					}}
				/>
			);

			// If the column is right-aligned, put the caret to the left of the text,
			// else put it to the right of the text.
			innerContent =
				column.props.style.textAlign !== "right" ? (
					<span>
						{column.header}
						{caret}
					</span>
				) : (
					<span>
						{caret}
						{column.header}
					</span>
				);
		} else {
			innerContent = column.header;
		}

		let colors = ["#FFC700", "#ffd645"];

		let width = this.props.width || column.props.style.width;

		let mergedProps = mergeProps(
			{
				style: {
					// http://stackoverflow.com/a/12131365/223486
					minWidth: 0,
					cursor: "pointer",
				},
				className:
					"table-widget__header-cell flexbox-container flex-align-items-stretch flex-0-1-auto",
			},
			column.props,
			this.props.width != null
				? { style: { width: this.props.width } }
				: null
		);

		return (
			<div
				onClick={this.handleClick}
				{...(column.canSort() ? this.getHoverHandlers() : {})}
				{...mergedProps}
			>
				<div
					style={{
						backgroundColor: colors[this.state.isHovered ? 1 : 0],
						// http://stackoverflow.com/a/12131365/223486
						minWidth: 0,
						width: "100%",
						padding: "0.8em",
						textDecoration:
							this.props.sortDirection != null
								? "underline"
								: "none",
					}}
				>
					{innerContent}
				</div>
			</div>
		);
	},

	handleClick: function () {
		this.props.onClick(this.props.column);
	},
});

let Row = CreateReactClass({
	propTypes: {
		index: PropTypes.number.isRequired,
	},

	mixins: [hoverState()],

	render: function () {
		const colors = ["#fff", "#eee"];

		let { children, index: _index, ...props } = this.props;

		let mergedProps = mergeProps(
			{
				className:
					"table-widget__row flexbox-container flex-align-items-stretch",
				style: {
					backgroundColor: colors[this.state.isHovered ? 1 : 0],
					borderBottom: "solid 1px #ccc",
				},
			},
			props
		);
		delete mergedProps.row;

		return (
			<div {...this.getHoverHandlers()} {...mergedProps}>
				{children}
			</div>
		);
	},
});
