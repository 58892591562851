import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { accountingSystemTypeLookup } from './accounting.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { Alert } from '../widgets.js';
import { invoicePageStore, invoiceActions } from './InvoicePageStore.js';
import { AccountingSystemAuth } from './AccountingSystemAuth.js';
import { ConnectionState } from './ConnectionState.js';

import PropTypes from "prop-types";


export var AccountingSystemSyncPopup = CreateReactClass({
  propTypes: {
    accountingSystemId: PropTypes.string.isRequired,
    accountingSystem: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    syncState: PropTypes.string,
    onCancel: PropTypes.func.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([invoicePageStore], function() {
      return {
        syncState: invoicePageStore.syncState,
        invoiceSyncStatus: invoicePageStore.invoiceSyncStatus,
      };
    })
  ],

  render: function() {
    let self = this;
    const isAuthorised = this.props.accountingSystem.connection.state === ConnectionState.connected;
    const formattedName = new (accountingSystemTypeLookup[this.props.accountingSystemId])().name;

    let syncHeading, syncDescription = null;

    if (this.props.syncState === 'sync_finished') {
      syncHeading = `${formattedName} Invoice Sync Successful!`;
    }
    else {
      syncHeading = <span>
        <i className="fa fa-spinner fa-spin" />
        Syncing Invoice to {formattedName}...
      </span>;
    }

    return <div className="invoice-page__copy-invoice-popup">
      <Alert
          alertType={isAuthorised ? "success" : null}
          style={{textAlign: 'center', padding: '1em', borderBottom: 'solid 1px #ccc'}}>
        <AccountingSystemAuth accountingSystem={this.props.accountingSystemId} />
      </Alert>
      {this.props.syncState != null ?
        <div style={{padding: '2em', borderBottom: 'solid 1px #ccc'}}>
          <h4 style={{textAlign: 'center'}}>
            {syncHeading}
          </h4>
          {syncDescription != null ?
            <div style={{textAlign: 'center'}}>
              {syncDescription}
            </div>
          : null}
        </div>
      : null}
      <div className="flexbox-container flex-align-items-center flex-justify-content-end" style={{padding: '1em'}}>
        <button
            className="btn btn-default close-button"
            onClick={() => this.props.onCancel()}
            disabled={self.state.syncState === 'sync_started'}>
            Close
        </button>
        {isAuthorised && self.showSyncButton()?
          <button
              className="btn btn-primary sync-form__sync-button"
              onClick={() => invoiceActions.syncInvoice(this.props.accountingSystemId)}
              disabled={self.syncClicked()}
              style={{marginLeft: '1em'}}>
            {self.state.syncState === 'sync_started' ? "Syncing..." : `Sync to ${formattedName}`}
          </button>
        : null}
      </div>
    </div>;
  },

  syncClicked: function() {
    return _.include(['sync_started', 'sync_finished'], this.state.syncState);
  },

  showSyncButton: function() {
    return !_.include(['sync_finished', 'sync_error'], this.state.syncState);
  }
});



