import { observer } from "mobx-react";
import React, {
	useState,
	useEffect,
	useCallback,
	useLayoutEffect,
	useRef,
} from "react";
import { CoincraftPage } from "../../CoincraftPage";
import PdfStore, { columnDefs } from "./PdfStore";
import {
	formatCurrencyWithCents,
	formatPercentage0,
	formatNumber2,
	sum,
} from "../../utils.js";
import { PdfSidebar } from "./PdfSidebar";

export default observer((props) => {
	useEffect(() => {
		PdfStore.init(props.params.invoiceId);
	}, [props.params.invoiceId]);
	return (
		<CoincraftPage
			renderHeader={false}
			bodyClass="grey print-hidden"
			body={
				!PdfStore.ready ? (
					<div>Loading...</div>
				) : (
					<PaperPages invoiceId={props.params.invoiceId} />
				)
			}
			sidebar={PdfStore.ready ? <PdfSidebar /> : null}
			saveBar={
				PdfStore.ready &&
				PdfStore.saving &&
				(PdfStore.saving === "saving" ? (
					<div className="save-bar">
						<strong>
							<i className="fa fa-spinner fa-pulse fa-fw" />{" "}
							Saving pdf template...
						</strong>
					</div>
				) : (
					<div className="save-bar">
						<strong>Saved!</strong>
					</div>
				))
			}
		/>
	);
});

const PaperPages = observer(({ content, invoiceId }) => {
	const defaultTitle = useRef(document.title);
	useEffect(() => {
		document.title = PdfStore.invoice.description;
		return () => {
			document.title = defaultTitle.current;
		};
	}, []);
	const [numPages, setNumPages] = useState(1);
	const [pageContents, setPageContents] = useState([
		{
			showStart: true,
			showEnd: true,
			showTable: true,
			lineItems: [0, PdfStore.lineItems.length - 1],
			showTotals: true,
		},
	]);
	const addPageOverflow = useCallback(
		(index, content, overflowContent) => {
			if (
				(pageContents[index].showStart !== content.showStart ||
					pageContents[index].showEnd !== content.showEnd ||
					pageContents[index].showTable !== content.showTable ||
					pageContents[index].lineItems[0] !== content.lineItems[0] ||
					pageContents[index].lineItems[1] !== content.lineItems[1] ||
					pageContents[index].showTotals !== content.showTotals) &&
				content.lineItems[1] > 0
			) {
				let newPageContents = [...pageContents];
				newPageContents[index] = {
					...content,
					lineItems: [...content.lineItems],
				};
				if (
					overflowContent.showStart ||
					overflowContent.showEnd ||
					overflowContent.showTable
				)
					newPageContents[index + 1] = {
						...overflowContent,
						lineItems: [...overflowContent.lineItems],
					};
				setPageContents(newPageContents);
				setNumPages(newPageContents.length);
			}
		},
		[pageContents]
	);
	const pages = [...Array(numPages).keys()].map((i) => (
		<PaperPage
			key={i}
			index={i}
			content={pageContents[i]}
			addPageOverflow={addPageOverflow}
			invoiceSettings={PdfStore.invoiceSettings}
		/>
	));
	return <div style={{ textAlign: "center" }}>{pages}</div>;
});

const PaperPage = observer(
	({ content, index, addPageOverflow, invoiceSettings }) => {
		const pageRef = useRef();
		useEffect(() => {
			const pageEl = pageRef.current;
			let newContent = {
				...content,
				lineItems: [...content.lineItems],
			};
			const maxHeight = Math.ceil(
				parseFloat(getComputedStyle(pageEl).getPropertyValue("height"))
			);
			const removeInvoiceRow = () => {
				if (newContent.lineItems[1] === PdfStore.lineItems.length - 1) {
					for (let el of pageEl.getElementsByClassName(
						"invoice-table-totals"
					)) {
						el.style.display = "none";
					}
					newContent.showTotals = false;
				}
				const rows = [
					...pageEl.getElementsByClassName("invoice-table-row"),
				].filter((el) => el.style.display !== "none");
				if (rows.length) {
					rows[rows.length - 1].style.display = "none";
					newContent.lineItems[1]--;
					if (newContent.lineItems[1] === 0) {
						for (let el of pageEl.getElementsByClassName(
							"invoice-table"
						)) {
							el.style.display = "none";
						}
						newContent.showTable = false;
					}
				}
			};
			if (pageEl.scrollHeight > maxHeight) {
				for (let el of pageEl.getElementsByClassName("content-end")) {
					el.style.display = "none";
				}
				newContent.showEnd = false;
			}
			const prevScrollHeight = null;
			while (pageEl.scrollHeight > maxHeight) {
				removeInvoiceRow();
			}
			const overflowContent = {
				showStart: false,
				showEnd: !newContent.showEnd,
				showTable:
					newContent.lineItems[1] < PdfStore.lineItems.length - 1,
				lineItems: [
					Math.min(
						PdfStore.lineItems.length - 1,
						newContent.lineItems[1] + 1
					),
					PdfStore.lineItems.length - 1,
				],
				showTotals: true,
			};
			addPageOverflow(index, newContent, overflowContent);
		}, [content, index, invoiceSettings]);
		return (
			<div className="invoice-page">
				<div className="header">
					<div
						className="logo"
						style={{
							height: `${invoiceSettings.logoSize}em`,
						}}
					>
						<img
							src={invoiceSettings.logoUrl}
							alt="logo"
							style={{ height: "100%" }}
						/>
					</div>
					{PdfStore.projectText && (
						<div className="project-info">
							<span className="inv-bold">Project</span>{" "}
							{PdfStore.projectText}
						</div>
					)}
					<div className="client-info">
						<span className="inv-bold">Client</span>{" "}
						{PdfStore.clientText}
					</div>
					<div className="issue-date">
						<span className="inv-bold">
							{invoiceSettings.datePreference === "issueDate"
								? "Due Date"
								: "Issued"}
						</span>{" "}
						{PdfStore.invoice[
							invoiceSettings.datePreference === "issueDate"
								? "dueDate"
								: "issuedOn"
						].format("DD MMMM, YYYY")}
					</div>
					<div className="inv-highlight inv-number">
						<div className="label">Tax Invoice</div>
						<div className="value">
							{PdfStore.invoice.description}
						</div>
					</div>
					<div className="inv-highlight due-date">
						<div className="label">
							{invoiceSettings.datePreference === "issueDate"
								? "Issued"
								: "Due Date"}
						</div>
						<div className="value">
							{PdfStore.invoice[
								invoiceSettings.datePreference === "issueDate"
									? "issuedOn"
									: "dueDate"
							].format("DD MMMM, YYYY")}
						</div>
					</div>
					<div
						className="inv-highlight amount-due"
						style={{ textAlign: "right" }}
					>
						<div className="label">Amount Due</div>
						<div className="value">
							{"$" +
								formatCurrencyWithCents(
									PdfStore.totals.totalAmount
								)}
						</div>
					</div>
				</div>

				<div
					ref={pageRef}
					className="content"
					style={{
						flex: "1 1 auto",
						padding: "1em",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{content.showStart && invoiceSettings.contentStart && (
						<div
							className="content-start"
							style={{
								flex: "0 0 auto",
								lineHeight: "1.5em",
								marginBottom: "2em",
							}}
						>
							{invoiceSettings.contentStart}
						</div>
					)}
					<div
						className="content-mid"
						style={{
							flex: "1 1 auto",
						}}
					>
						{content.showTable && (
							<InvTable
								lineItems={PdfStore.lineItems.slice(
									content.lineItems[0],
									content.lineItems[1] + 1
								)}
								totals={PdfStore.totals}
								showTotals={content.showTotals}
							/>
						)}
					</div>
					{content.showEnd && invoiceSettings.contentEnd && (
						<div
							className="content-end"
							style={{
								flex: "0 0 auto",
								lineHeight: "1.5em",
							}}
						>
							{invoiceSettings.contentEnd}
						</div>
					)}
				</div>
				<div
					style={{
						flex: "0 0 auto",
						paddingTop: "1.25em",
						borderTop: "solid 2px #444",
						marginTop: "0.5em",
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div style={{ textAlign: "left", lineHeight: "1.5em" }}>
						{invoiceSettings.footerLeft}
					</div>
					<div style={{ textAlign: "right", lineHeight: "1.5em" }}>
						{invoiceSettings.footerRight}
					</div>
				</div>
			</div>
		);
	}
);

const InvTable = observer(({ lineItems, totals, showTotals }) => {
	let cells = [
		<div
			key={"h0"}
			className="invoice-table-header"
			style={{ display: "contents" }}
		>
			{PdfStore.columns.map((c, i) => {
				return (
					<div
						key={i}
						className={`header cell ${columnDefs[c].align}`}
					>
						{columnDefs[c].label}
					</div>
				);
			})}
		</div>,
	];
	lineItems.forEach((line, index) => {
		const lineClass = index !== lineItems.length - 1 ? "cell" : "cell last";
		cells.push(
			<div
				key={"r" + index}
				className="invoice-table-row"
				style={{ display: "contents" }}
			>
				{PdfStore.columns.map((c, i) => {
					return (
						<div
							key={index + i}
							className={`${lineClass} ${columnDefs[c].align}`}
						>
							{columnDefs[c].value(line)}
						</div>
					);
				})}
			</div>
		);
	});
	return (
		<>
			<div
				className="invoice-table"
				style={{
					gridTemplateColumns: PdfStore.columns
						.map((c) => `${columnDefs[c].width}fr`)
						.join(" "),
				}}
			>
				{cells}
			</div>
			<div
				className="invoice-table invoice-table-totals"
				style={{
					gridTemplateColumns: "3fr 1fr 1fr 1fr",
				}}
			>
				<div className="cell right total label">Sub total</div>
				<div className="cell right total amount">
					{"$" + formatCurrencyWithCents(totals.subTotal)}
				</div>
				<div className="cell right total label">
					{PdfStore.invoiceSettings.taxName}
				</div>
				<div className="cell right total amount">
					{"$" + formatCurrencyWithCents(totals.tax)}
				</div>
				<div className="cell right total label last">Amount Due</div>
				<div className="cell right total amount last">
					{"$" + formatCurrencyWithCents(totals.totalAmount)}
				</div>
			</div>
		</>
	);
});
