import {Functions} from "../Functions"

export default ({ funcName, args }, context) => {
	args = args.type === "array" ? args.value : [args]
	const type = args[0]?.type
	if (!Functions[funcName]) {
		throw {
			value: `Function ${funcName} does not exist`,
			type: "error",
		};
	}
	if (Functions[funcName] && !Functions[funcName][type]) {
		throw {
			value: `Cannot apply ${funcName} function to ${type} types.`,
			type: "error",
		};
	}
	return Functions[funcName][type](args, context);
};