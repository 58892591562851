import _ from 'underscore';
import moment from 'moment';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { dateRangeOptions, getDateRangeById, CustomDateRange } from './DateRanges.js';
import { BasicMySelect2, DateValue } from '../widgets/generic.js';
import { caseInsensitiveContains } from '../utils.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


export var DateRangeSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func,
    showAllTimeOption: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      showAllTimeOption: true
    };
  },

  render: function() {
    const {showAllTimeOption, onChange: _onChange, value, className, ...props} = this.props;
    const options = showAllTimeOption ? dateRangeOptions : dateRangeOptions.filter(dr => dr.id !== 'all_time');
    const [start, end] = value.getDates(moment());

    return <div className="date-range-selector inline-flexbox-container flex-align-items-center">
      <BasicMySelect2
        className={classNames("flex-0-0-auto date-range-selector__range-type-select", className)}
        options={options.map(function(dr) {
          return {
            label: dr.label,
            value: dr.id
          };
        })}
        filter={(dr, searchText) => caseInsensitiveContains(dr.label, searchText)}
        onChange={this.handleSelectChange}
        value={value.id}
        {...props}
      />

      {value.unit === 'fortnight' ?
        <span className="flex-0-0-auto right-item-m-1 flexbox-container flex-align-items-center btn-group">
          <button
              className={classNames("btn btn-default btn-sm", {active: value.fortnightType === 'a'})}
              onClick={() => this.props.onChange(this.props.value.setFortnightType('a'))}>
            A
          </button>
          <button
              className={classNames("btn btn-default btn-sm", {active: value.fortnightType === 'b'})}
              onClick={() => this.props.onChange(this.props.value.setFortnightType('b'))}>
            B
          </button>
        </span>
      : null}

      <span className="flex-0-0-auto right-item-m-2">
        {value.id === 'custom' ?
          <span>
            <DateValue
              className="date-range-selector__start-date"
              style={{display: 'inline-block', width: '9em'}}
              value={start}
              onChange={this.handleStartDateChange}
            />
            {' to '}
            <DateValue
              className="date-range-selector__end-date"
              style={{display: 'inline-block', width: '9em'}}
              value={end}
              onChange={this.handleEndDateChange}
            />
          </span>
        : start != null || end != null ?
          <span>
            {' ('}
            {start != null && end != null ? (
              start.isSame(end) ?
                <span>{start.format("DD/MM/YYYY")}</span>
              :
                <span>{start.format("DD/MM/YYYY")} to {end.format("DD/MM/YYYY")}</span>
            ) : null}
            {')'}
          </span>
        : null}
      </span>

    </div>;
  },

  handleSelectChange: function(dateRangeId) {
    let dateRange;
    if (dateRangeId === 'custom') {
      dateRange = new CustomDateRange(
        this.props.value.start || moment().startOf('month'),
        this.props.value.end || moment().endOf('month').startOf('day')
      );
    }
    else {
      dateRange = getDateRangeById(dateRangeId);
    }
    this.props.onChange(dateRange);
  },

  handleStartDateChange: function(date) {
    this.props.onChange(new CustomDateRange(date, this.props.value.end));
  },

  handleEndDateChange: function(date) {
    this.props.onChange(new CustomDateRange(this.props.value.start, date));
  },
});
