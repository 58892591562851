import { textMethods } from "./properties/text";

export let Methods = {
    text: textMethods
}

export const addMethods = (newMeths) => {
    Methods = {
        ...Methods,
        ...newMeths
	};
}