import { addProperties } from "./Properties";
import { addMethods } from "./Methods";
import _ from "lodash"
import {
	setGetHashObj,
	setGetAtObj,
	setGetContextObj,
} from "./ReferenceLookup";
import Formula from "./Formula";
import { addConstants } from "./Constants";
import { addFunctions } from "./Functions";

export const initParser = ({
	getHashObj,
	getAtObj,
	getContextObj,
	objectProps,
	constants,
	functions,
}) => {
	setGetHashObj(getHashObj);
	setGetAtObj(getAtObj);
	setGetContextObj(getContextObj);
	addProperties(_.mapValues(objectProps, (o) => o.propertyLookup));
	addMethods(_.mapValues(objectProps, (o) => o.methodLookup));
	addConstants(constants);
	addFunctions(functions);
	return Formula;
};