import React from 'react';
import CreateReactClass from 'create-react-class';


export function wrapLoading(f) {
  /**
   * Decorator to return a spinner if `data === 'loading'`.
   */
  return function(item, i, stack, data) {
    if (data == null || data === 'loading') {
      return <i className="fa fa-spinner fa-spin" style={{margin: 0}} />;
    }
    else {
      return f(item, i, stack, data);
    }
  };
}



