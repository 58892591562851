import _ from 'underscore';

export function step(name, calculation, args) {
  return {name, calculation, args};
};


export const Equation = class {
  constructor(steps) {
    this.steps = steps;
  }

  evaluate(values) {
    let result = {...values};
    for (let {name, calculation, args} of this.steps) {
      result[name] = calculation.evaluate(args.map(a => evaluateArg(a, result)));
    }
    return result;
  }

  display(values) {
    let lines = [];
    let result = {...values};
    for (let {name, calculation, args} of this.steps) {
      const evaluatedArgs = args.map(a => evaluateArg(a, result));
      result[name] = calculation.evaluate(evaluatedArgs);
      lines.push(`${name}: `);
      lines.push(` = ${calculation.display(args)}`);
      lines.push(` = ${calculation.display(evaluatedArgs)} = ${result[name]}`);
    }
    return lines.join("\n");
  }
}

function evaluateArg(arg, context) {
  if (_.isString(arg)) {
    return context[arg];
  }
  else {
    return arg;
  }
}

export const add = {
  evaluate: ([a, b]) => a + b,
  display: ([a, b]) => `${a} + ${b}`
};

export const subtract = {
  evaluate: ([a, b]) => a - b,
  display: ([a, b]) => `${a} - ${b}`
};

export const multiply = {
  evaluate: ([a, b]) => a * b,
  display: ([a, b]) => `${a} * ${b}`
};

export const divide = {
  evaluate: ([a, b]) => a / b,
  display: ([a, b]) => `${a} / ${b}`
};

