import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions, store } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { SaveButton } from '../widgets.js';
import { ProjectExpenses } from '../project/ProjectExpenses.js';


export var ProjectExpensesForm = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([store], function() {
      return {
        expenses: store.editProjectExpenses
      };
    })
  ],

  render: function() {
    return <div>
      <div>
        <ProjectExpenses
          expenses={this.state.expenses}
          isEditable={true}
          onChange={this.handleExpensesChange}
        />
      </div>

      {/* Project Expense floats are a bitch to deal with */}
      <div style={{height: '1em', clear: 'both'}}></div>
      <div className="modal-footer" style={{clear: 'both', marginTop: '1em'}}>
        <button
            className="btn btn-default"
            onClick={this.handleCancelClick}>
          Cancel
        </button>
        <SaveButton
          text="Save Expenses"
          onClick={this.handleSaveButtonClick}
        />
      </div>
    </div>;
  },

  handleExpensesChange: function(expenses) {
    actions.projectSidebarSetProjectExpenses(expenses);
  },

  handleCancelClick: function() {
    actions.closeModal(this.props.modal);
  },

  handleSaveButtonClick: function() {
    actions.projectSidebarSaveExpenses(this.props.modal);
  }
});
