import React from "react";
import CreateReactClass from "create-react-class";
import _ from "underscore";
import moment from "moment";
import Immutable from "immutable";
import { Spreadsheet, Cell } from "./Spreadsheet.js";
import { organisationStore } from "../organisation.js";
import { dateConverter } from "../models.js";
import { sum, LinearScale, caseInsensitiveContains } from "../utils.js";
import {
	DeleteButton,
	BasicMySelect2,
	SaveBar,
	MySelect2,
	Dropdown3,
	Dropdown3ListItem,
	Checkbox,
} from "../widgets.js";
import { CoincraftPage, CoincraftPageHeader } from "../CoincraftPage.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { CashFlowChart, SuperimposedDataRenderer } from "../cashFlowChart.js";
import { userStore } from "../user/flux.js";
import { spreadsheetStore, actions } from "./SpreadsheetStore.js";
import { ProjectHistoryMenu } from "./ProjectHistoryMenu.js";
import classNames from "classnames";
import {
	MultiCostCentreSelect,
	MultiStaffMemberSelect,
	MultiProjectSelect,
	ProjectStatusSelect,
	MultiExpenseSelect,
} from "../widgets/coincraft.js";
import { ReportNameModal } from "../reports/ReportNameModal.js";
import { Modal, ModalContent } from "../modal.js";
import { IncompleteSetupPage } from "../IncompleteSetupPage.js";
import { permissions, PermissionLevel } from "../models/permissions.js";
import { getOnboardingData } from "../organisationStore.js";

import elementResizeDetectorMaker from "element-resize-detector";
import { FilterTextBox } from "../widgets/generic.js";
import PropTypes from "prop-types";
import { ConfirmSyncModal } from "../project/ConfirmSyncModal.js";
import { AutoAdjustRevenueModal } from "./AutoAdjustRevenueModal.js";
import { settingsStore } from "../settings/settingsStore.js";
const erd = elementResizeDetectorMaker();

export var NewSpreadsheetPage = CreateReactClass({
	propTypes: {
		spreadsheetType: PropTypes.oneOf(["revenue", "allocation"]).isRequired,
	},

	mixins: [
		makeMultipleStoreMixin(
			[spreadsheetStore, organisationStore, userStore],
			function () {
				if (!spreadsheetStore.isReady) {
					return { isReady: false };
				}

				return {
					isReady: spreadsheetStore.isReady,
					currentMonthIndex: spreadsheetStore.currentMonthIndex,
					startMonth: spreadsheetStore.startMonth,
					endMonth: spreadsheetStore.endMonth,
					dateColumns: spreadsheetStore.dateColumns,

					profitRows: spreadsheetStore.profitSpreadsheetRows,
					projectRows: spreadsheetStore.projectSpreadsheetRows,
					projectData: spreadsheetStore.projectData,
					staffRows: spreadsheetStore.staffSpreadsheetRows,
					expenseRows: spreadsheetStore.expenseSpreadsheetRows,
					graphData: spreadsheetStore.graphData,

					dataType: spreadsheetStore.dataType,
					profitDataType: spreadsheetStore.profitDataType,
					invoiceDateType: spreadsheetStore.invoiceDateType,
					totalType: spreadsheetStore.totalType,
					contractorExpense: spreadsheetStore.contractorExpense,
					showExpenses: spreadsheetStore.showExpenses,
					isDirty: spreadsheetStore.isDirty,
					saveState: spreadsheetStore.projectSaveState,
					filters: spreadsheetStore.filters,
					showFilters: spreadsheetStore.showFilters,
					showSidebar: spreadsheetStore.showSidebar,
					selectedCell: spreadsheetStore.selectedCell,
					columns: spreadsheetStore.getColumns(),

					selectedReport: spreadsheetStore.selectedReport,
					defaultReport: spreadsheetStore.defaultReport,
					reports: spreadsheetStore.reports,
					reportName: spreadsheetStore.reportName,
					modal: spreadsheetStore.modal,
					isPrinting: spreadsheetStore.isPrinting,

					user: userStore.user,
					organisationStore: organisationStore,

					onboardingData: getOnboardingData(
						organisationStore,
						permissions,
						userStore
					),
				};
			}
		),
	],

	componentWillMount: function () {
		spreadsheetStore.initialize();
		if (settingsStore.settings.autoUpdateRevenue.adjustOnLoad)
			spreadsheetStore.autoAdjustProjects();
	},

	shiftDate: function (direction) {
		if (direction === "right") {
			actions.moveRight();
		} else if (direction === "left") {
			actions.moveLeft();
		}
	},

	componentDidMount: function () {
		this.setupResizeHandler();
	},

	setupResizeHandler() {
		let el = this.refs.body;
		if (el) {
			erd.listenTo(el, this.handleResize);
			this.handleResize(el);
		}
	},

	handleResize: function (element) {
		this.setState({
			hasSize: true,
			offsetWidth: element.offsetWidth,
			contentOffsetWidth: (element.offsetWidth * 72) / 100,
			offsetHeight: element.offsetHeight,
		});
	},

	render: function () {
		if (!this.state.onboardingData.hasRevenue) {
			return (
				<IncompleteSetupPage
					heading={"Revenue Forecast"}
					onboardingData={this.state.onboardingData}
				/>
			);
		}
		let self = this;
		return (
			<CoincraftPage
				header={this.renderHeader()}
				body={
					<div
						ref="body"
						className={
							this.state.isPrinting ? "print-a4-landscape" : ""
						}
					>
						{this.renderModals()}
						<CashFlowChartContainer
							contentOffsetWidth={this.state.contentOffsetWidth}
							graphData={this.state.graphData}
							startMonth={this.state.graphData[0].monthIndex}
							yAxisWidth={"28%"}
							contentWidth={"72%"}
							yValueType={"dollars"}
						/>
						<Spreadsheet
							columns={this.state.columns}
							rows={this.state.profitRows}
							cellComponent={RevenueCell}
						/>
						<h1>Projects</h1>
						<Spreadsheet
							columns={this.state.columns}
							rows={this.state.projectRows}
							cellComponent={RevenueCell}
							onCommitCell={function (cell, inputText) {
								return new Promise(function (resolve, reject) {
									actions.commitProjectInputText(
										cell,
										inputText
									);
									resolve();
								});
							}}
							onRollbackCell={function (cell) {
								return new Promise(function (resolve, reject) {
									actions.rollbackProjectInputText(cell);
									resolve();
								});
							}}
							onSetInputText={function (cell, inputText) {
								return new Promise(function (resolve, reject) {
									actions.setProjectInputText(
										cell,
										inputText
									);
									resolve();
								});
							}}
							edgeKeyPress={this.shiftDate}
							selectCell={(cell) => actions.selectCell(cell)}
						/>
						{userStore.user.permissions.isAtLeastLimitedAdmin &&
						userStore.user.permissions.financialsVisibility ===
							"all" ? (
							<div>
								<h1>Staff</h1>
								<Spreadsheet
									columns={this.state.columns}
									rows={this.state.staffRows}
									cellComponent={RevenueCell}
									onCommitCell={function (cell, inputText) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.commitStaffInputText(
												cell,
												inputText
											);
											resolve();
										});
									}}
									onRollbackCell={function (cell) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.rollbackStaffInputText(
												cell
											);
											resolve();
										});
									}}
									onSetInputText={function (cell, inputText) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.setStaffInputText(
												cell,
												inputText
											);
											resolve();
										});
									}}
									edgeKeyPress={this.shiftDate}
								/>
								<h1>Expenses</h1>
								<Spreadsheet
									columns={this.state.columns}
									rows={this.state.expenseRows}
									cellComponent={RevenueCell}
									onCommitCell={function (cell, inputText) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.commitExpenseInputText(
												cell,
												inputText
											);
											resolve();
										});
									}}
									onRollbackCell={function (cell) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.rollbackExpenseInputText(
												cell
											);
											resolve();
										});
									}}
									onSetInputText={function (cell, inputText) {
										return new Promise(function (
											resolve,
											reject
										) {
											actions.setExpenseInputText(
												cell,
												inputText
											);
											resolve();
										});
									}}
									edgeKeyPress={this.shiftDate}
								/>
							</div>
						) : null}
					</div>
				}
				sidebar={
					<div>
						{this.state.showSidebar && this.state.selectedCell ? (
							<div
								className="context-menu"
								style={{ minWidth: "25em" }}
							>
								<ProjectHistoryMenu
									user={this.state.user}
									project={this.state.projectData.get(
										this.state.selectedCell.projectUuid
									)}
								/>
							</div>
						) : null}
					</div>
				}
				saveBar={
					this.state.saveState === "failed" ? (
						<SaveBar
							isDirty={this.state.isDirty}
							saveState={this.state.saveState}
							onSaveClick={() => actions.saveAll()}
							unsavedChangesMessage={
								"There was an issue saving your forecast. Please contact us if the problem persists."
							}
							buttonText={"Try Again"}
							color={"#dc2d11"}
						/>
					) : this.state.isDirty || this.state.saveState != null ? (
						<SaveBar
							isDirty={this.state.isDirty}
							saveState={this.state.saveState}
							onSaveClick={() => actions.saveAll()}
							unsavedChangesMessage={
								"Save changes to your forecast? "
							}
						/>
					) : null
				}
			/>
		);
	},

	renderHeader: function () {
		return (
			<div>
				<CoincraftPageHeader
					heading={"Revenue Forecast"}
					subHeading={`${_.first(this.state.dateColumns).value} - ${
						_.last(this.state.dateColumns).value
					}`}
					filter={
						false /*this.props.hasFilter*/ ? (
							<FilterTextBox
								style={{ width: "19em" }}
								value={this.props.filterText}
								onChange={this.handleFilterTextChange}
								placeholder={this.props.filterInputPlaceholder}
							/>
						) : null
					}
					rightButtons={
						<div style={{ display: "inline-block" }}>
							{this.props.extraRightButtons}
							{userStore.user.permissions
								.isAtLeastLimitedAdmin ? (
								<button
									className="export-button page-header__button"
									onClick={() =>
										actions.openAutoAdjustModal()
									}
								>
									<i className="fa fa-magic" />
									Auto Adjust
								</button>
							) : null}
							<button
								className="export-button page-header__button"
								onClick={() => actions.exportSpreadsheet()}
							>
								<i className="fa fa-table" />
								Export
							</button>
							<button
								className="print-button page-header__button"
								onClick={() => {
									actions.printSpreadsheet(true);
									setTimeout(() => {
										window.print();
										actions.printSpreadsheet(false);
									}, 100);
								}}
							>
								<i className="fa fa-print" />
								Print
							</button>
						</div>
					}
					extraContent={
						<div className="report-selector">
							{"Reports: "}
							<BasicMySelect2
								style={{
									marginLeft: "0.5em",
									maxWidth: "15em",
								}}
								value={this.state.selectedReport}
								onChange={(reportUuid) =>
									actions.changeSelectedReport(reportUuid)
								}
								options={this.state.reports.map((r) => ({
									label: `${r.name}${
										this.state.defaultReport === r.uuid
											? " (default)"
											: ""
									}`,
									value: r.uuid,
								}))}
								placeholder={"Select Report..."}
								filter={function (report, searchText) {
									return caseInsensitiveContains(
										report.label,
										searchText
									);
								}}
							/>
							{this.renderReportButtons()}
						</div>
					}
				/>
				{this.state.showFilters ? this.renderReportFilters() : null}
			</div>
		);
	},

	renderModals: function () {
		if (this.state.modal === "saveReport") {
			return (
				<ReportNameModal
					modal={"ReportNameModal"}
					header="Save Report As..."
					initialName={this.state.reportName}
					onSave={(reportName) => actions.saveReport(reportName)}
					actions={actions}
				/>
			);
		} else if (this.state.modal === "rename") {
			return (
				<ReportNameModal
					modal={"RenameReportModal"}
					header="Rename Report"
					initialName={this.state.reportName}
					onSave={(reportName) => actions.saveReport(reportName)}
					actions={actions}
				/>
			);
		} else if (this.state.modal === "delete") {
			return (
				<Modal
					closeOnClickOutside={true}
					onClosed={() => actions.closeModal()}
				>
					<ModalContent header="Delete report" width="35em">
						<p style={{ padding: "2em" }}>
							Are you sure you want to delete this report?
						</p>
						<div
							style={{
								padding: "1em",
								textAlign: "right",
								borderTop: "1px solid #ccc",
							}}
						>
							<a
								href="javascript:void(0)"
								onClick={() => actions.closeModal()}
							>
								Cancel
							</a>
							<DeleteButton
								text="Delete report"
								onClick={() => actions.deleteReport()}
								style={{ marginLeft: "2em" }}
							/>
						</div>
					</ModalContent>
				</Modal>
			);
		} else if (this.state.modal === "autoAdjust") {
			return (
				<AutoAdjustRevenueModal
					onConfirm={() => {
						actions.autoAdjustProjects();
						actions.closeModal();
					}}
					onCancel={() => actions.closeModal()}
				/>
			);
		}
	},

	renderReportButtons: function () {
		return (
			<div className="flex-1-1-auto flexbox-container">
				{this.state.selectedReport ? (
					<button
						onClick={() => actions.changeSelectedReport(null)}
						style={{ padding: "0.25em 0.5em" }}
					>
						x
					</button>
				) : null}
				<button onClick={() => actions.toggleFilters()}>
					{!this.state.showFilters ? "Show Filters" : "Hide Filters"}
				</button>
				{this.state.showFilters ? (
					<div style={{ marginTop: "0.25em" }}>
						{this.state.selectedReport ? (
							<ReportActionsDropdown />
						) : null}
						<ReportSaveDropdown />
					</div>
				) : null}
			</div>
		);
	},

	renderReportFilters: function () {
		return (
			<div
				className="report-selector"
				style={{
					width: "100%",
					marginTop: "1em",
					display: "block",
					padding: "1em 0",
					borderTop: "solid 1px #666",
				}}
			>
				<div style={{ padding: "1em 0.5em" }}>
					{"Revenue Data: "}
					<BasicMySelect2
						style={{ margin: "0 0.5em" }}
						value={this.state.dataType}
						onChange={(dataType) => actions.setDataType(dataType)}
						options={[
							{
								label: "Actuals + Projected",
								value: "actualsProjected",
							},
							{ label: "Actuals", value: "actuals" },
							{ label: "Projected", value: "projected" },
						]}
					/>
					{"Profit %: "}
					<BasicMySelect2
						style={{ margin: "0 0.5em" }}
						value={this.state.profitDataType}
						onChange={(dataType) =>
							actions.setProfitDataType(dataType)
						}
						options={[
							{
								label: "Profit Margin",
								value: "margin",
							},
							{ label: "Markup", value: "markup" },
						]}
					/>
					{"Invoice: "}
					<BasicMySelect2
						style={{ margin: "0 0.5em" }}
						value={this.state.invoiceDateType}
						onChange={(invoiceDateType) =>
							actions.setInvoiceDateType(invoiceDateType)
						}
						options={[
							{ label: "Work Completion", value: "endDate" },
							{ label: "Issue Date", value: "issuedOn" },
							{ label: "Due Date", value: "dueDate" },
						]}
					/>
					{"Totals: "}
					<BasicMySelect2
						style={{ margin: "0 0.5em" }}
						value={this.state.totalType}
						onChange={(totalType) =>
							actions.setTotalType(totalType)
						}
						options={[
							{
								label: "Revenue / Total Fee",
								value: "revenueTotalFee",
							},
							{ label: "Remaining Fee", value: "remaining" },
						]}
					/>
					{"Contractor Expense: "}
					<BasicMySelect2
						style={{ margin: "0 0.5em" }}
						value={this.state.contractorExpense}
						onChange={(contractorExpense) =>
							actions.setContractorExpense(contractorExpense)
						}
						options={[
							{
								label: "Available Hours",
								value: "availableHours",
							},
							{
								label: "Allocated Hours",
								value: "allocatedHours",
							},
						]}
					/>
					<Checkbox
						value={this.state.showExpenses}
						onChange={(showExpenses) => {
							actions.toggleShowExpenses(showExpenses);
						}}
					/>
					{" Show project expenses."}
				</div>
				<div
					className="flexbox-container"
					style={{ width: "100%", fontSize: "0.95em" }}
				>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Cost Centres:
						<MultiCostCentreSelect
							value={this.state.filters.costCentres}
							onChange={(costCentres) =>
								actions.setFilteredCostCenters(costCentres)
							}
							onBlur={() => null}
						/>
					</div>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Project Status:
						<ProjectStatusSelect
							value={this.state.filters.projectStatus}
							onChange={(projectStatuses) =>
								actions.setFilteredProjectStatus(
									projectStatuses.map((ps) => ps.id)
								)
							}
							onBlur={() => null}
						/>
					</div>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Projects:
						<MultiProjectSelect
							value={this.state.filters.projects}
							onChange={(projects) =>
								actions.setFilteredProjects(projects)
							}
							onBlur={() => null}
						/>
					</div>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Staff:
						<MultiStaffMemberSelect
							value={this.state.filters.staff}
							onChange={(staff) =>
								actions.setFilteredStaff(staff)
							}
							onBlur={() => null}
						/>
					</div>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Project Owner:
						<MultiStaffMemberSelect
							value={this.state.filters.projectOwners}
							onChange={(staff) =>
								actions.setFilteredProjectOwners(staff)
							}
							onBlur={() => null}
						/>
					</div>
					<div style={{ width: "20%", padding: "0.5em" }}>
						Expenses:
						<MultiExpenseSelect
							value={this.state.filters.expenses}
							onChange={(expenses) =>
								actions.setFilteredExpenses(expenses)
							}
							onBlur={() => null}
						/>
					</div>
				</div>
			</div>
		);
	},
});

class RevenueCell extends React.Component {
	shouldComponentUpdate(nextProps) {
		if (
			this.props.isEditing != nextProps.isEditing ||
			this.props.isSelected != nextProps.isSelected ||
			this.props.cell.inputText != nextProps.cell.inputText ||
			this.props.cell.value != nextProps.cell.value ||
			this.props.column.monthIndex != nextProps.column.monthIndex ||
			this.props.cell.visible != nextProps.cell.visible ||
			this.props.cell.expanded != nextProps.cell.expanded ||
			this.props.cell.inRange != nextProps.cell.inRange
		) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		const currentMonthIndex = dateConverter.getMonthIndex(
			dateConverter.momentToInt(moment())
		);
		const isRowHeader = this.props.cell.isRowHeader;
		const cellType = this.props.cell.cellType;
		const cell = this.props.cell;
		const isColumnHeader = this.props.cell.isColumnHeader;
		const value = this.props.cell.value;
		const numValue = this.props.cell.numValue;
		const isEditable = !(
			this.props.cell.isEditable === false ||
			isRowHeader ||
			isColumnHeader
		);

		let displayValue;
		if (this.props.isEditing) {
			displayValue = this.props.cell.inputText;
		} else {
			if (value != null && value.toFixed != null) {
				displayValue = value.toFixed(0);
			} else if (cellType === "projectTitle") {
				displayValue = (
					<span>
						<i
							className={`fa fa-eye${
								!cell.visible ? "-slash" : ""
							}`}
							onClick={() =>
								actions.toggleProjectVisibility(
									cell.projectUuid
								)
							}
						/>
						<i
							className={`fa fa-chevron${
								!cell.expanded ? "-right" : "-down"
							}`}
							onClick={() =>
								actions.toggleProjectExpand(cell.projectUuid)
							}
						/>
						{/*<span>{p.likelihood}</span>*/}
						<span>{value}</span>
					</span>
				);
			} else if (cellType === "projectPhaseTitle") {
				displayValue = (
					<span>
						<i
							className={`fa fa-eye${
								!cell.visible ? "-slash" : ""
							}`}
							onClick={() =>
								actions.toggleProjectPhaseVisibility(
									cell.projectPhaseUuid
								)
							}
						/>
						<span>{value}</span>
					</span>
				);
			} else if (cellType === "staffTitle") {
				displayValue = (
					<span>
						<i
							className={`fa fa-eye${
								!cell.visible ? "-slash" : ""
							}`}
							onClick={() =>
								actions.toggleStaffVisibility(cell.staffUuid)
							}
						/>
						<span>{value}</span>
					</span>
				);
			} else if (cellType === "expenseTitle") {
				displayValue = (
					<span>
						<i
							className={`fa fa-eye${
								!cell.visible ? "-slash" : ""
							}`}
							onClick={() =>
								actions.toggleExpenseVisibility(
									cell.expenseName
								)
							}
						/>
						<span>{value}</span>
					</span>
				);
			} else {
				displayValue = value;
			}
		}
		return (
			<Cell
				ref="input"
				{...this.props}
				value={displayValue}
				cellProps={{
					className: classNames({
						editable: isEditable,
						rowheader: isRowHeader,
						columnheader: isColumnHeader,
						pasttime:
							this.props.column.monthIndex &&
							this.props.column.monthIndex < currentMonthIndex,
						currenttime:
							this.props.column.monthIndex &&
							this.props.column.monthIndex === currentMonthIndex,
						futuretime:
							this.props.column.monthIndex &&
							this.props.column.monthIndex > currentMonthIndex,
						negative: numValue < 0 || this.props.cell.error,
						neutral: numValue === 0,
						positive: numValue > 0,
						notvisible: this.props.cell.visible === false,
						project: this.props.cell.isProject,
						inrange: this.props.cell.inRange,
					}),
				}}
			/>
		);
	}

	get input() {
		return this.refs.input.input;
	}
}

var CashFlowChartContainer = CreateReactClass({
	propTypes: {
		contentOffsetWidth: PropTypes.number.isRequired,
		graphData: PropTypes.array.isRequired,
		startMonth: PropTypes.number.isRequired,
		yAxisWidth: PropTypes.string.isRequired, // percent-string
		contentWidth: PropTypes.string.isRequired, // percent-string
		yValueType: PropTypes.oneOf(["dollars", "hours"]).isRequired,
	},

	// shouldComponentUpdate: function(nextProps) {
	//   //TODO needs to actually be aware of cell values for when visibilty changes
	//   return (this.props.contentOffsetWidth !== nextProps.contentOffsetWidth
	//           || this.props.startMonth !== nextProps.startMonth);
	// },

	render: function () {
		const data = this.props.graphData;
		const minX = data[0].date;
		const maxX = _.last(data).date + 31; //TODO-next_scheduler_fiddling

		const xScale = new LinearScale({
			minX: data[0].date,
			maxX: data[0].date + 365,
			minY: 0,
			maxY: this.props.contentOffsetWidth,
		});

		return (
			<CashFlowChart
				data={this.props.graphData}
				paddingBottom="20%"
				showLegend={false}
				renderer={new SuperimposedDataRenderer()}
				enableHoverTooltips={false}
				min={minX}
				max={maxX}
				viewportScrollLeft={dateConverter.monthIndexToOffset(
					this.props.startMonth
				)}
				manageOwnScrolling={false}
				showXAxis={false}
				showScrollbar={false}
				onScroll={this.handleCashFlowGraphScroll}
				xScale={xScale}
				yValueType={this.props.yValueType}
				padding={{
					top: "0%",
					left: this.props.yAxisWidth,
					bottom: "0%",
					right: "0%",
				}}
			/>
		);
	},
});

var ReportActionsDropdown = CreateReactClass({
	propTypes: {
		report: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
	},

	render: function () {
		return (
			<Dropdown3
				isExpanded={this.props.isExpanded}
				onToggle={this.handleToggle}
				toggleElement={
					<button className="primary" style={{ margin: "0.25em" }}>
						Actions
						<i
							className="rw-i rw-i-caret-down"
							style={{ marginLeft: "0.5em" }}
						/>
					</button>
				}
				contentStyle={{
					width: "18em",
					color: "#444",
					borderRadius: "0.5em",
				}}
			>
				<Dropdown3ListItem
					style={{ padding: "0.5em 1em", height: "2.5em" }}
					onClick={() => actions.setDefaultReport()}
				>
					Set As Default
				</Dropdown3ListItem>
				<Dropdown3ListItem
					style={{ padding: "0.5em 1em", height: "2.5em" }}
					onClick={() => actions.clickRenameReportButton()}
				>
					Rename
				</Dropdown3ListItem>
				<Dropdown3ListItem
					style={{ padding: "0.5em 1em", height: "2.5em" }}
					onClick={() => actions.clickDeleteReportButton()}
				>
					Delete
				</Dropdown3ListItem>
			</Dropdown3>
		);
	},

	handleToggle: function (isExpanded) {
		this.props.actions.setActionsMenuExpanded(isExpanded);
	},
});

var ReportSaveDropdown = CreateReactClass({
	propTypes: {
		report: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
	},

	render: function () {
		return (
			<Dropdown3
				className="report-save-dropdown"
				isExpanded={this.props.isExpanded}
				onToggle={this.handleToggle}
				toggleElement={
					<button className="primary" style={{ margin: "0.25em" }}>
						Save
						<i
							className="rw-i rw-i-caret-down"
							style={{ marginLeft: "0.5em" }}
						/>
					</button>
				}
				contentStyle={{
					width: "14em",
					color: "#444",
					borderRadius: "0.5em",
				}}
			>
				<Dropdown3ListItem
					style={{ padding: "0.5em 1em", height: "2.5em" }}
					className="report-save-dropdown__save-option"
					onClick={() => actions.clickSaveReportButton()}
				>
					Save
				</Dropdown3ListItem>
				<Dropdown3ListItem
					style={{ padding: "0.5em 1em", height: "2.5em" }}
					onClick={() => actions.clickSaveAsReportButton()}
				>
					Save As...
				</Dropdown3ListItem>
			</Dropdown3>
		);
	},

	handleToggle: function (isExpanded) {
		this.props.actions.setSaveMenuExpanded(isExpanded);
	},
});
