import React, { useRef, useEffect, useCallback, useState } from "react";
import { observer } from "mobx-react";
import useMouse from "@react-hook/mouse-position";
import {
	format,
	startOfWeek,
	endOfWeek,
	addDays,
	addWeeks,
	subWeeks,
	differenceInDays,
	isSameMonth,
} from "date-fns";
// import DatePicker from "react-calendar";
import calendarState from "./calendarState";
import "react-calendar/dist/Calendar.css";
import { observe } from "mobx";
import timeStore from "./timeStore";
import allocationStore from "./allocationStore";
import { useGesture } from "react-use-gesture";
import { StaffMemberSelector } from "../widgets/coincraft";
import { BasicMySelect2 } from "../widgets/generic";
import { getProjectOptions } from "../widgets/ProjectSelector";
import { PermissionLevel } from "../models/permissions";
import _ from "underscore";
import { projectStatusOptions } from "../models/project";
import { camelCaseToSentence } from "../utils";
import { getProjectPhases } from "../widgets/ProjectPhaseSelector";
import { organisationStore } from "../organisation";
import { userStore } from "../user/flux";
import { Select, Modal, Button, Space } from "antd";
import DatePicker from "./DatePicker";
import { permissions } from "../models/permissions.js";
import { mode } from "mathjs";

const { Option, OptGroup } = Select;

export const Calendar = observer(({ mode = "time" }) => {
	const settings = organisationStore.organisation.settings;
	const [showCal, setShowCal] = useState(false);
	const ref = React.useRef();
	const mouse = useMouse(ref, {
		enterDelay: 100,
		leaveDelay: 100,
	});
	const calDrag = useGesture({
		onDragStart: (state) => {
			calendarState.deselectItem();
			calendarState.setCalendarMouseData(
				mouse.x,
				mouse.y,
				mouse.elementWidth,
				mouse.elementHeight
			);
		},
		onDrag: (state) => {
			if (!calendarState.newItem) {
				if (
					state.movement[1] / (calendarState.calendarMinuteEms * 10) >
					7.5
				) {
					calendarState.createNewItem();
				}
			} else {
				const numMinutes =
					calendarState.calendarMouseFloatMinutes -
					calendarState.newItem.startMinutes;
				calendarState.updateNewItem({
					numMinutes,
				});
			}
		},
		onDragEnd: (state) => {
			if (calendarState.newItem) {
				let numMinutes =
					calendarState.calendarMouseFloatMinutes -
					calendarState.newItem.startMinutes;
				numMinutes = Math.round(numMinutes / 15) * 15;
				calendarState.commitNewItem({
					numMinutes,
				});
			}
		},
	});
	useEffect(() => {
		// scroll calendar to 7:30am
		calendarState.init(mode);
		ref.current.parentNode.scrollTop =
			calendarState.calendarMinuteEms * 7.5 * 60 * 10;
	}, []);
	const weekEndSameMonth = isSameMonth(
		calendarState.weekStart,
		calendarState.weekEnd
	);
	return (
		<div className="calendar" style={{ fontSize: "1em" }}>
			<div className="not-calendar" style={{ textAlign: "center" }}>
				<h3>{`${
					mode === "time" ? "Time Entry Calendar" : "Weekly Planning"
				}`}</h3>
				<h1>
					{`${format(
						calendarState.weekStart,
						weekEndSameMonth ? "dd" : "dd MMM"
					)}${weekEndSameMonth ? "-" : " - "}${format(
						calendarState.weekEnd,
						weekEndSameMonth ? "dd MMMM yyyy" : "dd MMM yyyy"
					)}`}
				</h1>
				<Button
					onClick={(e) =>
						calendarState.setWeekStart(
							subWeeks(calendarState.weekStart, 1)
						)
					}
				>
					{"<"}
				</Button>
				<DatePicker
					onChange={(d, ds) => d && calendarState.setWeekStart(d)}
					picker="week"
					value={calendarState.weekStart}
					format={(date) => format(date, "'#'ww / yyyy")}
				/>
				<Button
					onClick={(e) =>
						calendarState.setWeekStart(
							addWeeks(calendarState.weekStart, 1)
						)
					}
				>
					{">"}
				</Button>
			</div>
			{userStore.user.permissions.isAtLeastLimitedAdmin && (
				<div
					className="not-calendar"
					style={{
						paddingLeft: "4em",
						marginTop: "-em",
						paddingBottom: "1em",
					}}
				>
					<Select
						showSearch
						optionFilterProp="children"
						value={
							calendarState.selectedValues.staffMemberId ||
							userStore.user.id
						}
						onChange={(staffMemberId) => {
							calendarState.updateSelectedStaffId(staffMemberId);
						}}
						style={{ zIndex: 100, width: "20%" }}
					>
						{organisationStore.getVisibleStaff().map((staff) => {
							return (
								<Option key={staff.id} value={staff.id}>
									{`${staff.firstName} ${staff.lastName}`}
								</Option>
							);
						})}
					</Select>
				</div>
			)}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: `${window.innerHeight - 150}px`,
				}}
			>
				<div style={{ display: "flex", flex: "0 0 auto" }}>
					<div
						style={{
							flex: "0 0 auto",
							height: `${calendarState.calendarMinuteEms * 55}em`,
							width: "4em",
						}}
					/>
					<div
						ref={ref}
						style={{
							flex: "1 1 auto",
							height: `${calendarState.calendarMinuteEms * 55}em`,
							background: mode === "time" ? "#f8f8f8" : "#666",
							borderRadius: "1em 1em 0 0",
							display: "flex",
							borderBottom: "solid 1px #ddd",
						}}
					>
						{calendarState.weekDays.map((day, i) => {
							return (
								<div
									style={{
										width: `${100 / 7}%`,
										height: "100%",
										textAlign: "center",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: [5, 6].includes(i)
											? mode === "time"
												? "#888"
												: "#ccc"
											: mode === "time"
											? "#444"
											: "#fff",
									}}
								>
									<div>
										<div
											style={{
												fontSize: "1.75em",
												marginTop: "0.25em",
												fontWeight: "600",
											}}
										>
											{`${format(day, "dd")}`}
										</div>
										<div
											style={{
												textTransform: "uppercase",
												fontSize: "0.8em",
												marginTop: "0.25em",
												fontWeight: 400,
											}}
										>
											{`${format(day, "EEEE")}`}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div style={{ display: "flex", flex: "0 0 auto" }}>
					<div
						style={{
							flex: "0 0 auto",
							height: `${calendarState.calendarMinuteEms * 20}em`,
							width: "4em",
						}}
					/>
					<div
						ref={ref}
						style={{
							flex: "1 1 auto",
							height: `${calendarState.calendarMinuteEms * 20}em`,
							background: "#f8f8f8",
							display: "flex",
							borderBottom: "solid 1px #ddd",
						}}
					>
						{calendarState.weekDays.map((day, i) => {
							return (
								<div
									style={{
										width: `${100 / 7}%`,
										height: "100%",
										textAlign: "center",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: [5, 6].includes(i)
											? "#888"
											: "#444",
									}}
								>
									<div>
										<div
											style={{
												fontSize: "1.1em",
												fontWeight: 800,
											}}
										>
											{`${
												calendarState.dayTotals[i].hours
											}:${
												(calendarState.dayTotals[i]
													.minutes < 10
													? "0"
													: "") +
												calendarState.dayTotals[i]
													.minutes
											}`}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flex: "1 1 auto",
						overflowY: "auto",
					}}
				>
					<svg
						style={{
							flex: "0 0 auto",
							height: `${
								calendarState.calendarMinuteEms * 60 * 24
							}em`,
							width: "4em",
							background: "transparent",
						}}
					>
						{[...Array(24 - 1).keys()].map((d) => {
							const h24 = d + 1;
							const h12 = h24 > 12 ? h24 - 12 : h24;
							const ampm = h24 >= 12 ? "pm" : "am";
							return (
								<text
									style={{
										fontSize: "1em",
										fill: "#888",
									}}
									x={`85%`}
									y={`${
										(d + 1) *
											calendarState.calendarMinuteEms *
											60 +
										0.75
									}em`}
									textAnchor={`end`}
								>{`${h12} ${ampm}`}</text>
							);
						})}
					</svg>
					<div
						style={{
							flex: "1 1 auto",
							height: `${
								calendarState.calendarMinuteEms * 60 * 24
							}em`,
						}}
						ref={ref}
						onPointerMove={(e) => {
							calendarState.setCalendarMouseData(
								mouse.x,
								mouse.y,
								mouse.elementWidth,
								mouse.elementHeight
							);
						}}
					>
						{allocationStore.allocations.map((al) => {
							return (
								<CalendarAllocation
									key={al.uuid}
									allocation={al}
									mouse={mouse}
								/>
							);
						})}
						{timeStore.timeEntries.map((te) => {
							return (
								<CalendarTimeEntry
									key={te.uuid}
									timeEntry={te}
									mouse={mouse}
								/>
							);
						})}
						{calendarState.newItem &&
							(calendarState.mode === "allocation" ? (
								<CalendarAllocation
									key={calendarState.newItem.id}
									allocation={calendarState.newItem}
									mouse={mouse}
								/>
							) : (
								<CalendarTimeEntry
									key={calendarState.newItem.id}
									timeEntry={calendarState.newItem}
									mouse={mouse}
								/>
							))}
						<svg
							style={{
								height: `${
									calendarState.calendarMinuteEms * 60 * 24
								}em`,
								width: "100%",
								background: "white",
								borderRadius: "0 0 1em 1em",
								zIndex: 1,
								fontSize: "1em",
							}}
							onClick={(e) => {
								calendarState.deselectItem();
							}}
							{...calDrag()}
						>
							<defs>
								<pattern
									id="hatchtile"
									x="-5"
									y="-5"
									patternUnits="userSpaceOnUse"
									height="5"
									width="5"
								>
									<image
										x="0"
										y="0"
										height="5"
										width="5"
										xlinkHref={`${
											process.env.PUBLIC_URL +
											"/hatchtile.png"
										}`}
									/>
								</pattern>
							</defs>
							{[...Array(7).keys()].map((d) => {
								return calendarState.isDayIndexWeekend(d) ? (
									<rect
										width={`${100 / 7}%`}
										height="100%"
										x={`${d * (100 / 7)}%`}
										y="0"
										fill="url(#hatchtile)"
										opacity="1"
									/>
								) : null;
							})}
							{[...Array((24 * 60) / 15 - 1).keys()].map((d) => {
								return (
									<line
										x1={`0%`}
										y1={`${
											(d + 1) *
											calendarState.calendarMinuteEms *
											15
										}em`}
										x2={`100%`}
										y2={`${
											(d + 1) *
											calendarState.calendarMinuteEms *
											15
										}em`}
										stroke={
											(d + 1) % 4 ? "#f8f8f8" : "#eee"
										}
									/>
								);
							})}
							{[...Array(6).keys()].map((d) => {
								return (
									<line
										x1={`${(d + 1) * (100 / 7)}%`}
										y1="0%"
										x2={`${(d + 1) * (100 / 7)}%`}
										y2="100%"
										stroke="#ddd"
									/>
								);
							})}
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
});

const CalendarAllocation = observer(({ allocation, mouse }) => {
	return (
		<CalendarBlock
			item={allocation}
			itemType="allocation"
			zIndex={calendarState.mode === "allocation" ? 3 : 2}
			align={"left"}
			opacity={calendarState.mode === "allocation" ? 1 : 0.5}
			mouse={mouse}
		/>
	);
});

const CalendarTimeEntry = observer(({ timeEntry, mouse }) => {
	return (
		<CalendarBlock
			key={timeEntry.uuid}
			item={timeEntry}
			itemType="time"
			zIndex={calendarState.mode === "time" ? 3 : 2}
			align={"right"}
			opacity={calendarState.mode === "time" ? 1 : 0.5}
			mouse={mouse}
		/>
	);
});

const CalendarBlock = observer(
	({ item, itemType, zIndex, align, opacity, mouse, ...props }) => {
		const disabled = itemType !== calendarState.mode;
		const settings = organisationStore.organisation.settings;
		const diffDays = differenceInDays(item.date, calendarState.weekStart);
		const [hovering, setHovering] = useState(false);
		const [changeY, setChangeY] = useState(0);
		const [changeLength, setChangeLength] = useState(0);
		const y = changeY + item.startMinutes;
		const length = changeLength + item.numMinutes;
		const selected = item === calendarState.selectedItem;
		const startDrag = useGesture({
			onDragStart: () => {
				calendarState.setDragging(true);
			},
			onDrag: (state) => {
				const newY = calendarState.calendarMouseFloatMinutes;
				const newLength = length - (newY - y);
				setChangeY(newY - item.startMinutes);
				calendarState.adjustDragMinutes(newLength - item.numMinutes);
				setChangeLength(newLength - item.numMinutes);
			},
			onDragEnd: (state) => {
				let oldStartMniutes = item.startMinutes;
				let newStartMinutes = changeY + item.startMinutes;
				// newNumMinutes = Math.round(newNumMinutes / 15) * 15;
				newStartMinutes = Math.round(newStartMinutes / 15) * 15;
				let newNumMinutes =
					item.numMinutes - (newStartMinutes - oldStartMniutes);
				calendarState.editItem(item, itemType, {
					startMinutes: newStartMinutes,
					numMinutes: newNumMinutes,
				});
				setChangeY(0);
				calendarState.adjustDragMinutes(0);
				setChangeLength(0);
				calendarState.setDragging(false);
			},
		});
		const endDrag = useGesture({
			onDragStart: () => {
				calendarState.setDragging(true);
			},
			onDrag: (state) => {
				const newLength = calendarState.calendarMouseFloatMinutes - y;
				calendarState.adjustDragMinutes(newLength - item.numMinutes);
				setChangeLength(newLength - item.numMinutes);
			},
			onDragEnd: (state) => {
				let newNumMinutes = changeLength + item.numMinutes;
				newNumMinutes = Math.round(newNumMinutes / 15) * 15;
				calendarState.editItem(item, itemType, {
					numMinutes: newNumMinutes,
				});
				calendarState.adjustDragMinutes(0);
				setChangeLength(0);
				calendarState.setDragging(false);
			},
		});
		const calDrag = useGesture({
			onDragStart: (state) => {
				calendarState.deselectItem();
				calendarState.setCalendarMouseData(
					mouse.x,
					mouse.y,
					mouse.elementWidth,
					mouse.elementHeight
				);
			},
			onDrag: (state) => {
				if (!calendarState.newItem) {
					if (
						state.movement[1] /
							(calendarState.calendarMinuteEms * 10) >
						7.5
					) {
						calendarState.createNewItem();
					}
				} else {
					const numMinutes =
						calendarState.calendarMouseFloatMinutes -
						calendarState.newItem.startMinutes;
					calendarState.updateNewItem({
						numMinutes,
					});
				}
			},
			onDragEnd: (state) => {
				if (calendarState.newItem) {
					let numMinutes =
						calendarState.calendarMouseFloatMinutes -
						calendarState.newItem.startMinutes;
					numMinutes = Math.round(numMinutes / 15) * 15;
					calendarState.commitNewItem({
						numMinutes,
					});
				}
			},
		});
		return (
			diffDays < 7 &&
			diffDays >= 0 && (
				<div
					className={
						`calendar-item ${align}` +
						(selected ? " selected" : "") +
						(itemType === "allocation" ? " allocation" : "")
					}
					style={{
						width: `calc(${100 / 7}% - 2em - 2px)`,
						height: `calc(${
							calendarState.calendarMinuteEms * length
						}em - 2px)`,
						left:
							align === "left"
								? `calc(${(100 / 7) * diffDays}% + 1px)`
								: `calc(${(100 / 7) * diffDays}% + 2em + 1px)`,
						top: `calc(${
							calendarState.calendarMinuteEms * y
						}em + 1px)`,
						zIndex:
							zIndex +
							(hovering && !calendarState.dragging ? 2 : 0),
						opacity:
							opacity +
							(hovering && !calendarState.dragging ? 0.4 : 0),
					}}
					onPointerOver={(e) => setHovering(true)}
					onPointerOut={(e) => setHovering(false)}
					onClick={(e) => {
						calendarState.selectItem(item, itemType);
					}}
					{...(calendarState.mode === "time" &&
					itemType === "allocation"
						? calDrag()
						: {})}
					{...props}
				>
					{!disabled && (
						<svg
							className="vertical-drag"
							style={{
								width: "100%",
								height: "1em",
								position: "absolute",
								top: "-0.5em",
								left: 0,
							}}
							{...startDrag()}
						></svg>
					)}
					{!disabled && (
						<svg
							className="vertical-drag"
							style={{
								width: "100%",
								height: "1em",
								position: "absolute",
								bottom: "-0.5em",
								left: 0,
							}}
							{...endDrag()}
						></svg>
					)}
					<div>{item.project?.getTitle()}</div>
					<div>{item.projectPhase?.getTitle()}</div>
					{settings.useTasks && <div>{item.task?.name}</div>}
					<div
						className="time"
						style={{ pointerEvents: "none" }}
					>{`${Math.floor(
						Math.round(item.numMinutes + changeLength) / 60
					)}:${
						Math.round(item.numMinutes + changeLength) % 60 < 10
							? (Math.round(item.numMinutes + changeLength) %
									60) +
							  "0"
							: Math.round(item.numMinutes + changeLength) % 60
					}`}</div>
				</div>
			)
		);
	}
);
