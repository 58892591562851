import React from 'react';
import CreateReactClass from 'create-react-class';
import { ErrorAlert } from '../widgets/alerts.js';
import { IntercomMessageLink } from './IntercomMessageLink.js';
import PropTypes from "prop-types";


export var ErrorPanel = CreateReactClass({
  propTypes: {
    message: PropTypes.string.isRequired,
    includeTryAgainOrContactUs: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      includeTryAgainOrContactUs: true
    };
  },

  render: function() {
    return <ErrorAlert>
      <p>
        {this.props.message}
      </p>

      {this.props.includeTryAgainOrContactUs ?
        <p>
          {'You can try again, or if the problem persists, '}
          <IntercomMessageLink label="contact us" />.
        </p>
      : null}
    </ErrorAlert>;
  }
});
