import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { store as milestonesStore, actions as milestonesActions } from '../milestones/flux.js';

import PropTypes from "prop-types";

export var SaveBar = CreateReactClass({
  propTypes: {
    isDirty: PropTypes.bool,
    saveState: PropTypes.string,
    onSaveClick: PropTypes.func,
    unsavedChangesMessage: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      unsavedChangesMessage: "You have unsaved changes.",
      showButton: true,
      color: '#009a81',
      buttonText: 'Save'
    };
  },

  render: function() {
    return this.props.isDirty || this.props.saveState != null ?
      <div className="save-bar dont-print" style={{backgroundColor: this.props.color}}>
        {this.props.isDirty || this.props.saveState == null || this.props.saveState === 'saving' ?
          <div>
            {this.props.unsavedChangesMessage}
            {this.props.showButton ?
              <button
                  className="save-bar__save-button"
                  disabled={this.props.saveState === 'saving' || this.props.saveState === 'executing'}
                  onClick={this.handleAlertBarSaveClick}>
                {this.props.saveState !== 'saving' && this.props.saveState !== 'executing' ?
                  <span>{this.props.buttonText}</span>
                : <span>Saving... <i className="fa fa-spinner fa-spin" /></span>
                }
              </button>
            :null}
          </div>
        :
          <div className="save-bar__save-success-notification">
            <i className="fa fa-check" /> Your changes have been saved!
          </div>
        }
      </div>
    : null;
  },

  handleAlertBarSaveClick: function() {
    this.props.onSaveClick();
  }
});


export var MilestonesSaveBar = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([milestonesStore], function() {
      return {
        isDirty: milestonesStore.projectState.getDirtyProjects().length > 0,
        saveState: milestonesStore.saveState,
      };
    })
  ],

  render: function() {
    return <SaveBar
      isDirty={this.state.isDirty}
      saveState={this.state.saveState}
      onSaveClick={this.handleAlertBarSaveClick}
    />;
  },

  handleAlertBarSaveClick: function() {
    milestonesActions.alertBarSaveClick();
  },
});
