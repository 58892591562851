import React, { useEffect, useState } from "react";
import useVisible from "@21kb/react-page-visible-hook";
import { timesheetActions } from "./flux.js";

export default () => {
	const { visibilityState } = useVisible();
	const [preVisibilityState, setPrevVisibilityState] =
		useState(visibilityState);
	useEffect(() => {
		if (preVisibilityState === "hidden" && visibilityState === "visible") {
			timesheetActions.reload();
		}
		setPrevVisibilityState(visibilityState);
	}, [visibilityState]);
	return null;
};
