import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { ProjectStatus } from '../models/project.js';
import { BasicMySelect2 } from '../widgets.js';
import { NumberInputContainer } from '../widgets/NumberInputContainer.js';
import { formatNumber2 } from '../utils.js';
import PropTypes from "prop-types";



export var ProjectStatusList = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired,
  },

  render: function() {
    const project = this.props.project
    const showLikelihood = ['prospective', 'onHold'].includes(project.status)
    return (
      <div style={{margin: '3em 2em'}}>
        <div className="flexbox-container" style={{marginLeft: '1em', width: '50em'}}>
          <h4 className="flex-1-1-auto" style={{ fontSize: '1.8em', display: 'inline-block'}}>
            Project Status
          </h4>
          <div style={{ display: 'inline-block', textAlign: "right", width: "20em" }} >
            <div>Set All</div>
            <BasicMySelect2
              options={ProjectStatus.getOptions()}
              value={project.status}
              onChange={(status) => actions.setProjectProp('status', status)}
              dropLeft={true}
            />
          </div>
          <div style={{ width: '10em', paddingLeft: "2em", textAlign: "right"}}>
            {showLikelihood ? 
              <div>
                <div>% Likelihood</div>
                <NumberInputContainer
                  value={project.likelihood}
                  formatFunc={n => n != null ? formatNumber2(n) : ''}
                  onChange={(likelihood) => actions.setProjectProp('likelihood', likelihood)}
                />
              </div>
            : null}
          </div>
        </div>
        <PhaseStatusTable
          phases={this.props.project.getVisiblePhases()}
        />


      </div>
    );
  }

})


var PhaseStatusTable = CreateReactClass({
  propTypes: {
    phases: PropTypes.object.isRequired,
  },

  render: function() {
    const phases = this.props.phases
    return <div className="coincraft-table" style={{width:'50em', margin: '1em'}}>
      <div className="flexbox-container coincraft-table-row header">
        <div className="coincraft-table-cell" style={{ width: '20em' }}>Phase</div>
        <div className="coincraft-table-cell" style={{ width: '20em' }}>Status</div>
        <div className="coincraft-table-cell" style={{ width: '10em' }}>% Likelihood</div>
      </div>
      {phases.map((phase, i) => {
        return <PhaseStatusRow
          key={i}
          phase={phase}
        />;
      })}
    </div>;
  }
});


var PhaseStatusRow = CreateReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired,
  },

  render: function() {
    const phase = this.props.phase;
    const showLikelihood = ['prospective', 'onHold'].includes(phase.status)

    return <div className="flexbox-container coincraft-table-row">
      <div className="coincraft-table-cell" style={{ width: '20em' }}>
        {phase.getTitle()}
      </div>
      <div className="coincraft-table-cell coincraft-table-cell--dropdown" style={{width:'20em'}}>
        <BasicMySelect2
          options={ProjectStatus.getOptions()}
          value={phase.status}
          onChange={(status) => actions.setProjectPhaseProp(phase, 'status', status)}
          dropLeft={true}
        />
      </div>
      <div className="coincraft-table-cell coincraft-table-cell--input" style={{ width: '10em' }}>
        {showLikelihood ? 
          <NumberInputContainer
            className="coincraft-table-cell__input"
            value={phase.likelihood}
            formatFunc={n => n != null ? formatNumber2(n) : ''}
            onChange={(likelihood) => actions.setProjectPhaseProp(phase, 'likelihood', likelihood)}
          />
        : null}
      </div>
    </div>;
  }
});
