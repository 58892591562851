import { observer } from "mobx-react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import PdfStore, { columnDefs } from "./PdfStore";
import {
	Select,
	Switch,
	Input,
	InputNumber,
	Menu,
	Dropdown,
	Button,
} from "antd";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

export const PdfSidebar = observer(() => {
	return (
		<div className="dont-print" style={{ width: "32rem", padding: "1rem" }}>
			{/* {PdfStore.editingName ? (
				<Input
					value={PdfStore.invoiceSettings.templateName}
					onChange={(e) =>
						PdfStore.updateTemplateName(e.target.value)
					}
					style={{
						width: "80%",
						marginTop: "1rem",
						fontSize: "1.2rem",
					}}
				/>
			) : (
				<Select
					optionFilterProp="templateName"
					style={{
						zIndex: 100,
						width: "80%",
						marginTop: "1rem",
						fontSize: "1.2rem",
					}}
					value={PdfStore.templateId}
					status="active"
					onChange={(newId) => PdfStore.changeTemplateId(newId)}
					className={"budget-selector"}
				>
					{PdfStore.templates.map((temp) => {
						return (
							<Option key={temp.uuid} value={temp.uuid}>
								{temp.templateName}
							</Option>
						);
					})}
				</Select>
			)}
			{PdfStore.editingName ? (
				<Button
					type="primary"
					onClick={(e) => PdfStore.toggleEditingName(false)}
				>
					<i
						style={{ margin: 0, padding: 0 }}
						className="fa fa-save fa-fw"
					/>
				</Button>
			) : (
				<Dropdown
					placement="bottomRight"
					overlay={
						<Menu
							onClick={({ key }) => {
								switch (key) {
									case "newTemplate":
										PdfStore.createTemplate();
										break;
									case "renameTemplate":
										PdfStore.toggleEditingName(true);
										break;
									case "setDefault":
										PdfStore.setAsDefault();
										break;
									case "deleteTemplate":
										PdfStore.deleteTemplate();
										break;
									default:
										break;
								}
							}}
						>
							<Menu.Item key="newTemplate">
								+ New Template
							</Menu.Item>
							<Menu.Item key="renameTemplate">Rename</Menu.Item>
							<Menu.Item key="setDefault">
								Set as Default
							</Menu.Item>
							<Menu.Item key="deleteTemplate">Delete</Menu.Item>
						</Menu>
					}
				>
					<Button>
						<i
							style={{ margin: 0, padding: 0 }}
							className="fa fa-cog fa-fw"
						/>
					</Button>
				</Dropdown>
			)} */}
			<h3 style={{ margin: "0.5em 0 0 0" }}>Logo</h3>
			<div style={{ textAlign: "center" }}>
				<img
					style={{ height: "5em" }}
					alt="logo"
					src={PdfStore.invoiceSettings.logoUrl}
				/>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
						fontWeight: "bold",
						fontSize: "1.4rem",
					}}
				>
					Logo URL
				</div>
				<div style={{ flex: "1 1 auto", padding: "0 0.25em" }}>
					<Input
						value={PdfStore.invoiceSettings.logoUrl}
						onChange={(e) =>
							PdfStore.updateInvoiceSettings({
								logoUrl: e.target.value,
							})
						}
					/>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
						fontWeight: "bold",
						fontSize: "1.4rem",
					}}
				>
					Logo Size
				</div>
				<div style={{ flex: "1 1 auto", padding: "0 0.25em" }}>
					<InputNumber
						value={PdfStore.invoiceSettings.logoSize}
						onChange={(v) => {
							PdfStore.updateInvoiceSettings({
								logoSize: parseFloat(v),
							});
						}}
						min={1}
						max={20}
					/>
				</div>
			</div>
			<h3 style={{ margin: "1.5em 0 0 0" }}>Header</h3>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
					}}
				>
					<Switch
						checked={PdfStore.invoiceSettings.projectCode}
						onChange={(projectCode) => {
							PdfStore.updateInvoiceSettings({
								projectCode,
							});
						}}
					/>
				</div>
				<div
					style={{
						flex: "1 1 auto",
						padding: "0 0.25em",
						fontSize: "1.4rem",
					}}
				>
					Display Project Code
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
					}}
				>
					<Switch
						checked={PdfStore.invoiceSettings.projectName}
						onChange={(projectName) => {
							PdfStore.updateInvoiceSettings({
								projectName,
							});
						}}
					/>
				</div>
				<div
					style={{
						flex: "1 1 auto",
						padding: "0 0.25em",
						fontSize: "1.4rem",
					}}
				>
					Display Project Name
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "1rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
					}}
				>
					<Switch
						checked={PdfStore.invoiceSettings.projectAddress}
						onChange={(projectAddress) => {
							PdfStore.updateInvoiceSettings({
								projectAddress,
							});
						}}
					/>
				</div>
				<div
					style={{
						flex: "1 1 auto",
						padding: "0 0.25em",
						fontSize: "1.4rem",
					}}
				>
					Display Project Address
				</div>
			</div>
			{PdfStore.invoiceSettings.projectAddress && (
				<>
					<div
						style={{
							margin: "1.5em 0 0 0",
							fontWeight: "bold",
							fontSize: "1.4rem",
						}}
					>
						Project Address
					</div>
					<div
						style={{
							fontSize: "1.2rem",
							padding: "0 0.75em 0 0",
						}}
					>
						<TextArea
							value={PdfStore.projectAddress}
							onChange={(e) => {
								PdfStore.updateProjectAddress(e.target.value);
							}}
							placeholder="Edit Project Address..."
							autoSize={{ minRows: 3 }}
							allowClear
						/>
					</div>
				</>
			)}
			<>
				<div
					style={{
						margin: "1.5em 0 0 0",
						fontWeight: "bold",
						fontSize: "1.4rem",
					}}
				>
					Date Preference
				</div>
				<div
					style={{
						fontSize: "1.2rem",
						padding: "0 0.75em 0 0",
					}}
				>
					<Select
						value={PdfStore.invoiceSettings.datePreference}
						onChange={(datePreference) => {
							PdfStore.updateInvoiceSettings({
								datePreference,
							});
						}}
						style={{ width: "100%" }}
					>
						<Option value="dueDate">Due Date</Option>
						<Option value="issueDate">Issue Date</Option>
					</Select>
				</div>
			</>
			{PdfStore.client && (
				<>
					<div
						style={{
							margin: "1em 0 0 0",
							fontWeight: "bold",
							fontSize: "1.4rem",
						}}
					>
						Client Address
					</div>
					<div
						style={{
							fontSize: "1.2rem",
							padding: "0 0.75em 0 0",
						}}
					>
						<TextArea
							value={PdfStore.clientAddress}
							onChange={(e) => {
								PdfStore.updateClientAddress(e.target.value);
							}}
							placeholder="Edit client address..."
							autoSize={{ minRows: 3 }}
							allowClear
						/>
					</div>
				</>
			)}
			<h3 style={{ margin: "1.5em 0 0 0" }}>Content</h3>
			<div
				style={{
					margin: "1em 0 0 0",
					fontWeight: "bold",
					fontSize: "1.4rem",
				}}
			>
				Opening Statement
			</div>
			<div
				style={{
					fontSize: "1.2rem",
					padding: "0 0.75em 0 0",
				}}
			>
				<TextArea
					value={PdfStore.invoiceSettings.contentStart}
					onChange={(e) => {
						PdfStore.updateInvoiceSettings({
							contentStart: e.target.value,
						});
					}}
					placeholder="Enter some notes..."
					autoSize={{ minRows: 3 }}
					allowClear
				/>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginTop: "0.5rem",
						fontSize: "1.2rem",
					}}
				>
					<div
						style={{
							flex: "0 0 auto",
							padding: "0 0.5em",
						}}
					>
						<Switch
							size="small"
							checked={PdfStore.invoiceSettings.openPerProject}
							onChange={(openPerProject) => {
								PdfStore.updateInvoiceSettings({
									openPerProject,
								});
							}}
						/>
					</div>
					<div
						style={{
							flex: "1 1 auto",
							padding: "0 0.25em",
							fontSize: "1.4rem",
						}}
					>
						Unique to Each Project
					</div>
				</div>
			</div>
			<div
				style={{
					margin: "2em 0 0 0",
					fontWeight: "bold",
					fontSize: "1.4rem",
				}}
			>
				Table Columns
			</div>
			<div
				style={{
					fontSize: "1.2rem",
					padding: "0 0.75em 0 0",
				}}
			>
				<Select
					mode="multiple"
					allowClear
					style={{ width: "100%" }}
					placeholder="Please select some columns"
					defaultValue={PdfStore.invoiceSettings.columns}
					onChange={(v) =>
						PdfStore.updateInvoiceSettings({ columns: v })
					}
				>
					{Object.keys(columnDefs)
						.filter((ck) => !["amount"].includes(ck))
						.map((ck) => {
							return (
								<Option key={ck}>{columnDefs[ck].label}</Option>
							);
						})}
				</Select>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "3rem",
					fontSize: "1.2rem",
				}}
			>
				<div
					style={{
						flex: "0 0 auto",
						padding: "0 0.5em",
						fontWeight: "bold",
						fontSize: "1.4rem",
					}}
				>
					Tax Label
				</div>
				<div style={{ flex: "1 1 auto", padding: "0 0.25em" }}>
					<Input
						value={PdfStore.invoiceSettings.taxName}
						onChange={(e) =>
							PdfStore.updateInvoiceSettings({
								taxName: e.target.value,
							})
						}
					/>
				</div>
			</div>
			<div
				style={{
					margin: "2em 0 0 0",
					fontWeight: "bold",
					fontSize: "1.4rem",
				}}
			>
				Closing Statement
			</div>
			<div
				style={{
					fontSize: "1.2rem",
					padding: "0 0.75em 0 0",
				}}
			>
				<TextArea
					value={PdfStore.invoiceSettings.contentEnd}
					onChange={(e) => {
						PdfStore.updateInvoiceSettings({
							contentEnd: e.target.value,
						});
					}}
					placeholder="Enter some notes..."
					autoSize={{ minRows: 5 }}
					allowClear
				/>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginTop: "0.5rem",
						fontSize: "1.2rem",
					}}
				>
					<div
						style={{
							flex: "0 0 auto",
							padding: "0 0.5em",
						}}
					>
						<Switch
							size="small"
							checked={PdfStore.invoiceSettings.closePerProject}
							onChange={(closePerProject) => {
								PdfStore.updateInvoiceSettings({
									closePerProject,
								});
							}}
						/>
					</div>
					<div
						style={{
							flex: "1 1 auto",
							padding: "0 0.25em",
							fontSize: "1.4rem",
						}}
					>
						Unique to Each Project
					</div>
				</div>
			</div>
			<h3 style={{ margin: "1.5em 0 0 0" }}>Footer</h3>
			<div
				style={{
					margin: "1em 0 0 0",
					fontWeight: "bold",
					fontSize: "1.4rem",
				}}
			>
				Left
			</div>
			<div
				style={{
					fontSize: "1.2rem",
					padding: "0 0.75em 0 0",
				}}
			>
				<TextArea
					value={PdfStore.invoiceSettings.footerLeft}
					onChange={(e) => {
						PdfStore.updateInvoiceSettings({
							footerLeft: e.target.value,
						});
					}}
					style={{ fontSize: "1.2rem" }}
					placeholder="Enter some notes..."
					autoSize={{ minRows: 5 }}
					allowClear
				/>
			</div>
			<div
				style={{
					margin: "1em 0 0 0",
					fontWeight: "bold",
					fontSize: "1.4rem",
				}}
			>
				Right
			</div>
			<div
				style={{
					fontSize: "1.2rem",
					padding: "0 0.75em 0 0",
				}}
			>
				<TextArea
					value={PdfStore.invoiceSettings.footerRight}
					onChange={(e) => {
						PdfStore.updateInvoiceSettings({
							footerRight: e.target.value,
						});
					}}
					style={{ fontSize: "1.2rem" }}
					placeholder="Enter some notes..."
					autoSize={{ minRows: 5 }}
					allowClear
				/>
			</div>
		</div>
	);
});
