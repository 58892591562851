import _ from 'underscore';
import moment from 'moment';
import { sum, compareMultiple } from '../utils.js';
import { RowFactory, AllocationRow } from './Spreadsheet.js';
import { QUALIFIER } from './types.js';
import { dateConverter, Project, NoProjectProject, NoPhasePhase } from '../models.js';
import Immutable from 'immutable';


export function getAllocationSpreadsheetData({
    milestonesStore,
    organisationStore,
    timesheetData,
    currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment())),
    startMonth,
    endMonth,
    groupedColumns,
    leafColumn,
    projectMatcher = () => true,
    staffMatcher = () => true,
    existingAllocationRowKeys = Immutable.Set(),
    addedAllocationRowKeys = Immutable.Set()
}) {
  const monthIndexes = _.range(startMonth, endMonth + 1);
  const {rows, rowKeys} = getAllocationSpreadsheet({
    milestonesStore: milestonesStore,
    organisationStore: organisationStore,
    timesheetData: timesheetData,
    startMonth: startMonth,
    endMonth: endMonth,
    currentMonth: currentMonthIndex,
    projectMatcher: projectMatcher,
    staffMatcher: staffMatcher,
    existingAllocationRowKeys: existingAllocationRowKeys,
    addedAllocationRowKeys: addedAllocationRowKeys
  });

  const graph = getAllocationGraph({
    monthIndexes: monthIndexes,
    rows: rows,
    staffMembers: organisationStore.staffMembers.filter(staffMatcher),
    archivedStaffAvailability: timesheetData.archivedStaffAvailability,
    holidaysArray: organisationStore.getHolidaysXspans().data,
  });

  return {
    existingAllocationRowKeys: rowKeys,
    rows: rows,
    graph: graph,
    columnHeaders: graph.map(function(allocationGraphMonth) {
      return {
        header: dateConverter.intToMoment(allocationGraphMonth.date).format("MMM YY"),
        type: 'ratio',
        numerator: allocationGraphMonth.spend,
        denominator: allocationGraphMonth.income
      };
    })
  };
}


export function getUtilisationGraph({monthIndexes, rows, archivedStaffAvailability, staffMembers}) {
  let monthSums = {};
  for (let monthIndex of monthIndexes) {
    monthSums[monthIndex] = sum(rows.map(r => r.getTotalForMonth(monthIndex)));
  }

  return monthIndexes.map(function(monthIndex) {
    return {
      date: dateConverter.monthIndexToOffset(monthIndex),
      spend: monthSums[monthIndex]
    };
  });
}


export function getAvailabilityGraph({monthIndexes, archivedStaffAvailability, staffMembers, holidaysArray}) {
  function getStaffNumHoursAvailable(sm, monthIndex) {
    if (sm.isArchived) {
      if (archivedStaffAvailability[monthIndex] != null && archivedStaffAvailability[monthIndex][sm.id] != null) {
        return archivedStaffAvailability[monthIndex][sm.id];
      }
      else {
        return 0;
      }
    }
    else {
      const startOfMonth = dateConverter.monthIndexToOffset(monthIndex);
      const endOfMonth = dateConverter.endOfMonthOffset(startOfMonth);
      return sm.getNumHoursAvailableInRange(startOfMonth, endOfMonth, holidaysArray);
    }
  }

  return monthIndexes.map(function(monthIndex) {
    return {
      date: dateConverter.monthIndexToOffset(monthIndex),
      income: sum(staffMembers.map(s => getStaffNumHoursAvailable(s, monthIndex)))
    };
  });
}



export function getAllocationGraph({monthIndexes, rows, archivedStaffAvailability, staffMembers, holidaysArray}) {
  const availabilityGraph = getAvailabilityGraph({monthIndexes, archivedStaffAvailability, staffMembers, holidaysArray});
  const utilisationGraph = getUtilisationGraph({monthIndexes, rows, archivedStaffAvailability, staffMembers});
  return _.zip(availabilityGraph, utilisationGraph).map(function([availability, utilisation]) {
    return {
      date: availability.date,
      income: availability.income,
      spend: utilisation.spend
    };
  });
}


export function getAllocationSpreadsheet({
  milestonesStore,
  organisationStore,
  timesheetData,
  startMonth,
  endMonth,
  currentMonth = dateConverter.getMonthIndex(dateConverter.stringToInt(moment())),
  groupedColumns = ['staffMember', 'project'],
  leafColumn = 'phase',
  projectMatcher = () => true,
  staffMatcher = () => true,
  existingAllocationRowKeys = Immutable.Set(),
  addedAllocationRowKeys = Immutable.Set()
}) {
  let spreadsheet = new AllocationSpreadsheet(
    organisationStore,
    startMonth,
    endMonth,
    existingAllocationRowKeys
  );

  for (let {monthIndex, staffMember, project, projectPhase, hours} of timesheetData.iterate()) {
    if (projectMatcher(project) && staffMatcher(staffMember)) {
      spreadsheet.addHours(
        monthIndex,
        project,
        projectPhase,
        staffMember,
        QUALIFIER.ACTUAL,
        hours
      );
    }
  }

  // Add allocation data
  const startDateInt = dateConverter.monthIndexToOffset(currentMonth);

  for (let monthIndex = currentMonth, startOfMonth = startDateInt; monthIndex <= endMonth; monthIndex++) {
    let endOfMonth = dateConverter.endOfMonthOffset(startOfMonth);

    for (let [p, pp, _ra, staffId, hours] of milestonesStore.iterItems(null, null, null, null, startOfMonth, endOfMonth)) {
      const staffMember = organisationStore.getStaffMemberById(staffId);
      if (p.showProjections && projectMatcher(p) && staffId && !staffMember.isArchived && staffMatcher(staffMember)) {
        spreadsheet.addHours(monthIndex, p, pp, staffMember, QUALIFIER.TARGET, hours);
      }
    }

    startOfMonth = endOfMonth + 1;
  }

  for (let key of addedAllocationRowKeys) {
    spreadsheet.ensureKeyExists(key);
  }
  for (let key of spreadsheet.existingAllocationRowKeys) {
    spreadsheet.ensureKeyExists(key);
  }

  return {
    rowKeys: spreadsheet.existingAllocationRowKeys,
    rows: spreadsheet.resolveRows(groupedColumns, leafColumn)
  };
}


class AllocationSpreadsheet {
  constructor(organisationStore, startMonth, endMonth, existingAllocationRowKeys) {
    this.organisationStore = organisationStore;
    this.rows = Immutable.List();

    // Map<[<project id>, <project phase id>, <staff member id>], [row, rowIndex:int]>
    this.rowLookup = Immutable.Map();

    // Map<[<project id>, <project phase id>, <staff member id>], [actuals:int, allocated:int]>
    this.thisMonthValuesLookup = Immutable.Map();

    this.existingAllocationRowKeys = existingAllocationRowKeys;

    this.startMonth = startMonth;
    this.endMonth = endMonth;
    this.thisMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment()));
  }

  addHours(monthIndex, project, phase, staffMember, type, hours) {
    const key = Immutable.List([project.id, phase.id, staffMember.id]);
    if ((hours === 0 || monthIndex < this.startMonth || monthIndex > this.endMonth)
        && !this.existingAllocationRowKeys.includes(key)) {
      return;
    }
    let rowAndIndex = this.rowLookup.get(key);
    let row, rowIndex;
    if (rowAndIndex != null) {
      [row, rowIndex] = rowAndIndex;
    }
    else {
      row = makeAllocationRow(project, phase, staffMember, this.startMonth, this.endMonth);
      rowIndex = this.rows.count();
      this.rowLookup = this.rowLookup.set(key, [row, rowIndex]);
      this.thisMonthValuesLookup = this.thisMonthValuesLookup.set(key, [0, 0]);
      this.rows = this.rows.push(row);
    }

    if (monthIndex !== this.thisMonthIndex) {
      this.rows = this.rows.updateIn([rowIndex, monthIndex], h => h + hours);
    }
    else {
      /**
       * We have defined the 'this month' value as whichever is greater out of
       * the target or actuals value for this month. So we have to keep track
       * of both of those values. Then we set the real value to the bigger one
       * in `resolveRows`.
       */
      this.thisMonthValuesLookup = this.thisMonthValuesLookup.update(key, function([actuals, allocated]) {
        if (type === QUALIFIER.ACTUAL) {
          return [actuals + hours, allocated];
        }
        else {
          return [actuals, allocated + hours];
        }
      });
    }
    this.existingAllocationRowKeys = this.existingAllocationRowKeys.add(key);
  }

  ensureKeyExists(key) {
    if (!this.rowLookup.has(key)) {
      const projectId = key.get(0);
      const phaseId = key.get(1);
      const staffMemberId = key.get(2);

      const project = (projectId > 0) ? this.organisationStore.getProjectById(projectId) : new NoProjectProject();
      const phase = (phaseId > 0) ? this.organisationStore.getProjectPhaseById(phaseId) : new NoPhasePhase({project: project});
      const staffMember = this.organisationStore.getStaffMemberById(staffMemberId);
      this.rows = this.rows.push(makeAllocationRow(project, phase, staffMember, this.startMonth, this.endMonth));
    }
  }

  resolveRows(groupedColumns, leafColumn) {
    /**
     * Assume we've added everything we want to add, so we now have enough
     * information to decide whether to use the projected or actual values for
     * the current month.
     *
     * Also sort the rows according to `groupedColumns` and `leafColumn`.
     */
    let self = this;
    const comparatorLookup = {
      project: (a, b) => Project.compareByTitle(a.get('project'), b.get('project')),
      phase: (a, b) => (a.get('phase').startDate || 0) - (b.get('phase').startDate || 0),
      staffMember: (a, b) => a.get('staffMember').getFullName().localeCompare(b.get('staffMember').getFullName())
    };

    let rows = this.rows.map(function(row) {
      const key = Immutable.List([row.get('project').id, row.get('phase').id, row.get('staffMember').id]);
      const values = self.thisMonthValuesLookup.get(key);

      if (values != null) {
        const [actuals, allocated] = values;
        const max = Math.max(actuals, allocated);
        return row.set(self.thisMonthIndex, max);
      }
      else {
        return row;
      }
    });

    return rows.sort(compareMultiple(...[...groupedColumns, leafColumn].map(c => comparatorLookup[c])));
  }
}



function makeAllocationRow(project, phase, staffMember, startMonth, endMonth) {
  let headers = [
    ['project', project],
    ['phase', phase],
    ['staffMember', staffMember]
  ];
  return new RowFactory(AllocationRow, startMonth, endMonth).makeRow(headers);
}
