import React, { useState } from "react";
import CreateReactClass from "create-react-class";
import { Modal, ModalContent } from "../modal.js";
import { TriStateSaveButton } from "../widgets.js";
import moment from "moment";
import { FormSection, FormRow, FormLabel } from "../forms.js";
import { DateValue, Checkbox } from "../widgets.js";
import { ErrorPopover } from "../utils.js";
import { actions } from "./flux.js";
import PropTypes from "prop-types";
import { projectStore } from "./flux.js";

export const DelayProjectModal = ({ onConfirm, onCancel, saveState }) => {
	const project = projectStore.project;
	const [fromDate, setFromDate] = useState(moment());
	const [untilDate, setUntilDate] = useState(moment());
	const [splitPhases, setSplitPhases] = useState(false);
	const [selectAllPhases, setSelectAllPhases] = useState(true);
	const [selectedPhases, setSelectedPhases] = useState(
		new Set(project.getVisiblePhases().map((ph) => ph.uuid))
	);
	const labelWidth = "7em";
	return (
		<Modal>
			<ModalContent
				header="Delay Project"
				width="45em"
				bodyStyles={{ padding: 0 }}
			>
				<div className="delay-project-form" style={{ padding: "2em" }}>
					<p>
						Please select the dates you will like to delay{" "}
						<em>{project.getTitle()}</em>
						{
							" until. This shift all phase dates that occur after selected date forward by the same amount."
						}
					</p>

					<div>
						<FormSection>
							<FormRow>
								<FormLabel style={{ width: labelWidth }}>
									Delay From:
								</FormLabel>
								<DateValue
									className="start-date-input"
									style={{
										width: "16em",
										display: "inline-block",
									}}
									value={fromDate}
									onChange={setFromDate}
								/>
							</FormRow>
							<FormRow>
								<FormLabel style={{ width: labelWidth }}>
									Delay Until:
								</FormLabel>
								<DateValue
									className="until-date-input"
									style={{
										width: "16em",
										display: "inline-block",
									}}
									value={untilDate}
									onChange={setUntilDate}
								/>
							</FormRow>
							<FormRow>
								<Checkbox
									className="split-phases-input"
									value={splitPhases}
									label="Split phases in two that occur during the delay."
									onChange={setSplitPhases}
								/>
							</FormRow>
							<FormRow>
								<Checkbox
									className="all-phases-input"
									value={selectAllPhases}
									label="Apply to all phases."
									onChange={setSelectAllPhases}
								/>
							</FormRow>
							{!selectAllPhases ? (
								<FormRow style={{ paddingLeft: "3em" }}>
									{project.getVisiblePhases().map((ph) => {
										return (
											<div>
												<Checkbox
													value={selectedPhases.has(
														ph.uuid
													)}
													label={ph.getTitle()}
													onChange={(include, e) => {
														if (include) {
															selectedPhases.add(
																ph.uuid
															);
															setSelectedPhases(
																new Set([
																	...selectedPhases,
																])
															);
														} else {
															selectedPhases.delete(
																ph.uuid
															);
															setSelectedPhases(
																new Set([
																	...selectedPhases,
																])
															);
														}
													}}
												/>
											</div>
										);
									})}
								</FormRow>
							) : null}
						</FormSection>
					</div>
				</div>
				<div
					style={{
						borderTop: "1px solid #ccc",
						textAlign: "right",
						padding: "1em",
					}}
				>
					<a href="javascript:void(0)" onClick={onCancel}>
						Cancel
					</a>

					<TriStateSaveButton
						text="Shift Dates"
						className="btn btn-default"
						onClick={() =>
							onConfirm({
								fromDate: fromDate,
								untilDate: untilDate,
								splitPhases: splitPhases,
								phaseUuids: selectAllPhases
									? project
											.getVisiblePhases()
											.map((ph) => ph.uuid)
									: [...selectedPhases],
							})
						}
						state={saveState}
						style={{ marginLeft: "1em" }}
					/>
				</div>
			</ModalContent>
		</Modal>
	);
};
