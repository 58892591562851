import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { graphScaleStore } from './flux.js';
import { dateConverter } from '../models.js';
import { CashFlowChart, SuperimposedDataRenderer } from "../cashFlowChart.js";
import { mainContentPaddingLeft } from './dimensions.js';
import PropTypes from "prop-types";

export var ChartContainer = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([graphScaleStore], function() {
      return {
        overrideMaxY: graphScaleStore.overrideMaxY
      };
    })
  ],

  propTypes: {
    data: PropTypes.array,
    viewportScrollLeft: PropTypes.number,
    onScroll: PropTypes.func,
    xScale: PropTypes.object,
    minX: PropTypes.number,
    maxX: PropTypes.number,
    yValueType: PropTypes.string
  },

  render: function() {
    return <CashFlowChart
      paddingBottom="20%"
      data={this.props.data}
      viewportScrollLeft={this.props.viewportScrollLeft}
      onScroll={this.props.onScroll}
      xScale={this.props.xScale}
      min={this.props.minX}
      max={this.props.maxX}
      yValueType={this.props.yValueType}

      overrideMaxY={this.state.overrideMaxY}

      enableHoverTooltips={false}
      renderer={new SuperimposedDataRenderer({
        includeLines: true,
        includeCircles: true
      })}

      showLegend={false}
      showScrollbar={false}

      incomeAxisName="Staff hours available"
      spendAxisName="Staff hours allocated"

      width='100%'
      showXAxis={false}

      dateConverter={dateConverter}
      manageOwnScrolling={false}

      padding={{
        top: '0%',
        left: mainContentPaddingLeft,
        bottom: '0%',
        right: '0%'
      }}
    />;
  }
});


