import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { userStore } from '../user.js';
import { costCentresPageStore } from './flux.js';
import { organisationStore } from '../organisation.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { Link } from 'react-router';
import { Table, Column } from '../table.js';


export var CostCentresPage = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([userStore, organisationStore], function() {
      return {
        isAdmin: userStore.isAdmin(),
        costCentres: organisationStore.costCentres,
      };
    })
  ],

  getInitialState: function() {
    return {
      columns: [
        new Column({
          identifier: 'name',
          header: "Cost centre",
          width: '50%',
          data: cs => cs.name,
          content: function(cs) {
            return <div>
              <Link
                  to={`/dashboard/cost-centres/${cs.id}`}
                  style={{display: 'block'}}>
                {cs.name}
              </Link>
            </div>;
          },
          type: 'string',
        })
      ]
    };
  },

  render: function() {
    if (this.state.costCentres == null) {
      return null;
    }

    return <CoincraftPage
      header={
        <div>
          <div>
            <div className="heading">
              Cost Centres
            </div>
          </div>
          {this.state.isAdmin ?
            <button
                className="page-header__button add-cost-centre-button"
                onClick={() => costCentresPageStore.newCostCentre()}>
              + New Cost Centre
            </button>
          : null}
        </div>
      }
      body={
        <div style={{maxWidth: '60em'}}>
          <Table
            rows={this.state.costCentres}
            columns={this.state.columns}
          />
        </div>
      }
    />;
  }
});


