import { observable, computed, action, makeObservable } from "mobx";
import {
	format,
	startOfWeek,
	endOfWeek,
	addDays,
	startOfDay,
	isWeekend,
} from "date-fns";
import timeStore from "./timeStore";
import allocationStore from "./allocationStore";
import budgetStore from "./budgetStore";
import cuid from "cuid";
import apiRequest from "../apiRequest";
import { userStore } from "../user";
import { organisationStore } from "../organisation";
import { PermissionLevel } from "../models/permissions";
import { getProjectOptions } from "../widgets/ProjectSelector";

class calendarState {
	@observable mode = "time"; // or "allocation"
	@observable weekStart = startOfWeek(new Date());
	@observable calendarMouseX = 0;
	@observable calendarMouseY = 0;
	@observable calendarWidth = 100;
	@observable calendarHeight = 100;
	@observable calendarMinuteEms = 0.1;
	@observable selectedValues = {
		projectId: null,
		projectPhaseId: null,
		taskUuid: null,
		staffMemberId: null,
		isBillable: true,
		isVariation: false,
		isOvertime: false,
		isLocked: false,
		beenInvoiced: false,
		remote: false,
		flexi: false,
	};
	@observable budgetType = "phase";
	@observable newItem = null;
	@observable selectedItem = null;
	@observable newItemModal = false;
	@observable editItemModal = false;
	@observable deletingItem = false;
	@observable deletingItemUuid = null;
	@observable deleteCountdown = 5;
	@observable dragging = false;
	@observable draggingChangeMinutes = 0;
	deleteTimeout = null;
	constructor() {
		makeObservable(this);
	}
	@action.bound
	init(mode) {
		this.mode = mode;
		this.setDefaults();
	}
	@action
	setDefaults(selectedValues) {
		const project = getProjectOptions(
			this.mode === "allocation"
				? PermissionLevel.projectManager
				: PermissionLevel.timesheet
		).filter((p) =>
			organisationStore.organisation.settings.timeEntryStatus.includes(
				p.status
			)
		)[0];
		const phase = project
			.getVisiblePhases()
			.filter((p) =>
				organisationStore.organisation.settings.timeEntryStatus.includes(
					p.status
				)
			)[0];
		const task = phase.tasks.toJS()[0];
		this.selectedValues = {
			projectId: project.id,
			projectPhaseId: phase.id,
			taskUuid: task.uuid,
			staffMemberId: userStore.user?.id,
			isBillable: task?.isBillable ?? true,
			isVariation: task?.isVariation ?? false,
			isOvertime: false,
			isLocked: false,
			beenInvoiced: false,
			remote: false,
			flexi: false,
			...selectedValues,
		};
		timeStore.loadWeek(this.weekStart, this.selectedValues.staffMemberId);
		allocationStore.loadWeek(
			this.weekStart,
			this.selectedValues.staffMemberId
		);
		budgetStore.getBudget(
			this.weekStart,
			this.selectedItem ? this.selectedItem.date : this.weekStart,
			this.mode,
			this.selectedValues.staffMemberId,
			this.selectedValues.projectId,
			this.selectedValues.projectPhaseId
		);
	}
	@computed
	get weekEnd() {
		return endOfWeek(this.weekStart);
	}
	@computed
	get weekDays() {
		return [...Array(7).keys()].map((d) => addDays(this.weekStart, d));
	}
	@computed
	get calendarMouseDay() {
		return this.weekDays[
			Math.floor(this.calendarMouseX / (this.calendarWidth / 7))
		];
	}
	@computed
	get calendarMouseFloatMinutes() {
		return (
			Math.round(
				(this.calendarMouseY / this.calendarHeight) * (24 * 60)
			) || 0
		);
	}
	@computed
	get calendarMouseHour() {
		return Math.floor(this.calendarMouseFloatMinutes / 60) || 0;
	}
	@computed
	get calendarMouseMinute() {
		return (
			this.calendarMouseFloatMinutes - this.calendarMouseHour * 60 || 0
		);
	}
	@action.bound
	adjustDragMinutes(adjustment) {
		this.draggingChangeMinutes = adjustment;
	}
	@action.bound
	setDragging(isDragging) {
		this.dragging = isDragging;
	}
	@action.bound
	setWeekStart(newWeekStart) {
		this.weekStart = startOfWeek(newWeekStart);
		this.setDefaults(this.selectedValues);
	}
	@action.bound
	isDayIndexWeekend(dayIndex) {
		return isWeekend(this.weekDays[dayIndex]);
	}
	@action.bound
	setCalendarMouseData(x, y, width, height) {
		this.calendarMouseX = x;
		this.calendarMouseY = y;
		this.calendarHeight = height;
		this.calendarWidth = width;
	}
	@action.bound
	createNewItem() {
		this.newItem = {
			...this.selectedValues,
			uuid: cuid(),
			date: this.calendarMouseDay,
			startMinutes: Math.floor(this.calendarMouseFloatMinutes / 15) * 15,
			numMinutes: 15,
			get staff() {
				return organisationStore.getStaffMemberById(this.staffMemberId);
			},
			get project() {
				return organisationStore.getProjectById(this.projectId);
			},
			get projectPhase() {
				return organisationStore.getProjectPhaseById(
					this.projectPhaseId
				);
			},
			get task() {
				return this.projectPhase.getTaskByUuid(this.taskUuid).toJS();
			},
		};
	}
	@action.bound
	updateNewItem(newData) {
		for (const [key, val] of Object.entries(newData)) {
			this.newItem[key] = val;
		}
	}
	@action.bound
	commitNewItem(newData) {
		this.updateNewItem(newData);
		if (this.mode === "allocation") {
			allocationStore.createNewAllocation(this.newItem);
		} else {
			timeStore.createNewTimeEntry(this.newItem);
		}
		this.newItem = null;
	}
	@action.bound
	editItem(item, itemType, data) {
		if (itemType === "allocation") {
			allocationStore.editAllocation(item, data);
		} else {
			timeStore.editTimeEntry(item, data);
		}
	}
	@action.bound
	editSelectedItem(data) {
		this.editItem(this.selectedItem, this.selectedItemType, data);
	}
	@action.bound
	selectItem(item, itemType) {
		this.deleteCountdown = 0;
		this.selectedItem = item;
		this.selectedItemType = itemType;
		this.selectedValues = item.serialise();
		budgetStore.getBudget(
			this.weekStart,
			this.selectedItem ? this.selectedItem.date : this.weekStart,
			this.mode,
			this.selectedValues.staffMemberId,
			this.selectedValues.projectId,
			this.selectedValues.projectPhaseId
		);
	}
	@action.bound
	deselectItem() {
		if (this.selectedItem) {
			this.deleteCountdown = 0;
			this.selectedValues = {
				...this.selectedValues,
				...(this.mode === "allocation" ||
				this.selectedItemType === "time"
					? {
							isBillable:
								this.selectedItem?.task?.isBillable ?? true,
							isVariation:
								this.selectedItem?.task?.isVariation ?? false,
							isOvertime: false,
							isLocked: false,
							beenInvoiced: false,
							remote: false,
							flexi: false,
							notes: "",
					  }
					: {}),
			};
			this.selectedItem = null;
		}
	}
	@action.bound
	updateSelectedValues(data) {
		this.selectedValues = {
			...this.selectedValues,
			...data,
		};
		if ("projectId" in data) {
			const project = organisationStore.getProjectById(data.projectId);
			const phase = project.getVisiblePhases()[0];
			const task = phase.tasks.toJS()[0];
			this.selectedValues = {
				...this.selectedValues,
				projectPhaseId: phase.id,
				taskUuid: task.uuid,
				isBillable: task?.isBillable ?? true,
				isVariation: task?.isVariation ?? false,
			};
		}
		if ("projectPhaseId" in data) {
			const phase = organisationStore.getProjectPhaseById(
				data.projectPhaseId
			);
			const task = phase.tasks.toJS()[0];
			this.selectedValues = {
				...this.selectedValues,
				taskUuid: task.uuid,
				isBillable: task?.isBillable ?? true,
				isVariation: task?.isVariation ?? false,
			};
		}
		if (this.selectedItem) {
			this.editSelectedItem(this.selectedValues);
		}
		budgetStore.getBudget(
			this.weekStart,
			this.selectedItem ? this.selectedItem.date : this.weekStart,
			this.mode,
			this.selectedValues.staffMemberId,
			this.selectedValues.projectId,
			this.selectedValues.projectPhaseId
		);
	}
	@action.bound
	updateSelectedStaffId(id) {
		this.setDefaults({ staffMemberId: id });
	}
	@action.bound
	deleteSelectedItem() {
		this.deletingItem = true;
		this.deleteStartTimestamp = Date.now();
		this.deletingItemUuid = this.selectedItem.uuid;
		const itemUuid = this.deletingItemUuid;
		const itemType = this.selectedItemType;
		this.deleteCountdown = 5;
		this.deleteTimeout = setInterval(
			() => this.deleteItemInterval(itemUuid, itemType),
			1000
		);
	}
	@action.bound
	deleteItemInterval(itemUuid, itemType) {
		if (this.deleteCountdown > 0) {
			this.deleteCountdown--;
		} else {
			if (this.deletingSelectedItem) {
				this.deselectItem();
			}
			if (itemType === "allocation") {
				allocationStore.deleteAllocation(itemUuid);
			} else {
				timeStore.deleteTimeEntry(itemUuid);
			}
			this.deletingItem = false;
			this.deleteCountdown = 5;
			this.deletingItemUuid = null;
			clearInterval(this.deleteTimeout);
		}
	}
	@action.bound
	undoDeleteSelectedItem() {
		this.deletingItem = false;
		this.deleteCountdown = 5;
		this.deletingItemUuid = null;
		clearInterval(this.deleteTimeout);
	}
	@computed
	get deletingSelectedItem() {
		return (
			this.selectedItem &&
			this.deletingItemUuid === this.selectedItem.uuid
		);
	}
	@action.bound
	changeBudgetType(newType) {
		this.budgetType = newType;
	}
	@computed
	get dayTotals() {
		if (this.mode === "allocation") {
			return allocationStore.dayTotals;
		} else {
			return timeStore.dayTotals;
		}
	}
	@computed
	get budget() {
		if (this.budgetType === "phase") {
			return {
				budget: budgetStore.phaseBudget,
				use:
					budgetStore.phaseBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.phaseBudget
					? (budgetStore.phaseBudgetUse +
							((this.newItem?.numMinutes +
								this.draggingChangeMinutes) /
								60 || 0)) /
					  budgetStore.phaseBudget
					: 0,
			};
		}
		if (this.budgetType === "role") {
			return {
				budget: budgetStore.roleBudget,
				use:
					budgetStore.roleBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.roleBudget
					? (budgetStore.roleBudgetUse +
							((this.newItem?.numMinutes || 0) +
								this.draggingChangeMinutes) /
								60) /
					  budgetStore.roleBudget
					: 0,
			};
		}
		if (this.budgetType === "staff") {
			return {
				budget: budgetStore.staffBudget,
				use:
					budgetStore.staffBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.staffBudget
					? (budgetStore.staffBudgetUse +
							((this.newItem?.numMinutes || 0) +
								this.draggingChangeMinutes) /
								60) /
					  budgetStore.staffBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyPhase") {
			return {
				budget: budgetStore.monthlyPhaseBudget,
				use:
					budgetStore.monthlyPhaseBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.monthlyPhaseBudget
					? (budgetStore.monthlyPhaseBudgetUse +
							((this.newItem?.numMinutes || 0) +
								this.draggingChangeMinutes) /
								60) /
					  budgetStore.monthlyPhaseBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyRole") {
			return {
				budget: budgetStore.monthlyRoleBudget,
				use:
					budgetStore.monthlyRoleBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.monthlyRoleBudget
					? (budgetStore.monthlyRoleBudgetUse +
							((this.newItem?.numMinutes || 0) +
								this.draggingChangeMinutes) /
								60) /
					  budgetStore.monthlyRoleBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyStaff") {
			return {
				budget: budgetStore.monthlyStaffBudget,
				use:
					budgetStore.monthlyStaffBudgetUse +
					((this.newItem?.numMinutes || 0) +
						this.draggingChangeMinutes) /
						60,
				percent: budgetStore.monthlyStaffBudget
					? (budgetStore.monthlyStaffBudgetUse +
							((this.newItem?.numMinutes || 0) +
								this.draggingChangeMinutes) /
								60) /
					  budgetStore.monthlyStaffBudget
					: 0,
			};
		}
	}
	@computed
	get saving() {
		if (
			timeStore.saving === "saving" ||
			allocationStore.saving === "saving"
		) {
			return "saving";
		} else if (
			timeStore.saving === "saved" ||
			allocationStore.saving === "saved"
		) {
			return "saved";
		} else {
			return null;
		}
	}
}

export default new calendarState();
