import { observable, computed, action, makeObservable } from "mobx";
import cuid from "cuid";
import { organisationStore } from "../organisation";
import { camelCase } from "change-case";
import { parse, format } from "date-fns";

class allocationModel {
	@observable id = null;
	@observable uuid = cuid();

	@observable staffMemberId = null;
	@observable organisationId = null;
	@observable costCentreId = null;
	@observable projectId = null;
	@observable projectPhaseId = null;
	@observable taskUuid = null;

	@observable date = new Date();
	@observable numMinutes = 0;
	@observable startMinutes = 0;

	@observable isBillable = true;
	@observable isVariation = false;
	@observable isOvertime = false;
	@observable beenInvoiced = false;
	@observable flexi = false;
	@observable remote = false;
	@observable isLocked = false;

	@observable notes = "";
	@observable deleted = false;

	constructor(props) {
		makeObservable(this);
		this.updateProps(props);
	}
	@action.bound
	updateProps(props) {
		for (const [k, v] of Object.entries(props)) {
			if (k === "date" && typeof v === "string") {
				this[camelCase(k)] = parse(v, "yyyy-MM-dd", new Date());
			} else {
				this[camelCase(k)] = v;
			}
		}
		this.isBillable ??= this.task?.isBillable ?? true;
		this.isVariation ??= this.task?.isVariation ?? false;
		this.isOvertime ??= this.task?.isOvertime ?? false;
		this.isLocked ??= this.task?.isLocked ?? false;
		this.beenInvoiced ??= this.task?.beenInvoiced ?? false;
		this.remote ??= this.task?.remote ?? false;
		this.flexi ??= this.task?.flexi ?? false;
	}
	@computed
	get staffMember() {
		return organisationStore.getStaffMemberById(this.staffMemberId);
	}
	@computed
	get organisation() {
		return organisationStore.organisation;
	}
	@computed
	get project() {
		return organisationStore.getProjectById(this.projectId);
	}
	@computed
	get projectPhase() {
		return this.project
			? organisationStore.getProjectPhaseById(this.projectPhaseId)
			: null;
	}
	@computed
	get phase() {
		return this.projectPhase;
	}
	@computed
	get task() {
		return this.projectPhase?.getTaskByUuid(this.taskUuid)?.toJS();
	}
	@computed
	get endMinutes() {
		return this.startMinutes + this.numMinutes;
	}
	serialise() {
		return {
			uuid: this.uuid,
			costCentreId: this.costCentreId || this.project.costCentreId,
			projectId: this.projectId,
			projectPhaseId: this.projectPhaseId,
			taskUuid: this.taskUuid,
			staffMemberId: this.staffMemberId,
			date: format(this.date, "yyyy-MM-dd"),
			numMinutes: this.numMinutes,
			startMinutes: this.startMinutes,
			notes: this.notes,
			isBillable: this.isBillable ?? this.task?.isBillable ?? true,
			isVariation: this.isVariation ?? this.task?.isVariation ?? false,
			isOvertime: this.isOvertime ?? this.task?.isOvertime ?? false,
			isLocked: this.isLocked ?? this.task?.isLocked ?? false,
			beenInvoiced: this.beenInvoiced ?? this.task?.beenInvoiced ?? false,
			remote: this.remote ?? this.task?.remote ?? false,
			flexi: this.flexi ?? this.task?.flexi ?? false,
		};
	}
}

export default allocationModel;
