import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Checkbox } from './generic.js';

import PropTypes from "prop-types";

export var CheckboxList = CreateReactClass({
  propTypes: {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.any,
        disabled: PropTypes.bool
      })
    ).isRequired,
    selectedOptions: PropTypes.array,
    onChange: PropTypes.func
  },

  render: function() {
    let self = this;

    let lookup = {};
    this.props.selectedOptions.forEach(function(o) {
      lookup[o] = true;
    });

    let liStyle = {width: '18em', display: 'inline-block', margin: '1em 0'};

    return (
      <ul
          className="checkbox-list"
          style={{listStyleType: 'none', ...this.props.style}}>
        {this.props.options.map(function(option, i) {
          return <li key={i} style={liStyle}>
            <Checkbox
              className="checkbox-list__checkbox"
              value={lookup[option.value] === true}
              disabled={option.disabled}
              label={option.label}
              onChange={function(checked) {
                if (lookup[option.value] === true) {
                  self.props.onChange(_.without(self.props.selectedOptions, option.value));
                }
                else {
                  self.props.onChange([...self.props.selectedOptions, option.value]);
                }
              }}
            />
          </li>;
        })}
      </ul>
    );
  }
});


