import _ from "underscore";
import { organisationStore } from '../../../organisation.js';
import { formatCurrency, compareMultiple } from '../../../utils.js';

const displayTypes = {
    0: "sub-total",
    1: "",
}

const expandable = {
    0: false,
    1: false,
}

const hideable = {
    0: false,
    1: true,
}

const defaultExpanded = {
    0: true,
    1: false,
}

export const RevenueRow = class {
    constructor(spreadsheetStore, rowData) {
        this.uuid = rowData.uuid;
        this.cfis = {};
        this.parentId = rowData.parentId;
        this.childrenIds = new Set(rowData.childrenIds);
        this.visible = true;
        this.level = rowData.level;
        this.title = rowData.title || "";
        this.spreadsheetStore = spreadsheetStore;
        this.groupType = rowData.groupType;
        this.rowType = rowData.rowType;
        this.editable = rowData.editable === false ? false : true;
        this.project = rowData.project || organisationStore.getProjectById(rowData.projectId);
        this.phase = rowData.phase || organisationStore.getProjectPhaseById(rowData.phaseId);

        // cached revenues
        this._projectedRevenueCache = rowData.projectedRevenue || {};
        this._actualRevenueCache = rowData.actualRevenue || {};
        this._combinedRevenueCache = rowData.combinedRevenue || {};
        this._projectedRevenueToDateCache = rowData.projectedRevenueToDate || {};
        this._actualRevenueToDateCache = rowData.actualRevenueToDate || {};
        this._combinedRevenueToDateCache = rowData.combinedRevenueToDate || {};
        this._actualMinMonthIndex = rowData.actualMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._actualMaxMonthIndex = rowData.actualMaxMonthIndex || spreadsheetStore.currentMonthIndex;
        this._projectedMinMonthIndex = rowData.projectedMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._projectedMaxMonthIndex = rowData.projectedMaxMonthIndex || spreadsheetStore.currentMonthIndex;
        this._combinedMinMonthIndex = rowData.combinedMinMonthIndex || spreadsheetStore.currentMonthIndex;
        this._combinedMaxMonthIndex = rowData.combinedMaxMonthIndex || spreadsheetStore.currentMonthIndex;
    }

    get addPhaseButton() {
        return this.rowType === "project"
    }

    get parent() {
        return this.spreadsheetStore.revenueRows.get(this.parentId)
    }

    get children() {
        return [...this.childrenIds]
            .map(cId => this.spreadsheetStore.revenueRows.get(cId))
            .sort(compareMultiple(
                (a, b) => (a.rowType === "phase" && a.phase && a.phase.startDate ? a.phase.startDate : Infinity) - (b.rowType === "phase" && b.phase && b.phase.startDate ? b.phase.startDate : Infinity),
                (a, b) => a.title.localeCompare(b.title)
            ));
    }

    get isDisplayed() {
        return true
    }

    get rowDisplayType() {
        return displayTypes[this.level]
    }

    get expandable() {
        return expandable[this.level]
    }

    get hideable() {
        return hideable[this.level]
    }

    get selected() {
        return this.spreadsheetStore.selectedRevenueRowId === this.uuid
    }

    get totalFee() {
        return this.phase ? this.phase.fee : this.project ? this.project.fee : 0
    }

    get minMonthIndex() {
        return this._combinedMinMonthIndex;
    }

    get maxMonthIndex() {
        return this._combinedMaxMonthIndex;
    }

    toggleVisibility() {
        this.visible = !this.visible
        _.range(this.minMonthIndex, this.maxMonthIndex + 1).forEach(mi => {
            let multiplyer = this.visible ? 1 : -1;
            this.parent.addActualRevenue(this.getActualRevenueMonthIndex(mi) * multiplyer, mi)
            this.parent.addProjectedRevenue(this.getProjectedRevenueMonthIndex(mi) * multiplyer, mi)
            this.parent.addCombinedRevenue(this.getCombinedRevenueMonthIndex(mi) * multiplyer, mi)
        })
    }

    addCfi(cfi) {
        if (cfi.expense || cfi.spend) return false
        this.cfis[cfi.monthIndex] = this.cfis[cfi.monthIndex] || []
        this.cfis[cfi.monthIndex].push(cfi)
        if (cfi.milestone) {
            this.addProjectedRevenue(cfi.fee, cfi.monthIndex)
        } else {
            this.addActualRevenue(cfi.fee, cfi.monthIndex)
        }
    }
    addProjectedRevenue(revenue, monthIndex) {
        const { currentMonthIndex } = this.spreadsheetStore
        if (revenue == undefined) return false
        if (monthIndex > this._projectedMaxMonthIndex) this._projectedMaxMonthIndex = monthIndex
        if (monthIndex < this._projectedMinMonthIndex) this._projectedMinMonthIndex = monthIndex
        this._projectedRevenueCache[monthIndex] = this._projectedRevenueCache[monthIndex] || 0
        this._projectedRevenueCache[monthIndex] += revenue
        _.range(monthIndex, this._projectedMaxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._projectedRevenueToDateCache[mi] != undefined) {
                this._projectedRevenueToDateCache[mi] = this.getProjectedRevenueToDateMonthIndex(mi)
                this._projectedRevenueToDateCache[mi] += revenue
            } else {
                this._projectedRevenueToDateCache[mi] = this.getProjectedRevenueToDateMonthIndex(mi)
            }
        })
        if (this.parent) this.parent.addProjectedRevenue(revenue, monthIndex)
        if (this.childrenIds.size === 0) {
            if ((monthIndex > currentMonthIndex) || (
                (monthIndex == currentMonthIndex) &&
                ((this._projectedRevenueCache[monthIndex] || 0) >= (this._actualRevenueCache[monthIndex] || 0))
            )) {
                const changeInRevenue = (this._projectedRevenueCache[monthIndex] || 0) - (this._combinedRevenueCache[monthIndex] || 0)
                this.addCombinedRevenue(changeInRevenue, monthIndex)
            }
        }
    }
    addActualRevenue(revenue, monthIndex) {
        const { currentMonthIndex } = this.spreadsheetStore
        if (revenue == undefined) return false
        if (monthIndex > this._actualMaxMonthIndex) this._actualMaxMonthIndex = monthIndex
        if (monthIndex < this._actualMinMonthIndex) this._actualMinMonthIndex = monthIndex
        this._actualRevenueCache[monthIndex] = this._actualRevenueCache[monthIndex] || 0
        this._actualRevenueCache[monthIndex] += revenue
        _.range(monthIndex, this._actualMaxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._actualRevenueToDateCache[mi] != undefined) {
                this._actualRevenueToDateCache[mi] = this.getActualRevenueToDateMonthIndex(mi)
                this._actualRevenueToDateCache[mi] += revenue
            } else {
                this._actualRevenueToDateCache[mi] = this.getActualRevenueToDateMonthIndex(mi)
            }
        })
        if (this.parent) this.parent.addActualRevenue(revenue, monthIndex)
        if (this.childrenIds.size === 0) {
            if ((monthIndex < currentMonthIndex) || (
                (monthIndex == currentMonthIndex) &&
                ((this._actualRevenueCache[monthIndex] || 0) >= (this._projectedRevenueCache[monthIndex] || 0))
            )) {
                const changeInRevenue = (this._actualRevenueCache[monthIndex] || 0) - (this._combinedRevenueCache[monthIndex] || 0)
                this.addCombinedRevenue(changeInRevenue, monthIndex)
            }
        }
    }
    addCombinedRevenue(revenue, monthIndex) {
        if (revenue == undefined) return true
        if (monthIndex > this._combinedMaxMonthIndex) this._combinedMaxMonthIndex = monthIndex
        if (monthIndex < this._combinedMinMonthIndex) this._combinedMinMonthIndex = monthIndex
        this._combinedRevenueCache[monthIndex] = this._combinedRevenueCache[monthIndex] || 0
        this._combinedRevenueCache[monthIndex] += revenue

        _.range(monthIndex, this._combinedMaxMonthIndex + 1).forEach((mi, i) => {
            if (i == 0 || this._combinedRevenueToDateCache[mi] != undefined) {
                this._combinedRevenueToDateCache[mi] = this.getCombinedRevenueToDateMonthIndex(mi)
                this._combinedRevenueToDateCache[mi] += revenue
            } else {
                this._combinedRevenueToDateCache[mi] = this.getCombinedRevenueToDateMonthIndex(mi)
            }
        })

        if (this.parent) this.parent.addCombinedRevenue(revenue, monthIndex)
    }

    getProjectedRevenueMonthIndex(monthIndex) {
        return this._projectedRevenueCache[monthIndex] || 0
    }

    getActualRevenueMonthIndex(monthIndex) {
        return this._actualRevenueCache[monthIndex] || 0
    }

    getCombinedRevenueMonthIndex(monthIndex) {
        return this._combinedRevenueCache[monthIndex] || 0
    }

    getDisplayedRevenueMonthIndex(monthIndex) {
        const { dataType } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedRevenueMonthIndex(monthIndex);
            case "actuals":
                return this.getActualRevenueMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedRevenueMonthIndex(monthIndex);
        }
    }

    getProjectedRevenueToDateMonthIndex(monthIndex) {
        if (!this._projectedMinMonthIndex || monthIndex < this._projectedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._projectedMaxMonthIndex) {
            return this.getProjectedRevenueToDateMonthIndex(this._projectedMaxMonthIndex)
        } else {
            return this._projectedRevenueToDateCache[monthIndex] || this.getProjectedRevenueToDateMonthIndex(monthIndex - 1)
        }
    }

    getActualRevenueToDateMonthIndex(monthIndex) {
        if (!this._actualMinMonthIndex || monthIndex < this._actualMinMonthIndex) {
            return 0
        } else if (monthIndex > this._actualMaxMonthIndex) {
            return this.getActualRevenueToDateMonthIndex(this._actualMaxMonthIndex)
        } else {
            return this._actualRevenueToDateCache[monthIndex] || this.getActualRevenueToDateMonthIndex(monthIndex - 1)
        }

    }

    getCombinedRevenueToDateMonthIndex(monthIndex) {
        if (!this._combinedMinMonthIndex || monthIndex < this._combinedMinMonthIndex) {
            return 0
        } else if (monthIndex > this._combinedMaxMonthIndex) {
            return this.getCombinedRevenueToDateMonthIndex(this._combinedMaxMonthIndex)
        } else {
            return this._combinedRevenueToDateCache[monthIndex] || this.getCombinedRevenueToDateMonthIndex(monthIndex - 1)
        }

    }

    getDisplayedRevenueToDateMonthIndex(monthIndex) {
        const { dataType } = this.spreadsheetStore
        switch (dataType) {
            case "projected":
                return this.getProjectedRevenueToDateMonthIndex(monthIndex);
            case "actuals":
                return this.getActualRevenueToDateMonthIndex(monthIndex);
            default: // actualsProjected
                return this.getCombinedRevenueToDateMonthIndex(monthIndex);
        }
    }

    get totalProjectedRevenue() {
        return this.getDisplayedRevenueToDateMonthIndex(this.maxMonthIndex)
    }

    get monthIndexArray() {
        return _.range(
            Math.min(this.minMonthIndex, this.spreadsheetStore.selectedRevenueMonthIndex),
            Math.max(this.maxMonthIndex, this.spreadsheetStore.selectedRevenueMonthIndex) + 1
        )
    }

    getProjectedBudgetUseMonthIndex(monthIndex) {
        return (this.getProjectedRevenueToDateMonthIndex(monthIndex) / this.totalFee) * 100
    }

    getActualBudgetUseMonthIndex(monthIndex) {
        return (this.getActualRevenueToDateMonthIndex(monthIndex) / this.totalFee) * 100
    }

    getDisplayedBudgetUseMonthIndex(monthIndex) {
        return (this.getDisplayedRevenueToDateMonthIndex(monthIndex) / this.totalFee) * 100
    }


    generateSpreasheetRow(dateColumns, shadow = false) {
        const percent = Math.round((this.totalProjectedRevenue / this.totalFee) * 100)
        const percentString = isFinite(percent) && Math.abs(percent) < 1000 ? ` (${percent}%)` : ``
        const totalString = `$${formatCurrency(Math.round(this.totalProjectedRevenue))} / $${formatCurrency(Math.round(this.totalFee))}` + percentString;
        let error = Math.round(this.totalFee - this.totalProjectedRevenue) < 0;
        return {
            rowType: this.rowDisplayType + (shadow ? " shadow" : ""),
            cells: [
                {
                    value: this.title,
                    uuid: this.uuid,
                    cellType: "title",
                    isRowHeader: true,
                    isEditable: false,
                    expandable: this.expandable && this.childrenIds.size > 0,
                    hideable: this.hideable,
                    expanded: this.expanded,
                    visible: this.visible,
                    addPhaseButton: this.addPhaseButton,
                    row: this,
                },
                {
                    value: totalString,
                    isRowHeader: true,
                    isEditable: false,
                    error: error,
                    visible: this.visible,
                    row: this,
                },
                ...dateColumns.map(d => this.getMonthIndexCell(this, d.monthIndex))
            ]
        }
    }

    getMonthIndexCell(row, monthIndex) {
        if (!monthIndex) return null
        const selectedCell = this.selected && this.spreadsheetStore.selectedRevenueMonthIndex === monthIndex
        const monthInput = selectedCell ? this.spreadsheetStore.selectedRevenueCellInputText : ""
        const monthTotal = Math.round(this.getDisplayedRevenueMonthIndex(monthIndex));
        return {
            value: `$${formatCurrency(monthTotal)}`,
            numValue: monthTotal,
            uuid: this.uuid,
            inputText: monthInput,
            monthIndex: monthIndex,
            row: row,
            visible: this.visible,
            inRange: this.startMonthIndex && this.endMonthIndex && (monthIndex >= this.startMonthIndex && monthIndex <= this.endMonthIndex),
            isProject: true,
            isEditable: this.editable,
            error: false,
        }
    }
}