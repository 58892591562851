import React, { useState } from "react";

export default ({ value, onChange, ...props }) => {
	const [type, setType] = useState("password");
	return (
		<div style={{ position: "relative" }}>
			<input type={type} value={value} onChange={onChange} {...props} />
			<i
				style={{
					cursor: "pointer",
					position: "absolute",
					left: "calc(100% + 0.5em)",
					top: "33%",
					fontSize: "1.25em",
				}}
				className={`fa fa-eye${type === "password" ? "" : "-slash"}`}
				onClick={(e) =>
					setType(type === "password" ? "text" : "password")
				}
			/>
		</div>
	);
};
