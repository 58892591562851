import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';


export const ProjectNote = class extends DataObject {
			/**
			 * ProjectExpense objects aren't real objects in the database,
			 * they are just dicts in the `Project.expenses` list.
			 */
			defaults() {
				return {
					uuid: generateUUID(),
					date: null,
					phaseUuid: null,
					description: "",
				};
			}

			static fieldTypes() {
				return {
					uuid: "string",
					description: "string",
					phaseUuid: "string",
					date: "date",
				};
			}

			static fieldsForSerialize() {
				return ["uuid", "description"];
			}
		};
