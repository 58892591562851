import { Enum } from '../enum.js';


export const ConnectionState = Enum([
  'notConnected',
  'checking',
  'connecting',
  'authWindowOpen',
  'connected',
  'error',
  'disconnecting'
]);

