export const textProperties = (prop, textValue, context) => {
	return textPropertiesLookup[prop]?.(textValue, context);
};
		
const textPropertiesLookup = {
	length: (textValue, context) =>
		({
			type: "number",
			value: textValue.value.length,
		}),
};
        
export const textMethods = {};