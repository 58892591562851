import React from 'react';
import CreateReactClass from 'create-react-class';
import { isNumber, parsesToFloat } from '../utils.js';
import PropTypes from "prop-types";
var math = require('mathjs');


export function numberInputContainerMixin() {
  /**
   * Used both for `NumberInputContainer` and `AutosizeNumber`. Might be better
   * to have a generic component that uses can use <input> or <AutosizeInput>
   * in `render`.
   */

  function executeAction(event) {
    // Delegates to the `executeAction` method defined by `actionMixin` (which
    // is assumed to have also been mixed in by the component that mixed in
    // `numberInputContainerMixin`).
    let allowNull = this.props.allowNull;
    let val = parseNumberInput(event.target.value);
    if (parsesToFloat(val) || (allowNull && val === '')) {
      let floatVal = allowNull ? (parseFloat(val) || '') : parseFloat(val);
      if (this.props.action != null) {
        this.executeAction(floatVal);
      }
      else if (this.props.onChange != null) {
        this.props.onChange(floatVal);
      }
    }
  }

  function parseNumberInput(str) {
    try {
       return math.evaluate(str) != undefined ? math.evaluate(str) : '';
    }
    catch (e) {
       //if math.eval fails just pass string
       return withoutCommas(str);
    }
  }

  return {
    propTypes: {
      executeActionOn: PropTypes.oneOf(['change', 'blur']).isRequired
    },

    getDefaultProps: function() {
      return {
        executeActionOn: 'change'
      };
    },

    componentWillMount: function() {
      this.setState({value: this.props.formatFunc(this.props.value)});
      this._executeAction = executeAction.bind(this);
    },

    componentWillReceiveProps: function(newProps) {
      if (document.activeElement !== this.refs.input) {
        this.setState({value: this.props.formatFunc(newProps.value)});
      }
      else {
        // Note that we can get out of sync here if we get a value we didn't
        // expect.
      }
    },

    handleBlur: function(event) {
      let val = parseNumberInput(event.target.value);
      if (isNumber(val) || (this.props.allowNull && val === '')) {
        this.setState({value: this.props.formatFunc(val)});
        if (this.props.onBlur != null) {
          this.props.onBlur(val);
        }
        this._executeAction(event);
      }
    },

    handleChange: function(event) {
      this.setState({value: event.target.value});

      if (this.props.executeActionOn === 'change') {
        let val = withoutCommas(event.target.value);
        if (this.isValid(val)) {
          let floatVal = parseFloat(val);
          if (this.props.action != null) {
            this.executeAction(floatVal);
          }
          else {
            this.props.onChange(floatVal);
          }
        }
      }
    },

    handleKeyPress: function(event) {
      if (event.key === 'Enter') {
        this.refs.input.blur();
      }
    },

    isValid: function(s) {
      if (this.props.allowNegative) {
        return parsesToFloat(s);
      }
      else {
        return parsesToFloat(s) && s[0] !== '-';
      }
    }
  };
};


function withoutCommas(s) {
  return s.replace(/,/g, '');
};
