import React from 'react';
import CreateReactClass from 'create-react-class';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { trueKeys, isNumber } from '../utils.js';
import { MySelect2, ContactSelector, RadioButton, EditItemControls, SmallDeleteButton,
  CostCentreSelector, BasicMySelect2, InfoAlert } from '../widgets.js';
import { FormSection, FormHeader, FormRow, FormLabel } from '../forms.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { ProjectStatus } from '../models/project.js';
import { organisationStore } from '../organisation.js';
import { actions } from './flux.js';
import { userStore } from '../user.js';
import { StaffMemberCheckboxList } from './StaffMemberCheckboxList.js';
import { ConfirmChangeCostCentreModal } from '../project/ConfirmChangeCostCentreModal';
import { permissions, requiresPermission, OperationLevel } from '../models/permissions.js';
import { Tabs } from '../widgets/tabs.js';


export var EditProjectForm = requiresPermission(
  permissions.noRestrictions,
  {
    financialsPermission: ({project}) => permissions.projectFinancialsBudgets(project),
    hoursPermission: ({project}) => permissions.editProjectBudgets(project),
    deletePermission: ({project}) => permissions.projectAdmin(project)
  },
  CreateReactClass({
    mixins: [
      LinkedStateMixin,
      makeMultipleStoreMixin([organisationStore], function() {
        return {
          staffMembers: organisationStore.staffMembers,
          currencyFormatter: organisationStore.organisation.currencyFormatter
        };
      })
    ],

    getInitialState: function() {
      let self = this;

      if (this.props.project != null) {
        let allocation = this.props.project.allocation.mapHours(h => true).toDict();
        delete allocation[-1];

        return {
          name: this.props.project.name,
          jobCode: this.props.project.jobCode,
          contact: this.props.project.contact,
          oldCostCentre: this.props.project.costCentre,
          costCentre: this.props.project.costCentre,
          fee: this.props.project.fee,
          numHours: this.props.project.hours,
          milestoneType: this.props.project.milestoneType,
          status: this.props.project.status,
          allocation: allocation,
          phases: this.props.project.phases.map(function(phase) {
            let p = phase.copy();
            p.project = self.props.project;
            return p;
          }),
          showDurationField: false,
          hasDuration: this.props.project.getStartDate() != null && this.props.project.getEndDate() != null,
          confirmOverwriteBillabilityPopup: false
        };
      }
      else {
        return {
          name: '',
          jobCode: '',
          contact: null,
          oldCostCentre: null,
          costCentre: organisationStore.getEditableCostCentres(userStore.user)[0],
          fee: 100000,
          numMonths: 12,
          showDurationField: true,
          hasDuration: true,
          numHours: 2000,
          milestoneType: 'monthly',
          status: ProjectStatus.active,
          allocation: {},
          phases: [
            {name: "Phase 1"},
            {name: "Phase 2"},
            {name: "Phase 3"},
            {name: "Phase 4"},
            {name: "Phase 5"},
          ],
          confirmOverwriteBillabilityPopup: false
        };
      }
    },

    render: function() {
      let self = this;

      return <div className="scheduler__project-form">
        {this.state.confirmOverwriteBillabilityPopup ?
          <div className="inner-modal">
            <ConfirmChangeCostCentreModal
              project={this.state}
              onConfirm={this.save}
              onCancel={() => this.setState({confirmOverwriteBillabilityPopup: false})}
              saveState={this.state.saveState}
            />
          </div>
        : null}

        <Tabs
          initialValue="projectDetails"
          tabs={[
            {
              label: "Project details",
              value: 'projectDetails',
              props: {className: "project-details-tab"},
              content: <div>
                <FormHeader>
                  Project details
                </FormHeader>
                <div style={{width: '60em', marginLeft: 'auto', marginRight: 'auto'}}>
                  <div style={{display: 'inline-block', width: '30em', verticalAlign: 'top'}}>
                    <FormSection>
                      <FormRow>
                        <FormLabel style={{width: '7em'}}>
                          Project name:
                        </FormLabel>
                        <input
                          className="lg-input scheduler__project-form__name-input"
                          style={{width: '16em'}}
                          type="text"
                          valueLink={this.linkState('name')}
                        />
                      </FormRow>
                      <FormRow>
                        <FormLabel style={{width: '7em'}}>
                          Job code:
                        </FormLabel>
                        <input
                          type="text"
                          className="sm-input"
                          style={{width: '7em'}}
                          valueLink={this.linkState('jobCode')}
                        />
                      </FormRow>
                      <FormRow>
                        <FormLabel style={{width: '7em'}}>
                          Group:
                        </FormLabel>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', width: '14em'}}>
                          <CostCentreSelector
                            style={{ maxWidth: '100%' }}
                            value={this.state.costCentre}
                            onChange={function(costCentre) {
                              self.setState({costCentre: costCentre});
                            }}
                          />
                        </div>
                      </FormRow>
                      <FormRow>
                        <FormLabel style={{width: '7em'}}>
                          Contact:
                        </FormLabel>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', width: '12em'}}>
                          <ContactSelector
                            style={{ maxWidth: '100%' }}
                            value={this.state.contact}
                            onChange={function(contact) {
                              self.setState({contact: contact});
                            }}
                            context="project"
                          />
                        </div>
                      </FormRow>
                    </FormSection>
                  </div>
                  <div style={{display: 'inline-block', width: '25em', verticalAlign: 'top'}}>
                    <FormSection>
                      {this.props.financialsPermission ?
                        <FormRow>
                          <FormLabel style={{width: '9em'}}>
                            Estimated fee:
                          </FormLabel>
                          {this.state.currencyFormatter.symbol}
                          <input
                            className="sm-input"
                            style={{width: '6em'}}
                            type="text"
                            valueLink={this.linkState('fee')}
                            disabled={this.props.financialsPermission !== OperationLevel.edit}
                          />
                        </FormRow>
                      : null}
                      {this.state.showDurationField ?
                        <FormRow>
                          <FormLabel style={{width: '9em'}}>
                            Duration:
                          </FormLabel>
                          <div style={{display: 'inline-block'}}>
                            <div>
                              <RadioButton
                                valueLink={this.linkState('hasDuration')}
                                identifier={true}
                              />
                              <span
                                  onClick={this.handleDurationLabelClick}
                                  className={!this.state.hasDuration ? 'disabled' : null}>
                                <input
                                  className="project-form__num-months-input"
                                  type="text"
                                  style={{width: '4em'}}
                                  valueLink={this.linkState('numMonths')}
                                  onFocus={this.handleDurationInputFocus}
                                  disabled={this.props.hoursPermission !== OperationLevel.edit}
                                />
                                {' '}
                                months
                              </span>
                            </div>
                            <div>
                              <RadioButton
                                className="project-form__continuous-radio-button"
                                valueLink={this.linkState('hasDuration')}
                                identifier={false}
                              />
                              <label onClick={this.handleContinuousLabelClick}>
                                Continuous
                              </label>
                            </div>
                          </div>
                        </FormRow>
                      : null}

                      {/**
                        * If we're creating a new project, the estimated hours field is always visible, but
                        * it's disabled if they click the 'continuous' radio button. If we're editing an existing
                        * project, the field is visible if and only if the project has a start and end date,
                        * and nothing the user can do within the form can change that.
                        */}
                      {this.props.project == null || this.state.hasDuration ?
                        <FormRow className={!this.state.hasDuration ? 'disabled' : null}>
                          <FormLabel style={{width: '9em'}}>
                            Estimated hours:
                          </FormLabel>
                          <input
                            className="sm-input estimated-hours-input"
                            type="text"
                            style={{width: '5em'}}
                            value={isNumber(this.state.numHours) ? Math.round(this.state.numHours) : this.state.numHours}
                            disabled={!this.state.hasDuration || this.props.hoursPermission !== OperationLevel.edit}
                            title={!this.state.hasDuration ? "A project needs a duration to have estimated hours." : null}
                            onChange={function(event) {
                              self.setState({numHours: parseInt(event.target.value)});
                            }}
                          />
                          {' '}
                          hours
                        </FormRow>
                      : null}

                      <FormRow>
                        <FormLabel style={{width: '9em'}}>
                          Billing:
                        </FormLabel>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', width: '14em'}}>
                          <MySelect2
                            className="md-input"
                            valueLink={this.linkState('milestoneType')}
                            options={[
                              {label: 'End of the month', value: 'monthly'},
                              {label: 'On milestone completion', value: 'manual'}
                            ]}
                            getObjectValue={o => o.value}
                            getObjectLabel={function(o) {
                              return <span>{o.label}</span>;
                            }}
                          />
                        </div>
                      </FormRow>
                      <FormRow>
                        <FormLabel style={{width: '9em'}}>
                          Status:
                        </FormLabel>
                        <div style={{display: 'inline-block', verticalAlign: 'middle', width: '12em'}}>
                          <BasicMySelect2
                            options={ProjectStatus.getOptions()}
                            value={this.state.status}
                            onChange={(status) => this.setState({status: status})}
                          />
                        </div>
                      </FormRow>
                    </FormSection>
                  </div>
                </div>
              </div>
            },
            {
              label: "Phases",
              value: 'phases',
              props: {className: "phases-tab"},
              content: <div>
                <FormHeader>
                  Phases
                </FormHeader>
                <table style={{marginLeft: 'auto', marginRight: 'auto'}}>
                  <tbody>
                    <tr>
                      <td>
                        Phase Code
                      </td>
                      <td>
                        Phase Name
                      </td>
                      <td>
                      </td>
                    </tr>
                    {this.state.phases.map(function(phase, phaseIndex) {
                      if (phase.isDeleted) {
                        return null;
                      }
                      return <tr key={phaseIndex}>
                        <td>
                          <input
                            type="text"
                            style={{width: '8em'}}
                            value={phase.jobCode}
                            onChange={function(event) {
                              phase.jobCode = event.target.value;
                              self.setState({phases: self.state.phases});
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={phase.name}
                            onChange={function(event) {
                              phase.name = event.target.value;
                              self.setState({phases: self.state.phases});
                            }}
                            style={{
                              paddingRight: '1em',
                              width: '24em'
                            }}
                          />
                        </td>
                        <td>
                          <SmallDeleteButton
                            onClick={function() {
                              phase.isDeleted = true;
                              self.setState({phases: self.state.phases});
                            }} />
                        </td>
                      </tr>;
                    })}
                    <tr>
                      <td colSpan="3">
                        <button
                          className="btn btn-default"
                          style={{marginTop: '2em'}}
                          onClick={function() {
                            self.setState({phases: [...self.state.phases, {name: '', jobCode: ''}]});
                          }} >
                          + Add Phase
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            },
            {
              label: "Staff",
              value: 'staff',
              props: {className: "staff-tab"},
              content: <div>
                <FormHeader>
                  Staff
                </FormHeader>
                {this.state.hasDuration ?
                  <StaffMemberCheckboxList
                    style={{width: '55em', marginLeft: 'auto', marginRight: 'auto'}}
                    staffMembers={this.state.staffMembers}
                    allocation={this.state.allocation}
                    onChange={function(allocation) {
                      self.setState({allocation: allocation});
                    }}
                  />
                :
                  <InfoAlert style={{margin: '1em 4em'}}>
                    <p>
                      {this.props.project != null ?
                        <span>A project needs a start and end date to have staff assigned.</span>
                      :
                        <span>Continuous projects cannot have staff assigned.</span>
                      }
                    </p>
                  </InfoAlert>
                }
              </div>
            }
          ]}
        />

        <div style={{borderTop: '1px solid #e5e5e5'}}>
          <EditItemControls
            object={this.props.project}
            objectTypeName="project"
            onCancel={this.handleCancelClick}
            onSave={this.handleSaveClick}
            onDelete={this.handleDelete}
            canDelete={this.props.deletePermission}
          />
        </div>
      </div>;
    },

    handleDurationLabelClick: function() {
      this.setState({hasDuration: true});
    },

    handleDurationInputFocus: function() {
      this.setState({hasDuration: true});
    },

    handleContinuousLabelClick: function() {
      this.setState({
        hasDuration: false,
        numHours: ''
      });
    },

    handleSaveClick: function() {
      if (this.state.oldCostCentre != null && this.state.oldCostCentre.id !== this.state.costCentre.id) {
        this.setState({confirmOverwriteBillabilityPopup: true});
      }
      else {
        this.save();
      }
    },

    save: function() {
      let data = {
        name: this.state.name,
        costCentre: this.state.costCentre,
        contact: this.state.contact,
        jobCode: this.state.jobCode,
        fee: parseInt(this.state.fee),
        phases: this.state.phases,
        hasDuration: this.state.hasDuration,
        numMonths: parseFloat(this.state.numMonths),
        milestoneType: this.state.milestoneType,
        status: this.state.status,
        staffMembers: trueKeys(this.state.allocation).map(id => organisationStore.getStaffMemberById(parseInt(id))),
      };

      if (this.state.hasDuration) {
        data.numHours = parseInt(this.state.numHours);
      }

      actions.saveEditProject(this.props.project, data);
      actions.closeModal(this.props.modal);
    },

    handleCancelClick: function() {
      actions.closeModal(this.props.modal);
    },

    handleDelete: function() {
      actions.confirmDeleteProject(this.props.project);
    }
  })
);
