import {
	divideDurationBy,
	simplifyDuration,
	durationToUnit,
	simplifyRate,
	divideDurations,
	dividePowerDurationByDuration,
	divideRateBy,
	divideNumberByRate,
	divideRates,
} from "@ryki/datemath";
import { isZeroValue } from "../FormulaUtils";

export default ({ left, right }, context) => {
	const dividerId = left.type + right.type;
	if (!dividerValues[dividerId]) {
		throw {
			value: `Cannot divide a ${left.type} to a ${right.type}.`,
			type: "error",
		};
	}
	if (isZeroValue[right.type](right.value)) {
		throw {
			value: `Cannot divide by zero.`,
			type: "error",
		};
	}
	return {
		type: dividerTypes[dividerId],
		value: dividerValues[dividerId](left, right),
	};
};

const dividerTypes = {
	numbernumber: "number",
	numberduration: "rate",
	durationnumber: "duration",
	durationduration: "number",
	numberrate: "duration",
	ratenumber: "rate",
	raterate: "number",
	powerDurationduration: "duration",
};

const dividerValues = {
	numbernumber: (left, right) => left.value / right.value,
	numberduration: (left, right) => {
		return simplifyRate({ amount: left.value, per: right.value });
	},
	durationnumber: (left, right) =>
		simplifyDuration(divideDurationBy(left.value, right.value)),
	durationduration: (left, right) => {
		return divideDurations(left.value, right.value);
	},
	numberrate: (left, right) => {
		return divideNumberByRate(left.value, right.value);
	},
	ratenumber: (left, right) => {
		return divideRateBy(left.value, right.value);
	},
	raterate: (left, right) => {
		return divideRates(left.value, right.value);
	},
	powerDurationduration: (left, right) => {
		return simplifyDuration(
			dividePowerDurationByDuration(left.value, right.value)
		);
	},
};
