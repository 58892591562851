import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { actions } from './flux.js';
import { SaveButton, DeleteButton } from '../widgets.js';

import PropTypes from "prop-types";

export var EditPhaseForm = CreateReactClass({
  mixins: [LinkedStateMixin],

  propTypes: {
    modal: PropTypes.object.isRequired,
    phase: PropTypes.object
  },

  getInitialState: function() {
    if (this.props.phase != null) {
      return {
        jobCode: this.props.phase.jobCode,
        name: this.props.phase.name
      };
    }
    else {
      return {
        jobCode: '',
        name: ''
      };
    }
  },

  render: function() {
    return <div className="modal-section">
      {/* For some reason this table takes over 100% width if we don't set the width here */}
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            <td>
              Phase Code
            </td>
            <td>
              Phase Name
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                className="lg-input"
                style={{
                  textAlign: 'left',
                  paddingRight: '1em'
                }}
                valueLink={this.linkState('jobCode')}
              />
            </td>
            <td>
              <input
                type="text"
                className="lg-input"
                style={{
                  textAlign: 'left',
                  paddingRight: '1em'
                }}
                valueLink={this.linkState('name')}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="modal-footer">
        {this.props.phase != null && this.props.phase.project.getVisiblePhases().length >= 2 ?
          <DeleteButton
            text="Delete Phase"
            onClick={this.handleDeleteButtonClick} />
        : null}
        <button
            className="btn btn-default"
            onClick={this.handleCancelButtonClick}>
          Cancel
        </button>
        <SaveButton
          text="Save Phase"
          onClick={this.handleSaveButtonClick}
        />
      </div>
    </div>;
  },

  handleSaveButtonClick: function() {
    actions.editPhaseModalSave(
      this.props.modal,
      this.props.phase,
      {
        name: this.state.name,
        jobCode: this.state.jobCode
      }
    );
  },

  handleCancelButtonClick: function() {
    actions.closeModal(this.props.modal);
  },

  handleDeleteButtonClick: function() {
    actions.editPhaseModalDelete(this.props.modal, this.props.phase);
  }
});



