import { observable, computed, action, makeObservable } from "mobx";
import budgetStore from "../calendar/budgetStore";
import cuid from "cuid";
import apiRequest from "../apiRequest";
import { userStore } from "../user";
import { organisationStore } from "../organisation";
import { PermissionLevel } from "../models/permissions";
import { getProjectOptions } from "../widgets/ProjectSelector";
import { differenceInMinutes, format, startOfDay, subSeconds } from "date-fns";
import localforage from "localforage";
import superjson from "superjson";
import timeEntryModel from "../calendar/timeEntryModel";

class timerStore {
	@observable timerState = "idle";
	@observable saveState = "idle";
	@observable selectedValues = {
		projectId: null,
		projectPhaseId: null,
		taskUuid: null,
		staffMemberId: null,
		isBillable: true,
		isVariation: false,
		isOvertime: false,
		isLocked: false,
		beenInvoiced: false,
		remote: false,
		flexi: false,
	};
	@observable budgetType = "phase";
	@observable prevSeconds = 0;
	@observable startTime = null;
	@observable currentTime = null;
	@observable open = false;
	@observable initialized = false;
	@observable timeEntries = [];
	timerInterval = null;
	constructor() {
		makeObservable(this);
		this.saveTimeEntry = this.saveTimeEntry.bind(this);
	}
	@action.bound
	init() {
		// make conditional so it doesn't reset
		if (!this.selectedValues.projectId) this.setDefaults();
		apiRequest({
			path: "/api/v2/timesheet/daily",
			method: "post",
			data: {
				staff: userStore.user.id,
				startDate: format(new Date(), "yyyy-MM-dd"),
				noCache: new Date().getTime(),
			},
			success: (data) => {
				this.timeEntries = data.time.map((d) => new timeEntryModel(d));
			},
		});
		// if (!this.timerInterval) this.startTimer();
	}
	@action.bound
	tick() {
		this.currentTime = Date.now();
	}
	@action.bound
	startTimer() {
		clearInterval(this.timerInterval);
		this.startTime = Date.now();
		this.timerInterval = setInterval(this.tick, 1000);
		this.timerState = "running";
		this.storeLocal();
	}
	@action.bound
	pauseTimer() {
		this.prevSeconds = this.seconds;
		// this.startTime = null;
		this.currentTime = null;
		clearInterval(this.timerInterval);
		this.timerState = "idle";
		this.storeLocal();
	}
	@action.bound
	resetTimer() {
		this.pauseTimer();
		this.prevSeconds = 0;
		this.selectedValues.notes = "";
		this.storeLocal();
	}
	@computed
	get currentSeconds() {
		return this.startTime && this.currentTime
			? (this.currentTime - this.startTime) / 1000
			: 0;
	}
	@computed
	get seconds() {
		return this.prevSeconds + this.currentSeconds;
	}
	@computed
	get timeDisplay() {
		return new Date(this.seconds * 1000).toISOString().substr(12, 7);
	}
	@action.bound
	toggleOpen() {
		this.open = !this.open;
	}
	@action.bound
	setDefaults(selectedValues) {
		const setDefaults = () => {
			const project = getProjectOptions(
				this.mode === "allocation"
					? PermissionLevel.projectManager
					: PermissionLevel.timesheet
			).filter((p) =>
				organisationStore.organisation.settings.timeEntryStatus.includes(
					p.status
				)
			)[0];
			const phase = project
				?.getVisiblePhases()
				.filter((p) =>
					organisationStore.organisation.settings.timeEntryStatus.includes(
						p.status
					)
				)[0];
			const task = phase?.tasks.toJS()[0];
			this.selectedValues = {
				projectId: project?.id,
				projectPhaseId: phase?.id,
				taskUuid: task?.uuid,
				staffMemberId: userStore.user?.id,
				isBillable: task?.isBillable ?? true,
				isVariation: task?.isVariation ?? false,
				isOvertime: false,
				isLocked: false,
				beenInvoiced: false,
				remote: false,
				flexi: false,
				...selectedValues,
			};
			budgetStore.getBudget(
				new Date(), //weekStart
				new Date(), //selectedDate
				this.mode,
				this.selectedValues.staffMemberId,
				this.selectedValues.projectId,
				this.selectedValues.projectPhaseId
			);
			this.storeLocal();
			this.initialized = true;
		};
		localforage
			.getItem("timer")
			.then((v) => {
				if (!v) {
					setDefaults();
				} else {
					v = superjson.parse(v);
					this.timerState = v.timerState;
					this.selectedValues = v.selectedValues;
					this.budgetType = v.budgetType;
					this.prevSeconds = v.prevSeconds;
					this.startTime = v.startTime;
					if (this.timerState !== "idle") {
						this.currentTime = Date.now();
						clearInterval(this.timerInterval);
						this.timerInterval = setInterval(this.tick, 1000);
						this.timerState = "running";
					}
					budgetStore.getBudget(
						new Date(), //weekStart
						new Date(), //selectedDate
						this.mode,
						this.selectedValues.staffMemberId,
						this.selectedValues.projectId,
						this.selectedValues.projectPhaseId
					);
					this.initialized = true;
				}
			})
			.catch(setDefaults);
	}
	@action.bound
	updateSelectedValues(data) {
		this.selectedValues = {
			...this.selectedValues,
			...data,
		};
		if ("projectId" in data) {
			const project = organisationStore.getProjectById(data.projectId);
			const phase = project?.getVisiblePhases()[0];
			const task = phase?.tasks.toJS()[0];
			this.selectedValues = {
				...this.selectedValues,
				projectPhaseId: phase?.id,
				taskUuid: task?.uuid,
				isBillable: task?.isBillable ?? true,
				isVariation: task?.isVariation ?? false,
			};
		}
		if ("projectPhaseId" in data) {
			const phase = organisationStore.getProjectPhaseById(
				data.projectPhaseId
			);
			const task = phase.tasks.toJS()[0];
			this.selectedValues = {
				...this.selectedValues,
				taskUuid: task.uuid,
				isBillable: task?.isBillable ?? true,
				isVariation: task?.isVariation ?? false,
			};
		}
		if (this.selectedItem) {
			this.editSelectedItem(this.selectedValues);
		}
		budgetStore.getBudget(
			new Date(), //weekStart
			new Date(), //selectedDate
			this.mode,
			this.selectedValues.staffMemberId,
			this.selectedValues.projectId,
			this.selectedValues.projectPhaseId
		);
		this.storeLocal();
	}
	@action.bound
	changeBudgetType(newType) {
		this.budgetType = newType;
		this.storeLocal();
	}
	@computed
	get budget() {
		if (this.budgetType === "phase") {
			return {
				budget: budgetStore.phaseBudget,
				use: budgetStore.phaseBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.phaseBudget
					? (budgetStore.phaseBudgetUse +
							((this.seconds / 60 + this.draggingChangeMinutes) /
								60 || 0)) /
					  budgetStore.phaseBudget
					: 0,
			};
		}
		if (this.budgetType === "role") {
			return {
				budget: budgetStore.roleBudget,
				use: budgetStore.roleBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.roleBudget
					? (budgetStore.roleBudgetUse + this.seconds / 60 / 60) /
					  budgetStore.roleBudget
					: 0,
			};
		}
		if (this.budgetType === "staff") {
			return {
				budget: budgetStore.staffBudget,
				use: budgetStore.staffBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.staffBudget
					? (budgetStore.staffBudgetUse + this.seconds / 60 / 60) /
					  budgetStore.staffBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyPhase") {
			return {
				budget: budgetStore.monthlyPhaseBudget,
				use: budgetStore.monthlyPhaseBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.monthlyPhaseBudget
					? (budgetStore.monthlyPhaseBudgetUse +
							this.seconds / 60 / 60) /
					  budgetStore.monthlyPhaseBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyRole") {
			return {
				budget: budgetStore.monthlyRoleBudget,
				use: budgetStore.monthlyRoleBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.monthlyRoleBudget
					? (budgetStore.monthlyRoleBudgetUse +
							this.seconds / 60 / 60) /
					  budgetStore.monthlyRoleBudget
					: 0,
			};
		}
		if (this.budgetType === "monthlyStaff") {
			return {
				budget: budgetStore.monthlyStaffBudget,
				use: budgetStore.monthlyStaffBudgetUse + this.seconds / 60 / 60,
				percent: budgetStore.monthlyStaffBudget
					? (budgetStore.monthlyStaffBudgetUse +
							this.seconds / 60 / 60) /
					  budgetStore.monthlyStaffBudget
					: 0,
			};
		}
	}
	@action
	saveTimeEntry() {
		this.pauseTimer();
		this.saveState = "saving";
		const timeEntryData = {
			uuid: cuid(),
			costCentreId: organisationStore.getProjectById(
				this.selectedValues.projectId
			).costCentreId,
			projectId: this.selectedValues.projectId,
			projectPhaseId: this.selectedValues.projectPhaseId,
			taskUuid: this.selectedValues.taskUuid,
			staffMemberId: this.selectedValues.staffMemberId,
			date: format(this.startTime, "yyyy-MM-dd"),
			numMinutes: Math.round(this.seconds / 60),
			startMinutes: Math.round(
				differenceInMinutes(
					subSeconds(this.startTime, this.prevSeconds),
					startOfDay(this.startTime)
				)
			),
			notes: this.selectedValues.notes,
			isBillable: this.selectedValues.isBillable ?? true,
			isVariation: this.selectedValues.isVariation ?? false,
			isOvertime: this.selectedValues.isOvertime ?? false,
			remote: this.selectedValues.remote ?? false,
			flexi: this.selectedValues.flexi ?? false,
			isLocked: this.selectedValues.isLocked ?? false,
			beenInvoiced: this.selectedValues.beenInvoiced ?? false,
		};
		this.request = apiRequest({
			path: "/api/v2/timesheet/weekly/save",
			method: "post",
			data: {
				time: [timeEntryData],
				deleted: [],
			},
			success: (data) => {
				this.saveState = "saved";
				this.resetTimer();
				this.timeEntries.push(new timeEntryModel(timeEntryData));
				setTimeout(() => (this.saveState = "idle"), 500);
			},
		});
	}
	@action
	storeLocal() {
		localforage.setItem(
			"timer",
			superjson.stringify({
				timerState: this.timerState,
				selectedValues: this.selectedValues,
				budgetType: this.budgetType,
				prevSeconds: this.prevSeconds,
				startTime: this.startTime,
			})
		);
	}
	@computed
	get interfaceDisabled() {
		return !this.initialized || this.saveState !== "idle";
	}
}

export default new timerStore();
