import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeStoreMixin } from '../coincraftFlux.js';
import { MySelect2 } from "../widgets.js";
import { countries, getCountryCodeViaGeolocation } from "../countries.js";
import { userStore, actions } from './flux.js';
import { isNonEmptyString } from '../utils.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import classNames from 'classnames';


export var UserDetailsComponent = CreateReactClass({
  mixins: [
    makeStoreMixin(userStore, function() {
      let user = userStore.getUser();
      return {
        user: user,
        ...getValidityState(user)
      };
    })
  ],

  componentWillMount: function() {
    let self = this;
    if (this.state.user != null && this.state.user.country == null) {
      self.setState(updateUserField(self.state.user, 'country', 'loading'));
      getCountryCodeViaGeolocation().then(function(countryCode) {
        self.setState(updateUserField(self.state.user, 'country', countryCode));
      }, function(error) {
        self.setState(updateUserField(self.state.user, 'country', null));
      });
    }
  },

  render: function() {
    let self = this;
    return (
      <div className="user_details_form">
        <div
          className="flexbox-container flex-align-items-center flex-justify-content-center"
          style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, textAlign: 'center'}}>
          <section
              className={classNames(
                "login-widget", {
                  "login-widget--login-mode": this.props.route.name === 'login',
                  "login-widget--register-mode": this.props.route.name === 'register'
                })}>
            <header className="text-align-center">
              <div style={{width: '10em', height: '10em'}} className="logo2 s75">
                <img src={process.env.PUBLIC_URL + "/coincraft_logo_130.png"} />
              </div>
              <h1 style={{fontSize: '5em'}}>coincraft</h1>
            </header>
            <div className="body">
              <p style={{marginBottom: '1.5em'}}>
                We just need to know a few more things so we can finish setting up your account.
              </p>
              <form ref="userDetailsForm" className="no-margin" noValidate style={{textAlign: 'left'}}>
                <fieldset>
                  <div className="form-group">
                    <div className="control-group">
                      <label className="control-label" htmlFor="user-details-form__first-name">
                        What's your name?
                      </label>
                      <div className="controls form-group">
                        <div className="user_details_form__name-input first">
                          <input
                            id="user-details-form__first-name"
                            value={self.state.user.firstName}
                            onChange={self.handleFirstNameChange}
                            type="text"
                            className="form-control"
                            placeholder="First name" />
                        </div>
                        <div className="user_details_form__name-input">
                          <input
                            id="user-details-form__last-name"
                            value={self.state.user.lastName}
                            onChange={self.handleLastNameChange}
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="control-group" style={{clear: 'both'}}>
                      {self.state.submitted && (!self.state.firstNameValid || !self.state.lastNameValid) ?
                        <div className="popover error-message-popover bottom" style={{marginBottom: '20px'}}>
                          {!self.state.firstNameValid ?
                            <div className="arrow" style={{left: '25%'}} />
                          : null}
                          {!self.state.lastNameValid ?
                            <div className="arrow" style={{left: '75%'}} />
                          : null}
                          <div className="popover-content">Please enter your name.</div>
                        </div>
                      : null}
                    </div>
                    <div className="control-group">
                      <label className="control-label" htmlFor="user-details-form__organisation-name">
                        What is your organisation called?
                      </label>
                      <div className="controls form-group">
                        <div>
                          <input
                            value={self.state.user.organisationName}
                            onChange={self.handleOrganisationNameChange}
                            type="text"
                            id="user-details-form__organisation-name"
                            className="form-control"
                            placeholder="Organisation name"
                          />
                        </div>
                    </div>
                  </div>
                  <div className="control-group" style={{clear: 'both'}}>
                    {self.state.submitted && !self.state.organisationNameValid ?
                      <div className="popover error-message-popover bottom" style={{marginBottom: '20px'}}>
                        <div className="arrow"></div>
                        <div className="popover-content">Please enter your organisation's name.</div>
                      </div>
                    : null}
                  </div>
                  <div className="control-group">
                    <label className="control-label" htmlFor="user-details-form__country">Which country are you from?</label>
                    <div className="controls form-group">
                      <div>
                        {this.state.user.country === 'loading' ?
                          <div style={{textAlign: 'center'}}>
                            <i className="fa fa-spinner fa-pulse" />
                          </div>
                        :
                          <div className="user-details-form__country">
                            <CountrySelector
                              value={this.state.user.country}
                              onChange={this.handleCountryChange}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="control-group"  style={{clear: 'both'}}>
                    {self.state.submitted && !self.state.countryValid ?
                      <div className="popover error-message-popover bottom" style={{marginBottom: '20px'}}>
                        <div className="arrow"></div>
                        <div className="popover-content">Please enter your country.</div>
                      </div>
                    : null}
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label" htmlFor="user-details-form__country">
                    Coupon code?
                  </label>
                  <div className="controls form-group">
                    <div>
                      <input
                        id="user-details-form__coupon_code"
                        value={self.state.user.couponCode}
                        onChange={self.handleCouponCodeChange}
                        type="text"
                        className="form-control"
                        placeholder="If you have a coupon code, enter it here"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="form-actions">
                <button
                    onClick={self.save}
                    className="btn btn-block btn-lg btn-info user_details_form__submit_button"
                    disabled={this.state.user.country === 'loading'}>
                  <span><i className="fa fa-play"></i></span> &nbsp;
                  <small>Start Using Coincraft!</small>
                </button>
                <div className="forgot"></div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
    );
  },

  save: function(event) {
    try {
      this.setState({submitted: true});
      if (this.state.firstNameValid
          && this.state.lastNameValid
          && this.state.organisationNameValid
          && this.state.countryValid) {
        actions.saveUserDetails(this.state.user);
      }
    }
    catch(e) {
      console.error(e);
    }
    finally {
      event.preventDefault();
    }
  },

  handleFirstNameChange: function(event) {
    this.setState(updateUserField(this.state.user, 'firstName', event.target.value));
  },

  handleLastNameChange: function(event) {
    this.setState(updateUserField(this.state.user, 'lastName', event.target.value));
  },

  handleOrganisationNameChange: function(event) {
    this.setState(updateUserField(this.state.user, 'organisationName', event.target.value));
  },

  handleCountryChange: function(countryCode) {
    this.setState(updateUserField(this.state.user, 'country', countryCode));
  },

  handleCouponCodeChange: function(event) {
    this.setState(updateUserField(this.state.user, 'couponCode', event.target.value));
  }
});

function updateUserField(user, fieldName, value) {
  user = user.set(fieldName, value);
  return {
    user: user,
    ...getValidityState(user)
  };
}

function getValidityState(user) {
  return {
    firstNameValid: isNonEmptyString(user.firstName),
    lastNameValid: isNonEmptyString(user.lastName),
    organisationNameValid: isNonEmptyString(user.organisationName),
    countryValid: isNonEmptyString(user.country),
  };
}


var CountrySelector = CreateReactClass({
  mixins: [
    PureRenderMixin
  ],

  getInitialState: function() {
    return {
      countryOptions: [null, ...countries]
    };
  },

  render: function() {
    return <MySelect2
      value={this.props.value}
      options={this.state.countryOptions}
      filter={function(country, searchText) {
        if (country == null) {
          return false;
        }
        else {
          return country.name.toLowerCase().match(searchText.toLowerCase());
        }
      }}
      getObjectLabel={function(country) {
        if (country != null) {
          return <span>{country.name}</span>;
        }
        else {
          return <span>(Please select)</span>;
        }
      }}
      getObjectValue={function(country) {
        if (country != null) {
          return country.code;
        }
        else {
          return null;
        }
      }}
      onChange={this.props.onChange}
    />;
  }
});
