import tuple from "immutable-tuple";

export let FormulaStore = new Map();
export let DependencyStore = new Map();
export let ForumlasToUpdate = new Set();

export const storeFormula = (formula) => {
    FormulaStore.set(formula.context, formula)
}
export const storeDependency = (dependencyRef, formula) => {
    DependencyStore.set(dependencyRef, DependencyStore.get(dependencyRef) ?? new Set());
    DependencyStore.get(dependencyRef).add(formula)
}
export const deleteDependency = (dependencyRef, formula) => {
    const dependants = DependencyStore.get(dependencyRef)
    dependants && dependants.delete(formula);
    if (!dependants.size) DependencyStore.delete(dependencyRef);
};

export const updateDependencies = (formula, oldDeps, newDeps) => {
    for (const depRef of oldDeps.keys()) {
        deleteDependency(depRef, formula)
    }
    for (const depRef of newDeps.keys()) {
		storeDependency(depRef, formula);
    }
}

export const disposeFormula = (formula) => {
    FormulaStore.delete(formula.context);
    for (const depRef of formula.dependencies.keys()) {
        DependencyStore.get(depRef).delete(formula);
    }
};

export const getDependants = (formula) => {
    // console.log(
    //     "🚀 ~ file: FormulaStore.js ~ line 41 ~ getDependants ~ DependencyStore.get(formula.context)",
	// 	DependencyStore
	// );    
    return DependencyStore.get(formula.context) || new Set();
}

export const updateObjRefs = (oldRef, newRef) => {
    for (const formulaRef of FormulaStore.keys()) {
        if (formulaRef[0] === oldRef) {
            const newFormulaRef = tuple(newRef, formulaRef[1])
            const formula = FormulaStore.get(formulaRef);
            FormulaStore.delete(formulaRef);
            FormulaStore.set(newFormulaRef, formula);
        }
	}
    for (const depRef of DependencyStore.keys()) {
		if (depRef[0] === oldRef) {
			const dependants = DependencyStore.get(depRef);
			for (const dep of dependants) {
                replaceDependencyRef(dep)
            }
		}
	}
}

export const replaceDependencyRef = (formula, oldRef, newRef) => {
    const oldString = formula.formula;
    const newString = oldString.replace(`#{oldRef}`, `#{newRef}`);
    if (oldString !== newString) {
        formula.setFormula(newString)
    }
}

export const prepareFormulaForUpdate = (formula) => {
    ForumlasToUpdate.add(formula)
}
export const updateFormulas = () => {
    console.log(
        "🚀 ~ file: FormulaStore.js ~ line 76 ~ updateFormulas ~ ForumlasToUpdate",
        ForumlasToUpdate
    );
	for (const formula of ForumlasToUpdate) {
        formula.update();
    }
    ForumlasToUpdate = new Set();
};