import { addDurationToDate } from "@ryki/datemath";
export default ({ date, time }, context) => {
	return {
		type: "dateTime",
		value: time?.value
			? addDurationToDate(date.value, time.value).getTime()
			: date.value,
		primitive: true,
	};
};
