import React from 'react';
import CreateReactClass from 'create-react-class';
import { isNumber, formatNumber2 } from '../utils.js';
import { actionMixin } from './utils.js';
import { numberInputContainerMixin } from './numberInputContainerMixin.js';
import AutosizeInput from 'react-input-autosize';

import PropTypes from "prop-types";


export var AutosizeNumber = CreateReactClass({
  propTypes: {
    value: PropTypes.number,

    // Should have either `onChange` or `action` (which uses `actionMixin`).
    onChange: PropTypes.func,
    formatFunc: PropTypes.func
  },

  getDefaultProps: function() {
    return {
      formatFunc: n => isNumber(n) ? formatNumber2(n) : ''
    };
  },

  mixins: [
    actionMixin,
    numberInputContainerMixin()
  ],

  render: function() {
    let {
      value: _value,
      onChange: _onChange,
      formatFunc: _formatFunc,
      actionArgs: _actionArgs,
      executeActionOn: _executeActionOn,
      ...props
    } = this.props;
    return <AutosizeInput
      ref="input"
      value={this.state.value}      // from `numberInputContainerMixin`
      onBlur={this.handleBlur}      // from `numberInputContainerMixin`
      onChange={this.handleChange}  // from `numberInputContainerMixin`
      {...props}
    />;
  },

  focus: function() {
    this.refs.input.focus();
  }
});
