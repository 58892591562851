import React from 'react';
import CreateReactClass from 'create-react-class';
import { ModalFooter } from '../modal.js';
import { DateValue, InfoAlert } from '../widgets.js';

import PropTypes from "prop-types";


export var EditInvoiceDatesPopup = CreateReactClass({
  propTypes: {
    invoice: PropTypes.object.isRequired
  },

  getInitialState: function() {
    return {
      startDate: this.props.invoice.startDate.clone(),
      endDate: this.props.invoice.endDate.clone()
    };
  },

  render: function() {
    const showUpdateMessage = (this.props.invoice.phases.some(function(phase) {
      return phase.lineItems.some(li => li.lineItemType === 'timesheets');
    }));
    return (
      <div>
        <div style={{padding: '2em'}}>
          <div style={{height: 40}}>
            <div style={{display: 'inline-block', width: 140}}>
              From:
            </div>
            <div style={{display: 'inline-block'}}>
              <DateValue
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
              />
            </div>
          </div>
          <div style={{height: 40}}>
            <div style={{display: 'inline-block', width: 140}}>
              To:
            </div>
            <div style={{display: 'inline-block'}}>
              <DateValue
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
              />
            </div>
          </div>
        </div>
        {showUpdateMessage ?
          <InfoAlert>
            These changes will not affect timesheet line items you have already
            added to the invoice.
          </InfoAlert>
        : null}
        <ModalFooter
          acceptButtonText="Save invoice dates"
          onSubmit={() => this.props.onSubmit(this.state.startDate, this.state.endDate)}
          onCancel={this.props.onCancel}
        />
      </div>
    );
  },

  handleStartDateChange: function(date) {
    this.setState({startDate: date});
  },

  handleEndDateChange: function(date) {
    this.setState({endDate: date});
  }
});
