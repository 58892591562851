import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { AccountingSystem } from './base.js';
import { AccountSelectors } from './AccountSelectors.js';
import PropTypes from "prop-types";


export const QuickBooksAccountingSystem = class extends AccountingSystem {
  constructor(options) {
    super(options);
    this.identifier = "quickbooks";
    this.name = "QuickBooks";
    this.shortName = "QuickBooks";
  }

  getDefaultSettings() {
    return {
      salesAccount: null,
      taxOnTaxType: null,
      taxOffTaxType: null
    };
  }

  requiresContact() {
    return true;
  }

  canSaveSettings(settings) {
    return (settings != null
      && settings.taxOnTaxType != null
      && settings.taxOffTaxType != null
    );
  }

  getSettingsComponent() {
    return QuickbooksSettings;
  }
}


var QuickbooksSettings = CreateReactClass({
  propTypes: {
    data: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  },

  render: function() {
    return <div>
      <div>
        <AccountSelectors
          accountingSystemShortName="QuickBooks"
          includeAccounts={false}
          data={this.props.data}
          settings={this.props.settings}
          onChange={(settings) => this.props.onChange(settings)}
        />
      </div>
    </div>;
  }
});

