import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { invoicePageStore, invoiceActions } from './InvoicePageStore.js';
import { organisationStore } from '../organisation.js';
import { ConnectionState } from './ConnectionState.js';

import PropTypes from "prop-types";


export var AccountingSystemAuth = CreateReactClass({
  propTypes: {
    accountingSystem: PropTypes.string.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([invoicePageStore, organisationStore], function() {
      // We want to be notified on updates but we don't want to get a
      // connection here because it doesn't seem we can rely on
      // `this.props.accountingSystem` to be correctly in sync with the
      // `invoicePageStore` data at this point, so we pull the connection data
      // in `render`.
      return {};
    })
  ],

  render: function() {
    const ac = organisationStore.getAccountingSystemByIdentifier(this.props.accountingSystem);
    const connection = ac.connection;

    return (
      <div className="accounting-system-connection-widget">
        {_.include([ConnectionState.connected, ConnectionState.disconnecting], connection.state) ?
          <span className="accounting-system-connection-widget__connected-message">
            <span>
              Connected to {ac.name}
            </span>
            <button
                className="btn btn-default btn-sm"
                onClick={() => invoiceActions.disconnect(this.props.accountingSystem)}
                disabled={connection.state === ConnectionState.disconnecting}
                style={{marginLeft: '1em', borderRadius: '4px'}}>
              <i className="fa fa-chain-broken" />
              {connection.state === ConnectionState.connected ? "Disconnect" : "Disconnecting..."}
            </button>
          </span>
        :
          connection.state === ConnectionState.checking ?
            <span>
              <span>
                <i className="fa fa-spinner fa-spin" />
                Verifying {ac.name} Connection...
              </span>
            </span>
        : connection.state === ConnectionState.authWindowOpen ?
          <span>
            <span>
              <i className="fa fa-spinner fa-spin" />
              Authorising {ac.name} Connection...
            </span>
            {/* Useful if something goes wrong... */}
            <button
                className="btn btn-default btn-sm"
                onClick={() => invoiceActions.disconnect(this.props.accountingSystem)}
                disabled={connection.state === ConnectionState.disconnecting}
                style={{marginLeft: '1em', borderRadius: '4px'}}>
              Cancel
            </button>
          </span>
        :
          <button
              className="btn btn-primary accounting-system-connection-widget__connect-button"
              onClick={() => invoiceActions.openAuthPopup(this.props.accountingSystem)}>
            <i className="fa fa-plug" />
            Connect to {ac.name}
          </button>
        }
      </div>
    );
  }
});



