import React, { useEffect } from "react";
import { organisationStore } from "../organisation.js";
import moment from "moment";

export default () => {
	useEffect(() => {
		organisationStore.projects.forEach((pr) => {
			let needsSave = false;
			pr.phases.forEach((ph) => {
				if (
					ph.hasDates &&
					ph.getEndDate().isAfter(moment().startOf("month"))
				) {
					ph.syncMilestones(
						ph.remainingRevenue,
						moment().startOf("month"),
						ph.getEndDate()
					);
					needsSave = true;
				}
				// ph.hasDates &&
				// 	ph.getEndDate().isBefore(moment()) &&
				// 	ph.syncMilestones(0, moment(), ph.getEndDate());
				// ph.syncStaffMemberAllocations(
				// 	sb.staffMember,
				// 	hours,
				// 	startDate,
				// 	endDate
				// );
			});
			if (needsSave) organisationStore.saveProject(pr, false);
		});
	}, []);
	return <div>Yay!</div>;
};
