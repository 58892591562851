import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { ProfitPanel } from './SidebarUtils.js';
import { PhaseBudgetedExpensesPanel } from './BudgetedExpensesPanel.js';
import { PhaseBudgetedHoursPanel } from './BudgetedHoursPanel.js';
import { PhaseFeePanel } from './FeePanel.js';
import { StaffExpensesPanel } from './StaffExpensesPanel.js';
import { PhaseMilestonesPanel } from './PhaseMilestonesPanel.js';
import { PhaseDatesPanel } from './DatesPanel.js';
import { userStore } from '../user.js';
import PropTypes from "prop-types";


export var PhaseSidebar = CreateReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([userStore], function() {
      return {
        user: userStore.user
      };
    })
  ],

  render: function() {
    let phase = this.props.phase;

    return <div>
      <PhaseDatesPanel
        phase={phase}
        user={this.state.user}
      />

      {phase.startDate != null && phase.endDate != null ?
        <div>
          <ProfitPanel
            item={phase}
            user={this.state.user}
          />
          <PhaseFeePanel
            phase={phase}
            user={this.state.user}
          />
          <PhaseBudgetedExpensesPanel
            phase={phase}
            user={this.state.user}
          />
          <PhaseBudgetedHoursPanel
            phase={phase}
            user={this.state.user}
          />
          <StaffExpensesPanel
            item={phase}
            user={this.state.user}
            hasGroupBy={false}
          />
          <PhaseMilestonesPanel
            phase={phase}
            user={this.state.user}
          />
        </div>
      : null}
    </div>;
  },

  handleAddStaffMemberButtonClick: function() {
    actions.openAddStaffMemberToItemPopup(this.props.phase);
  }
});




