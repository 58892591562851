import _ from "underscore";
import moment from "moment";
import {
	StoreBase,
	registerActions,
	handleAction,
	dispatcher,
} from "../coincraftFlux.js";
import { Enum } from "../enum.js";
import { ProjectTaskStore } from "./tasks.js";
import { organisationStore } from "../organisation.js";
import { ContactSelectorStore } from "../widgets/ContactSelectorStore.js";
import {
	areIntersectingKeyValuesEqual,
	isNumber,
	generateUUID,
} from "../utils.js";
import { router } from "../router.js";
import { ProjectPhase } from "../models.js";
import { ProjectNote } from "../models/projectnote.js";
import { permissions } from "../models/permissions.js";
import { userStore } from "../user/flux.js";
import {
	phaseRevenueDateMap,
	phaseStaffDateMap,
	phaseRoleDateMap,
} from "./PhaseDates.js";
import { dateConverter } from "../models/dateconverter.js";
import { ChangeLogItem } from "../models/changelogitem.js";
import { autoAdjustHours, autoAdjustRevenue } from "./AutoAdjustForecasts.js";
import { settingsStore } from "../settings/settingsStore.js";

export const MilestoneSyncState = Enum(["synced"]);

const feeProps = ["fee", "startDate", "endDate"];
const budgetProps = [
	"manualBudget",
	"manualHoursBudget",
	"manualExpensesBudget",
	"startDate",
	"endDate",
];

const projectPageActionDefinitions = [
	{ action: "setProjectProp", args: ["name", "value"] },
	{ action: "setProjectPhaseProp", args: ["phase", "name", "value"] },
	{
		action: "setChangeLogItemProp",
		args: ["project", "index", "propName", "value"],
	},
	{ action: "addChangeLogItem", args: ["project"] },
	{ action: "deleteChangeLogItem", args: ["project", "index"] },
	{ action: "deletePhase", args: ["phase"] },
	{ action: "resetMilestones", args: [] },
	{ action: "resetMilestonesSuccessTimeout", args: [] },
	{ action: "clearCantDeletePopup", args: ["phase"] },
	{ action: "syncAllocations", args: [] },
	{ action: "save", args: [] },
	{ action: "confirmSave", args: ["data", "callback"] },
	{ action: "cancelSave", args: [] },
	{ action: "saveSuccess", args: ["data", "callback"] },
	{ action: "saveSuccessTimeout", args: ["callback"] },
	{ action: "saveFailure", args: [] },

	{ action: "addPhase", args: ["project"] },
	{ action: "toggleStaffBudgets", args: ["phase"] },
	{ action: "setPhaseBudgetedHoursHours", args: ["phase", "item", "hours"] },
	{ action: "deletePhaseBudgetedHours", args: ["phase", "item"] },
	{ action: "setPhaseDurationUnit", args: ["phase", "durationUnit"] },
	{ action: "setPhaseDuration", args: ["phase", "duration"] },
	{ action: "setProjectDurationUnit", args: ["project", "durationUnit"] },
	{ action: "setProjectDuration", args: ["project", "duration"] },
	{ action: "linkUpPhaseFee", args: ["phase"] },
	{ action: "linkDownPhaseFee", args: ["phase"] },
	{ action: "unlinkPhaseFee", args: ["phase"] },
	{ action: "linkUpPhaseExpenseBudget", args: ["phase"] },
	{ action: "linkDownPhaseExpenseBudget", args: ["phase"] },
	{ action: "unlinkPhaseExpenseBudget", args: ["phase"] },
	{ action: "linkUpPhaseHoursBudget", args: ["phase"] },
	{ action: "linkDownPhaseHoursBudget", args: ["phase"] },
	{ action: "unlinkPhaseHoursBudget", args: ["phase"] },
	{
		action: "changePhaseBudgetedHoursItem",
		args: ["phase", "item", "budgetedHours"],
	},
	{ action: "addNewPhaseBudgetedHours", args: ["phase"] },
	{ action: "clickCopyProject", args: [] },
	{ action: "clickDelayProject", args: [] },
	{ action: "clickFeeCalculator", args: [] },
	{ action: "printPage", args: ["isPrinting"] },
	{ action: "addError", args: ["error"] },
	{ action: "removeError", args: ["error"] },
	{ action: "copyProject", args: ["data"] },
	{ action: "delayProject", args: ["data"] },
	{ action: "updateMilestones", args: [] },
	{ action: "updateAllocations", args: [] },
	{ action: "setMilestonePercent", args: ["phase", "milestone", "percent"] },
	{ action: "setMilestoneRevenue", args: ["phase", "milestone", "revenue"] },
	{ action: "setMilestoneDate", args: ["phase", "milestone", "dateMoment"] },
	{ action: "createMilestone", args: ["phase"] },
	{ action: "deleteMilestone", args: ["phase", "milestone"] },
	{ action: "createNote", args: [] },
	{ action: "deleteNote", args: ["note"] },
	{ action: "setNoteDate", args: ["note", "date"] },
	{ action: "setNotePhase", args: ["note", "phase"] },
	{ action: "setNoteDescription", args: ["note", "description"] },

	{ action: "linkFeeOver", args: ["phase", "hoverType"] },
	{ action: "linkFeeOut", args: [] },
	{ action: "linkCostOver", args: ["phase", "hoverType"] },
	{ action: "linkCostOut", args: [] },
	{ action: "linkHoursOver", args: ["phase", "hoverType"] },
	{ action: "linkHoursOut", args: [] },

	{ action: "addRate", args: [] },
	{ action: "setRateField", args: ["rateIndex", "field", "value"] },
	{ action: "setRatePhase", args: ["rateIndex", "phase"] },
	{ action: "setRateItem", args: ["rateIndex", "item"] },
	{ action: "deleteRate", args: ["rateIndex"] },

	{ action: "setDirty", args: ["dirty"] },
	{ action: "toggleMenu", args: ["showMenu"] },

	{ action: "changeForecastType", args: ["forecastType"] },
	{ action: "changeForecastDataType", args: ["forecastDataType"] },
	{ action: "changeDisplayedProjectPhase", args: ["displayedProjectPhase"] },

	{
		action: "changeUpdateForecastSelection",
		args: ["updateForecastSelection"],
	},
	{ action: "changeUpdateBudgetValue", args: ["updateBudgetValue"] },
	{ action: "changeUpdateForecastStartDate", args: ["startDate"] },
	{ action: "changeUpdateForecastEndDate", args: ["endDate"] },
	{
		action: "changeUpdateForecastCustomStartDate",
		args: ["customStartDate"],
	},
	{ action: "changeUpdateForecastCustomEndDate", args: ["customEndDate"] },
	{ action: "changeUpdateForecastDontAsk", args: ["dontAsk"] },
	{ action: "changeSelectedTab", args: ["selectedTab"] },
];

export const actions = registerActions(
	"project-page",
	projectPageActionDefinitions,
	dispatcher
);

export const ProjectStore = class extends StoreBase {
	constructor() {
		super();
		this.path = "project-page";
		this.project = null;
		this.setDirty(false);
		this.selectedTab = "projectDetails";

		// If the user tried to delete a phase they couldn't delete, this variable
		// points to that phase.
		this.cantDeletePopup = false;

		this.confirmOverwriteBillabilityPopup = false;
		this.confirmSyncPopup = false;
		this.copyProjectPopup = false;
		this.delayProjectPopup = false;
		this.feeCalculatorPopup = false;
		this.isPrinting = false;

		this.saveState = null;
		this.modifiedFee = false;
		this.modifiedBudget = false;
		this.datesChanged = false;

		this.updateForecastSelection = null;
		this.updateBudgetValue = "remaining";
		this.updateForecastStartDate = "now";
		this.updateForecastEndDate = "endDate";
		this.updateForecastCustomStartDate = null;
		this.updateForecastCustomEndDate = null;
		this.updateForecastDontAsk = false;

		this.modifiedPhases = new Set();
		this.modifiedStaffBudgets = new Set();
		this.modifiedRoleBudgets = new Set();
		this.deletedStaffBudgets = new Set();
		this.deletedRoleBudgets = new Set();

		this.linkHoverHours = null;
		this.linkHoverCost = null;
		this.linkHoverFee = null;
		this.linkHoverPhase = null;

		this.expandedPhases = [];
		this.errors = [];

		this.showMenu = false;

		this.stores = {
			"contact-selector": new ContactSelectorStore(
				"project-page/contact-selector"
			),
			"invoice-contact-selector": new ContactSelectorStore(
				"project-page/invoice-contact-selector"
			),
		};

		this.projectTaskStore = new ProjectTaskStore({
			path: "project-page/tasks",
		});

		this.actionDefinitions = projectPageActionDefinitions;
		this.modifiedProjectProps = {};
		this.modifiedProjectPhaseProps = {};
	}

	handle(action) {
		if (action.path.startsWith("project-page/contact-selector")) {
			if (action.type === "contact/saveSuccess") {
				let contact = this.stores["contact-selector"].handle(action);
				this.setProjectProp("contact", contact);
			} else {
				this.stores["contact-selector"].handle(action);
			}
		} else if (
			action.path.startsWith("project-page/invoice-contact-selector")
		) {
			if (action.type === "contact/saveSuccess") {
				let contact =
					this.stores["invoice-contact-selector"].handle(action);
				this.setProjectProp("invoiceContact", contact);
			} else {
				this.stores["invoice-contact-selector"].handle(action);
			}
		} else if (action.path === "project-page/tasks") {
			this.projectTaskStore.handle(action);
			this.setDirty(true);
		} else {
			handleAction(action, this);
		}
	}

	_storeInitialProjectValues(project) {
		this.oldIsBillable = project.costCentre.isBillable;
		this.oldTaskBillabilityLookup = project.getTaskBillabilityLookup();
	}

	loadProject(project) {
		const finPerm = permissions.financialVisibilityRevenue.ok(
			userStore.user
		);
		const expPerm = permissions.financialVisibilityExpenses.ok(
			userStore.user
		);
		const adminPerm = permissions.projectAdmin(project).ok(userStore.user);
		const pmPerm = permissions.projectManager(project).ok(userStore.user);
		this._storeInitialProjectValues(project);
		this.project = project;
		this.projectTaskStore.project = project;
		this.isDirty = project.isDirty;
		this.saveState = null;
		this.modifiedFee = false;
		this.modifiedPhases = new Set();
		this.modifiedStaffBudgets = new Set();
		this.modifiedRoleBudgets = new Set();
		this.deletedStaffBudgets = new Set();
		this.deletedRoleBudgets = new Set();
		this.modifiedBudget = false;
		this.milestoneSyncState = null; // MilestoneSyncState
		this.forecastType =
			pmPerm && finPerm
				? "revenueVsExpenses"
				: expPerm
				? "expenseBudget"
				: "hoursBudget";
		this.forecastDataType = "actualsProjected";
		this.displayedProjectPhase = project;
		this.selectedTab = "projectDetails";
		this.updateBudgetValue =
			project.milestoneType === "manual" ? "total" : "remaining";
		this.updateForecastStartDate =
			project.milestoneType === "manual" ? "endDate" : "now";
		this.updateForecastEndDate = "endDate";
		this.modifiedProjectProps = {};
		this.modifiedProjectPhaseProps = {};
		this.emitChanged();
	}

	setDirty(dirty) {
		this.isDirty = dirty;
		if (this.project) {
			this.project.isDirty = dirty;
		}
	}

	getProjectDates(project) {
		let dates = project.phases.map((phase) => [
			phase.startDate,
			phase.endDate,
		]);
		return dates;
	}

	setProjectProp(name, value) {
		if (
			!_.include(
				[
					"name",
					"jobCode",
					"manualBudget",
					"manualHoursBudget",
					"manualExpensesBudget",
					"contact",
					"invoiceContact",
					"ownerId",
					"costCentre",
					"status",
					"phases",
					"expenses",
					"fee",
					"startDate",
					"endDate",
					"milestoneType",
					"likelihood",
					"feeData",
				],
				name
			)
		) {
			throw new Error("Unknown prop name");
		}
		this.setProjectModifiedProp(this.project, [name]);
		if (feeProps.includes(name) && this.project.hasDates) {
			this.modifiedFee = true;
			this.project
				.getVisiblePhases()
				.forEach((ph) => this.modifiedPhases.add(ph));
		}

		if (budgetProps.includes(name) && this.project.hasDates) {
			this.modifiedBudget = true;
			this.project.getVisiblePhases().forEach((ph) => {
				ph.staffMemberBudgetedHours.forEach((b) =>
					this.modifiedStaffBudgets.add(b)
				);
				ph.staffRoleBudgetedHours.forEach((b) =>
					this.modifiedRoleBudgets.add(b)
				);
			});
		}

		if (name === "manualBudget") {
			this.project.setManualBudget(value);
			this.setProjectModifiedProp(this.project, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else if (name === "manualHoursBudget") {
			this.project.setManualHoursBudget(parseFloat(value));
			this.setProjectModifiedProp(this.project, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else if (name === "expenses") {
			this.project.setExpenses(value);
		} else if (name === "fee") {
			this.project.setFee(parseFloat(value));
			this.setProjectModifiedProp(this.project, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else if (name === "startDate") {
			if (value != null) {
				this.setProjectDates(
					this.project,
					dateConverter.momentToInt(value),
					dateConverter.momentToInt(this.project.getEndDate())
				);
			}
		} else if (name === "endDate") {
			if (value != null) {
				this.setProjectDates(
					this.project,
					dateConverter.momentToInt(this.project.getStartDate()),
					dateConverter.momentToInt(value)
				);
			}
		} else {
			this.project[name] = value;
		}
		this.setDirty(true);
		this.emitChanged();
	}

	setProjectDates(project, startDate, endDate) {
		let self = this;
		let factor =
			(endDate - startDate) /
			(dateConverter.momentToInt(project.getEndDate()) -
				dateConverter.momentToInt(project.getStartDate()));

		project.getVisiblePhases().forEach(function (phase) {
			this.setProjectPhaseModifiedProp(phase, ["startDate", "endDate"]);
			if (isNumber(factor)) {
				self.setPhaseDates(
					phase,
					startDate +
						(phase.startDate -
							dateConverter.momentToInt(project.getStartDate())) *
							factor,
					startDate +
						(phase.endDate -
							dateConverter.momentToInt(project.getStartDate())) *
							factor
				);
			} else {
				// If the project was zero size when we started.
				self.setPhaseDates(phase, startDate, endDate);
			}
		});
	}

	setPhaseDates(phase, startDate, endDate) {
		this.setProjectPhaseModifiedProp(phase, ["startDate", "endDate"]);
		phase.setDates(startDate, endDate);
		if (phase.hasDates) {
			this.modifiedFee = true;
			this.modifiedPhases.add(phase);
			this.modifiedBudget = true;
			phase.staffMemberBudgetedHours.forEach((b) =>
				this.modifiedStaffBudgets.add(b)
			);
			phase.staffRoleBudgetedHours.forEach((b) =>
				this.modifiedRoleBudgets.add(b)
			);
		}
	}

	setProjectPhaseProp(phase, name, value) {
		if (
			!_.include(
				[
					"name",
					"jobCode",
					"startDate",
					"endDate",
					"fee",
					"manualBudget",
					"manualHoursBudget",
					"status",
					"likelihood",
					"tasks",
					"feeData",
				],
				name
			)
		) {
			throw new Error("Unknown prop name");
		}
		this.setProjectPhaseModifiedProp(phase, [name]);
		if (feeProps.includes(name) && phase.hasDates) {
			this.modifiedFee = true;
			this.modifiedPhases.add(phase);
		}

		if (budgetProps.includes(name) && phase.hasDates) {
			this.modifiedBudget = true;
			phase.staffMemberBudgetedHours.forEach((b) =>
				this.modifiedStaffBudgets.add(b)
			);
			phase.staffRoleBudgetedHours.forEach((b) =>
				this.modifiedRoleBudgets.add(b)
			);
		}

		if (name === "startDate") {
			this.setPhaseDates(
				phase,
				value ? dateConverter.momentToInt(value) : null,
				phase.endDate
			);
		} else if (name === "endDate") {
			this.setPhaseDates(
				phase,
				phase.startDate,
				value ? dateConverter.momentToInt(value) : null
			);
		} else if (name === "fee") {
			phase.setFee(parseFloat(value));
			this.setProjectPhaseModifiedProp(phase, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else if (name === "manualBudget") {
			phase.setExpenseBudget(parseFloat(value));
			this.setProjectPhaseModifiedProp(phase, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else if (name === "manualHoursBudget") {
			phase.setHours(parseFloat(value));
			this.setProjectPhaseModifiedProp(phase, [
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
		} else {
			phase[name] = value;
		}
		this.setDirty(true);
		this.emitChanged();
	}

	setPhaseDurationUnit(phase, durationUnit) {
		this.setProjectPhaseModifiedProp(phase, ["durationUnit"]);
		phase.durationUnit = durationUnit;
		this.setDirty(true);
		this.emitChanged();
	}

	setPhaseDuration(phase, duration) {
		this.setProjectPhaseModifiedProp(phase, [
			"durationUnit",
			"startDate",
			"endDate",
		]);
		let startMoment = phase.getStartDate() || moment();
		let startDateInt = dateConverter.momentToInt(startMoment);
		if (phase.durationUnit === "months") {
			let endMoment = startMoment
				.clone()
				.add(duration, "months")
				.subtract(1, "day");
			this.setPhaseDates(
				phase,
				startDateInt,
				dateConverter.momentToInt(endMoment)
			);
		} else if (phase.durationUnit === "weeks") {
			this.setPhaseDates(
				phase,
				startDateInt,
				startDateInt + duration * 7 - 1
			);
		} else {
			this.setPhaseDates(
				phase,
				startDateInt,
				startDateInt + duration - 1
			);
		}
		if (phase.hasDates) {
			this.modifiedFee = true;
			this.modifiedPhases.add(phase);
			this.modifiedBudget = true;
			phase.staffMemberBudgetedHours.forEach((b) =>
				this.modifiedStaffBudgets.add(b)
			);
			phase.staffRoleBudgetedHours.forEach((b) =>
				this.modifiedRoleBudgets.add(b)
			);
		}
		this.setDirty(true);
		this.emitChanged();
	}

	setProjectDurationUnit(project, durationUnit) {
		this.setProjectModifiedProp(this.project, ["durationUnit"]);
		project.durationUnit = durationUnit;
		this.setDirty(true);
		this.emitChanged();
	}

	setProjectDuration(project, duration) {
		this.setProjectModifiedProp(this.project, [
			"durationUnit",
			"startDate",
			"endDate",
		]);
		let startMoment = project.getStartDate() || moment();
		let startDateInt = dateConverter.momentToInt(startMoment);
		if (project.durationUnit === "months") {
			let endMoment = startMoment
				.clone()
				.add(duration, "months")
				.subtract(1, "day");
			this.setProjectDates(
				project,
				startDateInt,
				dateConverter.momentToInt(endMoment)
			);
		} else if (project.durationUnit === "weeks") {
			this.setProjectDates(
				project,
				startDateInt,
				startDateInt + duration * 7 - 1
			);
		} else {
			this.setProjectDates(
				project,
				startDateInt,
				startDateInt + duration - 1
			);
		}
		this.setDirty(true);
		this.emitChanged();
	}

	addChangeLogItem(project) {
		this.setProjectModifiedProp(this.project, ["changeLog"]);
		project.changeLog = project.changeLog.push(
			new ChangeLogItem({
				project: project,
				date: moment().startOf("day"),
			})
		);
		project.changeLogEdited = true;
		this.setDirty(true);
		this.emitChanged();
	}

	deleteChangeLogItem(project, index) {
		this.setProjectModifiedProp(this.project, ["changeLog"]);
		project.changeLog = project.changeLog.remove(index);
		project.changeLogEdited = true;
		this.setDirty(true);
		this.emitChanged();
	}

	setChangeLogItemProp(project, index, propName, value) {
		this.setProjectModifiedProp(this.project, ["changeLog"]);
		project.changeLog = project.changeLog.updateIn([index], (item) =>
			item.set({ [propName]: value })
		);
		project.changeLogEdited = true;
		this.setDirty(true);
		this.emitChanged();
	}

	deletePhase(phase) {
		this.setProjectPhaseModifiedProp(phase, ["isDeleted"]);
		if (!phase.hasTimesheets) {
			this.project.removePhase(phase);
		} else {
			this.cantDeletePopup = phase;
		}
		this.setDirty(true);
		this.emitChanged();
	}

	addPhase(project) {
		let pp = new ProjectPhase({ project: project });
		pp.createDefaultTask();
		project.phases.push(pp);
		this.setProjectPhaseModifiedProp(pp, ["tasks"]);
		this.setDirty(true);
		this.emitChanged();
	}

	toggleStaffBudgets(phase) {
		if (this.expandedPhases.includes(phase)) {
			this.expandedPhases = _.without(this.expandedPhases, phase);
		} else {
			this.expandedPhases.push(phase);
		}
		this.emitChanged();
	}

	setPhaseBudgetedHoursHours(phase, item, hours) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		let itemType = item.constructor.getClassName();
		if (itemType === "StaffRole") {
			phase.setBudgetedHoursForStaffRole(item, hours);
			if (phase.hasDates)
				this.modifiedRoleBudgets.add(
					phase.getBudgetedHoursObjectForStaffRole(item)
				);
		} else {
			phase.setBudgetedHoursForStaffMember(item, hours);
			if (phase.hasDates)
				this.modifiedStaffBudgets.add(
					phase.getBudgetedHoursObjectForStaffMember(item)
				);
		}
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	deletePhaseBudgetedHours(phase, item) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		let itemType = item.constructor.getClassName();
		if (itemType === "StaffRole") {
			const budgetedHours =
				phase.getBudgetedHoursObjectForStaffRole(item);
			phase.deleteBudgetedHoursForStaffRole(item);
			if (phase.hasDates)
				this.deletedRoleBudgets.add({ ...budgetedHours, item: item });
		} else {
			const budgetedHours =
				phase.getBudgetedHoursObjectForStaffMember(item);
			phase.deleteBudgetedHoursForStaffMember(item);
			if (phase.hasDates)
				this.deletedStaffBudgets.add({ ...budgetedHours, item: item });
		}
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	resetMilestones() {
		// https://docs.google.com/document/d/19vjqPLSbXnE0sXfcNj_B2slvMqhjjhy07zbMW_wfaFg/edit
		for (let p of this.project.phases) {
			if (p.startDate != null && p.endDate != null) {
				p.milestones = [];
				p.adjustMilestones(p.startDate, p.endDate);
				this.setProjectPhaseModifiedProp(p, ["milestones"]);
			}
		}
		this.setDirty(true);
		this.milestoneSyncState = MilestoneSyncState.synced;
		this.emitChanged();
		setTimeout(actions.resetMilestonesSuccessTimeout, 1500);
	}

	resetMilestonesSuccessTimeout() {
		this.milestoneSyncState = null;
		this.emitChanged();
	}

	syncAllocations() {
		for (let p of this.project.phases) {
			this.setProjectPhaseModifiedProp(p, ["allocations"]);
		}
		this.project.syncAllocationDates();
		this.setDirty(true);
		this.allocationSyncState = "synced";
		this.emitChanged();
	}

	clearCantDeletePopup() {
		this.cantDeletePopup = null;
		this.emitChanged();
	}

	handleTasksChanged() {
		this.setDirty(true);
		this.emitChanged();
	}

	linkUpPhaseFee(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
			let ratio = phase.fee / phase.getTotalChargeOutFromStaffBudgets();
			phase.getCombinedBudgetedHours().forEach((x) => {
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, x.hours * ratio);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(
						x.item,
						x.hours * ratio
					);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		} else {
			let feePortion =
				phase.fee / phase.getCombinedBudgetedHours().length;
			phase.getCombinedBudgetedHours().forEach((x) => {
				let hours = feePortion / x.item.chargeOutRate;
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, hours);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(x.item, hours);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		}
		phase.feeLinked = true;
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	linkDownPhaseFee(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.feeLinked = true;
		phase.updateFeeFromStaffBudgets();
		this.setDirty(true);
		this.emitChanged();
	}

	unlinkPhaseFee(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.feeLinked = false;
		this.setDirty(true);
		this.emitChanged();
	}

	linkUpPhaseExpenseBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
			let ratio =
				phase.manualBudget / phase.getTotalExpenseFromStaffBudgets();
			phase.getCombinedBudgetedHours().forEach((x) => {
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, x.hours * ratio);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(
						x.item,
						x.hours * ratio
					);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		} else {
			let budgetPortion =
				phase.manualBudget / phase.getCombinedBudgetedHours().length;
			phase.getCombinedBudgetedHours().forEach((x) => {
				let hours = budgetPortion / x.item.costRate;
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, hours);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(x.item, hours);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		}
		phase.expenseBudgetLinked = true;
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	linkDownPhaseExpenseBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.expenseBudgetLinked = true;
		phase.updateExpenseBudgetFromStaffBudgets();
		this.setDirty(true);
		this.emitChanged();
	}

	unlinkPhaseExpenseBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.expenseBudgetLinked = false;
		this.setDirty(true);
		this.emitChanged();
	}

	linkUpPhaseHoursBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		if (phase.getTotalChargeOutFromStaffBudgets() > 0) {
			let ratio =
				phase.manualHoursBudget /
				phase.getTotalHoursBudgetFromStaffBudgets();
			phase.getCombinedBudgetedHours().forEach((x) => {
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, x.hours * ratio);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(
						x.item,
						x.hours * ratio
					);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		} else {
			let budgetPortion =
				phase.manualHoursBudget /
				phase.getCombinedBudgetedHours().length;
			phase.getCombinedBudgetedHours().forEach((x) => {
				let itemType = x.item.constructor.getClassName();
				if (itemType === "StaffRole") {
					phase.setBudgetedHoursForStaffRole(x.item, budgetPortion);
					if (phase.hasDates)
						this.modifiedRoleBudgets.add(
							phase.getBudgetedHoursObjectForStaffRole(x.item)
						);
				} else {
					phase.setBudgetedHoursForStaffMember(x.item, budgetPortion);
					if (phase.hasDates)
						this.modifiedStaffBudgets.add(
							phase.getBudgetedHoursObjectForStaffMember(x.item)
						);
				}
			});
		}
		phase.hoursBudgetLinked = true;
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	linkDownPhaseHoursBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.hoursBudgetLinked = true;
		phase.updateHoursBudgetFromStaffBudgets();
		this.setDirty(true);
		this.emitChanged();
	}

	unlinkPhaseHoursBudget(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		phase.hoursBudgetLinked = false;
		this.setDirty(true);
		this.emitChanged();
	}

	changePhaseBudgetedHoursItem(phase, item, budgetedHours) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		let oldType = budgetedHours.item.constructor.getClassName();
		let newType = item.constructor.getClassName();
		if (newType === "StaffMember") {
			phase.setBudgetedHoursForStaffMember(item, budgetedHours.hours);
			if (phase.hasDates)
				this.modifiedStaffBudgets.add(
					phase.getBudgetedHoursObjectForStaffMember(item)
				);
		} else if (newType === "StaffRole") {
			phase.setBudgetedHoursForStaffRole(item, budgetedHours.hours);
			if (phase.hasDates)
				this.modifiedRoleBudgets.add(
					phase.getBudgetedHoursObjectForStaffRole(item)
				);
		}
		if (oldType === "StaffMember") {
			phase.deleteBudgetedHoursForStaffMember(budgetedHours.item);
			if (phase.hasDates) this.deletedStaffBudgets.add(budgetedHours);
		} else if (oldType === "StaffRole") {
			phase.deleteBudgetedHoursForStaffRole(budgetedHours.item);
			if (phase.hasDates) this.deletedRoleBudgets.add(budgetedHours);
		}
		if (phase.hasDates) this.modifiedBudget = true;
		this.setDirty(true);
		this.emitChanged();
	}

	addNewPhaseBudgetedHours(phase) {
		this.setProjectPhaseModifiedProp(phase, [
			"fee",
			"feeLinked",
			"staffMemberBudgetedHours",
			"staffRoleBudgetedHours",
			"manualHoursBudget",
			"manualBudget",
			"hoursBudgetLinked",
			"expenseBudgetLinked",
		]);
		let allItems = [
			...organisationStore.staffRoles,
			...organisationStore.getVisibleStaff(),
		];
		let selectedItems = phase
			.getCombinedBudgetedHours()
			.map((sbh) => sbh.item);
		let availableItems = allItems.filter((s) => {
			return !selectedItems.map((s) => s.id).includes(s.id);
		});
		let itemType = availableItems[0].constructor.getClassName();
		if (itemType === "StaffRole") {
			phase.setBudgetedHoursForStaffRole(availableItems[0], 0);
		} else {
			phase.setBudgetedHoursForStaffMember(availableItems[0], 0);
		}
		this.setDirty(true);
		this.emitChanged();
	}

	save() {
		this._validate();
		this.saveState = null; // hack - sometimes this is 'saved' state (cause TBD)
		if (this.isValid) {
			if (this.project.costCentre.isBillable !== this.oldIsBillable) {
				this.confirmOverwriteBillabilityPopup = true;
				this.emitChanged();
			} else if (
				!areIntersectingKeyValuesEqual(
					this.project.getTaskBillabilityLookup(),
					this.oldTaskBillabilityLookup
				)
			) {
				this.confirmOverwriteTimesheetPopup = true;
				this.emitChanged();
			} else if (
				(settingsStore.settings.autoUpdateRevenue.action === "ask" ||
					settingsStore.settings.autoUpdateHours.action === "ask") &&
				(this.modifiedFee || this.modifiedBudget) &&
				this.project.id != null &&
				this.selectedTab === "projectDetails"
			) {
				if (this.modifiedFee && this.modifiedBudget) {
					this.updateForecastSelection = "revenueResource";
				} else if (this.modifiedFee) {
					this.updateForecastSelection = "revenue";
				} else if (this.modifiedBudget) {
					this.updateForecastSelection = "resource";
				} else {
					this.updateForecastSelection = "nothing";
				}
				this.confirmSyncPopup = true;
				this.emitChanged();
			} else {
				this._save();
			}
		} else {
			this.saveInvalid();
		}
	}

	updateMilestones() {
		for (let p of this.project.phases) {
			this.setProjectPhaseModifiedProp(p, ["milestones"]);
		}
		autoAdjustRevenue({
			projects: [this.project],
			options: {
				budget: this.updateBudgetValue,
				start: this.updateForecastStartDate,
				end: this.updateForecastEndDate,
			},
		});
	}

	updateAllocations() {
		for (let p of this.project.phases) {
			this.setProjectPhaseModifiedProp(p, ["allocations"]);
		}
		autoAdjustHours({
			projects: [this.project],
			// staff: [
			// 	...new Set([
			// 		...[...this.modifiedStaffBudgets].map(
			// 			(sb) => sb.staffMember
			// 		),
			// 		...[...this.deletedStaffBudgets].map(
			// 			(sb) => sb.staffMember
			// 		),
			// 	]),
			// ],
			// roles: [
			// 	...new Set([
			// 		...[...this.modifiedRoleBudgets].map((rb) => rb.staffRole),
			// 		...[...this.deletedRoleBudgets].map((rb) => rb.staffRole),
			// 	]),
			// ],
			options: {
				budget: this.updateBudgetValue,
				start: this.updateForecastStartDate,
				end: this.updateForecastEndDate,
			},
		});
	}

	printPage(isPrinting) {
		this.isPrinting = isPrinting;
		this.emitChanged();
	}
	clickCopyProject() {
		this.copyProjectPopup = true;
		this.emitChanged();
	}
	clickDelayProject() {
		this.delayProjectPopup = true;
		this.emitChanged();
	}
	clickFeeCalculator() {
		this.feeCalculatorPopup = true;
		this.emitChanged();
	}

	confirmSave(data = {}, callback) {
		this._save(data, callback);
	}

	cancelSave() {
		this._closeConfirmSavePopups();
		this.emitChanged();
	}

	get updateRevenueForecast() {
		return (
			this.updateForecastSelection &&
			this.updateForecastSelection.toLowerCase().includes("revenue")
		);
	}

	get updateResourceSchedule() {
		return (
			this.updateForecastSelection &&
			this.updateForecastSelection.toLowerCase().includes("resource")
		);
	}

	_save(data, callback) {
		if (this.project.id == null) {
			this.project
				.getVisiblePhases()
				.forEach((p) => p.createMilestones());
			this.updateAllocations();
		}
		if (this.updateForecastDontAsk) {
			if (this.modifiedFee && this.updateRevenueForecast) {
				settingsStore.changeSetting("autoUpdateRevenue", {
					action: "automatic",
					budget: this.updateBudgetValue,
					start: this.updateForecastStartDate,
					end: this.updateForecastEndDate,
				});
			}
			if (this.modifiedFee && !this.updateRevenueForecast) {
				settingsStore.changeSetting("autoUpdateRevenue", {
					action: "never",
					budget: this.updateBudgetValue,
					start: this.updateForecastStartDate,
					end: this.updateForecastEndDate,
				});
			}
			if (this.modifiedBudget && this.updateResourceSchedule) {
				settingsStore.changeSetting("autoUpdateHours", {
					action: "automatic",
					budget: this.updateBudgetValue,
					start: this.updateForecastStartDate,
					end: this.updateForecastEndDate,
				});
			}
			if (this.modifiedBudget && !this.updateResourceSchedule) {
				settingsStore.changeSetting("autoUpdateHours", {
					action: "never",
					budget: this.updateBudgetValue,
					start: this.updateForecastStartDate,
					end: this.updateForecastEndDate,
				});
			}
			settingsStore.save();
		}
		if (
			settingsStore.settings.autoUpdateRevenue.action === "automatic" ||
			this.updateRevenueForecast
		) {
			this.updateMilestones();
		}
		if (
			settingsStore.settings.autoUpdateHours.action === "automatic" ||
			this.updateResourceSchedule
		) {
			this.updateAllocations();
		}
		this.saveState = "saving";
		this.emitChanged();
		const filterSerializedProject = (project) => {
			const serializedData = project.serialize();
			const filteredData = {
				id: serializedData.id,
				uuid: serializedData.uuid,
			};
			if (this.modifiedProjectProps[project.uuid]) {
				for (let key in serializedData) {
					if (this.modifiedProjectProps[project.uuid].has(key)) {
						filteredData[key] = serializedData[key];
					}
				}
			}
			if (Object.keys(this.modifiedProjectPhaseProps).length) {
				filteredData.phases = [];
				for (let phaseData of serializedData.phases) {
					if (this.modifiedProjectPhaseProps[phaseData.uuid]) {
						let filteredPhaseData = {
							id: phaseData.id,
							uuid: phaseData.uuid,
						};
						for (let key in phaseData) {
							if (
								this.modifiedProjectPhaseProps[
									phaseData.uuid
								].has(key)
							) {
								filteredPhaseData[key] = phaseData[key];
							}
						}
						filteredData.phases.push(filteredPhaseData);
					}
				}
			}
			return filteredData;
		};
		organisationStore
			.saveProject(
				this.project,
				true,
				this.project.id ? filterSerializedProject(this.project) : null
			)
			.then(
				function (data) {
					actions.saveSuccess(data, callback);
				},
				function (err) {
					actions.saveFailure();
				}
			);
	}

	_closeConfirmSavePopups() {
		this.confirmOverwriteBillabilityPopup = false;
		this.confirmOverwriteTimesheetPopup = false;
		this.confirmSyncPopup = false;
		this.copyProjectPopup = false;
		this.delayProjectPopup = false;
		this.feeCalculatorPopup = false;
	}

	saveSuccess(data, callback) {
		this._closeConfirmSavePopups();

		if (this.project.id != null) {
			// If we saved and updated the cost centre billability then the server will
			// have updated the billability of our tasks, so we need to update the
			// values on the form to reflect this.
			for (let p of data.project.phases) {
				let thisPhase = _.find(
					this.project.phases,
					(pp) => pp.uuid === p.uuid
				);
				if (thisPhase != null) {
					for (let t of p.tasks) {
						let thisTaskIndex = thisPhase.tasks.findIndex(
							(tt) => tt.uuid === t.uuid
						);
						if (thisTaskIndex !== -1) {
							thisPhase.tasks = thisPhase.tasks.setIn(
								[thisTaskIndex, "isBillable"],
								t.isBillable
							);
						}
					}
				}
			}
			this._storeInitialProjectValues(this.project);
			this.project.initRates();
			this.modifiedFee = false;
			this.modifiedPhases = new Set();
			this.modifiedStaffBudgets = new Set();
			this.modifiedRoleBudgets = new Set();
			this.deletedStaffBudgets = new Set();
			this.deletedRoleBudgets = new Set();
			this.modifiedBudget = false;
			this.changeLogEdited = false;
			this.modifiedProjectProps = {};
			this.modifiedProjectPhaseProps = {};

			this.setDirty(false);
			this.saveState = "saved";
			this.emitChanged();
			setTimeout(function () {
				actions.saveSuccessTimeout(callback);
			}, 1500);
		} else {
			this.setDirty(false);
			router.history.replace(`/dashboard/project/${data.project.id}`);
		}
	}

	saveSuccessTimeout(callback) {
		this.saveState = null;
		callback && callback();
		this.emitChanged();
	}

	saveFailure() {
		this.saveState = "failed";
		this.emitChanged();
	}

	saveInvalid() {
		this.saveState =
			this.project.getErrors().length > 0 ? "invalid" : "failed";
		this.emitChanged();
	}

	addError(error) {
		this.errors.push(error);
	}

	removeError(error) {
		this.errors = _.reject(this.errors, error);
	}

	copyProject({ project, name, jobCode, startDate }) {
		project.name = name;
		project.jobCode = jobCode;
		if (startDate) {
			project.moveBy(
				dateConverter.momentToInt(startDate) -
					dateConverter.momentToInt(project.getStartDate())
			);
		}
		project.uuid = generateUUID();

		this.project = null;
		this.loadProject(project);
		this.save();
	}

	delayProject({ fromDate, untilDate, splitPhases, phaseUuids }) {
		const project = this.project;
		const fromInt = dateConverter.momentToInt(fromDate);
		const untilInt = dateConverter.momentToInt(untilDate);
		const diff = untilInt - fromInt;
		if (!diff) {
			this._closeConfirmSavePopups();
			this.emitChanged();
			return;
		}
		this.project.getVisiblePhases().forEach((ph) => {
			this.setProjectPhaseModifiedProp(ph, [
				"startDate",
				"endDate",
				"fee",
				"feeLinked",
				"staffMemberBudgetedHours",
				"staffRoleBudgetedHours",
				"manualHoursBudget",
				"manualBudget",
				"hoursBudgetLinked",
				"expenseBudgetLinked",
			]);
			if (!phaseUuids.includes(ph.uuid)) return;
			if (ph.startDate >= fromInt) {
				this.setPhaseDates(ph, ph.startDate + diff, ph.endDate + diff);
			} else if (ph.endDate >= fromInt) {
				if (splitPhases) {
					const lengthRatio1 =
						(fromInt - ph.startDate) / (ph.endDate - ph.startDate);
					const lengthRatio2 = 1 - lengthRatio1;

					const newPhase = new ProjectPhase({
						project: project,
						name: ph.name + " (delayed)",
						jobCode: ph.jobCode + "_2",
					});
					newPhase.createDefaultTask();
					project.phases.splice(
						project.phases.findIndex((p) => p.id === ph.id) + 1,
						0,
						newPhase
					);

					this.setPhaseDates(newPhase, untilInt, ph.endDate + diff);
					this.setPhaseDates(ph, ph.startDate, fromInt - 1);
					newPhase.setFee(ph.fee * lengthRatio2);
					ph.setFee(ph.fee * lengthRatio1);
					newPhase.setExpenseBudget(ph.manualBudget * lengthRatio2);
					ph.setExpenseBudget(ph.manualBudget * lengthRatio1);
					newPhase.setHours(ph.manualHoursBudget * lengthRatio2);
					ph.setHours(ph.manualHoursBudget * lengthRatio1);

					ph.staffRoleBudgetedHours.forEach((srbh) => {
						newPhase.setBudgetedHoursForStaffRole(
							srbh.staffRole,
							srbh.hours * lengthRatio2
						);
						ph.setBudgetedHoursForStaffRole(
							srbh.staffRole,
							srbh.hours * lengthRatio1
						);
					});
					ph.staffMemberBudgetedHours.forEach((smbh) => {
						newPhase.setBudgetedHoursForStaffMember(
							smbh.staffMember,
							smbh.hours * lengthRatio2
						);
						ph.setBudgetedHoursForStaffMember(
							smbh.staffMember,
							smbh.hours * lengthRatio1
						);
					});

					this.setProjectPhaseModifiedProp(newPhase, [
						"startDate",
						"endDate",
						"fee",
						"feeLinked",
						"staffMemberBudgetedHours",
						"staffRoleBudgetedHours",
						"manualHoursBudget",
						"manualBudget",
						"hoursBudgetLinked",
						"expenseBudgetLinked",
					]);
				} else {
					this.setPhaseDates(ph, ph.startDate, ph.endDate + diff);
				}
			}
		});
		this.setDirty(true);
		this._closeConfirmSavePopups();
		this.emitChanged();
	}

	_validate() {
		this.isValid = this.project.isValid();
	}

	setMilestonePercent(phase, milestone, percent) {
		this.setProjectPhaseModifiedProp(phase, ["milestones"]);
		if (
			Math.round(percent * 100) / 100 !==
			Math.round(milestone.percent * 100) / 100
		) {
			milestone.setPercent(percent);
			phase.setupMilestones();
			phase.updateMilestoneRevenuesBasedOnPercent();
			this.setDirty(true);
			this.emitChanged();
		}
	}

	setMilestoneRevenue(phase, milestone, revenue) {
		this.setProjectPhaseModifiedProp(phase, ["milestones"]);
		if (
			Math.round(revenue * 100) / 100 !==
			Math.round(milestone.revenue * 100) / 100
		) {
			milestone.setRevenue(revenue);
			phase.updateMilestonePercentsBasedOnRevenue();
			phase.setupMilestones();
			this.setDirty(true);
			this.emitChanged();
		}
	}

	setMilestoneDate(phase, milestone, dateMoment) {
		this.setProjectPhaseModifiedProp(phase, ["milestones"]);
		const dateInt = dateConverter.momentToInt(dateMoment);
		milestone.setEndDate(dateInt);
		phase.setupMilestones();
		phase.updateMilestonePercentsBasedOnRevenue();
		this.setDirty(true);
		this.emitChanged();
	}

	createMilestone(phase) {
		this.setProjectPhaseModifiedProp(phase, ["milestones"]);
		phase.addMilestone();
		phase.setupMilestones();
		phase.updateMilestoneRevenuesBasedOnPercent();
		this.setDirty(true);
		this.emitChanged();
	}

	deleteMilestone(phase, milestone) {
		this.setProjectPhaseModifiedProp(phase, ["milestones"]);
		phase.milestones = _.without(phase.milestones, milestone);
		phase.setupMilestones();
		phase.updateMilestonePercentsBasedOnRevenue();
		this.setDirty(true);
		this.emitChanged();
	}

	createNote() {
		this.setProjectModifiedProp(this.project, ["notes"]);
		let newNote = new ProjectNote();
		this.project.notes.unshift(newNote);
		this.setDirty(true);
		this.emitChanged();
	}

	deleteNote(note) {
		this.setProjectModifiedProp(this.project, ["notes"]);
		this.project.notes = _.without(this.project.notes, note);
		this.setDirty(true);
		this.emitChanged();
	}

	setNoteDate(note, date) {
		this.setProjectModifiedProp(this.project, ["notes"]);
		note.date = date;
		this.setDirty(true);
		this.emitChanged();
	}

	setNotePhase(note, phase) {
		this.setProjectModifiedProp(this.project, ["notes"]);
		note.phaseUuid = phase?.uuid;
		this.setDirty(true);
		this.emitChanged();
	}

	setNoteDescription(note, description) {
		this.setProjectModifiedProp(this.project, ["notes"]);
		note.description = description;
		this.setDirty(true);
		this.emitChanged();
	}

	addRate() {
		this.setProjectModifiedProp(this.project, ["rates"]);
		let newRate = {
			date: "2000-01-01",
			phaseUuid: undefined,
			costRate: null,
			chargeOutRate: null,
			itemType: "StaffMember",
			itemUuid: organisationStore.staffMembers[0].uuid,
		};
		this.project.rates.push(newRate);
		this.project.initRates();
		this.isDirty = true;
	}

	setRateField(rateIndex, field, value) {
		this.setProjectModifiedProp(this.project, ["rates"]);
		if (field != "date") {
			value = value == "" ? null : value;
		}
		this.project.rates[rateIndex][field] = value;
		let phase = this.project.rates[rateIndex].phase();
		if (phase) {
			phase.updateExpenseBudgetFromStaffBudgets();
			phase.updateFeeFromStaffBudgets();
		} else {
			this.project.phases.forEach((ph) => {
				ph.updateExpenseBudgetFromStaffBudgets();
				ph.updateFeeFromStaffBudgets();
			});
		}
		this.isDirty = true;
	}

	setRatePhase(rateIndex, phase) {
		this.setProjectModifiedProp(this.project, ["rates"]);
		if (phase) {
			this.project.rates[rateIndex]["phaseUuid"] = phase.uuid;
			phase.updateExpenseBudgetFromStaffBudgets();
			phase.updateFeeFromStaffBudgets();
		} else {
			this.project.rates[rateIndex]["phaseUuid"] = undefined;
			this.project.phases.forEach((ph) => {
				ph.updateExpenseBudgetFromStaffBudgets();
				ph.updateFeeFromStaffBudgets();
			});
		}
		this.isDirty = true;
	}

	setRateItem(rateIndex, item) {
		this.setProjectModifiedProp(this.project, ["rates"]);
		this.project.rates[rateIndex]["itemType"] =
			item.constructor.getClassName();
		this.project.rates[rateIndex]["itemUuid"] = item.uuid;
		let phase = this.project.rates[rateIndex].phase();
		if (phase) {
			phase.updateExpenseBudgetFromStaffBudgets();
			phase.updateFeeFromStaffBudgets();
		} else {
			this.project.phases.forEach((ph) => {
				ph.updateExpenseBudgetFromStaffBudgets();
				ph.updateFeeFromStaffBudgets();
			});
		}
		this.isDirty = true;
	}

	deleteRate(rateIndex) {
		this.setProjectModifiedProp(this.project, ["rates"]);
		let phase = this.project.rates[rateIndex].phase();
		this.project.rates.splice(rateIndex, 1);
		this.isDirty = true;
		if (phase) {
			phase.updateExpenseBudgetFromStaffBudgets();
			phase.updateFeeFromStaffBudgets();
		} else {
			this.project.phases.forEach((ph) => {
				ph.updateExpenseBudgetFromStaffBudgets();
				ph.updateFeeFromStaffBudgets();
			});
		}
	}

	toggleMenu(showMenu) {
		this.showMenu = showMenu !== undefined ? showMenu : !this.showMenu;
	}

	changeForecastType(forecastType) {
		this.forecastType = forecastType;
		this.emitChanged();
	}

	changeForecastDataType(forecastDataType) {
		this.forecastDataType = forecastDataType;
		this.emitChanged();
	}

	changeDisplayedProjectPhase(displayedProjectPhase) {
		this.displayedProjectPhase = displayedProjectPhase;
		this.emitChanged();
	}

	changeUpdateForecastSelection(updateForecastSelection) {
		this.updateForecastSelection = updateForecastSelection;
		this.emitChanged();
	}
	changeUpdateBudgetValue(updateBudgetValue) {
		this.updateBudgetValue = updateBudgetValue;
		this.emitChanged();
	}
	changeUpdateForecastStartDate(startDate) {
		this.updateForecastStartDate = startDate;
		this.emitChanged();
	}
	changeUpdateForecastEndDate(endDate) {
		this.updateForecastEndDate = endDate;
		this.emitChanged();
	}
	changeUpdateForecastCustomStartDate(customStartDate) {
		this.updateForecastCustomStartDate = customStartDate;
		this.emitChanged();
	}
	changeUpdateForecastCustomEndDate(customEndDate) {
		this.updateForecastCustomEndDate = customEndDate;
	}
	changeUpdateForecastDontAsk(dontAsk) {
		this.updateForecastDontAsk = dontAsk;
		this.emitChanged();
	}

	changeSelectedTab(selectedTab) {
		this.selectedTab = selectedTab;
		this.showMenu = false;
		this.emitChanged();
	}
	linkFeeOver(phase, hoverType) {
		this.linkHoverPhase = phase;
		this.linkHoverFee = hoverType;
	}
	linkFeeOut() {
		this.linkHoverPhase = null;
		this.linkHoverFee = null;
	}
	linkCostOver(phase, hoverType) {
		this.linkHoverPhase = phase;
		this.linkHoverCost = hoverType;
	}
	linkCostOut() {
		this.linkHoverPhase = null;
		this.linkHoverCost = null;
	}
	linkHoursOver(phase, hoverType) {
		this.linkHoverPhase = phase;
		this.linkHoverHours = hoverType;
	}
	linkHoursOut() {
		this.linkHoverPhase = null;
		this.linkHoverHours = null;
	}
	setProjectModifiedProp(project, propNames, fromPhase = false) {
		this.modifiedProjectProps[project.uuid] ??= new Set();
		for (let propName of propNames) {
			if (propName === "contact") propName = "contactId";
			if (propName === "invoiceContact") propName = "invoiceContactId";
			if (propName === "costCentre") propName = "costCentreId";
			if (propName === "likelihood") propName = "percentLikelihood";
			this.modifiedProjectProps[project.uuid].add(propName);
		}
		if (!fromPhase) {
			project.phases.forEach((phase) => {
				this.setProjectPhaseModifiedProp(phase, propNames, true);
			});
		}
	}
	setProjectPhaseModifiedProp(phase, propNames, fromProject = false) {
		this.modifiedProjectPhaseProps[phase.uuid] ??= new Set();
		for (let propName of propNames) {
			if (propName === "contact") propName = "contactId";
			if (propName === "invoiceContact") propName = "invoiceContactId";
			if (propName === "costCentre") propName = "costCentreId";
			if (propName === "likelihood") propName = "percentLikelihood";
			this.modifiedProjectPhaseProps[phase.uuid].add(propName);
		}
		if (!fromProject) {
			this.setProjectModifiedProp(phase.project, propNames, true);
		}
	}
};

export let projectStore = new ProjectStore();
