import { Project } from './project.js';


export const NoProjectProject = class extends Project {
			constructor(options) {
				super(options);
				if (this.name == null || this.name === "") {
					this.name = "(No project)";
				}
			}
		};



