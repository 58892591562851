import { dateConverter } from './dateconverter.js';


export const DateRange = class {
			constructor(
				startDate,
				endDate = null,
				repeatUnit = "months",
				repeatQuantity = 1
			) {
				this.startDate = startDate;
				this.endDate = endDate;
				this.repeatUnit = repeatUnit;
				this.repeatQuantity = repeatQuantity;
			}

			dates(callback) {
				var d = this.startDate.clone();

				if (this.repeatUnit == null || this.repeatQuantity == 0) {
					callback(d);
				} else {
					var delta = {};
					delta[this.repeatUnit] = this.repeatQuantity;

					while (this.endDate == null || !d.isAfter(this.endDate)) {
						if (callback(d) === false) {
							break;
						}
						d = d.clone().add(delta);
					}
				}
			}
		};


export function dateRangeAsKeys(startDate, endDate, callback) {
  /**
   * `startDate` and `endDate` are `moment` objects; call `callback` with the integer
   * keys for all the dates from `startDate` to `endDate` inclusive.
   */
  var startDateOffset = dateConverter.momentToInt(startDate);
  var endDateOffset = dateConverter.momentToInt(endDate);
  for (var i = startDateOffset, j = 0; i <= endDateOffset; i++, j++) {
    callback(i, j);
  }
};
