export const contactProperties = (prop, contactValue, context) => {
	const dependencies = contactPropDepLookup[prop]?.(contactValue, context) || new Map();
	if (dependencies.get(context)) {
		throw {
			value: "Circular reference.",
			type: "error",
			dependencies: dependencies
		};
	}
	const val =
		contactPropertiesLookup[prop]?.(contactValue, context)
	return val && ({...val, dependencies});
}

const contactPropertiesLookup = {
	firstName: (contactValue, context) => {
		return {
			type: "text",
			value: contactValue.value.firstName,
		};
	},
	lastName: (contactValue, context) => {
		return {
			type: "text",
			value: contactValue.value.lastName,
		};
	},
	organisation: (contactValue, context) => {
		return {
			type: "text",
			value: contactValue.value.contactOrganisationName,
		};
	},
};

const contactPropDepLookup = {
	firstName: (contactValue, context) =>
		new Map([...(contactValue.dependencies || [])]),
	lastName: (contactValue, context) =>
		new Map([...(contactValue.dependencies || [])]),
	organisation: (contactValue, context) =>
		new Map([...(contactValue.dependencies || [])]),
};
        
export const contactMethods = {};