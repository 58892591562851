import React from 'react';
import CreateReactClass from 'create-react-class';
import { Budgets } from './SidebarUtils.js';
import { actions } from './flux.js';
import { permissions, requiresPermission, OperationLevel } from '../models/permissions.js';

import PropTypes from "prop-types";

export var ProjectBudgetedHoursPanel = requiresPermission(
  ({project}) => permissions.editProjectBudgets(project),
  {},
  CreateReactClass({
    propTypes: {
      project: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired // from requiresPermission
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Budgeted Hours</h3>
          <Budgets
            item={this.props.project}
            isEditable={this.props.permissionLevel === OperationLevel.edit}
            budgetType='hours'
            getItemBudget={(item) => item.manualHoursBudget}
            actions={{
              calculate: actions.projectSidebarCalculateHours,
              setManualBudget: actions.projectSidebarSetProjectManualHours,
              setPhaseBudget: actions.projectSidebarSetPhaseManualHours
            }}
          />
        </div>
      );
    }
  })
);


export var PhaseBudgetedHoursPanel = requiresPermission(
  ({phase}) => permissions.editProjectBudgets(phase.project),
  {},
  CreateReactClass({
    propTypes: {
      phase: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired // from requiresPermission
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Budgeted Hours</h3>
          <Budgets
            item={this.props.phase}
            isEditable={true}
            budgetType='hours'
            getItemBudget={(item) => item.manualHoursBudget}
            actions={{
              calculate: actions.phaseSidebarCalculateHours,
              setManualBudget: actions.phaseSidebarSetPhaseManualHoursBudget
            }}
          />
        </div>
      );
    }
  })
);
