import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { dateConverter } from '../../../models/dateconverter.js';
import classNames from 'classnames';
import { projectForecastsStore, actions } from '../ProjectForecastsStore.js';
import { makeMultipleStoreMixin } from '../../../coincraftFlux.js';
import { permissions, requiresPermission, OperationLevel, UserLevel } from '../../../models/permissions.js';
import { userStore } from '../../../user.js';
import { ProgressBar } from '../../../newSpreadsheet/ProgressBar.js';


export var RevenueMenu = CreateReactClass({
    mixins: [
        makeMultipleStoreMixin([projectForecastsStore, userStore], function () {
            return {
                cell: projectForecastsStore.selectedRevenueCell,
                user: userStore.user,
                selectedRowId: projectForecastsStore.selectedRevenueRowId,
                selectedRow: projectForecastsStore.selectedRevenueRow,
                selectedMonthIndex: projectForecastsStore.selectedRevenueMonthIndex,
                currentMonthIndex: projectForecastsStore.currentMonthIndex,
                selectedRevenueInputText: projectForecastsStore.selectedRevenueCellInputText,
                selectedBudgetInputText: projectForecastsStore.selectedRevenueFeeUseInputText,
            };
        })
    ],

    componentWillMount() {
        this.timelineItems = {}
        this.timeline = null
    },

    componentDidUpdate(prevProps, prevState) {
        const thisMonth = this.state.selectedMonthIndex;
        const timeline = this.timeline
        const timecard = this.timelineItems[thisMonth]
        if (
            (timecard.getBoundingClientRect().top <= (timeline.getBoundingClientRect().top - timecard.offsetHeight*2))
            || (timecard.getBoundingClientRect().bottom >= (timeline.getBoundingClientRect().bottom + timecard.offsetHeight*2))
        ) {
            timecard.scrollIntoView(true);
            timeline.scrollTop -= timeline.offsetHeight / 2 - timecard.offsetHeight / 2;
        } else if (timecard.getBoundingClientRect().top <= (timeline.getBoundingClientRect().top + timecard.offsetHeight / 2)) {
            timecard.scrollIntoView(true);
            timeline.scrollTop -= timecard.offsetHeight / 2;
        } else if (timecard.getBoundingClientRect().bottom >= (timeline.getBoundingClientRect().bottom - timecard.offsetHeight / 2)) {
            timecard.scrollIntoView(false);
            timeline.scrollTop += timecard.offsetHeight / 2;
        }
    },

    render() {
        const { 
            selectedRow, 
            selectedMonthIndex, 
            currentMonthIndex, 
            selectedRowId, 
            selectedRevenueInputText,
            selectedBudgetInputText,
        } = this.state
        return (
            <div className="spreadsheet-history-menu">
                <div className="spreadsheet-history-menu__header" style={{ borderBottom: '1px solid #ccc' }}>
                    <div className="title">{selectedRow.title}</div>
                    {/*<div className="fee-input">
                        <span className="text" style={{ marginRight: "0.5em" }}>Fee</span>
                        <span className="input">
                        <input 
                            style={{ width: "6em", textAlign: "right" }} 
                            type="text" value={selectedRow.phase ? selectedRow.phase.fee : selectedRow.project.fee} 
                            onChange={e => actions.changeFee(selectedRow.phase || selectedRow.project, e.target.value)} />
                        </span>
                    </div>*/}
                </div>
                <div className="spreadsheet-history-menu__timeline" ref={el => this.timeline = el}>
                    {selectedRow.monthIndexArray.reverse().map(monthIndex => {
                        const selected = monthIndex === selectedMonthIndex
                        const past = monthIndex < currentMonthIndex
                        const editable = selected && !past && this.props.isEditable
                        return <TimeCard
                            key={monthIndex}
                            monthIndex={monthIndex}
                            selected={selected}
                            past={past}
                            editable={editable}
                            value={Math.round(selectedRow.getDisplayedRevenueMonthIndex(monthIndex))}
                            onChangeValue={e => {
                                actions.setSelectedRevenueCellRevenue(e.target.value)
                                this.props.onChange()
                            }}
                            refFunc={el => this.timelineItems[monthIndex] = el}
                            onClick={!selected ? () => actions.selectRevenueMonthIndex(monthIndex) : undefined}
                            inputText={selectedRevenueInputText}
                            >
                            <TimeCardProgress
                                title={"Percentage of Fee"}
                                value={Math.round(selectedRow.getDisplayedRevenueMonthIndex(monthIndex))}
                                percent={Math.round(selectedRow.getDisplayedBudgetUseMonthIndex(monthIndex))}
                                prevPercent={Math.round(selectedRow.getDisplayedBudgetUseMonthIndex(monthIndex - 1))}
                                editable={editable}
                                onChangePercent={e => {
                                    actions.setSelectedRevenueCellFeeUse(e.target.value)
                                    this.props.onChange()
                                }}
                                inputText={selectedBudgetInputText}
                            />
                        </TimeCard>
                    })}
                </div>
            </div>
        );
    },
});

var TimeCard = CreateReactClass({
    render: function () {
        const { selected, past, monthIndex, children, onClick, editable, value, onChangeValue, inputText} = this.props
        return <div
            ref={this.props.refFunc}
            className={classNames('spreadsheet-history-menu__timecard', { 'selected': selected }, { 'past': past })}
            onClick={onClick}
            style={onClick ? {cusor: 'pointer'} : {}}
        >
            <div className="date-title">
                <div className="date">{dateConverter.monthIndexToMoment(monthIndex).format('MMM YY')}</div>
                <div className="hours">{editable
                    ? <span>Revenue: <input style={{ width: '6em' }} type="text" value={inputText || value} onChange={onChangeValue} /></span>
                    : <span>Revenue: {value}</span>
                }</div>
            </div> 
            {children}
        </div>;
    }
});

var TimeCardProgress = CreateReactClass({
    render: function () {
        const { title, value, percent, editable, prevPercent, onChangeValue, onChangePercent, inputText } = this.props
        return <div className="timecard-progress">
            <div className="timecard-text">
                <div className="title-val">
                    <div className="title">{title}</div>
                </div>
                <div className="percent">
                    {editable
                        ? <span><input type="text" value={inputText || percent} onChange={onChangePercent} />%</span>
                        : <span>{`${percent}%`}</span>
                    }
                </div>
            </div>
            <ProgressBar className="progress-bar" fromPercentage={prevPercent} toPercentage={percent} height="0.4em" />
        </div>;
    }
});