import React, { useRef, useState } from "react";
import CreateReactClass from "create-react-class";
import { Modal, ModalContent } from "../modal.js";
import { TriStateSaveButton } from "../widgets.js";
import moment from "moment";
import { FormSection, FormRow, FormLabel } from "../forms.js";
import { DateValue, Checkbox } from "../widgets.js";
import { ErrorPopover, formatCurrency } from "../utils.js";
import { actions } from "./flux.js";
import PropTypes from "prop-types";
import { projectStore } from "./flux.js";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Select, Input } from "antd";
import DraggableList from "react-draggable-list";
import Formula from "../formulas/Formula.js";
import { sum } from "lodash";

const { Option } = Select;
const projectOptions = [
	<Option
		style={{ fontSize: "1rem" }}
		value="percentAgreed"
		label="% Agreed Fee"
	>
		% Agreed Fee
	</Option>,
	<Option
		style={{ fontSize: "1rem" }}
		value="percentLessPrevious"
		label="% Agreed Less Previous"
	>
		% Agreed Fee Less Previous
	</Option>,
];

class phaseFeeData {
	@observable phase = null;
	@observable postition = 0;
	@observable feeData = {};
	constructor(phase, index, projectStore) {
		makeObservable(this);
		this.projectStore = projectStore;
		this.id = phase.id;
		this.phase = phase;

		this.position = index;
	}
	@action.bound
	initFeeData() {
		const phase = this.phase;
		this.feeData = phase.feeData || {
			type: "percentAgreed",
			visible: true,
			locked: false,
		};
		if (this.feeData.type === "percentAgreed") {
			this.feeData.value = phase.fee;
			this.feeData.percent =
				(phase.fee / this.projectStore?.agreedFee) * 100;
		} else {
			this.feeData.value =
				sum(this.previousPhases.map((pp) => pp.phase.fee)) + phase.fee;
			this.feeData.percent =
				((sum(this.previousPhases.map((pp) => pp.phase.fee)) +
					phase.fee) /
					this.projectStore?.agreedFee) *
				100;
		}
	}
	@action.bound
	setLocked(newLocked) {
		this.feeData.locked = newLocked;
		if (newLocked) {
			if (this.feeData.type === "percentAgreed") {
				this.setFeeDataValue(
					this.projectStore?.agreedFee * (this.feeData.percent / 100)
				);
			} else {
				this.setFeeDataValue(
					this.projectStore?.agreedFee *
						(this.feeData.percent / 100) -
						this.previousPhasesAgreedFee
				);
			}
		}
	}
	@action.bound
	setVisible(newVisible) {
		this.feeData.visible = newVisible;
		if (!newVisible) {
			if (this.feeData.type === "percentAgreed") {
				this.setFeeDataValue(
					this.projectStore?.agreedFee * (this.feeData.percent / 100)
				);
			} else {
				this.setFeeDataValue(
					this.projectStore?.agreedFee *
						(this.feeData.percent / 100) -
						this.previousPhasesAgreedFee
				);
			}
		}
	}
	@action.bound
	setPosition(newPosition) {
		this.position = newPosition;
	}
	@action.bound
	setFeeDataType(newFeeType) {
		this.feeData.type = newFeeType;
	}
	@action.bound
	setFeeDataValue(newFeeValue) {
		this.feeData.value = newFeeValue;
	}
	@action.bound
	setFeeDataPercent(newFeePercent) {
		this.feeData.percent = newFeePercent;
	}
	@computed
	get previousPhases() {
		return (
			this.projectStore?.phasesFeeData.filter(
				(ph) => ph.position < this.position && ph.feeData.visible
			) || []
		);
	}
	@computed
	get previousPhasesAgreedFee() {
		return sum(this.previousPhases.map((ph) => ph.agreedFee));
	}
	@computed
	get agreedFee() {
		if (this.feeData.locked || !this.feeData.visible) {
			return this.feeData.value;
		} else if (this.feeData.type === "percentAgreed") {
			return this.projectStore?.agreedFee * (this.feeData.percent / 100);
		} else {
			return (
				this.projectStore?.agreedFee * (this.feeData.percent / 100) -
				this.previousPhasesAgreedFee
			);
		}
	}
}

class projectFeeData {
	@observable project = null;
	@observable feeData = {};
	@observable phasesFeeData = [];
	constructor(project) {
		makeObservable(this);
		this.project = project;
		this.feeData = project.feeData || { value: String(project.fee) };
		this.phasesFeeData = [];
		project.getVisiblePhases().forEach((ph, i) => {
			this.phasesFeeData.push(new phaseFeeData(ph, i, this));
		});
		this.phasesFeeData.forEach((ph) => ph.initFeeData());
	}
	@action.bound
	reorderPhasesFeeData(newList) {
		newList.forEach((ph, i) => {
			ph.setPosition(i);
		});
		this.phasesFeeData = newList;
	}
	@computed
	get agreedFee() {
		return new Formula({
			formula: this.feeData.value,
		}).value;
	}
	@action.bound
	setAgreedFee(value) {
		this.feeData.value = value;
	}
}

export const FeeCalculatorModal = observer(
	({ onConfirm, onCancel, saveState, setPhaseFee }) => {
		const [store, setStore] = useState(
			new projectFeeData(projectStore.project)
		);
		const project = projectStore.project;
		const labelWidth = "7em";

		const listContainerRef = useRef();
		return (
			<Modal>
				<ModalContent
					header="Fee Calculator"
					width="45em"
					bodyStyles={{ padding: 0 }}
				>
					<div
						className="delay-project-form"
						style={{ padding: "2em" }}
					>
						<div
							style={{
								textAlign: "right",
								fontWeight: "bold",
								fontSize: "1.4rem",
							}}
						>
							${formatCurrency(store.agreedFee)}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginBottom: "2em",
							}}
						>
							<div
								style={{
									flex: "0 0 auto",
									marginRight: "1em",
									fontWeight: "bold",
								}}
							>
								Agreed Fee
							</div>
							<div style={{ flex: "0 0 auto" }}>
								<Input
									size="small"
									value={store.feeData.value}
									style={{
										width: 150,
										minHeight: "inherit",
										textAlign: "right",
									}}
									onChange={(e) => {
										store.setAgreedFee(e.target.value);
									}}
								/>
							</div>
						</div>
						<div
							style={{ display: "relative" }}
							ref={listContainerRef}
						>
							<DraggableList
								itemKey="id"
								template={PhaseListItem}
								list={store.phasesFeeData}
								onMoveEnd={(newList) =>
									store.reorderPhasesFeeData(newList)
								}
								container={() => listContainerRef.current}
							/>
						</div>
					</div>
					<div
						style={{
							borderTop: "1px solid #ccc",
							textAlign: "right",
							padding: "1em",
						}}
					>
						<a href="javascript:void(0)" onClick={onCancel}>
							Cancel
						</a>

						<TriStateSaveButton
							text="Adjust Fees"
							className="btn btn-default"
							onClick={() => {
								store.project.feeData = store.feeData;
								store.phasesFeeData
									.filter((ph) => ph.feeData.visible)
									.forEach((ph) => {
										setPhaseFee(ph.phase, ph.agreedFee);
										ph.phase.feeData = ph.feeData;
									});
								projectStore.setProjectProp(
									"feeData",
									store.project.feeData
								);
								onCancel();
							}}
							state={saveState}
							style={{ marginLeft: "1em" }}
						/>
					</div>
				</ModalContent>
			</Modal>
		);
	}
);

const PhaseListItem = observer(
	({ item: ph, itemSelected, dragHandleProps }) => {
		return (
			<div
				style={{
					display: "flex",
					opacity: ph.feeData.visible ? 1 : 0.5,
					alignItems: "center",
					borderTop: "1px solid #eee",
					padding: "0.5em 0",
				}}
			>
				<div style={{ flex: "1 1 auto" }}>
					<span {...dragHandleProps} style={{ color: "grey" }}>
						<i
							className="fa fa-ellipsis-v"
							style={{ marginRight: "2px" }}
						/>
						<i className="fa fa-ellipsis-v" />
					</span>
					<Checkbox
						value={ph.feeData.visible}
						onChange={(visible) => ph.setVisible(visible)}
						style={{ marginRight: "1em" }}
					/>
					{ph.phase.getTitle()}
				</div>
				<div style={{ flex: "0 0 auto" }}>
					<div style={{ textAlign: "right", fontWeight: "bold" }}>
						${formatCurrency(ph.agreedFee)}
						<i
							className={
								ph.feeData.locked
									? "fa fa-lock"
									: "fa fa-unlock"
							}
							style={{
								marginLeft: "1rem",
							}}
							onClick={() =>
								ph.feeData.visible &&
								ph.setLocked(!ph.feeData.locked)
							}
						/>
					</div>
					<Input
						size="small"
						style={{
							width: 40,
							minHeight: "inherit",
							textAlign: "right",
						}}
						value={ph.feeData.percent}
						onChange={(e) => {
							ph.setFeeDataPercent(e.target.value);
						}}
						disabled={ph.feeData.locked || !ph.feeData.visible}
					/>
					<Select
						size={"small"}
						defaultValue={ph.feeData.type}
						onChange={(type) => {
							ph.setFeeDataType(type);
						}}
						style={{
							width: 170,
							fontSize: "1rem",
						}}
						disabled={ph.feeData.locked || !ph.feeData.visible}
					>
						{projectOptions}
					</Select>
				</div>
			</div>
		);
	}
);
