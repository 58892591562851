import array from "./operations/array";
import context from "./operations/context";
import date from "./operations/date";
import dateTime from "./operations/dateTime";
import duration from "./operations/duration";
import number from "./operations/number";
import method from "./operations/method";
import property from "./operations/property";
import percent from "./operations/percent";
import quoteText from "./operations/quoteText"
import range from "./operations/range"
import atRef from "./operations/atRef";
import hashRef from "./operations/hashRef";
import text from "./operations/text"
import time from "./operations/time"
import add from "./operations/add"
import and from "./operations/and"
import divide from "./operations/divide"
import equal from "./operations/equal"
import func from "./operations/func"
import greaterThan from "./operations/greaterThan"
import greaterThanEqual from "./operations/greaterThanEqual";
import ifElse from "./operations/ifElse"
import lessThan from "./operations/lessThan"
import lessThanEqual from "./operations/lessThanEqual"
import multiply from "./operations/multiply"
import not from "./operations/not"
import or from "./operations/or"
import power from "./operations/power"
import subtract from "./operations/subtract"

export let Operations = {
			array: array,
			context: context,
			date: date,
			dateTime: dateTime,
			duration: duration,
			number: number,
			method: method,
			property: property,
			percent: percent,
			quoteText: quoteText,
			range: range,
			text: text,
			time: time,
			add: add,
			and: and,
			divide: divide,
			equal: equal,
			function: func,
			greaterThan: greaterThan,
			greaterThanEqual: greaterThanEqual,
			ifElse: ifElse,
			lessThan: lessThan,
			lessThanEqual: lessThanEqual,
			multiply: multiply,
			not: not,
			or: or,
			power: power,
			subtract: subtract,
			atRef: atRef,
			hashRef: hashRef
		};