import React from "react";
import CreateReactClass from "create-react-class";
import { IntercomMessageLink } from "../widgets.js";
import { errorMessages } from "./errorMessages.js";
import { Modal2 } from "../modal.js";

import PropTypes from "prop-types";

export var ErrorModal = CreateReactClass({
	propTypes: {
		modal: PropTypes.object.isRequired,
		onDismiss: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	},

	render: function () {
		return (
			<Modal2
				header="Error"
				closeOnClickOutside={true}
				onCancel={this.props.onCancel}
				width="45em"
			>
				<div style={{ padding: "1em" }}>
					<p>
						There was a problem{" "}
						{errorMessages[this.props.modal.operation]}.
					</p>
					{this.props.modal.error != null ? (
						<div
							style={{
								color: "#e60000",
								padding: "1em",
								background: "#c3000029",
								border: "1px solid #e60000",
								borderRadius: "0.5em",
								margin: "0.5em",
							}}
						>
							{this.props.modal.error != null &&
							this.props.modal.error.code ===
								"invoice_number_already_exists" ? (
								<p>
									This invoice number already exists in your
									accounting system.
								</p>
							) : this.props.modal.error != null &&
							  this.props.modal.error.code ===
									"item_code_does_not_exist" ? (
								<p>
									One the invoice phase codes does not exist
									as an item code in Xero. Please alter your
									phase codes in Coincraft or add matching
									item codes to Xero.
								</p>
							) : this.props.modal.error != null &&
							  this.props.modal.error.code ===
									"invoice_of_invalid_status" ? (
								<p>
									This invoice has been void or deleted in
									your accounting system. Please{" "}
									<IntercomMessageLink label="contact us" />
									{" to fix."}
								</p>
							) : this.props.modal.errorCode ===
							  "quickbooks_no_tax_codes" ? (
								<div
									style={{
										marginTop: "1em",
										marginBottom: "1em",
									}}
								>
									<p>
										Your QuickBooks account doesn't seem to
										be set up for tax.
									</p>
									<p>
										We can't currently sync your invoices to
										an account that isn't setup for tax.
									</p>
								</div>
							) : this.props.modal.errorCode ===
							  "myob_permission_denied" ? (
								<div
									style={{
										marginTop: "1em",
										marginBottom: "1em",
									}}
								>
									<p>
										MYOB permission denied. Please check you
										username and password.
									</p>
								</div>
							) : this.props.modal.error != null &&
							  this.props.modal.error.accountingSystemMessage !=
									null ? (
								<div
									style={{
										marginTop: "1em",
										marginBottom: "1em",
									}}
								>
									<p>
										The accounting system reported the
										following error:
									</p>
									<pre style={{ overflow: "auto" }}>
										{
											this.props.modal.error
												.accountingSystemMessage
										}
									</pre>
								</div>
							) : null}
						</div>
					) : null}
					<p>
						{"You can try again, or if the problem persists, "}
						<IntercomMessageLink label="chat with us" />.
					</p>
					<p>
						<button
							className="btn btn-default"
							onClick={this.props.onDismiss}
						>
							Okay
						</button>
					</p>
				</div>
			</Modal2>
		);
	},
});
