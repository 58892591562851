export default ({ base, power }, context) => {
	const raiserId = base.type + power.type;
	if (!raiserValues[raiserId]) {
		throw {
			value: `Cannot raise a ${base.type} to the power of a ${power.type}.`,
			type: "error",
		};
	}
	return ({
		type: raiserTypes[raiserId],
		value: raiserValues[raiserId] && raiserValues[raiserId](base, power),
	});
};

const raiserTypes = {
	numbernumber: "number",
};

const raiserValues = {
	numbernumber: (base, power) => Math.pow(base.value, power.value)
};

const raiserValidators = {
	numbernumber: [],
};