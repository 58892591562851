import moment from 'moment';
import _ from 'underscore';
import { sum, imap, isNumber, groupBy, compareMultiple, rangeIntersection,
  enumerate, scaleInnerRange, generateUUID } from '../utils.js';
import { organisationStore } from '../organisation.js';
import { ProjectTaskStore, PhaseTaskStore } from '../project/tasks.js';
import { getProjectPermissionLevel } from '../models/permissions.js';
import { iterStaffSpendTimeSeries, getStaffAvailabilityGraph } from '../organisationStore.js';
import { StoreBase, dispatcher, ActionCollection } from "../coincraftFlux.js";
import { getProjectReportMatcher } from '../reports/reportMatchers.js';
import { jsonHttp } from '../jsonHttp.js';
import { userStore } from '../user.js';
import { dateConverter } from '../models/dateconverter.js';
import { RangeAllocation } from '../models/rangeallocation.js';
import { Allocation } from '../models/allocation.js';
import { ProjectPhase } from '../models/projectphase.js';
import { Project } from '../models/project.js';
import apiRequest from '../apiRequest.js';

// TODO-scheduler_fiddling saving no-phase milestones? (but what)

export const Modal = class {
  constructor(component, props = {}) {
    this.component = component;
    this.props = props;
  }

  close() {
    actions.closeModal(this);
  }
}


export const ItemSelection = class {
  constructor({project = null, phase = null, rangeAllocation = null, staffMember = null, hours = null}) {
    this.project = project;
    this.phase = phase;

    if (phase != null) {
      this.project = phase.project;
    }

    this.rangeAllocation = rangeAllocation;
    this.staffMember = staffMember;
    this.hours = hours;

    this.lookup = {
      project: this.matchesProject,
      phase: this.matchesPhase,
      staffMember: this.matchesStaffMember,
      rangeAllocation: this.matchesRangeAllocation
    };
  }

  get isBlank() {
    return this.project == null &&
      this.phase == null &&
      this.rangeAllocation == null &&
      this.staffMember == null &&
      this.hours == null;
  }

  get isProject() {
    return this.project != null &&
      this.phase == null &&
      this.rangeAllocation == null &&
      this.staffMember == null &&
      this.hours == null;
  }

  get isPhase() {
    return this.project != null &&
      this.phase != null &&
      this.rangeAllocation == null &&
      this.staffMember == null &&
      this.hours == null;
  }

  matchesProject(project) {
    return this.project != null && project.id === this.project.id;
  }

  matchesPhase(phase) {
    return this.phase != null &&
      ((phase.id != null && phase.id === this.phase.id)
        || (phase.uuid != null && phase.uuid === this.phase.uuid));
  }

  matchesStaffMember(staffMember) {
    return this.staffMember != null && staffMember.id === this.staffMember.id;
  }

  matchesRangeAllocation(rangeAllocation) {
    return this.rangeAllocation != null
      && rangeAllocation.startDate === this.rangeAllocation.startDate
      && rangeAllocation.endDate === this.rangeAllocation.endDate;
  }

  matches(query) {
    for (let k in query) {
      if (query.hasOwnProperty(k)) {
        if (!this.lookup[k].call(this, query[k])) {
          return false;
        }
      }
    }
    return true;
  }
}


class ProjectStateCollection {
  constructor() {
    // List of {project: <Project>, state: 'dirty' | 'saving'}.
    // A project does not appear in the list if and only if it is clean.
    this.dirtyProjects = [];
  }

  setDirty(item) {
    var project = item.getProject();
    if (_.find(this.dirtyProjects, s => s.project === project) == null) {
      this.dirtyProjects.push({project: project, state: 'dirty'});
    }
  }

  removeProject(project) {
    var i = _.findIndex(this.dirtyProjects, s => s.project === project);
    this.dirtyProjects.splice(i, 1);
  }

  setSaving(item) {
    var project = item.getProject();
    var s = _.find(this.dirtyProjects, s => s.project === project);
    s.state = 'saving';
  }

  get isDirty() {
    return _.any(this.dirtyProjects, s => s.state === 'dirty');
  }

  getDirtyProjects() {
    return this.dirtyProjects.filter(s => s.state === 'dirty').map(s => s.project);
  }

  initiateSave() {
    this.dirtyProjects.forEach(function(s) {
      if (s.state === 'dirty') {
        s.state = 'saving';
      }
    });
  }

  saveComplete(projects) {
    var self = this;
    projects.forEach(function(project) {
      var i = _.findIndex(self.dirtyProjects, s => s.project === project);
      if (self.dirtyProjects[i].state === 'saving') {
        self.dirtyProjects.splice(i, 1);
      }
    });
  }
}


export const Store = class extends StoreBase {
  constructor() {
    super();

    // `startDate` and `endDate` refer to the leftmost and rightmost visible
    // points in the viewport. They don't need to correspond to the leftmost or
    // rightmost points in the data and they don't even need be integer date
    // values; the granularity depends on the granularity of the scrollbar.
    this.startDate = null;
    this.endDate = null;

    /*
       [
         {
            phase: <Phase>,
            percentageOfProjectFee: <percent>,
            percentageOfProjectFeeText: <percent or invalid input>,
            fee: <fee>,
            feeText: <fee or invalid input>,
         }
       ]

     * for the project `this.selection.project`.
     */
    this.projectSidebarPhaseList = null;

    this.projectFilterText = '';

    this.selection = new ItemSelection({});
    this.projectSidebarGroupBy = 'staffMember'; // 'phase' or 'staffMember'.

    this.projectState = new ProjectStateCollection();
    this.saveState = null; // null (not saving), 'saving', or 'saved' (recently saved and feedback still visible).

    this.deleteProjectState = null; // null, 'confirm', 'cantDelete'

    this.monthExpensesLookup = {};

    this.graphMode = 'actuals';
    this.cashFlowMode = 'actuals';

    // If true, dragging phases will scale allocations, and dragging
    // allocations may contract or expand their associated phase. If false,
    // neither of these will happen.
    this.linkDates = true;

    this.modals = [];
    this.reportUuid = null;

    this.feedSaveError = false;

    this.isReady = false;
    this.isRefreshing = false;

    this.projectTaskStore = new ProjectTaskStore({
      path: "milestones/projectTasks"
    });
    this.phaseTaskStore = new PhaseTaskStore({
      path: "milestones/phaseTasks"
    });
  }

  handle(action) {
    if (action.path === "milestones/projectTasks") {
      this.projectTaskStore.handle(action);
      this.emitChanged();
    }
    else if (action.path === "milestones/phaseTasks") {
      this.phaseTaskStore.handle(action);
      this.emitChanged();
    }
  }

  initializePage() {
    this.isReady = true;


    this.graphMode = 'actuals';
    this.cashFlowMode = 'actuals';

    this.isRefreshing = false;

    let self = this;
    this._refreshProjectsList(this.reportUuid).then(function(projects) {
      self._update();
    });
  }

  _refreshProjectsList(reportUuid) {
    let self = this;
    return new Promise(function(resolve, reject) {
      getProjectReportMatcher(organisationStore.getReportByUuid(reportUuid)).then(function(projectMatcher) {
        self.projectMatcher = projectMatcher;
        resolve();
      });
    });
  }

  * iterVisibleProjects() {
    const user = userStore.user;

    for (let p of organisationStore.projects) {
      if (this.projectMatcher(p) && (user.isAdmin || getProjectPermissionLevel(p, user) != null)) {
        yield p;
      }
    }
  }

  refreshPage() {
    this.isRefreshing = true;
    this.emitChanged();
    this.initializePage();
  }

  get isDirty() {
    return this.projectState.isDirty;
  }

  setDirty(item) {
    this.projectState.setDirty(item);
  }

  closeModal(modal, {emitChanged = true} = {}) {
    this.modals = _.without(this.modals, modal);
    if (emitChanged) {
      this.emitChanged();
    }
  }

  selectReport(reportUuid, {update = true} = {}) {
    let self = this;
    this.reportUuid = reportUuid;
    this._refreshProjectsList(reportUuid).then(function() {
      if (update) {
        self._update();
      }
    });
  }

  openCreateProjectForm() {
    this.modals.push({
      type: 'editItem',
      itemType: 'project',
      item: null
    });
    this.emitChanged();
  }

  closeCreateProjectForm() {
    this._closeModal('createProjectForm');
  }


  _makeProjectInitialPhases(project, projectData) {
    let projectStartDate = moment().startOf('month');

    // Support floating point `numMonths` (moment.js doesn't by itself).
    let projectEndDate = projectStartDate.clone()
                                         .add(Math.floor(projectData.numMonths), 'months')
                                         .add((projectData.numMonths % 1) * 30, 'days');

    if (projectData.hasDuration) {
      project.startDate = dateConverter.momentToInt(projectStartDate);
      project.endDate = dateConverter.momentToInt(projectEndDate);
    }

    let projectDays = projectEndDate.diff(projectStartDate, 'days');
    let numPhases = projectData.phases.length;
    let phaseFee = projectData.fee / numPhases;

    var phases = projectData.phases.map(function(phasePrototype, i) {
      let phaseStartDate, phaseEndDate;
      if (projectData.hasDuration) {
        phaseStartDate = dateConverter.momentToInt(
          projectStartDate.clone().add(i * projectDays / numPhases, 'days')
        );
        phaseEndDate = dateConverter.momentToInt(
          projectStartDate.clone().add((i + 1) * projectDays / numPhases, 'days')
        );
      }
      else {
        phaseStartDate = phaseEndDate = null;
      }

      let pp = new ProjectPhase({
        name: phasePrototype.name,
        jobCode: phasePrototype.jobCode,
        startDate: phaseStartDate,
        endDate: phaseEndDate,
        fee: phaseFee,
        milestones: [], // We'll set the milestones after this loop.
        allocation: new Allocation()
      });
      return pp;
    });


    phases.forEach(function(phase) {
      phase.project = project;
      phase.createDefaultTask();
      phase.adjustMilestones(phase.startDate, phase.endDate, phase.fee);
    });

    return phases;
  }

  saveEditProject(existingProject = null, projectData) {
    let project;
    if (existingProject != null) {
      project = existingProject;
      if ((project.startDate == null || project.endDate == null) && projectData.numHours != null) {
        throw new Error("Project has to have dates to have hours");
      }
    }
    else {
      if (!projectData.hasDuration && projectData.numHours != null) {
        throw new Error("Project has to have a duration to have hours");
      }
      project = new Project({
        expenses: []
      });
    }

    project.name = projectData.name;
    project.jobCode = projectData.jobCode;
    project.costCentre = projectData.costCentre;
    project.contact = projectData.contact;
    project.milestoneType = projectData.milestoneType;
    project.status = projectData.status;

    if (existingProject != null) {
      let phases = [];
      projectData.phases.forEach(function(phasePrototype) {
        let existingPhase = _.find(project.phases, p => p.uuid === phasePrototype.uuid);
        if (existingPhase != null) {
          phases.push(existingPhase);
          existingPhase.name = phasePrototype.name;
          existingPhase.jobCode = phasePrototype.jobCode;
          existingPhase.isDeleted = phasePrototype.isDeleted;
        }
        else {
          phases.push(project.createNewPhase({
            name: phasePrototype.name,
            jobCode: phasePrototype.jobCode,
            isDeleted: phasePrototype.isDeleted
          }));
        }
      });
      project.phases = phases;
    }
    else {
      project.phases = this._makeProjectInitialPhases(project, projectData);

      project.uuid = generateUUID();
      organisationStore.setObjectByUuid('Project', project);

      this.selectItem(new ItemSelection({project: project}));
    }

    project.setStaffMembers(projectData.staffMembers);
    project.updateFromMilestones();
    if (projectData.numHours != null) {
      // Set manual hours budgets, but don't create generic allocations; only
      // create allocations if real staff members are assigned.
      // https://docs.google.com/document/d/1ukDNu1gpvLvjyvIVFCvEmzdaYY-6An4SIMFAnhqPOvM/edit

      if (projectData.staffMembers.length > 0) {
        project.setTotalAllocatedHours(projectData.numHours);
        project.updateFromMilestones();
      }

      const hoursPerPhase = projectData.numHours / project.phases.length;
      for (let pp of project.phases) {
        pp.manualHoursBudget = hoursPerPhase;
      }
      project.manualHoursBudget = projectData.numHours;
    }
    project.setFee(projectData.fee, true);

    if (existingProject == null) {
      project.setupDefaultTasks();
    }

    this.setDirty(project);
    this._update();
  }

  _setPhaseDates(phase, startDate, endDate) {
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }
    if (this.linkDates) {
      phase.setAllocationDates(...scaleInnerRange(
        phase.getAllocationRange(),
        [phase.startDate, phase.endDate],
        [startDate, endDate]
      ));
    }
    phase.adjustMilestones(startDate, endDate);
  }

  _ensureProjectGoesForwards(project) {
    if (project.startDate > project.endDate) {
      this.isReversed = !this.isReversed;
      [project.startDate, project.endDate] = [project.endDate, project.startDate];

      for (let phase of project.getVisiblePhases()) {
        let phaseStartDate = Math.max(phase.endDate, project.startDate);
        let phaseEndDate = Math.min(phase.startDate, project.endDate);
        if (phaseStartDate > phaseEndDate) {
          [phaseStartDate, phaseEndDate] = [phaseEndDate, phaseStartDate];
        }
        phase.adjustMilestones(phaseStartDate, phaseEndDate);

        for (let rangeAllocation of phase.allocations) {
          if (rangeAllocation.startDate > rangeAllocation.endDate) {
            [rangeAllocation.startDate, rangeAllocation.endDate] = [rangeAllocation.endDate, rangeAllocation.startDate];
          }
        }
      }
    }
  }

  _updateProjectFromPhase(phase) {
    phase.project.startDate = Math.min(...phase.project.getVisiblePhases().map(p => p.startDate));
    phase.project.endDate = Math.max(...phase.project.getVisiblePhases().map(p => p.endDate));
  }

  _updatePhaseFromAllocations(phase) {
    phase.adjustMilestones(
      Math.min(...phase.allocations.map(a => a.startDate)),
      Math.max(...phase.allocations.map(a => a.endDate))
    );
    this._updateProjectFromPhase(phase);
  }

  slideProjectStartDate(project, startDate) {
    let self = this;
    if (!isNumber(startDate)) {
      throw new Error(startDate);
    }

    let factor = (project.endDate - startDate) / (project.endDate - project.startDate);

    project.getVisiblePhases().forEach(function(phase) {
      if (isNumber(factor)) {
        self._setPhaseDates(phase,
          startDate + (phase.startDate - project.startDate) * factor,
          startDate + (phase.endDate - project.startDate) * factor
        );
      }
      else {
        // If the project was zero size when we started.
        self._setPhaseDates(phase, startDate, project.endDate);
      }
    });

    project.startDate = startDate;
    this._ensureProjectGoesForwards(project);

    this.setDirty(project);
    this._update();
  }

  slideProjectEndDate(project, endDate) {
    let self = this;
    if (!isNumber(endDate)) {
      throw new Error(endDate);
    }

    let factor = (endDate - project.startDate) / (project.endDate - project.startDate);
    project.getVisiblePhases().forEach(function(phase) {
      if (isNumber(factor)) {
        self._setPhaseDates(phase,
          project.startDate + (phase.startDate - project.startDate) * factor,
          project.startDate + (phase.endDate - project.startDate) * factor
        );
      }
      else {
        // If the project was zero size when we started.
        self._setPhaseDates(phase, project.startDate, endDate);
      }
    });

    project.endDate = endDate;
    this._ensureProjectGoesForwards(project);

    this.setDirty(project);
    this._update();
  }

  slideProjectBy(project, diff) {
    project.moveBy(diff);
    this.setDirty(project);
    this._update();
  }

  slidePhaseBy(phase, diff) {
    this._setPhaseDates(phase, phase.startDate + diff, phase.endDate + diff);
    this._updateProjectFromPhase(phase);
    this.setDirty(phase);
    this._update();
  }

  slideStaffAllocationBy(phase, staffMember, diff) {
    phase.allocations.forEach(function(rangeAllocation) {
      if (rangeAllocation.staffMember.id === staffMember.id) {
        rangeAllocation.startDate += diff;
        rangeAllocation.endDate += diff;
      }
    });

    if (this.linkDates) {
      this._updatePhaseFromAllocations(phase);
    }
    this.setDirty(phase);
    this._update();
  }

  slideStaffAllocationSliderStartDate(phase, staffMember, startDate) {
    let isReversed = phase.setAllocationDates(startDate, null, staffMember);
    if (isReversed) {
      this.isReversed = !this.isReversed;
    }

    if (this.linkDates) {
      this._updatePhaseFromAllocations(phase);
    }

    this.setDirty(phase);
    this._update();
  }

  setRangeAllocationSidebarStartDate(phase, staffMember, rangeAllocation = null, startDate) {
    if (rangeAllocation != null) {
      phase.setRangeAllocationStartDate(rangeAllocation, dateConverter.momentToInt(startDate));
      if (this.linkDates) {
        this._updatePhaseFromAllocations(phase);
      }
      this._update();
    }
    else {
      this.slideStaffAllocationSliderStartDate(phase, staffMember, dateConverter.momentToInt(startDate));
    }
  }

  setRangeAllocationSidebarEndDate(phase, staffMember, rangeAllocation = null, endDate) {
    if (rangeAllocation != null) {
      phase.setRangeAllocationEndDate(rangeAllocation, dateConverter.momentToInt(endDate));
      if (this.linkDates) {
        this._updatePhaseFromAllocations(phase);
      }
      this._update();
    }
    else {
      this.slideStaffAllocationSliderEndDate(phase, staffMember, dateConverter.momentToInt(endDate));
    }
  }

  slideStaffAllocationSliderEndDate(phase, staffMember, endDate) {
    let isReversed = phase.setAllocationDates(null, endDate, staffMember);
    if (isReversed) {
      this.isReversed = !this.isReversed;
    }

    if (this.linkDates) {
      this._updatePhaseFromAllocations(phase);
    }

    this.setDirty(phase);
    this._update();
  }

  deselectAll({emitChanged = true} = {}) {
    this.selection = new ItemSelection({});
    if (emitChanged) {
      this.emitChanged();
    }
  }

  selectItem(selection, {update = true} = {}) {
    this.selection = selection;

    if (selection.project != null && selection.phase == null) {
      this._setProjectSidebarPhaseList(selection.project);
    }
    if (update) {
      this._update();
    }
  }

  selectSliderRangeAllocation(phase, staffMember, connectingLineItemIndex) {
    this.selection = new ItemSelection({
      phase: phase,
      staffMember: staffMember,
      rangeAllocation: phase.allocations.filter(ra => ra.staffMember.id === staffMember.id)[connectingLineItemIndex]
    });
    this.emitChanged();
  }

  sliderMouseUp() {
    // If while dragging, they dragged the start of the selected item to after
    // the end (or vice versa) and therefore reversed the dragging behaviour,
    // clear the reversal when they let go.

    this.isReversed = false;
    this.emitChanged();
  }

  _setProjectSidebarPhaseList(project) {
    project.fee = sum(project.getVisiblePhases().map(p => p.fee));
    this.projectSidebarPhaseList = project.getVisiblePhases().map(function(phase) {
      var percentageOfProjectFee = phase.fee / project.fee * 100;
      return {
        phase: phase,
        percentageOfProjectFee: percentageOfProjectFee,
        fee: phase.fee,
      };
    });
  }

  setProjectSidebarPhaseHours(phase, hours) {
    phase.setTotalAllocatedHours(parseInt(hours));
    this.setDirty(phase);
    this._update();
  }

  setProjectSidebarPhaseStaffExpenses(phase, staffExpenses) {
    this._setPhaseHoursVia(
      phase,
      staffExpenses,
      phase => phase.staffExpenses,
      staffMember => staffMember.payRate
    );
    this._update();
  }

  setPhaseSidebarPhaseStaffExpenses(staffExpenses) {
    this.setProjectSidebarPhaseStaffExpenses(this.selection.phase, staffExpenses);
  }

  setRangeAllocationSidebarHours(hours, rangeAllocation = null) {
    if (this.selection.phase == null) {
      throw new Error("Must have a phase");
    }

    rangeAllocation = rangeAllocation || this.selection.rangeAllocation;

    if (rangeAllocation != null) {
      rangeAllocation.hours = hours;
    }
    else {
      this.selection.phase.setStaffAllocation(this.selection.staffMember, hours);
    }
    this.setDirty(this.selection.phase);
    this._update();
  }

  setRangeAllocationSidebarExpenses(expenses) {
    this.setRangeAllocationSidebarHours(expenses / this.selection.staffMember.payRate);
  }

  setRangeAllocationSidebarChargeOut(chargeOut) {
    this.setRangeAllocationSidebarHours(chargeOut / this.selection.staffMember.chargeOutRate);
  }

  setProjectSidebarPhaseStaffChargeOut(phase, phaseChargeOut) {
    this._setPhaseHoursVia(
      phase,
      phaseChargeOut,
      phase => phase.staffChargeOut,
      staffMember => staffMember.chargeOutRate
    );
    this._update();
  }

  setPhaseSidebarPhaseChargeOut(phaseChargeOut) {
    this._setPhaseHoursVia(
      this.selection.phase,
      phaseChargeOut,
      phase => phase.staffChargeOut,
      staffMember => staffMember.chargeOutRate
    );
    this._update();
  }

  _setPhaseHoursVia(phase, value, phaseFunc, staffFunc) {
    /**
     * Indirectly set the the phase hours by reverse engineering what they would be
     * based on the charge out / pay rate desired.
     *
     * `phaseFunc`: a function that given a phase, returns the sum of the values
     *   of the quantity desired. (eg. If we're updating the phase pay rate, it should
     *   return the total pay for all the staff members for the phase).
     *
     * `staffMemberFunc`: a function that given a staff member, returns the value
     *   for that staff member for the type of quantity desired. (If we're updating
     *   the phase pay rate, it should return the staff member's pay rate).
     */

    if (phase.allocation.hasGeneric()) {
      throw new Error("Called _setPhaseHoursVia with generic allocation");
    }

    var currentTotalHours = phase.allocation.getTotalAllocatedHours();
    if (currentTotalHours > 0) {
      // If we already have a nonzero value, just scale it.
      phase.setTotalAllocatedHours(currentTotalHours * value / phaseFunc(phase));
    }
    else {
      // Otherwise, assume we're setting equal numbers of hours for all the staff
      // allocated to the phase.
      //
      // Then the number of hours is
      //  (for charge out rate): (desired total charge out rate) / (average charge out rate for staff)
      //  (for pay rate):        (desired pay rate) / (average pay rate for staff)

      let total = sum(phase.allocation.getStaffMembers().map(staffFunc));
      let avg = total / phase.allocation.getNumAllocatedStaff();
      phase.setTotalAllocatedHours(value / avg);
    }
    this.setDirty(phase);
  }


  _setProjectStaffAllocationVia(project, value, projectFunc, staffFunc) {
    /**
     * Analogous to above.
     */
    const currentValue = projectFunc(project);
    if (currentValue > 0) {
      const factor = value / currentValue;
      project.getVisiblePhases().forEach(function(phase) {
        phase.setTotalAllocatedHours(phase.hours * factor);
      });
    }
    else {
      const phaseValue = value / project.getVisiblePhases().length;
      project.getVisiblePhases().forEach(function(phase) {
        const totalStaffValue = sum(phase.allocation.getStaffMembers().map(staffFunc));
        const avg = totalStaffValue / phase.allocation.getNumAllocatedStaff();
        phase.setTotalAllocatedHours(phaseValue / avg);
      });
    }
    this.setDirty(project);
  }


  setProjectSidebarStaffMemberHours(staffMember, hours) {
    this.selection.project.setStaffAllocation(staffMember, parseInt(hours));
    this.setDirty(this.selection.project);
    this._update();
  }

  setProjectSidebarStaffMemberExpenses(staffMember, expenses) {
    this.selection.project.setStaffAllocation(staffMember, parseFloat(expenses) / staffMember.costRate);
    this.setDirty(this.selection.project);
    this._update();
  }

  setProjectSidebarStaffMemberChargeOut(staffMember, chargeOut) {
    this.selection.project.setStaffAllocation(staffMember, parseInt(chargeOut) / staffMember.chargeOutRate);
    this.setDirty(this.selection.project);
    this._update();
  }

  setProjectSidebarPhaseFee(phase, fee) {
    phase.setFee(fee, true);
    this._setProjectSidebarPhaseList(phase.project);
    this.setDirty(phase);
    this._update();
  }

  * iterItems(projectFilter, phaseFilter, allocationFilter, staffMemberFilter, startDate, endDate) {
    if ((startDate != null) != (endDate != null)) {
      throw new Error("must supply either both `startDate` and `endDate` or neither");
    }

    if (phaseFilter != null && projectFilter == null) {
      projectFilter = phaseFilter.project;
    }

    for (let project of ((projectFilter != null) ? [projectFilter] : organisationStore.projects)) {
      if (this.projectMatcher != null && !this.projectMatcher(project)) {
        continue;
      }
      for (let phase of ((phaseFilter != null) ? [phaseFilter] : project.getVisiblePhases())) {
        for (let rangeAllocation of ((allocationFilter != null) ? [allocationFilter] : phase.allocations)) {
          if (staffMemberFilter != null && rangeAllocation.staffMember.id !== staffMemberFilter.id) {
            continue;
          }

          let intersectingHours = null;
          if (startDate == null && endDate == null) {
            intersectingHours = rangeAllocation.hours;
          }
          else {
            let intersection = rangeIntersection([rangeAllocation.startDate, rangeAllocation.endDate], [startDate, endDate]);
            if (intersection != null) {
              const intersectingWeekdays = dateConverter.numWeekdaysBetween(intersection[0], intersection[1]);
              const rangeAllocationWeekdays = dateConverter.numWeekdaysBetween(rangeAllocation.startDate, rangeAllocation.endDate);
              if (rangeAllocationWeekdays > 0) {
                intersectingHours = intersectingWeekdays / rangeAllocationWeekdays * rangeAllocation.hours;
              }
              else {
                intersectingHours = 0;
              }
            }
          }

          if (intersectingHours != null && rangeAllocation.staffMember) {
            yield [project, phase, rangeAllocation, rangeAllocation.staffMember.id, intersectingHours];
          }
        }
      }
    }
  }

  itemsGroupedBy({projectFilter, phaseFilter, allocationFilter, staffMemberFilter, startDate, endDate}, groupers) {
    let args = [projectFilter, phaseFilter, allocationFilter, staffMemberFilter, startDate, endDate];
    let lst = Array.from(this.iterItems(...args));

    let lookup = {
      project: {
        grouperFunc: a => a[0],
        keyFunc: p => p.id
      },
      phase: {
        grouperFunc: a => a[1],
        keyFunc: pp => pp.id
      },
      staffMember: {
        grouperFunc: a => a[3],
        keyFunc: staffId => staffId
      }
    };

    return groupBy(lst,
      item => groupers.map(g => lookup[g].grouperFunc(item)),
      group => Array.from(imap(enumerate(group), ([i, g]) => lookup[groupers[i]].keyFunc(g)))
    );
  }


  eachItem(projectFilter, phaseFilter, allocationFilter, staffMemberFilter, startDate, endDate, callback) {
    for (let item of this.iterItems(projectFilter, phaseFilter, allocationFilter, staffMemberFilter, startDate, endDate)) {
      callback(...item);
    }
  }

  getTotalHours(project, phase, milestone, staffMember) {
    let total = 0;
    this.eachItem(project, phase, milestone, staffMember, function(project, phase, milestone, staffMember, hours) {
      total += hours;
    });
    return total;
  }

  setHours(project, phase, milestone, staffMember, hours) {
    var currentTotal = this.getTotalHours(project, phase, milestone, staffMember);
    if (currentTotal > 0) {
      var factor = hours / currentTotal;
      this.eachItem(project, phase, milestone, staffMember, function(project, phase, milestone, staffId, hours) {
        milestone.setStaffMemberAllocation({id: staffId}, hours * factor);
      });
      this._update();
    }
    else {
      throw new Error("not yet");
    }
  }

  setProjectSidebarProjectStaffExpenses(expenses) {
    //TODO-new_project_creator handle non-integer input.
    this._setProjectStaffAllocationVia(
      this.selection.project,
      parseInt(expenses),
      project => project.getStaffExpensesFromAllocations(null, 'costRate').expenses,
      staffMember => staffMember.costRate
    );
    this._update();
  }

  setProjectSidebarProjectChargeOut(chargeOut) {
    //TODO-new_project_creator handle non-integer input.
    this._setProjectStaffAllocationVia(
      this.selection.project,
      parseInt(chargeOut),
      project => project.staffChargeOut,
      staffMember => staffMember.chargeOutRate
    );
    this._update();
  }

  projectSidebarGroupByButtonClick(groupBy) {
    this.projectSidebarGroupBy = groupBy;
    this.emitChanged();
  }

  projectSidebarSetProjectManualBudget(budget) {
    let project = this.selection.project;
    //TODO-scheduling_tweaks better handle non-numeric input
    project.setManualBudget(parseFloat(budget));
    this.setDirty(project);
    this.emitChanged();
  }

  projectSidebarSetPhaseManualBudget(phase, budget) {
    /**
     * `phase`: ProjectPhase | 'expenses'
     */
    let project = this.selection.project;
    project.setPhaseManualBudget(phase, budget);
    this.setDirty(project);
    this.emitChanged();
  }

  phaseSidebarSetPhaseManualBudget(budget) {
    //TODO-manual_budget_expenses better handle non-numeric input
    this.selection.project.setPhaseManualBudget(this.selection.phase, budget);
    this.setDirty(this.selection.project);
    this.emitChanged();
  }

  phaseSidebarCalculateHours() {
    let phase = this.selection.phase;
    let project = this.selection.project;

    phase.updateHoursBudgetFromStaffAllocations();

    this.setDirty(project);
    this.emitChanged();
  }

  phaseSidebarSetPhaseManualHoursBudget(hours) {
    let phase = this.selection.phase;
    let project = this.selection.project;
    project.setPhaseManualHoursBudget(phase, hours);
    this.setDirty(project);
    this.emitChanged();
  }

  addPhaseClick() {
    this.modals.push({
      type: 'editItem',
      itemType: 'phase',
      item: null
    });
    this.emitChanged();
  }

  setProjectStaffMemberAllocationSliderValue(project, staffMember, hours) {
    project.setStaffAllocation(staffMember, hours);
    this.setDirty(project);
    this._update();
  }

  setPhaseStaffMemberAllocationSliderValue(phase, staffMember, hours) {
    phase.setStaffAllocation(staffMember, hours);
    this.setDirty(phase);
    this._update();
  }

  setPhaseSidebarStaffMemberHours(staffMember, hours) {
    var phase = this.selection.phase;
    phase.setStaffAllocation(staffMember, hours);
    this.setDirty(phase);
    this._update();
  }

  setPhaseSidebarStaffMemberChargeOut(staffMember, chargeOut) {
    var phase = this.selection.phase;
    phase.setStaffAllocation(staffMember, chargeOut / staffMember.chargeOutRate);
    this.setDirty(phase);
    this._update();
  }

  phaseSidebarSetStaffMemberBudgetedHours(staffMember, hours) {
    let phase = this.selection.phase;
    phase.setBudgetedHoursForStaffMember(staffMember, hours);
    this.setDirty(phase);
    this.emitChanged();
  }

  setPhaseSidebarStaffMemberExpenses(staffMember, expenses) {
    let phase = this.selection.phase;
    phase.setStaffAllocation(staffMember, expenses / staffMember.costRate);
    this.setDirty(this.selection.phase);
    this._update();
  }

  setPhaseSidebarPhaseHours(hours) {
    this.selection.phase.setTotalAllocatedHours(parseInt(hours));
    this.setDirty(this.selection.phase);
    this._update();
  }

  setPhaseSidebarPhaseFee(fee) {
    this.selection.phase.setFee(parseInt(fee), true);
    this.setDirty(this.selection.phase);
    this._update();
  }

  slideMilestoneEndDate(phase, milestone, endDate) {
    if (!isNumber(endDate)) {
      throw new Error(endDate);
    }

    if (milestone === _.last(phase.milestones)) {
      if (phase.startDate > endDate) {
        this.isReversed = !this.isReversed;
      }
      this._setPhaseDates(phase, phase.startDate, endDate);
      this._updateProjectFromPhase(phase);
    }
    else {
      milestone.endDate = endDate;
    }
    this.setDirty(milestone);
    this._update();
  }

  setMilestonePercent(milestone, newPercent) {
    this._setMilestonePercent(milestone, newPercent);
    this.setDirty(milestone);
    this._update();
  }

  setMilestoneFee(milestone, newFee) {
    milestone.setRevenue(newFee);
    milestone.phase.updateMilestonePercentsBasedOnRevenue();
    this.setDirty(milestone);
    this._update();
  }

  _setMilestonePercent(milestone, newPercent) {
    milestone.setPercent(newPercent);
    milestone.phase.updateMilestoneRevenuesBasedOnPercent();
  }

  setFeedStaffMemberHours(project, phase, staffMember, startDate, endDate, hours) {
    phase.setStaffMemberHours(
      staffMember,
      startDate,
      endDate,
      hours
    );
    this.setDirty(project);
    this._update();
  }

  setFeedStaffMemberPercent(project, phase, staffMember, startDate, endDate, percent) {
    /**
     * Set the staff member's allocation for this project+phase+date range to
     * be `percent` percent of that staff member's total availability for the
     * date range (regardless of whatever other allocations may or may not
     * already exist).
     */

    this.setFeedStaffMemberHours(
      project,
      phase,
      staffMember,
      startDate,
      endDate,
      percent / 100 * staffMember.getNumHoursAvailableInRange(startDate, endDate, organisationStore.getHolidaysXspans().data)
    );
  }

  slidePhaseStartDate(phase, startDate, {update = true} = {}) {
    if (startDate > phase.endDate) {
      this.isReversed = !this.isReversed;
    }
    this._setPhaseDates(phase, startDate, phase.endDate);
    this._updateProjectFromPhase(phase);

    this.setDirty(phase);
    if (update) {
      this._update();
    }
  }

  getCashFlowGraph(graphMode, startDate, endDate, now = moment()) {
    startDate = dateConverter.startOfMonthOffset(startDate);

    let cashFlow = {};

    const projects = Array.from(this.iterVisibleProjects());

    for (let monthStart = startDate; monthStart <= endDate; ) {
      const monthEnd = dateConverter.endOfMonthOffset(monthStart);

      cashFlow[monthStart] = {
        income: 0,
        spend: 0,
        cashFlowItems: []
      };

      monthStart = monthEnd + 1;
    }

    function addCashFlowItem(cashFlowItem, project) {
      const month = dateConverter.startOfMonthOffset(cashFlowItem.endDate);
      if (cashFlow[month] == null
          || (graphMode === 'projected' && project != null && cashFlowItem.milestone != null && !project.showProjections)) {
        return;
      }
      const fee = cashFlowItem.fee;
      if (!isNumber(fee)) {
        throw new Error("should be a number");
      }
      cashFlow[month].cashFlowItems.push(cashFlowItem);
    }

    const momentStartDate = dateConverter.intToMoment(startDate);
    const momentEndDate = dateConverter.intToMoment(endDate);

    if (userStore.includeOrganisationExpenses()) {
      organisationStore.getNonProjectExpenses(momentStartDate, momentEndDate, addCashFlowItem);

      const ts = organisationStore.getStaffMonthlySpend(
        projects,
        momentStartDate.clone().startOf('month'),
        momentEndDate.clone().endOf('month')
      );
      for (let cfi of iterStaffSpendTimeSeries(ts)) {
        addCashFlowItem(cfi);
      }
    }

    for (let project of projects) {
      //TODO-fun why not have getCashFlowItemsByMode and iterExpenseCashFlowItemsInRange be one function?
      for (let cfi of project.getCashFlowItemsByMode(graphMode, now)) {
        addCashFlowItem(cfi, project);
      }
      for (let cfi of project.iterExpenseCashFlowItemsInRange(startDate, endDate)) {
        addCashFlowItem(cfi, project);
      }
    }

    const keys = _.keys(cashFlow).map(k => parseInt(k)).sort((a, b) => a - b);

    return keys.map(function(k) {
      return {
        date: k,
        income: sum(cashFlow[k].cashFlowItems.map(cfi => cfi.fee)),
        spend: sum(cashFlow[k].cashFlowItems.map(cfi => cfi.spend)),
        cashFlowItems: cashFlow[k].cashFlowItems
      };
    });
  }

  getStaffAllocationGraph(startDate, endDate) {
// <<<<<<< HEAD
//     let staffAllocationGraph = {};
//
//     const totalWeeklyHoursAvailable = sum(organisationStore.getVisibleStaff().map(sm => sm.weeklyHoursAvailable));
//     const holidaysArray = organisationStore.getHolidaysXspans().data;
// =======
    const staffMembers = organisationStore.getVisibleStaff();
    let holidaysArray = organisationStore.getHolidaysXspans().data;
    let staffAllocationGraph = _.mapObject(
      getStaffAvailabilityGraph(
        staffMembers,
        holidaysArray,
        startDate,
        endDate
      ),
      n => ({income: n, spend: 0})
    );
// >>>>>>> new_project_form

    for (let monthStart = dateConverter.startOfMonthOffset(startDate); monthStart <= endDate; ) {
      const monthEnd = dateConverter.endOfMonthOffset(monthStart);
// <<<<<<< HEAD
//
//       staffAllocationGraph[monthStart] = {
//         income: dateConverter.numWeekdaysInMonthWithoutHolidays(monthStart, holidaysArray) * totalWeeklyHoursAvailable / 5,
//         spend: 0
//       };
//
//       this.eachItem(null, null, null, null, monthStart, monthEnd, function(pr, ph, ra, st, hours) {
// =======
      this.eachItem(null, null, null, null, monthStart, monthEnd, function(pr, ph, ra, staffId, hours) {
// >>>>>>> new_project_form
        staffAllocationGraph[monthStart].spend += hours;
      });
      monthStart = monthEnd + 1;
    }

    const keys = _.keys(staffAllocationGraph).map(k => parseInt(k)).sort((a, b) => a - b);
    return keys.map(function(k) {
      return {
        date: k,
        income: staffAllocationGraph[k].income,
        spend: staffAllocationGraph[k].spend
      };
    });
  }

  getGraphs(graphMode, startDate, endDate, now) {
    return {
      cashFlow: this.getCashFlowGraph(graphMode, startDate, endDate, now),
      staffAllocationGraph: this.getStaffAllocationGraph(startDate, endDate)
    };
  }

  _update({setTimer = true} = {}) {
    const projects = Array.from(this.iterVisibleProjects());

    for (let project of projects) {
      let endDate = Math.max(
        ...project.getVisiblePhases().map(function(p) {
          if (p.milestones.length > 0) {
            return p.milestones[p.milestones.length - 1].endDate;
          }
          else {
            return p.endDate;
          }
        })
        .filter(d => d != null)
      );

      if (!isFinite(endDate)) {
        endDate = null;
      }
      project.endDate = endDate;
    }

    let cashFlowProjects = projects.filter(p => p.startDate != null && p.endDate != null);

    // `startDate` and `endDate` are the left- and right-most points of the
    // available data. Not to be confused with `this.startDate` and
    // `this.endDate` which are the left- and right-most visible points on the
    // viewport. (TODO: fix the naming.)
    var startDate = Math.min(...cashFlowProjects.map(p => p.startDate));
    var endDate = Math.max(...cashFlowProjects.map(p => p.endDate));

    if (!isFinite(startDate) || !isFinite(endDate)) {
      startDate = dateConverter.momentToInt(moment().startOf('month'));
      endDate = dateConverter.momentToInt(moment().startOf('month').add(1, 'year').subtract(1, 'day'));
    }

    if (this.cashFlow != null && this.cashFlow.length > 0) {
      // Don't ever contract the start/end date, because that's confusing for the user even
      // if it's technically correct.

      startDate = Math.min(startDate, this.cashFlow[0].date);
      endDate = Math.max(endDate, this.cashFlow[this.cashFlow.length - 1].date);
    }

    // Order of this and the previous is important. If we expand to 12 months
    // then expand to include the previous range, the range gets bigger and
    // bigger. But if we expand to the previous range then expand to 12 months
    // we keep the max 12 months.
    if (dateConverter.diffMonths(endDate, startDate) < 12) {
      endDate = dateConverter.momentToInt(dateConverter.intToMoment(startDate).add(1, 'year').subtract(1, 'day'));
    }

    // If `this.startDate` and `this.endDate` aren't set yet it means this is
    // our first go at generating the graph, so set up the initial viewport.
    if (this.startDate == null) {
      this.startDate = dateConverter.momentToInt(moment().startOf('month'));
    }
    if (this.endDate == null) {
      this.endDate = dateConverter.momentToInt(moment().startOf('month').add(1, 'year').subtract(1, 'day'));
    }

    let {cashFlow, staffAllocationGraph} = this.getGraphs(
      this.graphMode,
      Math.min(startDate, this.startDate),
      Math.max(endDate, this.endDate)
    );
    this.cashFlow = cashFlow;
    this.staffAllocationGraph = staffAllocationGraph;

    if (this.firstSelectedMonth == null) {
      this.selectMonth(moment().startOf('month'));
    }

    // Calculated project data

    for (let project of projects) {
      project.updateFromMilestones();
    }

    this.checkInvariants(projects);

    this.emitChanged();

    graphScaleStore.touch();
  }

  selectMonth(d) {
    this.firstSelectedMonth = d.clone();
    this.lastSelectedMonth = d.clone();
  }

  getGraphData() {
    return (this.graphMode === 'projected' || this.graphMode === 'actuals') ?
      this.cashFlow
    : this.staffAllocationGraph;
  }

  exportCashFlow() {
    /**
     * {
     *    "2015-01-01": {
     *      income: 5000,
     *      spend: 2000
     *    },
     *    "2015-02-01": {
     *      ...
     *    }
     * }
     */
    return _(this.cashFlow.map(function(d) {
      return [dateConverter.intToMoment(d.date).format("YYYY-MM-DD"), _.pick(d, ['income', 'spend'])];
    })).object();
  }


  getOverrideMaxY() {
    let factor = 1.2;
    if (this.graphMode === 'projected' || this.graphMode === 'actuals') {
      return Math.max(...this.cashFlow.map(d => Math.max(d.income, d.spend))) * factor;
    }
    else {
      return Math.max(...this.staffAllocationGraph.map(d => Math.max(d.income, d.spend))) * factor;
    }
  }


  getStaffAllocation(staffMember, startDate, endDate) {
    let allocation = 0;
    this.eachItem(null, null, null, staffMember, startDate, endDate, function(pr, ph, ra, st, hours) {
      allocation += hours;
    });
    return allocation;
  }

  checkInvariants(projects) {
    var self = this;

    function checkInvariant(val, message) {
      // if (!val) {
      //   console.log(self, message);
      //   throw new Error(message);
      // }
    };

    function similar(n1, n2) {
      return Math.abs(n1 - n2) < 0.001;
    }

    function checkAllocation(allocation, message) {
      checkInvariant(allocation != null, message + ' allocation does not exist');
      checkInvariant(allocation.dict != null, message + ' allocation has no dict');
      _.each(allocation.dict, function(v, k) {
        checkInvariant(isNumber(parseInt(k)), message + ' allocation has invalid key');
        checkInvariant(isNumber(v), message + ` allocation has invalid value ${v}`);
      });
    }

    for (let project of projects) {
      checkInvariant(project.getVisiblePhases() != null, 'project has no phases');
      checkAllocation(project.allocation, 'project allocation');

      project.getVisiblePhases().forEach(function(phase) {
        checkInvariant(phase.project === project, 'phase project link broken');
        checkAllocation(phase.allocation, 'phase');
        checkInvariant(isNumber(phase.fee), `phase fee is not a number (${phase.fee})`);
        checkInvariant(similar(phase.hours, sum(phase.allocations.filter(a => a.staffMember).map(a => a.hours))),
                       "milestone allocations don't add up to phase hours");

        if (phase.startDate != null && phase.endDate != null) {
          checkInvariant(phase.startDate <= phase.endDate, "phase start after end");
          if (project.startDate != null && project.endDate != null) {
            checkInvariant(phase.startDate >= project.startDate, 'phase started before project start');
            // checkInvariant(phase.milestones.length > 0, 'phase had no milestones');
            // let lastMilestoneEndDate = _.last(phase.milestones).endDate;
          }
        }


        phase.milestones.forEach(function(milestone) {
          checkInvariant(milestone.phase === phase, 'milestone phase link broken');
        });

        for (let ra of phase.allocations) {
          checkInvariant(isNumber(ra.startDate), 'RangeAllocation start date was not a number');
          checkInvariant(isNumber(ra.endDate), 'RangeAllocation end date was not a number');
        }
      });

      checkInvariant(similar(sum(project.getVisiblePhases().map(p => p.fee)), project.fee), "phase fees didn't add to project fee");
    }

    checkInvariant(this.cashFlow.length != null, 'cash flow does not exist');
    this.cashFlow.forEach(function(d) {
      checkInvariant(isNumber(d.income), 'cash flow entry income is not a number');
      checkInvariant(isNumber(d.spend), 'cash flow entry spend is not a number');
      checkInvariant(isNumber(d.date), 'cash flow entry date is not a number');
    });
  }


  getStaffMemberById(staffId) {
    return organisationStore.getStaffMemberById(staffId);
  }

  updateOverrideMaxY(overrideMaxY) {
    this.overrideMaxY = overrideMaxY;
    this._update();
  }

  addMilestoneToPhase(phase) {
    phase.addMilestone();
    this.setDirty(phase);
    this._update();
  }

  removeMilestoneFromPhase(phase, milestone) {
    phase.removeMilestone(milestone, {updateAllocations: this.linkDates});
    this.setDirty(phase);
    this._update();
  }

  openAddStaffMemberToItemPopup(item) {
    this.modals.push({
      type: 'assignStaffMembers',
      item: item
    });
    this.emitChanged();
  }

  staffAllocationSidebarAddAllocation() {
    let maxEndDate = Math.max(...this.selection.phase.allocations
      .filter(ra => ra.staffMember.id === this.selection.staffMember.id)
      .map(ra => ra.endDate)
    );

    this.selection.phase.allocations.push(new RangeAllocation({
      startDate: maxEndDate + 1,
      // Make it at least two days and end on the end of a month.
      endDate: dateConverter.endOfMonthOffset(maxEndDate + 2),
      staffMember: this.selection.staffMember,
      staffRole: this.selection.staffMember.role,
      hours: 0
    }));

    if (this.linkDates) {
      this._updatePhaseFromAllocations(this.selection.phase);
    }

    this._update();
  }

  staffAllocationSidebarRemoveAllocation(rangeAllocation) {
    let phase = this.selection.phase;
    let allocations = _.sortBy(
      phase.allocations.filter(ra => ra.staffMember.id === this.selection.staffMember.id),
      a => a.startDate
    );

    if (rangeAllocation === allocations[0] || rangeAllocation === _.last(allocations)) {
      phase.allocations = _.without(phase.allocations, rangeAllocation);
    }
    else {
      rangeAllocation.hours = 0;
    }

    if (this.linkDates) {
      this._updatePhaseFromAllocations(phase);
    }

    this.setDirty(phase);
    this._update();
  }

  setProjectSidebarProjectStartDate(startDate) {
    let intStartDate = dateConverter.momentToInt(startDate);

    if (this.selection.project.endDate != null) {
      // If we have an end date, then setting the start date works the same way
      // as dragging the start date on the slider (it scales the project).

      this.slideProjectStartDate(this.selection.project, dateConverter.momentToInt(startDate));
    }
    else {
      // Otherwise just set the start date by itself.
      this.selection.project.startDate = dateConverter.momentToInt(startDate);
      this.selection.project.getVisiblePhases().forEach(function(phase) {
        phase.startDate = intStartDate;
      });
      this._update();
    }
  }

  setProjectSidebarProjectEndDate(endDate) {
    // Converse of above.
    let intEndDate = dateConverter.momentToInt(endDate);

    if (this.selection.project.startDate != null) {
      // If we have a start date, then setting the end date works the same way
      // as dragging the end date on the slider (it scales the project).
      this.slideProjectEndDate(this.selection.project, intEndDate);
    }
    else {
      // Otherwise just set the end date by itself.
      this.selection.project.endDate = intEndDate;
      this.selection.project.getVisiblePhases().forEach(function(phase) {
        phase.endDate = intEndDate;
      });
      this._update();
    }
  }


  setPhaseSidebarPhaseStartDate(startDate) {
    if (!moment.isMoment(startDate)) {
      throw new Error("`startDate` should be a `moment`");
    }

    this.slidePhaseStartDate(this.selection.phase, dateConverter.momentToInt(startDate));
  }

  setPhaseSidebarPhaseEndDate(endDate) {
    if (!moment.isMoment(endDate)) {
      throw new Error("`endDate` should be a `moment`");
    }

    this.slideMilestoneEndDate(
      this.selection.phase,
      _.last(this.selection.phase.milestones),
      dateConverter.momentToInt(endDate)
    );
  }

  setPhaseSidebarMilestoneEndDate(milestone, endDate) {
    milestone.phase.setMilestoneEndDate(
      milestone,
      dateConverter.momentToInt(endDate),
      {updateAllocations: this.linkDates}
    );
    this.setDirty(milestone);
    this._update();
  }

  setProjectSidebarProjectFee(fee) {
    //TODO-new_project_creator: handle non-integer.
    this.selection.project.setFee(parseInt(fee), true);
    this._setProjectSidebarPhaseList(this.selection.project);
    this.setDirty(this.selection.project);
    this._update();
  }

  setProjectSidebarProjectHours(hours) {
    this.selection.project.setTotalAllocatedHours(parseInt(hours));
    this.setDirty(this.selection.project);
    this._update();
  }

  setItemStaffMembers(staffMembers, item) {
    item.setStaffMembers(staffMembers);
    this.setDirty(item);
    this._update();
  }

  getAllocation() {
    return this.staffAllocation;
  }

  scrollLeft() {
    this._scroll(-1);
  }

  scrollRight() {
    this._scroll(+1);
  }

  scrollTo(x) {
    var diff = x - this.startDate;
    this.startDate += diff;
    this.endDate += diff;
    this._update();
  }

  _scroll(direction) {
    var newStartDate = dateConverter.momentToInt(dateConverter.intToMoment(this.startDate).add(direction, 'month'));
    var newEndDate = dateConverter.momentToInt(dateConverter.intToMoment(this.endDate).add(1, 'day')
                                                                                      .add(direction, 'month')
                                                                                      .subtract(1, 'day'));
    this.startDate = newStartDate;
    this.endDate = newEndDate;
    this._update();
  }

  saveAll() {
    let projects = this.projectState.getDirtyProjects();
    this.projectState.initiateSave();
    this.saveState = 'saving';
    this.emitChanged();
    apiRequest({
      path: `/organisation/current/project/bulk-save`,
      method: "post",
      data: { projects: projects.map(p => p.serialize()) },
      success: data =>actions.saveAllSuccess(projects, data),
    });
  }

  saveAllSuccess(projects, {projectIds, objects, phaseUuidToIdLookup}) {
    let self = this;

    this.projectState.saveComplete(projects);
    if (this.projectState.getDirtyProjects().length === 0) {
      this.saveState = 'saved';
      setTimeout(actions.saveAllSuccessTimeoutExpired, 2000);
    }
    else {
      this.saveState = null;
    }
    this.emitChanged();
  }

  saveAllSuccessTimeoutExpired() {
    this.saveState = null;
    this.emitChanged();
  }

  feedStaffMemberAddAllocation(staffMember, month) {
    this.modals.push({
      type: 'addAllocation',
      staffMember: staffMember,
      month: month
    });
    this.emitChanged();
  }

  feedStaffMemberAllocationFormSave(modal, phase) {
    phase.allocations.push(new RangeAllocation({
      startDate: modal.month,
      endDate: dateConverter.endOfMonthOffset(modal.month),
      staffMember: modal.staffMember,
      staffRole: modal.staffMember.role,
      hours: 0
    }));

    this.setDirty(phase);
    this.closeModal(modal);
    this._update();
  }

  feedStaffMemberAllocationFormCancel(modal, phase) {
    this.closeModal(modal);
    this.emitChanged();
  }

  feedNextMonth() {
    if (this.firstSelectedMonth != null) {
      this.firstSelectedMonth.add(1, 'month');
      this.lastSelectedMonth.add(1, 'month');
      this.endDate = Math.max(
        this.endDate,
        dateConverter.endOfMonthOffset(dateConverter.momentToInt(this.lastSelectedMonth))
      );
      this._update();
    }
  }

  feedPreviousMonth() {
    if (this.firstSelectedMonth != null) {
      this.firstSelectedMonth.subtract(1, 'month');
      this.lastSelectedMonth.subtract(1, 'month');
      this.startDate = Math.min(this.startDate, dateConverter.momentToInt(this.firstSelectedMonth));
      this._update();
    }
  }

  feedSetFirstMonth(date) {
    this.firstSelectedMonth = date;
    if (this.lastSelectedMonth.isBefore(this.firstSelectedMonth)) {
      this.lastSelectedMonth = this.firstSelectedMonth.clone();
    }
    this.emitChanged();
  }

  feedSetLastMonth(date) {
    this.lastSelectedMonth = date;
    if (this.firstSelectedMonth.isAfter(this.lastSelectedMonth)) {
      this.firstSelectedMonth = this.lastSelectedMonth.clone();
    }
    this.emitChanged();
  }

  graphSetMonth(month) {
    /**
     * `month`: int-date
     */
    this.selectMonth(dateConverter.intToMoment(month));
    this.emitChanged();
  }

  setLinkDates(value) {
    this.linkDates = value;
    this.emitChanged();
  }

  deleteProjectButtonClick() {
    this.openDeleteProjectConfirmPopup();
  }

  openDeleteProjectConfirmPopup() {
    this.deleteProjectState = 'confirm';
    this.emitChanged();
  }

  closeDeleteProjectConfirmPopup() {
    this.deleteProjectState = null;
    this.emitChanged();
  }

  deleteProject(project) {
    if (project.id != null) {
      organisationStore.deleteObject(project);
    }
    else {
      organisationStore._deleteObjectByUuid('Project', project.uuid);

      let modal = _.find(this.modals, m => m.type === 'editItem' && m.item === project);
      if (modal != null) {
        this.closeModal(modal);
      }

      this.projectState.removeProject(project);
      this.deselectAll({emitChanged: false});
      this._update();
    }
  }

  handleDeleteObjectSuccess(responseData) {
    let modal = _.find(this.modals, m => m.type === 'editItem' && m.itemType === 'project');
    if (modal != null) {
      let openProject = modal.item;
      if (organisationStore.hasDeletedObject(openProject)) {
        this.closeModal(modal);

        this.projectState.removeProject(openProject);
        this.deleteProjectState = null;
        this.deselectAll({emitChanged: false});
        this._update();
      }
    }
  }

  handleDeleteProjectFailure(project, error) {
    if (this.selection.matches({project: project})) {
      this.deleteProjectState = 'cantDelete';
      this.emitChanged();
    }
  }

  handleSaveStaffMemberSuccess(staffMemberData) {
    this._update();
  }

  projectSidebarEditProjectExpenses() {
    this.editProjectExpenses = this.selection.project.expenses;
    this.modals.push({
      type: 'editProjectExpenses'
    });
    this.emitChanged();
  }

  projectSidebarSetProjectExpenses(expenses) {
    this.editProjectExpenses = expenses;
    this.emitChanged();
  }

  projectSidebarSaveExpenses(modal) {
    this.selection.project.expenses = this.editProjectExpenses;
    this.setDirty(this.selection.project);
    this.closeModal(modal);
    this._update();
  }

  projectSidebarCalculateExpenses() {
    let project = this.selection.project;

    let totalPhaseBudget = 0;
    project.phases.forEach(function(phase) {
      phase.manualBudget = phase.staffExpenses || 0;
      totalPhaseBudget += phase.manualBudget;
    });
    project.manualExpensesBudget = project.getTotalNonStaffExpenses();
    project.manualBudget = totalPhaseBudget + project.manualExpensesBudget;

    this.setDirty(project);
    this.emitChanged();
  }

  projectSidebarSetProjectManualHours(hours) {
    let project = this.selection.project;
    project.setManualHoursBudget(hours);
    this.setDirty(project);
    this._update();
  }

  projectSidebarSetPhaseManualHours(phase, hours) {
    let project = this.selection.project;
    phase.manualHoursBudget = hours;
    project.manualHoursBudget = sum(project.phases.map(p => p.manualHoursBudget));
    this.setDirty(project);
    this._update();
  }

  projectSidebarCalculateHours() {
    let project = this.selection.project;

    let totalHours = 0;
    for (let phase of project.phases) {
      phase.updateHoursBudgetFromStaffAllocations();
      totalHours += phase.manualHoursBudget;
    }
    project.manualHoursBudget = totalHours;
    this.setDirty(project);
    this.emitChanged();
  }

  phaseSidebarCalculateExpenses() {
    let phase = this.selection.phase;
    phase.manualBudget = phase.staffExpenses || 0;
    this.setDirty(phase);
    this.emitChanged();
  }

  viewAllProjects() {
    this.deselectAll({emitChanged: false});
    this.emitChanged();
  }

  setProjectName(project, name) {
    //TODO-new_project_creator get rid of this
    project.name = name;
    this.emitChanged();
  }

  setPhaseName(phase, name) {
    //TODO-new_project_creator get rid of this
    phase.name = name;
    this.emitChanged();
  }

  editItem(item) {
    this.modals.push({
      type: 'editItem',
      itemType: (item.phase != null) ? 'phase' : 'project',
      item: (item.phase != null) ? item.phase : item.project
    });
    this.emitChanged();
  }

  editItemTasks(item) {
    if (item.phase == null) {
      this.projectTaskStore.loadProject(item.project);
    }
    else {
      this.phaseTaskStore.loadPhase(item.phase);
    }

    this.modals.push({
      type: 'editItemTasks',
      itemType: (item.phase != null) ? 'phase' : 'project',
      item: (item.phase != null) ? item.phase : item.project
    });
    this.emitChanged();
  }

  clickCopyProjectButton() {
    this.modals.push({
      type: 'copyProject',
      project: this.selection.project.templateCopy()
    });
    this.emitChanged();
  }

  editProjectModalSave(modal, project, projectData) {
    project.name = projectData.name;
    this.setDirty(project);
    this.closeModal(modal);
  }

  editPhaseModalSave(modal, phase, phaseData) {
    if (phase != null) {
      phase.name = phaseData.name;
      phase.jobCode = phaseData.jobCode;
    }
    else {
      phase = this.selection.project.appendNewPhase({
        name: phaseData.name,
        jobCode: phaseData.jobCode,
      });
    }
    this.setDirty(this.selection.project);
    this.closeModal(modal);
  }

  editPhaseModalDelete(modal, phase, phaseData) {
    this.setDirty(phase);
    phase.project.removePhase(phase);
    this.closeModal(modal, {emitChanged: false});
    this._update();
  }

  editProjectTasksModalSave(modal, project, newProject) {
    project.phases.forEach(function(phase, i) {
      phase.tasks = newProject.phases[i].tasks;
    });
    this.closeModal(modal);
    this.setDirty(project);
    this.emitChanged();
  }

  editProjectTasksModalCancel(modal) {
    this.closeModal(modal);
    this.emitChanged();
  }

  editPhaseTasksModalSave(modal, phase, newPhase) {
    phase.tasks = newPhase.tasks;
    this.closeModal(modal);
    this.setDirty(phase);
    this.emitChanged();
  }

  editPhaseTasksModalCancel(modal) {
    this.closeModal(modal);
    this.emitChanged();
  }

  copyProjectModalCancel(modal) {
    this.closeModal(modal);
    this.emitChanged();
  }

  copyProjectModalSubmit({modal, project, name, jobCode, startDate}) {
    project.name = name;
    project.jobCode = jobCode;
    project.moveBy(dateConverter.momentToInt(startDate) - project.startDate);

    project.uuid = generateUUID();
    organisationStore.setObjectByUuid('Project', project);

    this.setDirty(project);

    this.closeModal(modal);
    this.selection = new ItemSelection({project: project});
    this._update();
  }

  setProjectFilterText(text) {
    this.projectFilterText = text;
    this.emitChanged();
  }

  setGraphMode(graphMode, {update = true} = {}) {
    if (graphMode !== 'actuals' && graphMode !== 'projected' && graphMode !== 'staff') {
      throw new Error('invalid graphMode');
    }
    if (graphMode === 'projected' || graphMode === 'actuals') {
      this.cashFlowMode = graphMode;
    }
    this.graphMode = graphMode;
    graphScaleStore.setOverrideMaxY(null);
    if (update) {
      this._update();
    }
  }

  getFeedGroupedByStaffMember(firstSelectedMonth, lastSelectedMonth, staffMembers = null) {
    /**
     * `firstSelectedMonth`, `lastSelectedMonth`: date-int
     *
     * Returns:

        {
          allocationGroups: [
            {
              staffMember: <StaffMember>,
              projects: [
                {
                  project: <Project>,
                  phases: [
                    phase: <Phase>,
                    hours: number
                  ]
                }
              ]
            }
          ],
          staffMemberAvailableHours: {
            <staffMemberId:int>: number
          },
          staffMemberAllocatedHours: {
            <staffMemberId:int>: number
          },
          totalAvailableHours: number,
          totalAllocatedHours: number
        }
     */

    let startDate = firstSelectedMonth;
    let endDate = dateConverter.endOfMonthOffset(lastSelectedMonth);

    let staffMemberAllocatedHours = {};

    let staffMemberAvailableHours = {};
    let totalAvailableHours = 0;
    let totalAllocatedHours = 0;

    let staffMemberAllocations = {};
    let staffMemberLookup = {};

    if (staffMembers == null) {
      staffMembers = organisationStore.getVisibleStaff();
    }

    staffMembers.forEach(function(staffMember) {
      let totalMinutes = isNumber(staffMember.minutesPerWeek) ? staffMember.minutesPerWeek : 0;
      let availableHours = staffMember.getNumHoursAvailableInRange(startDate, endDate, organisationStore.getHolidaysXspans().data);
      staffMemberAvailableHours[staffMember.id] = availableHours;
      totalAvailableHours += availableHours;

      staffMemberAllocations[staffMember.id] = [];
      staffMemberAllocatedHours[staffMember.id] = 0;
      staffMemberLookup[staffMember.id] = staffMember;
    });

    for (let project of this.iterVisibleProjects()) {
      project.getVisiblePhases().forEach(function(phase) {
        phase.allocations.forEach(function(rangeAllocation) {
          let staffMember = rangeAllocation.staffMember;

          // We distinguish between there being no hours because the
          // rangeAllocation has zero hours (which could mean the user just
          // changed the value to zero and we still want to show it), and there
          // being no intersection at all, in which case we don't want to show
          // it.
          let hours = rangeAllocation.getTotalHoursInDateRange(startDate, endDate, {nullIfNoIntersection: true});
          if (hours != null) {
            totalAllocatedHours += hours;

            // In case we have an allocation with an archived staff member that
            // wouldn't have created an entry in the previous loop.
            if (staffMemberAllocatedHours[staffMember.id] == null) {
              staffMemberAllocatedHours[staffMember.id] = 0;
            }
            if (staffMemberAllocations[staffMember.id] == null) {
              staffMemberAllocations[staffMember.id] = [];
            }

            staffMemberLookup[staffMember.id] = staffMember;
            staffMemberAllocatedHours[staffMember.id] += hours;
            staffMemberAllocations[staffMember.id].push({
              project: project,
              phase: phase,
              hours: hours
            });
          }
        });
      });
    }

    let allocationGroups = [];

    _.each(staffMemberAllocations, function(staffMemberItems, staffMemberId) {
      let staffMember = staffMemberLookup[staffMemberId];
      let projectGroups = groupBy(staffMemberItems, a => a.project, project => project.id);

      allocationGroups.push({
        staffMember: staffMember,
        projects: projectGroups.map(function({grouper: project, items: projectItems}) {
          let phaseGroups = groupBy(projectItems, item => item.phase, phase => phase.id);
          return {
            project: project,
            phases: phaseGroups.map(function({grouper: phase, items: phaseItems}) {
              return {
                phase: phase,
                hours: sum(phaseItems.map(pi => pi.hours))
              };
            })
          };
        })
      });
    });

    // Ordering:
    // 1. Staff members with allocations (by first name then last name);
    // 2. The "Unallocated" pseudo-staff member;
    // 3. Staff members with no allocations (by first name then last name).
    allocationGroups.sort(compareMultiple(
      (a, b) => ((a.projects.length === 0) ? 1 : 0) - ((b.projects.length === 0) ? 1 : 0),
      (a, b) => ((a.staffMember.id === -1) ? 1 : 0) - ((b.staffMember.id === -1) ? 1 : 0),
      (a, b) => (a.staffMember.firstName || '').localeCompare(b.staffMember.firstName || ''),
      (a, b) => (a.staffMember.lastName || '').localeCompare(b.staffMember.lastName || '')
    ));

    return {
      allocationGroups: allocationGroups,
      staffMemberAvailableHours: staffMemberAvailableHours,
      staffMemberAllocatedHours: staffMemberAllocatedHours,
      totalAvailableHours: totalAvailableHours,
      totalAllocatedHours: totalAllocatedHours,
    };
  }
}


class GraphScaleStore extends StoreBase {
  /**
   * Use a different store to hold the `overrideMaxY` value so we don't have
   * to recalculate everything when the scale changes. Will be useful if we want
   * to animate the rescaling.
   *
   * `GraphScaleStore` is not responsible for doing any active listening; the
   * milestones store calls `touch()` on the `GraphScaleStore` to update a timer
   * each time the milestones store changes, and then when the timer expires the
   * `GraphScaleStore` will pull the current value from the milestones store.
   */
  constructor() {
    super();
    this.overrideMaxY = null;
    this.timer = null;
  }

  setOverrideMaxY(overrideMaxY) {
    this.overrideMaxY = overrideMaxY;
    this.emitChanged();
  }

  touch() {
    let self = this;

    if (this.overrideMaxY == null) {
      this.setOverrideMaxY(store.getOverrideMaxY());
    }
    else {
      if (this.timer != null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(function() {
        // Don't change the scale just because the max y changed by a small
        // amount.  Only change if if the new max y is bigger than the old max
        // y, or if the new max y is less than half the old max y (0.5 is
        // slightly arbitrary.. something like 1/3 or 2/3 could also work).

        let newOverrideMaxY = store.getOverrideMaxY();

        if (newOverrideMaxY > self.overrideMaxY || newOverrideMaxY < self.overrideMaxY * 0.5) {
          self.setOverrideMaxY();
        }

        self.timer = null;
      }, 2000);
    }
  }
}


export var store = new Store();
export var graphScaleStore = new GraphScaleStore();


var actionCollection = new ActionCollection("MILESTONES_", store);

actionCollection.addActions([
  {
    name: 'initializePage',
    callback: function(action) {
      return null;
    }
  },
  {
    name: 'refreshPage',
    callback: function(action) {
      store.refreshPage();
    }
  },
  {
    name: 'updateMilestoneAllocation',
    args: ['milestone, staffMember, newPercent'],
    callback: function(action) {
      store.updateMilestoneAllocation(action.milestone, action.staffMember, action.newPercent);
    }
  },
  {
    name: 'selectReport',
    args: ['reportUuid'],
    callback: function(action) {
      store.selectReport(action.reportUuid);
    }
  },
  {
    name: 'openCreateProjectForm',
    callback: function(action) {
      store.openCreateProjectForm();
    }
  },
  {
    name: 'closeCreateProjectForm',
    callback: function(action) {
      store.closeCreateProjectForm();
    }
  },
  {
    name: 'createProject',
    args: ['projectData'],
    callback: function(action) {
      store.createProject(action.projectData);
    }
  },
  {
    name: 'saveEditProject',
    args: ['project', 'projectData'],
    callback: 'default'
  },
  {
    name: 'alertBarSaveClick',
    callback: function(action) {
      store.saveAll();
    }
  },
  {
    name: 'saveAllSuccess',
    args: ['projects', 'data'],
    callback: function(action) {
      store.saveAllSuccess(action.projects, action.data);
    }
  },
  {
    name: 'saveAllSuccessTimeoutExpired',
    args: ['projects'],
    callback: function(action) {
      store.saveAllSuccessTimeoutExpired(action.projects);
    }
  },
  {
    name: 'deleteProjectButtonClick',
    callback: function(action) {
      store.deleteProjectButtonClick();
    }
  },
  {
    name: 'cancelDeleteProject',
    callback: function(action) {
      store.closeDeleteProjectConfirmPopup();
    }
  },
  {
    name: 'confirmDeleteProject',
    args: ['project'],
    callback: function(action) {
      store.deleteProject(action.project);
    }
  },
  {
    name: 'deleteProjectFailureOkButtonClick',
    callback: function(action) {
      store.closeDeleteProjectConfirmPopup();
    }
  },
  {
    name: 'slideProjectStartDate',
    args: ['project', 'startDate'],
    callback: 'default'
  },
  {
    name: 'slideProjectEndDate',
    args: ['project', 'endDate'],
    callback: 'default'
  },
  {
    name: 'slideProjectBy',
    args: ['project', 'diff'],
    callback: 'default'
  },
  {
    name: 'slidePhaseBy',
    args: ['phase', 'diff'],
    callback: 'default'
  },
  {
    name: 'slideStaffAllocationBy',
    args: ['phase', 'staffMember', 'diff'],
    callback: 'default'
  },
  {
    name: 'sliderMouseUp',
    args: [],
    callback: 'default'
  },
  {
    name: 'deselectAll',
    callback: 'default'
  },
  {
    name: 'selectItem',
    args: ['selection'],
    callback: 'default'
  },
  {
    name: 'selectSliderRangeAllocation',
    args: ['phase', 'staffMember', 'connectingLineItemIndex'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectStartDate',
    args: ['startDate'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectEndDate',
    args: ['endDate'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseStartDate',
    args: ['startDate'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseEndDate',
    args: ['endDate'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarMilestoneEndDate',
    args: ['milestone', 'endDate'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectFee',
    args: ['fee'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectHours',
    args: ['hours'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarPhaseHours',
    args: ['phase', 'hours'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarPhaseStaffExpenses',
    args: ['phase', 'staffExpenses'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarPhaseStaffChargeOut',
    args: ['phase', 'staffChargeOut'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarPhaseFee',
    args: ['phase', 'fee'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectStaffExpenses',
    args: ['expenses'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarProjectChargeOut',
    args: ['chargeOut'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarStaffMemberHours',
    args: ['staffMember', 'hours'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarStaffMemberExpenses',
    args: ['staffMember', 'expenses'],
    callback: 'default'
  },
  {
    name: 'setProjectSidebarStaffMemberChargeOut',
    args: ['staffMember', 'chargeOut'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseHours',
    args: ['hours'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseFee',
    args: ['fee'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseChargeOut',
    args: ['chargeOut'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarPhaseStaffExpenses',
    args: ['staffExpenses'],
    callback: 'default'
  },
  {
    name: 'setRangeAllocationSidebarHours',
    args: [
      'hours',
      'rangeAllocation' // optional
    ],
    callback: 'default',
  },
  {
    name: 'setRangeAllocationSidebarExpenses',
    args: ['expenses'],
    callback: 'default'
  },
  {
    name: 'setRangeAllocationSidebarChargeOut',
    args: ['chargeOut'],
    callback: 'default'
  },
  {
    name: 'setRangeAllocationSidebarStartDate',
    args: [
      'phase',
      'staffMember',
      'rangeAllocation',  // nullable
      'endDate'
    ],
    callback: 'default'
  },
  {
    name: 'setRangeAllocationSidebarEndDate',
    args: [
      'phase',
      'staffMember',
      'rangeAllocation',  // nullable
      'endDate'
    ],
    callback: 'default'
  },
  {
    name: 'projectSidebarGroupByButtonClick',
    args: ['groupBy'],
    callback: 'default'
  },
  {
    name: 'projectSidebarSetProjectManualBudget',
    args: ['budget'],
    callback: 'default'
  },
  {
    name: 'projectSidebarSetPhaseManualBudget',
    args: ['phase', 'budget'],
    callback: 'default'
  },
  {
    name: 'setProjectStaffMemberAllocationSliderValue',
    args: ['project', 'staffMember', 'hours'],
    callback: 'default'
  },
  {
    name: 'addPhaseClick',
    callback: 'default'
  },
  {
    // Slider from the sidebar
    name: 'setPhaseStaffMemberAllocationSliderValue',
    args: ['phase', 'staffMember', 'hours'],
    callback: 'default'
  },
  {
    // Slider from the sidebar
    name: 'setPhaseSidebarStaffMemberHours',
    args: ['staffMember', 'hours'],
    callback: 'default'
  },
  {
    // Slider in main pane
    name: 'slideStaffAllocationSliderStartDate',
    args: ['phase', 'staffMember', 'startDate'],
    callback: 'default'
  },
  {
    // Slider in main pane
    name: 'slideStaffAllocationSliderEndDate',
    args: ['phase', 'staffMember', 'endDate'],
    callback: 'default'
  },
  {
    name: 'phaseSidebarCalculateExpenses',
    args: [],
    callback: 'default'
  },
  {
    name: 'phaseSidebarSetPhaseManualBudget',
    args: ['budget'],
    callback: 'default'
  },
  {
    name: 'projectSidebarCalculateHours',
    args: [],
    callback: 'default'
  },
  {
    name: 'projectSidebarSetProjectManualHours',
    args: ['hours'],
    callback: 'default'
  },
  {
    name: 'projectSidebarSetPhaseManualHours',
    args: ['phase', 'hours'],
    callback: 'default'
  },
  {
    name: 'phaseSidebarCalculateHours',
    args: [],
    callback: 'default'
  },
  {
    name: 'phaseSidebarSetPhaseManualHoursBudget',
    args: ['hours'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarStaffMemberExpenses',
    args: ['staffMember', 'expenses'],
    callback: 'default'
  },
  {
    name: 'setPhaseSidebarStaffMemberChargeOut',
    args: ['staffMember', 'chargeOut'],
    callback: 'default'
  },
  {
    name: 'phaseSidebarSetStaffMemberBudgetedHours',
    args: ['staffMember', 'hours'],
    callback: 'default'
  },
  {
    name: 'openAddStaffMemberToItemPopup',
    args: ['item'],
    callback: 'default'
  },
  {
    name: 'staffAllocationSidebarAddAllocation',
    args: [],
    callback: 'default'
  },
  {
    name: 'staffAllocationSidebarRemoveAllocation',
    args: ['rangeAllocation'],
    callback: 'default'
  },
  {
    name: 'submitEditItemStaffMembers',
    args: ['modal', 'staffMembers', 'item'],
    callback: function(action) {
      store.setItemStaffMembers(action.staffMembers, action.item);
      store.closeModal(action.modal);
    }
  },
  {
    name: 'setMilestonePercent',
    args: ['milestone', 'percent'],
    callback: 'default'
  },
  {
    name: 'setMilestoneFee',
    args: ['milestone', 'fee'],
    callback: 'default'
  },
  {
    name: 'setFeedStaffMemberHours',
    args: ['project', 'phase', 'staffMember', 'startDate', 'endDate', 'hours'],
    callback: 'default'
  },
  {
    name: 'setFeedStaffMemberPercent',
    args: ['project', 'phase', 'staffMember', 'startDate', 'endDate', 'percent'],
    callback: 'default'
  },
  {
    name: 'addMilestoneToPhase',
    args: ['phase'],
    callback: 'default'
  },
  {
    name: 'removeMilestoneFromPhase',
    args: ['phase', 'milestone'],
    callback: 'default'
  },
  {
    name: 'slidePhaseStartDate',
    args: ['phase', 'startDate'],
    callback: 'default'
  },
  {
    name: 'slideMilestoneEndDate',
    args: ['phase', 'milestone', 'endDate'],
    callback: 'default'
  },
  {
    name: 'updateOverrideMaxY',
    args: ['overrideMaxY'],
    callback: 'default'
  },
  {
    name: 'scrollLeft',
    callback: 'default'
  },
  {
    name: 'scrollRight',
    callback: 'default'
  },
  {
    name: 'scrollTo',
    args: ['x'],
    callback: 'default'
  },
  {
    name: 'setGraphMode',
    args: ['graphMode'],
    callback: 'default'
  },
  {
    name: 'projectSidebarEditProjectExpenses',
    callback: 'default'
  },
  {
    name: 'projectSidebarSetProjectExpenses',
    args: ['expenses'],
    callback: 'default'
  },
  {
    name: 'projectSidebarSaveExpenses',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'projectSidebarCalculateExpenses',
    args: [],
    callback: 'default'
  },
  {
    name: 'viewAllProjects',
    callback: 'default'
  },
  {
    name: 'setProjectName',
    args: ['project', 'name'],
    callback: 'default'
  },
  {
    name: 'setPhaseName',
    args: ['phase', 'name'],
    callback: 'default'
  },
  {
    name: 'closeModal',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'editItem',
    args: ['item'],
    callback: 'default'
  },
  {
    name: 'editItemTasks',
    args: ['item'],
    callback: 'default'
  },
  {
    name: 'clickCopyProjectButton',
    args: [],
    callback: 'default'
  },
  {
    name: 'editProjectModalSave',
    args: ['modal', 'project', 'projectData'],
    callback: 'default'
  },
  {
    name: 'editPhaseModalSave',
    args: ['modal', 'phase', 'phaseData'],
    callback: 'default'
  },
  {
    name: 'editPhaseModalDelete',
    args: ['modal', 'phase'],
    callback: 'default'
  },
  {
    name: 'editProjectTasksModalSave',
    args: ['modal', 'project', 'newProject'],
    callback: 'default'
  },
  {
    name: 'editProjectTasksModalCancel',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'editPhaseTasksModalSave',
    args: ['modal', 'phase', 'newPhase'],
    callback: 'default'
  },
  {
    name: 'editPhaseTasksModalCancel',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'copyProjectModalSubmit',
    args: ['data'],
    callback: 'default'
  },
  {
    name: 'copyProjectModalCancel',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'setProjectFilterText',
    args: ['text'],
    callback: 'default'
  },
  {
    name: 'feedStaffMemberAddAllocation',
    args: ['staffMember', 'month'],
    callback: 'default'
  },
  {
    name: 'feedStaffMemberAllocationFormSave',
    args: ['modal', 'phase'],
    callback: 'default'
  },
  {
    name: 'feedStaffMemberAllocationFormCancel',
    args: ['modal'],
    callback: 'default'
  },
  {
    name: 'feedNextMonth',
    args: [],
    callback: 'default'
  },
  {
    name: 'feedPreviousMonth',
    args: [],
    callback: 'default'
  },
  {
    name: 'feedSetFirstMonth',
    args: ['date'],
    callback: 'default'
  },
  {
    name: 'feedSetLastMonth',
    args: ['date'],
    callback: 'default'
  },
  {
    name: 'graphSetMonth',
    args: ['month'], // int-date
    callback: 'default'
  },
  {
    name: 'setLinkDates',
    args: ['value'],
    callback: 'default'
  }
]);


store.dispatchToken = actionCollection.register(dispatcher, function(action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS': case 'REGISTER_SUCCESS':
      dispatcher.waitFor([organisationStore.dispatchToken]);
      store.initializePage();
      break;

    case 'ORGANISATION_DELETE_OBJECT_SUCCESS':  // From organisation
      dispatcher.waitFor([organisationStore.dispatchToken]);
      store.handleDeleteObjectSuccess(action.responseData);
      break;
    case 'ORGANISATION_DELETE_OBJECT_FAILURE':  // From organisation
      if (action.object.apiTypeName != null && action.object.apiTypeName() === 'project') {
        store.handleDeleteProjectFailure(action.object, action.error);
      }
      break;
  };
});


export let actions = actionCollection.actionsDict;
