import moment from 'moment';
import _ from 'underscore';
import { Checkbox } from './widgets/generic';
import PropTypes from "prop-types";
import React from "react";
import CreateReactClass from "create-react-class";
var ReactDOM = require("react-dom");
var RSVP = require("rsvp");
var DateTimePicker = require('react-widgets').DateTimePicker;


export var MyTable = CreateReactClass({
  propTypes: {
    items: PropTypes.array.isRequired,
    shouldItemBeDisplayed: PropTypes.func,
    hasSaveButton: PropTypes.bool,
    hasAddEntryButton: PropTypes.bool,
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    columns: PropTypes.array.isRequired,
    hasHeader: PropTypes.bool,

    // If true, if the items list is empty, `blankText` will be displayed rather than a table.
    // If false, an empty table will be displayed. Useful to set this to false if you want
    // to have a footer (via `renderFooter`) regardless.
    hasBlankText: PropTypes.bool,
    blankText: PropTypes.string,
    maxHeight: PropTypes.number,
    validate: PropTypes.func,
    tableProps: PropTypes.object,
    trProps: PropTypes.func,
    getRowKey: PropTypes.func,
    renderFooter: PropTypes.func
  },

  getDefaultProps: function() {
    return {
      hasSaveButton: true,
      shouldItemBeDisplayed: function() { return true; },
      hasAddEntryButton: true,
      onChange: function() { },
      hasBlankText: true,
      blankText: "",
      hasHeader: true,
      maxHeight: null,
      tableProps: {},
      trProps: function(ob) { return {}; },
      validate: function(items) { return true; },
      getRowKey: function(object, index) { return index; },
      renderFooter: function() { return null; },
      onSave: function() {
        var deferred = RSVP.defer();
        deferred.resolve();
        return deferred.promise;
      }
    };
  },

  getInitialState: function() {
    return {
      items: _.clone(this.props.items),
      isFixedHeaderTableSetup: false,
      submitAttempted: false,
      submitFailedValidation: false
    };
  },

  componentWillReceiveProps: function(newProps) {
    this.setState({items: _.clone(newProps.items)});
  },

  makeTextInput: function(column, object, isLastFirst, props) {
    // Hook so a `makeCustomComponent` column can use a properly-bound text box
    // and decorate it with their own stuff.
    var self = this;

    props = props || {};

    var { style, afterChange, ...other } = props;

    if (style == null) {
      style = {width: '100%'};
    }

    return <input
      type="text"
      style={style}
      ref={isLastFirst ? "lastFirstInput" : null}
      value={object[column.prop]}
      onChange={function(event) {
        self.handleChange(object, column.prop, event.target.value);
        if (afterChange != null) {
          afterChange(object);
        }
      }}
      {...other}
    />;
  },

  render: function() {
    var self = this;

    var {
      onEdit: _onEdit,
      addButtonText: _addButtonText,
      onChange: _onChange,
      ...other
    } = this.props;

    var style = (this.props.maxHeight != null)
      ? {maxHeight: this.props.maxHeight, overflowY: 'scroll'}
      : null;

    return (
      <div {...other}>
       {this.state.items.length > 0 || !self.props.hasBlankText ?
          <div className="wrapper" ref="wrapper" style={style}>
            <table style={{width: '100%'}} {...self.props.tableProps}>
              {self.props.hasHeader
                ? <thead>
                    <tr>
                      {self.props.columns.map(function(column, i) {
                        return <th key={self.props.getRowKey(column, i)} {...column.tdProps}>{column.heading}</th>;
                      })}
                    </tr>
                  </thead>
                : null}
              <tbody>
                {self.state.items.map(function(p, rowIndex) {
                  if (!self.props.shouldItemBeDisplayed(p)) {
                    return null;
                  }
                  return (
                    <tr key={rowIndex} {...self.props.trProps(p)}>
                      {self.props.columns.map(function(column, columnIndex) {
                        // Is this the first input of the last row? That's the input that we should focus
                        // when the user hits the add button.
                        var isLastFirst = (columnIndex === 0 && rowIndex === self.state.items.length - 1);

                        return (
                          <td key={columnIndex} {...column.tdProps}>
                            {(column.makeCustomComponent != null)
                             ? column.makeCustomComponent(p, column, self, isLastFirst)
                             : self.makeTextInput(column, p, isLastFirst)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {self.props.renderFooter(self.state.items, self)}
            </table>
          </div>
        :
          <p>{self.props.blankText}</p>
        }
        <div style={{marginTop: '15px'}}>
          {self.props.hasAddEntryButton ?
            <button
                className="btn btn-inverse mytable__add-button"
                onClick={this.newRow}>
              + {this.props.addButtonText}
            </button>
          : null}

          {self.props.hasSaveButton ?
            <span style={{marginLeft: '20px'}}>
              {(self.state.dirty)
               ? <button className="btn btn-success" onClick={self.handleSaveButtonClick}>
                   <i className="fa fa-floppy-o"></i>
                   {self.props.saveButtonText}
                 </button>
               : null
              }
              {(self.state.saving) ? <strong>Saving...</strong> : null}
              {(self.state.justSaved) ? <strong>Saved</strong> : null}
              {(self.state.submitFailedValidation && !self.state.valid) ? <em style={{marginLeft: 10}}>
                We can't save yet because we couldn't understand some of your entries. Please try again.
              </em> : null}
            </span>
          : null}
        </div>
      </div>
    );
  },

  newRow: function() {
    var self = this;
    let newItem;
    if (this.props.createNewItem != null) {
      newItem = this.props.createNewItem();
    }
    else {
      newItem = {id: null};
      this.props.columns.forEach(function(column) {
        newItem[column.prop] = '';
      });
    }
    this.state.items.push(newItem);
    this.setState({items: this.state.items}, function() {
      // If we have a scrolling tbody, scroll to the bottom so we can see the new item.
      var el = ReactDOM.findDOMNode(self.refs.wrapper);
      var scrollHeight = Math.max(el.scrollHeight, el.clientHeight);
      el.scrollTop = scrollHeight - el.clientHeight;

      // Also focus the first input of the last item.
      if (self.refs.lastFirstInput != null) {
        // But don't crash if the caller used custom components and didn't set
        // a `lastFirstInput` ref.
        self.refs.lastFirstInput.focus();
      }
    });
    this.props.onChange(this.state.items);
  },

  deleteItem: function(item) {
    this.state.items.splice(this.state.items.indexOf(item), 1);
    this.setState({items: this.state.itemss});
    this.props.onChange(this.state.items);
  },

  updateState: function() {
    this.setState({
      items: this.state.items,
      dirty: true,
      justSaved: false
    });
    this.props.onChange(this.state.items);
  },

  handleChange: function(item, prop, value) {
    item[prop] = value;
    this.updateState();
  },

  handleDelete: function(item) {
    this.props.onDelete(item);
  },

  validate: function() {
    var isValid = this.props.validate(this.state.items);
    this.setState({
      items: this.state.items,
      valid: isValid
    });
    return isValid;
  },

  handleSaveButtonClick: function() {
    var self = this;
    var deferred = RSVP.defer();
    this.setState({
      dirty: true,
      submitAttempted: true
    });
    if (this.validate(this.state.items)) {
      this.setState({
        saving: true,
        submitFailedValidation: false
      });
      this.props.onSave(this.state.items, this).then(function() {
        self.setState({
          dirty: false,
          saving: false,
          justSaved: true,
          submitAttempted: false
        });
        deferred.resolve();
      }, function() {
        // Set state appropriately for save failed.
        deferred.reject();
      });
    }
    else {
      this.setState({
        items: this.state.items,
        submitFailedValidation: true
      });
      deferred.reject();
    }
    return deferred.promise;
  },

  edit: function(item) {
    this.props.onEdit(item);
  }
});


export function makeDatePickerCell(ob, column, myTable, isLastFirst) {
  var prop = column.prop;
  return <div>
    <DateTimePicker
      time={false}
      format={"dd/MM/yyyy"}
      value={ob[prop] != null ? ob[prop].toDate() : null}
      onChange={function(newDate) {
        myTable.handleChange(ob, prop, newDate != null ? moment(newDate) : null);
      }}
    />
  </div>;
};


export function makeCheckboxCell(ob, column, myTable, isLastFirst) {
  var prop = column.prop;
  return <div>
    <Checkbox
      value={ob[prop]}
      onChange={function(value) { myTable.handleChange(ob, prop, value); }} />
  </div>;
}


export function makeStaticTextCell(ob, column, myTable, isLastFirst, formatterFunc = null) {
  let val = ob[column.prop];
  let out;
  if (val == null) {
    out = "";
  }
  else {
    if (formatterFunc != null) {
      out = formatterFunc(val);
    }
    else {
      out = val.toString();
    }
  }
  return <div>{out}</div>;
}


export function makeStaticTextCellWithFormatter(formatterFunc) {
  return function(ob, column, myTable, isLastFirst) {
    return makeStaticTextCell(ob, column, myTable, isLastFirst, formatterFunc);
  };
};

