import React from 'react';
import CreateReactClass from 'create-react-class';
import { sum } from '../utils.js';
import { actions } from './flux.js';
import { CurrencyIcon } from '../widgets.js';
import { permissions, requiresPermission, OperationLevel } from '../models/permissions.js';
import { LargeSidebarInput } from './SidebarUtils.js';
import PropTypes from "prop-types";


export var ProjectExpensesPanel = requiresPermission(
  ({project}) => permissions.projectFinancialsBudgets(project),
  {},
  CreateReactClass({
    propTypes: {
      project: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Project Expenses</h3>

          <LargeSidebarInput
            value={sum(this.props.project.expenses.map(e => e.totalCost()))}
            isEditable={false}
          />

          {this.props.project.expenses.length > 0 ?
            <div>
              <table className="input-table">
                <tbody>
                  {this.props.project.expenses.map(function(expense) {
                    return <tr>
                      <td className="text" style={{borderRight: 'solid 1px #ccc'}}>
                        {expense.name}
                      </td>
                      <td className="text" style={{borderRight: 'solid 1px #ccc', width: '28%'}}>
                        {expense.date != null
                        ? expense.date.format("DD/MM/YYYY")
                        : <span><i>N/A</i></span>}
                      </td>
                      <td className="dollar">
                        <CurrencyIcon />
                        {expense.totalCost()}
                      </td>
                    </tr>;
                  })}
                </tbody>
              </table>
            </div>
          : null}

          {this.props.permissionLevel === OperationLevel.edit ?
            <button className="button-100" onClick={actions.projectSidebarEditProjectExpenses}>
              Edit
            </button>
          : null}
        </div>
      );
    }
  })
);

