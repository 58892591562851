import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions, store, ItemSelection } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { SliderRow } from './SliderRow.js';
import { groupBy, applyTextFilter } from '../utils.js';
import { ItemExpandedState } from '../widgets.js';
import PropTypes from "prop-types";


export var PhaseSliderSet = CreateReactClass({
  propTypes: {
    onPhaseClick: PropTypes.func,
    padding: PropTypes.object,
    isEditable: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      onPhaseClick: function() { },
      padding: {
        left: 0,
        right: 0
      },
      isEditable: true
    };
  },

  mixins: [
    makeMultipleStoreMixin([store], function() {
      let allProjects = Array.from(store.iterVisibleProjects());
      let projects = applyTextFilter(allProjects, p => p.name, store.projectFilterText);
      return {
        projects: projects,
        deleteProjectState: store.deleteProjectState,
        selection: store.selection,
        startDate: store.startDate,
        endDate: store.endDate,
        isReversed: store.isReversed
      };
    })
  ],

  getInitialState: function() {
    return {
      hoveredItem: null,
    };
  },

  render: function() {
    var self = this;

    const sliderMin = this.state.startDate;
    const sliderMax = this.state.endDate;

    const projectList = (this.state.selection.project != null) ?  [this.state.selection.project] : this.state.projects;

    return <div className="scheduler__project-list">
      {projectList.map(function(project, projectIndex) {
        var phases = project.getVisiblePhases();
        var phasesWithMilestones = phases.filter(p => p.getVisibleMilestones() != null && p.getVisibleMilestones().length > 0);

        let projectSliderValues = null;
        if (project.startDate != null && project.endDate != null) {
          projectSliderValues = [
            project.startDate,
            Math.max(...phasesWithMilestones.map(p => p.getVisibleMilestones()[p.getVisibleMilestones().length - 1].endDate))
          ];
        }

        let isProjectSelected = self.state.selection.matches({project: project});
        let projectSelection = new ItemSelection({project: project});

        return <div key={projectIndex}>
          <div className="scheduler__project-list__project">
            <SliderRow
              item={projectSelection}
              isReversed={self.state.isReversed}
              isHighlighted={project === self.state.hoveredItem || isProjectSelected}
              label={
                <h3 style={{margin: 0, padding: 0}}>
                  <ItemExpandedState isExpanded={isProjectSelected} />
                  <span className="scheduler__project-list__project-name">
                    {project.name}
                  </span>
                </h3>
              }
              sliderValues={projectSliderValues}
              sliderMin={sliderMin}
              sliderMax={sliderMax}
              onSliderMove={function(currentValues, movedItemValue, movedItemIndex, diff, newX) {
                if (movedItemIndex === 0) {
                  actions.slideProjectStartDate(project, newX);
                }
                else {
                  actions.slideProjectEndDate(project, newX);
                }
              }}
              onSliderBarMove={function(itemValue, itemIndex, diff, newX) {
                actions.slideProjectBy(project, diff);
              }}
              onSliderMouseUp={function() {
                actions.sliderMouseUp();
              }}
              onClick={self.handleSliderRowClick}
              onMouseOver={self.handleSliderRowMouseOver}
              isEditable={self.props.isEditable}
            />
          </div>

          {isProjectSelected || _.some(project.phases, p => self.state.selection.matches({phase: p})) ?
            phases.map(function(phase, phaseIndex) {
              let sliderValues = null;
              if (phase.startDate != null && phase.endDate != null) {
                sliderValues = [phase.startDate, ...phase.getVisibleMilestones()];
              }

              let phaseSelection = new ItemSelection({project: project, phase: phase});

              let allAllocations = phase.allocations.map(function(rangeAllocation) {
                return new ItemSelection({
                  phase: phase,
                  rangeAllocation: rangeAllocation,
                  staffMember: rangeAllocation.staffMember
                });
              });

              let staffAllocations = groupBy(
                allAllocations,
                allocation => allocation.staffMember,
                staffMember => staffMember.id
              );

              staffAllocations.forEach(function({grouper: staffMember, items: allocations}) {
                allocations.sort((a, b) => a.rangeAllocation.startDate - b.rangeAllocation.startDate);
                allocations.forEach(function(allocation, i) {
                  if (i > 0 && allocation.rangeAllocation.startDate <= allocations[i - 1].rangeAllocation.endDate) {
                    throw new Error("Assertion failed: overlap");
                  }
                });
              });

              /**
                staffAllocations = [
                  {
                    grouper: <StaffMember>,
                    items: [ItemSelection(<ProjectPhase>, <Project>, <RangeAllocation>, <StaffMember>]
                  }
                ]
              */

              return (
                <div className="scheduler__project-list__phase" key={phaseIndex}>
                  <SliderRow
                    item={phaseSelection}
                    isReversed={self.state.isReversed}
                    label={<div className="scheduler__project-list__phase-name">{phase.name}</div>}
                    isHighlighted={phase === self.state.hoveredItem || self.state.selection.matches({phase: phase})}
                    sliderValues={sliderValues}
                    sliderMin={sliderMin}
                    sliderMax={sliderMax}
                    getItemValue={item => item.endDate != null ? item.endDate : item}
                    onSliderMove={function(currentValues, movedItemValue, movedItemIndex, diff, newX) {
                      if (movedItemIndex === 0) {
                        actions.slidePhaseStartDate(phase, newX);
                      }
                      else {
                        var milestone = movedItemValue;
                        actions.slideMilestoneEndDate(phase, milestone, newX);
                      }
                    }}
                    onSliderBarMove={function(itemValue, itemIndex, diff, newX) {
                      actions.slidePhaseBy(phase, diff);
                    }}
                    onSliderMouseUp={function() {
                      actions.sliderMouseUp();
                    }}
                    onClick={self.handleSliderRowClick}
                    onMouseOver={self.handleSliderRowMouseOver}
                    isEditable={self.props.isEditable}
                  />

                  {self.state.selection.matches({phase: phase}) ?
                    staffAllocations.map(function({grouper: staffMember, items: allocations}, staffIndex) {
                      let isHighlighted = false, connectingLineItemIndex = null;
                      [self.state.hoveredItem, self.state.selection].forEach(function(s) {
                        if (s != null && s.matches({phase: phase, staffMember: staffMember})) {
                          isHighlighted = true;
                          if (s.rangeAllocation != null) {
                            let i = _.findIndex(allocations, a => s.matchesRangeAllocation(a.rangeAllocation));
                            if (i !== -1) {
                              connectingLineItemIndex = i;
                            }
                          }
                        }
                      });

                      let values = [
                        ...allocations.map(item => item.rangeAllocation.startDate),
                        _.last(allocations).rangeAllocation.endDate
                      ];

                      return (
                        <div className="scheduler__project-list__staff-member" key={staffIndex}>
                          <SliderRow
                            item={new ItemSelection({
                              phase: phase,
                              staffMember: staffMember,
                            })}
                            isReversed={self.state.isReversed}
                            label={
                              <div
                                  style={{paddingLeft: "1.5em", marginBottom: "-0.6em"}}
                                  className="scheduler__project-list__staff-member-name">
                                {staffMember.getFullName()}
                              </div>
                            }
                            sliderValues={values}
                            isHighlighted={isHighlighted}
                            sliderMin={sliderMin}
                            sliderMax={sliderMax}
                            onClick={self.handleSliderRowClick}
                            onConnectingLineItemClick={function(connectingLineItemIndex) {
                              actions.selectSliderRangeAllocation(phase, staffMember, connectingLineItemIndex);
                            }}
                            onSliderMove={function(currentValues, movedItemValue, movedItemIndex, diff) {
                              // Currently only supporting moving the first and last bubbles.
                              if (movedItemIndex === 0) {
                                actions.slideStaffAllocationSliderStartDate(
                                  phase,
                                  staffMember,
                                  values[0] + diff
                                );
                              }
                              else if (movedItemIndex === values.length - 1) {
                                actions.slideStaffAllocationSliderEndDate(
                                  phase,
                                  staffMember,
                                  _.last(values) + diff
                                );
                              }
                            }}
                            onSliderBarMove={function(itemValue, itemIndex, diff, newX) {
                              actions.slideStaffAllocationBy(phase, staffMember, diff);
                            }}
                            onSliderMouseUp={function() {
                              actions.sliderMouseUp();
                            }}
                            selectedConnectingLineItemIndex={connectingLineItemIndex}
                            onMouseOver={self.handleSliderRowMouseOver}
                            hasSettingsButton={false}
                            style={{
                              padding: '0 30px 0',
                              fontSize: '0.85em'
                            }}
                            isEditable={self.props.isEditable}
                          />
                        </div>
                      );
                    })
                  : null}
                </div>
              );
            })
          : null}

          {self.state.selection != null && self.state.selection.matches({project: project}) ?
            <div className="widget-footer" style={{padding: '1em'}}>
              <button
                  className="btn btn-default"
                  onClick={self.handleAddPhaseClick}>
                + Add Phase
              </button>
            </div>
          : null}
        </div>;
      })}
    </div>;
  },

  handleAddPhaseClick() {
    actions.addPhaseClick();
  },

  handleSaveProjectButtonClick(project) {
    actions.saveProject(project);
  },

  handleDeleteProjectButtonClick() {
    actions.deleteProjectButtonClick();
  },

  handleDeleteProjectFailureOkButtonClick() {
    actions.deleteProjectFailureOkButtonClick();
  },

  handleSliderRowMouseOver(item) {
    this.setState({hoveredItem: item});
  },

  handleSliderRowClick(item) {
    actions.selectItem(item);
  },

  handleRangeAllocationConnectingLineClick(item) {
    actions.selectItem(item);
  }
});
