import React from 'react';
import CreateReactClass from 'create-react-class';
import { store } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { ProfitPanel } from './SidebarUtils.js';
import { ProjectFeePanel } from './FeePanel.js';
import { ProjectBudgetedExpensesPanel } from './BudgetedExpensesPanel.js';
import { StaffExpensesPanel } from './StaffExpensesPanel.js';
import { ProjectExpensesPanel } from './ProjectExpensesPanel.js';
import { ProjectBudgetedHoursPanel } from './BudgetedHoursPanel.js';
import { ProjectDatesPanel } from './DatesPanel.js';
import { userStore } from '../user.js';
import PropTypes from "prop-types";


export var ProjectSidebar = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([store, userStore], function() {
      return {
        phaseList: store.projectSidebarPhaseList,
        user: userStore.user
      };
    })
  ],

  render: function() {
    let project = this.props.project;

    return <div className="project-sidebar">
      <ProjectDatesPanel
        project={project}
        user={this.state.user}
      />

    {project.getStartDate() != null && project.getEndDate() != null ?
        <div>
          <ProfitPanel
            item={project}
            user={this.state.user}
          />
          <ProjectFeePanel
            project={project}
            user={this.state.user}
            phaseList={this.state.phaseList}
          />
          <ProjectBudgetedHoursPanel
            project={project}
            user={this.state.user}
          />
          <ProjectBudgetedExpensesPanel
            user={this.state.user}
            project={project}
          />
          <StaffExpensesPanel
            user={this.state.user}
            item={project}
            hasGroupBy={true}
          />
          <ProjectExpensesPanel
            user={this.state.user}
            project={project}
          />
        </div>
      : null}
    </div>;
  }
});
