import _ from "underscore";
import moment from "moment";
import { Column } from "../table.js";
import { Project } from "../models.js";
import { ProjectStatus } from "../models/project.js";
import { sum } from "../utils.js";
import { StoreBase, dispatcher, ActionCollection } from "../coincraftFlux.js";
import { router } from "../router.js";
import { organisationStore } from "../organisation.js";
import { AllTime } from "../reports/DateRanges.js";
import { Report, parseFilter } from "../reports/Report.js";
import {
	ReportStore,
	applyTimesheetCacheRequirement,
	isDataChangedAction,
} from "../reports/flux.js";
import { userStore } from "../user/flux.js";
import { dateConverter } from "../models.js";
import { TimesheetDataCache } from "../reports/TimesheetDataCache.js";
import { Enum } from "../enum.js";
import Immutable from "immutable";
import { FinancialsVisibility } from "../models/permissions.js";

export const columnRequirements = {
	name: null,
	jobCode: null,
	startDate: null,
	endDate: null,
	fee: FinancialsVisibility.allExceptPay,
	expenseBudget: FinancialsVisibility.onlyExpenses,
	costCentre: null,
	contact: null,
	status: null,
	remainingFee: FinancialsVisibility.allExceptPay,
	remainingExpenseProjectBudget: FinancialsVisibility.onlyExpenses,
	staffMembers: null,
	progress: null,
	revenue: FinancialsVisibility.allExceptPay,
	revenueWithVariations: FinancialsVisibility.allExceptPay,
	revenueWithVariationsReimbursements: FinancialsVisibility.allExceptPay,
	recordedHours: null,
	hoursBudgeted: null,
	hoursAllocated: null,
	projectedHours: null,
	expenses: FinancialsVisibility.onlyExpenses,
	expensesVariation: FinancialsVisibility.onlyExpenses,
	expensesVariationProject: FinancialsVisibility.onlyExpenses,
	expensesProject: FinancialsVisibility.onlyExpenses,
	projectExpenses: FinancialsVisibility.onlyExpenses,
	projectedExpense: FinancialsVisibility.onlyExpenses,
	labourExpense: FinancialsVisibility.all,
	projectedLabourExpense: FinancialsVisibility.all,
	chargeOut: FinancialsVisibility.allExceptPay,
	chargeOutVariation: FinancialsVisibility.allExceptPay,
	projectedChargeOut: FinancialsVisibility.allExceptPay,
	actualVsBudgetedHours: null,
	actualVsBudgetedExpenses: FinancialsVisibility.onlyExpenses,
	actualProjectVsBudgetedExpenses: FinancialsVisibility.onlyExpenses,
	actualVariationVsBudgetedExpenses: FinancialsVisibility.onlyExpenses,
	actualVariationProjectVsBudgetedExpenses: FinancialsVisibility.onlyExpenses,
	revenueVsFee: FinancialsVisibility.allExceptPay,
	chargeOutVsFee: FinancialsVisibility.allExceptPay,
	chargeOutVariationVsFee: FinancialsVisibility.allExceptPay,
	costVsFee: FinancialsVisibility.allExceptPay,
	costProjectVsFee: FinancialsVisibility.allExceptPay,
	payVsFee: FinancialsVisibility.all,
	percentOfTotalHours: null,
	percentOfTotalRevenue: FinancialsVisibility.allExceptPay,
	percentOfTotalExpenses: FinancialsVisibility.onlyExpenses,
	percentOfTotalExpensesLabour: FinancialsVisibility.all,
	profit: FinancialsVisibility.allExceptPay,
	profitCost: FinancialsVisibility.allExceptPay,
	profitCostVariationProject: FinancialsVisibility.allExceptPay,
	profitMarginVariationProject: FinancialsVisibility.allExceptPay,
	revenueVsExpenseVariationProject: FinancialsVisibility.allExceptPay,
	expenseVsRevenueVariationProject: FinancialsVisibility.allExceptPay,
	profitVsExpenseVariationProject: FinancialsVisibility.allExceptPay,
	profitVsRevenueVariationProject: FinancialsVisibility.allExceptPay,
	markupVariationProject: FinancialsVisibility.allExceptPay,
	projectedProfit: FinancialsVisibility.allExceptPay,
	profitLabour: FinancialsVisibility.all,
	profitFee: FinancialsVisibility.allExceptPay,
	profitFeeVariation: FinancialsVisibility.allExceptPay,
	profitMargin: FinancialsVisibility.allExceptPay,
	profitMarginCost: FinancialsVisibility.allExceptPay,
	projectedProfitMargin: FinancialsVisibility.allExceptPay,
	profitMarginLabour: FinancialsVisibility.all,
	profitMarginFee: FinancialsVisibility.allExceptPay,
	profitMarginFeeVariation: FinancialsVisibility.allExceptPay,
	revenuePerHour: FinancialsVisibility.allExceptPay,
	revenueVariationPerHour: FinancialsVisibility.allExceptPay,
	revenueVariationReimbursementPerHour: FinancialsVisibility.allExceptPay,
	completionDate: null,
	currentPhase: null,
	latestRevenueDate: FinancialsVisibility.allExceptPay,
};

const TimesheetField = Enum([
	"numHours",
	"numHoursToDate",
	"pay",
	"payToDate",
	"cost",
	"costToDate",
	"chargeOut",
	"chargeOutToDate",
	"numHoursVariation",
	"numHoursToDateVariation",
	"payVariation",
	"payToDateVariation",
	"costVariation",
	"costToDateVariation",
	"chargeOutVariation",
	"chargeOutToDateVariation",
]);

export const ProjectColumns = class {
	constructor(dataSource) {
		/**
     * `dataSource`: {
          dateRange: ?,
          timesheetDataCache: TimesheetDataCache
        }
     */

		let self = this;
		this.dataSource = dataSource;

		const now = moment();

		function expensesFieldFunc(timesheetField) {
			return function (item) {
				return (
					item.getExpensesSpentInDateRange(
						self.dataSource.dateRange.getDatesObject(now)
					) + self.getTimesheetDataInDateRange(item, timesheetField)
				);
			};
		}

		function percentOfExpensesColumnData(timesheetField) {
			return {
				sharedData: function () {
					return {
						totalExpenses:
							self.timesheetData.projectMap
								.valueSeq()
								.map((v) => v[timesheetField])
								.reduce((a, b) => a + b, 0) +
							sum(
								organisationStore.projects.map((p) =>
									p.getExpensesSpentInDateRange(
										self.dataSource.dateRange.getDatesObject(
											now
										)
									)
								)
							),
					};
				},
				data: (item, { totalExpenses }) =>
					self.getPercentOfTotalExpenses(
						item,
						now,
						timesheetField,
						totalExpenses
					),
			};
		}

		this.columns = [
			// Fields that aren't date-specific
			new Column({
				identifier: "project",
				header: "Project",
				width: "25%",
				data: (item) => item,
				type: "project",
			}),
			new Column({
				identifier: "name",
				header: "Name",
				width: "25%",
				data: (item) => item.getTitle(),
				type: "string",
				isMandatory: true,
			}),
			new Column({
				identifier: "jobCode",
				header: "Job code",
				width: "10%",
				data: (item) => item.jobCode,
				type: "string",
			}),
			new Column({
				identifier: "startDate",
				header: "Start date",
				width: "10%",
				data: (item) => item.startDate,
				type: "intDate",
			}),
			new Column({
				identifier: "endDate",
				header: "End date",
				width: "10%",
				data: (item) => item.endDate,
				type: "intDate",
			}),
			new Column({
				identifier: "fee",
				headerText: "Fee",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) => item.getFee()),
				type: "number",
			}),
			new Column({
				identifier: "expenseBudget",
				headerText: "Expense Budget",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) => item.getBudget()),
				type: "number",
			}),
			new Column({
				identifier: "costCentre",
				header: "Cost centre",
				width: "10%",
				data: (item) => item.costCentre,
				type: "costCentre",
			}),
			new Column({
				identifier: "contact",
				header: "Client",
				width: "10%",
				data: (item) => item.contact,
				type: "contact",
			}),
			new Column({
				identifier: "invoiceContact",
				header: "Primary Contact",
				width: "10%",
				data: (item) => item.invoiceContact,
				type: "contact",
			}),
			new Column({
				identifier: "owner",
				header: "Project Owner",
				width: "15%",
				data: (item) => item.owner,
				type: "staffMember",
			}),
			new Column({
				identifier: "status",
				header: "Status",
				width: "10%",
				data: (item) => item.status,
				type: "projectStatus",
			}),

			new Column({
				identifier: "remainingFee",
				headerText: "Remaining fee",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) => item.getFee()) -
					self.sumValueFromPhases(item, (item) =>
						item.getRevenueInDateRange(AllTime, "agreedFee")
					),
				type: "number",
			}),

			new Column({
				identifier: "remainingExpenseProjectBudget",
				headerText: "Remaining Expense Budget",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) => item.getBudget()) -
					(self.sumValueFromPhases(item, (item) =>
						item.getExpensesSpentInDateRange(
							self.dataSource.dateRange.getDatesObject(now)
						)
					) +
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							)
						)),
				type: "number",
			}),

			new Column({
				identifier: "latestEvent",
				header: "Latest Event",
				width: "10%",
				data: (item) => item.latestEventDate,
				type: "intDate",
			}),

			new Column({
				identifier: "latestNote",
				header: "Latest Note",
				width: "35%",
				data: (item) => {
					let format =
						userStore.user.country === "us"
							? "MM/DD/YYYY"
							: "DD/MM/YYYY";
					return `${
						item.latestNote?.date
							? item.latestNote?.date.format(format) + " - "
							: ""
					}${item.latestNote?.description || ""}`;
				},
				type: "string",
			}),
			new Column({
				identifier: "currentPhases",
				header: "Current Phases",
				width: "10%",
				data: (item) =>
					item.phases
						?.filter((ph) => ph.isCurrent)
						.map((d) => d.name)
						.join(", ") || "",
				type: "string",
			}),
			new Column({
				identifier: "activePhases",
				header: "Active Phases",
				width: "10%",
				data: (item) =>
					item.phases
						?.filter((ph) => ph.status === "active")
						.map((d) => d.name)
						.join(", ") || "",
				type: "string",
			}),
			new Column({
				identifier: "prospectivePhases",
				header: "Prospective Phases",
				width: "10%",
				data: (item) =>
					item.phases
						?.filter((ph) => ph.status === "prospective")
						.map((d) => d.name)
						.join(", ") || "",
				type: "string",
			}),

			// Fields that are date-specific
			new Column({
				identifier: "staffMembers",
				header: "Staff members assigned",
				width: "10%",
				data: function (item) {
					// We don't want `unallocated` in this list.
					const dr = self.dataSource.dateRange
						.getDates(now)
						.map((m) =>
							m != null ? dateConverter.momentToInt(m) : null
						);
					return item
						.getAllocatedStaffMembersInDateRange(dr)
						.filter((sm) => sm && sm.id != null && sm.id !== -1);
				},
				type: "staffMembers",
			}),
			new Column({
				identifier: "progress",
				header: "Progress",
				width: "10%",
				data: (item) =>
					item.getProgressAtEndOfDateRange(
						self.dataSource.dateRange.getDatesObject(now)
					),
				type: "number",
			}),
			new Column({
				identifier: "revenue",
				header: "Revenue",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						item.getRevenueInDateRange(
							self.dataSource.dateRange.getDatesObject(now),
							"agreedFee"
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "revenueWithVariations",
				header: "Revenue (Variations)",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						item.getRevenueInDateRange(
							self.dataSource.dateRange.getDatesObject(now),
							"agreedFee",
							"variation"
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "revenueWithVariationsReimbursements",
				header: "Revenue (Variations + Reimbursements)",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						item.getRevenueInDateRange(
							self.dataSource.dateRange.getDatesObject(now)
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "recordedHours",
				header: "Recorded Hours",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					return self.sumValueFromPhases(item, (item) =>
						item == null || item.id === -1 || item.id == null
							? 0
							: self.getTimesheetDataInDateRange(
									item,
									TimesheetField.numHours
							  )
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "recordedHoursVariation",
				header: "Recorded Hours (Variation)",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					return self.sumValueFromPhases(item, (item) =>
						item == null || item.id === -1 || item.id == null
							? 0
							: self.getTimesheetDataInDateRange(
									item,
									TimesheetField.numHours
							  ) +
							  self.getTimesheetDataInDateRange(
									item,
									TimesheetField.numHoursVariation
							  )
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "hoursBudgeted",
				header: "Hours budgeted",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) => item.manualHoursBudget
					),
				type: "number",
			}),
			new Column({
				identifier: "hoursAllocated",
				header: "Hours allocated",
				width: "10%",
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						item.getAllocatedHoursInDateRange(
							self.dataSource.dateRange.getDatesObject(now)
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "expenses",
				header: "Expenses (cost)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.cost
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "projectExpenses",
				header: "Project Expenses",
				width: "10%",
				data: (item) => {
					return self.sumValueFromPhases(item, (item) =>
						item.getExpensesSpentInDateRange(
							self.dataSource.dateRange.getDatesObject(now)
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "billableProjectExpenses",
				header: "Billable Project Expenses",
				width: "10%",
				data: (item) => {
					return self.sumValueFromPhases(item, (item) =>
						item.sumExpensesInDateRange(
							self.dataSource.dateRange.getDatesObject(now),
							(e) => e.isInvoiceable
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "allProjectExpenses",
				header: "All Project Expenses",
				width: "10%",
				data: (item) => {
					return self.sumValueFromPhases(item, (item) =>
						item.sumExpensesInDateRange(
							self.dataSource.dateRange.getDatesObject(now)
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "expensesVariation",
				header: "Expenses (cost) + Variation Cost",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							) +
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.costVariation
							)
					),
				type: "number",
			}),
			new Column({
				identifier: "expensesVariationProject",
				header: "Expenses (cost) + Variation Cost + Project Expenses",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) =>
							item.getExpensesSpentInDateRange(
								this.dataSource.dateRange.getDatesObject(now)
							) +
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							) +
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.costVariation
							)
					),
				type: "number",
			}),
			new Column({
				identifier: "expensesProject",
				header: "Expenses (cost) + Project Expenses",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						expensesFieldFunc(TimesheetField.cost)
					),
				type: "number",
			}),
			new Column({
				identifier: "labourExpense",
				header: "Labour expense",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.pay
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "chargeOut",
				header: "Charge Out",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					return self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.chargeOut
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "chargeOutVariation",
				header: "Charge Out (Variations)",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					return self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOut
							) +
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutVariation
							)
					);
				},
				type: "number",
			}),

			new Column({
				// Semi date-specific: hours until end of date range vs. total project hours.
				identifier: "actualVsBudgetedHours",
				header: "Actual / Budgeted Hours",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.numHoursToDate
							)
						),
						denominator: self.sumValueFromPhases(
							item,
							(item) => item.manualHoursBudget
						),
					};
				},
				type: "rational",
			}),

			new Column({
				// Semi date-specific: hours until end of date range vs. total project hours.
				identifier: "actualVsBudgetedHoursVariation",
				header: "Actual (Variation) / Budgeted Hours",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.numHoursToDate
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.numHoursToDateVariation
								)
						),
						denominator: self.sumValueFromPhases(
							item,
							(item) => item.manualHoursBudget
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: expenses until end of date range vs. total project budget.
				identifier: "actualProjectVsBudgetedExpenses",
				header: "Actual (Cost + Project) / Budgeted Expenses",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getBudget()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: expenses until end of date range vs. total project budget.
				identifier: "actualVariationProjectVsBudgetedExpenses",
				header: "Actual (Cost + Project + Variation) / Budgeted Expenses",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getBudget()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: expenses until end of date range vs. total project budget.
				identifier: "actualVariationVsBudgetedExpenses",
				header: "Actual (Cost + Variation) / Budgeted Expenses",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getBudget()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: expenses until end of date range vs. total project budget.
				identifier: "actualVsBudgetedExpenses",
				header: "Actual (Cost) / Budgeted Expenses",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getBudget()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "revenueVsFee",
				headerText: "Revenue / Fee",
				width: "20%",
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee"
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "revenueVsChargeOut",
				headerText: "Revenue / Charge Out",
				width: "20%",
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee"
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutToDate
							)
						),
					};
				},
				type: "rational",
			}),

			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "percentRevenueVsFee",
				headerText: "Percent Revenue / Fee",
				width: "10%",
				data: function (item) {
					return (
						(self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee"
							)
						) /
							self.sumValueFromPhases(item, (item) =>
								item.getFee()
							)) *
						100
					);
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: charge out until end of date range vs. total project budget.
				identifier: "costProjectVsFee",
				headerText: "Expenses (Cost + Project) / Fee",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costToDate
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "costProjectVsChargeOut",
				headerText: "Expenses (Cost + Project) / Charge Out",
				width: "20%",
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costToDate
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutToDate
							)
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: charge out until end of date range vs. total project budget.
				identifier: "costVsFee",
				headerText: "Expense (cost) / Fee",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.costToDate
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "costVsChargeOut",
				headerText: "Expenses (Cost) / Charge Out",
				width: "20%",
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.costToDate
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutToDate
							)
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: charge out until end of date range vs. total project budget.
				identifier: "payVsFee",
				headerText: "Expense (labour) / Fee",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.payToDate
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: revenue until end of date range vs. total project budget.
				identifier: "payVsChargeOut",
				headerText: "Expenses (Labour) / Charge Out",
				width: "20%",
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.payToDate
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutToDate
							)
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: charge out until end of date range vs. total project budget.
				identifier: "chargeOutVsFee",
				headerText: "Charge Out / Fee",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOutToDate
							)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),
			new Column({
				// Semi date-specific: charge out until end of date range vs. total project budget.
				identifier: "chargeOutVariationVsFee",
				headerText: "Charge Out (Variations) / Fee",
				width: "20%",
				requires: ["timesheetCache"],
				data: function (item) {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.chargeOutToDate
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.chargeOutToDateVariation
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getFee()
						),
					};
				},
				type: "rational",
			}),

			new Column({
				identifier: "percentOfTotalHours",
				header: "Percentage of total hours",
				width: "10%",
				requires: ["timesheetCache"],
				sharedData: function () {
					return {
						totalHours: self.timesheetData.projectMap
							.valueSeq()
							.map((v) => v.numHours)
							.reduce((a, b) => a + b, 0),
					};
				},
				data: function (item, { totalHours }) {
					// Note that it's acceptable for this to return `NaN` or `Infinity`.
					return (
						(self.getTimesheetDataInDateRange(
							item,
							TimesheetField.numHours
						) /
							totalHours) *
						100
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "percentOfTotalRevenue",
				header: "Percentage of total revenue",
				width: "10%",
				requires: ["timesheetCache"],
				sharedData: function () {
					return {
						totalRevenue: sum(
							organisationStore.projects.map((p) =>
								p.getRevenueInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									)
								)
							)
						),
					};
				},
				data: function (item, { totalRevenue }) {
					// Note that it's acceptable for this to return `NaN` or `Infinity`.
					return (
						(item.getRevenueInDateRange(self.dataSource.dateRange) /
							totalRevenue) *
						100
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "percentOfTotalExpenses",
				header: "Percentage of total expenses (cost)",
				width: "10%",
				requires: ["timesheetCache"],
				...percentOfExpensesColumnData(TimesheetField.cost),
				type: "number",
			}),
			new Column({
				identifier: "percentOfTotalExpensesLabour",
				header: "Percentage of total expenses (labour)",
				width: "10%",
				requires: ["timesheetCache"],
				...percentOfExpensesColumnData(TimesheetField.pay),
				type: "number",
			}),

			new Column({
				identifier: "profit",
				header: "Profit (cost + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						self.getProfit(item, now, TimesheetField.cost, true)
					),
				type: "number",
			}),
			new Column({
				identifier: "profitCost",
				header: "Profit (cost)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						self.getProfit(item, now, TimesheetField.cost, false)
					),
				type: "number",
			}),
			new Column({
				identifier: "profitCostVariationProject",
				header: "Profit (cost + variation + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) =>
							self.sumValueFromPhases(item, (item) =>
								this.getProfit(
									item,
									now,
									TimesheetField.cost,
									true
								)
							) -
							self.sumValueFromPhases(item, (item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
							)
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMarginVariationProject",
				header: "Profit Margin (cost + variation + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) =>
							(self.sumValueFromPhases(item, (item) =>
								this.getProfit(
									item,
									now,
									TimesheetField.cost,
									true
								)
							) -
								self.sumValueFromPhases(item, (item) =>
									self.getTimesheetDataInDateRange(
										item,
										TimesheetField.costVariation
									)
								)) /
							self.sumValueFromPhases(item, (item) =>
								item.getRevenueInDateRange(
									self.dataSource.dateRange.getDatesObject(
										now
									),
									"agreedFee",
									"variation"
								)
							)
					) * 100,
				type: "number",
			}),
			new Column({
				identifier: "markupVariationProject",
				header: "Markup (cost + variation + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(
						item,
						(item) =>
							(self.sumValueFromPhases(item, (item) =>
								this.getProfit(
									item,
									now,
									TimesheetField.cost,
									true
								)
							) -
								self.sumValueFromPhases(item, (item) =>
									self.getTimesheetDataInDateRange(
										item,
										TimesheetField.costVariation
									)
								)) /
							self.sumValueFromPhases(
								item,
								(item) =>
									item.getExpensesSpentInDateRange(
										this.dataSource.dateRange.getDatesObject(
											now
										)
									) +
									self.getTimesheetDataInDateRange(
										item,
										TimesheetField.cost
									) +
									self.getTimesheetDataInDateRange(
										item,
										TimesheetField.costVariation
									)
							)
					) * 100,
				type: "number",
			}),
			new Column({
				identifier: "expenseVsRevenueVariationProject",
				header: "Expense / Revenue (cost + variation + project expenses)",
				width: "20%",
				requires: ["timesheetCache"],
				data: (item) => {
					return {
						numerator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									this.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
						),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee",
								"variation"
							)
						),
					};
				},
				type: "number",
			}),
			new Column({
				identifier: "revenueVsExpenseVariationProject",
				header: "Revenue / Expense (cost + variation + project expenses)",
				width: "20%",
				requires: ["timesheetCache"],
				data: (item) => {
					return {
						numerator: self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee",
								"variation"
							)
						),
						denominator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									this.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
						),
					};
				},
				type: "number",
			}),
			new Column({
				identifier: "profitVsRevenueVariationProject",
				header: "Profit / Revenue (cost + variation + project expenses)",
				width: "20%",
				requires: ["timesheetCache"],
				data: (item) => {
					return {
						numerator:
							self.sumValueFromPhases(item, (item) =>
								this.getProfit(
									item,
									now,
									TimesheetField.cost,
									true
								)
							) -
							self.sumValueFromPhases(item, (item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
							),
						denominator: self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee",
								"variation"
							)
						),
					};
				},
				type: "number",
			}),
			new Column({
				identifier: "profitVsExpenseVariationProject",
				header: "Profit / Expense (cost + variation + project expenses)",
				width: "20%",
				requires: ["timesheetCache"],
				data: (item) => {
					return {
						numerator:
							self.sumValueFromPhases(item, (item) =>
								this.getProfit(
									item,
									now,
									TimesheetField.cost,
									true
								)
							) -
							self.sumValueFromPhases(item, (item) =>
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
							),
						denominator: self.sumValueFromPhases(
							item,
							(item) =>
								item.getExpensesSpentInDateRange(
									this.dataSource.dateRange.getDatesObject(
										now
									)
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.cost
								) +
								self.getTimesheetDataInDateRange(
									item,
									TimesheetField.costVariation
								)
						),
					};
				},
				type: "number",
			}),
			new Column({
				identifier: "profitLabour",
				header: "Profit (labour)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						self.getProfit(item, now, TimesheetField.pay, false)
					),
				type: "number",
			}),

			new Column({
				identifier: "profitFee",
				header: "Profit (fee)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						this.getProfit(
							item,
							now,
							TimesheetField.cost,
							true,
							"fee"
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "profitFeeVariation",
				header: "Profit (fee, variation & project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					self.sumValueFromPhases(item, (item) =>
						this.getProfit(
							item,
							now,
							TimesheetField.cost,
							true,
							"fee"
						)
					) -
					self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.costVariation
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMargin",
				header: "Profit margin (cost + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedProfitMargin(
						item,
						now,
						TimesheetField.cost,
						true
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMarginCost",
				header: "Profit margin (cost)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedProfitMargin(
						item,
						now,
						TimesheetField.cost,
						false
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMarginLabour",
				header: "Profit margin (labour)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedProfitMargin(
						item,
						now,
						TimesheetField.pay,
						false
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMarginFee",
				header: "Profit margin (fee)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedProfitMargin(
						item,
						now,
						TimesheetField.cost,
						true,
						"fee"
					),
				type: "number",
			}),
			new Column({
				identifier: "profitMarginFeeVariation",
				header: "Profit margin (fee, variation & project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					(self.sumValueFromPhases(item, (item) =>
						this.getProfit(
							item,
							now,
							TimesheetField.cost,
							true,
							"fee"
						)
					) -
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.costVariation
							)
						)) /
					this.sumValueFromPhases(item, (item) =>
						this.getRevenue(
							item,
							now,
							TimesheetField.cost,
							true,
							"fee"
						)
					),
				type: "number",
			}),
			new Column({
				identifier: "markup",
				header: "Markup (cost + project expenses)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedMarkup(item, now, TimesheetField.cost, true),
				type: "number",
			}),
			new Column({
				identifier: "markupCost",
				header: "Markup (cost)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedMarkup(item, now, TimesheetField.cost, false),
				type: "number",
			}),
			new Column({
				identifier: "markupLabour",
				header: "Markup (labour)",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedMarkup(item, now, TimesheetField.pay, false),
				type: "number",
			}),
			new Column({
				identifier: "netMultiplier",
				header: "Net Multiplier",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) =>
					this.getSummedNetMultiplier(
						item,
						now,
						TimesheetField.pay,
						false
					),
				type: "number",
			}),
			new Column({
				identifier: "revenuePerHour",
				header: "Revenue per hour",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					// Note that it's acceptable for this to return `NaN` or `Infinity`.
					const hoursSpent = self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.numHours
						)
					);
					return (
						self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee"
							)
						) / hoursSpent
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "revenueVariationPerHour",
				header: "Revenue per hour (variations)",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					// Note that it's acceptable for this to return `NaN` or `Infinity`.
					const hoursSpent = self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.numHours
						)
					);
					return (
						self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now),
								"agreedFee",
								"variation"
							)
						) / hoursSpent
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "revenueVariationReimbursementPerHour",
				header: "Revenue per hour (variations + reimbursements)",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					// Note that it's acceptable for this to return `NaN` or `Infinity`.
					const hoursSpent = self.sumValueFromPhases(item, (item) =>
						self.getTimesheetDataInDateRange(
							item,
							TimesheetField.numHours
						)
					);
					return (
						self.sumValueFromPhases(item, (item) =>
							item.getRevenueInDateRange(
								self.dataSource.dateRange.getDatesObject(now)
							)
						) / hoursSpent
					);
				},
				type: "number",
			}),

			new Column({
				identifier: "completionDate",
				header: "Completion date",
				width: "10%",
				data: (item) => item.getCompletionDate(),
				type: "moment",
			}),
			new Column({
				identifier: "latestInvoicedPhases",
				header: "Latest Invoiced Phases",
				width: "15%",
				data: (item) => item.getLatestInvoicedPhases?.() || [],
				type: "string",
			}),
			new Column({
				identifier: "latestRevenueDate",
				header: "Latest Revenue Date",
				width: "10%",
				data: (item) => item.getMostRecentRevenueDate(),
				type: "moment",
			}),
			new Column({
				identifier: "projectedHours",
				header: "Projected Hours",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedHoursCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"numHoursCurrent"
							)
					);
					const recordedHoursFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"numHoursFuture"
							)
					);
					const allocatedHoursCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedHoursInDateRange([
										moment().startOf("month"),
										moment().endOf("month"),
								  ])
					);
					const recordedHours =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.numHours
							)
						) -
						recordedHoursCurrent -
						recordedHoursFuture;
					let allocatedHours = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedHours =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedHoursInDateRange([
											moment().startOf("month"),
											self.dataSource.dateRange.getDatesObject(
												now
											).end,
									  ])
							) - allocatedHoursCurrent;
					}
					return (
						recordedHours +
						allocatedHours +
						Math.max(recordedHoursCurrent, allocatedHoursCurrent)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "futureHours",
				header: "Future Hours",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedHoursCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"numHoursCurrent"
							)
					);
					const allocatedHoursCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedHoursInDateRange([
										moment().startOf("month"),
										moment().endOf("month"),
								  ])
					);
					let allocatedHours = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedHours =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedHoursInDateRange([
											moment().startOf("month"),
											self.dataSource.dateRange.getDatesObject(
												now
											).end,
									  ])
							) - allocatedHoursCurrent;
					}
					return (
						allocatedHours +
						Math.max(
							0,
							allocatedHoursCurrent - recordedHoursCurrent
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "futureRevenue",
				header: "Future Revenue",
				width: "10%",
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					return self.sumValueFromPhases(item, (item) => {
						return sum(
							item
								.getMilestonesInRange(
									dateConverter.momentToInt(now),
									self.dataSource.dateRange.getDatesObject(
										now
									).end
										? dateConverter.momentToInt(
												self.dataSource.dateRange.getDatesObject(
													now
												).end
										  )
										: null
								)
								.map((m) => m.revenue)
						);
					});
				},
				type: "number",
			}),
			new Column({
				identifier: "projectedExpense",
				header: "Projected Expense (Cost)",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"costCurrent"
							)
					);
					const recordedCostFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(item, "costFuture")
					);
					const allocatedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedRateInDateRange(
										[
											moment().startOf("month"),
											moment().endOf("month"),
										],
										"costRate"
								  )
					);
					const recordedCost =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							)
						) -
						recordedCostCurrent -
						recordedCostFuture;
					let allocatedCost = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedCost =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedRateInDateRange(
											[
												moment().startOf("month"),
												self.dataSource.dateRange.getDatesObject(
													now
												).end,
											],
											"costRate"
									  )
							) - allocatedCostCurrent;
					}
					return (
						recordedCost +
						allocatedCost +
						Math.max(recordedCostCurrent, allocatedCostCurrent) +
						self.sumValueFromPhases(item, (item) =>
							item.getExpensesSpentInDateRange(
								self.dataSource.dateRange.getDatesObject(now)
							)
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "projectedLabourExpense",
				header: "Projected Labour Expense",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedPayCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(item, "payCurrent")
					);
					const recordedPayFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(item, "payFuture")
					);
					const allocatedPayCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedRateInDateRange(
										[
											moment().startOf("month"),
											moment().endOf("month"),
										],
										"payRate"
								  )
					);
					const recordedPay =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.pay
							)
						) -
						recordedPayCurrent -
						recordedPayFuture;
					let allocatedPay = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedPay =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedRateInDateRange(
											[
												moment().startOf("month"),
												self.dataSource.dateRange.getDatesObject(
													now
												).end,
											],
											"payRate"
									  )
							) - allocatedPayCurrent;
					}
					return (
						recordedPay +
						allocatedPay +
						Math.max(recordedPayCurrent, allocatedPayCurrent)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "projectedChargeOut",
				header: "Projected Charge Out",
				width: "10%",
				requires: ["timesheetCache"],
				data: function (item) {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedChargeOutCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"chargeOutCurrent"
							)
					);
					const recordedChargeOutFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"chargeOutFuture"
							)
					);
					const allocatedChargeOutCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedRateInDateRange(
										[
											moment().startOf("month"),
											moment().endOf("month"),
										],
										"chargeOutRate"
								  )
					);
					const recordedChargeOut =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.chargeOut
							)
						) -
						recordedChargeOutCurrent -
						recordedChargeOutFuture;
					let allocatedChargeOut = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedChargeOut =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedRateInDateRange(
											[
												moment().startOf("month"),
												self.dataSource.dateRange.getDatesObject(
													now
												).end,
											],
											"chargeOutRate"
									  )
							) - allocatedChargeOutCurrent;
					}
					return (
						recordedChargeOut +
						allocatedChargeOut +
						Math.max(
							recordedChargeOutCurrent,
							allocatedChargeOutCurrent
						)
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "projectedProfit",
				header: "Projected Profit",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) => {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"costCurrent"
							)
					);
					const recordedCostFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(item, "costFuture")
					);
					const allocatedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedRateInDateRange(
										[
											moment().startOf("month"),
											moment().endOf("month"),
										],
										"costRate"
								  )
					);
					const recordedCost =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							)
						) -
						recordedCostCurrent -
						recordedCostFuture;
					let allocatedCost = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedCost =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedRateInDateRange(
											[
												moment().startOf("month"),
												self.dataSource.dateRange.getDatesObject(
													now
												).end,
											],
											"costRate"
									  )
							) - allocatedCostCurrent;
					}
					const totalCost =
						recordedCost +
						allocatedCost +
						Math.max(recordedCostCurrent, allocatedCostCurrent) +
						self.sumValueFromPhases(item, (item) =>
							item.getExpensesSpentInDateRange(
								self.dataSource.dateRange.getDatesObject(now)
							)
						);
					return (
						self.sumValueFromPhases(item, (item) => item.getFee()) -
						totalCost
					);
				},
				type: "number",
			}),
			new Column({
				identifier: "projectedProfitMargin",
				header: "Projected Profit Margin",
				width: "10%",
				requires: ["timesheetCache"],
				data: (item) => {
					//HACK
					if (item == null || item.id === -1 || item.id == null) {
						return 0;
					}
					const recordedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(
								item,
								"costCurrent"
							)
					);
					const recordedCostFuture = self.sumValueFromPhases(
						item,
						(item) =>
							self.getTimesheetDataInDateRange(item, "costFuture")
					);
					const allocatedCostCurrent = self.sumValueFromPhases(
						item,
						(item) =>
							item.status === "archived"
								? 0
								: item.getAllocatedRateInDateRange(
										[
											moment().startOf("month"),
											moment().endOf("month"),
										],
										"costRate"
								  )
					);
					const recordedCost =
						self.sumValueFromPhases(item, (item) =>
							self.getTimesheetDataInDateRange(
								item,
								TimesheetField.cost
							)
						) -
						recordedCostCurrent -
						recordedCostFuture;
					let allocatedCost = 0;
					const endDate =
						self.dataSource.dateRange.getDatesObject(now).end;
					if (!endDate || endDate.isAfter(moment())) {
						allocatedCost =
							self.sumValueFromPhases(item, (item) =>
								item.status === "archived"
									? 0
									: item.getAllocatedRateInDateRange(
											[
												moment().startOf("month"),
												self.dataSource.dateRange.getDatesObject(
													now
												).end,
											],
											"costRate"
									  )
							) - allocatedCostCurrent;
					}
					const totalCost =
						recordedCost +
						allocatedCost +
						Math.max(recordedCostCurrent, allocatedCostCurrent) +
						self.sumValueFromPhases(item, (item) =>
							item.getExpensesSpentInDateRange(
								self.dataSource.dateRange.getDatesObject(now)
							)
						);
					return ((item.getFee() - totalCost) / item.getFee()) * 100;
				},
				type: "number",
			}),
		];
		applyTimesheetCacheRequirement(this.columns, this.dataSource);

		this.columnLookup = {};
		for (let c of this.columns) {
			this.columnLookup[c.identifier] = c;
		}
	}

	getColumnById(id) {
		return this.columnLookup[id];
	}

	get timesheetData() {
		return {
			projectMap: Immutable.Map(),
			projectPhaseMap: Immutable.Map(),
			staffMemberMap: Immutable.Map(),
			staffMemberBillableMap: Immutable.Map(),
			...this.dataSource.timesheetDataCache.getDateRange(
				this.dataSource.dateRange
			),
		};
	}

	getTimesheetDataInDateRange(item, field) {
		// `field`:TimesheetField
		let d;
		if (item.constructor === Project) {
			d = this.timesheetData.projectMap?.get(item.id);
		} else {
			d = this.timesheetData.projectPhaseMap.get(
				this.dataSource.timesheetDataCache.getProjectPhaseMapKey(
					item.project.id,
					item.id
				)
			);
		}
		return d != null ? d[field] : 0;
	}

	getProfit(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		return (
			this.getRevenue(item, now, field, includeExpenses, reveneuField) -
			this.getExpenses(item, now, field, includeExpenses, reveneuField)
		);
	}

	getRevenue(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		return reveneuField === "revenue"
			? item.getRevenueInDateRange(
					this.dataSource.dateRange.getDatesObject(now),
					...(!includeExpenses ? ["agreedFee", "variation"] : [])
			  )
			: item.getFee();
	}

	getExpenses(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		return (
			(includeExpenses
				? item.getExpensesSpentInDateRange(
						this.dataSource.dateRange.getDatesObject(now)
				  )
				: 0) + this.getTimesheetDataInDateRange(item, field)
		);
	}

	getProfitMargin(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		// Note that it's acceptable for this to return `NaN` or `Infinity`.
		const income = this.getRevenue(
			item,
			now,
			field,
			includeExpenses,
			reveneuField
		);
		const spend = this.getExpenses(
			item,
			now,
			field,
			includeExpenses,
			reveneuField
		);
		return ((income - spend) / income) * 100;
	}

	getSummedProfitMargin(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		const income = this.sumValueFromPhases(item, (item) =>
			this.getRevenue(item, now, field, includeExpenses, reveneuField)
		);
		const spend = this.sumValueFromPhases(item, (item) =>
			this.getExpenses(item, now, field, includeExpenses, reveneuField)
		);
		return ((income - spend) / income) * 100;
	}

	getSummedMarkup(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		const income = this.sumValueFromPhases(item, (item) =>
			this.getRevenue(item, now, field, includeExpenses, reveneuField)
		);
		const spend = this.sumValueFromPhases(item, (item) =>
			this.getExpenses(item, now, field, includeExpenses, reveneuField)
		);
		return ((income - spend) / spend) * 100;
	}

	getSummedNetMultiplier(
		item,
		now,
		field,
		includeExpenses = false,
		reveneuField = "revenue"
	) {
		const income = this.sumValueFromPhases(item, (item) =>
			this.getRevenue(item, now, field, includeExpenses, reveneuField)
		);
		const spend = this.sumValueFromPhases(item, (item) =>
			this.getExpenses(item, now, field, includeExpenses, reveneuField)
		);
		return income / spend;
	}

	getPercentOfTotalExpenses(item, now, field, totalExpenses) {
		// Note that it's acceptable for this to return `NaN` or `Infinity`.
		const payField =
			field === TimesheetField.pay || field === TimesheetField.payToDate;
		if (item.constructor !== Project) {
			return 0;
		} else {
			const expenses =
				(!payField
					? item.getExpensesSpentInDateRange(
							this.dataSource.dateRange.getDatesObject(now)
					  )
					: 0) + this.getTimesheetDataInDateRange(item, field);
			return (expenses / totalExpenses) * 100;
		}
	}

	sumValueFromPhases(item, getValue) {
		if (this.isProject(item) && this.reportHasPhaseFilters) {
			return sum(this.getProjectPhases(item).map((ph) => getValue(ph)));
		} else {
			return getValue(item);
		}
	}

	isProject(item) {
		return item.hasOwnProperty("phases");
	}

	getProjectPhases(project) {
		return project.getPhasesForProjectTable(projectsPageStore.reportStore);
	}

	get reportHasPhaseFilters() {
		return (
			projectsPageStore.reportStore.report.filters
				.toJS()
				.filter((f) => f.options?.applyPhases).length > 0
		);
	}
};

export const ProjectsPageStore = class extends StoreBase {
	constructor() {
		super();

		let self = this;

		this.columns = new ProjectColumns(this).columns;

		this.expandedProjects = [];
		this.isReportActionsDropdownExpanded = false;
		this.isReportSettingsExpanded = false;
		this.reportSaveState = null;
		this.filterText = "";
		this.isDirty = false;

		this.reportStore = new ReportStore({
			path: "projects-page/report",
			columns: this.columns,
			isColumnVisible: (id) => this.isColumnVisible(id),
		});

		this.timesheetDataCache = new TimesheetDataCache(() =>
			this.emitChanged()
		);
	}

	handle(action) {
		if (action.type.startsWith("report/")) {
			this.reportStore.handle(action);
			if (isDataChangedAction(action)) {
				this.updateTimesheetDataCache();
			}
		}
	}

	isColumnVisible(id) {
		const requirement = columnRequirements[id];
		return (
			requirement == null ||
			FinancialsVisibility.isAtLeast(
				userStore.user.permissions.financialsVisibility,
				requirement
			)
		);
	}

	get visibleColumns() {
		return this.columns.filter((c) => this.isColumnVisible(c));
	}

	get selectedVisibleColumns() {
		return this.reportStore.report.columns
			.filter((c) => this.isColumnVisible(c))
			.map((c) => this.reportStore.getColumnById(c));
	}

	loadReport(report) {
		if (report == null) {
			report = organisationStore.getReportByUuid(
				organisationStore.organisation.settings.reports.visibleUuid
			);
		}
		this.reportStore.report = report;
		this.updateTimesheetDataCache();
		this.emitChanged();
	}

	toDefaultPage() {
		router.history.push("/dashboard/projects");
	}

	_makeNonArchivedProjectsReport() {
		return this._makeReport(
			parseFilter({
				columnId: "status",
				matcher: {
					type: "projectStatus",
					value: [ProjectStatus.archived],
					operation: "not_any",
				},
			})
		);
	}

	_makeActiveProjectsReport() {
		return this._makeReport(
			parseFilter({
				columnId: "status",
				matcher: {
					type: "projectStatus",
					value: [ProjectStatus.active],
					operation: "any",
				},
			})
		);
	}

	_makeReport(filter) {
		return new Report({
			name: "New report",
			reportType: "project",
			columns: [
				"name",
				"revenue",
				"expenses",
				"profit",
				"profitMargin",
				"actualVsBudgetedHours",
				"actualVsBudgetedExpenses",
				"revenueVsFee",
			],
			filters: Immutable.fromJS([filter]),
		});
	}

	_newReport() {
		this.reportStore.setReport(this._makeNonArchivedProjectsReport());
	}

	toggleProjectExpanded(project) {
		this.expandedProjects = !_.include(this.expandedProjects, project)
			? [...this.expandedProjects, project]
			: _.without(this.expandedProjects, project);
		this.emitChanged();
	}

	expandProjects(projects) {
		this.expandedProjects = _.union(this.expandedProjects, projects);
		this.emitChanged();
	}

	collapseProjects(projects) {
		this.expandedProjects = _.difference(this.expandedProjects, projects);
		this.emitChanged();
	}

	newProject() {
		// this.timesheetDataCache.cancelRequests();
		router.history.push("/dashboard/project/new");
	}

	get dateRange() {
		if (this.reportStore.report != null) {
			return this.reportStore.report.dateRange;
		} else {
			return null;
		}
	}

	get timesheetData() {
		const dateRange = this.dateRange;
		if (dateRange != null) {
			return {
				projectMap: Immutable.Map(),
				projectPhaseMap: Immutable.Map(),
				staffMemberMap: Immutable.Map(),
				staffMemberBillableMap: Immutable.Map(),
				...this.timesheetDataCache.getDateRange(dateRange),
			};
		} else {
			return {
				projectMap: Immutable.Map(),
				projectPhaseMap: Immutable.Map(),
				staffMemberMap: Immutable.Map(),
				staffMemberBillableMap: Immutable.Map(),
			};
		}
	}

	updateTimesheetDataCache() {
		if (
			this.reportStore.hasSelectedColumnWithRequirement("timesheetCache")
		) {
			this.timesheetDataCache.populateDateRange(this.dateRange);
		}
	}

	newReport() {
		// this.timesheetDataCache.cancelRequests();
		router.history.replace("/dashboard/projects/report/new");
	}
};

export let projectsPageStore = new ProjectsPageStore();

export let actions = new ActionCollection(
	"PROJECT_TABLE_",
	projectsPageStore,
	[
		{
			name: "toggleProjectExpanded",
			args: ["project"],
			callback: "default",
		},
		{ name: "expandProjects", args: ["projects"], callback: "default" },
		{ name: "collapseProjects", args: ["projects"], callback: "default" },
		{ name: "newProject", args: [], callback: "default" },
	],
	dispatcher,
	function (action) {}
).actionsDict;
