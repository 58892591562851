import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { organisationStore, actions as organisationActions, newOrganisationActions } from "./organisation.js";
import { CoincraftPage } from './CoincraftPage.js';
import { Link } from 'react-router';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import { InfoAlert } from './widgets/alerts.js';
import PropTypes from "prop-types";


export var IncompleteSetupPage = CreateReactClass({
  propTypes: {
    // If `header` is provided, we include it verbatim inside the header info panel.
    header: PropTypes.node,
    // Otherwise if `heading` is provided, we include "Not yet ready for {header}" in the info panel
    heading: PropTypes.string,
    // If neither is provided then there is no info panel.
    type: PropTypes.string,
    // Display allocations or reveneu.

    onboardingData: PropTypes.shape({
      hasRevenue: PropTypes.bool.isRequired,
      hasProjects: PropTypes.bool.isRequired,

      // Not needed if `hasRevenue === true`
      canCreateRevenue: PropTypes.bool,
      // Not needed if `hasProjects === true`
      canCreateProjects: PropTypes.bool
    }).isRequired
  },

  render: function() {
    let content;
    const itemType = this.props.type == "resource" ? "allocations" : "revenue"

    const notYetReady = this.props.header != null ?
      <InfoAlert>
        {this.props.header}
      </InfoAlert>
    : this.props.heading != null ?
      <InfoAlert>
        <strong>
          Not yet ready for {this.props.heading}
        </strong>
      </InfoAlert>
    :
      null;

    if (this.props.onboardingData.hasProjects) {
      if (this.props.onboardingData.canCreateRevenue) {
        content = <div className="no-revenue--can-create">
          {notYetReady}

          <p>
            The next step to getting a functional {this.props.heading} is to
            enter some <em>{itemType}</em> for some of your projects.
          </p>
          <p>
            Click one of your projects below and we'll show you how to add some <em>{itemType}</em>.
          </p>

          <div style={{margin: '1em 0 0 1em'}}>
            <ProjectList />
          </div>
        </div>;
      }
      else {
        content = <div className="no-revenue--cannot-create">
          {notYetReady}

          <p>
            You have projects but no <em>{itemType}</em>. Why not ask your Coincraft admin to set some up?
          </p>
          <div style={{marginTop: '1em'}}>
            <AdminList />
          </div>
        </div>;
      }
    }
    else {
      if (this.props.onboardingData.canCreateProjects) {
        content = <div className="no-projects--can-create">
          {notYetReady}

          <p>
            You don't have any projects yet. Why not create one?
          </p>
          <p>
            <button
                onClick={() => newOrganisationActions.newProject()}
                className="btn btn-lg btn-primary">
              <small>
                <i className={classNames("fa", "fa-plus")} />
                Create Project
              </small>
            </button>
          </p>
        </div>;
      }
      else {
        content = <div className="no-projects--cannot-create">
          {notYetReady}

          <p>
            You don't have any projects. Why not ask your Coincraft admin to create some?
          </p>
          <div style={{marginTop: '1em'}}>
            <AdminList />
          </div>
        </div>;
      }
    }

    return <CoincraftPage
      className={this.props.className}
      heading={this.props.heading}
      body={content}
    />;
  },
});


var ProjectList = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        projects: organisationStore.getSortedProjects()
      };
    })
  ],

  render: function() {
    return <div>
      {this.state.projects.map(function(project) {
        return <div key={project.id} style={{marginBottom: '0.5em'}}>
          <Link to={`/dashboard/project/${project.id}?showRevenueHint=true`}>
            {project.getTitle()}
          </Link>
        </div>;
      })}
    </div>;
  }
});


var AdminList = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        adminNames: organisationStore.getAdminNames()
      };
    })
  ],

  render: function() {
    if (this.state.adminNames.length > 1) {
      return <div>
        <p>
          Your Coincraft admins are:
        </p>
        <ul>
          {this.state.adminNames.map(function(name, i) {
            return <li key={i}>
              {name}
            </li>;
          })}
        </ul>
      </div>;
    }
    else {
      return <div>
        Your Coincraft admin is {this.state.adminNames[0]}.
      </div>;
    }
  }
});
