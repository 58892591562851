import { jsonHttp } from "../jsonHttp.js";
import { myHttp } from "../myHttp.js";
import { organisationStore } from "../organisation.js";
import { ConnectionState } from "./ConnectionState.js";
import apiRequest from "../apiRequest.js";
import Request from "axios-request-handler";
import Cookies from "js-cookie";

let checkOauth = new Request(
	`${process.env.REACT_APP_SERVER_URL}/organisation/${organisationStore.organisation.id}/${organisationStore.organisation.accountingSystem}/oauth-check`,
	{ withCredentials: true }
);

export function beginAuth(accountingSystemId) {
	return apiRequest({
		path: `/organisation/${organisationStore.organisation.id}/${accountingSystemId}/oauth-start`,
		method: "get",
		params: { timezoneOffset: -1 * new Date().getTimezoneOffset() },
	});
}

export function openAuthPopup(accountingSystem) {
	let connection =
		organisationStore.getAccountingSystemByIdentifier(
			accountingSystem
		).connection;
	connection.state = ConnectionState.authWindowOpen;
	return new Promise((resolve, reject) => {
		window.open(connection.url);
		const timer = setInterval(function () {
			try {
				if (Cookies.get("cc-accounting") != null) {
					const credentials = JSON.parse(
						Cookies.get("cc-accounting")
					);
					clearInterval(timer);
					if (credentials.verified) {
						resolve(credentials);
					} else {
						reject();
					}
				}
			} catch (e) {
				// Trying to get a variable from the window object will throw an error
				// until the page redirects to a Coincraft URL.
			}
		}, 200);
		// you can cancel polling by returning false
	});
	// return new Promise(function(resolve, reject) {
	//   let w = window.open(connection.url);
	//   const timer = setInterval(function() {
	//     try {
	//       if (w.credentials != null) {
	//         clearInterval(timer);
	//         if (w.credentials.verified) {
	//           resolve(w.credentials);
	//         }
	//         else {
	//           reject();
	//         }
	//       }
	//     }
	//     catch(e) {
	//       // Trying to get a variable from the window object will throw an error
	//       // until the page redirects to a Coincraft URL.
	//     }
	//   }, 200);
	// });
}

export function getAccountingSystemData(accountingSystem) {
	let data = accountingSystem.data;
	if (data == null || data.status === "error") {
		accountingSystem.data = "loading";
		organisationStore._updateInvoiceSettingsState(accountingSystem);
		organisationStore.emitChanged();
		return new Promise(function (resolve, reject) {
			apiRequest({
				path: `/organisation/current/${accountingSystem.identifier}/get-data`,
				method: "get",
			}).then(
				(data) => {
					if (data.error) {
						reject(data);
					} else {
						resolve(data.data);
					}
				},
				(data) => reject(data)
			);
		});
	} else {
		return new Promise(function (resolve) {
			resolve(data);
		});
	}
}

export function disconnect(accountingSystem) {
	checkOauth.cancel();
	return new Promise(function (resolve, reject) {
		apiRequest({
			path: `/organisation/current/${accountingSystem}/disconnect`,
			method: "post",
		}).then(
			(data) => resolve(),
			(data) => reject()
		);
	});
}

export function reconnect(accountingSystem) {
	var connection =
		organisationStore.getAccountingSystemByIdentifier(
			accountingSystem
		).connection;
	connection.url = null;
	this.beginAuth(accountingSystem); // `beginAuth` will `emitChanged` if appropriate.
}

export function saveSettings(accountingSystemId, settings, generalSettings) {
	let ac =
		organisationStore.getAccountingSystemByIdentifier(accountingSystemId);
	return new Promise(function (resolve, reject) {
		apiRequest({
			path: `/organisation/current/${accountingSystemId}/save-settings`,
			method: "post",
			data: {
				settings: settings,
				data: ac.data !== "loading" ? ac.data : null,
				generalSettings: generalSettings,
			},
		}).then(
			(data) => resolve(data),
			(data) => reject()
		);
	});
}

export function syncInvoice(accountingSystemId, invoice) {
	return apiRequest({
		path: `/organisation/current/${accountingSystemId}/copy-invoice/${invoice.id}`,
		method: "post",
	});
}
