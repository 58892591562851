import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { userStore } from "../user.js";
import { Table, Column } from "../table.js";
import { rootStore } from "../RootStore.js";
import { OrganisationHoliday } from "../models/organisationHoliday.js";
import { CoincraftPage } from "../CoincraftPage.js";
import { staffRoleListStore, actions } from "./staffRoleListStore.js";
import { Link } from "react-router";

export var StaffRoleList = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([userStore, rootStore], function () {
			return {
				isAdmin: userStore.isAdmin(),
				staffRoles: staffRoleListStore.staffRoles,
				showArchived: staffRoleListStore.showArchived,
			};
		}),
	],

	render: function () {
		return (
			<CoincraftPage
				header={
					<div>
						<div>
							<div className="heading">Staff Roles</div>
						</div>
						{this.state.isAdmin ? (
							<div>
								<button
									className="page-header__button--primary create-role-button"
									onClick={() => actions.newStaffRole()}
									style={{
										marginTop: "2em",
										marginRight: "1em",
									}}
								>
									+ Create Staff Role
								</button>
								<input
									type="checkbox"
									style={{ marginBottom: "0.5em" }}
									className="flex-0-0-auto"
									checked={this.state.showArchived}
									onChange={(e) =>
										actions.toggleShowArchived()
									}
								/>
								<span
									style={{ marginLeft: "0.5em" }}
									className="flex-0-0-auto"
								>
									Show Archived
								</span>
							</div>
						) : null}
					</div>
				}
				body={
					<div style={{ maxWidth: "60em", height: "100%" }}>
						<StaffRolesTable staffRoles={this.state.staffRoles} />
					</div>
				}
			/>
		);
	},
});

var StaffRolesTable = CreateReactClass({
	getInitialState: function () {
		let self = this;
		return {
			columns: [
				new Column({
					identifier: "name",
					header: "Name",
					width: "75%",
					data: (sr) => sr.name,
					content: function (sr, i, stack, data) {
						return (
							<div>
								<Link
									to={`/dashboard/staff-roles/${sr.id}`}
									style={{ display: "block" }}
								>
									{sr.name}
								</Link>
							</div>
						);
					},
					type: "string",
				}),
			],
		};
	},

	render: function () {
		if (this.props.staffRoles == null) {
			return null;
		}
		return (
			<div
				style={{ maxWidth: "65em", height: "100%", minHeight: "30em" }}
			>
				<Table
					scroll={true}
					rows={this.props.staffRoles}
					columns={this.state.columns}
				/>
			</div>
		);
	},

	handleSelectedColumnsChange: function (selectedColumns) {
		this.setState({ selectedColumns: selectedColumns });
	},
});
