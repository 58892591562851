import _ from 'underscore';
import { handleAction } from '../coincraftFlux.js';
import { organisationStore } from '../organisation.js';
import { ContactFormStore } from '../contacts/flux.js';


const actionDefinitions = [
  {action: 'newContact', args: ['context']},
];


export const ContactSelectorStore = class {
			constructor(path, { onSave = null } = {}) {
				this.path = path;
				this.isPopupOpen = false;
				this.onSave = onSave;

				this.contactFormStore = new ContactFormStore(
					this.path + "/contact-form"
				);
				this.stores = {
					"contact-form": this.contactFormStore
				};

				this.actionDefinitions = actionDefinitions;
			}

			newContact(context) {
				this.isPopupOpen = true;
				organisationStore._checkCurrentAccountingSystemAuth();
				this.contactFormStore.newContact(context);
			}

			handle(action) {
				if (action.path.startsWith(this.path + "/contact-form")) {
					let result = this.contactFormStore.handle(action);
					if (
						action.type === "contact/saveSuccess" ||
						action.type === "contact/cancel"
					) {
						this.isPopupOpen = false;
					}
					if (
						action.type === "contact/saveSuccess" &&
						this.onSave != null
					) {
						this.onSave(action.contactData);
					}
					return result;
				} else {
					return handleAction(action, this);
				}
			}
		};


