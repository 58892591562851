import { dateConverter } from './dateconverter.js';

export const TimesheetItem = class {
  constructor({
      endDate, project, phase, staffMember, staffRole, hours,
      allocation, type = 'staffAllocation', monthIndex }) {
    this.endDate = endDate ? endDate : dateConverter.monthIndexToEndOfMonthOffset(monthIndex);
    this.project = project;
    this.phase = phase;
    this.staffMember = staffMember;
    this.staffRole = staffRole;
    this.hours = hours;
    this.allocation = allocation;
    this.type = type; //staffAllocation, roleAllocation, timesheet
    this.monthIndex = monthIndex ? monthIndex : dateConverter.getMonthIndex(endDate);
  }
}
