import React from 'react';
import CreateReactClass from 'create-react-class';
import { DropdownList } from 'react-widgets';

import PropTypes from "prop-types";

export var AccountSelectors = CreateReactClass({
  propTypes: {
    accountingSystemShortName: PropTypes.string.isRequired,

    includeAccounts: PropTypes.bool,

    data: PropTypes.shape({
      accounts: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })),
      taxTypes: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        rate: PropTypes.number.isRequired
      })),
    }).isRequired,

    settings: PropTypes.shape({
      account: PropTypes.object,
      taxOnTaxType: PropTypes.object,
      taxOffTaxType: PropTypes.object,
    }),

    onChange: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      includeAccounts: true
    };
  },

  render: function() {
    return <div>
      {this.props.includeAccounts ?
        <div className="flexbox-container flex-align-items-center" style={{margin: '1em 0'}}>
          <div style={{width: '30%'}}>
            <strong>
              {this.props.accountingSystemShortName} Sales Account
            </strong>
          </div>
          <div style={{width: '70%'}} className="xero-settings-widget__sales-account-cell">
            <DropdownList
              value={this.props.settings.salesAccount}
              data={this.props.data.accounts}
              valueComponent={({item}) => <span>{item.name}</span>}
              itemComponent={({item}) => <span>{item.name}</span>}
              filter={function(option, searchText) {
                return option.name.toUpperCase().indexOf(searchText.toUpperCase()) >= 0;
              }}
              valueField='id'
              onChange={(account) => this.props.onChange({...this.props.settings, salesAccount: account})}
              placeholder="Please select..."
            />
          </div>
        </div>
      : null}
      <div className="flexbox-container flex-align-items-center" style={{margin: '1em 0'}}>
        <div style={{width: '30%'}}>
          <strong>{this.props.accountingSystemShortName} Tax Types</strong>
        </div>
        <div style={{width: '70%'}} />
      </div>
      <div className="flexbox-container flex-align-items-flex-start" style={{margin: '1em 0'}}>
        <div style={{width: '30%', paddingLeft: '2em'}}>
          Taxable Income:
        </div>
        <div style={{width: '70%'}} className="xero-settings-widget__tax-on-tax-type-cell">
          <div>
            <DropdownList
              value={this.props.settings.taxOnTaxType}
              data={this.props.data.taxTypes.filter(tt => tt.rate > 0)}
              valueComponent={({item}) => <span>{`${item.name} (${item.rate}%)`}</span>}
              itemComponent={({item}) => <span>{`${item.name} (${item.rate}%)`}</span>}
              valueField='id'
              onChange={(taxType) => this.props.onChange({...this.props.settings, taxOnTaxType: taxType})}
              placeholder='Please select...'
            />
          </div>
          <small>
            The default {this.props.accountingSystemShortName} Tax Type for <strong>taxed</strong> invoice line items.
          </small>
        </div>
      </div>
      <div className="flexbox-container flex-align-items-flex-start" style={{margin: '1em 0'}}>
        <div style={{width: '30%', paddingLeft: '2em'}}>
          Tax Free Income:
        </div>
        <div style={{width: '70%'}} className="xero-settings-widget__tax-off-tax-type-cell">
          <div>
            <DropdownList
              value={this.props.settings.taxOffTaxType}
              data={this.props.data.taxTypes.filter(tt => tt.rate === 0)}
              valueComponent={({item}) => <span>{`${item.name}`}</span>}
              itemComponent={({item}) => <span>{`${item.name}`}</span>}
              valueField='id'
              onChange={(taxType) => this.props.onChange({...this.props.settings, taxOffTaxType: taxType})}
              placeholder='Please select...'
            />
          </div>
          <small>
            The default {this.props.accountingSystemShortName} Tax Type for invoice line items <strong>without tax.</strong>
          </small>
        </div>
      </div>
    </div>;
  }
});


