import { parseFormula } from "./parseFormula";
import { format } from "date-fns";
import { durationToUnit } from "@ryki/datemath";

export const adjustFormulaToValue = (ast, newValue, ref) => {
	if (!(ast.type === newValue.type)) return;
	if (!adjustableNodeTypes.includes(ast.type)) return;
	if (ast.primitive && ast.type === newValue.type) {
		return formulaFormatters[newValue.type](newValue.value);
	} else {
		let nodes = [];
		let newFormula = null;
		const diffAst = parseFormula(
			`(${formulaFormatters[newValue.type](newValue.value)}) - (${
				ast.matchString
			})`,
			ref
		);
		if (isZeroValue[diffAst.type]?.(diffAst.value)) return ast.matchString;
		nodes.push(ast);
		while (nodes.length && !newFormula) {
			// may be an issue with primitive string inputs
			const node = nodes.shift();
			if (node.primitive && node.type === diffAst.type) {
				const invert = node?.parent?.op === "subtract";
				// node does not know if it's being subtracted resulting in bad calculations
				const adjustedVal = parseFormula(
					`((${formulaFormatters[diffAst.type](diffAst.value)}) ${
						!invert ? "+" : "-"
					} (${node.matchString}))${invert ? "* -1" : ""}`,
					ref
				);
				newFormula = ast.matchString
					.replace(
						node.matchString,
						formulaFormatters[adjustedVal.type](adjustedVal.value)
					)
					.replace("+ -", "- ")
					.replace("- -", "+ ");
			}
			if (!newFormula && isNodeAdjustable(node.type, node.op)) {
				nodes.push(...Object.values(node.params));
			}
		}
		if (!newFormula && isNodeScalable(ast.type, ast.op)) {
			let nodes = [];
			const scalerVal = parseFormula(
				`(${formulaFormatters[newValue.type](
					newValue.value
				)}) / (${formulaFormatters[ast.type](ast.value)})`,
				ref
			);
			nodes.push(ast);
			while (nodes.length && !newFormula) {
				// may be an issue with primitive string inputs
				const node = nodes.shift();
				if (node.primitive && scaleableNodeTypes.includes(node.type)) {
					const scaledVal = parseFormula(
						`(${node.matchString}) * (${formulaFormatters[
							scalerVal.type
						](scalerVal.value)})`,
						ref
					);
					newFormula = ast.matchString.replace(
						node.matchString,
						formulaFormatters[scaledVal.type](scaledVal.value)
					);
				}
				if (!newFormula && isNodeScalable(node.type, node.op)) {
					nodes.push(...Object.values(node.params));
				}
			}
		}
		return (
			newFormula ||
			`${ast.matchString} + ${formulaFormatters[diffAst.type](
				diffAst.value
			)}`.replace("+ -", "- ")
		);
	}
};

export const formulaFormatters = {
	text: (val) => String(val),
	number: (val) => String(val),
	dateTime: (val) => {
		const date = new Date(val);
		if (date.getHours() || date.getMinutes() || date.getSeconds()) {
			return format(date, "dd.MM.yyyy HH:mm:ss");
		} else {
			return format(date, "dd.MM.yyyy");
		}
	},
	duration: (val) => {
		const units = Object.keys(val)[0];
		return `${val[units]} ${units}`;
	},
	rate: (val) => {
		return `${val.amount} / ${Object.keys(val.per)[0].slice(0, -1)}`;
	},
	task: (val) => val.label,
	resource: (val) => val.label,
	undefined: (val) => "-",
	null: (val) => "-",
};

export const isZeroValue = {
	string: (val) => null,
	number: (val) => val === 0,
	dateTime: (val) => val === 0,
	duration: (val) => {
		const units = Object.keys(val)[0];
		return val[units] === 0;
	},
	rate: (val) => val.amount === 0,
	task: (val) => null,
	resource: (val) => null,
};

export const isNodeAdjustable = (type, op) =>
	adjustableNodeTypes.includes(type) && adjustableNodeOps.includes(op);
export const adjustableNodeTypes = ["number", "dateTime", "duration", "rate"];
export const adjustableNodeOps = ["add", "subtract"];

export const isNodeScalable = (type, op) =>
	scaleableNodeTypes.includes(type) && scaleableNodeOps.includes(op);
export const scaleableNodeTypes = ["number", "duration", "rate"];
export const scaleableNodeOps = ["divide", "multiply"];

export const refToString = (ref) => {
	return /^[a-zA-Z_0-9]+$/.test(ref) ? ref : `"${ref}"`;
};

export const formulaDefaults = {
	string: (val) => "",
	number: (val) => "0",
	dateTime: (val) => "",
	duration: (val) => "0 days",
	rate: (val) => "0 / hour",
	task: (val) => "",
	resource: (val) => "",
};
