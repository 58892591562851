import React from 'react';
import CreateReactClass from 'create-react-class';
import { formatPercentage0, isNumber } from '../../utils.js';
import { ProgressBar } from './ProgressBar.js';
import PropTypes from "prop-types";


export var ProgressWidget = CreateReactClass({
  propTypes: {
    title: PropTypes.string,
    fromValue: PropTypes.number,
    toValue: PropTypes.number,
    targetValue: PropTypes.number,
    valueFormatter: PropTypes.func,
    description: PropTypes.node,
    style: PropTypes.object,
  },

  getDefaultProps: function() {
    return {
      valueFormatter: value => Math.round(value),
    };
  },

  render: function() {
    const hasDenominator = isNumber(this.props.targetValue) && this.props.targetValue !== 0;
    let fromPercentage, toPercentage;
    if (hasDenominator) {
      fromPercentage = (this.props.fromValue / this.props.targetValue) * 100;
      toPercentage = this.props.toValue != null ? (this.props.toValue / this.props.targetValue) * 100 : null;
    }

    return <div style={this.props.style}>
      <h5>{this.props.title}</h5>
      <div className="flexbox-container flex-space-between">
        {this.props.valueFormatter(this.props.toValue)} / {this.props.valueFormatter(this.props.targetValue)}
        <span>
          {hasDenominator ? (
            this.props.fromValue != null ?
              <span>
                {formatPercentage0(fromPercentage)}
                <i className="fa fa-long-arrow-right" style={{margin: '0 0.25em'}} />
                {formatPercentage0(toPercentage)}
              </span>
            : formatPercentage0(toPercentage)
          ) : null}
        </span>
      </div>
      <ProgressBar
        fromPercentage={fromPercentage || 0}
        toPercentage={toPercentage}
      />
      <div>
        {this.props.description}
      </div>
    </div>;
  }
});
