import React, { useState } from "react";
import { ModalFooter } from "../modal.js";
import { Checkbox } from "../widgets.js";
import useHover from "@react-hook/hover";

export var EditLineItemTimeEntriesPopup = ({
	lineItem,
	phaseId,
	invoice,
	allTimesheetEntries,
	usedTimesheetIds,
	setLineItemTimeEntries,
	onCancel,
}) => {
	const initialSelectedTimesheetIds = new Set(lineItem.timesheetIds.toJS());
	const [selectedTimesheetIds, setSelectedTimesheetIds] = useState(
		new Set(lineItem.timesheetIds.toJS())
	);
	return (
		<>
			<div style={{ padding: "2em" }}>
				{allTimesheetEntries.map((te) => (
					<TimesheetRow
						{...{
							te,
							selectedTimesheetIds,
							setSelectedTimesheetIds,
							usedTimesheetIds,
							initialSelectedTimesheetIds,
						}}
					/>
				))}
			</div>
			<ModalFooter
				acceptButtonText="Save billed time entries"
				onSubmit={() => {
					setLineItemTimeEntries(lineItem.uuid, [
						...selectedTimesheetIds,
					]);
				}}
				onCancel={onCancel}
			/>
		</>
	);
};

const TimesheetRow = ({
	te,
	selectedTimesheetIds,
	setSelectedTimesheetIds,
	usedTimesheetIds,
	initialSelectedTimesheetIds,
}) => {
	const target = React.useRef(null);
	const isHovering = useHover(target, { enterDelay: 200, leaveDelay: 200 });
	const usedInThisInvoice =
		usedTimesheetIds.has(te.id) &&
		!initialSelectedTimesheetIds.has(te.id) &&
		!selectedTimesheetIds.has(te.id);
	const usedInAnotherInvoice =
		te.beenInvoiced &&
		!initialSelectedTimesheetIds.has(te.id) &&
		!selectedTimesheetIds.has(te.id);
	const disabled = usedInThisInvoice || usedInAnotherInvoice;
	return (
		<div
			ref={target}
			style={{
				borderBottom: "1px solid #eee",
				background: isHovering && !disabled ? "#eee" : "transparent",
				cursor: "pointer",
				color: !disabled ? "#444" : "#888",
			}}
			onClick={() => {
				if (!disabled) {
					if (!selectedTimesheetIds.has(te.id)) {
						selectedTimesheetIds.add(te.id);
					} else {
						selectedTimesheetIds.delete(te.id);
					}
					setSelectedTimesheetIds(new Set(selectedTimesheetIds));
				}
			}}
		>
			<div style={{ display: "flex", padding: "0.5em", width: "100%" }}>
				<div
					style={{
						marginRight: "2em",
						display: "flex",
						flex: "0 0 auto",
					}}
				>
					<Checkbox
						style={{ marginRight: "1em" }}
						value={
							disabled
								? usedTimesheetIds.has(te.id)
								: selectedTimesheetIds.has(te.id)
						}
						onChange={() => null}
						disabled={disabled}
					/>
					<div>{`${te.date.format("DD/MM/YYYY")}`}</div>
				</div>
				<div style={{ flex: "1 1 auto" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{ marginRight: "1em" }}
						>{`${te.staffMember.getFullName()} ${
							disabled
								? `(billed ${
										usedInThisInvoice
											? "in this invoice"
											: "in another invoice"
								  })`
								: ""
						}`}</div>
						<div style={{ textAlign: "right" }}>{`${
							te.numMinutes / 60
						} hours`}</div>
					</div>
					<div>{te.notes}</div>
				</div>
			</div>
		</div>
	);
};
