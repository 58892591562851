
import PropTypes from "prop-types";
var React = require("react");


export var actionMixin = {
  propTypes: {
    action: PropTypes.func,
    actionArgs: PropTypes.array
  },

  getDefaultProps: function() {
    return {
      actionArgs: []
    };
  },

  executeAction: function(...args) {
    this.props.action(...[...this.props.actionArgs, ...args]);
  }
};


export function buttons(...buttons) {
  let nonNullButtons = buttons.filter(b => b != null);
  return nonNullButtons.map(function(button, i) {
    return (
      <div
          key={i}
          style={{display: 'inline-block', marginLeft: i === nonNullButtons.length - 1 ? '0.6em' : null}}>
        {button}
      </div>
    );
  });
}


