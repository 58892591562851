export let getHashObj = (ref, contextRef) => null;
export const setGetHashObj = (newLookupFunc) =>
    (getHashObj = newLookupFunc ?? getHashObj);

export let getAtObj = (ref, contextRef) => null;
export const setGetAtObj = (newLookupFunc) =>
    (getAtObj = newLookupFunc ?? getAtObj);
    
export let getContextObj = (contextRef) => null;
export const setGetContextObj = (newLookupFunc) =>
	(getContextObj = newLookupFunc ?? getContextObj);

export const getHashReferenceObject = (ref, contextRef) => {
	return getHashObj(ref, contextRef);
};
export const getAtReferenceObject = (ref, contextRef) => {
	return getAtObj(ref, contextRef);
};
export const getContextReferenceObject = (contextRef) => {
	return getContextObj(contextRef);
};