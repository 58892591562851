import { getAtReferenceObject } from "../ReferenceLookup";

export default ({ ref }, context) => {
	const refObj = getAtReferenceObject(ref.value, context);
	if (!refObj) {
		throw {
			value: "No object found with this reference.",
			type: "error",
			dependencies: new Map([...ref.dependencies]),
		};
	}
	return {
		type: refObj.type,
		value: refObj,
		primitive: true,
	};
};
