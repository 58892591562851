import { generateUUID } from '../utils.js';
import { makeRecordClass, BooleanType, NumberType, StringType, DateType, DateTimeType, MapType, ListOf, UUID } from './record.js';
import Immutable from 'immutable';
import xspans from 'xspans';
import moment from 'moment';
import _ from 'underscore';
import { dateConverter } from './dateconverter.js';
import { organisationStore } from '../organisation.js';

export const Rate = makeRecordClass({
  date: DateType,
  payRate: NumberType({ defaultValue: 0 }),
  overtimeRate: NumberType({ defaultValue: 0 }),
  costRate: NumberType({ defaultValue: 0 }),
  chargeOutRate: NumberType({ defaultValue: 0 }),
  roleRate: BooleanType({defaultValue: true}),
  uuid: UUID,
});


export const StaffRole = class extends makeRecordClass({
			id: null,
			uuid: UUID,
			name: StringType,
			rates: ListOf(() => Rate),
			avgPayRate: BooleanType,
			avgOvertimeRate: BooleanType,
			avgCostRate: BooleanType,
			avgChargeOutRate: BooleanType,
			isArchived: BooleanType
		}) {
			constructor(...args) {
				super(...args);
				if (this.uuid == null) {
					this.uuid = generateUUID();
				}
				this._cache = {};
			}

			get staff() {
				return organisationStore.getStaffMembersByRoleId(this.id);
			}

			get staffIds() {
				return this.staff.map(s => s.id);
			}

			getAvgStaffRateAtDate(rateType, dateInt) {
				let totalVal = 0;
				let staff = this.staff;
				staff.forEach(s => {
					totalVal = totalVal + s.getRateAtDate(rateType, dateInt);
				});
				return totalVal / staff.length;
			}

			getAvgStaffRate(rateType) {
				const totalVal = this.getTotalStaffRate(rateType);
				const staff = this.staff;
				return totalVal / staff.length;
			}

			getTotalStaffRate(rateType) {
				let totalVal = 0;
				const staff = this.staff;
				staff.forEach(s => {
					totalVal = totalVal + s[rateType];
				});
				return totalVal;
			}

			fetchStaffRates() {
				return (
					this.avgPayRate ||
					this.avgOvetertimeRate ||
					this.avgCostRate ||
					this.avgChargeOutRate
				);
			}

			fetchRoleRates() {
				return (
					!this.avgPayRate ||
					!this.avgOvetertimeRate ||
					!this.avgCostRate ||
					!this.avgChargeOutRate
				);
			}

			getStaffAvgRates() {
				let self = this;
				let allStaffRateDates = [];
				this.staff.forEach(s => {
					allStaffRateDates = [
						...allStaffRateDates,
						...s.payRates.map(pr =>
							dateConverter.momentToInt(pr.date)
						)
					];
				});
				allStaffRateDates = _.uniq(allStaffRateDates);
				return allStaffRateDates.map(d => {
					return {
						date: dateConverter.intToMoment(d),
						payRate: self.avgPayRate
							? self.getAvgStaffRateAtDate("payRate", d)
							: 0,
						overtimeRate: self.avgOvertimeRate
							? self.getAvgStaffRateAtDate("overtimeRate", d)
							: 0,
						costRate: self.avgCostRate
							? self.getAvgStaffRateAtDate("costRate", d)
							: 0,
						chargeOutRate: self.avgChargeOutRate
							? self.getAvgStaffRateAtDate("chargeOutRate", d)
							: 0,
						staffRate: true
					};
				});
			}

			getCombinedRates() {
				return [
					...(this.fetchStaffRates() ? this.getStaffAvgRates() : []),
					...(this.fetchRoleRates() ? this.rates : [])
				];
			}

			refreshRates() {
				if (!this.rates.isEmpty()) {
					const now = moment();
					let i = 0,
						l = this.rates.size;
					while (i < l) {
						if (this.rates.get(i).date.isAfter(now)) {
							break;
						}
						i++;
					}
					const currentRate = this.rates.get(i > 0 ? i - 1 : 0);
					this._cache.payRate = [currentRate.payRate, this.rates];
					this._cache.overtimeRate = [
						currentRate.overtimeRate,
						this.rates
					];
					this._cache.costRate = [currentRate.costRate, this.rates];
					this._cache.chargeOutRate = [
						currentRate.chargeOutRate,
						this.rates
					];
				}
				return this;
			}

			_getRate(field) {
				let c = this._cache[field];
				if (c == null || this.rates !== c[1]) {
					this.refreshRates();
					c = this._cache[field];
					if (c != null) {
						return c[0];
					} else {
						//TODO-rroject_architect is null ok?
						return null;
					}
				} else {
					return c[0];
				}
			}

			get payRate() {
				if (this.avgPayRate) {
					return this.getAvgStaffRate("payRate");
				} else {
					return this._getRate("payRate");
				}
			}

			get overtimeRate() {
				if (this.avgOvertimeRate) {
					return this.getAvgStaffRate("overtimeRate");
				} else {
					return this._getRate("overtimeRate");
				}
			}

			get costRate() {
				if (this.avgCostRate) {
					return this.getAvgStaffRate("costRate");
				} else {
					return this._getRate("costRate");
				}
			}

			get chargeOutRate() {
				if (this.avgChargeOutRate) {
					return this.getAvgStaffRate("chargeOutRate");
				} else {
					return this._getRate("chargeOutRate");
				}
			}

			get weeklyAvailability() {
				return this.getTotalStaffRate("weeklyAvailability");
			}

			getNumHoursAvailableInMonth(monthIndex, holidaysArray = []) {
				let monthMoment = dateConverter.monthIndexToMoment(monthIndex);
				let startDate = dateConverter.momentToInt(monthMoment);
				let endDate = dateConverter.momentToInt(
					monthMoment.endOf("month")
				);
				return this.getNumHoursAvailableInRange(
					startDate,
					endDate,
					holidaysArray
				);
			}

			getNumHoursAvailableInRange(
				startDate,
				endDate,
				holidaysArray = []
			) {
				let totalVal = 0;
				const staff = this.staff;
				staff.forEach(s => {
					totalVal += s.getNumHoursAvailableInRange(
						startDate,
						endDate
					);
				});
				return totalVal;
			}

			getAvgStaffRateInRange(rateType, startDateInt, endDateInt) {
				const totalVal = this.getTotalStaffRateInRange(
					rateType,
					startDateInt,
					endDateInt
				);
				const staff = this.staff;
				return totalVal / staff.length;
			}

			getTotalStaffRateInRange(rateType, startDateInt, endDateInt) {
				let totalVal = 0;
				const staff = this.staff;
				staff.forEach(s => {
					totalVal =
						totalVal +
						s.getRateInRange(rateType, startDateInt, endDateInt);
				});
				return totalVal;
			}

			avgRateType(rateType) {
				let string = `avg${rateType[0].toUpperCase()}${rateType.slice(
					1
				)}`;
				return this[string];
			}

			getRateInRange(rateType, startDateInt, endDateInt) {
				if (this.avgRateType(rateType)) {
					return this.getAvgStaffRateInRange(
						rateType,
						startDateInt,
						endDateInt
					);
				} else {
					let rates = [];
					if (startDateInt && endDateInt) {
						rates = this.rates
							.toJS()
							.filter(
								rr =>
									rr[rateType] !== null &&
									rr[rateType] !== undefined &&
									dateConverter.momentToInt(rr.date) <=
										endDateInt
							)
							.sort((a, b) => a.date.diff(b.date));
						if (
							rates.length === 0 &&
							this.rates.toJS().length > 0
						) {
							rates = [new Rate().toJS()];
						}
					} else {
						if (this.rates.toJS().length > 0) {
							rates = [_.last(this.rates.toJS())];
						}
					}
					if (rates.length === 1) {
						return rates[0][rateType];
					} else {
						let rateInRange = 0;
						let totalDuration = endDateInt - startDateInt;
						let intersectingRates = [];
						rates.forEach((a, i) => {
							let rateStartDate = dateConverter.momentToInt(
								a.date
							);
							let rateEndDate = rates[i + 1]
								? dateConverter.momentToInt(rates[i + 1].date)
								: rateStartDate + 100000;
							let intersectingDates = xspans.and(
								[rateStartDate, rateEndDate],
								[startDateInt, endDateInt]
							).data;
							if (intersectingDates.length === 2) {
								intersectingRates.push({
									startDate: intersectingDates[0],
									endDate: intersectingDates[1],
									duration:
										intersectingDates[1] -
										intersectingDates[0],
									rate: a[rateType]
								});
							}
						});
						intersectingRates.forEach(
							a =>
								(rateInRange +=
									a.rate * (a.duration / totalDuration))
						);
						return rateInRange;
					}
				}
			}

			getRateInMonth(rateType, monthIndex) {
				let monthMoment = dateConverter.monthIndexToMoment(monthIndex);
				let startDate = dateConverter.momentToInt(monthMoment);
				let endDate = dateConverter.momentToInt(
					monthMoment.endOf("month")
				);
				return this.getRateInRange(rateType, startDate, endDate);
			}

			getRateAtDate(rateType, dateInt) {
				if (this.avgRateType(rateType)) {
					return this.getAvgStaffRateAtDate(rateType, dateInt);
				} else {
					let rates = this.payRates
						.toJS()
						.filter(
							pr =>
								pr[rateType] !== null &&
								pr[rateType] !== undefined &&
								dateConverter.momentToInt(pr.date) <= dateInt
						)
						.sort((a, b) => a.date.diff(b.date));
					if (rates.length > 0) {
						return _.last(rates)[rateType];
					} else {
						return 0;
					}
				}
			}

			getAvgStaffTotalPayInRange(startDateInt, endDateInt) {
				let totalVal = 0;
				let staff = this.staff;
				staff.forEach(s => {
					totalVal =
						totalVal +
						s.getTotalPayInRange(startDateInt, endDateInt);
				});
				return totalVal / staff.length;
			}

			getTotalPayInRange(startDateInt, endDateInt, staffMember) {
				if (this.avgRateType("payRate")) {
					return this.getAvgStaffTotalPayInRange(
						startDateInt,
						endDateInt
					);
				} else {
					let rates = this.rates
						.toJS()
						.filter(rr => rr.payRate != null)
						.sort((a, b) => a.date.diff(b.date));
					if (rates.length === 1) {
						return (
							rates[0].payRate *
							staffMember.getNumHoursAvailableInRange(
								startDateInt,
								endDateInt
							)
						);
					} else {
						let payInRange = 0;
						let totalDuration = endDateInt - startDateInt;
						let intersectingrates = [];
						rates.forEach((a, i) => {
							let ratestartDate = dateConverter.momentToInt(
								a.date
							);
							let payRateEndDate = rates[i + 1]
								? dateConverter.momentToInt(rates[i + 1].date)
								: ratestartDate + 100000;
							let intersectingDates = xspans.and(
								[ratestartDate, payRateEndDate],
								[startDateInt, endDateInt]
							).data;
							if (intersectingDates.length === 2) {
								intersectingrates.push({
									startDate: intersectingDates[0],
									endDate: intersectingDates[1],
									duration:
										intersectingDates[1] -
										intersectingDates[0],
									availability: staffMember.getNumHoursAvailableInRange(
										intersectingDates[0],
										intersectingDates[1]
									),
									payRate: a.payRate
								});
							}
						});
						intersectingrates.forEach(
							a => (payInRange += a.payRate * a.availability)
						);
						return payInRange;
					}
				}
			}

			static getClassName() {
				return "StaffRole";
			}

			apiTypeName() {
				return "staff-role";
			}
		};
