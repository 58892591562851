import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { actions } from './flux.js';
import { MySlider } from './MySlider.js';
import { mainContentWidth, mainContentPaddingLeft } from './dimensions.js';
import PropTypes from "prop-types";


export var SliderRow = CreateReactClass({
  propTypes: {
    item: PropTypes.object,
    isReversed: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    label: PropTypes.node,
    sliderValues: PropTypes.array,
    getItemValue: PropTypes.func,
    sliderMin: PropTypes.number,
    sliderMax: PropTypes.number,
    onClick: PropTypes.func,
    onConnectingLineItemClick: PropTypes.func,
    selectedConnectingLineItemIndex: PropTypes.number,
    onSliderMove: PropTypes.func,
    onSliderBarMove: PropTypes.func,
    onSliderMouseUp: PropTypes.func,
    onMouseOver: PropTypes.func,
    hasSettingsButton: PropTypes.bool,
    style: PropTypes.object,
    isEditable: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isReversed: false,
      onClick: function() { },
      onConnectingLineItemClick: function() { },
      onSliderMouseUp: function() { },
      onSliderBarMove: function() { },
      hasSettingsButton: true,
      style: {}
    };
  },

  render: function(left, right) {
    let item = this.props.item;

    return (
      <div
          className={classNames("row", this.props.isHighlighted ? 'selected' : null)}
          style={{
            verticalAlign: 'middle',
            borderBottom: '1px solid #ccc',
            padding: (item != null && item.isProject) ? '1em 30px .5em' : '0 30px .5em',
            ...this.props.style
          }}
          onClick={this.handleLinkClick}
          onMouseOver={this.handleMouseOver}>
        <div>
          <a
              href="javascript:void(0)"
              onClick={this.handleLinkClick}
              style={{
                textDecoration: 'none',
                fontWeight: this.props.isHighlighted ? 'bold' : null
              }}>
            {this.props.label}
          </a>
        </div>
        {this.props.hasSettingsButton ?
          <div style={{float: 'right', display: 'inline-block'}}>
            <div
                className="dark-grey-hover"
                style={{
                  margin: (item != null && item.isProject) ? '-1.5em 0 0 0' : '0 0 0 0',
                  fontSize: (item != null && item.isProject) ? '1.5em' : '1.2em',
                  display: 'inline-block'
                }}
                onClick={function() {
                  actions.editItemTasks(item);
                }}>
              <i className="fa fa-list" />
            </div>
            <div
                className='project-settings dark-grey-hover'
                style={{
                  margin: (item != null && item.isProject) ? '-1.5em -0.5em 0 0' : '0 -0.5em 0 0',
                  fontSize: (item != null && item.isProject) ? '1.5em' : '1.2em',
                  display: 'inline-block'
                }}
                onClick={function() {
                  actions.editItem(item);
                }}>
              <i className="fa fa-cog" />
            </div>
          </div>
        : null}
        <div>
          <div style={{width: mainContentWidth}}>
            <div style={{marginLeft: mainContentPaddingLeft}}>
              <MySlider
                isReversed={this.props.isReversed}
                values={this.props.sliderValues}
                getItemValue={this.props.getItemValue}
                min={this.props.sliderMin}
                max={this.props.sliderMax}
                height={40}
                width={'100%'}
                throttleMs={100}
                onMove={this.props.onSliderMove}
                onBarMove={this.props.onSliderBarMove}
                onClick={this.handleSliderClick}
                onMouseUp={this.props.onSliderMouseUp}
                onConnectingLineItemClick={this.props.onConnectingLineItemClick}
                selectedConnectingLineItemIndex={this.props.selectedConnectingLineItemIndex}
                isEditable={this.props.isEditable}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },

  handleMouseOver: function() {
    this.props.onMouseOver(this.props.item);
  },

  handleLinkClick: function() {
    this.props.onClick(this.props.item);
  },

  handleSliderClick: function() {
    this.props.onClick(this.props.item);
  }
});



