import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { Slider } from "../slider.js";
import PropTypes from "prop-types";


export var MySlider = CreateReactClass({
  propTypes: {
    isReversed: PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      isReversed: false
    };
  },

  render: function() {
    let self = this;

    return <Slider
      className="horizontal-slider"
      height={40}
      throttleMs={100}
      getWidgetLineProps={function(slider) {
        return {
          y: (slider.props.height - 6) / 2,
          height: 6,
          fill: '#999',
          className: "phase-slider__widget-line"
        };
      }}
      getConnectingLineProps={function(slider, x1, x2, isSelected) {
        return {
          y: (slider.props.height - 6) / 2,
          height: 6,
          fill: isSelected ? 'red' : '#ffa100',
          className: "phase-slider__connecting-line"
        };
      }}
      drawItem={function(itemValue, itemIndex, x, slider) {
        return <circle
          key={itemValue.uuid || itemIndex}
          cx={x}
          cy={slider.props.height / 2}
          r={itemIndex === 0 ? 8 : 5}
          stroke="#aaa"
          strokeWidth={2}
          fill="white"
          className={classNames(
            "phase-slider__circle",
             itemIndex === 0 ? "phase-slider__circle--large" : "phase-slider__circle--small"
          )}
          {...slider.dragController.getMouseEventProperties(function() {
            if (self.props.isReversed) {
              /**
               * If the user has dragged the start of the project/phase to after the
               * end of the project/phase (or vice versa) we continue the same drag,
               * but move the other end of the project/phase.
               */
              let ix = (itemIndex === 0) ? (self.props.values.length - 1) : 0;
              return {
                itemValue: self.props.values[ix],
                itemIndex: ix
              };
            }
            else {
              return {itemValue: itemValue, itemIndex: itemIndex};
            }
          })}
        />;
      }}
      onChange={function(newValues) {
        // Handled by the object in the `mover` param.
      }}
      {...this.props}
    />;
  }
});

