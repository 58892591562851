import React from 'react';
import CreateReactClass from 'create-react-class';
import { dateConverter } from '../../models.js';
import { formatNumber0 } from '../../utils.js';
import { FlexTable, FlexRow, FlexCell } from '../../widgets/FlexTable.js';
import PropTypes from "prop-types";


export var CashFlowItemTable = CreateReactClass({
  propTypes: {
    cashFlowItems: PropTypes.any,
    currencyFormatter: PropTypes.func,
  },

  render: function() {
    let self = this;
    const cashFlowItems = this.props.cashFlowItems;
    if (cashFlowItems != null && !cashFlowItems.isEmpty()) {
      return <div>
        <FlexTable
            style={{width: '100%'}}
            columns={[
              {style: {width: '30%'}},
              {style: {width: '40%'}},
              {style: {width: '30%'}}
            ]}>
          <FlexRow className="heading">
            <FlexCell style={{textAlign: 'center'}}>
              Type
            </FlexCell>
            <FlexCell style={{textAlign: 'center'}}>
              Date
            </FlexCell>
            <FlexCell style={{textAlign: 'center'}}>
              Amount
            </FlexCell>
          </FlexRow>

          {cashFlowItems.map(function(cfi, i) {
            let type;
            if (cfi.milestone != null) {
              type = `Milestone (${formatNumber0(cfi.percent)}%)`;
            }
            else if (cfi.invoice != null) {
              type = 'Invoice';
            }
            else {
              type = 'Manual';
            }

            return <FlexRow key={i}>
              <FlexCell>
                {type}
              </FlexCell>
              <FlexCell>
                {dateConverter.intToMoment(cfi.endDate).format("MMM DD")}
              </FlexCell>
              <FlexCell style={{textAlign: 'right'}}>
                {self.props.currencyFormatter.format(cfi.fee)}
              </FlexCell>
            </FlexRow>;
          })}
        </FlexTable>
      </div>;
    }
    else {
      return null;
    }
  }
});
