import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../../../modal.js';
import { SaveButton } from '../../../widgets.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { organisationStore } from '../../../organisation.js';
import { Multiselect } from 'react-widgets';
import PropTypes from "prop-types";


export var AddAllocationModal = CreateReactClass({
    propTypes: {
        onSubmit: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        phaseName: PropTypes.string.isRequired,
    },

    mixins: [
        PureRenderMixin
    ],

    getInitialState: function () {
        return {
            isLoaded: false,
            selectedStaff: []
        };
    },

    render: function () {
        let self = this;
        const multiSelText = item => item.staff ? item.staff.getFullName() : item.role.name
        return (
            <Modal
                className="add-allocation-modal"
                closeOnClickOutside={true}
                onClosed={() => this.props.onClose()}>
                <ModalContent header={"Add Expense"} width='45em'>
                    <div>
                        <div style={{ padding: '2em' }}>
                            <div>
                                <label>
                                    Please select the roles / staff below that you wish to assign to {this.props.phaseName || "phase"}.
                                </label>
                                <Multiselect
                                    value={this.state.selectedStaff}
                                    data={[
                                        ...organisationStore.staffMembers.reduce((staff, sm) => {
                                            staff.push({
                                                id: sm.uuid,
                                                staff: sm,
                                                role: sm.role,
                                            })
                                            return staff
                                        }, []),
                                        ...organisationStore.staffRoles.reduce((roles, r) => {
                                            roles.push({
                                                id: r.uuid,
                                                staff: null,
                                                role: r,
                                            })
                                            return roles
                                        }, [])
                                    ]}
                                    valueField='id'
                                    textField={multiSelText}
                                    onChange={(options) => this.setState({ selectedStaff: options })}
                                    filter={function (item, searchText) {
                                        return multiSelText(item).toLowerCase().match(searchText.toLowerCase());
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ padding: '1em', textAlign: 'right', borderTop: 'solid 1px #ccc' }}>
                            <a
                                href="javascript:void(0)"
                                onClick={() => this.props.onClose()}>
                                Cancel
              </a>
                            <SaveButton
                                text={"Add Allocations"}
                                onClick={() => this.props.onSubmit(self.state.selectedStaff)}
                                style={{ marginLeft: '2em' }}
                            />
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        );
    }
});