import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import { authenticationStore } from './authenticationService.js';
import { IntercomMessageLink, ErrorAlert, SuccessAlert } from './widgets.js';
import { store, actions } from './pricing.js';
import PropTypes from "prop-types";


export var SubscriptionForm = CreateReactClass({
  propTypes: {
    finishButtonText: PropTypes.string.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([store, authenticationStore], function() {
      if (authenticationStore.user == null) {
        return {
          isReady: false
        };
      }
      if (!store.isReady) {
        store.initialize();
        return {
          isReady: false
        };
      }
      else {
        return {
          isReady: true,
          price: store.calculatePrice(),
          currency: store.currency,
          state: store.state,
          buyingInProgress: store.buyingInProgress,
        };
      }
    })
  ],

  render: function() {
    if (!this.state.isReady) {
      return null;
    }

    return <div
        style={{
          fontSize: 16,
          letterSpacing: '0.05em',
          lineHeight: 'normal',
          color: '#444',
          textAlign: 'center',
          padding: '0.5em'
        }}>
      <div>
        <div className="price-widget">
          <div className="user-table">
            <table style={{display: 'inline-table'}}>
              <thead>
                <tr>
                  <td>
                    Admin Users
                    <br />
                    <span className="small">
                      ({formatCurrency(this.state.price.adminPricePerMonth)} / month)
                    </span>
                  </td>
                  <td>
                    Standard Users
                    <br />
                    <span className="small">
                      ({formatCurrency(this.state.price.standardUserPricePerMonth)} / month)
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="user-input">
                    <input
                      type="number"
                      min="1"
                      value={this.state.price.numAdminUsers}
                      onChange={this.handleNumAdminsChange}
                    />
                    <span className="small">
                      Click to edit number of users.
                    </span>
                  </td>
                  <td className="user-input">
                    <input
                      type="number"
                      min="0"
                      value={this.state.price.numStandardUsers}
                      onChange={this.handleNumStandardUsersChange}
                    />
                    <span className="small">
                      Click to edit number of users.
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
          <div className="calc-table">
            <table style={{display: 'inline-table', marginBottom: 0, position: 'relative', zIndex: 2}}>
              <tbody>
                <tr>
                  <td>Monthly Subscription</td>
                  <td style={{backgroundColor: "gold"}}>Yearly Subscription</td>
                </tr>
                <tr>
                  <td>
                    <span style={{fontSize: '3em'}}>
                      {formatCurrency(this.state.price.effectiveMonthlyPrice)}
                    </span>
                    <br />
                    <span className="small">
                      {this.state.currency} per month
                      {this.state.monthlyDiscountPercent > 0 ?
                        <span> ({this.state.price.monthlyDiscountPercent}% discount)</span>
                      : null}
                    </span>
                  </td>
                  <td>
                    <span style={{fontSize: '3em'}}>
                      {formatCurrency(this.state.price.effectiveYearlyPricePerMonth)}
                    </span>
                    <br />
                    <span className="small">
                      {this.state.currency} per month
                      {this.state.yearlyDiscountPercent > 0 ?
                        <span> ({this.state.price.yearlyDiscountPercent}% discount)</span>
                      : null}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{formatCurrency(this.state.price.effectiveMonthlyPricePerYear)} / year</td>
                  <td>{formatCurrency(this.state.price.effectiveYearlyPrice)} / year</td>
                </tr>
              </tbody>
            </table>
            <div>
              <table
                  style={{
                    display: 'inline-table',
                    background: 'transparent',
                    boxShadow: 'none',
                    marginTop: 0,
                    position: 'relative',
                    zIndex: 1,
                    fontSize: '.9em',
                    fontWeight: 'bold',
                    fontStyle: 'italic'
                  }}>
                <tbody>
                  <tr>
                    <td style={{border: 'none'}}></td>
                    <td style={{
                          backgroundColor: "gold",
                          border: 'none',
                          boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.4)'
                        }}>
                      Save {formatCurrency(this.state.price.yearlyDiscount)}!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {this.state.state !== 'success' ?
              <table style={{display: 'inline-table', marginBottom: 0, position: 'relative', zIndex: 2}}>
                <tbody>
                  <tr>
                    <td>
                      <SubscribeButton
                        text="Buy Monthly Subscription"
                        state={this.state.state}
                        isInProgress={this.state.buyingInProgress === 'monthly'}
                        onClick={this.handleMonthlySubscriptionButtonClick}
                      />
                    </td>
                    <td>
                      <SubscribeButton
                        text="Buy Yearly Subscription"
                        state={this.state.state}
                        isInProgress={this.state.buyingInProgress === 'yearly'}
                        onClick={this.handleYearlySubscriptionButtonClick}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            : null}
          </div>
        </div>
      </div>

      <div>
        {this.state.state === 'success' ?
          <SuccessAlert>
            <div>
              Your subscription has been successfully processed. Thanks for signing up!
            </div>
            <div style={{marginTop: 20}}>
              <button
                  className="btn btn-lg btn-success"
                  onClick={this.handleFinishButtonClick}>
                {this.props.finishButtonText}
              </button>
            </div>
          </SuccessAlert>
        : null}

        {this.state.state === 'error' ?
          <ErrorAlert>
            <div>
              There was an error processing your subscription.
              You can try again, or <IntercomMessageLink label="contact us" />.
              If you are using Safari, you may need to refresh the page.
            </div>
          </ErrorAlert>
        : null}
      </div>
    </div>;
  },

  handleNumAdminsChange: function(event) {
    actions.setNumAdmins(event.target.value);
  },

  handleNumStandardUsersChange: function(event) {
    actions.setNumStandardUsers(event.target.value);
  },

  handleMonthlySubscriptionButtonClick: function() {
    actions.buyMonthlySubscription();
  },

  handleYearlySubscriptionButtonClick: function() {
    actions.buyYearlySubscription();
  },

  handleFinishButtonClick: function() {
    actions.finish();
  },
});


var SubscribeButton = CreateReactClass({
  propTypes: {
    text: PropTypes.string.isRequired,
    isInProgress: PropTypes.bool,
    state: PropTypes.string,
    onClick: PropTypes.func
  },

  render: function() {
    return _.include([null, "waitingForStripe", "processing", "error"], this.props.state) ?
      <button
          className="btn btn-lg btn-success"
          onClick={this.props.onClick}
          disabled={_.include(['waitingForStripe', 'processing'], this.props.state)}>
        {this.props.state === 'processing' && this.props.isInProgress ?
          "Setting up your subscription"
        : this.props.text}
        {this.props.isInProgress ?
          <span> <i className="fa fa-spinner fa-spin" style={{margin: 0}} /></span>
        : null}
      </button>
    : null;
  }
});


function formatCurrency(x) {
  /**
   * Note that this function isn't related to utils.formatCurrency; it's specific to
   * the pricing widget.
   */
  if (Math.floor(x) === x) {
    return "$" + x.toString();
  }
  else {
    return "$" + x.toFixed(2);
  }
};




