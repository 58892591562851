import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { Enum } from "../enum.js";
import { areSameDbObjects } from "../utils.js";
import { makeRecordClass, StringType, ListOf, BooleanType } from "./record.js";
import PropTypes from "prop-types";

export const PermissionLevel = Enum([
	["admin", 4],
	["projectManager", 3],
	["view", 2],
	["timesheet", 1],
]);
export const OperationLevel = Enum(["edit", "view"]);

export function getPermissionLevelNum(level) {
	return PermissionLevel.itemToNumber(level);
}

const items = ["all", "allExceptPay", "onlyExpenses", "none"];
export let FinancialsVisibility = Enum(items);

FinancialsVisibility.isAtLeast = function (val1, val2) {
	return (
		_.findIndex(items, (i) => i === val1) <=
		_.findIndex(items, (i) => i === val2)
	);
};

export const Permissions = class extends makeRecordClass({
	items: ListOf(() => PermissionItem),
	financialsVisibility: StringType({
		defaultValue: FinancialsVisibility.none,
	}),
	viewRevenueForecast: BooleanType,
	viewResourceSchedule: BooleanType,
}) {
	get isAdmin() {
		// https://docs.google.com/document/d/1DEe0WrCTUb2mGDDN6dTTWbSD8YlNkAXcXy41YlN69OY/edit#heading=h.6hmtqe2odgkk
		// We are admin if we have everything-admin *and* we don't have anything
		// else with sub-admin.
		let foundAdmin = false;
		for (let item of this.items) {
			if (!PermissionLevel.isAtLeast(item.level, PermissionLevel.admin)) {
				foundAdmin = false;
			}
			if (
				item.object.isEverything &&
				item.level === PermissionLevel.admin
			) {
				return true;
			}
		}
		return foundAdmin;
	}

	isAtLeast(level) {
		return (
			UserLevel.itemToNumber(this.getUserLevel()) >=
			UserLevel.itemToNumber(level)
		);
	}

	get isAtLeastLimitedAdmin() {
		return this.isAtLeast(UserLevel.limitedAdmin);
	}

	get isAtLeastProjectManager() {
		return this.isAtLeast(UserLevel.projectManager);
	}

	get isAtLeastViewer() {
		return this.isAtLeast(UserLevel.viewer);
	}

	hasAtLeastLevelForProject(level, project) {
		return permissions
			.projectLevel(level, project)
			.ok({ permissions: this });
	}

	getUserLevel() {
		let highestLevel = 0;
		for (let item of this.items) {
			if (
				item.object.isEverything &&
				item.level === PermissionLevel.admin
			) {
				return UserLevel.admin;
			}
			highestLevel = Math.max(
				getPermissionLevelNum(item.level),
				highestLevel
			);
		}

		switch (highestLevel) {
			case 4:
				return UserLevel.limitedAdmin;
			case 3:
				return UserLevel.projectManager;
			case 2:
				return UserLevel.viewer;
			case 1:
				return UserLevel.timesheet;
			default:
				return null;
		}
	}

	getLabel() {
		switch (this.getUserLevel()) {
			case UserLevel.admin:
				return "Master admin";
			case UserLevel.limitedAdmin:
				return "Limited admin";
			case UserLevel.projectManager:
				return "Project manager";
			case UserLevel.viewer:
				return "Viewer";
			case UserLevel.timesheet:
				return "Timesheet";
			default:
				return "Disabled";
		}
	}

	canEditProject(project) {
		return permissions.canEditProject(project).ok({ permissions: this });
	}

	canViewProject(project) {
		return permissions.canViewProject(project).ok({ permissions: this });
	}

	canViewRevenue() {
		return permissions.canViewRevenue.ok({ permissions: this });
	}

	getProjectsWithAtLeastPermissionLevel(projects, level) {
		//TODO-project_architect maybe phase this out
		if (arguments.length !== 2) {
			throw new Error("deprecated usage");
		}

		return projects.filter((p) => this.hasAtLeastLevelForProject(level, p));
	}
};

export const Permission = class {
	constructor(ok) {
		this.ok = ok;
	}

	static fromFunction(func) {
		return new Permission(func);
	}

	static all(permissions) {
		for (let p of permissions) {
			if (p == null || p.ok == null) {
				throw new Error("Missing permission");
			}
		}
		return new Permission(function (user) {
			return _.all(permissions, (p) => p.ok(user));
		});
	}
};

export let permissions = {
	noRestrictions: Permission.fromFunction((user) => true),
	admin: Permission.fromFunction((user) => user.permissions.isAdmin),
	limitedAdmin: Permission.fromFunction(
		(user) => user.permissions.isAtLeastLimitedAdmin
	),
	projectManager: Permission.fromFunction(
		(user) => user.permissions.isAtLeastProjectManager
	),
	viewer: Permission.fromFunction((user) => user.permissions.isAtLeastViewer),
	financialVisibilityExpenses: Permission.fromFunction(function (user) {
		return FinancialsVisibility.isAtLeast(
			user.permissions.financialsVisibility,
			FinancialsVisibility.onlyExpenses
		);
	}),
	financialVisibilityRevenue: Permission.fromFunction(function (user) {
		return FinancialsVisibility.isAtLeast(
			user.permissions.financialsVisibility,
			FinancialsVisibility.allExceptPay
		);
	}),
	revenueForecastVisible: Permission.fromFunction(function (user) {
		return user.permissions.viewRevenueForecast !== false;
	}),
	resourceScheduleVisible: Permission.fromFunction(function (user) {
		return user.permissions.viewResourceSchedule !== false;
	}),
};

const projectAdmin = function (project) {
	return permissions.projectLevel(PermissionLevel.admin, project);
};

const projectManager = function (project) {
	return permissions.projectLevel(PermissionLevel.projectManager, project);
};

const canEditProject = function (project) {
	return permissions.projectLevel(PermissionLevel.projectManager, project);
};

permissions = {
	...permissions,

	// Pages
	dashboard: Permission.all([
		permissions.viewer,
		permissions.financialVisibilityRevenue,
	]),
	revenueForecast: Permission.all([
		permissions.viewer,
		permissions.financialVisibilityRevenue,
		permissions.revenueForecastVisible,
	]),
	oldRevenueForecast: Permission.all([
		permissions.viewer,
		permissions.financialVisibilityRevenue,
		permissions.revenueForecastVisible,
	]),
	weeklyPlanning: permissions.projectManager,
	resourceSchedule: Permission.all([
		permissions.viewer,
		permissions.resourceScheduleVisible,
	]),
	oldResourceSchedule: permissions.viewer,
	projectPlanner: permissions.viewer,
	projects: permissions.viewer,
	createProject: permissions.limitedAdmin,
	staff: permissions.limitedAdmin,
	staffRoles: permissions.admin,
	createStaff: permissions.admin,
	myTimesheets: permissions.noRestrictions,
	timesheetCalendar: permissions.noRestrictions,
	timesheetReports: permissions.noRestrictions,
	invoices: Permission.all([
		permissions.limitedAdmin,
		permissions.financialVisibilityRevenue,
	]),
	invoiceTemplates: Permission.all([
		permissions.limitedAdmin,
		permissions.financialVisibilityRevenue,
	]),
	invoiceSettings: permissions.admin,
	createInvoice: Permission.all([
		permissions.limitedAdmin,
		permissions.financialVisibilityRevenue,
	]),
	costCentres: permissions.admin,
	settings: permissions.admin,
	holidays: permissions.limitedAdmin,
	overheadExpenses: permissions.limitedAdmin,
	contacts: permissions.viewer,
	billing: permissions.admin,

	canEditRevenue: permissions.limitedAdmin,
	projectLevel: function (level, project) {
		return Permission.fromFunction(function (user) {
			return ruleLists[level].hasPermission(project, user);
		});
	},
	projectAdmin: projectAdmin,
	projectManager: projectManager,
	canEditProjectRevenue: projectAdmin,
	canEditProject: canEditProject,
	canEditProjectHours: canEditProject,
	canViewProject: function (project) {
		return permissions.projectLevel(PermissionLevel.view, project);
	},
	staffTable: Permission.fromFunction(
		(user) => user.permissions.isAtLeastLimitedAdmin
	),
	editContacts: Permission.fromFunction(
		(user) => user.permissions.isAtLeastLimitedAdmin
	),
	projectProfit: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (level === PermissionLevel.admin) {
				return true;
			} else if (
				(level === PermissionLevel.projectManager ||
					level === PermissionLevel.view) &&
				permissions.financialVisibilityRevenue.ok(user)
			) {
				return true;
			} else {
				return false;
			}
		});
	},
	projectFinancialsBudgets: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (
				level === PermissionLevel.admin &&
				permissions.financialVisibilityRevenue.ok(user)
			) {
				return OperationLevel.edit;
			} else if (
				(level === PermissionLevel.projectManager ||
					level === PermissionLevel.view) &&
				permissions.financialVisibilityRevenue.ok(user)
			) {
				return OperationLevel.view;
			} else {
				return false;
			}
		});
	},
	projectExpenseBudgets: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (
				level === PermissionLevel.admin &&
				permissions.financialVisibilityExpenses.ok(user)
			) {
				return OperationLevel.edit;
			} else if (
				(level === PermissionLevel.projectManager ||
					level === PermissionLevel.view) &&
				permissions.financialVisibilityExpenses.ok(user)
			) {
				return OperationLevel.view;
			} else {
				return false;
			}
		});
	},
	editProjectBudgets: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (level === PermissionLevel.admin) {
				return OperationLevel.edit;
			} else if (
				level === PermissionLevel.projectManager ||
				level === PermissionLevel.view
			) {
				return OperationLevel.view;
			} else {
				return false;
			}
		});
	},
	projectAllocations: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (level === PermissionLevel.admin) {
				return "expenses";
			} else if (
				level === PermissionLevel.projectManager ||
				level === PermissionLevel.view
			) {
				if (permissions.financialVisibilityRevenue.ok(user)) {
					return "expenses";
				} else {
					return "no-expenses";
				}
			} else {
				return false;
			}
		});
	},
	projectMilestones: function (project) {
		return Permission.fromFunction(function (user) {
			const level = getProjectPermissionLevel(project, user);
			if (level === PermissionLevel.admin) {
				return true;
			} else if (
				(level === PermissionLevel.projectManager ||
					level === PermissionLevel.view) &&
				permissions.financialVisibilityRevenue.ok(user)
			) {
				return true;
			} else {
				return false;
			}
		});
	},
	otherStaffTimesheets: permissions.limitedAdmin,
	canCreateProject: permissions.limitedAdmin,
	canSaveReports: permissions.projectManager,
	canEditStaff: permissions.admin,
	canEditAccountingSystemSettings: permissions.admin,
	canViewRevenue: permissions.financialVisibilityRevenue,
};

class RuleList {
	constructor(rules) {
		this.rules = rules;
	}

	hasPermission(ob, user) {
		for (let rule of this.rules) {
			const result = rule.hasPermission(ob, user);
			if (result != null) {
				return result;
			}
		}
		return false;
	}

	getRuleResults(ob, user) {
		let results = [];
		for (let rule of this.rules) {
			const result = rule.hasPermission(ob, user);
			results.push([rule, result]);
			if (result != null) {
				break;
			}
		}
		return results;
	}
}

class AdminRule {
	hasPermission(ob, user) {
		if (user.permissions.isAdmin) {
			return true;
		}
	}
}

class EverythingRule {
	constructor(level) {
		this.levelNum = getPermissionLevelNum(level);
	}

	hasPermission(ob, user) {
		if (
			user.permissions.items.some(
				(i) => i.object.isEverything && i.levelNum >= this.levelNum
			)
		) {
			return true;
		}
	}
}

class ProjectRule {
	constructor(level) {
		this.levelNum = getPermissionLevelNum(level);
	}

	hasPermission(ob, user) {
		for (let item of user.permissions.items) {
			if (item.object.projectId === ob.id) {
				return item.levelNum >= this.levelNum;
			}
		}
	}
}

class CostCentreRule {
	constructor(level) {
		this.levelNum = getPermissionLevelNum(level);
	}

	hasPermission(ob, user) {
		for (let item of user.permissions.items) {
			if (
				item.object.costCentreId === ob.costCentreId &&
				item.levelNum >= this.levelNum
			) {
				return true;
			}
		}
	}
}

// https://docs.google.com/document/d/1DEe0WrCTUb2mGDDN6dTTWbSD8YlNkAXcXy41YlN69OY/edit#heading=h.6hmtqe2odgkk
const levelRuleList = new RuleList([
	{
		hasPermission: function (ob, user) {
			for (let item of user.permissions.items) {
				if (
					item.object.projectId != null &&
					item.object.projectId === ob.id
				) {
					return item.level;
				}
			}
		},
	},
	{
		hasPermission: function (ob, user) {
			for (let item of user.permissions.items) {
				if (
					item.object.costCentreId != null &&
					item.object.costCentreId === ob.costCentreId
				) {
					return item.level;
				}
			}
		},
	},
	{
		hasPermission: function (ob, user) {
			const p = user.permissions.items.find((i) => i.object.isEverything);
			if (p != null) {
				return p.level;
			}
		},
	},
]);

export const ProjectPermissionChecker = class {
	constructor(user, projects) {
		this.isAdmin = user.isAdmin();
		if (!this.isAdmin) {
			this.projects = new Map(
				projects.map(function (p) {
					return [p.id, getProjectPermissionLevel(p, user)];
				})
			);
		}
	}

	checkProject(project) {
		if (this.isAdmin) {
			return PermissionLevel.admin;
		} else {
			return this.projects.get(project.id);
		}
	}

	checkCashFlowItem(cfi) {
		if (cfi.project == null) {
			// This is an organisation-level expense.
			return this.isAdmin;
		} else {
			return this.checkProject(cfi.project);
		}
	}
};

function getPermissionResult(perm, props) {
	const p = _.isFunction(perm) ? perm(props) : perm;
	return p.ok(props.user);
}

function resolvePermissions(permissionsDict, props) {
	return _.mapObject(permissionsDict, (v) => getPermissionResult(v, props));
}

export function requiresPermission(
	mainPermission,
	secondaryPermissions,
	component
) {
	/**
	 * ** Note that this will lose statics on the component.
	 *
	 * Creates a component that renders `component` if and only if `mainPermission`
	 * is satisfied.
	 *
	 * `permission` is either a `Permission` object or a function which when
	 * called with the element's `props` dict, returns a `Permission` object.
	 */
	if (mainPermission == null) {
		throw new Error("Nonexistent permission");
	}
	for (let k in secondaryPermissions) {
		if (secondaryPermissions[k] == null) {
			throw new Error("Nonexistent permission");
		}
	}

	let klass = CreateReactClass({
		propTypes: {
			user: PropTypes.object.isRequired,
		},

		render: function () {
			const permissionLevel = getPermissionResult(
				mainPermission,
				this.props
			);
			if (permissionLevel) {
				return React.createElement(component, {
					...this.props,
					permissionLevel: permissionLevel,
					...resolvePermissions(secondaryPermissions, this.props),
				});
			} else {
				return null;
			}
		},
	});
	klass.displayName = `requiresPermission(${component.displayName})`;
	return klass;
}

export function getProjectPermissionLevel(project, user) {
	return levelRuleList.hasPermission(project, user);
}

export function makeRuleList(level) {
	return new RuleList([
		new AdminRule(),
		new ProjectRule(level),
		new CostCentreRule(level),
		new EverythingRule(level),
	]);
}

const ruleLists = {
	[PermissionLevel.admin]: makeRuleList(PermissionLevel.admin),
	[PermissionLevel.projectManager]: makeRuleList(
		PermissionLevel.projectManager
	),
	[PermissionLevel.view]: makeRuleList(PermissionLevel.view),
	[PermissionLevel.timesheet]: makeRuleList(PermissionLevel.timesheet),
};

export const UserLevel = Enum([
	["admin", 5],
	["limitedAdmin", 4],
	["projectManager", 3],
	["viewer", 2],
	["timesheet", 1],
]);

export const PermissionsType = {
	default: () =>
		Permissions.fromJson({
			items: [
				{
					object: { item: "everything" },
					level: PermissionLevel.timesheet,
				},
			],
			financialsVisibility: FinancialsVisibility.none,
		}),
	fromJson: (ob) => Permissions.fromJson(ob),
	serialize: (ob) => ob.serialize(),
};

export const PermissionObject = class {
	constructor(options) {
		if (options != null) {
			this.item = options.item;
			this.costCentreId = options.costCentreId;
			this.projectId = options.projectId;
		} else {
			this.item = "everything";
		}
	}

	get isEverything() {
		return this.item === "everything";
	}

	get project() {
		throw new Error("deprecated");
	}

	get costCentre() {
		throw new Error("deprecated");
	}

	serialize() {
		return {
			...(this.item === "everything" ? { item: "everything" } : null),
			...(this.costCentreId != null
				? { costCentreId: this.costCentreId }
				: null),
			...(this.projectId != null ? { projectId: this.projectId } : null),
		};
	}
};

const PermissionObjectType = {
	fromJson: (ob) => new PermissionObject(ob),
	serialize: (ob) => ob.serialize(),
};

export let PermissionItem = makeRecordClass({
	object: PermissionObjectType,
	level: StringType({ defaultValue: PermissionLevel.view }),
});

Object.defineProperty(PermissionItem.prototype, "project", {
	get: function () {
		return this.object.project;
	},
});

Object.defineProperty(PermissionItem.prototype, "costCentre", {
	get: function () {
		return this.object.costCentre;
	},
});

Object.defineProperty(PermissionItem.prototype, "levelNum", {
	get: function () {
		return getPermissionLevelNum(this.level);
	},
});

PermissionItem.prototype.matchesProject = function (
	project,
	{ requireDirect = false } = {}
) {
	let matchesDirect =
		this.project != null && areSameDbObjects(this.project, project);
	if (requireDirect) {
		return matchesDirect;
	} else {
		return matchesDirect || this.matchesCostCentre(project.costCentre);
	}
};

PermissionItem.prototype.matchesCostCentre = function (costCentre) {
	return (
		this.item === "everything" ||
		(this.costCentre != null &&
			areSameDbObjects(costCentre, this.costCentre))
	);
};

PermissionItem.prototype.serialize = function () {
	return {
		object: this.object.serialize(),
		level: this.level,
	};
};
