import _ from 'underscore';
import Immutable from 'immutable'
import { generateUUID, capitaliseFirst } from "./utils.js";


function hasClassName(klass) {
  /**
   * Class decorator.
   */
  return class extends klass {
    getClassName() {
      return klass.name;
    }
  };
}


function hasClone(klass) {
  /**
   * Class decorator.
   */
  return class C extends klass {
    clone() {
      return new (this.constructor)();
    }
  };
}


function hasUUID(klass) {
  /**
   * Class decorator.
   */
  return class C extends klass {
    constructor({uuid} = {}) {
      super(...arguments);
      this.uuid = uuid || generateUUID();
    }

    /**
     * For `copy` and `clone` see comment for corresponding methods in `models.DataObject`.
     */

    copy({cloneIdentity = true} = {}) {
      let newOb = super.clone();
      if (cloneIdentity) {
        newOb.uuid = this.uuid;
      }
      return newOb;
    }

    clone() {
      return this.copy({cloneIdentity: true});
    }
  };
}


function isImmutable(props) {
  /**
   * Class decorator.
   */
  return function(klass) {
    class C extends klass {
      constructor(options = {}) {
        super(...arguments);
        let d = {};
        _.each(props, function(propDescriptor, propName) {
          if (options.hasOwnProperty(propName)) {
            d[propName] = options[propName];
          }
          else if (propDescriptor.hasOwnProperty('defaultValue')) {
            d[propName] = propDescriptor.defaultValue;
          }
        });
        this.data = Immutable.Map(d);
      }

      setData(map) {
        this.data = map;
        return this;
      }

      copy({cloneIdentity = true} = {}) {
        return super.copy().setData(cloneIdentity ? this.data : this.data.delete('uuid'));
      }

      clone() {
        return super.clone().setData(this.data);
      }

      set(props) {
        return super.clone().setData(this.data.merge(props));
      }
    }

    _.each(props, function(propDescriptor, propName) {
      Object.defineProperty(C.prototype, propName, {
        get: function() {
          return this.data.get(propName);
        }
      });

      C.prototype['set' + capitaliseFirst(propName)] = function(val) {
        return this.clone().setData(this.data.set(propName, val));
      };
    });

    return C;
  };
}


export function immutableClass(props) {
  /**
   * Class decorator.
   */
  return function(klass) {
    @isImmutable(props)
    @hasUUID
    @hasClone
    @hasClassName
    class C extends klass {
    }

    return C;
  };
}

