import React, { useEffect, useState } from "react";
import useVisible from "@21kb/react-page-visible-hook";
import {
	actions as authenticationActions,
	authenticationStore,
} from "./authenticationService.js";
import { Modal, ModalContent } from "./modal";
import { ErrorAlert, TriStateSaveButton } from "./widgets.js";
import classNames from "classnames";
import { observer } from "mobx-react";
import { loginModalStore } from "./LoginModalStore.js";
import { router } from "./router.js";
import PasswordInput from "./widgets/PasswordInput.js";
import { userStore } from "./user";
import axios from "axios";

export default observer((props) => {
	const { visibilityState } = useVisible();
	const [email, setEmail] = useState(authenticationStore.user?.email);
	const [password, setPassword] = useState(null);
	useEffect(() => {
		if (visibilityState === "visible") {
			if (userStore.user) {
				axios
					.post(
						process.env.REACT_APP_NODE_SERVER_URL +
							"/flags/has-flag",
						{
							email: userStore.user.email,
							organisationId: userStore.user.organisationId,
							flag: "has-migrated",
						}
					)
					.then((response) => {
						if (response.data) {
							window.location.replace(
								process.env.REACT_APP_NEW_CLIENT_URL
							);
						}
					});
			}
			loginModalStore.checkLogin();
		}
	}, [visibilityState]);
	if (loginModalStore.needsLogin) {
		return (
			<Modal style={{ zIndex: 999999 }}>
				<ModalContent
					header="Login Expired"
					width="32em"
					bodyStyles={{ padding: "3em" }}
				>
					<div className="form-group">
						<div className="input-group input-group-lg">
							<input
								style={{ width: "100%" }}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								type="email"
								placeholder="Your Email"
							/>
						</div>
					</div>

					<div className="form-group">
						<div className="input-group input-group-lg">
							<PasswordInput
								style={{ width: "100%" }}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Your Password"
							/>
						</div>
					</div>

					{loginModalStore.status === "error" ? (
						<ErrorAlert>
							{`Sorry, we couldn't log you in with those details. Please try again.`}
						</ErrorAlert>
					) : null}
					<button
						onClick={() => loginModalStore.login(email, password)}
						className="btn btn-block btn-lg btn-primary"
						disabled={loginModalStore.status === "loading"}
					>
						<i
							className={classNames(
								"fa fa-fw",
								loginModalStore.status === "loading"
									? "fa-spinner fa-spin"
									: "fa-user"
							)}
						/>{" "}
						<small>Sign In</small>
					</button>
					<div style={{ paddingTop: "2em", textAlign: "center" }}>
						<a
							className="forgot"
							href="javascript:void(0)"
							onClick={() =>
								router.history.replace({
									pathname: "/user/forgotten-password",
								})
							}
						>
							Forgotten Your Password?
						</a>
					</div>
				</ModalContent>
			</Modal>
		);
	} else {
		return null;
	}
});
