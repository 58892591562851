import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from './coincraftFlux.js';
import { store as milestonesStore, actions as milestonesActions } from './milestones/flux.js';
import { dateConverter } from './models.js';
import { PermissionLevel, ProjectPermissionChecker } from './models/permissions.js';
import { sum, groupBy, compareObjectsWithNames, formatNumber0,
  formatNumber2, isNumber, objectToQueryString } from './utils.js';
import { FormSection, FormRow, FormLabelInline } from './forms.js';
import { getProjectPhases, ProjectPhaseSelector } from './widgets/ProjectPhaseSelector.js';
import { Modal, ModalContent } from './modal.js';
import { organisationStore } from './organisation.js';
import { userStore } from './user.js';
import { permissions } from './models/permissions.js';
import classNames from 'classnames';
import { DateValue, NumberInputContainer, EditItemControls } from './widgets/generic.js';
import { CurrencyIcon } from './widgets/CurrencyIcon.js';
import { ProjectSelector } from './widgets/ProjectSelector.js';
import PropTypes from "prop-types";


export var Feed = CreateReactClass({
  propTypes: {
    firstSelectedMonth: PropTypes.object.isRequired,
    lastSelectedMonth: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    showFinancials: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([milestonesStore, organisationStore, userStore], function() {
      return {
        isReady: milestonesStore.isReady,
        graphMode: milestonesStore.graphMode,
        cashFlow: milestonesStore.cashFlow,
        feedSaveError: milestonesStore.feedSaveError,
        modals: milestonesStore.modals,
        linkAllocationsToMilestones: milestonesStore.linkAllocationsToMilestones,
        checker: new ProjectPermissionChecker(this.props.user, organisationStore.getVisibleProjects()),
        currencyFormatter: organisationStore.organisation.currencyFormatter,
        user: userStore.user
      };
    })
  ],

  getInitialState: function() {
    return {
      groupBy: 'project',
    };
  },

  render: function() {
    if (!this.state.isReady) {
      return null;
    }

    return <div>
      {this.state.modals.map(function(modal, i) {
        return <Modal key={i}>
          {modal.type === 'addAllocation' ?
            <ModalContent header="Add allocation" width="40em">
              <AddAllocationForm modal={modal} />
            </ModalContent>
          : null}
        </Modal>;
      })}
      <div className="dashboard-widget info-panel" style={{fontSize: '12px'}}>
        <div className="flexbox-container flex-align-items-center" style={{borderBottom: '1px solid #ccc'}}>
          <div className="flex-0-0-auto" style={{fontSize: 13}}>
            <button
                className="btn btn-default feed__previous-month-button"
                onClick={this.handlePreviousMonthButtonClick}
                style={{borderRadius: 0, border: 0, borderRight: 'solid 1px #ccc'}}>
              <i className="fa fa-chevron-left"  style={{margin: 0}}/>
            </button>
          </div>
          <h3
              className="feed__month-heading flex-1-1-auto"
              style={{textAlign: 'center', margin: 0, fontSize: 19}}>
            {this.getMonthHeading()}
          </h3>
          <div className="flex-0-0-auto" style={{fontSize: 13}}>
            <button
                className="btn btn-default feed__next-month-button"
                onClick={this.handleNextMonthButtonClick}
                style={{borderRadius: 0, border: 0, borderLeft: 'solid 1px #ccc'}}>
              <i className="fa fa-chevron-right" style={{margin: 0}} />
            </button>
          </div>
        </div>
        <div>
          <table className="input-table" style={{width: '100%'}}>
            <tbody>
              <tr>
                <td style={{borderRight: 'solid 1px #ccc'}}>
                  First month
                </td>
                <td className="date cell-input-field">
                  <DateValue
                    className="feed__first-month-input"
                    value={this.props.firstSelectedMonth}
                    action={milestonesActions.feedSetFirstMonth}
                    isEditable={true}
                    dropLeft={true}
                    initialView="year"
                    format="MMM yyyy"
                  />
                </td>
              </tr>
              <tr>
                <td style={{borderRight: 'solid 1px #ccc'}}>
                  Last month
                </td>
                <td className="date cell-input-field">
                  <DateValue
                    className="feed__last-month-input"
                    value={this.props.lastSelectedMonth}
                    action={milestonesActions.feedSetLastMonth}
                    isEditable={true}
                    dropLeft={true}
                    initialView="year"
                    format="MMM yyyy"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {this.state.graphMode !== 'actuals' ?
          <div>
            <a
                style={{padding: '5px 0', display: 'block'}}
                className="feed__export-button button-100"
                href={this.getExportUrl()}>
              <i className="fa fa-external-link" />
              Export to Excel
            </a>
          </div>
        : null}
      </div>

      {this.props.selectedDisplay === 'revenue' ?
        this.renderGroupedByProject()
      : this.renderGroupedByStaffMember()
      }
    </div>;
  },

  getMonthHeading: function() {
    if (this.props.firstSelectedMonth == null) {
      return null;
    }

    let first = this.props.firstSelectedMonth;
    let last = this.props.lastSelectedMonth;

    if (first.isSame(last)) {
      return first.format("MMMM YYYY");
    }
    else if (first.year() === last.year()) {
      return `${first.format("MMM")} - ${last.format("MMM")} ${first.format("YYYY")}`;
    }
    else {
      return `${first.format("MMM 'YY")} - ${last.format("MMM 'YY")}`;
    }
  },

  getExportUrl: function() {
    let params = {
      startDate: this.props.firstSelectedMonth.format("YYYY-MM-DD"),
      endDate: this.props.lastSelectedMonth.format("YYYY-MM-DD")
    };
    let mode = (this.props.selectedDisplay === 'utilisation') ? 'allocation' : 'revenue';
    return `/projections/${mode}?${objectToQueryString(params)}`;
  },

  renderGroupedByProject: function() {
    let self = this;
    const firstSelectedMonth = dateConverter.momentToInt(this.props.firstSelectedMonth);
    const lastSelectedMonth = dateConverter.momentToInt(this.props.lastSelectedMonth);
    const endOfLastMonth = dateConverter.endOfMonthOffset(lastSelectedMonth);

    let visibleCashFlowItems = _.flatten(
      this.state.cashFlow.map(function(m) {
        if (!(m.date >= firstSelectedMonth && m.date <= endOfLastMonth)) {
          return [];
        }
        else {
          return m.cashFlowItems.filter(cfi => self.state.checker.checkCashFlowItem(cfi));
        }
      })
    );

    /**
     * The problem of the user setting a milestone to have zero income causing
     * the milestone to suddenly disappear has moved here, which is why we use
     * this convoluted check to define "income items" rather than simply doing
     * `cfi.fee > 0`.  Would be better to have this explicitly encoded in
     * the model.
     */

    let visibleIncomeItems = visibleCashFlowItems.filter(function(cfi) {
      return cfi.project != null && (cfi.fee > 0 || cfi.spend === 0 || cfi.percent != null);
    });
    let visibleExpenses = visibleCashFlowItems.filter(cfi => cfi.spend > 0);
    let totalIncome = sum(visibleCashFlowItems.map(m => m.fee));
    let totalExpenses = sum(visibleExpenses.map(e => e.spend));
    let total = totalIncome - totalExpenses;
    let percentProfit = (total / totalIncome) * 100;

    let projectGroups = groupBy(visibleIncomeItems, m => m.project, p => p.id);
    projectGroups.sort((a, b) => compareObjectsWithNames(a.grouper, b.grouper));
    projectGroups.forEach(function(projectGroup) {
      if (firstSelectedMonth !== lastSelectedMonth) {
        // Group by phase.
        projectGroup.phaseGroups = groupBy(projectGroup.items, m => m.phase, p => p != null ? p.id : 0).map(function(p) {
          return {
            phase: p.grouper,
            title: p.items[0].title, // All items should have the same title.
            isEditable: false,
            fee: sum(p.items.map(i => i.fee)),
            percent: Math.max(...p.items.map(i => i.percent))
          };
        });
      }
      else {
        projectGroup.phaseGroups = projectGroup.items;
      }
      projectGroup.phaseGroups.sort((a, b) => compareObjectsWithNames(a.phase, b.phase));
    });

    let expenseGroupsLookup = _.groupBy(visibleExpenses, e => e.title);
    let expenseGroups = [];
    _.each(expenseGroupsLookup, function(expenses, title) {
      expenseGroups.push({
        title: title,
        spend: sum(expenses.map(e => e.spend))
      });
    });

    return (
      <div>
        {this.props.showFinancials ?
          <div className="dashboard-widget info-panel feed__profit-panel" style={{padding: 0}}>
            {isNumber(percentProfit) ?
              <h3 className="title">{`${Math.round(percentProfit)}% Profit`}</h3>
            : null}
            <div style={{textAlign:'center', fontSize:'2.5em', padding:'.75em'}}>
              {this.state.currencyFormatter.format(total)}
            </div>
          </div>
        : null}

        <div className="feed__revenue-widget">
          <div className="dashboard-widget info-panel">
            <h3 className="title">Revenue</h3>
            <div
                className="feed__revenue-widget__revenue-total"
                style={{
                  textAlign: 'center',
                  fontSize: '2.5em',
                  padding: '.75em',
                  borderBottom: '1px #ccc solid'
                }}>
              {this.state.currencyFormatter.format(totalIncome)}
            </div>

            <div>
              <table className="input-table">
                {projectGroups.map(function(group, i) {
                  return (
                    <tbody className="feed_revenue-widget__project" style={{marginBottom: 12}} key={i}>
                      <tr>
                        <td colSpan="2" style={{width:'66%', textAlign: 'left', padding: '1em 0 .5em 1em'}}>
                          <strong className="feed_revenue-widget__project__title">{group.grouper.getTitle()}</strong>
                        </td>
                        <td style={{padding: '1em 0 .5em 1em'}}>
                          <strong>
                            {self.state.currencyFormatter.format(sum(group.phaseGroups.map(m => m.fee)))}
                          </strong>
                        </td>
                      </tr>
                      {group.phaseGroups.map(function(cashFlowItem, i) {
                        const feeInputId = `project_${group.grouper.id}_milestone_${i}`;
                        const displayFee = formatNumber2(cashFlowItem.fee);
                        const displayPercent = cashFlowItem.percent != null ? formatNumber2(cashFlowItem.percent) : null;

                        const isEditable = (cashFlowItem.isEditable
                          && permissions.canEditProject(cashFlowItem.project).ok(self.state.user)
                        );

                        return (
                          <tr className="feed_revenue-widget__project__row" key={i}>
                            <td
                                className="feed_revenue-widget__project__row__title"
                                style={{borderRight: 'solid 1px #ccc', padding: 0}}>
                              {cashFlowItem.invoice != null ?
                                <span style={{border: 'solid 1px #666', color: '#666', padding: 1}}>
                                  <small>INV </small>
                                </span>
                              : null}
                              {cashFlowItem.isChangeLogItem ?
                                <span style={{border: 'solid 1px #666', color: '#666', padding: 1}}>
                                  <small>C </small>
                                </span>
                              : null}
                              {cashFlowItem.title}
                            </td>

                            <td
                                className={isEditable ? "percent cell-input-field" : "percent"}
                                style={{
                                  borderRight: 'solid 1px #ccc',
                                  width: '24%',
                                  padding: 0,
                                  textAlign: 'right',
                                  paddingRight: '1em'
                                }}>

                              {isEditable ?
                                <div>
                                  <NumberInputContainer
                                    className="feed_revenue-widget__project__row__percent"
                                    value={cashFlowItem.percent}
                                    formatFunc={n => n != null ? formatNumber2(n) : ''}
                                    style={{
                                      width: '3em',
                                      padding: 0
                                    }}
                                    executeActionOn='blur'
                                    onBlur={function(percent) {
                                      // Use `onBlur` rather than `onChange` because of the logic that sets
                                      // the value to the previous milestone's percentage if the user tries to
                                      // set a value lower than that.
                                      //
                                      // Eg. if this milestone's percentage is 50, the previous milestone is 30,
                                      // and the user wants to change this milestone to 40, and we used `onChange`,
                                      // then we would call `setMilestonePercent` with a value of 4 before the user
                                      // could type the zero.
                                      milestonesActions.setMilestonePercent(cashFlowItem.milestone, percent);
                                    }}
                                  />
                                  <span> %</span>
                                </div>
                              :
                                <div>
                                  {/* Aid for testing */}
                                  <input
                                    type="hidden"
                                    className="feed_revenue-widget__project__row__percent"
                                    value={displayPercent}
                                  />
                                  <span style={{display: 'inline-block', width: '3em'}}>
                                    {cashFlowItem.percent != null ? displayPercent : <em>N/A</em>}
                                  </span>
                                  <span> %</span>
                                </div>
                              }
                            </td>
                            <td
                              className={isEditable ? "dollar cell-input-field" : "dollar"}
                              style={{padding:0}}
                            >
                              {/* Aid for testing */}
                              <input
                                type="hidden"
                                className="feed_revenue-widget__project__row__fee"
                                value={cashFlowItem.fee}
                              />

                              <label
                                  htmlFor={feeInputId}
                                  style={{height: '100%', margin: 0, marginTop: 0, paddingLeft: '0.3em'}}
                                  className="flexbox-container flex-align-items-center">
                                <div className="flex-0-0-auto" style={{textAlign: 'right'}}>
                                  <CurrencyIcon />
                                </div>
                                <div className="flex-1-1-auto">
                                  {isEditable ?
                                    <NumberInputContainer
                                      id={feeInputId}
                                      value={cashFlowItem.fee}
                                      onChange={function(fee) {
                                        milestonesActions.setMilestoneFee(cashFlowItem.milestone, fee);
                                      }}
                                      style={{
                                        display: 'inline-block',
                                        width: '100%',
                                        paddingRight: '0.8em'
                                      }}
                                    />
                                  :
                                    <span
                                        style={{
                                          display: 'inline-block',
                                          width: '100%',
                                          textAlign: 'right',
                                          paddingRight: '0.8em'
                                        }}>
                                      {displayFee}
                                    </span>
                                  }
                                </div>
                              </label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>

        {this.props.showFinancials ?
          <div className="dashboard-widget info-panel feed__expenses-panel">
            <h3 className="title">
              Expenses
            </h3>
            <div style={{textAlign:'center', fontSize:'2.5em', padding:'.75em', borderBottom:'1px #ccc solid'}}>
              {self.state.currencyFormatter.format(totalExpenses)}
            </div>
            <table className="input-table" style={{width: '100%'}}>
              <tbody>
                {expenseGroups.map(function(e, i) {
                  return <tr key={i}>
                    <td style={{borderRight: 'solid 1px #ccc'}}>
                      {e.title}
                    </td>
                    <td className="dollar">
                      {self.state.currencyFormatter.format(e.spend)}
                    </td>
                  </tr>;
                })}
              </tbody>
            </table>
          </div>
        : null}
      </div>
    );
  },

  renderGroupedByStaffMember: function() {
    let self = this;
    const firstSelectedMonthOffset = dateConverter.momentToInt(this.props.firstSelectedMonth);
    const lastSelectedMonthOffset = dateConverter.momentToInt(this.props.lastSelectedMonth);
    const isMultiMonth = (firstSelectedMonthOffset !== lastSelectedMonthOffset);

    let startDate, endDate;
    if (!isMultiMonth) {
      startDate = firstSelectedMonthOffset;
      endDate = dateConverter.endOfMonthOffset(startDate);
    }

    let {
      allocationGroups,
      staffMemberAvailableHours,
      staffMemberAllocatedHours,
      totalAvailableHours,
      totalAllocatedHours
    } = milestonesStore.getFeedGroupedByStaffMember(firstSelectedMonthOffset, lastSelectedMonthOffset);

    return <div>
      <div className="dashboard-widget info-panel">
        <h3 className="title">
          Total Utilisation
        </h3>
        <div style={{textAlign:'center', fontSize:'2.5em', padding:'.75em'}}>
          {totalAvailableHours > 0 ?
            <span>{formatNumber0(totalAllocatedHours / totalAvailableHours * 100)}%</span>
          : <span>0%</span>
          }
        </div>
        <div style={{textAlign: 'center', borderTop: 'solid 1px #ccc', borderBottom: 'solid 1px #aaa', padding: '0.2em'}}>
          {formatNumber2(totalAllocatedHours)} / {formatNumber2(totalAvailableHours)} hours
        </div>
      </div>

      {allocationGroups.map(function({staffMember, projects}, staffMemberIndex) {
        let numHoursAllocated = staffMemberAllocatedHours[staffMember.id];
        let numHoursAvailable = staffMemberAvailableHours[staffMember.id];

        return <div className="dashboard-widget info-panel feed__staff-member" key={staffMemberIndex}>
          <h3 className="title feed__staff-member__name">
            {staffMember.getFullName()}
          </h3>
          {numHoursAvailable > 0 ?
            <div
                className="feed__staff-member__total-percent"
                style={{textAlign:'center', fontSize:'2.5em', padding:'.75em'}}>
              {formatNumber0(numHoursAllocated / numHoursAvailable * 100)}%
            </div>
          : null}
          <div style={{textAlign: 'center', borderTop: 'solid 1px #ccc', borderBottom: 'solid 1px #aaa', padding: '0.2em'}}>
            {numHoursAvailable > 0 ?
              <span>{formatNumber2(numHoursAllocated)} / {formatNumber2(numHoursAvailable)} hours</span>
            : <span>{formatNumber2(numHoursAllocated)} hours allocated</span>
            }
          </div>

          <div className="input-table">
            {projects.map(function({project, phases}, projectIndex) {
              const isEditable = !isMultiMonth && self.state.checker.checkProject(project);

              return <div className="feed__staff-member__project" key={projectIndex}>
                <div style={{borderBottom: '1px solid #ccc'}}>
                  <div style={{display: 'inline-block', width: '66%', textAlign: 'left', padding: '1em 0 .5em 1em'}}>
                    <strong className="feed__staff-member__project__name">
                      {project.getTitle()}
                    </strong>
                  </div>
                  <div style={{display: 'inline-block', width: '33%', textAlign: 'center', padding: '1em 0 .5em 1em'}}>
                    <strong>
                      {formatNumber2(sum(phases.map(p => p.hours)))}
                    </strong>
                  </div>
                </div>
                {phases.map(function({phase, hours}, phaseIndex) {
                  return (
                    <div
                        className="feed__staff-member__project__phase"
                        style={{borderBottom: '1px solid #ccc'}}
                        key={phaseIndex}>
                      <div
                          className="feed__staff-member__project__phase__name"
                          style={{
                            display: 'inline-block',
                            width: (numHoursAvailable > 0) ? '33%' : '66%',
                            borderRight: 'solid 1px #ccc',
                            padding: 0,
                            textAlign: 'center'
                          }}>
                        {phase.getTitle()}
                      </div>
                      {numHoursAvailable > 0 ?
                        <div
                            className={classNames("percent", {"cell-input-field": isEditable})}
                            style={{
                              borderRight: 'solid 1px #ccc',
                              width: '33%',
                              padding: 0,
                              textAlign: 'right',
                              paddingRight: '1em',
                              display: 'inline-block'
                            }}>
                          <div>
                            <NumberInputContainer
                              isEditable={isEditable}
                              className="feed__staff-member__project__phase__percent"
                              value={hours / numHoursAvailable * 100}
                              formatFunc={n => n != null ? formatNumber2(n) : ''}
                              style={{
                                width: '3em',
                                padding: 0
                              }}
                              onChange={function(percentText) {
                                milestonesActions.setFeedStaffMemberPercent(
                                  project,
                                  phase,
                                  staffMember,
                                  startDate,
                                  endDate,
                                  parseFloat(percentText)
                                );
                              }}
                            />
                            <span> %</span>
                          </div>
                        </div>
                      : null}
                      <div
                          className={classNames("dollar", {"cell-input-field": isEditable})}
                          style={{padding:0, width: '33%', display: 'inline-block'}}>
                        <NumberInputContainer
                          isEditable={isEditable}
                          className="feed__staff-member__project__phase__hours"
                          value={hours}
                          formatFunc={n => n != null ? formatNumber2(n) : ''}
                          onChange={function(hoursText) {
                            milestonesActions.setFeedStaffMemberHours(
                              project,
                              phase,
                              staffMember,
                              startDate,
                              endDate,
                              hoursText
                            );
                          }}
                          maxLength={11}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>;
            })}
            {!isMultiMonth ?
              <div style={{marginTop: '1em', padding: '0.5em'}}>
                <button
                    className="btn btn-sm btn-default feed__staff-member__add-allocation-button"
                    onClick={function() { self.add(staffMember); }}>
                  + Add allocation
                </button>
              </div>
            : null}
          </div>
        </div>;
      })}
    </div>;
  },

  handlePreviousMonthButtonClick: function() {
    milestonesActions.feedPreviousMonth();
  },

  handleNextMonthButtonClick: function() {
    milestonesActions.feedNextMonth();
  },

  add: function(staffMember) {
    // This should only be called if we have a single month selected.
    milestonesActions.feedStaffMemberAddAllocation(
      staffMember,
      dateConverter.momentToInt(this.props.firstSelectedMonth)
    );
  }
});


var AddAllocationForm = CreateReactClass({
  propTypes: {
    modal: PropTypes.shape({
      staffMember: PropTypes.object.isRequired,
      month: PropTypes.number.isRequired
    }).isRequired
  },

  getInitialState: function() {
    return {
      project: null,
      phase: null
    };
  },

  render: function() {
    const rowHeight = '3.5em';
    const labelWidth = '6em';

    let phasesWithAllocations = new Set();
    milestonesStore.eachItem(
      null,
      null,
      null,
      this.props.modal.staffMember,
      this.props.modal.month,
      dateConverter.endOfMonthOffset(this.props.modal.month),
      function(project, phase, milestone, staffMember, hours) {
        phasesWithAllocations.add(phase);
      }
    );

    let phaseOptions;
    if (this.state.project != null) {
      phaseOptions = getProjectPhases({
        project: this.state.project,
        selectedPhase: this.state.phase,
        allowNull: false,
        organisationStore: organisationStore
      });
      for (let o of phaseOptions) {
        if (phasesWithAllocations.has(o.value)) {
          o.disabled = true;
        }
      }
    }

    const selectableProjects = organisationStore.getSelectableProjects();
    const projectsFullyAllocated = selectableProjects.filter(function(p) {
      return _.all(p.getVisiblePhases().map(pp => phasesWithAllocations.has(pp)));
    });

    const monthName = dateConverter.intToMoment(this.props.modal.month).format("MMMM YYYY");

    if (projectsFullyAllocated.length < selectableProjects.length) {
      return <div className="feed-add-allocation-form">
        <p>
          Adding a new allocation for {this.props.modal.staffMember.getFullName()}
          {' for'} {monthName}.
        </p>
        <FormSection>
          <FormRow style={{height: rowHeight}}>
            <FormLabelInline style={{width: labelWidth, verticalAlign: 'middle'}}>
              Project
            </FormLabelInline>
            <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
              <ProjectSelector
                value={this.state.project}
                onChange={this.handleProjectChange}
                getDisabledProjects={() => projectsFullyAllocated}
                disabledOptionTooltip="This staff member already has an allocation for all this project's phases in this month"
                requirePermissionLevel={PermissionLevel.projectManager}
              />
            </div>
          </FormRow>
          <FormRow style={{height: rowHeight}}>
            <FormLabelInline style={{width: labelWidth, verticalAlign: 'middle'}}>
              Phase
            </FormLabelInline>
            <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
              {this.state.project == null ?
                <span className="disabled-text">
                  Please select a project.
                </span>
              :
                <ProjectPhaseSelector
                  options={phaseOptions}
                  phase={this.state.phase}
                  onChange={this.handlePhaseChange}
                  getObjectLabel={function(o) {
                    return <div title={o.disabled ? "This staff member already has an allocation for this phase in this month" : null}>
                      {o.label}
                    </div>;
                  }}
                />
              }
            </div>
          </FormRow>
        </FormSection>
        <EditItemControls
          objectTypeName='allocation'
          showCancelButton={true}
          onSave={this.handleSaveButtonClick}
          onCancel={this.handleCancelButtonClick}
          isSaveButtonDisabled={this.state.project == null || this.state.phase == null}
        />
      </div>;
    }
    else {
      return <div>
        <p style={{padding: '1.5em'}}>
          {this.props.modal.staffMember.getFullName()} is already
          allocated to all your organisation's phases in {monthName}!
        </p>
        <p style={{padding: '0 1.5em', textAlign: 'right'}}>
          <button
              className="btn btn-large btn-default"
              onClick={this.handleCancelButtonClick}>
            Close
          </button>
        </p>
      </div>;
    }
  },

  handleProjectChange: function(project) {
    this.setState({project: project});
  },

  handlePhaseChange: function(phase) {
    this.setState({phase: phase});
  },

  handleSaveButtonClick: function() {
    milestonesActions.feedStaffMemberAllocationFormSave(this.props.modal, this.state.phase);
  },

  handleCancelButtonClick: function() {
    milestonesActions.feedStaffMemberAllocationFormCancel(this.props.modal);
  }
});
