import apiRequest from "../apiRequest.js";

var RSVP = require("rsvp");
var myHttp = require("../myHttp.js").myHttp;


export const Collection = class {
			constructor(options) {
				this.klass = options.klass;
				this.url = options.url;
				this.params = options.params || {};
			}

			getAllRaw() {
				var deferred = RSVP.defer();
				apiRequest({
					url: this.url,
					method: "get",
					data: this.params,
					success: data => deferred.resolve(data.objects)
				});
				return deferred.promise;
			}

			getAll() {
				var klass = this.klass;
				return this.getAllRaw().then(function(objects) {
					return objects.map(function(ob) {
						return new klass(ob);
					});
				});
			}
		};


