import React from 'react';
import CreateReactClass from 'create-react-class';
import { AccountSelectors } from './AccountSelectors.js';
import PropTypes from "prop-types";


export var MyobSettings = CreateReactClass({
  propTypes: {
    data: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  },

  render: function() {
    return <div>
      <div>
        <AccountSelectors
          accountingSystemShortName="MYOB"
          data={this.props.data}
          settings={this.props.settings}
          onChange={(settings) => this.props.onChange(settings)}
        />
      </div>
    </div>;
  }
});

