import moment from 'moment';
import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { DropdownList, DateTimePicker } from 'react-widgets';
import { middleOfThree, mergeProps, formatPercentage0 } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { organisationStore } from "../organisation.js";
import { userStore } from '../user/flux.js';
import { actionMixin, buttons } from './utils.js';
import { Checkbox } from "./Checkbox.js";
import { ConfirmableDeleteButton } from "./ConfirmableDeleteButton.js";
import PropTypes from "prop-types";
export { NumberInputContainer } from "./NumberInputContainer.js";
export { AutosizeNumber } from "./AutosizeNumber.js";
export { FilterTextBox } from "./FilterTextBox.js";
export { LoadingSpinner } from "./LoadingSpinner.js";
export { ConfirmableDeleteButton } from "./ConfirmableDeleteButton.js";
export { Checkbox } from "./Checkbox.js";


export var RadioButton = CreateReactClass({
  /**
    <RadioButton
      valueLink={this.linkState('variable')}
      identifier="value1"
    />

    is a shortcut for

    <RadioButton
      value={this.state.variable === 'value1'}
      onChange={function(checked) {
        if (checked) {
          self.setState({variable: 'value1'});
        }
      }}
    />
  */

  propTypes: {
    label: PropTypes.node,

    value: PropTypes.bool,

    // `onChange` and `onSelected` are identical; `onSelected` is a better name
    // but `onChange` still exists for backward compatibility.
    onChange: PropTypes.func,
    onSelected: PropTypes.func,

    valueLink: PropTypes.object,
    identifier: PropTypes.any,

    className: PropTypes.string,
    name: PropTypes.string,

    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      className: "",
      disabled: false
    };
  },


  getInitialState: function() {
    return {};
  },

  render: function() {
    let value = this.props.valueLink != null ?
      this.props.valueLink.value === this.props.identifier
    : this.props.value;

    //TODO-project_architect make this accessible
    return (
      <label className={`coincraft-radio-button ${this.props.className}`}>
        <div className={classNames('coincraft-radio-button__square', {checked: value})}>
          <input className="coincraft-radio-button__input"
            type="radio"
            name={this.props.name}
            checked={value}
            onChange={this.handleChange}
            required={true}
          />
          <ins className="coincraft-radio-button__helper" />
        </div>
        {this.props.label}
      </label>
    );
  },

  handleChange: function(event) {
    if (!this.props.disabled) {
      if (this.props.valueLink != null) {
        this.props.valueLink.requestChange(this.props.identifier);
      }
      else {
        if (this.props.onChange != null) {
          this.props.onChange(event.target.checked, event);
        }
        if (this.props.onSelected != null) {
          this.props.onSelected(event.target.checked, event);
        }
      }
    }
  }
});


export var MySelect2 = CreateReactClass({
  propTypes: {
    isEditable: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: PropTypes.object,
    options: PropTypes.array,
    selectProps: PropTypes.object,
    isOptionDisabled: PropTypes.func,
    getObjectLabel: PropTypes.func,
    getObjectValue: PropTypes.func,
    dropLeft: PropTypes.bool,
    style: PropTypes.object,
    groupBy: PropTypes.func
  },

  getDefaultProps: function() {
    return {
      isEditable: true,
      selectProps: {},
      isOptionDisabled: function() { return false; },
      getObjectLabel: function(object) { return object.name; },
      onChange: function() { },
      dropLeft: false
    };
  },

  render: function() {
    let {
		value,
		onChange: _onChange,
		valueLink,
		options,
		selectProps,
		isOptionDisabled,
		getObjectLabel,
		getObjectValue,
		dropLeft,
		className,
		outerClassName,
		style,
		isEditable,
		groupBy,
		...props
	} = this.props;

    value = valueLink != null ? valueLink.value : value;
    if (getObjectValue != null) {
      value = _.find(options, o => getObjectValue(o) === value);
    }

    if (!isEditable) {
      return <span>{getObjectLabel(value)}</span>;
    }

    let component = CreateReactClass({
      render: function() {
        return getObjectLabel(this.props.item);
      }
    });

    let {className: selectClassName, ...remainingSelectProps} = selectProps || {};

    className = classNames(
      className,
      dropLeft ? 'rw-dropleft' : null,
      selectClassName
    );

    return (
		<div style={{ display: "inline-block", ...style }} className={outerClassName} >
			<DropdownList
				value={value}
				onChange={this.handleChange}
				data={options}
				disabled={options.filter(o => isOptionDisabled(o))}
				valueComponent={component}
				itemComponent={component}
				className={className}
				groupBy={groupBy}
				{...props}
				{...remainingSelectProps}
			/>
		</div>
	);
  },

  handleChange: function(option) {
    var value = this.props.getObjectValue != null ? this.props.getObjectValue(option) : option;
    if (this.props.valueLink != null) {
      this.props.valueLink.requestChange(value);
    }
    else {
      this.props.onChange(value);
    }
  }
});


export var BasicMySelect2 = CreateReactClass({
  propTypes: {
    isEditable: PropTypes.bool,
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  },

  getDefaultProps: function() {
    return {
      isEditable: true
    };
  },

  render: function() {
    let {value, options, onChange, ...props} = this.props;

    return <MySelect2
      value={value}
      options={options}
      getObjectLabel={o => <span>{o.label}</span>}
      getObjectValue={o => o.value}
      isOptionDisabled={o => o.disabled}
      onChange={onChange}
      {...props}
    />;
  }
});




export var SmallDeleteButton = CreateReactClass({
  propTypes: {
    className: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      className: ''
    };
  },

  render: function() {
    var { className, ...props } = this.props;
    return (
      <button className={classNames('btn', 'btn-default', 'btn-sm', 'delete-button', className)} {...props}>
        <i className="fa fa-times" style={{marginRight: 0}} />
      </button>
    );
  }
});




export var CurrencyValue = CreateReactClass({
  propTypes: {
    value: PropTypes.any,
    onChange: PropTypes.func,
    isEditable: PropTypes.bool,
    nullReadOnlyValue: PropTypes.any,
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        currencyFormatter: organisationStore.organisation.currencyFormatter
      };
    })
  ],

  render: function() {
    let { isEditable, nullReadOnlyValue, value, ...props } = this.props;

    if (isEditable) {
      let mergedProps = mergeProps(
        {style: {display: 'inline-block', textAlign: 'right'}},
        props
      );

      return <div {...mergedProps}>
        <div className="flexbox-container flex-align-items-center">
          <div className="flex-0-0-auto">
            {this.state.currencyFormatter.symbol}
          </div>
          <div className="flex-1-1-auto">
            <TextValue
              isEditable={isEditable}
              value={value}
              style={{width: '100%', textAlign: 'right'}}
            />
          </div>
        </div>
      </div>;
    }
    else {
      let mergedProps = mergeProps(
        {style: {display: 'inline-block', textAlign: 'right'}},
        props
      );
      return <div {...mergedProps}>
        {value == null && nullReadOnlyValue != null ?
          nullReadOnlyValue
        : this.state.currencyFormatter.format(value)
        }
      </div>;
    }
  }
});



export var TextValue = CreateReactClass({
  propTypes: {
    value: PropTypes.any,
    onChange: PropTypes.func,
    isEditable: PropTypes.bool,
    isValid: PropTypes.bool,
    nullReadOnlyValue: PropTypes.any,
  },

  getDefaultProps: function() {
    return {
      isEditable: true,
      isValid: true,
    };
  },

  render: function() {
    let { isEditable, isValid, nullReadOnlyValue, value, style, ...props } = this.props;

    const errorStyles = {
      color: '#dc2d11',
      border: '2px solid #dc2d11',
    };

    if (isEditable) {
      return (
        <input
          type="text"
          value={value != null ? value : ''}
          style={{
            ...(!isValid ? errorStyles : {}),
            ...style
          }}
          {...props}
        />
      );
    }
    else {
      if (props.value == null && nullReadOnlyValue != null) {
        if (_.isString(nullReadOnlyValue)) {
          return <span>{nullReadOnlyValue}</span>;
        }
        else {
          return nullReadOnlyValue;
        }
      }
      else {
        return <span>{value}</span>;
      }
    }
  }
});


export var OptionallyEditableValue = CreateReactClass({
  propTypes: {
    value: PropTypes.any,
    onChange: PropTypes.func,
    isEditable: PropTypes.bool,
    nullReadOnlyValue: PropTypes.any,
  },

  getDefaultProps: function() {
    return {
      isEditable: true
    };
  },

  render: function() {
    if (this.props.isEditable) {
      return this.props.children;
    }
    else {
      if (this.props.value == null && this.props.nullReadOnlyValue != null) {
        if (_.isString(this.props.nullReadOnlyValue)) {
          return <span>{this.props.nullReadOnlyValue}</span>;
        }
        else {
          return this.props.nullReadOnlyValue;
        }
      }
      else {
        return <span>{this.props.value}</span>;
      }
    }
  }
});


export var DateValue = CreateReactClass({
  mixins: [
    actionMixin,
    makeMultipleStoreMixin([userStore], function() {
      let user = userStore.getUser();
      return {
        country: user != null ? user.country : null
      };
    })
  ],

  propTypes: {
    value: PropTypes.object,
    onChange: PropTypes.func,
    isEditable: PropTypes.bool,
    dropLeft: PropTypes.bool,
    isValid: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isEditable: true,
      dropLeft: false,
      isValid: true
    };
  },

  render: function() {
    var {
      isEditable,
      value,
      onChange: _onChange,
      dropLeft,
      min,
      max,
      actionArgs: _actionArgs,
      ...props
    } = this.props;

    if (isEditable) {
      if (dropLeft) {
        props = mergeProps({className: "rw-dropleft"}, props);
      }

      let formats =  this.state.country === 'us' ? 
        [
          'MM/dd/yyyy',
          'MM/dd/yy',
          'MM.dd.yyyy',
          'MM.dd.yy',
          'MM dd yyyy',
          'MM dd yy',
        ]
        : [
          'dd/MM/yyyy',
          'dd/MM/yy',
          'dd.MM.yyyy',
          'dd.MM.yy',
          'dd MM yyyy',
          'dd MM yy',
        ];

      return (
        <DateTimePicker
          className={!this.props.isValid ? 'invalid-date-picker' : ''}
          time={false}
          //TODO-countries
          format={formats[0]}
          value={value != null ? value.toDate() : null}
          onChange={this.handleDatePickerChange}
          parse={formats}

          // react-widgets DateTimePicker doesn't work properly if you pass `null`
          // here (esp. `max`).
          min={min || new Date(1900, 0, 1)}
          max={max || new Date(2099, 11, 30)}

          {...props}
        />
      );
    }
    else {
      // Yes, moment.js format strings are different to the format strings
      // accepted by `DateTimePicker`.
      return <span>{value != null ? value.format("DD/MM/YYYY") : "(None)"}</span>;
    }
  },

  handleDatePickerChange: function(newDate) {
    var momentVal = (newDate != null) ? moment(newDate).startOf('day') : null;
    if (this.props.action != null) {
      this.executeAction(momentVal);
    }
    else if (this.props.onChange != null) {
      this.props.onChange(momentVal);
    }
  }
});




export var SelectValue = CreateReactClass({
  propTypes: {
    options: PropTypes.array.isRequired,
    getObjectLabel: PropTypes.func.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func,
    isEditable: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isEditable: true
    };
  },

  render: function() {
    var { isEditable, ...props } = this.props;

    if (isEditable) {
      return <MySelect2 {...props} />;
    }
    else {
      return <span>{this.props.getObjectLabel(this.props.value)}</span>;
    }
  }
});



export var BooleanValue = CreateReactClass({
  propTypes: {
    value: PropTypes.bool,
    onChange: PropTypes.func,
  },

  render: function() {
    var { isEditable, ...props } = this.props;
    if (isEditable) {
      return <Checkbox {...props} />;
    }
    else {
      return <span>{this.props.value ? 'Yes' : 'No'}</span>;
    }
  }
});


export var ActionTextBox = CreateReactClass({
  /**
   * Experimental: syntactic sugar for a text box connected to a (Flux) action.
   */
  mixins: [actionMixin],

  propTypes: {
    inputType: PropTypes.string,
    value: PropTypes.any,
  },

  getDefaultProps: function() {
    return {
      inputType: "text"
    };
  },

  render: function() {
    var {
      inputType,
      value,
      action: _action,
      actionArgs: _actionArgs,
      ...props
    } = this.props;
    return <input
      type={inputType}
      value={value}
      onChange={this.handleOnChange}
      {...props}
    />;
  },

  handleOnChange: function(event) {
    this.executeAction(event.target.value);
  }
});


export var ActionButton = CreateReactClass({
  mixins: [actionMixin],

  render: function() {
    return <button className={this.props.className} onClick={this.handleClick}>{this.props.children}</button>;
  },

  handleClick: function() {
    this.executeAction();
  }
});


export var RefreshButton = CreateReactClass({
  propTypes: {
    isRefreshing: PropTypes.bool,
    onClick: PropTypes.func,
    iconOnly: PropTypes.bool,
    smallButton: PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      isRefreshing: false,
      onClick: function() { },
      iconOnly: false,
      smallButton: true
    };
  },

  render: function() {
    let { disabled, isRefreshing, ...props } = this.props;

    return (
      <button
          className={classNames("btn", "btn-default", this.props.smallButton ? "btn-sm" : null)}
          disabled={disabled || isRefreshing}
          {...props}>
        <i
            className={classNames("fa", "fa-refresh", isRefreshing ? "fa-spin" : null)}
            style={this.props.iconOnly ? {margin: 0} : null}
        />
        {this.props.iconOnly ? null : "Refresh"}
      </button>
    );
  }
});



export var SaveButton = CreateReactClass({
  propTypes: {
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    disabled: PropTypes.bool,
    className: PropTypes.any
  },

  getDefaultProps: function() {
    return {
      disabled: false,
      size: 'lg'
    };
  },

  render: function() {
    var {
      text: _text,
      className,
      size: _size,
      ...other
    } = this.props;
    return (
      <button
          className={classNames("btn", 'btn-success', 'save-button', className)}
          style={{fontSize: '1.2em'}}
          disabled={this.props.disabled}
          {...other}>
        <i className="fa fa-floppy-o"></i>
        {this.props.text}
      </button>
    );
  }
});


export var DeleteButton = CreateReactClass({
  propTypes: {
    text: PropTypes.any.isRequired
  },

  render: function() {
    var { className, text, ...props } = this.props;
    return <button className={classNames("btn", "btn-default", className)} {...props}>
      <i className="fa fa-trash" />
      {text}
    </button>;
  }
});


export var AlertBar = CreateReactClass({
  propTypes: {
    color: PropTypes.string, // 'red' or 'yellow'
    className: PropTypes.any,
    closeButton: PropTypes.bool,
  },

  getDefaultProps: function () {
    return {
      closeButton: true,
    };
  },

  getInitialState: function() {
    return {
      isVisible: true
    };
  },

  render: function() {
    if (!this.state.isVisible) {
      return null;
    }
    return <div className={this.props.className}>
      <div className={classNames("cc-alert", {"red-alert": this.props.color === "red"})}>
        {this.props.closeButton ? 
          <button
            className="save-bar__close-button"
            style={{ float: 'right' }}
            onClick={this.handleCloseButtonClick}>
            <i className="fa fa-times" />
          </button>
        : null}
        {this.props.children}
      </div>
    </div>;
  },

  handleCloseButtonClick: function() {
    this.setState({isVisible:false});
  }
});


export var ProgressBar = CreateReactClass({
  propTypes: {
    width: PropTypes.any, // CSS width: number, 'xx%', etc.
    height: PropTypes.any, // CSS width: number, 'xx%', etc.

    // [0..1] or NaN. If NaN, display as if the value is zero but in a greyed-out colour.
    value: PropTypes.number,

    text: PropTypes.string,
    className: PropTypes.string,
  },

  render: function() {
    var val = !isNaN(this.props.value) ? this.props.value : 0;
    var color;
    if (val < 0.8) {
      color = "#FFC734";
    }
    else if (val <= 1.0) {
      color = "#ffb534";
    }
    else {
      color = "#f05a28";
    }
    var innerWidth = middleOfThree(0, val, 1) * 100 + '%';

    return (
      <svg
          className={this.props.className}
          style={{width: this.props.width, height: this.props.height, display: "inline-block"}}>
        <g shapeRendering="crisp-edges">
          <rect x={0} y={0} width='100%' height='100%' fill={!isNaN(this.props.value) ? "#888" : "#aaa"} stroke="none" />
          {this.props.value > 0 ?
            <g>
              <rect x={0} y={0} width={innerWidth} height='100%' fill={color} stroke="none" />
            </g>
          : null}
          {this.props.text ?
            <text
                x="50%"
                y="50%"
                fill="white"
                textAnchor="middle"
                dy="0.4em"
                style={{
                  fontWeight: 600,
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.43)'
                }}>
              {this.props.text}
            </text>
          : null}
        </g>
      </svg>
    );
  }
});


export var ProgressBar2 = CreateReactClass({
  propTypes: {
    numerator: PropTypes.number,
    denominator: PropTypes.number,
    nullText: PropTypes.any,
    formatNumber: PropTypes.func
  },

  getDefaultProps: function() {
    return {
      nullText: "N/A",
      formatNumber: n => Math.round(n)
    };
  },

  render: function() {
    let {numerator, denominator, formatNumber, ...props} = this.props;
    let value, text;
    if (numerator == null) {
      value = NaN;
      text = this.props.nullText;
    }
    else if (denominator == null || denominator === 0) {
      value = NaN;
      text = `${formatNumber(numerator)} / -`;
    }
    else {
      value = numerator / denominator;
      text = `${formatNumber(numerator)} / ${formatNumber(denominator)} (${formatPercentage0((numerator/denominator)*100)})`;
    }

    return <ProgressBar
      value={value}
      text={text}
      {...props}
    />;
  }
});


export var Dropdown3 = CreateReactClass({
  propTypes: {
    isExpanded: PropTypes.bool,
    onToggle: PropTypes.func,
    contentStyle: PropTypes.any,
    containerStyle: PropTypes.any,

    // Optional. If provided, the `Dropdown3ListItem` children are wrapped so
    // when one is clicked, this function is called with the list item's
    // `identifier` property as the single argument. If not provided it's up to
    // the user to attach `onClick` handlers as they see fit.
    onItemClick: PropTypes.func,

    // If true, open to the left, else, open to the right.
    dropLeft: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      onToggle: function(isExpanded) { },
      dropLeft: false
    };
  },

  getInitialState: function() {
    return {
      isExpanded: false
    };
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.isExpanded && !this.props.isExpanded) {
      this.addBodyClickHandler();
    }
  },

  componentWillUnmount() {
    window.$("body").off("click", this.handleBodyClick);
  },

  render: function() {
    let self = this;

    let mainContentStyle = {
      position: 'absolute',
      top: '100%',
      zIndex: 100,
      padding: 0,
      [this.props.dropLeft ? 'right' : 'left']: 0,
    };

    return <div
        style={{display: 'inline-block', ...this.props.containerStyle}}
        className={this.props.className}>
      <div style={{position: 'relative'}}>
        {React.cloneElement(
          this.props.toggleElement,
          {
            onClick: this.handleToggleButtonClick
          }
        )}
        {this.isExpanded() ?
          <div style={mainContentStyle}>
            <div style={{
                  backgroundColor: 'white',
                  padding: 0,
                  fontSize: 13,
                  boxShadow: '0 6px 12px rgba(0,0,0,.175)',
                  border: '1px solid rgba(0,0,0,.15)',
                  textAlign: 'left',
                  marginTop: '0.2em',
                  ...this.props.contentStyle
                }}>
              {this.props.children.map(function(child, i) {
                if (child == null) {
                  return null;
                }
                return <Dropdown3ListItemWrapper
                  key={i}
                  onClick={() => self.handleMenuItemClick(child)}
                  disabled={child.props.disabled}
                  item={child}
                />;
              })}
            </div>
          </div>
        : null}
      </div>
    </div>;
  },

  handleMenuItemClick: function(child, event) {
    if (this.props.onItemClick != null) {
      this.props.onItemClick(child.props.identifier, event);
    }
    this.setExpanded(false);
  },

  addBodyClickHandler: function() {
    window.$("body").one("click", this.handleBodyClick);
  },

  isExpanded: function() {
    if (this.props.isExpanded != null) {
      return this.props.isExpanded;
    }
    else {
      return this.state.isExpanded;
    }
  },

  setExpanded: function(isExpanded) {
    if (isExpanded !== this.isExpanded()) {
      if (this.props.isExpanded != null) {
        this.props.onToggle(isExpanded);
      }
      else {
        this.setState({isExpanded: isExpanded});
      }
      if (isExpanded) {
        this.addBodyClickHandler();
      }
    }
  },

  handleToggleButtonClick: function(event) {
    this.setExpanded(!this.isExpanded());
    event.stopPropagation();
  },

  handleBodyClick(event) {
    if (!_.include(window.$(event.target).parents(), ReactDOM.findDOMNode(this))) {
      this.setExpanded(false);
    }
  }
});


var Dropdown3ListItemWrapper = CreateReactClass({
  /**
   * Simple wrapper so `Dropdown3` can send child-specific click events to its own
   * parent without having to bind callbacks itself.
   */
  propTypes: {
    onClick: PropTypes.func,
    item: PropTypes.node.isRequired,
    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      disabled: false
    };
  },

  render: function() {
    return <div onClick={this.handleClick}>
      {this.props.item}
    </div>;
  },

  handleClick: function(event) {
    if (!this.props.disabled) {
      this.props.onClick(this.props.item, event);
    }
  }
});


export var Dropdown3ListItem = CreateReactClass({
  propTypes: {
    // Used by the `DropdownList3` parent (optional).
    identifier: PropTypes.any,
    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      disabled: false
    };
  },

  getInitialState: function() {
    return {
      isHovered: false
    };
  },

  render: function() {
    let { children, style, disabled, onClick: _onClick, ...props } = this.props;

    return <div
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        style={{
          backgroundColor: this.state.isHovered ? '#eaeaea' : null,
          color: disabled ? '#aaa' : null,
          cursor: 'pointer',
          padding: '0.5em 1em',
          ...style
        }}
        onClick={this.handleClick}
        {...props}>
      {children}
    </div>;
  },

  handleClick: function() {
    if (!this.props.disabled && this.props.onClick != null) {
      this.props.onClick();
    }
  },

  handleMouseOver: function() {
    this.setState({isHovered: true});
  },

  handleMouseOut: function() {
    this.setState({isHovered: false});
  },
});


export var EditItemControls = CreateReactClass({
  propTypes: {
    object: PropTypes.object,
    objectTypeName: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
    deleteButtonText: PropTypes.string,
    extraMiddleButtons: PropTypes.array,
    showCancelButton: PropTypes.bool,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,

    // This, ...
    saveButton: PropTypes.node,

    // ...or some of these.
    onSave: PropTypes.func,
    saveButtontext: PropTypes.string,
    isSaving: PropTypes.bool,
    isSaveButtonDisabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      canDelete: true,
      extraMiddleButtons: [],
      showCancelButton: true,
      isSaving: false,
      isSaveButtonDisabled: false
    };
  },

  render: function() {
    return (
      <div className="flexbox-container flex-align-items-center flex-space-between" style={{padding: 15}}>

        <div className="flex-0-0-auto">
          {this.props.object != null && this.props.canDelete ?
            buttons(
              <ConfirmableDeleteButton
                object={this.props.object}
                objectTypeName={this.props.objectTypeName}
                text={this.props.deleteButtonText}
                onDelete={this.props.onDelete}
              />,
              ...this.props.extraMiddleButtons
            )
          : null}
        </div>

        <div className="flex-0-0-auto inline-flexbox-container flex-align-items-center">
          {this.props.showCancelButton ?
            <a href="javascript:void(0)" style={{marginRight: 20}} onClick={this.props.onCancel}>Cancel</a>
          : null}
          {this.props.saveButton ||
            <SaveButton
              text={
                this.props.saveButtonText || (
                  this.props.isSaving
                  ? `Saving ${this.props.objectTypeName}...`
                  : `Save ${this.props.objectTypeName}`
                )
              }
              onClick={this.props.onSave}
              disabled={this.props.isSaveButtonDisabled || this.props.isSaving}
            />
          }
        </div>
      </div>
    );
  }
});



export var ItemExpandedState = CreateReactClass({
  propTypes: {
    isExpanded: PropTypes.bool
  },

  render: function() {
    let { isExpanded, className, ...props} = this.props;
    return <i
      className={classNames(
        "fa",
        isExpanded ? 'fa-caret-down' : 'fa-caret-right',
        "fa-fw",
        className
      )}
      {...props}
    />;
  }
});


export var ItemExpandedToggle = CreateReactClass({
  propTypes: {
    isExpanded: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  },

  render: function() {
    return (
      <button
          onClick={this.handleClick}
          className="btn btn-default btn-sm"
          style={{padding: '2px 6px'}}>
        <ItemExpandedState
          isExpanded={this.props.isExpanded}
          style={{margin: 0}}
        />
      </button>
    );
  },

  handleClick: function() {
    this.props.onChange(!this.props.isExpanded);
  }
});
