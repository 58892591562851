import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import moment from "moment";
import { StoreBase, dispatcher, ActionCollection } from "../coincraftFlux.js";
import { ReportType } from "../reports/Report.js";
import { sum } from "../utils.js";
import { dateConverter } from "../models.js";
import {
	ProjectPermissionChecker,
	getProjectPermissionLevel,
} from "../models/permissions.js";
import { PermissionLevel } from "../models/permissions.js";
import { organisationStore } from "../organisation.js";
import { jsonHttp } from "../jsonHttp.js";
import Immutable from "immutable";
import Papa from "papaparse";
import { userStore } from "../user.js";
import { permissions, requiresPermission } from "../models/permissions.js";
import { generateUUID } from "../utils.js";
import { AjaxOperation } from "../AjaxOperation.js";
import apiRequest, { chainRequests } from "../apiRequest.js";
import Axios from "axios";
import { autoAdjustRevenue } from "../project/AutoAdjustForecasts.js";

export const SpreadsheetStore = class extends StoreBase {
	constructor() {
		super();
		this.isReady = false;
	}

	initialize() {
		// Start at, say, three months before the current month.
		this.currentMonthIndex = dateConverter.momentToMonthIndex(moment());
		this.startMonth = this.currentMonthIndex - 3;
		this.endMonth = this.startMonth + 11;

		this.showFilters = false;
		this.showSidebar = false;
		this.selectedCell = {};

		this.reports = organisationStore.revenueForecastReports;
		this.defaultReport =
			organisationStore.organisation.defaultRevenueForecastReport;
		this.selectedReport = this.defaultReport;

		this.isPrinting = false;

		if (this.selectedReport) {
			const selectedReport = organisationStore
				.getRevenueForecastReportByUuid(this.selectedReport)
				.toJS();
			this.reportName = selectedReport.name;
			this.filters = {
				costCentres: selectedReport.filters.costCentres.map((ccId) =>
					organisationStore.getCostCentreById(ccId)
				),
				projectStatus: selectedReport.filters.projectStatus,
				projects: selectedReport.filters.projects.map((pId) =>
					organisationStore.getProjectById(pId)
				),
				staff: selectedReport.filters.staff.map((sId) =>
					organisationStore.getStaffMemberById(sId)
				),
				projectOwners:
					selectedReport.filters.projectOwners?.map((sId) =>
						organisationStore.getStaffMemberById(sId)
					) || [],
				expenses: selectedReport.filters.expenses
					.map((eId) => organisationStore.getExpenseById(eId))
					.filter((e) => e),
			};
			this.dataType = selectedReport.filters.revenueData;
			this.invoiceDateType = selectedReport.filters.invoiceData;
			this.profitDataType =
				selectedReport.filters.profitDataType || "margin";
			this.totalType =
				selectedReport.filters.totalData || "revenueTotalFee";
			this.contractorExpense =
				selectedReport.filters.contractorExpense || "allocatedHours";
			this.showExpenses = selectedReport.filters.showExpenses ?? true;
		} else {
			this.reportName = "New Report";
			this.filters = {
				costCentres: [],
				projectStatus: [],
				projects: [],
				staff: [],
				projectOwners: [],
				expenses: [],
			};
			this.dataType = "actualsProjected";
			this.invoiceDateType = "issuedOn";
			this.profitDataType = "margin";
			this.totalType = "revenueTotalFee";
			this.contractorExpense = "allocatedHours";
			this.showExpenses = true;
		}

		organisationStore.projects.forEach((p) => {
			p.mergeChangeLog(this.invoiceDateType);
		});

		this.dateColumns = this.getDateColumns();
		this.renderedMonths = this.dateColumns.map((dc) => dc.monthIndex);
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.expandedProjects = [];
		this.staffData = Immutable.Map({ total: {} });
		this.displayedStaff = [];
		this.expenseData = Immutable.Map({ total: {} });
		this.displayedExpenses = [];

		this.getAllProjectData();
		this.getStaffData();
		this.getExpenseData();

		this.moveMonths = 0;

		this.projectSpreadsheetRows = this.getProjectRows();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();

		this.save = new AjaxOperation({
			emitChanged: function () {
				let rootStore = require("../RootStore").rootStore;
				rootStore.emitChanged();
			},
		});

		this.projectSaveState = null;
		this.modal = null;
		this.isDirty = false;
		this.dirtyProjects = [];
		this.isReady = true;

		this.emitChanged();
	}

	get saveState() {
		return this.save.state;
	}

	getDateColumns() {
		let columns = [];
		let monthIndex = this.startMonth;
		while (monthIndex <= this.endMonth) {
			let date = dateConverter.monthIndexToMoment(monthIndex); //has funny results
			columns.push({
				date: date,
				dateInt: dateConverter.monthIndexToOffset(monthIndex),
				string: date.format("MMM YY"),
				value: date.format("MMM YY"),
				monthIndex: monthIndex,
				isColumnHeader: true,
				isEditable: false,
			});
			monthIndex += 1;
		}
		return columns;
	}

	projectDisplayed(project) {
		const projects =
			this.filters.projects.length === 0 ||
			this.filters.projects.map((p) => p.id).includes(project.id);
		const costCentre =
			this.filters.costCentres.length === 0 ||
			this.filters.costCentres
				.map((cc) => cc.id)
				.includes(project.costCentre.id);
		const projectStatus =
			this.filters.projectStatus.length === 0 ||
			this.filters.projectStatus.includes(project.status);
		const projectOwner =
			this.filters.projectOwners.length === 0 ||
			this.filters.projectOwners
				.map((po) => po.id)
				.includes(project.owner?.id);
		const projectData = this.projectData.get(project.uuid).revenueData;
		const archived =
			project.status !== "archived" || //if not archived
			projectData.totalAgreedFee !== 0; //or project has fee in displayed date regionFragment:
		return (
			projects && costCentre && projectStatus && projectOwner && archived
		);
	}

	staffDisplayed(staffMember) {
		let dates = this.dateColumns.map((dc) => dc.dateInt);
		let payRate = staffMember.getRateInRange(
			"payRate",
			_.first(dates),
			_.last(dates)
		);
		let availability = staffMember.getRateInRange(
			"weeklyAvailability",
			_.first(dates),
			_.last(dates)
		);
		let staff =
			this.filters.staff.length === 0 ||
			this.filters.staff.map((s) => s.id).includes(staffMember.id);
		let costCentre =
			this.filters.costCentres.length === 0 ||
			this.filters.costCentres
				.map((cc) => cc.id)
				.includes(staffMember.costCentre.id);
		let archived = !staffMember.isArchived || payRate * availability !== 0;
		return staff && costCentre && archived;
	}

	expenseDisplayed(expense) {
		let months = this.dateColumns.map((dc) => dc.monthIndex);
		let startIndex = dateConverter.momentToMonthIndex(expense.startDate);
		let endIndex = expense.endDate
			? dateConverter.momentToMonthIndex(expense.endDate)
			: null;
		let expenses =
			this.filters.expenses.length === 0 ||
			this.filters.expenses.map((e) => e.name).includes(expense.name);
		let costCentre =
			this.filters.costCentres.length === 0 ||
			this.filters.costCentres
				.map((cc) => cc.id)
				.includes(expense.costCentre.id);
		let inRange =
			startIndex <= _.last(months) &&
			(endIndex ? endIndex >= _.first(months) : true);
		return expenses && costCentre && inRange;
	}

	getAllProjectData() {
		let projects = {};
		organisationStore.projects
			.filter((p) => permissions.canViewProject(p).ok(userStore.user))
			.forEach((p) => {
				this.getProjectData(p);
				if (p.status === "archived") {
					this.archivedProjects.push(p); //cache archived projects to loop through later
				}
			});
		return projects;
	}

	getProjectData(project) {
		const self = this;
		const p = project;
		const cashflowItems = project.getCashFlowItems();
		const phaseCashflowItems = _.groupBy(cashflowItems, (cfi) =>
			cfi.phase ? cfi.phase.uuid : "null"
		);
		const projectRevData =
			this.getRevenueDataFromCashflowItems(cashflowItems);
		this.projectData = this.projectData.set(p.uuid, {
			project: p,
			visible: true,
			likelihood: p.likelihood,
			expanded: this.expandedProjects.includes(p.uuid),
			isDirty: false,
			cashflowItems: cashflowItems,
			revenueData: projectRevData,
		});
		const projectDisplayed = this.projectDisplayed(p);
		if (projectDisplayed) {
			this.displayedProjects[p.status].push(p);
			if (!self.projectData.get(p.status).totalAgreedFee) {
				self.projectData.get(p.status).totalAgreedFee =
					projectRevData.totalAgreedFee;
			} else {
				self.projectData.get(p.status).totalAgreedFee +=
					projectRevData.totalAgreedFee;
			}
			projectRevData.cfiMonths.forEach((cfiMonth) => {
				if (!self.projectData.get("total")[cfiMonth]) {
					self.projectData.get("total")[cfiMonth] =
						projectRevData[cfiMonth].total;
				} else {
					self.projectData.get("total")[cfiMonth] +=
						projectRevData[cfiMonth].total;
				}
				if (!self.projectData.get("totalRevenue")[cfiMonth]) {
					self.projectData.get("totalRevenue")[cfiMonth] =
						projectRevData[cfiMonth].feeTotal;
				} else {
					self.projectData.get("totalRevenue")[cfiMonth] +=
						projectRevData[cfiMonth].feeTotal;
				}
				if (!self.projectData.get("totalExpense")[cfiMonth]) {
					self.projectData.get("totalExpense")[cfiMonth] =
						projectRevData[cfiMonth].spendTotal;
				} else {
					self.projectData.get("totalExpense")[cfiMonth] +=
						projectRevData[cfiMonth].spendTotal;
				}
				if (!self.projectData.get(p.status)[cfiMonth]) {
					self.projectData.get(p.status)[cfiMonth] =
						projectRevData[cfiMonth].total;
				} else {
					self.projectData.get(p.status)[cfiMonth] +=
						projectRevData[cfiMonth].total;
				}
			});
		}
		project.getVisiblePhases().forEach((ph) => {
			const phCfis = phaseCashflowItems[ph.uuid] || [];
			const phaseRevData = this.getRevenueDataFromCashflowItems(phCfis);
			this.projectData = this.projectData.set(ph.uuid, {
				project: p,
				phase: ph,
				visible: true,
				likelihood: ph.likelihood,
				cashflowItems: phCfis,
				revenueData: phaseRevData,
			});
			phaseRevData.cfiMonths.forEach((cfiMonth) => {
				if (
					cfiMonth >= this.currentMonthIndex &&
					projectDisplayed &&
					["prospective", "onHold"].includes(ph.status)
				) {
					if (!self.projectData.get("prospectiveGraph")[cfiMonth]) {
						self.projectData.get("prospectiveGraph")[cfiMonth] =
							phaseRevData[cfiMonth].total;
					} else {
						self.projectData.get("prospectiveGraph")[cfiMonth] +=
							phaseRevData[cfiMonth].total;
					}
				}
			});
		});
		const noPhCfis = project.getNoPhaseCashFlowItems();
		if (noPhCfis.length > 0) {
			this.projectData = this.projectData.set(
				p.getProjectNoPhase().uuid,
				{
					project: p,
					phase: p.getProjectNoPhase(),
					visible: true,
					likelihood: p.likelihood,
					cashflowItems: noPhCfis,
					revenueData: this.getRevenueDataFromCashflowItems(noPhCfis),
				}
			);
		}
	}

	getRevenueDataFromCashflowItems(cashflowItems) {
		cashflowItems = cashflowItems.filter(
			(cfi) => cfi.endDate && !(cfi.expense && cfi.expense.isInvoiceable)
		);
		const self = this;
		let revenueData = {
			totalAgreedFee: 0,
		};
		const cfiMonths = _.uniq(
			cashflowItems.map((cfi) => cfi.monthIndex)
		).sort((a, b) => a - b);
		const startMonth = Math.min(...cfiMonths);
		const endMonth = Math.max(...cfiMonths);
		const groupedCfis = _.groupBy(cashflowItems, (cfi) => cfi.monthIndex);

		revenueData.cfiMonths = cfiMonths;
		revenueData.startMonth = startMonth;
		revenueData.endMonth = endMonth;

		let agreedFeeToDate = 0;
		cfiMonths.forEach((monthIndex) => {
			const cfis = groupedCfis[monthIndex] || [];
			const revData = this.getCashflowItemRevenueTotals(cfis, monthIndex);
			agreedFeeToDate += revData.agreedFeeTotal;
			revenueData.totalAgreedFee += revData.agreedFeeTotal;
			revenueData[monthIndex] = revData;
			revenueData[monthIndex].agreedFeeToDate = agreedFeeToDate;
		});
		return revenueData;
	}

	getCashflowItemRevenueTotals(cfis, monthIndex) {
		let agreedFeeTotal, monthTotal, feeTotal, spendTotal;
		const futureMonth = monthIndex > this.currentMonthIndex;
		const currentMonth = monthIndex === this.currentMonthIndex;
		const currentOrFutureMonth = futureMonth || currentMonth;
		const pastMonth = monthIndex < this.currentMonthIndex;
		const getLikelihood = (cfi) =>
			currentOrFutureMonth
				? cfi.phase?.status === "archived"
					? 0
					: cfi.likelihood / 100
				: 1;
		const actualCfis = cfis.filter(
			(cfi) =>
				(!cfi.milestone && !cfi.expense) ||
				(cfi.expense && !cfi.expense.isInvoiceable)
		);
		const projectedCfis = cfis.filter(
			(cfi) => cfi.milestone || cfi.expense
		);
		const actualFeeTotal = sum(actualCfis.map((cli) => cli.fee));
		const projectedFeeTotal = sum(
			projectedCfis.map((cli) => cli.fee * getLikelihood(cli))
		);
		const actualSpendTotal = this.showExpenses
			? sum(actualCfis.map((cli) => cli.spend))
			: 0;
		const projectedSpendTotal = this.showExpenses
			? sum(projectedCfis.map((cli) => cli.spend * getLikelihood(cli)))
			: 0;
		const actualAgreedFeeTotal = sum(
			actualCfis
				.filter((cfi) => cfi.billingType === "agreedFee")
				.map((cli) => cli.fee)
		);
		const projectedAgreedFeeTotal = sum(
			projectedCfis
				.filter((cfi) => cfi.billingType === "agreedFee")
				.map((cli) => cli.fee * getLikelihood(cli))
		);
		if (this.dataType === "actualsProjected" && currentMonth) {
			if (actualFeeTotal >= projectedFeeTotal) {
				agreedFeeTotal = actualAgreedFeeTotal;
				monthTotal = actualFeeTotal - actualSpendTotal;
				feeTotal = actualFeeTotal;
				spendTotal = actualSpendTotal;
			} else {
				agreedFeeTotal = projectedAgreedFeeTotal;
				monthTotal = projectedFeeTotal - projectedSpendTotal;
				feeTotal = projectedFeeTotal;
				spendTotal = projectedSpendTotal;
			}
		} else if (
			this.dataType === "actuals" ||
			(this.dataType === "actualsProjected" && pastMonth)
		) {
			agreedFeeTotal = actualAgreedFeeTotal;
			monthTotal = actualFeeTotal - actualSpendTotal;
			feeTotal = actualFeeTotal;
			spendTotal = actualSpendTotal;
		} else {
			agreedFeeTotal = projectedAgreedFeeTotal;
			monthTotal = projectedFeeTotal - projectedSpendTotal;
			feeTotal = projectedFeeTotal;
			spendTotal = projectedSpendTotal;
		}
		return {
			agreedFeeTotal: agreedFeeTotal,
			total: monthTotal,
			feeTotal: feeTotal,
			spendTotal: spendTotal,
			cashflowItems: cfis,
			projected: {
				total: projectedFeeTotal - projectedSpendTotal,
				feeTotal: projectedFeeTotal,
				spendTotal: projectedSpendTotal,
				agreedFeeTotal: projectedAgreedFeeTotal,
				cashflowItems: projectedCfis,
			},
			actual: {
				total: actualFeeTotal - actualSpendTotal,
				feeTotal: actualFeeTotal,
				spendTotal: actualSpendTotal,
				agreedFeeTotal: actualAgreedFeeTotal,
				cashflowItems: actualCfis,
			},
		};
	}

	toggleProjectExpand(uuid) {
		let project = this.projectData.get(uuid);
		project.expanded = !project.expanded;
		this.projectData = this.projectData.set(uuid, project);
		if (project.expanded) {
			this.expandedProjects.push(uuid);
		} else {
			this.expandedProjects = _.without(this.expandedProjects, uuid);
		}
		this.projectSpreadsheetRows = this.getProjectRows();
		this.emitChanged();
	}

	updateProjectDataFromDateChange() {
		const self = this;
		this.displayedProjects.archived = [];
		this.archivedProjects.forEach((project) => {
			const projectData = self.projectData.get(project.uuid).revenueData;
			const archived =
				projectData.totalAgreedFee !== 0 && //or project has fee in displayed date range
				((projectData.startMonth >= self.startMonth &&
					projectData.startMonth <= self.endMonth) ||
					(projectData.endMonth >= self.startMonth &&
						projectData.endMonth <= self.endMonth));
			if (archived) {
				self.displayedProjects.archived.push(project);
			}
		});
	}

	getStaffData() {
		organisationStore.staffMembers
			.filter(
				(sm) =>
					getProjectPermissionLevel(sm, userStore.user) === "admin"
			)
			.forEach((sm) => {
				if (this.staffDisplayed(sm)) {
					this.displayedStaff.push(sm);
					this.staffData = this.staffData.set(sm.uuid, {
						staffMember: sm,
						id: sm.id,
						visible: true,
						expenseData: this.getStaffExpenseData(sm),
					});
				}
			});
	}

	getStaffExpenseData(sm) {
		let staffExpenseData = {};
		this.dateColumns.forEach((dc) => {
			const isEmployee = sm.staffType === "employee";
			const monthIndex = dc.monthIndex;
			let staffExpense = 0;
			const contractorAvailableHours =
				this.contractorExpense === "availableHours";
			const getAvailableHoursExpense = (staff, mi) =>
				staff.getRateInMonth("payRate", mi) *
				staff.getNumHoursAvailableInMonth(mi);
			const getAllocatedHoursExpense = (staff, mi) =>
				(staff.monthlyHours.get("combinedHours", {})[mi] || 0) *
				staff.getRateInMonth("payRate", mi);
			if (isEmployee) {
				staffExpense = getAvailableHoursExpense(sm, monthIndex);
			} else if (monthIndex < this.currentMonthIndex) {
				staffExpense =
					sm.monthlyHours.get("combinedPay", {})[monthIndex] || 0;
			} else if (monthIndex > this.currentMonthIndex) {
				staffExpense = contractorAvailableHours
					? getAvailableHoursExpense(sm, monthIndex)
					: getAllocatedHoursExpense(sm, monthIndex);
			} else if (monthIndex == this.currentMonthIndex) {
				const projectedStaffExpense = contractorAvailableHours
					? getAvailableHoursExpense(sm, monthIndex)
					: getAllocatedHoursExpense(sm, monthIndex);
				const actualStaffExpense =
					sm.monthlyHours.get("combinedPay", {})[monthIndex] || 0;
				staffExpense = Math.max(
					projectedStaffExpense,
					actualStaffExpense
				);
			}
			staffExpenseData[dc.monthIndex] = { total: staffExpense };
			if (this.staffData.get("total")[dc.monthIndex]) {
				this.staffData.get("total")[dc.monthIndex] += staffExpense;
			} else {
				this.staffData.get("total")[dc.monthIndex] = staffExpense;
			}
		});
		return staffExpenseData;
	}

	updateStaffDataFromDateChange(newMonths) {
		this.displayedStaff = [];
		organisationStore.staffMembers
			.filter(
				(sm) =>
					getProjectPermissionLevel(sm, userStore.user) === "admin"
			)
			.forEach((sm) => {
				if (this.staffDisplayed(sm)) {
					this.displayedStaff.push(sm);
					const sData = this.staffData.get(sm.uuid);
					if (sData) {
						newMonths.forEach((monthIndex) => {
							if (!sData.expenseData[monthIndex]) {
								const isEmployee = sm.staffType === "employee";
								let staffExpense = 0;
								const contractorAvailableHours =
									this.contractorExpense === "availableHours";
								const getAvailableHoursExpense = (staff, mi) =>
									staff.getRateInMonth("payRate", mi) *
									staff.getNumHoursAvailableInMonth(mi);
								const getAllocatedHoursExpense = (staff, mi) =>
									(staff.monthlyHours.get(
										"combinedHours",
										{}
									)[mi] || 0) *
									staff.getRateInMonth("payRate", mi);
								if (isEmployee) {
									staffExpense = getAvailableHoursExpense(
										sm,
										monthIndex
									);
								} else if (
									monthIndex < this.currentMonthIndex
								) {
									staffExpense =
										sm.monthlyHours.get("combinedPay", {})[
											monthIndex
										] || 0;
								} else if (
									monthIndex > this.currentMonthIndex
								) {
									staffExpense = contractorAvailableHours
										? getAvailableHoursExpense(
												sm,
												monthIndex
										  )
										: getAllocatedHoursExpense(
												sm,
												monthIndex
										  );
								} else if (
									monthIndex == this.currentMonthIndex
								) {
									const projectedStaffExpense =
										contractorAvailableHours
											? getAvailableHoursExpense(
													sm,
													monthIndex
											  )
											: getAllocatedHoursExpense(
													sm,
													monthIndex
											  );
									const actualStaffExpense =
										sm.monthlyHours.get("combinedPay", {})[
											monthIndex
										] || 0;
									staffExpense = Math.max(
										projectedStaffExpense,
										actualStaffExpense
									);
								}
								sData.expenseData[monthIndex] = {
									total: staffExpense,
								};
								if (this.staffData.get("total")[monthIndex]) {
									this.staffData.get("total")[monthIndex] +=
										staffExpense;
								} else {
									this.staffData.get("total")[monthIndex] =
										staffExpense;
								}
							}
						});
					} else {
						this.staffData = this.staffData.set(sm.uuid, {
							staffMember: sm,
							id: sm.id,
							visible: true,
							expenseData: this.getStaffExpenseData(sm),
						});
					}
				}
			});
	}

	getExpenseData() {
		const self = this;
		let expensesCFIs = [];
		organisationStore.getNonProjectExpenses(
			_.first(this.dateColumns).date,
			_.last(this.dateColumns).date.endOf("month"),
			(cfi) =>
				this.expenseDisplayed(cfi.expense)
					? expensesCFIs.push(cfi)
					: null
		);
		const groupedCfis = _.groupBy(expensesCFIs, (ecfi) => ecfi.title);
		const expenseNames = Object.keys(groupedCfis);
		this.displayedExpenses = expenseNames.map((eName) => ({
			name: eName,
			expense: groupedCfis[eName][0].expense,
		}));
		expenseNames.forEach((eName) => {
			const cfis = groupedCfis[eName];
			const expenseData = this.getExpenseDataFromCashflowItems(cfis);
			this.expenseData = this.expenseData.set(eName, {
				name: eName,
				visible: true,
				cashflowItems: cfis,
				expenseData: expenseData,
			});
			expenseData.cfiMonths.forEach((cfiMonth) => {
				if (!self.expenseData.get("total")[cfiMonth]) {
					self.expenseData.get("total")[cfiMonth] =
						expenseData[cfiMonth].total;
				} else {
					self.expenseData.get("total")[cfiMonth] +=
						expenseData[cfiMonth].total;
				}
			});
		});
	}

	updateExpenseDataFromDateChange(newMonths) {
		const self = this;
		let expensesCFIs = [];
		organisationStore.getNonProjectExpenses(
			dateConverter.monthIndexToMoment(_.first(newMonths)),
			dateConverter.monthIndexToMoment(_.last(newMonths)).endOf("month"),
			(cfi) =>
				this.expenseDisplayed(cfi.expense)
					? expensesCFIs.push(cfi)
					: null
		);
		const groupedCfis = _.groupBy(expensesCFIs, (ecfi) => ecfi.title);
		const expenseNames = Object.keys(groupedCfis);
		expenseNames.forEach((eName) => {
			const cfis = groupedCfis[eName];
			const expenseData = this.getExpenseDataFromCashflowItems(cfis);
			const eData = self.expenseData.get(eName);
			if (!eData) {
				this.expenseData = this.expenseData.set(eName, {
					name: eName,
					visible: true,
					cashflowItems: cfis,
					expenseData: expenseData,
				});
			}
			expenseData.cfiMonths.forEach((cfiMonth) => {
				if (eData && !eData.expenseData[cfiMonth]) {
					eData.expenseData[cfiMonth] = {
						total: expenseData[cfiMonth].total,
					};
				}
				if (eData && eData.visible) {
					if (!self.expenseData.get("total")[cfiMonth]) {
						self.expenseData.get("total")[cfiMonth] =
							expenseData[cfiMonth].total;
					} else {
						self.expenseData.get("total")[cfiMonth] +=
							expenseData[cfiMonth].total;
					}
				}
			});
		});
	}

	getExpenseDataFromCashflowItems(cashflowItems) {
		const self = this;
		let expenseData = {};
		const cfiMonths = _.uniq(cashflowItems.map((cfi) => cfi.monthIndex));
		const startMonth = Math.min(...cfiMonths);
		const endMonth = Math.max(...cfiMonths);
		const groupedCfis = _.groupBy(cashflowItems, (cfi) => cfi.monthIndex);

		expenseData.cfiMonths = cfiMonths;
		expenseData.startMonth = startMonth;
		expenseData.endMonth = endMonth;

		cfiMonths.forEach((monthIndex) => {
			const cfis = groupedCfis[monthIndex] || [];
			expenseData[monthIndex] = {
				total: sum(cfis.map((cli) => cli.spend)),
				cashflowItems: cfis,
			};
		});
		return expenseData;
	}

	toggleProjectVisibility(uuid) {
		let pData = this.projectData.get(uuid);
		pData.visible = !pData.visible;
		this.projectData = this.projectData.set(uuid, pData);
		pData.revenueData.cfiMonths.forEach((cfiMonth) => {
			this.projectData.get("total")[cfiMonth] +=
				pData.revenueData[cfiMonth].total * (pData.visible ? 1 : -1);
			this.projectData.get("totalRevenue")[cfiMonth] +=
				pData.revenueData[cfiMonth].feeTotal * (pData.visible ? 1 : -1);
			this.projectData.get("totalExpense")[cfiMonth] +=
				pData.revenueData[cfiMonth].spendTotal *
				(pData.visible ? 1 : -1);
			this.projectData.get(pData.project.status)[cfiMonth] +=
				pData.revenueData[cfiMonth].total * (pData.visible ? 1 : -1);
		});
		//TODO make more effecient
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	toggleProjectPhaseVisibility(uuid) {
		let pData = this.projectData.get(uuid);
		pData.visible = !pData.visible;
		this.projectData = this.projectData.set(uuid, pData);
		pData.revenueData.cfiMonths.forEach((cfiMonth) => {
			this.projectData.get("total")[cfiMonth] +=
				pData.revenueData[cfiMonth].total * (pData.visible ? 1 : -1);
			this.projectData.get("totalRevenue")[cfiMonth] +=
				pData.revenueData[cfiMonth].feeTotal * (pData.visible ? 1 : -1);
			this.projectData.get("totalExpense")[cfiMonth] +=
				pData.revenueData[cfiMonth].spendTotal *
				(pData.visible ? 1 : -1);
			this.projectData.get(pData.project.status)[cfiMonth] +=
				pData.revenueData[cfiMonth].total * (pData.visible ? 1 : -1);

			this.projectData.get(pData.project.uuid).revenueData[
				cfiMonth
			].total +=
				pData.revenueData[cfiMonth].total * (pData.visible ? 1 : -1);
			this.projectData.get(pData.project.uuid).revenueData[
				cfiMonth
			].feeTotal +=
				pData.revenueData[cfiMonth].feeTotal * (pData.visible ? 1 : -1);
			this.projectData.get(pData.project.uuid).revenueData[
				cfiMonth
			].spendTotal +=
				pData.revenueData[cfiMonth].spendTotal *
				(pData.visible ? 1 : -1);

			["prospective", "onHold"].includes(pData.phase.status) &&
				(this.projectData.get("prospectiveGraph")[cfiMonth] +=
					pData.revenueData[cfiMonth].total *
					(pData.visible ? 1 : -1));
		});
		//TODO make more effecient
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setProjectInputText(cell, text) {
		const uuid = cell.projectPhaseUuid
			? cell.projectPhaseUuid
			: cell.projectUuid;
		let project = this.projectData.get(uuid);
		if (!project.revenueData[cell.monthIndex]) {
			project.revenueData[cell.monthIndex] = {};
		}
		project.revenueData[cell.monthIndex]["inputText"] = text;
		this.projectData.set(uuid, project);
		this.projectSpreadsheetRows = this.getProjectRows();
		this.emitChanged();
	}

	rollbackProjectInputText(cell) {
		const uuid = cell.projectPhaseUuid
			? cell.projectPhaseUuid
			: cell.projectUuid;
		let project = this.projectData.get(uuid);
		project.revenueData[cell.monthIndex]["inputText"] = null;
		if (!project.revenueData[cell.monthIndex]["total"]) {
			project.revenueData[cell.monthIndex]["total"] = 0;
		}
		this.projectData.set(uuid, project);
		this.projectSpreadsheetRows = this.getProjectRows();
		this.emitChanged();
	}

	commitProjectInputText(cell, text) {
		let settings = organisationStore.organisation.settings;
		if (cell.monthIndex >= this.currentMonthIndex) {
			const uuid = cell.projectPhaseUuid
				? cell.projectPhaseUuid
				: cell.projectUuid;
			let project = this.projectData.get(uuid);
			let revenue = parseFloat(text);
			if (cell.projectPhaseUuid) {
				project.phase.setTotalMilestoneRevenueInMonth(
					revenue,
					cell.monthIndex
				);
				if (settings.updateHoursFromRevenue) {
					project.phase.adjustAllocationsFromRevenueMonthIndex(
						cell.monthIndex
					);
				}
			} else {
				project.project.setTotalMilestoneRevenueInMonth(
					revenue,
					cell.monthIndex
				);
				if (settings.updateHoursFromRevenue) {
					project.project.getVisiblePhases().forEach((ph) => {
						ph.adjustAllocationsFromRevenueMonthIndex(
							cell.monthIndex
						);
					});
				}
			}
			this.projectData = Immutable.Map({
				total: {},
				totalRevenue: {},
				totalExpense: {},
				active: {},
				prospective: {},
				prospectiveGraph: {},
				onHold: {},
				archived: {},
			});
			this.displayedProjects = {
				active: [],
				prospective: [],
				onHold: [],
				archived: [],
			};
			this.archivedProjects = [];

			this.getAllProjectData();

			this.projectSpreadsheetRows = this.getProjectRows();
			this.graphData = this.getGraphData();
			this.profitSpreadsheetRows = this.getProfitRows();

			this.dirtyProjects.push(project.project);
			this.isDirty = true;
			this.emitChanged();
		}
	}

	adjustSidebarMilestone(uuid, monthIndex, text) {
		let project = this.projectData.get(uuid);
		let revenue = parseFloat(text);
		if (project.phase) {
			project.phase.setTotalMilestoneRevenueInMonth(revenue, monthIndex);
		} else {
			project.project.setTotalMilestoneRevenueInMonth(
				revenue,
				monthIndex
			);
		}
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];

		this.getAllProjectData();

		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();

		this.dirtyProjects.push(project.project);
		this.isDirty = true;
		this.emitChanged();
	}

	addSidebarPhase(uuid, monthIndex) {
		this.adjustSidebarMilestone(uuid, monthIndex, 0);
	}

	removePhaseMilestonesInMonth(uuid, monthIndex) {
		const phase = this.projectData.get(uuid).phase;
		phase.removeMilestonesInMonth(monthIndex);
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];

		this.getAllProjectData();

		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();

		this.dirtyProjects.push(phase.project);
		this.isDirty = true;
		this.emitChanged();
	}

	changeLikelihood(uuid, val) {
		let project = this.projectData.get(uuid).project;
		let likelihood = parseFloat(val);

		project.likelihood = likelihood;

		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];

		this.getAllProjectData();

		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();

		this.dirtyProjects.push(project);
		this.isDirty = true;
		this.emitChanged();
	}

	changeSelectedReport(uuid) {
		this.selectedReport = uuid;
		if (this.selectedReport) {
			const selectedReport = organisationStore
				.getRevenueForecastReportByUuid(this.selectedReport)
				.toJS();
			this.reportName = selectedReport.name;
			this.filters = {
				costCentres: selectedReport.filters.costCentres.map((ccId) =>
					organisationStore.getCostCentreById(ccId)
				),
				projectStatus: selectedReport.filters.projectStatus,
				projects: selectedReport.filters.projects.map((pId) =>
					organisationStore.getProjectById(pId)
				),
				staff: selectedReport.filters.staff.map((sId) =>
					organisationStore.getStaffMemberById(sId)
				),
				projectOwners:
					selectedReport.filters.projectOwners?.map((sId) =>
						organisationStore.getStaffMemberById(sId)
					) || [],
				expenses: selectedReport.filters.expenses
					.map((eId) => organisationStore.getExpenseById(eId))
					.filter((e) => e),
			};
			this.dataType = selectedReport.filters.revenueData;
			this.invoiceDateType = selectedReport.filters.invoiceData;
			this.profitDataType =
				selectedReport.filters.profitDataType || "margin";
			this.totalType =
				selectedReport.filters.totalData || "revenueTotalFee";
			this.contractorExpense =
				selectedReport.filters.contractorExpense || "allocatedHours";
			this.showExpenses = selectedReport.filters.showExpenses ?? true;
		} else {
			this.reportName = "New Report";
			this.filters = {
				costCentres: [],
				projectStatus: [],
				projects: [],
				staff: [],
				projectOwners: [],
				expenses: [],
			};
			this.dataType = "actualsProjected";
			this.invoiceDateType = "issuedOn";
			this.profitDataType = "margin";
			this.totalType = "revenueTotalFee";
			this.contractorExpense = "allocatedHours";
			this.showExpenses = true;
		}

		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.expandedProjects = [];
		this.staffData = Immutable.Map({ total: {} });
		this.displayedStaff = [];
		this.expenseData = Immutable.Map({ total: {} });
		this.displayedExpenses = [];

		organisationStore.projects.forEach((p) => {
			p.mergeChangeLog(this.invoiceDateType);
		});

		this.getAllProjectData();
		this.getStaffData();
		this.getExpenseData();

		this.projectSpreadsheetRows = this.getProjectRows();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();

		this.emitChanged();
	}

	clickSaveReportButton() {
		if (this.selectedReport) {
			this.saveReport();
		} else {
			this.openSaveReportModal();
		}
		this.emitChanged();
	}

	clickSaveAsReportButton() {
		this.selectedReport = null;
		this.openSaveReportModal();
		this.emitChanged();
	}

	clickRenameReportButton() {
		this.openRenameReportModal();
		this.emitChanged();
	}

	clickDeleteReportButton() {
		this.openDeleteReportModal();
		this.emitChanged();
	}

	saveReport(name = null) {
		let self = this;
		const UUID = this.selectedReport || generateUUID();
		const selectedReport = this.selectedReport
			? organisationStore
					.getRevenueForecastReportByUuid(this.selectedReport)
					.toJS()
			: null;

		this.save.execute(
			apiRequest({
				path: `/organisation/current/rev-report/${UUID}`,
				method: "post",
				data: {
					report: {
						uuid: UUID,
						name: name || this.reportName,
						filters: {
							revenueData: this.dataType,
							invoiceData: this.invoiceDateType,
							profitDataType: this.profitDataType,
							totalData: this.totalType,
							contractorExpense: this.contractorExpense,
							showExpenses: this.showExpenses,
							projectStatus: this.filters.projectStatus,
							costCentres: this.filters.costCentres.map(
								(item) => item?.id
							),
							projects: this.filters.projects.map(
								(item) => item?.id
							),
							staff: this.filters.staff.map((item) => item?.id),
							projectOwners: this.filters.projectOwners.map(
								(item) => item?.id
							),
							expenses: this.filters.expenses.map(
								(item) => item?.id
							),
						},
					},
				},
				success: (data) => self.saveReportSuccess(data),
				error: (data) => self.saveReportFailure(data),
			})
		);
	}

	saveReportSuccess(data) {
		organisationStore.updateRevenueReport(data.report);
		this.reports = organisationStore.revenueForecastReports;
		this.modal = null;
		this.changeSelectedReport(data.report.uuid);
		// router.history.replace({pathname: this.report.getPath()});
	}

	saveReportFailure() {
		alert(
			"There was a problem saving this report. Try again, or let us know if the problem persists."
		);
		this.reportSaveState = null;
	}

	deleteReport() {
		let self = this;
		apiRequest({
			path: `/organisation/current/rev-report/${this.selectedReport}`,
			method: "delete",
			success: (data) => self.deleteReportSuccess(data),
			error: (data) =>
				alert(
					"There was a problem deleting this report. Try again, or let us know if the problem persists."
				),
		});
	}

	setDefaultReport() {
		let self = this;
		apiRequest({
			path: `/organisation/current/default-rev-report/${this.selectedReport}`,
			method: "post",
			success: (data) => self.setDefaultReportSuccess(data),
			error: (data) =>
				alert(
					"There was a problem setting this report as the default. Try again, or let us know if the problem persists."
				),
		});
	}

	setDefaultReportSuccess(data) {
		organisationStore.organisation.defaultRevenueForecastReport =
			data.reportUUID;
		this.defaultReport = data.reportUUID;
		this.emitChanged();
	}

	deleteReportSuccess(data) {
		organisationStore.deleteRevenueReport(data.reportUUID);
		this.reports = organisationStore.revenueForecastReports;
		this.selectedReport = null;
		this.modal = null;
		this.emitChanged();
	}

	openSaveReportModal() {
		this.modal = "saveReport";
		this.emitChanged();
	}

	openRenameReportModal() {
		this.modal = "rename";
		this.emitChanged();
	}

	openDeleteReportModal() {
		this.modal = "delete";
		this.emitChanged();
	}

	closeModal() {
		this.modal = null;
		this.emitChanged();
	}

	setStaffInputText(cell, text) {
		let staffMember = _.find(
			this.staffData,
			(sm) => sm.id === cell.staffId
		);
		let item = _.find(
			staffMember.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.inputText = text;
		this.emitChanged();
	}

	rollbackStaffInputText(cell) {
		let staffMember = _.find(
			this.staffData,
			(sm) => sm.id === cell.staffId
		);
		let item = _.find(
			staffMember.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.inputText = null;
		this.emitChanged();
	}

	commitStaffInputText(cell, text) {
		let staffMember = _.find(
			this.staffData,
			(sm) => sm.id === cell.staffId
		);
		let item = _.find(
			staffMember.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.revenue = parseFloat(text);
		this.isDirty = true;
		this.emitChanged();
	}

	setExpenseInputText(cell, text) {
		let expense = _.find(
			this.expenseData,
			(e) => e.name === cell.expenseName
		);
		let item = _.find(
			expense.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.inputText = text;
		this.emitChanged();
	}

	rollbackExpenseInputText(cell) {
		let expense = _.find(
			this.expenseData,
			(e) => e.name === cell.expenseName
		);
		let item = _.find(
			expense.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.inputText = null;
		this.emitChanged();
	}

	commitExpenseInputText(cell, text) {
		let expense = _.find(
			this.expenseData,
			(e) => e.name === cell.expenseName
		);
		let item = _.find(
			expense.dateValues,
			(dv) => dv.monthIndex === cell.monthIndex
		);
		item.revenue = parseFloat(text);
		this.isDirty = true;
		this.emitChanged();
	}

	toggleStaffVisibility(uuid) {
		let sData = this.staffData.get(uuid);
		sData.visible = !sData.visible;
		this.staffData = this.staffData.set(uuid, sData);
		//TODO makes sure new data checks staff visibility on data change
		this.dateColumns.forEach((dc) => {
			if (sData.expenseData[dc.monthIndex]) {
				this.staffData.get("total")[dc.monthIndex] +=
					sData.expenseData[dc.monthIndex].total *
					(sData.visible ? 1 : -1);
			}
		});
		//TODO - make more effecient
		this.staffSpreadsheetRows = this.getStaffRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	toggleExpenseVisibility(name) {
		let eData = this.expenseData.get(name);
		eData.visible = !eData.visible;
		this.expenseData = this.expenseData.set(name, eData);
		this.dateColumns.forEach((dc) => {
			if (eData.expenseData[dc.monthIndex]) {
				this.expenseData.get("total")[dc.monthIndex] +=
					eData.expenseData[dc.monthIndex].total *
					(eData.visible ? 1 : -1);
			}
		});
		//TODO - make more effecient
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredProjects(projects) {
		this.filters.projects = projects;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredProjectStatus(projectStatuses) {
		this.filters.projectStatus = projectStatuses;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredStaff(staff) {
		this.filters.staff = staff;
		this.staffData = Immutable.Map({ total: {} });
		this.displayedStaff = [];
		this.getStaffData();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredProjectOwners(projectOwners) {
		this.filters.projectOwners = projectOwners;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredExpenses(expenses) {
		this.filters.expenses = expenses;
		this.expenseData = Immutable.Map({ total: {} });
		this.displayedExpenses = [];
		this.getExpenseData();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setFilteredCostCenters(costCentres) {
		this.filters.costCentres = costCentres;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.staffData = Immutable.Map({ total: {} });
		this.displayedStaff = [];
		this.expenseData = Immutable.Map({ total: {} });
		this.displayedExpenses = [];
		this.getAllProjectData();
		this.getStaffData();
		this.getExpenseData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setDataType(dataType) {
		this.dataType = dataType;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setProfitDataType(profitDataType) {
		this.profitDataType = profitDataType;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	setInvoiceDateType(invoiceDateType) {
		organisationStore.projects.forEach((p) => {
			p.mergeChangeLog(invoiceDateType);
		});
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.invoiceDateType = invoiceDateType;
		this.emitChanged();
	}

	setTotalType(totalType) {
		this.totalType = totalType;
		this.projectSpreadsheetRows = this.getProjectRows();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.emitChanged();
	}

	setContractorExpense(contractorExpense) {
		this.contractorExpense = contractorExpense;
		this.displayedStaff = [];
		this.staffData = Immutable.Map({ total: {} });
		this.getStaffData();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.graphData = this.getGraphData();
		this.emitChanged();
	}

	toggleShowExpenses(showExpenses) {
		this.showExpenses = showExpenses ?? !this.showExpenses;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.getAllProjectData();
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
		this.emitChanged();
	}

	selectCell(cell) {
		if (cell) {
			this.selectedCell = cell;
			this.showSidebar = true;
		} else {
			this.selectedCell = null;
			this.showSidebar = false;
		}
		this.emitChanged();
	}

	getSelectedCashflowItems() {
		const cell = this.selectedCell;
		const uuid =
			cell && cell.projectPhaseUuid
				? cell.projectPhaseUuid
				: cell && cell.projectUuid
				? cell.projectUuid
				: null;
		const cfis =
			uuid && this.projectData.get(uuid)
				? this.projectData.get(uuid).cashflowItems
				: [];
		return cfis;
	}

	saveAll() {
		let projects = [...new Set(this.dirtyProjects)];
		this.projectSaveState = "saving";
		this.emitChanged();
		chainRequests(
			projects.map((p) => {
				const serializedProject = p.serialize();
				return () =>
					apiRequest({
						url: `/organisation/current/project/${p.id}`,
						method: "post",
						data: {
							// project: serializedProject,
							project: {
								id: serializedProject.id,
								phases: serializedProject.phases.map((ph) => {
									return {
										id: ph.id,
										uuid: ph.uuid,
										allocations: ph.allocations,
										milestones: ph.milestones,
									};
								}),
							},
						},
					});
			})
		).then((responseArr) => {
			actions.saveAllSuccess(projects, responseArr);
		});
	}

	saveAllSuccess(projects, { projectIds, objects, phaseUuidToIdLookup }) {
		let self = this;
		this.dirtyProjects = [];
		this.isDirty = false;
		this.projectSaveState = null;
		this.emitChanged();
	}

	*iterVisibleProjects() {
		const user = userStore.user;

		for (let p of organisationStore.projects) {
			if (user.isAdmin || getProjectPermissionLevel(p, user) != null) {
				yield p;
			}
		}
	}

	moveBy(numMonths) {
		this.moveMonths += numMonths;
		_.debounce(() => {
			this.startMonth += this.moveMonths;
			this.endMonth += this.moveMonths;
			if (this.selectedCell) {
				this.selectedCell.monthIndex += this.moveMonths;
			}
			const prevMonths = this.renderedMonths;
			this.dateColumns = this.getDateColumns();
			const currentMonths = this.dateColumns.map((dc) => dc.monthIndex);
			const newMonths = _.difference(currentMonths, prevMonths);
			this.moveMonths = 0;
			this.renderedMonths = [...prevMonths, ...newMonths];
			this.updateProjectDataFromDateChange();
			this.updateStaffDataFromDateChange(newMonths);
			this.expenseData = Immutable.Map({ total: {} });
			this.displayedExpenses = [];
			this.getExpenseData();
			// this.updateExpenseDataFromDateChange(newMonths);

			this.projectSpreadsheetRows = this.getProjectRows();
			this.staffSpreadsheetRows = this.getStaffRows();
			this.expenseSpreadsheetRows = this.getExpenseRows();
			this.graphData = this.getGraphData();
			this.profitSpreadsheetRows = this.getProfitRows();

			this.emitChanged();
		}, 300)();
	}

	moveLeft() {
		this.moveBy(-1);
	}

	moveRight() {
		this.moveBy(+1);
	}

	getColumns() {
		const rowHeadings = [
			"Title",
			<div style={{ width: "100%" }} className="flexbox-container">
				<div className="flex-1-0-auto">
					{this.totalType == "revenueTotalFee"
						? "Total"
						: "Remaining"}
				</div>
				<div className="flex-0-1-auto" style={{ fontSize: "1.6em" }}>
					<i
						className={`fa fa-caret-left`}
						style={{ cursor: "pointer" }}
						onClick={() => actions.moveLeft()}
					/>
					<i
						className={`fa fa-caret-right`}
						style={{ marginRight: 0, cursor: "pointer" }}
						onClick={() => actions.moveRight()}
					/>
				</div>
			</div>,
		];
		const columns = [
			...rowHeadings.map((v) => {
				return {
					value: v,
					isColumnHeader: true,
					isRowHeader: true,
					isEditable: false,
				};
			}),
			...this.dateColumns,
		];
		return columns;
	}

	getProjectRows() {
		const currencyFormatter =
			organisationStore.organisation.currencyFormatter;
		const columns = this.getColumns();
		const dateColumns = this.dateColumns;
		const statusHeadings = {
			active: "Active Projects",
			prospective: "Prospective Projects",
			onHold: "On Hold Projects",
			archived: "Archived Projects",
		};
		let projects = [
			...this.displayedProjects["active"],
			...this.displayedProjects["prospective"],
			...this.displayedProjects["onHold"],
			...this.displayedProjects["archived"],
		];
		const sumFees = sum(projects.map((p) => p.fee * (p.likelihood / 100)));
		const sumTotalRevenue = sum(
			["active", "prospective", "onHold", "archived"].map(
				(status) => this.projectData.get(status).totalAgreedFee || 0
			)
		);
		const rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			{
				rowType: "sub-total",
				cells: [
					{
						value: "Total Revenue",
						isRowHeader: true,
						isEditable: false,
					},
					{
						value:
							this.totalType == "revenueTotalFee"
								? `${currencyFormatter.format(
										sumTotalRevenue
								  )} / ${currencyFormatter.format(sumFees)}`
								: `${currencyFormatter.format(
										sumFees - sumTotalRevenue
								  )}`,
						isRowHeader: true,
						isEditable: false,
					},
					...this.getGraphData().map((d) => ({
						value: currencyFormatter.format(d.income),
						numValue: d.income,
						isEditable: false,
					})),
				],
			},
		];
		["active", "prospective", "onHold", "archived"].forEach((status) => {
			let projects = _.sortBy(this.displayedProjects[status], (p) =>
				p.getTitle()
			);
			const sumFees = sum(
				projects.map((p) => p.fee * (p.likelihood / 100))
			);
			if (projects.length > 0) {
				rows.push({
					rowType:
						status === "active" ? "sub-total shadow" : "sub-total",
					cells: [
						{
							value: statusHeadings[status],
							isRowHeader: true,
							isEditable: false,
						},
						{
							value:
								this.totalType == "revenueTotalFee"
									? `${currencyFormatter.format(
											this.projectData.get(status)
												.totalAgreedFee
									  )} / ${currencyFormatter.format(sumFees)}`
									: `${currencyFormatter.format(
											sumFees -
												this.projectData.get(status)
													.totalAgreedFee
									  )}`,
							isRowHeader: true,
							isEditable: false,
						},
						...dateColumns.map((d) => {
							return {
								value: currencyFormatter.format(
									this.projectData.get(status)[d.monthIndex]
										? this.projectData.get(status)[
												d.monthIndex
										  ]
										: 0
								),
								numValue: this.projectData.get(status)[
									d.monthIndex
								]
									? this.projectData.get(status)[d.monthIndex]
									: 0,
								monthIndex: d.monthIndex,
								isColumnHeader: true,
								isEditable: false,
							};
						}),
					],
				});

				projects.forEach((p, i) => {
					const pData = this.projectData.get(p.uuid);
					const revData = pData.revenueData;
					const agreedFee = sum(
						p
							.getVisiblePhases()
							.map((ph) => ph.fee * (ph.likelihood / 100))
					);
					rows.push({
						rowType: i === 0 ? "shadow" : "",
						cells: [
							{
								value: p.getTitle(),
								cellType: "projectTitle",
								projectUuid: p.uuid,
								isRowHeader: true,
								isEditable: false,
								expanded: pData.expanded,
								visible: pData.visible,
							},
							{
								value:
									this.totalType == "revenueTotalFee"
										? `${currencyFormatter.format(
												revData.totalAgreedFee
										  )} / ${currencyFormatter.format(
												agreedFee
										  )}`
										: `${currencyFormatter.format(
												agreedFee -
													revData.totalAgreedFee
										  )}`,
								isRowHeader: true,
								isEditable: false,
								error: agreedFee
									? p
											.getVisiblePhases()
											.some(
												(ph) =>
													Math.round(
														ph.fee *
															(ph.likelihood /
																100) -
															this.projectData.get(
																ph.uuid
															).revenueData
																.totalAgreedFee
													) < 0
											)
									: false,
								visible: pData.visible,
							},
							...dateColumns.map((d) => {
								const monthData = revData[d.monthIndex];
								const monthTotal = monthData
									? monthData.total
									: 0;
								const monthInput = monthData
									? monthData.inputText
									: "";
								const agreedFeeToDate = monthData
									? monthData.agreedFeeToDate
									: 0;
								return {
									value: currencyFormatter.format(monthTotal),
									numValue: monthTotal,
									projectUuid: p.uuid,
									inputText: monthInput,
									monthIndex: d.monthIndex,
									visible: pData.visible,
									inRange:
										p.startMonthIndex &&
										p.endMonthIndex &&
										d.monthIndex >= p.startMonthIndex &&
										d.monthIndex <= p.endMonthIndex,
									isProject: true,
									isEditable: permissions
										.canEditProject(p)
										.ok(userStore.user),
									error: p.fee
										? Math.round(p.fee - agreedFeeToDate) <
												0 && monthTotal > 0
										: false,
								};
							}),
						],
					});
					if (pData.expanded) {
						p.getVisiblePhases().forEach((ph, i) => {
							const phData = this.projectData.get(ph.uuid);
							const revData = phData.revenueData;
							rows.push({
								rowType: i === 0 ? "child shadow" : "child",
								cells: [
									{
										value: ph.getTitle(),
										projectUuid: p.uuid,
										projectPhaseUuid: ph.uuid,
										cellType: "projectPhaseTitle",
										isRowHeader: true,
										isEditable: false,
										visible:
											pData.visible && phData.visible,
									},
									{
										value:
											this.totalType == "revenueTotalFee"
												? `${currencyFormatter.format(
														revData.totalAgreedFee
												  )} / ${currencyFormatter.format(
														ph.fee *
															(ph.likelihood /
																100)
												  )}`
												: `${currencyFormatter.format(
														ph.fee *
															(ph.likelihood /
																100) -
															revData.totalAgreedFee
												  )}`,
										isRowHeader: true,
										isEditable: false,
										error: ph.fee
											? Math.round(
													ph.fee *
														(ph.likelihood / 100) -
														revData.totalAgreedFee
											  ) < 0
											: false,
										visible:
											pData.visible && phData.visible,
									},
									...dateColumns.map((d) => {
										const monthData = revData[d.monthIndex];
										const monthTotal = monthData
											? monthData.total
											: 0;
										const monthInput = monthData
											? monthData.inputText
											: "";
										const agreedFeeToDate = monthData
											? monthData.agreedFeeToDate
											: 0;
										return {
											value: currencyFormatter.format(
												monthTotal
											),
											numValue: monthTotal,
											projectUuid: p.uuid,
											projectPhaseUuid: ph.uuid,
											inputText: monthInput,
											monthIndex: d.monthIndex,
											visible:
												pData.visible && phData.visible,
											inRange:
												ph.startMonthIndex &&
												ph.endMonthIndex &&
												d.monthIndex >=
													ph.startMonthIndex &&
												d.monthIndex <=
													ph.endMonthIndex,
											isProject: true,
											isEditable: permissions
												.canEditProject(p)
												.ok(userStore.user),
											error: ph.fee
												? Math.round(
														ph.fee - agreedFeeToDate
												  ) < 0 && monthTotal > 0
												: false,
										};
									}),
								],
							});
						});
						const noPhData = this.projectData.get(
							p.getProjectNoPhase().uuid
						);
						if (noPhData) {
							const revData = noPhData.revenueData;
							rows.push({
								rowType: "child",
								cells: [
									{
										value: "(No Phase)",
										projectUuid: p.uuid,
										projectPhaseUuid:
											p.getProjectNoPhase().uuid,
										cellType: "projectPhaseTitle",
										isRowHeader: true,
										isEditable: false,
										visible:
											pData.visible && noPhData.visible,
									},
									{
										value:
											this.totalType == "revenueTotalFee"
												? `${currencyFormatter.format(
														revData.totalAgreedFee
												  )}`
												: `-`,
										isRowHeader: true,
										isEditable: false,
										visible:
											pData.visible && noPhData.visible,
									},
									...dateColumns.map((d) => {
										const monthData = revData[d.monthIndex];
										const monthTotal = monthData
											? monthData.total
											: 0;
										const monthInput = monthData
											? monthData.inputText
											: "";
										return {
											value: currencyFormatter.format(
												monthTotal
											),
											numValue: monthTotal,
											projectUuid: p.uuid,
											projectPhaseUuid:
												p.getProjectNoPhase().uuid,
											inputText: monthInput,
											monthIndex: d.monthIndex,
											visible:
												pData.visible &&
												noPhData.visible,
											isEditable: permissions
												.canEditProject(p)
												.ok(userStore.user),
											isProject: true,
										};
									}),
								],
							});
						}
					}
				});
			}
		});
		return rows;
	}

	getProfitRows() {
		const currencyFormatter =
			organisationStore.organisation.currencyFormatter;
		const columns = this.getColumns();
		const hasProspectiveData = this.graphData.some(
			(d) => d.prospectiveIncome
		);
		let rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			{
				rowType: hasProspectiveData ? "sub-total" : null,
				cells: [
					{
						value: "Profit",
						isRowHeader: true,
						isEditable: false,
					},
					{ value: "", isRowHeader: true, isEditable: false },
					...this.graphData.map((d) => ({
						value: currencyFormatter.format(d.income - d.spend),
						numValue: d.income - d.spend,
						isEditable: false,
					})),
				],
			},
		];
		hasProspectiveData &&
			rows.push({
				rowType: "child shadow",
				cells: [
					{
						value: "Active Profit",
						isRowHeader: true,
						isEditable: false,
					},
					{ value: "", isRowHeader: true, isEditable: false },
					...this.graphData.map((d) => ({
						value: currencyFormatter.format(
							d.income - d.spend - d.prospectiveIncome
						),
						numValue: d.income - d.spend - d.prospectiveIncome,
						isEditable: false,
					})),
				],
			});
		rows.push({
			rowType: hasProspectiveData ? "sub-total" : null,
			cells: [
				{
					value:
						this.profitDataType === "margin"
							? "Profit Margin"
							: "Markup",
					isRowHeader: true,
					isEditable: false,
				},
				{ value: "", isRowHeader: true, isEditable: false },
				...this.graphData.map((d) => ({
					value:
						this.profitDataType === "margin"
							? Number.isFinite((d.income - d.spend) / d.income)
								? `${Math.round(
										((d.income - d.spend) / d.income) * 100
								  )}%`
								: "NA"
							: Number.isFinite((d.income - d.spend) / d.spend)
							? `${Math.round(
									((d.income - d.spend) / d.spend) * 100
							  )}%`
							: "NA",
					isEditable: false,
				})),
			],
		});
		hasProspectiveData &&
			rows.push({
				rowType: "child shadow",
				cells: [
					{
						value:
							this.profitDataType === "margin"
								? "Active Profit Margin"
								: "Active Markup",
						isRowHeader: true,
						isEditable: false,
					},
					{ value: "", isRowHeader: true, isEditable: false },
					...this.graphData.map((d) => ({
						value:
							this.profitDataType === "margin"
								? Number.isFinite(
										(d.income -
											d.spend -
											d.prospectiveIncome) /
											d.income
								  )
									? `${Math.round(
											((d.income -
												d.spend -
												d.prospectiveIncome) /
												d.income) *
												100
									  )}%`
									: "NA"
								: Number.isFinite(
										(d.income -
											d.spend -
											d.prospectiveIncome) /
											d.spend
								  )
								? `${Math.round(
										((d.income -
											d.spend -
											d.prospectiveIncome) /
											d.spend) *
											100
								  )}%`
								: "NA",
						isEditable: false,
					})),
				],
			});
		return rows;
	}

	getStaffRows() {
		const currencyFormatter =
			organisationStore.organisation.currencyFormatter;
		const columns = this.getColumns();
		const dateColumns = this.dateColumns;
		const rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			{
				rowType: "sub-total",
				cells: [
					{
						value: "Staff Total",
						isRowHeader: true,
						isEditable: false,
					},
					{ value: "", isRowHeader: true, isEditable: false },
					...dateColumns.map((d) => {
						return {
							value: currencyFormatter.format(
								this.staffData.get("total")[d.monthIndex]
									? this.staffData.get("total")[d.monthIndex]
									: 0
							),
							numValue: this.staffData.get("total")[d.monthIndex]
								? this.staffData.get("total")[d.monthIndex]
								: 0,
							monthIndex: d.monthIndex,
							isColumnHeader: true,
							isEditable: false,
						};
					}),
				],
			},
			..._.sortBy(this.displayedStaff, (s) => s.getFullName()).map(
				(sm) => {
					const staffData = this.staffData.get(sm.uuid);
					const expenseData = staffData.expenseData;
					return {
						cells: [
							{
								value: sm.getFullName(),
								cellType: "staffTitle",
								staffUuid: sm.uuid,
								isRowHeader: true,
								isEditable: false,
								visible: staffData.visible,
							},
							{
								value: ``,
								isRowHeader: true,
								isEditable: false,
								visible: staffData.visible,
							},
							...dateColumns.map((d) => {
								const monthData = expenseData[d.monthIndex];
								const monthTotal = monthData
									? monthData.total
									: 0;
								const monthInput = monthData
									? monthData.inputText
									: "";
								return {
									value: currencyFormatter.format(monthTotal),
									numValue: monthTotal,
									staffUuid: sm.uuid,
									inputText: monthInput,
									monthIndex: d.monthIndex,
									isEditable: false,
									visible: staffData.visible,
								};
							}),
						],
					};
				}
			),
		];
		return rows;
	}

	getExpenseRows() {
		const currencyFormatter =
			organisationStore.organisation.currencyFormatter;
		const columns = this.getColumns();
		const dateColumns = this.dateColumns;
		const rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			{
				rowType: "sub-total",
				cells: [
					{
						value: "Overhead Total",
						isRowHeader: true,
						isEditable: false,
					},
					{ value: "", isRowHeader: true, isEditable: false },
					...dateColumns.map((d) => {
						return {
							value: currencyFormatter.format(
								this.expenseData.get("total")[d.monthIndex]
									? this.expenseData.get("total")[
											d.monthIndex
									  ]
									: 0
							),
							numValue: this.expenseData.get("total")[
								d.monthIndex
							]
								? this.expenseData.get("total")[d.monthIndex]
								: 0,
							monthIndex: d.monthIndex,
							isColumnHeader: true,
							isEditable: false,
						};
					}),
				],
			},
			..._.sortBy(this.displayedExpenses, (e) => e.name).map((e) => {
				const expenseData = this.expenseData.get(e.name);
				const expenseDataVals = expenseData.expenseData;
				return {
					cells: [
						{
							value: e.name,
							cellType: "expenseTitle",
							expenseName: e.name,
							isRowHeader: true,
							isEditable: false,
							visible: expenseData.visible,
						},
						{
							value: ``,
							isRowHeader: true,
							isEditable: false,
							visible: expenseData.visible,
						},
						...dateColumns.map((d) => {
							const monthData = expenseDataVals[d.monthIndex];
							const monthTotal = monthData ? monthData.total : 0;
							const monthInput = monthData
								? monthData.inputText
								: "";
							return {
								value: currencyFormatter.format(monthTotal),
								numValue: monthTotal,
								expenseName: e.name,
								inputText: monthInput,
								monthIndex: d.monthIndex,
								isEditable: false,
								visible: expenseData.visible,
							};
						}),
					],
				};
			}),
		];
		return rows;
	}

	getGraphData() {
		return this.dateColumns.map((dc, i) => {
			return {
				date: dc.dateInt,
				monthIndex: dc.monthIndex,
				income:
					(this.projectData.get("totalRevenue")[dc.monthIndex]
						? this.projectData.get("totalRevenue")[dc.monthIndex]
						: 0) -
					(this.projectData.get("totalExpense")[dc.monthIndex]
						? this.projectData.get("totalExpense")[dc.monthIndex]
						: 0) +
					(dc.monthIndex < this.currentMonthIndex &&
					this.projectData.get("prospectiveGraph")[dc.monthIndex]
						? this.projectData.get("prospectiveGraph")[
								dc.monthIndex
						  ]
						: 0),
				spend:
					(userStore.isAdmin() &&
					this.staffData.get("total")[dc.monthIndex]
						? this.staffData.get("total")[dc.monthIndex]
						: 0) +
					(userStore.isAdmin() &&
					this.expenseData.get("total")[dc.monthIndex]
						? this.expenseData.get("total")[dc.monthIndex]
						: 0),
				prospectiveIncome:
					dc.monthIndex >= this.currentMonthIndex &&
					this.projectData.get("prospectiveGraph")[dc.monthIndex]
						? this.projectData.get("prospectiveGraph")[
								dc.monthIndex
						  ]
						: 0,
			};
		});
	}

	printSpreadsheet(isPrinting) {
		this.isPrinting = isPrinting;
		this.emitChanged();
	}

	openAutoAdjustModal() {
		this.modal = "autoAdjust";
		this.emitChanged();
	}

	autoAdjustProjects() {
		["active", "prospective", "onHold"].forEach((status) => {
			let projects = this.displayedProjects[status];
			this.dirtyProjects.push(
				...autoAdjustRevenue({ projects: projects })
			);
		});
		this.isDirty = true;
		this.projectData = Immutable.Map({
			total: {},
			totalRevenue: {},
			totalExpense: {},
			active: {},
			prospective: {},
			prospectiveGraph: {},
			onHold: {},
			archived: {},
		});
		this.displayedProjects = {
			active: [],
			prospective: [],
			onHold: [],
			archived: [],
		};
		this.archivedProjects = [];
		this.expandedProjects = [];
		this.staffData = Immutable.Map({ total: {} });
		this.displayedStaff = [];
		this.expenseData = Immutable.Map({ total: {} });
		this.displayedExpenses = [];

		this.getAllProjectData();
		this.getStaffData();
		this.getExpenseData();

		this.projectSpreadsheetRows = this.getProjectRows();
		this.staffSpreadsheetRows = this.getStaffRows();
		this.expenseSpreadsheetRows = this.getExpenseRows();
		this.graphData = this.getGraphData();
		this.profitSpreadsheetRows = this.getProfitRows();
		this.emitChanged();
	}

	exportSpreadsheet() {
		let data = [];
		["active", "prospective", "onHold", "archived"].forEach((status) => {
			let projects = this.displayedProjects[status];
			projects.forEach((p) => {
				p.getVisiblePhases().forEach((ph) => {
					let rowData = {};
					rowData["Name"] = `${p.getTitle()}: ${ph.getTitle()}`;
					rowData["Cost Centre"] = p.costCentre.name;
					this.dateColumns.forEach((d) => {
						const revData = this.projectData.get(ph.uuid)
							.revenueData[d.monthIndex];
						rowData[d.string] = revData ? revData.total : 0;
					});
					data.push(rowData);
				});
				const noPhData = this.projectData.get(
					p.getProjectNoPhase().uuid
				);
				if (noPhData) {
					let rowData = {};
					rowData["Name"] = `${p.getTitle()}: No Phase`;
					rowData["Cost Centre"] = p.costCentre.name;
					this.dateColumns.forEach((d) => {
						const revData = noPhData.revenueData[d.monthIndex];
						rowData[d.string] = revData ? revData.total : 0;
					});
					data.push(rowData);
				}
			});
		});

		this.displayedStaff.forEach((sm) => {
			let rowData = {};
			rowData["Name"] = sm.getFullName();
			rowData["Cost Centre"] = sm.costCentre.name;
			this.dateColumns.forEach((d) => {
				const expData = this.staffData.get(sm.uuid).expenseData[
					d.monthIndex
				];
				rowData[d.string] = expData ? expData.total : 0;
			});
			data.push(rowData);
		});

		this.displayedExpenses.forEach((e) => {
			let rowData = {};
			rowData["Name"] = e.name;
			rowData["Cost Centre"] = e.expense.costCentre.name;
			this.dateColumns.forEach((d) => {
				const expData = this.expenseData.get(e.name).expenseData[
					d.monthIndex
				];
				rowData[d.string] = expData ? expData.total : 0;
			});
			data.push(rowData);
		});

		let csvContent = Papa.unparse(data);

		let download = function (content, fileName, mimeType) {
			let a = document.createElement("a");
			mimeType = mimeType || "application/octet-stream";

			if (navigator.msSaveBlob) {
				// IE10
				navigator.msSaveBlob(
					new Blob([content], {
						type: mimeType,
					}),
					fileName
				);
			} else if (URL && "download" in a) {
				//html5 A[download]
				a.href = URL.createObjectURL(
					new Blob([content], {
						type: mimeType,
					})
				);
				a.setAttribute("download", fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} else {
				window.location.href =
					"data:application/octet-stream," +
					encodeURIComponent(content); // only this mime type is supported
			}
		};

		download(csvContent, "revenue-forecast.csv", "text/csv;encoding:utf-8");
	}
};

export let spreadsheetStore = new SpreadsheetStore();

export let actions = new ActionCollection(
	"NEW_SPREADSHEET_",
	spreadsheetStore,
	[
		{ name: "moveLeft", args: [], callback: "default" },
		{ name: "moveRight", args: [], callback: "default" },
		{ name: "toggleProjectExpand", args: ["uuid"], callback: "default" },
		{
			name: "toggleProjectVisibility",
			args: ["uuid"],
			callback: "default",
		},
		{
			name: "toggleProjectPhaseVisibility",
			args: ["uuid"],
			callback: "default",
		},
		{ name: "toggleStaffVisibility", args: ["uuid"], callback: "default" },
		{
			name: "toggleExpenseVisibility",
			args: ["name"],
			callback: "default",
		},
		{ name: "setDataType", args: ["dataType"], callback: "default" },
		{
			name: "setProfitDataType",
			args: ["profitDataType"],
			callback: "default",
		},
		{
			name: "setInvoiceDateType",
			args: ["invoiceDateType"],
			callback: "default",
		},
		{ name: "setTotalType", args: ["totalType"], callback: "default" },
		{
			name: "setContractorExpense",
			args: ["contractorExpense"],
			callback: "default",
		},
		{
			name: "toggleShowExpenses",
			args: ["showExpenses"],
			callback: "default",
		},

		{
			name: "setProjectInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{
			name: "rollbackProjectInputText",
			args: ["cell"],
			callback: "default",
		},
		{
			name: "commitProjectInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{
			name: "adjustSidebarMilestone",
			args: ["uuid", "monthIndex", "text"],
			callback: "default",
		},
		{
			name: "addSidebarPhase",
			args: ["uuid", "monthIndex"],
			callback: "default",
		},
		{
			name: "removePhaseMilestonesInMonth",
			args: ["uuid", "monthIndex"],
			callback: "default",
		},

		{
			name: "changeLikelihood",
			args: ["uuid", "text"],
			callback: "default",
		},
		{ name: "changeSelectedReport", args: ["uuid"], callback: "default" },

		{
			name: "setStaffInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{ name: "rollbackStaffInputText", args: ["cell"], callback: "default" },
		{
			name: "commitStaffInputText",
			args: ["cell", "text"],
			callback: "default",
		},

		{
			name: "setExpenseInputText",
			args: ["cell", "text"],
			callback: "default",
		},
		{
			name: "rollbackExpenseInputText",
			args: ["cell"],
			callback: "default",
		},
		{
			name: "commitExpenseInputText",
			args: ["cell", "text"],
			callback: "default",
		},

		{ name: "saveAll", args: [], callback: "default" },
		{
			name: "saveAllSuccess",
			args: ["projects", "data"],
			callback: "default",
		},

		{
			name: "setFilteredProjects",
			args: ["projects"],
			callback: "default",
		},
		{
			name: "setFilteredCostCenters",
			args: ["costCentres"],
			callback: "default",
		},
		{ name: "setFilteredStaff", args: ["staff"], callback: "default" },
		{
			name: "setFilteredProjectOwners",
			args: ["staff"],
			callback: "default",
		},
		{
			name: "setFilteredProjectStatus",
			args: ["projectStatuses"],
			callback: "default",
		},
		{
			name: "setFilteredExpenses",
			args: ["expenses"],
			callback: "default",
		},
		{ name: "toggleFilters", args: [], callback: "default" },
		{ name: "selectCell", args: ["cell"], callback: "default" },
		{ name: "exportSpreadsheet", args: ["cell"], callback: "default" },
		{ name: "openAutoAdjustModal", args: [], callback: "default" },
		{ name: "autoAdjustProjects", args: [], callback: "default" },
		{ name: "printSpreadsheet", args: ["isPrinting"], callback: "default" },

		{ name: "clickSaveReportButton", args: [], callback: "default" },
		{ name: "clickSaveAsReportButton", args: [], callback: "default" },
		{ name: "clickRenameReportButton", args: [], callback: "default" },
		{ name: "clickDeleteReportButton", args: [], callback: "default" },
		{ name: "closeModal", args: [], callback: "default" },
		{ name: "saveReport", args: ["name"], callback: "default" },
		{ name: "deleteReport", args: [], callback: "default" },
		{ name: "setDefaultReport", args: [], callback: "default" },
	],
	dispatcher,
	function (action) {}
).actionsDict;
