import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { SaveButton } from '../widgets.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PropTypes from "prop-types";


export var ReportNameModal = CreateReactClass({
  propTypes: {
    modal: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    initialName: PropTypes.string,
    instructions: PropTypes.string,
    saveButtonText: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      closeModal: PropTypes.func.isRequired
    }).isRequired
  },

  getDefaultProps: function() {
    return {
      saveButtonText: "Save report"
    };
  },

  mixins: [
    PureRenderMixin
  ],

  getInitialState: function() {
    return {
      name: this.props.initialName,
      isLoaded: false
    };
  },

  render: function() {
    let self = this;

    return (
      <Modal
          className="report-name-modal"
          closeOnClickOutside={true}
          onClosed={() => this.props.actions.closeModal(this.props.modal)}>
        <ModalContent header={this.props.header} width='45em'>
          <div>
            <div style={{padding: '2em'}}>
              {this.props.instructions != null ?
                <p style={{marginBottom: '1em'}}>{this.props.instructions}</p>
              : null}
              <div>
                <label>
                  Report Name:
                </label>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={(event) => this.setState({name: event.target.value})}
                  onKeyUp={function(event) {
                    if (event.key === 'Enter') {
                      self.props.onSave(self.state.name);
                    }
                  }}
                  ref={function(textInput) {
                    if (!self.state.isLoaded) {
                      setTimeout(function() {
                        if (textInput != null) {
                          textInput.focus();
                          textInput.select();
                          self.setState({isLoaded: true});
                        }
                      }, 0);
                    }
                  }}
                  style={{width: '20em'}}
                />
              </div>
            </div>
            <div style={{padding: '1em', textAlign: 'right', borderTop: 'solid 1px #ccc'}}>
              <a
                  href="javascript:void(0)"
                  onClick={() => this.props.actions.closeModal(this.props.modal)}>
                Cancel
              </a>
              <SaveButton
                text={this.props.saveButtonText}
                onClick={() => this.props.onSave(this.state.name)}
                style={{marginLeft: '2em'}}
              />
            </div>
          </div>
        </ModalContent>
      </Modal>
    );
  }
});
