import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { BasicMySelect2 } from "../widgets/generic.js";
import { Alert, ErrorAlert } from "../widgets/alerts.js";
import { VerticalCheckboxList } from "../widgets/VerticalCheckboxList.js";
import { RetrievingContactsFromAccountingSystem } from "./RetrievingContactsFromAccountingSystem.js";
import {
	organisationStore,
	actions as organisationActions,
} from "../organisation.js";
import { invoicePageStore } from "../invoices/InvoicePageStore.js";
import { rootStore } from "../RootStore.js";
import { ConnectionState } from "../invoices/ConnectionState.js";
import { accountingSystems } from "../invoices/accounting.js";
import { AccountingSystemAuth } from "../invoices/AccountingSystemAuth.js";
import { IntercomMessageLink } from "../widgets/IntercomMessageLink.js";
import PropTypes from "prop-types";

export var ContactImportForm = CreateReactClass({
	propTypes: {
		retrieveContacts: PropTypes.any,
		importContacts: PropTypes.any,
		retrievedContacts: PropTypes.any,
		selectedRetrievedContacts: PropTypes.any,
	},

	mixins: [
		makeMultipleStoreMixin(
			[rootStore, organisationStore, invoicePageStore],
			function () {
				// `invoicePageStore` is the store that `emitChange`s on connect.

				const invoiceSettingsState =
					organisationStore.invoiceSettingsState;
				return {
					...invoiceSettingsState,
					accountingSystemName:
						invoiceSettingsState.accountingSystem.name,
				};
			}
		),
	],

	render: function () {
		const hasExternalContacts =
			this.props.retrievedContacts != null &&
			this.props.retrievedContacts.length > 0;

		const accountingSystemAuthenticated =
			this.state.accountingSystem != null &&
			this.state.accountingSystem.connection != null &&
			this.state.accountingSystem.connection.state ===
				ConnectionState.connected;

		return (
			<div>
				<Alert
					alertType={accountingSystemAuthenticated ? "success" : null}
					className={
						"flexbox-container flex-align-items-center flex-space-between"
					}
					style={{ padding: "1em", borderBottom: "solid 1px #ccc" }}
				>
					<div
						style={{ marginRight: "1em" }}
						className="flexbox-container flex-align-items-center "
					>
						<div
							style={{
								paddingRight: "1em",
								display: "inline-block",
							}}
						>
							Accounting system:
						</div>
						<BasicMySelect2
							value={this.state.accountingSystem.identifier}
							className="accounting-system-settings__accounting-system-select"
							options={accountingSystems.map(function (
								AccountingSystemClass
							) {
								const ac = new AccountingSystemClass({});
								return {
									value: ac.identifier,
									label: ac.name,
								};
							})}
							onChange={(accountingSystemId) =>
								organisationActions.invoiceSettingsSelectAccountingSystem(
									accountingSystemId
								)
							}
						/>
					</div>
					{this.state.accountingSystem.identifier !== "none" ? (
						<AccountingSystemAuth
							accountingSystem={
								this.state.accountingSystem.identifier
							}
						/>
					) : null}
				</Alert>

				<div style={{ padding: "1em" }}>
					{this.state.accountingSystem.identifier === "none" ||
					!accountingSystemAuthenticated ? (
						<p>
							To import contacts, please connect to an accounting
							system.
						</p>
					) : (
						<div>
							{this.props.retrieveContacts.isExecuting ? (
								<RetrievingContactsFromAccountingSystem
									accountingSystem={
										this.state.accountingSystem
									}
								/>
							) : this.props.retrieveContacts.hasFailed ? (
								<ErrorAlert>
									<p>
										There was a problem retrieving your
										contacts.
									</p>
									<p>
										You can try again, or if the problem
										persists,{" "}
										<IntercomMessageLink label="contact us" />
										.
									</p>
									<p>
										<button
											className="btn btn-default btn-sm"
											onClick={() =>
												this.props.actions.retrieveContacts()
											}
										>
											Retry
										</button>
									</p>
								</ErrorAlert>
							) : null}

							{this.props.importContacts.isUntouched &&
							this.props.retrieveContacts.isUntouched &&
							!hasExternalContacts ? (
								this.renderRetrieveButton()
							) : this.props.retrieveContacts.hasSucceeded &&
							  !hasExternalContacts ? (
								<div>
									<p>
										We didn't find any new contacts to
										import.
									</p>
								</div>
							) : !this.props.retrieveContacts.isExecuting ? (
								<div>{this.renderContactList()}</div>
							) : null}
						</div>
					)}
				</div>
			</div>
		);
	},

	renderRetrieveButton: function () {
		return (
			<div>
				<p>
					We will first retrieve your contacts from{" "}
					{this.state.accountingSystemName}.
				</p>
				<p>
					<button
						className="btn btn-default btn-lg"
						onClick={() => this.props.actions.retrieveContacts()}
					>
						Retrieve {this.state.accountingSystemName} contacts
					</button>
				</p>
			</div>
		);
	},

	renderContactList: function () {
		let existingIds = [];
		let contactsByAccountingId = {};
		const alreadyExists = (c1) => {
			let c2 = contactsByAccountingId[c1.accountingSystemContactId];
			return (
				c1.firstName === c2?.firstName &&
				c1.lastName === c2?.lastName &&
				c1.contactOrganisationName === c2?.contactOrganisationName &&
				c1.accountingSystemContactId === c2?.accountingSystemContactId
			);
		};
		organisationStore.contacts.forEach((c) => {
			if (c.id && c.accountingSystemContactId) {
				existingIds.push(c.accountingSystemContactId);
				contactsByAccountingId[c.accountingSystemContactId] = c;
			}
		});

		return (
			<div>
				<div style={{ padding: "1em" }}>
					<div style={{ width: "34em" }}>
						<VerticalCheckboxList
							options={this.props.retrievedContacts.map((c) => ({
								label:
									(c.contactOrganisationName
										? c.contactOrganisationName
										: `${c.firstName} ${c.lastName}`) +
									(existingIds.includes(
										c.accountingSystemContactId
									)
										? !alreadyExists(c)
											? ` (modified)`
											: ` (imported)`
										: ``),
								value: c,
								disabled: alreadyExists(c),
							}))}
							selectedOptionValues={Array.from(
								this.props.selectedRetrievedContacts
							)}
							onCheckboxChange={(contact, value) =>
								this.props.actions.selectContactForImport(
									contact,
									value
								)
							}
							innerHeight="18em"
							filterPlaceholder="Find a contact"
						/>
					</div>
				</div>
			</div>
		);
	},
});
