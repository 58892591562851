import { generateUUID, isDecimal, isInteger } from '../utils.js';
import { DataObject } from './dataobject.js';
import { organisationStore } from '../organisation.js';


export const Expense = class extends DataObject {
			constructor(options) {
				super(options);

				if (this.uuid == null) {
					this.uuid = generateUUID();
				}
			}

			static getClassName() {
				return "Expense";
			}

			defaults() {
				return {
					id: null,
					name: "",
					budget: null,
					startDate: null,
					endDate: null,
					hasRepeat: false,
					repeatQuantity: null,
					repeatUnit: null,
					costCentreId: this.getDefaultCostCentre(),
					isDeleted: false
				};
			}

			getDefaultCostCentre() {
				let costCentres = organisationStore.costCentres;
				if (costCentres.length > 0) {
					let projectCostCentres = costCentres.filter(
						cc => cc.name === "Expenses"
					);
					if (projectCostCentres.length > 0) {
						return projectCostCentres[0].id;
					} else {
						return costCentres[0].id;
					}
				} else {
					return null;
				}
			}

			static fieldTypes() {
				return {
					id: "int",
					name: "string",
					budget: "number",
					startDate: "date",
					endDate: "date",
					hasRepeat: "bool",
					repeatQuantity: "int",
					repeatUnit: "string",
					isDeleted: "bool",
					costCentreId: "int"
				};
			}

			static fieldsForSerialize() {
				return [
					"id",
					"uuid",
					"name",
					"budget",
					"startDate",
					"endDate",
					"hasRepeat",
					"repeatQuantity",
					"repeatUnit",
					"isDeleted",
					"costCentreId"
				];
			}

			serialize() {
				return {
					id: this.id,
					uuid: this.uuid,
					name: this.name,
					budget: this.budget,
					startDate:
						this.startDate != null
							? this.startDate.format("YYYY-MM-DD")
							: null,
					endDate:
						this.endDate != null
							? this.endDate.format("YYYY-MM-DD")
							: null,
					hasRepeat: this.hasRepeat,
					repeatQuantity: this.repeatQuantity,
					repeatUnit: this.repeatUnit,
					isDeleted: this.isDeleted,
					costCentreId: this.costCentre ? this.costCentre.id : null
				};
			}

			validate() {
				this.errors = {};
				var isValid = true;
				if (!this.isDeleted) {
					isValid =
						this.setFieldValidity("name", this.name !== "") &&
						isValid;
					isValid =
						this.setFieldValidity(
							"budget",
							this.budget != null && isDecimal(this.budget)
						) && isValid;
					isValid =
						this.setFieldValidity(
							"startDate",
							this.startDate != null
						) && isValid;
					isValid =
						this.setFieldValidity(
							"repeatQuantity",
							!this.hasRepeat || isInteger(this.repeatQuantity)
						) && isValid;
					isValid =
						this.setFieldValidity(
							"repeatUnit",
							!this.hasRepeat || this.repeatUnit != null
						) && isValid;
				}
				return isValid;
			}

			get costCentre() {
				return organisationStore.getCostCentreById(this.costCentreId);
			}

			set costCentre(costCentre) {
				this.costCentreId = costCentre != null ? costCentre.id : null;
			}
		};
