import { StoreBase } from '../coincraftFlux.js';
import { AjaxOperation } from '../AjaxOperation.js';
import { organisationStore } from '../organisation.js';
import { router } from '../router.js';


class CostCentresPageStore extends StoreBase {
  newCostCentre() {
    router.history.push('/dashboard/cost-centres/new');
  }
}


class CostCentrePageStore extends StoreBase {
  constructor() {
    super();
    this.costCentre = null;

    this.saveWithEntriesOperation = new AjaxOperation(this);
    this.saveWithoutEntriesOperation = new AjaxOperation(this);

    this.isConfirmingSave = false;

    this.name = null;
    this.isBillable = true;
  }

  loadPage(costCentre) {
    this.costCentre = costCentre;
    this.name = costCentre.name;
    this.isBillable = costCentre.isBillable;
    this.saveWithEntriesOperation.reset();
    this.saveWithoutEntriesOperation.reset();
    this.isConfirmingSave = false;
    this.emitChanged();
  }

  setName(name) {
    this.name = name;
    this.emitChanged();
  }

  setIsBillable(isBillable) {
    this.isBillable = isBillable;
    this.emitChanged();
  }

  clickSaveButton() {
    if (this.isBillable !== this.costCentre.isBillable) {
      this.isConfirmingSave = true;
      this.emitChanged();
    }
    else {
      this.save({withEntries: false});
    }
  }

  saveWithEntries() {
    this.save({withEntries: true});
  }

  saveWithoutEntries() {
    this.save({withEntries: false});
  }

  save({withEntries}) {
    let request = organisationStore.saveCostCentre(
      {
        id: this.costCentre.id,
        uuid: this.costCentre.uuid,
        name: this.name,
        isBillable: this.isBillable
      },
      {
        withEntries: withEntries
      }
    );

    this.saveWithEntriesOperation.reset();
    this.saveWithoutEntriesOperation.reset();

    let operation = (withEntries) ? this.saveWithEntriesOperation : this.saveWithoutEntriesOperation;
    operation.execute(request);
  }

  saveCostCentreSuccess({costCentre}) {
    router.history.replace('/dashboard/cost-centres');
  }

  cancelSave() {
    this.isConfirmingSave = false;
    this.saveWithEntriesOperation.reset();
    this.saveWithoutEntriesOperation.reset();
    this.emitChanged();
  }

  toCostCentresPage() {
    router.history.push('/dashboard/cost-centres');
  }
}

export let costCentresPageStore = new CostCentresPageStore();
export let costCentrePageStore = new CostCentrePageStore();


