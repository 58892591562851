import React from "react";
import CreateReactClass from "create-react-class";
import _ from "underscore";
import classNames from "classnames";
import {
	MyTable,
	makeStaticTextCell,
	makeStaticTextCellWithFormatter,
} from "../myTable.js";
import { pluralise } from "../utils.js";
import { Checkbox, RadioButton } from "../widgets.js";
import { invoicePageStore } from "./InvoicePageStore.js";
import { organisationStore } from "../organisation.js";

export var ExpensesPopup = CreateReactClass({
	getInitialState: function () {
		return {
			invoice: invoicePageStore.invoice,
			initialExpenses: invoicePageStore.invoice.getSelectedExpenses(),
			expensesToCopy: invoicePageStore.invoice.getSelectedExpenses(),
			showAll: false,
		};
	},

	render: function () {
		let self = this;
		const expenses = this.getVisibleExpenses();

		function toggleSelected(ob) {
			var isSelected = self.state.expensesToCopy.indexOf(ob) !== -1;
			if (!isSelected) {
				self.setState({
					expensesToCopy: [...self.state.expensesToCopy, ob],
				});
			} else {
				self.setState({
					expensesToCopy: _.without(self.state.expensesToCopy, ob),
				});
			}
		}

		return (
			<div style={{ padding: "2em" }}>
				{expenses ? (
					<div>
						<p style={{ maxWidth: 650 }}>
							You can use this form to copy expenses you have
							defined for this project to this invoice. Expenses
							you select in the list below will be added to the
							invoice; expenses you deselect will be removed.
						</p>

						<div
							style={{ padding: 12, backgroundColor: "#f2f2f2" }}
						>
							<RadioButton
								label="Show expenses in invoice date range"
								name="showAll"
								value={self.state.showAll === false}
								onChange={function (event) {
									self.setState({ showAll: false });
								}}
							/>
							<RadioButton
								label="Show all project expenses"
								name="showAll"
								value={self.state.showAll === true}
								onChange={function (event) {
									self.setState({ showAll: true });
								}}
							/>

							<MyTable
								items={expenses}
								addButtonText="Add expense"
								hasSaveButton={false}
								hasAddEntryButton={false}
								hasHeader={true}
								tableProps={{ className: "data-table" }}
								trProps={function (ob) {
									var isSelected =
										self.state.expensesToCopy.indexOf(
											ob
										) !== -1;
									return {
										className: classNames(
											isSelected ? "selected" : null
										),
										onClick: function (event) {
											toggleSelected(ob);
										},
									};
								}}
								columns={[
									{
										prop: null,
										heading: "",
										tdProps: {
											style: { width: "5%" },
										},
										makeCustomComponent: function (
											ob,
											column,
											myTable,
											isLastFirst
										) {
											return (
												<div>
													<Checkbox
														value={
															self.state.expensesToCopy.indexOf(
																ob
															) !== -1
														}
														onChange={function () {
															// No need to do anything here since the click is
															// handled by the <tr>'s `onClick` handler.
														}}
													/>
												</div>
											);
										},
									},
									{
										prop: "name",
										heading: "Expense",
										tdProps: {
											style: { width: "17%" },
										},
										makeCustomComponent: makeStaticTextCell,
									},
									{
										prop: "startDate",
										heading: "Start Date",
										tdProps: {
											style: { width: "17%" },
										},
										makeCustomComponent:
											makeStaticTextCellWithFormatter(
												(d) => d.format("DD/MM/YYYY")
											),
									},
									{
										prop: "endDate",
										heading: "End Date",
										tdProps: {
											style: { width: "17%" },
										},
										makeCustomComponent:
											makeStaticTextCellWithFormatter(
												(d) => d.format("DD/MM/YYYY")
											),
									},
									{
										prop: "remainingCost",
										heading: "Remaining Cost",
										tdProps: {
											style: { width: "17%" },
										},
										makeCustomComponent:
											makeStaticTextCellWithFormatter(
												(d) =>
													organisationStore.organisation.currencyFormatter.format(
														d
													)
											),
									},
									{
										prop: "cost",
										heading: "Total Cost",
										tdProps: {
											style: { width: "17%" },
										},
										makeCustomComponent:
											makeStaticTextCellWithFormatter(
												(d) =>
													organisationStore.organisation.currencyFormatter.format(
														d
													)
											),
									},
									{
										prop: "isInvoiceable",
										heading: "Invoiceable",
										tdProps: {
											style: { width: "10%" },
										},
										makeCustomComponent:
											makeStaticTextCellWithFormatter(
												(d) => (d ? "Yes" : "No")
											),
									},
								]}
							/>
						</div>

						<div>
							<p>
								Syncing will {self.getExpenseSyncSummaryText()}.
							</p>
							<div>
								<button
									onClick={self.handleSubmit}
									className="btn btn-lg btn-success"
								>
									Sync expenses to invoice
								</button>
								<span style={{ marginLeft: 20 }}>
									<a
										href="javascript:void(0)"
										onClick={() => this.props.onCancel()}
									>
										Close
									</a>
								</span>
							</div>
						</div>
					</div>
				) : (
					<div>
						<p style={{ maxWidth: 650 }}>
							You don't currently have any expenses defined for
							this project.
						</p>
						<p style={{ maxWidth: 650 }}>
							If you like, you can add some on the project page,
							accessible by selecting this project from the
							sidebar. Then you will be able to to add them to
							this invoice.
						</p>
						<div>
							<a
								href="javascript:void(0)"
								onClick={() => this.props.onCancel()}
							>
								Close
							</a>
						</div>
					</div>
				)}
			</div>
		);
	},

	expensesToAdd: function () {
		return this.state.expensesToCopy.filter(
			(e) =>
				_.find(
					this.state.initialExpenses,
					(e2) => e2.uuid === e.uuid
				) == null
		);
	},

	expensesToRemove: function () {
		let self = this;
		const visibleExpenses = this.getVisibleExpenses();
		return this.state.invoice.project.expenses.filter(function (expense) {
			return (
				_.find(
					self.state.initialExpenses,
					(e) => e.uuid === expense.uuid
				) != null &&
				_.find(visibleExpenses, (e) => e.uuid === expense.uuid) !=
					null &&
				_.find(
					self.state.expensesToCopy,
					(e) => e.uuid === expense.uuid
				) == null
			);
		});
	},

	handleSubmit: function () {
		this.props.onSubmit(this.expensesToAdd(), this.expensesToRemove());
	},

	getExpenseSyncSummaryText: function () {
		const l1 = this.expensesToAdd().length;
		const l2 = this.expensesToRemove().length;
		let parts = [];

		if (l1 === 0 && l2 === 0) {
			return "leave the invoice expenses unchanged";
		} else {
			if (l1 > 0) {
				parts.push("add " + pluralise(l1, "expense"));
			}
			if (l2 > 0) {
				parts.push("remove " + pluralise(l2, "expense"));
			}
			return parts.join(" and ");
		}
	},

	getVisibleExpenses: function () {
		if (this.state.invoice.project == null) {
			return [];
		}
		if (this.state.showAll) {
			return this.state.invoice.project.expenses;
		} else {
			return this.state.invoice.project
				.getExpensesInRange(
					this.state.invoice.startDate,
					this.state.invoice.endDate
				)
				.filter(
					(exp) =>
						exp.isInvoiceable &&
						(!exp.beenInvoiced || exp.remainingCost <= 0)
				);
		}
	},
});
