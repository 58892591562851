/**
  * Generated by going here: https://countrycode.org/
  * - Making the window size so that the visible table has three columns
  * - Selecting the visible table in dev tools
  * - Running the following Javascript:

    console.log(JSON.stringify(
      [].slice.call($0.querySelectorAll("tr")).map(function(e, i) {
        if (i > 0) {
          var tds = e.querySelectorAll("td");
          return [
            tds[0].querySelector("a").innerHTML,
            tds[2].innerHTML.split("/")[0].trim().toLowerCase()
          ];
        }
        else {
          return null;
        }
      }).filter(r => r != null),
      null,
      4
    ))

  */
var RSVP = require("rsvp");



export function getCountryCodeViaGeolocation() {
    return new RSVP.Promise(function(resolve, reject) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            window.$.ajax({
              url: 'http://ws.geonames.org/countryCode',
              dataType: 'json',
              data: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                username: 'amagee99',
                type: 'JSON'
              },
              timeout: 5000,
              success: function(result) {
                if (result.countryCode != null) {
                  resolve(result.countryCode.toLowerCase());
                }
                else {
                  reject(result);
                }
              }
            }).fail(function() {
              reject('request_failed');
            });
          },
          function() {
            reject('geolocation_failed');
          }
        );
      }
      else {
        reject("no_geolocation");
      }
    });
}

window.getCountryCodeViaGeolocation = getCountryCodeViaGeolocation;


let cs = [
    [
        "Afghanistan",
        "af"
    ],
    [
        "Albania",
        "al"
    ],
    [
        "Algeria",
        "dz"
    ],
    [
        "American Samoa",
        "as"
    ],
    [
        "Andorra",
        "ad"
    ],
    [
        "Angola",
        "ao"
    ],
    [
        "Anguilla",
        "ai"
    ],
    [
        "Antarctica",
        "aq"
    ],
    [
        "Antigua and Barbuda",
        "ag"
    ],
    [
        "Argentina",
        "ar"
    ],
    [
        "Armenia",
        "am"
    ],
    [
        "Aruba",
        "aw"
    ],
    [
        "Australia",
        "au"
    ],
    [
        "Austria",
        "at"
    ],
    [
        "Azerbaijan",
        "az"
    ],
    [
        "Bahamas",
        "bs"
    ],
    [
        "Bahrain",
        "bh"
    ],
    [
        "Bangladesh",
        "bd"
    ],
    [
        "Barbados",
        "bb"
    ],
    [
        "Belarus",
        "by"
    ],
    [
        "Belgium",
        "be"
    ],
    [
        "Belize",
        "bz"
    ],
    [
        "Benin",
        "bj"
    ],
    [
        "Bermuda",
        "bm"
    ],
    [
        "Bhutan",
        "bt"
    ],
    [
        "Bolivia",
        "bo"
    ],
    [
        "Bosnia and Herzegovina",
        "ba"
    ],
    [
        "Botswana",
        "bw"
    ],
    [
        "Brazil",
        "br"
    ],
    [
        "British Indian Ocean Territory",
        "io"
    ],
    [
        "British Virgin Islands",
        "vg"
    ],
    [
        "Brunei",
        "bn"
    ],
    [
        "Bulgaria",
        "bg"
    ],
    [
        "Burkina Faso",
        "bf"
    ],
    [
        "Burundi",
        "bi"
    ],
    [
        "Cambodia",
        "kh"
    ],
    [
        "Cameroon",
        "cm"
    ],
    [
        "Canada",
        "ca"
    ],
    [
        "Cape Verde",
        "cv"
    ],
    [
        "Cayman Islands",
        "ky"
    ],
    [
        "Central African Republic",
        "cf"
    ],
    [
        "Chad",
        "td"
    ],
    [
        "Chile",
        "cl"
    ],
    [
        "China",
        "cn"
    ],
    [
        "Christmas Island",
        "cx"
    ],
    [
        "Cocos Islands",
        "cc"
    ],
    [
        "Colombia",
        "co"
    ],
    [
        "Comoros",
        "km"
    ],
    [
        "Cook Islands",
        "ck"
    ],
    [
        "Costa Rica",
        "cr"
    ],
    [
        "Croatia",
        "hr"
    ],
    [
        "Cuba",
        "cu"
    ],
    [
        "Curacao",
        "cw"
    ],
    [
        "Cyprus",
        "cy"
    ],
    [
        "Czech Republic",
        "cz"
    ],
    [
        "Democratic Republic of the Congo",
        "cd"
    ],
    [
        "Denmark",
        "dk"
    ],
    [
        "Djibouti",
        "dj"
    ],
    [
        "Dominica",
        "dm"
    ],
    [
        "Dominican Republic",
        "do"
    ],
    [
        "East Timor",
        "tl"
    ],
    [
        "Ecuador",
        "ec"
    ],
    [
        "Egypt",
        "eg"
    ],
    [
        "El Salvador",
        "sv"
    ],
    [
        "Equatorial Guinea",
        "gq"
    ],
    [
        "Eritrea",
        "er"
    ],
    [
        "Estonia",
        "ee"
    ],
    [
        "Ethiopia",
        "et"
    ],
    [
        "Falkland Islands",
        "fk"
    ],
    [
        "Faroe Islands",
        "fo"
    ],
    [
        "Fiji",
        "fj"
    ],
    [
        "Finland",
        "fi"
    ],
    [
        "France",
        "fr"
    ],
    [
        "French Polynesia",
        "pf"
    ],
    [
        "Gabon",
        "ga"
    ],
    [
        "Gambia",
        "gm"
    ],
    [
        "Georgia",
        "ge"
    ],
    [
        "Germany",
        "de"
    ],
    [
        "Ghana",
        "gh"
    ],
    [
        "Gibraltar",
        "gi"
    ],
    [
        "Greece",
        "gr"
    ],
    [
        "Greenland",
        "gl"
    ],
    [
        "Grenada",
        "gd"
    ],
    [
        "Guam",
        "gu"
    ],
    [
        "Guatemala",
        "gt"
    ],
    [
        "Guernsey",
        "gg"
    ],
    [
        "Guinea",
        "gn"
    ],
    [
        "Guinea-Bissau",
        "gw"
    ],
    [
        "Guyana",
        "gy"
    ],
    [
        "Haiti",
        "ht"
    ],
    [
        "Honduras",
        "hn"
    ],
    [
        "Hong Kong",
        "hk"
    ],
    [
        "Hungary",
        "hu"
    ],
    [
        "Iceland",
        "is"
    ],
    [
        "India",
        "in"
    ],
    [
        "Indonesia",
        "id"
    ],
    [
        "Iran",
        "ir"
    ],
    [
        "Iraq",
        "iq"
    ],
    [
        "Ireland",
        "ie"
    ],
    [
        "Isle of Man",
        "im"
    ],
    [
        "Israel",
        "il"
    ],
    [
        "Italy",
        "it"
    ],
    [
        "Ivory Coast",
        "ci"
    ],
    [
        "Jamaica",
        "jm"
    ],
    [
        "Japan",
        "jp"
    ],
    [
        "Jersey",
        "je"
    ],
    [
        "Jordan",
        "jo"
    ],
    [
        "Kazakhstan",
        "kz"
    ],
    [
        "Kenya",
        "ke"
    ],
    [
        "Kiribati",
        "ki"
    ],
    [
        "Kosovo",
        "xk"
    ],
    [
        "Kuwait",
        "kw"
    ],
    [
        "Kyrgyzstan",
        "kg"
    ],
    [
        "Laos",
        "la"
    ],
    [
        "Latvia",
        "lv"
    ],
    [
        "Lebanon",
        "lb"
    ],
    [
        "Lesotho",
        "ls"
    ],
    [
        "Liberia",
        "lr"
    ],
    [
        "Libya",
        "ly"
    ],
    [
        "Liechtenstein",
        "li"
    ],
    [
        "Lithuania",
        "lt"
    ],
    [
        "Luxembourg",
        "lu"
    ],
    [
        "Macao",
        "mo"
    ],
    [
        "Macedonia",
        "mk"
    ],
    [
        "Madagascar",
        "mg"
    ],
    [
        "Malawi",
        "mw"
    ],
    [
        "Malaysia",
        "my"
    ],
    [
        "Maldives",
        "mv"
    ],
    [
        "Mali",
        "ml"
    ],
    [
        "Malta",
        "mt"
    ],
    [
        "Marshall Islands",
        "mh"
    ],
    [
        "Mauritania",
        "mr"
    ],
    [
        "Mauritius",
        "mu"
    ],
    [
        "Mayotte",
        "yt"
    ],
    [
        "Mexico",
        "mx"
    ],
    [
        "Micronesia",
        "fm"
    ],
    [
        "Moldova",
        "md"
    ],
    [
        "Monaco",
        "mc"
    ],
    [
        "Mongolia",
        "mn"
    ],
    [
        "Montenegro",
        "me"
    ],
    [
        "Montserrat",
        "ms"
    ],
    [
        "Morocco",
        "ma"
    ],
    [
        "Mozambique",
        "mz"
    ],
    [
        "Myanmar",
        "mm"
    ],
    [
        "Namibia",
        "na"
    ],
    [
        "Nauru",
        "nr"
    ],
    [
        "Nepal",
        "np"
    ],
    [
        "Netherlands",
        "nl"
    ],
    [
        "Netherlands Antilles",
        "an"
    ],
    [
        "New Caledonia",
        "nc"
    ],
    [
        "New Zealand",
        "nz"
    ],
    [
        "Nicaragua",
        "ni"
    ],
    [
        "Niger",
        "ne"
    ],
    [
        "Nigeria",
        "ng"
    ],
    [
        "Niue",
        "nu"
    ],
    [
        "North Korea",
        "kp"
    ],
    [
        "Northern Mariana Islands",
        "mp"
    ],
    [
        "Norway",
        "no"
    ],
    [
        "Oman",
        "om"
    ],
    [
        "Pakistan",
        "pk"
    ],
    [
        "Palau",
        "pw"
    ],
    [
        "Palestine",
        "ps"
    ],
    [
        "Panama",
        "pa"
    ],
    [
        "Papua New Guinea",
        "pg"
    ],
    [
        "Paraguay",
        "py"
    ],
    [
        "Peru",
        "pe"
    ],
    [
        "Philippines",
        "ph"
    ],
    [
        "Pitcairn",
        "pn"
    ],
    [
        "Poland",
        "pl"
    ],
    [
        "Portugal",
        "pt"
    ],
    [
        "Puerto Rico",
        "pr"
    ],
    [
        "Qatar",
        "qa"
    ],
    [
        "Republic of the Congo",
        "cg"
    ],
    [
        "Reunion",
        "re"
    ],
    [
        "Romania",
        "ro"
    ],
    [
        "Russia",
        "ru"
    ],
    [
        "Rwanda",
        "rw"
    ],
    [
        "Saint Barthelemy",
        "bl"
    ],
    [
        "Saint Helena",
        "sh"
    ],
    [
        "Saint Kitts and Nevis",
        "kn"
    ],
    [
        "Saint Lucia",
        "lc"
    ],
    [
        "Saint Martin",
        "mf"
    ],
    [
        "Saint Pierre and Miquelon",
        "pm"
    ],
    [
        "Saint Vincent and the Grenadines",
        "vc"
    ],
    [
        "Samoa",
        "ws"
    ],
    [
        "San Marino",
        "sm"
    ],
    [
        "Sao Tome and Principe",
        "st"
    ],
    [
        "Saudi Arabia",
        "sa"
    ],
    [
        "Senegal",
        "sn"
    ],
    [
        "Serbia",
        "rs"
    ],
    [
        "Seychelles",
        "sc"
    ],
    [
        "Sierra Leone",
        "sl"
    ],
    [
        "Singapore",
        "sg"
    ],
    [
        "Sint Maarten",
        "sx"
    ],
    [
        "Slovakia",
        "sk"
    ],
    [
        "Slovenia",
        "si"
    ],
    [
        "Solomon Islands",
        "sb"
    ],
    [
        "Somalia",
        "so"
    ],
    [
        "South Africa",
        "za"
    ],
    [
        "South Korea",
        "kr"
    ],
    [
        "South Sudan",
        "ss"
    ],
    [
        "Spain",
        "es"
    ],
    [
        "Sri Lanka",
        "lk"
    ],
    [
        "Sudan",
        "sd"
    ],
    [
        "Suriname",
        "sr"
    ],
    [
        "Svalbard and Jan Mayen",
        "sj"
    ],
    [
        "Swaziland",
        "sz"
    ],
    [
        "Sweden",
        "se"
    ],
    [
        "Switzerland",
        "ch"
    ],
    [
        "Syria",
        "sy"
    ],
    [
        "Taiwan",
        "tw"
    ],
    [
        "Tajikistan",
        "tj"
    ],
    [
        "Tanzania",
        "tz"
    ],
    [
        "Thailand",
        "th"
    ],
    [
        "Togo",
        "tg"
    ],
    [
        "Tokelau",
        "tk"
    ],
    [
        "Tonga",
        "to"
    ],
    [
        "Trinidad and Tobago",
        "tt"
    ],
    [
        "Tunisia",
        "tn"
    ],
    [
        "Turkey",
        "tr"
    ],
    [
        "Turkmenistan",
        "tm"
    ],
    [
        "Turks and Caicos Islands",
        "tc"
    ],
    [
        "Tuvalu",
        "tv"
    ],
    [
        "U.S. Virgin Islands",
        "vi"
    ],
    [
        "Uganda",
        "ug"
    ],
    [
        "Ukraine",
        "ua"
    ],
    [
        "United Arab Emirates",
        "ae"
    ],
    [
        "United Kingdom",
        "gb"
    ],
    [
        "United States",
        "us"
    ],
    [
        "Uruguay",
        "uy"
    ],
    [
        "Uzbekistan",
        "uz"
    ],
    [
        "Vanuatu",
        "vu"
    ],
    [
        "Vatican",
        "va"
    ],
    [
        "Venezuela",
        "ve"
    ],
    [
        "Vietnam",
        "vn"
    ],
    [
        "Wallis and Futuna",
        "wf"
    ],
    [
        "Western Sahara",
        "eh"
    ],
    [
        "Yemen",
        "ye"
    ],
    [
        "Zambia",
        "zm"
    ],
    [
        "Zimbabwe",
        "zw"
    ]
];

export const countries = cs.map(c => ({name: c[0], code: c[1]}));


