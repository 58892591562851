import React from 'react';
import CreateReactClass from 'create-react-class';
import { userStore } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';


export function wrapUserStore(component) {
  return CreateReactClass({
    mixins: [
      makeMultipleStoreMixin([userStore], function() {
        return {
          user: userStore.user
        };
      })
    ],

    render: function() {
      if (this.state.user == null) {
        return null;
      }
      return React.createElement(component, {...this.props, user: this.state.user});
    }
  });
};

