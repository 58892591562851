import { dateConverter } from './dateconverter.js';

export const CashFlowItem = class {
  constructor({
      endDate, title, milestone, project, phase, percent, invoice, expense,
      fee, spend, isEditable, isChangeLogItem = false, changeLogItem, billingType = 'agreedFee' }) {
    this.endDate = endDate;
    this.title = title;
    this.milestone = milestone;
    this.percent = percent;
    this.project = project;
    this.phase = phase;
    this.fee = fee || 0;
    this.spend = spend || 0;
    this.isEditable = isEditable;
    this.invoice = invoice;
    this.expense = expense;
    this.isChangeLogItem = isChangeLogItem;
    this.changeLogItem = changeLogItem;
    this.billingType = billingType || "agreedFee";
    this.monthIndex = dateConverter.getMonthIndex(endDate);
  }

  get likelihood() {
    if (this.monthIndex < dateConverter.currentMonthIndex) return 100
    if (this.phase) return this.phase.likelihood
    if (this.project) return this.project.likelihood
    return 100
  }

  get net() {
    return this.fee - this.spend;
  }
}


export function makeCashFlowItemsFromMilestones(milestones) {
  return milestones.map(function(milestone) {
    return new CashFlowItem({
      invoice: null,
      endDate: milestone.endDate,
      title: milestone.phase.getTitle(),
      project: milestone.phase.project,
      phase: milestone.phase,
      fee: milestone.revenue,
      isEditable: true,
      percent: milestone.percent,
      milestone: milestone
    });
  });
}
