import { Select, Switch, Input, Progress, Button, Alert } from "antd";
import { observer } from "mobx-react";
import React, { useRef, useEffect, useCallback, useState } from "react";
import {
	budgetNames,
	budgetOptions,
	ProjectPhaseSelector,
	ProjectSelector,
	TaskSelector,
	TimeInput,
} from "../calendar/calendarSidebarComponent";
import { PermissionLevel } from "../models/permissions";
import { organisationStore } from "../organisation";
import { HoursInput } from "../widgets/HoursInput";
import timerStore from "./timerStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPauseCircle,
	faPlayCircle,
} from "@fortawesome/free-regular-svg-icons";
import useOutsideClick from "@rooks/use-outside-click";
import { formatNumber2 } from "../utils";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

export default observer(() => {
	const renderTime = new Date();
	const settings = organisationStore.organisation.settings;
	const disabled = timerStore.saveState === "pending";
	let progressGradient = { "0%": "#108ee9" };
	progressGradient[
		`${Math.round((0.5 / timerStore.budget.percent) * 100)}%`
	] = "#87d068";
	if (timerStore.budget.percent > 0.8) {
		progressGradient[
			`${Math.round((0.8 / timerStore.budget.percent) * 100)}%`
		] = "#ffc200";
	}
	if (timerStore.budget.percent > 1) {
		progressGradient[
			`${Math.round((1 / timerStore.budget.percent) * 100)}%`
		] = "#ff5800";
	}

	const panelRef = useRef();

	function outsideClick(e) {
		const panel = panelRef.current.getBoundingClientRect();
		const x = e.clientX;
		const y = e.clientY;
		if (
			(new Date() - renderTime > 100 && // hack for early click
				new Date() - renderTime < 100) || // registers the open event as a click outside
			(x >= panel.left &&
				x <= panel.right &&
				y >= panel.top &&
				y <= panel.bottom)
		) {
			// console.log('Clicked inside panel')
		} else {
			// console.log('Clicked outside panel', new Date() - renderTime)
			timerStore.toggleOpen(false);
		}
	}
	useOutsideClick(panelRef, outsideClick);
	return (
		<div
			style={{
				// height: "20em",
				width: "20em",
				position: "absolute",
				top: "2.5em",
				right: 0,
				zIndex: 10,
				boxShadow: "6px 6px 16px -1px #00000033",
			}}
			ref={panelRef}
		>
			<div
				style={{
					background: "white",
					padding: "1em",
					zIndex: 10,
				}}
			>
				<div
					style={{
						textAlign: "center",
						fontSize: "3.5em",
						padding: "2rem 0rem 1rem 0rem",
					}}
				>
					{timerStore.timeDisplay}
					{timerStore.timerState === "idle" ? (
						<span
							style={{
								marginLeft: "1.5rem",
								cursor: "pointer",
								fontSize: "4rem",
							}}
							onClick={
								timerStore.interfaceDisabled
									? () => null
									: timerStore.startTimer
							}
						>
							<FontAwesomeIcon icon={faPlayCircle} />
						</span>
					) : (
						<span
							style={{
								marginLeft: "1.5rem",
								cursor: "pointer",
								fontSize: "4rem",
							}}
							onClick={
								timerStore.interfaceDisabled
									? () => null
									: timerStore.pauseTimer
							}
						>
							<FontAwesomeIcon icon={faPauseCircle} />
						</span>
					)}
				</div>
				<div
					style={{
						padding: "1.5rem 0",
					}}
				>
					<div
						style={{
							margin: "0.5rem 0 -0.75rem 0",
							padding: "0 0.75rem",
						}}
					>
						{`${Math.round(timerStore.budget.use * 4) / 4} / ${
							Math.round(timerStore.budget.budget * 4) / 4
						} (${Math.round(timerStore.budget.percent * 100)}%)`}
					</div>
					<Progress
						percent={timerStore.budget.percent * 100}
						showInfo={false}
						strokeColor={progressGradient}
						trailColor="#dddddd"
						style={{ padding: "0 0.75rem" }}
					/>
					<Select
						optionFilterProp="children"
						style={{
							zIndex: 100,
							width: "100%",
							fontSize: "1.2rem",
						}}
						value={timerStore.budgetType}
						status="active"
						onChange={(newType) =>
							timerStore.changeBudgetType(newType)
						}
						className={"budget-selector"}
						disabled={timerStore.interfaceDisabled}
					>
						{budgetOptions.map((bOpt) => {
							return (
								<Option key={bOpt} value={bOpt}>
									{budgetNames[bOpt]}
								</Option>
							);
						})}
					</Select>
				</div>
				<ProjectSelector
					value={timerStore.selectedValues.projectId}
					onChange={(projectId) => {
						timerStore.updateSelectedValues({ projectId });
					}}
					style={{ zIndex: 100, width: "100%", fontWeight: "bold" }}
					permissions={
						timerStore.mode === "allocation"
							? PermissionLevel.projectManager
							: PermissionLevel.timesheet
					}
					disabled={timerStore.interfaceDisabled}
				/>
				<ProjectPhaseSelector
					value={timerStore.selectedValues.projectPhaseId}
					onChange={(projectPhaseId) => {
						timerStore.updateSelectedValues({ projectPhaseId });
					}}
					style={{
						zIndex: 100,
						width: "100%",
						fontWeight: "bold",
						fontSize: "1.2rem",
					}}
					projectId={timerStore.selectedValues.projectId}
					disabled={timerStore.interfaceDisabled}
				/>
				{settings.useTasks && (
					<TaskSelector
						value={timerStore.selectedValues.taskUuid}
						onChange={(taskUuid) => {
							timerStore.updateSelectedValues({ taskUuid });
						}}
						style={{
							zIndex: 100,
							width: "100%",
							fontSize: "1.2rem",
						}}
						projectPhaseId={
							timerStore.selectedValues.projectPhaseId
						}
						disabled={timerStore.interfaceDisabled}
					/>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						textAlign: "center",
						margin: "2rem 0",
						fontSize: "1.2rem",
					}}
				>
					{settings.timeEntryFlags.includes("billable") && (
						<div>
							<Switch
								checked={timerStore.selectedValues.isBillable}
								onChange={(isBillable) => {
									timerStore.updateSelectedValues({
										isBillable,
									});
								}}
								disabled={timerStore.interfaceDisabled}
							/>
							<div>Billable</div>
						</div>
					)}
					{settings.timeEntryFlags.includes("variation") && (
						<div>
							<Switch
								checked={timerStore.selectedValues.isVariation}
								onChange={(isVariation) => {
									timerStore.updateSelectedValues({
										isVariation,
									});
								}}
								disabled={timerStore.interfaceDisabled}
							/>
							<div>Variation</div>
						</div>
					)}
					{settings.timeEntryFlags.includes("overtime") && (
						<div>
							<Switch
								checked={timerStore.selectedValues.isOvertime}
								onChange={(isOvertime) => {
									timerStore.updateSelectedValues({
										isOvertime,
									});
								}}
								disabled={timerStore.interfaceDisabled}
							/>
							<div>Overtime</div>
						</div>
					)}
					{settings.timeEntryFlags.includes("remote") && (
						<div>
							<Switch
								checked={timerStore.selectedValues.remote}
								onChange={(remote) => {
									timerStore.updateSelectedValues({
										remote,
									});
								}}
								disabled={timerStore.interfaceDisabled}
							/>
							<div>Remote</div>
						</div>
					)}
					{settings.timeEntryFlags.includes("flexi") && (
						<div>
							<Switch
								checked={timerStore.selectedValues.flexi}
								onChange={(flexi) => {
									timerStore.updateSelectedValues({
										flexi,
									});
								}}
								disabled={timerStore.interfaceDisabled}
							/>
							<div>Flexi</div>
						</div>
					)}
				</div>
				<TextArea
					value={timerStore.selectedValues.notes}
					onChange={(e) => {
						timerStore.updateSelectedValues({
							notes: e.target.value,
						});
					}}
					style={{ fontSize: "1.2rem" }}
					placeholder="Enter some notes..."
					autoSize={{ minRows: 5 }}
					allowClear
					disabled={timerStore.interfaceDisabled}
				/>
				<Button
					danger
					style={{ marginTop: "1rem", width: "100%" }}
					onClick={timerStore.resetTimer}
					disabled={timerStore.interfaceDisabled}
				>
					Reset Timer & Notes
				</Button>
				<Button
					style={{ marginTop: "1rem", width: "100%" }}
					onClick={timerStore.saveTimeEntry}
					disabled={timerStore.interfaceDisabled}
				>
					{timerStore.saveState === "saved"
						? "Saved!"
						: timerStore.saveState === "saving"
						? "Saving..."
						: "Save Time Entry"}
				</Button>
			</div>
			{timerStore.timeEntries.length ? (
				<div
					style={{
						background: "#f5f5f5",
						padding: "1em",
						boxShadow: "3px 3px 12px -1px inset #00000011",
						position: "relative",
						borderTop: "solid 1px #ddd",
						maxHeight: "7em",
						overflow: "auto",
					}}
				>
					{[...timerStore.timeEntries]
						.sort((a, b) => b.startMinutes - a.startMinutes)
						.map((te) => (
							<div
								style={{
									padding: "1em",
									fontSize: "0.8em",
									lineHeight: "1.2em",
									borderBottom: "solid 1px #ddd",
								}}
							>
								<div style={{ display: "flex" }}>
									<div
										style={{
											flex: "1 1 auto",
											textAlign: "left",
										}}
									>{`${formatNumber2(
										te.numMinutes / 60
									)} hours`}</div>
									<div
										style={{ flex: "0 0 auto" }}
									>{`${te.project.getTitle()}`}</div>
								</div>
								<div style={{ display: "flex" }}>
									<div
										style={{
											flex: "1 1 auto",
											textAlign: "left",
										}}
									>{`${formatTime(
										te.startMinutes / 60
									)}-${formatTime(te.endMinutes / 60)}`}</div>
									<div
										style={{ flex: "0 0 auto" }}
									>{`${te.projectPhase.getTitle()}`}</div>
								</div>
							</div>
						))}
				</div>
			) : null}
		</div>
	);
});

const formatTime = (hours) => {
	let wholeHours = Math.floor(hours);
	let mins = Math.round((hours - wholeHours) * 60);
	let ampm = "am";
	if (wholeHours > 12) {
		wholeHours -= 12;
		ampm = "pm";
	}
	return `${wholeHours}:${mins < 10 ? "0" : ""}${mins}${ampm}`;
};
