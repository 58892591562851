import _ from 'underscore';
import moment from 'moment';
import { parseDateTime } from '../../utils.js';
import { ConnectionState } from '../ConnectionState.js';


export const AccountingSystem = class {
  constructor({settings, data, connection} = {}) {
    this.settings = settings || this.getDefaultSettings();
    this.data = data;
    this.connection = new AccountingSystemConnection(connection);

    // If a subclass sets this, we won't be able to save an invoice with an
    // invoice number with more characters than this.
    this.invoiceNumberMaxLength = null;
  }

  getDefaultTaxRate() {
    if (this.settings != null && this.settings.taxOnTaxType != null) {
      return this.settings.taxOnTaxType.rate;
    }
    else {
      return 10;
    }
  }

  needsData() {
    return true;
  }

  getDefaultSettings() {
    return null;
  }

  requiresContact() {
    return false;
  }

  get requiresLinkedContact() {
    return true;
  }

  isInitialised() {
    return this.data != null && this.data !== 'loading';
  }

  get isAuthenticated() {
    return this.connection.state === ConnectionState.connected;
  }

  getInvoiceUrl() {
    return null;
  }
}


class AccountingSystemConnection {
  constructor(credentials) {
    this.setCredentials(credentials);
    this.url = null;
  }

  setCredentials(credentials) {
    if (credentials != null) {
      const {expiresAt, verified} = credentials;
      this.expiresAt = _.isString(expiresAt) ? parseDateTime(expiresAt) : expiresAt;
      if (verified && this.expiresAt.isAfter(moment())) {
        this.verified = true;
        this.state = ConnectionState.connected;
      }
      else {
        this.verified = false;
        this.state = ConnectionState.notConnected;
      }
    }
    else {
      this.expiresAt = null;
      this.verified = false;
      this.state = ConnectionState.notConncted;
    }
  }
}



