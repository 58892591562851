import moment from 'moment';
import _ from 'underscore';
import Immutable from 'immutable';
import { generateUUID } from '../utils.js';


export function makeRecordClass(fields) {
  /**
   * Fields is a dict mapping field names to "fields" where a field should have
   * a `fromJson` function, a `serialize` function, and optionally a `default` function.
   */
  return class extends Immutable.Record(_.mapObject(fields, () => null)) {
    constructor(options = {}) {
      // `options` can be either a dict or an `Immutable.Map`.
      options = Immutable.Map(options);

      _.each(fields, function(v, k) {
        if (v != null && v.default != null && !options.has(k)) {
          options = options.set(k, v.default());
        }

        /**
        // Sketch of how to make it fail with missing args.
        if ((v == null || v.default == null) && !options.has(k)) {
          throw new Error(`Missing argument ${k}`);
        }
        */
      });
      super(options);
    }

    static fromJson(ob) {
      let args = {};
      _.each(ob, function(v, k) {
        if (fields[k] != null && fields[k].fromJson != null) {
          args[k] = fields[k].fromJson(v);
        }
        else {
          args[k] = v;
        }
      });
      return new this(Immutable.fromJS(args));
    }

    serialize() {
      let j = {};
      for (let [k, v] of this) {
        j[k] = (fields[k] != null && fields[k].serialize != null) ?
          fields[k].serialize(v)
        : v;
      }
      return j;
    }

    refersToSameObject(other) {
      return (this.id != null
        && other != null
        && other.constructor === other.constructor
        && other.id === this.id
      );
    }
  };
}


export const UUID = {
  default: generateUUID
};

export const DateType = {
  fromJson: (s) => s != null ? moment(s, "YYYY-MM-DD") : null,
  serialize: (m) => (m != null) ? m.format("YYYY-MM-DD") : null
};

export const DateTimeType = {
  fromJson: (s) => s != null ? moment(s, "YYYY-MM-DDTHH:mm:ss") : null,
  serialize: (m) => (m != null) ? m.format("YYYY-MM-DDTHH:mm:ss") : null
};

export function MapType({ defaultValue = {} } = {}) {
  return {
    default: () => Immutable.Map(defaultValue),
    fromJson: (ob) => Immutable.Map(ob),
    serialize: (ob) => ob.toJS()
  };
}

export const ListType = {
  default: () => Immutable.List(),
  fromJson: (a) => Immutable.List(a),
  serialize: (ob) => ob.toJS()
};

export function StringType({defaultValue = ''} = {}) {
  return {
    default: () => defaultValue,
    fromJson: (a) => a,
    serialize: (a) => a
  };
}

export function BooleanType({defaultValue = false} = {}) {
  return {
    default: () => defaultValue,
    fromJson: (a) => a,
    serialize: (a) => a
  };
}

export function NumberType({defaultValue = 0} = {}) {
  return {
    default: () => defaultValue,
    fromJson: (a) => a,
    serialize: (a) => a
  };
}

export function ListOf(getClass) {
  return {
    default: () => Immutable.List(),
    fromJson: (items) => Immutable.List(items.map(p => getClass().fromJson(p))),
    serialize: (items) => items.map(li => li.serialize()).toJS()
  };
}



