import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from "../organisation.js";
import { BasicMySelect2 } from './generic.js';
import { compareMultiple } from '../utils.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


export function sortPhases(phases) {
  phases.sort(compareMultiple(
    (a, b) => (a.startDate != null ? 0 : 1) - (b.startDate != null ? 0 : 1),
    (a, b) => a.startDate - b.startDate,
    (a, b) => a.getTitle().localeCompare(b.getTitle())
  ));
}


export function getProjectPhases({project, selectedPhase, allowNull, nullText, organisationStore, filters = []}) {
  let availablePhases = project.phases.filter(function(p) {
    // If a phase is deleted we can't select it unless it was already selected.
    return filters.every(f => f(p)) && (!p.isDeleted || p === selectedPhase);
  });

  sortPhases(availablePhases);

  if (allowNull) {
    availablePhases.push(null);
  }

  return availablePhases.map(function(phase) {
    return {
      label: phase != null ? phase.getTitle()
        : nullText != null ? nullText
        : '(No phase)',
      value: phase
    }
  });
}


export var ProjectPhaseSelector = CreateReactClass({
  propTypes: {
    // Either pass in a list of options,
    options: PropTypes.array,

    // Or some tips for how to generate the options.
    project: PropTypes.object,
    allowNull: PropTypes.bool,

    phase: PropTypes.object,
    onChange: PropTypes.func.isRequired
  },

  getDefaultProps: function() {
    return {
      allowNull: true,
      placeholder: "(Please select)"
    };
  },

  render: function() {
    let { className, options, project, phase, allowNull, placeholder, ...props } = this.props;

    options = options || getProjectPhases({
      project: project,
      selectedPhase: phase,
      allowNull: allowNull,
      organisationStore: organisationStore
    });

    return <BasicMySelect2
      className={classNames("project-phase-selector", className)}
      options={options}
      value={phase}
      filter={function(option, searchText) {
        return option.label.toUpperCase().match(searchText.toUpperCase());
      }}
      placeholder={placeholder}
      {...props}
    />;
  }
});
