import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import LinkedStateMixin from 'react-addons-linked-state-mixin';
import { LinearScale } from '../utils.js';
import { ScrollBar, Timeline } from "../cashFlowChart.js";
import { Checkbox, FilterTextBox, Dropdown3, Dropdown3ListItem, ProjectReportSelector } from '../widgets.js';
import { MilestonesSaveBar } from '../widgets/savebar.js';
import { Modal, ModalContent } from '../modal.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { permissions } from '../models/permissions.js';
import { organisationStore } from '../organisation.js';
import { getOnboardingData } from '../organisationStore.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import { actions, store } from './flux.js';
import { CopyProjectForm } from './copyproject.js';
import { ProjectSidebar, PhaseSidebar, StaffAllocationSidebar } from './sidebars.js';
import { userStore } from '../user.js';
import { EditProjectForm } from './EditProjectForm.js';
import { EditProjectTasks } from './EditProjectTasks.js';
import { EditPhaseTasks } from './EditPhaseTasks.js';
import { EditPhaseForm } from './EditPhaseForm.js';
import { EditItemStaffMembersForm } from './EditItemStaffMembersForm.js';
import { ProjectExpensesForm } from './ProjectExpensesForm.js';
import { ChartContainer } from './ChartContainer.js';
import { PhaseSliderSet } from './PhaseSliderSet.js';
import { BIGGEST_SLIDER_CIRCLE_RADIUS, mainContentWidth, mainContentPaddingLeft } from './dimensions.js';


const elementResizeDetectorMaker = require("element-resize-detector");
const erd = elementResizeDetectorMaker();


export var MilestonesPage = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore, userStore], function() {
      return {
        onboardingData: getOnboardingData(organisationStore, permissions, userStore)
      };
    })
  ],

  render: function() {
    if (this.state.onboardingData.hasProjects) {
      return <MilestonesPageWithProjects />;
    }
    else {
      return <IncompleteSetupPage
        heading="Project Planning"
        onboardingData={this.state.onboardingData}
      />;
    }
  }
});


var MilestonesPageWithProjects = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([organisationStore, store, userStore], function() {
      return {
        projects: Array.from(store.iterVisibleProjects()),
        staffMembers: organisationStore.staffMembers,
        selection: store.selection,
        graphMode: store.graphMode,
        graphData: (
          store.graphMode === 'projected' || store.graphMode === 'actuals' ?
            store.cashFlow
          : store.staffAllocationGraph
        ),
        startDate: store.startDate,
        endDate: store.endDate,
        projectFilterText: store.projectFilterText,
        modals: store.modals,
        isDirty: store.projectState.getDirtyProjects().length > 0,
        saveState: store.saveState,
        linkDates: store.linkDates,
        user: userStore.user,
        canCreateProject: permissions.canCreateProject.ok(userStore.user),
        reportUuid: store.reportUuid
      };
    }),
    LinkedStateMixin
  ],


  getInitialState: function() {
    return {
      // SizeAwareContainer stuff
      offsetWidth: null,
      offsetHeight: null,
    };
  },

  componentWillMount: function() {
    var self = this;
    //TODO-merge
    if (store.organisation == null) {
      setTimeout(function() {
        actions.initializePage(self.props.organisation);
      }, 0);
    }
  },

  componentDidMount: function() {
    this.setupResizeHandler();
  },

  componentDidUpdate: function() {
    this.setupResizeHandler();
  },

  setupResizeHandler() {
    if (!this.isResizeHandlerSetup && this.refs.content != null) {
      var el = ReactDOM.findDOMNode(this.refs.content);
      erd.listenTo(el, this.handleResize);
      this.handleResize(el);
      this.isResizeHandlerSetup = true;
    }
  },

  handleResize: function(element) {
    this.setState({
      offsetWidth: element.offsetWidth,
      innerOffsetWidth: this.refs.innercontent.offsetWidth * ((100 - parseInt(mainContentPaddingLeft)) / 100),
      offsetHeight: element.offsetHeight
    });
  },

  update: function() {
    this.setState(this.getStateFromStores());
  },

  render: function() {
    let self = this;

    if (this.state.graphData != null && this.state.graphData.length > 0) {
      var data = this.state.graphData;

      // TODO-new_project_creator this should probably be done in the store.
      var xScale = new LinearScale({
        minX: data[0].date,
        maxX: data[0].date + 365,
        minY: 0,
        maxY: this.state.innerOffsetWidth
      });

      var minX = data[0].date;
      var maxX = data[data.length - 1].date + 31;
    }

    const graphModeOptions = [
      {value: "actuals", label: "Cash flow - actuals"},
      {value: "projected", label: "Cash flow - projected"},
      {value: "staff", label: "Staff utilisation - projected"}
    ];

    return <CoincraftPage
      className="scheduler"
      heading="Project Planning"
      body={<div>
        <div
            className="flexbox-container"
            style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '65em'}}>
          <div className="flex-1-1-auto scheduling-tool">
            {this.state.modals.map(function(modal) {
              let component, props, header, width = '70em';
              switch (modal.type) {
                case 'editItem':
                  if (modal.itemType === 'project') {
                    header = "Edit project";
                    component = EditProjectForm;
                    props = {
                      project: modal.item,
                      user: self.state.user
                    };
                  }
                  else {
                    header = "Edit phase";
                    component = EditPhaseForm;
                    props = {phase: modal.item};
                  }
                  break;
                case 'editItemTasks':
                  if (modal.itemType === 'project') {
                    header = "Project Tasks";
                    component = EditProjectTasks;
                    props = {project: modal.item};
                  }
                  else {
                    header = "Phase Tasks";
                    component = EditPhaseTasks;
                    props = {phase: modal.item};
                  }
                  break;
                case 'assignStaffMembers':
                  header = "Assigned staff members";
                  component = EditItemStaffMembersForm;
                  props = {item: modal.item};
                  break;
                case 'editProjectExpenses':
                  header = "Project expenses";
                  component = ProjectExpensesForm;
                  break;
                case 'copyProject':
                  width = '60em';
                  header = "Copy Project";
                  component = CopyProjectForm;
                  props = {project: modal.project};
                  break;
                default:
                  throw new Error("Unrecognised modal type");
              }

              return <Modal
                  closeOnClickOutside={true}
                  onClosed={() => actions.closeModal(modal)}>
                <ModalContent
                    header={header}
                    width={width}>
                  {React.createElement(component, {modal: modal, ...props})}
                </ModalContent>
              </Modal>;
            })}

            {(this.state.graphData != null
                && this.state.graphData.length > 0
                && permissions.financialVisibilityRevenue.ok(this.state.user)) ?
              <div ref="content">
                <div className="new-dashboard__panel new-dashboard__panel__inner">
                  <div ref="innercontent" style={{width: mainContentWidth, display: 'inline-block'}}>
                    <div>
                      <div style={{marginBottom: '1em'}}>
                        <Dropdown3
                            toggleElement={
                              <button className="btn btn-default">
                                {_.find(graphModeOptions , o => o.value === this.state.graphMode).label}
                                <i className="fa fa-caret-down" style={{margin: '0 0 0 1em'}} />
                              </button>
                            }>
                          {graphModeOptions .map(function(option) {
                            return <Dropdown3ListItem
                                key={option.value}
                                onClick={() => self.handleGraphModeChange(option)}
                                style={{width: '15em'}}>
                              {option.label}
                            </Dropdown3ListItem>;
                          })}
                        </Dropdown3>
                      </div>
                      <ChartContainer
                        data={this.state.graphData}
                        viewportScrollLeft={this.state.startDate}
                        onScroll={self.handleCashFlowGraphScroll}
                        xScale={xScale}
                        minX={minX}
                        maxX={maxX}
                        yValueType={this.state.graphMode === 'staff' ? 'hours' : 'dollars'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            :
              <div ref="content">
                {/* HACK - the handleResize code depends on there being a set of elements
                  of this form in order to calculate the scale for the scrollbar, so
                  we did all this to handle the case where there is a scrollbar but
                  no graph. */}
                <div style={{padding: '0 30px', height: 0, margin: 0}}>
                  <div ref="innercontent" style={{width: mainContentWidth, display: 'inline-block'}} />
                </div>
              </div>
            }

            <div
               className="new-dashboard__panel new-dashboard__panel__inner"
               style={{padding: '10px 30px'}}>
              {this.state.graphData != null && this.state.graphData.length !== 0 ?
                <div>
                  <div style={{width: mainContentWidth}}>
                    <div style={{marginLeft: mainContentPaddingLeft}}>
                      <div style={{height: 20}}>
                        <svg style={{width: '100%', height: '100%'}}>
                          <ScrollBar
                            viewportWidth={this.state.innerOffsetWidth}
                            scrollX={this.state.startDate}
                            min={minX}
                            max={maxX}
                            onScroll={self.handleCashFlowGraphScroll}
                            xScale={xScale}
                          />
                        </svg>
                      </div>
                      <div style={{height: 40}}>
                        <svg style={{width: '100%', height: '100%'}}>
                          <Timeline
                            min={minX}
                            max={maxX}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            offsetWidth={this.state.innerOffsetWidth}
                            offsetHeight={40}
                            data={this.state.graphData}
                            xScale={xScale}
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              : null}
            </div>

            <div className="new-dashboard__panel new-dashboard__panel__inner" style={{padding:0}}>
              <div className="widget-header" style={{padding: '1em', borderBottom: 'solid 1px #ccc'}}>
                {self.state.selection != null && self.state.selection.project != null ?
                  <div>
                    <div className="buttons" style={{display:'inline-block'}}>
                      <button
                          className="btn btn-default scheduler__back-to-project-list-button"
                          onClick={actions.viewAllProjects}>
                        <i className="fa fa-chevron-left" />
                        Back to Project List
                      </button>
                    </div>
                    <div style={{float: 'right'}}>
                      <Checkbox
                        label="Link Dates"
                        value={self.state.linkDates}
                        style={{marginRight: '1.5em'}}
                        onChange={function(checked) {
                          actions.setLinkDates(checked);
                        }}
                      />
                      <button
                          className="scheduler__copy-project-button btn btn-default"
                          onClick={this.handleCopyProjectButtonClick}>
                        <i className="fa fa-files-o" />
                        Copy Project
                      </button>
                    </div>
                  </div>
                :
                  <div className="flexbox-container flex-align-items-center">
                    <strong className="flex-0-0-auto">
                      Show:
                    </strong>
                    <ProjectReportSelector
                      reportUuid={this.state.reportUuid}
                      onChange={(reportUuid) => actions.selectReport(reportUuid)}
                    />
                    {self.state.canCreateProject ?
                      <div className="flex-0-0-auto" style={{marginLeft: '3.5em'}}>
                        <button
                            onClick={function() { actions.openCreateProjectForm(); }}
                            className="btn btn-default scheduler__create-project-button">
                          + Create project
                        </button>
                      </div>
                    : null}
                    <div className="flex-1-0-auto" />
                    <div className="flex-0-0-auto">
                      <FilterTextBox
                        value={self.state.projectFilterText}
                        onChange={(text) => actions.setProjectFilterText(text)}
                        placeholder="Search Projects"
                      />
                    </div>
                  </div>
                }
              </div>
              <div ref="sliderContent">
                <PhaseSliderSet
                  padding={{left: BIGGEST_SLIDER_CIRCLE_RADIUS, right: BIGGEST_SLIDER_CIRCLE_RADIUS}}
                  isEditable={
                    this.state.selection.project != null
                    && permissions.canEditProject(this.state.selection.project).ok(this.state.user)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>}
      sidebar={!this.state.selection.isBlank ?
        <div className="dashboard-layout__column dashboard-layout__aux-content-column">
          <div>
            {this.state.selection.phase != null && this.state.selection.staffMember != null ?
              <StaffAllocationSidebar
                item={this.state.selection}
                user={this.state.user}
              />
            : this.state.selection.phase != null ?
              <PhaseSidebar phase={this.state.selection.phase} />
            : this.state.selection.project != null ?
              <ProjectSidebar project={this.state.selection.project} />
            : null}
          </div>
        </div>
      : null}
      saveBar={
        <MilestonesSaveBar />

      }
    />;
  },

  handleCashFlowGraphScroll: function(x) {
    actions.scrollTo(x);
  },

  handleAlertBarSaveClick: function() {
    actions.alertBarSaveClick();
  },

  handleGraphModeChange: function(option) {
    actions.setGraphMode(option.value);
  },

  handleCopyProjectButtonClick: function() {
    actions.clickCopyProjectButton();
  }
});
