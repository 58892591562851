import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { organisationStore, actions as organisationActions } from "../organisation.js";
import PropTypes from "prop-types";


export var ConfirmableDeleteButton = CreateReactClass({
  propTypes: {
    object: PropTypes.object.isRequired,
    text: PropTypes.string,
    objectTypeName: PropTypes.string.isRequired,
    popupBelow: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      text: "Delete",
      popupBelow: false
    };
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        deleteError: organisationStore.getDeleteError(this.props.object)
      };
    })
  ],

  getInitialState: function() {
    return {
      showConfirmDeletePopup: false
    };
  },

  render: function() {
    return (
      <div style={{display: 'inline-block', position: 'relative'}}>
        <button
            className="btn btn-default edit-controls__delete-button"
            onClick={this.openConfirmDelete}>
          <i className="fa fa-trash" />
          {this.props.text}
        </button>

        {this.state.showConfirmDeletePopup ?
          <MyPopover width="27em" popupBelow={this.props.popupBelow}>
            <div style={{textAlign: 'left'}}>
              <div className="edit-controls__confirm-popup">
                <p>
                  Are you sure you want to delete this {this.props.objectTypeName}?
                </p>
                <div>
                  <a
                      href="javascript:void(0)"
                      onClick={this.handleCancelDeleteClick}>
                    Cancel
                  </a>
                  <button
                      className="btn btn-default edit-controls__confirm-delete-button"
                      style={{marginLeft: 12}}
                      onClick={this.handleConfirmDeleteClick}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </MyPopover>
        : null}

        {this.state.deleteError != null ?
          <MyPopover width="36em" popupBelow={this.props.popupBelow}>
            <div className="edit-controls__cant-delete-because-dependencies">
              {this.state.deleteError === 'timesheet_entries_exist' ?
                <div>
                  <p>
                    We can't delete this {this.props.objectTypeName} because there is time allocated to it.
                  </p>
                  <p>
                    If you want to hide it, check the <strong>Is archived</strong> check box and save.
                  </p>
                </div>
              : this.state.deleteError === 'projects_exist' ?
                <p>
                  Sorry, we can't delete this contact because projects are using it.
                </p>
              :
                <p>
                  Sorry, we can't delete this item because other items depend on it.
                </p>
              }
              <div>
                <button
                    className="btn btn-default"
                    onClick={this.handleDismissDeleteButTimesheets}>
                  Okay
                </button>
              </div>
            </div>
          </MyPopover>
        : null}
      </div>
    );
  },

  openConfirmDelete: function() {
    this.setState({showConfirmDeletePopup: true});
  },

  handleConfirmDeleteClick: function() {
    if (this.props.onDelete != null) {
      this.props.onDelete(this.props.object);
    }
    else {
      organisationActions.deleteObject(this.props.object);
    }
  },

  handleCancelDeleteClick: function() {
    this.setState({showConfirmDeletePopup: false});
  },

  handleDismissDeleteButTimesheets: function() {
    organisationActions.dismissDeleteError();
    this.setState({
      showConfirmDeletePopup: false,
    });
  }
});


var MyPopover = CreateReactClass({
  propTypes: {
    width: PropTypes.any,
    popupBelow: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      width: '30em'
    };
  },

  render: function() {
    function makeDownTriangle(width, height) {
      let width2 = width / 2;
      return <svg style={{width: width, height: width, marginTop: -1}}>
        <path d={`M 0 0   L ${width2} ${height}    L ${width} 0 z`} style={{fill: 'white'}} />
        <path d={`M 0 0   L ${width2} ${height} z`} style={{stroke: 'rgba(0,0,0,0.2)', strokeWidth: 1}} />
        <path d={`M ${width2} ${height}    L ${width} 0 z`} style={{stroke: 'rgba(0,0,0,0.2)', strokeWidth: 1}} />
      </svg>;
    }

    function makeUpTriangle(width, height) {
      let width2 = width / 2;
      let svgHeight = width;
      let triHeight = height;
      return <svg style={{width: width, height: width, marginBottom: -6}}>
        <path d={`M 0 ${svgHeight}   L ${width2} ${svgHeight - triHeight}    L ${width} ${svgHeight} z`} style={{fill: 'white'}} />
        <path d={`M 0 ${svgHeight}   L ${width2} ${svgHeight - triHeight} z`} style={{stroke: 'rgba(0,0,0,0.2)', strokeWidth: 1}} />
        <path d={`M ${width2} ${svgHeight - triHeight}   L ${width} ${svgHeight} z`} style={{stroke: 'rgba(0,0,0,0.2)', strokeWidth: 1}} />
      </svg>;
    }

    // The somewhat arbitrary numbers here are based on the fact that this
    // control is only used for the ConfirmableDeleteButton, so we want it to
    // basically align to the left of that, in case the delete button exists to
    // the left of a small screen (we used to centre but now if we centre then
    // the buttons will be off the screen).
    return (
      <div style={
          this.props.popupBelow ?
            {position: 'absolute', top: '100%', right: '-50%', width: this.props.width, zIndex: 1}
          : {position: 'absolute', bottom: '100%', left: '50%', width: this.props.width, zIndex: 1}
        }>
        <div style={
            this.props.popupBelow ?
              {width: '100%', height: '100%'}
            : {marginLeft: '-12%', width: '100%', height: '100%'}
          }>
          {this.props.popupBelow ?
            <div style={{marginLeft: `calc(${this.props.width} - 7em)`}}>
              {makeUpTriangle(22, 13)}
            </div>
          : null}
          <div style={{backgroundColor: 'white', color: '#444', padding: '1.5em', border: '1px solid rgba(0,0,0,0.2)', borderRadius: 6, boxShadow: '0 5px 10px rgba(0,0,0,.2)'}}>
            {this.props.children}
          </div>
          {!this.props.popupBelow ?
            <div style={{marginLeft: '2.6em'}}>
              {makeDownTriangle(22, 13)}
            </div>
          : null}
        </div>
      </div>
    );
  }
});
