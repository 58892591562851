import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import { CommaSeparatedList } from "../widgets.js";
import { CoincraftPage } from "../CoincraftPage.js";
import { reportsPageWrapper } from "../reports/reportsPageWrapper.js";
import { userStore } from "../user.js";
import { Table } from "../table.js";
import { organisationStore, newOrganisationActions } from "../organisation.js";
import { staffMembersPageStore } from "./flux.js";
import {
	ReportStoreWidget,
	ReportStoreSaveBar,
} from "../reports/ReportWidget.js";
import { wrapLoading } from "../reports/wrapLoading.js";
import { formatNumber2, formatPercentage0, formatMinutes } from "../utils.js";

export var StaffMembersPage = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin(
			[organisationStore, staffMembersPageStore, userStore],
			function () {
				const reportStore = staffMembersPageStore.reportStore;
				if (
					!(
						organisationStore.isReady &&
						staffMembersPageStore.isReady
					)
				) {
					return {
						isReady: false,
					};
				} else {
					return {
						isReady: true,
						staffMembers: staffMembersPageStore.getMatchingStaff(),
						selectedColumns: reportStore.report.columns.map((c) =>
							reportStore.getColumnById(c)
						),
						columns: reportStore.columns,
						report: reportStore.report,
						reportStore: reportStore,
						reports: organisationStore.reports.filter(
							(r) => r.reportType === "staffMember"
						),
						currencyFormatter:
							organisationStore.organisation.currencyFormatter,
						user: userStore.user,
						isAdmin: userStore.isAdmin(),
					};
				}
			}
		),
	],

	render: function () {
		if (!this.state.isReady) {
			return null;
		}

		const formatNumber2Column = {
			content: function (sm, i, stack, num) {
				return num === Infinity ? (
					<span>&infin;</span>
				) : (
					formatNumber2(num)
				);
			},
		};

		const currencyFormatter = {
			content: (item, i, stack, amount) =>
				this.state.currencyFormatter.format(amount),
		};

		const columnPresentation = {
			allocatedHours: formatNumber2Column,
			availableHours: formatNumber2Column,
			billableHours: formatNumber2Column,
			nonBillableHours: formatNumber2Column,
			invoicedHours: formatNumber2Column,
			weeklyAvailability: {
				content: function (sm, i, stack, minutes) {
					return formatMinutes(minutes);
				},
			},
			payRate: currencyFormatter,
			overtimeRate: currencyFormatter,
			costRate: currencyFormatter,
			chargeOutRate: currencyFormatter,
			revenueEarned: currencyFormatter,
			revenueEarnedPerHour: currencyFormatter,
			recordedHours: {
				content: wrapLoading(function (sm, i, stack, num) {
					return num === Infinity ? (
						<span>&infin;</span>
					) : (
						formatNumber2(num)
					);
				}),
			},
			projects: {
				content: function (sm, i, stack, projects) {
					return (
						<CommaSeparatedList
							items={projects}
							formatter={(p) => p.getTitle()}
						/>
					);
				},
				contentText: function (sm, i, stack, projects) {
					return projects.map((p) => p.getTitle()).join(", ");
				},
			},
			isArchived: {
				content: function (sm, i, stack, isArchived) {
					return isArchived ? "Yes" : "No";
				},
			},
			percentBillableHours: {
				content: function (sm, i, stack, percent) {
					return formatPercentage0(percent);
				},
			},
		};

		return (
			<CoincraftPage
				className="staff-members-page"
				header={
					<ReportStoreWidget
						heading="Staff members"
						user={this.state.user}
						items={this.state.staffMembers}
						reports={this.state.reports}
						reportStore={this.state.reportStore}
						columnPresentation={columnPresentation}
						filterInputPlaceholder="Filter by staff name"
						buttons={[
							this.state.isAdmin ? (
								<button
									className="page-header__button--primary"
									onClick={() =>
										newOrganisationActions.newStaffMember()
									}
								>
									+ New Staff Member
								</button>
							) : null,
						]}
					/>
				}
				body={
					<Table
						scroll={true}
						rows={this.state.staffMembers}
						tableProps={{ className: "data-table" }}
						rowProps={function (sm, i) {
							return {
								key: sm.id,
								style: { cursor: "pointer" },
								onClick: () =>
									newOrganisationActions.editStaffMember(
										sm.id
									),
							};
						}}
						columns={this.state.selectedColumns}
						columnPresentation={columnPresentation}
						initialSort={{
							columnIdentifier: "lastName",
							direction: "asc",
							isAlreadySorted: false,
						}}
					/>
				}
			/>
		);
	},
});

export var StaffMembersPageWrapper = reportsPageWrapper(
	staffMembersPageStore,
	StaffMembersPage
);
