import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { NumberInputContainer, CurrencyIcon } from '../widgets.js';
import { LargeSidebarInput } from './SidebarUtils.js';
import { permissions, requiresPermission, OperationLevel } from '../models/permissions.js';
import classNames from 'classnames';

import PropTypes from "prop-types";


export var ProjectFeePanel = requiresPermission(
  ({project}) => permissions.projectFinancialsBudgets(project),
  {},
  CreateReactClass({
    propTypes: {
      user: PropTypes.object.isRequired,
      project: PropTypes.object.isRequired,
      phaseList: PropTypes.array.isRequired,
      permissionLevel: PropTypes.string.isRequired // Provided by `requiresPermission`
    },

    render: function() {
      const isEditable = (this.props.permissionLevel === OperationLevel.edit);

      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">
            Fee
          </h3>
          <LargeSidebarInput
            value={this.props.project.fee}
            action={actions.setProjectSidebarProjectFee}
            isEditable={isEditable}
          />

          <div>
            <table className="input-table">
              <tbody>
                {this.props.phaseList.map(function(row, i) {
                  return <tr key={i}>
                    <td className="text" style={{borderRight: 'solid 1px #ccc'}}>
                      {row.phase.name}
                    </td>
                    <td className="percent" style={{borderRight: 'solid 1px #ccc', width: '28%'}}>
                      {Math.round(row.percentageOfProjectFee)}
                      <span style={{marginRight: '1em', fontWeight: 600}}>%</span>
                    </td>
                    <td className={classNames("dollar", {"cell-input-field": isEditable})}>
                      <CurrencyIcon />
                      <NumberInputContainer
                        isEditable={isEditable}
                        value={row.fee}
                        action={actions.setProjectSidebarPhaseFee}
                        actionArgs={[row.phase]}
                      />
                    </td>
                  </tr>;
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  })
);


export var PhaseFeePanel = requiresPermission(
  ({phase}) => permissions.projectFinancialsBudgets(phase.project),
  {},
  CreateReactClass({
    propTypes: {
      user: PropTypes.object.isRequired,
      phase: PropTypes.object.isRequired,
      permissionLevel: PropTypes.string.isRequired // Provided by `requiresPermission`
    },

    render: function() {
      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">Fee</h3>
          <LargeSidebarInput
            value={this.props.phase.fee}
            action={actions.setPhaseSidebarPhaseFee}
          />
        </div>
      );
    }
  })
);
