import { observer } from "mobx-react";
import React, { useEffect } from "react";
import TimerPanel from "./TimerPanel";
import timerStore from "./timerStore";

export default observer(() => {
	useEffect(() => {
		timerStore.init(); // make sure data loaded before intializing
	}, []);
	return (
		<div style={{ position: "relative", cursor: "pointer" }}>
			<div onClick={timerStore.toggleOpen}>
				{timerStore.timeDisplay}
				<i
					style={{ fontSize: "1.25em", marginLeft: "0.5em" }}
					className="fa fa-clock-o"
				/>
			</div>
			{timerStore.open ? <TimerPanel /> : null}
		</div>
	);
});
