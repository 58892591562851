import React from 'react';
import CreateReactClass from 'create-react-class';


export var LoadingSpinner = CreateReactClass({
  render: function() {
    return <i className="fa fa-spinner fa-spin" style={{margin: 0, ...this.props.style}} />;
  }
});

