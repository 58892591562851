import React from "react";
import CreateReactClass from "create-react-class";
import _ from "underscore";
import { dateConverter } from "../../models/dateconverter.js";
import { sum } from "../../utils.js";
import { NumberInputContainer, ProjectPhaseSelector } from "../../widgets.js";
import { Link } from "react-router";
import classNames from "classnames";
import { scheduleStore, actions } from "../ScheduleStore.js";
import { makeMultipleStoreMixin } from "../../coincraftFlux.js";
import { ProgressBar } from "../ProgressBar.js";
import { organisationStore } from "../../organisation.js";
import {
	permissions,
	requiresPermission,
	OperationLevel,
	UserLevel,
} from "../../models/permissions.js";
import { userStore } from "../../user.js";

export var HistoryMenu = requiresPermission(
	permissions.noRestrictions,
	{
		isEditable: ({ project }) =>
			project ? permissions.canEditProject(project) : { ok: () => true },
	},
	CreateReactClass({
		mixins: [
			makeMultipleStoreMixin([scheduleStore, userStore], function () {
				return {
					cell: scheduleStore.selectedCell,
					user: userStore.user,
					selectedRowId: scheduleStore.selectedRowId,
					selectedRow: scheduleStore.selectedRow,
					selectedMonthIndex: scheduleStore.selectedMonthIndex,
					currentMonthIndex: scheduleStore.currentMonthIndex,
					selectedHoursInputText:
						scheduleStore.selectedHoursInputText,
					selectedBudgetInputText:
						scheduleStore.selectedBudgetInputText,
					selectedUtilisationInputText:
						scheduleStore.selectedUtilisationInputText,
				};
			}),
		],

		componentWillMount() {
			this.timelineItems = {};
			this.timeline = null;
		},

		componentDidUpdate(prevProps, prevState) {
			const thisMonth = this.state.selectedMonthIndex;
			const timeline = this.timeline;
			const timecard = this.timelineItems[thisMonth];
			if (!(thisMonth && timeline && timecard)) return;
			if (
				timecard.getBoundingClientRect().top <=
					timeline.getBoundingClientRect().top -
						timecard.offsetHeight * 2 ||
				timecard.getBoundingClientRect().bottom >=
					timeline.getBoundingClientRect().bottom +
						timecard.offsetHeight * 2
			) {
				timecard.scrollIntoView(true);
				timeline.scrollTop -=
					timeline.offsetHeight / 2 - timecard.offsetHeight / 2;
			} else if (
				timecard.getBoundingClientRect().top <=
				timeline.getBoundingClientRect().top + timecard.offsetHeight / 2
			) {
				timecard.scrollIntoView(true);
				timeline.scrollTop -= timecard.offsetHeight / 2;
			} else if (
				timecard.getBoundingClientRect().bottom >=
				timeline.getBoundingClientRect().bottom -
					timecard.offsetHeight / 2
			) {
				timecard.scrollIntoView(false);
				timeline.scrollTop += timecard.offsetHeight / 2;
			}
		},

		render() {
			const {
				selectedRow,
				selectedMonthIndex,
				currentMonthIndex,
				selectedRowId,
				selectedHoursInputText,
				selectedBudgetInputText,
				selectedUtilisationInputText,
			} = this.state;
			return (
				<div className="spreadsheet-history-menu">
					<div
						className="spreadsheet-history-menu__header"
						style={{ borderBottom: "1px solid #ccc" }}
					>
						<div className="title">{selectedRow?.title}</div>
						{false &&
						selectedRow?.project &&
						(selectedRow.project.status === "prospective" ||
							selectedRow.project.status === "onHold") ? (
							<div className="likelihood">
								<span className="text">Likelihood</span>
								<span className="input">
									<input
										type="text"
										value={selectedRow.project.likelihood}
										onChange={(e) =>
											actions.changeLikelihood(
												selectedRowId,
												e.target.value
											)
										}
									/>
									%
								</span>
							</div>
						) : null}
					</div>
					<div
						className="spreadsheet-history-menu__timeline"
						ref={(el) => (this.timeline = el)}
					>
						{selectedRow?.monthIndexArray
							.reverse()
							.map((monthIndex) => {
								const selected =
									monthIndex === selectedMonthIndex;
								const past = monthIndex < currentMonthIndex;
								const editable =
									selected && !past && this.props.isEditable;
								return (
									<TimeCard
										key={monthIndex}
										monthIndex={monthIndex}
										selected={selected}
										past={past}
										editable={editable}
										value={Math.round(
											selectedRow.getDisplayedHoursMonthIndex(
												monthIndex
											)
										)}
										onChangeValue={(e) =>
											actions.setSelectedCellHours(
												e.target.value
											)
										}
										refFunc={(el) =>
											(this.timelineItems[monthIndex] =
												el)
										}
										onClick={
											!selected
												? () =>
														actions.selectHistoryMenuMonth(
															monthIndex
														)
												: undefined
										}
										inputText={selectedHoursInputText}
									>
										<TimeCardProgress
											title={"Utilisation"}
											value={Math.round(
												selectedRow.getDisplayedHoursMonthIndex(
													monthIndex
												)
											)}
											percent={Math.round(
												selectedRow.getDisplayedPercentUtilisationMonthIndex(
													monthIndex
												)
											)}
											prevPercent={0}
											editable={editable}
											onChangeValue={(e) =>
												actions.setSelectedCellHours(
													e.target.value
												)
											}
											onChangePercent={(e) =>
												actions.setSelectedUtilisation(
													e.target.value
												)
											}
											inputText={
												selectedUtilisationInputText
											}
										/>
										{selectedRow.displayBudget ? (
											<TimeCardProgress
												title={"Budget Use"}
												value={Math.round(
													selectedRow.getDisplayedHoursMonthIndex(
														monthIndex
													)
												)}
												percent={Math.round(
													selectedRow.getDisplayedBudgetUseMonthIndex(
														monthIndex
													)
												)}
												prevPercent={Math.round(
													selectedRow.getDisplayedBudgetUseMonthIndex(
														monthIndex - 1
													)
												)}
												editable={editable}
												onChangeValue={(e) =>
													actions.setSelectedCellHours(
														e.target.value
													)
												}
												onChangePercent={(e) =>
													actions.setSelectedBudgetUse(
														e.target.value
													)
												}
												inputText={
													selectedBudgetInputText
												}
											/>
										) : null}
									</TimeCard>
								);
							})}
					</div>
				</div>
			);
		},
	})
);

var TimeCard = CreateReactClass({
	render: function () {
		const {
			selected,
			past,
			monthIndex,
			children,
			onClick,
			editable,
			value,
			onChangeValue,
			inputText,
		} = this.props;
		return (
			<div
				ref={this.props.refFunc}
				className={classNames(
					"spreadsheet-history-menu__timecard",
					{ selected: selected },
					{ past: past }
				)}
				onClick={onClick}
				style={onClick ? { cusor: "pointer" } : {}}
			>
				<div className="date-title">
					<div className="date">
						{dateConverter
							.monthIndexToMoment(monthIndex)
							.format("MMM YY")}
					</div>
					<div className="hours">
						{editable ? (
							<span>
								Hours:{" "}
								<input
									type="text"
									value={inputText || value}
									onChange={onChangeValue}
								/>
							</span>
						) : (
							<span>Hours: {value}</span>
						)}
					</div>
				</div>
				{children}
			</div>
		);
	},
});

var TimeCardProgress = CreateReactClass({
	render: function () {
		const {
			title,
			value,
			percent,
			editable,
			prevPercent,
			onChangeValue,
			onChangePercent,
			inputText,
		} = this.props;
		return (
			<div className="timecard-progress">
				<div className="timecard-text">
					<div className="title-val">
						<div className="title">{title}</div>
					</div>
					<div className="percent">
						{editable ? (
							<span>
								<input
									type="text"
									value={inputText || percent}
									onChange={onChangePercent}
								/>
								%
							</span>
						) : (
							<span>{`${percent}%`}</span>
						)}
					</div>
				</div>
				<ProgressBar
					className="progress-bar"
					fromPercentage={prevPercent}
					toPercentage={percent}
					height="0.4em"
				/>
			</div>
		);
	},
});
