import React from 'react';
import CreateReactClass from 'create-react-class';
import { VerticalAlign } from '../utils.js';
import { organisationStore } from "../organisation.js";
import { actionMixin, NumberInputContainer, AutosizeNumber, CurrencyIcon } from '../widgets.js';
import { Project, ProjectPhase } from '../models.js';
import { actions } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { FlexTable, FlexRow, FlexCell } from '../widgets/FlexTable.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import classNames from 'classnames';

import PropTypes from "prop-types";


export var LargeSidebarInput = CreateReactClass({
  mixins: [actionMixin],

  propTypes: {
    value: PropTypes.any,
    isEditable: PropTypes.bool,

    // If specified, use this FontAwesome icon to the left of the input,
    // otherwise default to the organisation's default currency
    icon: PropTypes.string
  },

  getDefaultProps: function() {
    return {
      isEditable: true,
    };
  },

  render: function() {
    return (
      <div
          className={classNames("large-input", {"input-field": this.props.isEditable})}
          style={this.props.style}
          onClick={this.handleClick}>
        <VerticalAlign align="middle">
          {this.props.icon != null ?
            <i className={classNames("fa", this.props.icon)} style={{fontSize: 25}} />
          : <CurrencyIcon style={{fontSize: 25}} />}
          {this.props.isEditable ?
            <AutosizeNumber
              ref="input"
              style={{display: 'inline', padding: 0, margin: 0, outline: 0, lineHeight: 1, fontSize: 25}}
              inputStyle={{border: 0, padding: 0, outline: 0}}
              value={this.props.value}
              onChange={this.handleInputChange}
            />
          :
            <span style={{lineHeight: 1, fontSize: 25}}>
              {this.props.value}
            </span>
          }
        </VerticalAlign>
      </div>
    );
  },

  focus: function() {
    if (this.refs.input != null) {
      this.refs.input.focus();
    }
  },

  handleInputChange: function(value) {
    this.executeAction(value);
  },

  handleClick: function() {
    this.focus();
  }
});


export var Budgets = CreateReactClass({
  propTypes: {
    isEditable: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    budgetType: PropTypes.oneOf(['expenses', 'hours']).isRequired,
    getItemBudget: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      setManualBudget: PropTypes.func.isRequired,
      setPhaseBudget: PropTypes.func,
      calculate: PropTypes.func.isRequired
    }).isRequired
  },

  componentWillMount: function() {
    this.hasBeenRendered = false;
  },

  render: function() {
    let self = this;

    return <div>
      {/* TODO-project_architect this has problems with backspacing to zero or null value */}
      <LargeSidebarInput
        isEditable={self.props.isEditable}
        value={this.props.getItemBudget(this.props.item)}
        action={this.props.actions.setManualBudget}
        style={{height: 'auto', padding: '0.6em'}}
        icon={this.props.budgetType === 'expenses' ? null : 'fa-clock-o'}
      />

      {this.props.item.constructor === Project ?
        <table className="input-table" style={{width: '100%'}}>
          <tbody>
            {this.props.item.phases.map(function(phase, i) {
              return <tr key={i}>
                <td style={{borderRight: 'solid 1px #ccc'}}>{phase.name}</td>
                <td className={self.props.isEditable ? "cell-input-field" : null}>
                  <NumberInputContainer
                    isEditable={self.props.isEditable}
                    value={self.props.getItemBudget(phase)}
                    action={self.props.actions.setPhaseBudget}
                    actionArgs={[phase]}
                  />
                </td>
              </tr>;
            })}
            {this.props.budgetType === 'expenses' ?
              <tr>
                <td style={{borderRight: 'solid 1px #ccc'}}>
                  Project expenses
                </td>
                <td className={self.props.isEditable ? "cell-input-field" : null}>
                  <NumberInputContainer
                    isEditable={self.props.isEditable}
                    value={this.props.item.manualExpensesBudget}
                    action={self.props.actions.setPhaseBudget}
                    actionArgs={['expenses']}
                  />
                </td>
              </tr>
            : null}
          </tbody>
        </table>
      : null}

      {this.props.item.constructor === ProjectPhase && this.props.budgetType === 'hours' ?
        <FlexTable>
          {this.props.item.staffMemberBudgetedHours.map(function({staffMember, hours}, i) {
            return <FlexRow key={i}>
              <FlexCell style={{width: '50%'}}>
                {staffMember.getFullName()}
              </FlexCell>
              <FlexCell
                  className={self.props.isEditable? "cell-input-field" : null}
                  style={{width: '50%'}}>
                <NumberInputContainer
                  isEditable={self.props.isEditable}
                  type="text"
                  value={hours}
                  action={actions.phaseSidebarSetStaffMemberBudgetedHours}
                  actionArgs={[staffMember]}
                />
              </FlexCell>
            </FlexRow>;
          })}
        </FlexTable>
      : null}

      {this.props.isEditable ?
        <div style={{padding: '0.6em', textAlign: 'center'}}>
          <button
              className="btn btn-default"
              onClick={this.handleCalculateButtonClick}>
            Calculate from Staff Hours
          </button>
        </div>
      : null}
    </div>;
  },

  handleCalculateButtonClick: function() {
    this.props.actions.calculate();
  }
});


export var ProfitPanel = requiresPermission(
  ({item}) => permissions.projectProfit(item.getProject()),
  {},
  CreateReactClass({
    propTypes: {
      item: PropTypes.object.isRequired // Project or Phase
    },

    mixins: [
      makeMultipleStoreMixin([organisationStore], function() {
        return {
          currencyFormatter: organisationStore.organisation.currencyFormatter
        };
      })
    ],

    render: function() {
      const item = this.props.item;
      return (
        <div className="dashboard-widget info-panel" style={{padding: 0}}>
          <h3 className="title">Profit</h3>
          <div style={{textAlign: 'center', fontSize: '3em', padding: '.75em'}}>
            {item.fee > 0 ?
              <span>{Math.round(item.getProfitPercent())}%</span>
            : <span
                  title="We can't show a profit percentage for this item because it has no fee"
                  style={{fontStyle: 'italic', color: '#666'}}>
                N/A
              </span>
            }
          </div>
          <div style={{textAlign:'center', fontSize:'1.3em', padding:'.5em', borderTop: 'solid 1px #ccc'}}>
            {this.state.currencyFormatter.format(item.getProfit())}
          </div>
        </div>
      );
    }
  })
);

