import {Constants} from "../Constants";

export default ({ string }) => {
	return (
		Constants[string]?.() || {
			type: "text",
			value: string,
			primitive: true,
		}
	);
};
