export const InterpolationThingy = class {
			constructor(values) {
				this.values = values;
			}

			interpolate(x) {
				let y1 = this.values[0].y;
				let x1 = this.values[0].x;
				for (let i = 1, l = this.values.length; i < l; i++) {
					let point = this.values[i];
					if (point.x >= x) {
						let x2 = point.x;
						let y2 = point.y;
						return y1 + ((x - x1) / (x2 - x1)) * (y2 - y1);
					}
					x1 = point.x;
					y1 = point.y;
				}
				return this.values[this.values.length - 1].y;
			}
		};



