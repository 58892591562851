import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../modal.js';
import { TriStateSaveButton } from '../widgets.js';
import PropTypes from "prop-types";


export var ConfirmUpdateTimesheetEntriesModal = CreateReactClass({
  propTypes: {
    itemTypeName: PropTypes.string.isRequired,
    saveState: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  },

  render: function() {
    return <Modal>
      <ModalContent header="Saving project" width='45em' bodyStyles={{padding: 0}}>
        <div style={{padding: "2em"}}>
          <p>
            You have changed the billable or variation status of one or more
            tasks in this {this.props.itemTypeName}.
          </p>
          <p>
            {"Saving will set the modified task "}<strong>timesheet entries</strong>
            {' to the new '}<strong>billable & variation</strong> settings.
          </p>
        </div>
        <div style={{borderTop: '1px solid #ccc', textAlign: 'right', padding: '1em'}}>
          <a
              href="javascript:void(0)"
              onClick={this.props.onCancel}>
            Cancel
          </a>

          <TriStateSaveButton
            text="Save"
            className="btn btn-default"
            onClick={this.props.onConfirm}
            state={this.props.saveState}
            style={{marginLeft: '1em'}}
          />
        </div>
      </ModalContent>
    </Modal>;
  }
});
