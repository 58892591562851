import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { CoincraftPage } from '../CoincraftPage.js';
import { LeaveHook } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { rootStore } from '../RootStore.js';
import { staffRoleStore, actions } from './staffRoleStore.js';
import { StaffRoleForm } from './StaffRoleForm.js';


export var StaffRolePage = CreateReactClass({
  mixins: [
    LeaveHook,
    makeMultipleStoreMixin([rootStore], function() {
      return {
        staffRole: staffRoleStore.staffRole
      };
    })
  ],

  componentWillMount: function() {
    let self = this;
    setTimeout(function() {
      actions.loadPage(self.props.params.roleId);
    }, 0);
  },

  componentWillReceiveProps: function(nextProps) {
    let self = this;
    // this is because if you create a new staff member then create another new staff member
    // the url will not change and it will keep the first staff member data
    if (nextProps.params.roleId === 'new' || nextProps.params.roleId !== this.props.params.roleId) {
      setTimeout(function() {
        actions.loadPage(self.props.params.roleId);
      }, 0);
    }
  },

  routerWillLeave(nextLocation) {
    if (staffRoleStore.getIsDirty()) {
      return "We haven't saved your changes to this staff role yet. " +
        "If you leave the staff page without saving, you'll lose your changes.";
    }
  },

  render: function() {
    if (this.state.staffRole == null) {
      return null;
    }
    return <StaffRoleForm
      staffRole={this.state.staffRole}
      template={CoincraftPage}
    />;
  }
});
