import React from 'react';
import CreateReactClass from 'create-react-class';
import { ProgressWidget } from './ProgressWidget.js';
import { NumberInputContainer } from '../../widgets.js';
import PropTypes from "prop-types";

export var ContextMenuProgressBars = CreateReactClass({
  propTypes: {
    currentMonthTitle: PropTypes.string,
    totalTitle: PropTypes.string,
    previousMonth: PropTypes.number,
    toDate: PropTypes.number,
    allTime: PropTypes.number,
    target: PropTypes.number,
    valueFormatter: PropTypes.func
  },

  render: function() {
    return <section>
      <ProgressWidget
        title={this.props.currentMonthTitle}
        fromValue={this.props.previousMonth}
        toValue={this.props.toDate}
        targetValue={this.props.target}
        valueFormatter={this.props.valueFormatter}
      />
      <ProgressWidget
        title={this.props.totalTitle}
        toValue={this.props.allTime}
        targetValue={this.props.target}
        valueFormatter={this.props.valueFormatter}
        style={{marginTop: '1.5em'}}
      />
    </section>;
  }
});

export var ContextMenuMonthInput = CreateReactClass({
  propTypes: {
    unitName: PropTypes.string,
    selectedCellTense: PropTypes.string,
    momentMonth: PropTypes.object,
    targetValue: PropTypes.number,
    actualValue: PropTypes.number,
    projectedValue: PropTypes.number,
    targetAction: PropTypes.any,
    targetActionArgs: PropTypes.array,
    isEditable: PropTypes.bool,
    valueFormatter: PropTypes.func,
    actualValueDetails: PropTypes.node
  },

  getDefaultProps: function() {
    return {
      valueFormatter: value => Math.round(value),
    };
  },

  getInitialState: function() {
    return {
      showDetails: false,
    };
  },

  render: function() {
    const valueType = this.props.selectedCellTense === 'past' ? 'Actual' : 'Projected';
    const renderActuals = this.props.selectedCellTense !== 'future' || this.props.actualValue > 0;
    const usingActualValue = this.props.selectedCellTense === 'past' || this.props.projectedValue !== this.props.targetValue;

    return <section>
      <div className="context-menu__title-line--small" style={{marginBottom: '0.5em', textAlign: 'center'}}>
        {`${valueType} ${this.props.unitName} for ${this.props.momentMonth.format("MMM YYYY")}`}
      </div>
      {renderActuals ?
        <div
            className="context-menu__title-line--large"
            style={{marginBottom: '1em', textAlign: 'center', textDecoration: 'none', fontWeight: 600}}>
          {this.props.valueFormatter(this.props.projectedValue)}
        </div>
      : null}
      <div
          style={{fontWeight: !usingActualValue ? 900 : 'normal', fontSize: '1.1em'}}
          className="flexbox-container flex-space-between flex-align-items-center">
        <span>{renderActuals ? `Target ${this.props.unitName}: ` : null}</span>
        <NumberInputContainer
          executeActionOn="blur"
          value={this.props.targetValue}
          action={this.props.targetAction}
          actionArgs={this.props.targetActionArgs}
          isEditable={this.props.isEditable}
          style={{
            textAlign: renderActuals ? 'right' : 'center',
            width: renderActuals ? '50%' : '100%'
          }}
        />
      </div>
      {renderActuals ?
        <div>
          <div
              style={{fontWeight: usingActualValue ? 900 : 'normal', fontSize: '1.1em', margin: '0.5em 0'}}
              className="flexbox-container flex-space-between flex-align-items-center">
            <span>{`Actual ${this.props.unitName}: `}</span>
            <span style={{textAlign: 'right', width: '50%'}}>
              {this.props.valueFormatter(this.props.actualValue)}
            </span>
          </div>
          {this.props.actualValue > 0 && this.props.actualValueDetails != null ?
            <div>
              <button
                  style={{marginBottom: '1em'}}
                  onClick={() => this.setState({showDetails: !this.state.showDetails})}>
                Details
              </button>
            </div>
          : null}
          {this.state.showDetails ? this.props.actualValueDetails : null}
        </div>
      : null}
    </section>;
  }
});

