import { observable, computed, action, makeObservable } from "mobx";
import {
	format,
	startOfWeek,
	endOfWeek,
	addDays,
	startOfDay,
	isWeekend,
} from "date-fns";
import cuid from "cuid";
import apiRequest from "../../apiRequest";
import { userStore } from "../../user";
import { organisationStore } from "../../organisation";
import { PermissionLevel } from "../../models/permissions";
import { getProjectOptions } from "../../widgets/ProjectSelector";
import { Invoice } from "../../models/Invoice.js";
import {
	formatCurrencyWithCents,
	formatPercentage0,
	formatNumber2,
	sum,
} from "../../utils.js";

export const columnDefs = {
	description: {
		label: "Description",
		value: (line) => line.description,
		width: 3,
		align: "left",
	},
	quantity: {
		label: "Quantity",
		value: (line) =>
			(!line.note ? line.quantity : "") +
			(line.percentQuantity ? "%" : ""),
		width: 1,
		align: "right",
	},
	unitPrice: {
		label: "Unit Price",
		value: (line) =>
			!line.note ? "$" + formatCurrencyWithCents(line.unitPrice) : "",
		width: 1,
		align: "right",
	},
	phase: {
		label: "Phase",
		value: (line) => line.phaseName || "",
		width: 3,
		align: "left",
	},
	previouslyBilled: {
		label: "Previously Billed",
		value: (line) =>
			line.phasePreviouslyBilled
				? "$" + formatCurrencyWithCents(line.phasePreviouslyBilled)
				: "",
		width: 1,
		align: "right",
	},
	progress: {
		label: "Progress",
		value: (line) => `${line.phaseProgress}%` || "",
		width: 1,
		align: "left",
	},
	amount: {
		label: "Amount",
		value: (line) =>
			!line.note
				? "$" +
				  formatCurrencyWithCents(Math.round(line.amount * 100) / 100)
				: "",
		width: 1,
		align: "right",
	},
};

class pdfStore {
	@observable invoiceId = null;
	@observable ready = false;
	@observable editingName = false;
	@observable savingProcessTemplates = null;
	@observable deletingProcessTemplates = null;
	@observable savingProcessDefault = null;
	@observable savingProcessProjects = null;
	@observable savingProcessContacts = null;
	@observable dirtyTemplates = new Map();
	@observable savingTemplates = new Map();
	@observable deletedTemplates = new Map();
	@observable deletingTemplates = new Map();
	@observable dirtyDefaultTemplateId = null;
	@observable savingDefaultTemplateId = null;
	@observable dirtyProjects = new Set();
	@observable savingProjects = new Set();
	@observable dirtyClients = new Set();
	@observable savingClients = new Set();
	@observable templateId = null;
	@observable templateSettings = {};
	@observable projectAddress = "";
	@observable clientAddress = "";
	@observable datePreference = "dueDate";
	deleteTimeout = null;
	constructor() {
		makeObservable(this);
	}
	@action.bound
	init(invoiceId) {
		this.invoiceId = invoiceId;
		this.ready = false;
		apiRequest({ url: `/api/v1/org/invoice/${invoiceId}` }).then(
			this.initSuccess
		);
	}
	@action.bound
	initSuccess(data) {
		const inv = Invoice.fromJson(data.invoice);
		organisationStore.setObjectById("Invoice", inv, {
			emitChanged: false,
		});
		organisationStore.organisation.settings.invoice.templates =
			organisationStore.organisation.settings.invoice.templates || {};
		this.changeTemplateId(this.getDefaultTemplateId());
		this.ready = true;
		this.projectAddress = this.project.address || "";
		this.clientAddress = this.client?.address || "";
	}
	@action.bound
	setTemplateDefaults() {
		organisationStore.organisation.settings.invoice.templates =
			organisationStore.organisation.settings.invoice.templates || {};
		organisationStore.organisation.settings.invoice.templates[
			this.templateId
		] = this.invoiceSettings;
	}
	@action.bound
	changeTemplateId(templateId) {
		const temps =
			organisationStore.organisation.settings.invoice.templates || {};
		this.templateId = templateId;
		this.templateSettings = temps[templateId] || {};
		organisationStore.organisation.settings.invoice.templates[templateId] =
			this.invoiceSettings;
	}
	getDefaultTemplateId() {
		const temps =
			organisationStore.organisation.settings.invoice.templates || {};
		const defaultTemplateId =
			organisationStore.organisation.settings.invoice.defaultTemplate;
		return defaultTemplateId || Object.keys(temps)[0] || cuid();
	}
	get templates() {
		const temps =
			organisationStore.organisation.settings.invoice.templates || {};
		return Object.values(temps).length
			? Object.values(temps)
			: [this.invoiceSettings];
	}
	@computed
	get invoice() {
		if (!this.ready) return null;
		return organisationStore.getInvoiceById(this.invoiceId)?.toJS();
	}
	@computed
	get client() {
		if (!this.ready) return null;
		return organisationStore.getContactById(this.invoice.contactId);
	}
	@computed
	get project() {
		if (!this.ready) return null;
		return organisationStore.getProjectById(this.invoice.projectId);
	}
	@computed
	get lineItems() {
		if (!this.invoice) return [];
		let lineItems = [];
		this.invoice.phases.forEach((ph) => {
			lineItems.push(
				...ph.lineItems.map((li) => {
					const percentQuantity = [
						"progress",
						"projectProgress",
					].includes(li.lineItemType);
					return {
						description: li.description,
						quantity: percentQuantity
							? li.phasePercent
							: li.unitQuantity,
						unitPrice: percentQuantity
							? organisationStore.getProjectPhaseById(ph.phaseId)
									.fee || li.unitCost
							: li.unitCost,
						amount: Math.round(li.lineTotalExTax * 100) / 100,
						tax: Math.round(li.lineTotalTax * 100) / 100,
						total: Math.round(li.lineTotalIncTax * 100) / 100,
						percentQuantity: percentQuantity,
						note: li.lineItemType === "note",
						phaseName: ph.phaseId > 0 ? ph.phaseName : "",
						phasePreviouslyBilled: ph.phasePreviousBilled,
						phaseProgress: Math.round(ph.phasePercent),
					};
				})
			);
		});
		return lineItems;
	}
	@computed
	get totals() {
		let totals = {
			subTotal: 0,
			tax: 0,
			totalAmount: 0,
		};
		this.lineItems.forEach((li) => {
			totals.subTotal += Math.round(li.amount * 100) / 100;
			totals.tax += Math.round(li.tax * 100) / 100;
			totals.totalAmount += Math.round(li.total * 100) / 100;
		});
		return totals;
	}
	@computed
	get clientText() {
		if (!this.client) return "";
		let ctext = [];
		if (this.client.firstName && this.client.lastName)
			ctext.push(`${this.client.firstName} ${this.client.lastName}`);
		if (this.client.contactOrganisationName)
			ctext.push(this.client.contactOrganisationName);
		if (this.clientAddress) ctext.push(this.clientAddress);
		return ctext.join("\n");
	}
	@computed
	get projectText() {
		let ctext = [];
		if (this.invoiceSettings.projectCode && this.project.jobCode)
			ctext.push(this.project.jobCode);
		if (this.invoiceSettings.projectName && this.project.name)
			ctext.push(this.project.name);
		if (this.invoiceSettings.projectAddress && this.projectAddress)
			ctext.push(this.project.address);
		return ctext.join("\n");
	}
	@computed
	get invoiceSettings() {
		const defaultSettings = {
			templateName: "Default",
			contentStart: `Current fees based on the estimated construction cost 
determined during the Initial Budget Discussion.

Nominated Percentage of Contract: 12%
Estimated Construction Cost: $1,420,000`,
			contentEnd: `Please deposit funds electronically within 14 days to:
CC Architecture
BSB: XXX-XXX
Account: XXXX XXXX
*Please include reference the invoice number in the payment description.`,
			footerLeft: `CC Architecture Pty Ltd
ABN: XX XXX XXX XXX`,
			footerRight: `123 Threshold Lane, Melbourne, 
VIC Australia 3000
Ph: XX XXXX XXXX
lecorb@ccarch.com.au
`,
			logoUrl:
				"https://server.coincraft.co/static/images/coincraft_logo_130.png",
			logoSize: 6,
			taxName: "GST",
			columns: ["description", "quantity", "unitPrice"],
			projectCode: true,
			projectName: true,
			projectAddress: true,
			openPerProject: false,
			closePerProject: false,
			datePreference: "dueDate",
		};
		const useProjectOpen = this.templateSettings.openPerProject;
		const useProjectCose = this.templateSettings.closePerProject;
		return {
			uuid: this.templateId,
			...defaultSettings,
			...this.templateSettings,
			...{
				contentStart: useProjectOpen
					? this.project?.invoiceOpen
					: this.templateSettings?.contentStart ??
					  defaultSettings.contentStart,
				contentEnd: useProjectCose
					? this.project?.invoiceClose
					: this.templateSettings?.contentEnd ??
					  defaultSettings.contentEnd,
			},
		};
	}
	@computed
	get columns() {
		return [...this.invoiceSettings.columns, "amount"];
	}
	@action.bound
	updateInvoiceSettings(newSettings) {
		const updatedSettings = {
			...this.invoiceSettings,
			...newSettings,
		};

		if (updatedSettings.openPerProject)
			this.project.invoiceOpen = updatedSettings.contentStart;
		if (updatedSettings.closePerProject)
			this.project.invoiceClose = updatedSettings.contentEnd;
		this.dirtyProjects.add(this.project);

		this.setTemplateDefaults();
		organisationStore.organisation.settings.invoice.templates[
			this.templateId
		] = updatedSettings;
		this.templateSettings =
			organisationStore.organisation.settings.invoice.templates[
				this.templateId
			];
		this.dirtyTemplates.set(this.templateId, this.invoiceSettings);
		this.saveTemplates();
		this.saveProjects();
	}
	@action.bound
	updateTemplateName(newName) {
		this.setTemplateDefaults();
		organisationStore.organisation.settings.invoice.templates[
			this.templateId
		].templateName = newName;
		this.templateSettings =
			organisationStore.organisation.settings.invoice.templates[
				this.templateId
			];
	}
	@action.bound
	toggleEditingName(v) {
		this.editingName = v ?? !this.editingName;
		if (this.editingName === false) {
			this.dirtyTemplates.set(this.templateId, this.invoiceSettings);
			this.saveTemplates();
		}
	}
	@action.bound
	setAsDefault() {
		organisationStore.organisation.settings.invoice.defaultTemplate =
			this.templateId;
		this.dirtyDefaultTemplateId = this.templateId;
		this.saveDefaultTemplateId();
	}
	@action.bound
	deleteTemplate() {
		this.setTemplateDefaults();
		delete organisationStore.organisation.settings.invoice.templates[
			this.templateId
		];
		this.deletedTemplates.set(this.templateId, { uuid: this.templateId });
		if (
			organisationStore.organisation.settings.invoice.defaultTemplate ===
			this.templateId
		) {
			organisationStore.organisation.settings.invoice.defaultTemplate =
				null;
		}
		this.changeTemplateId(this.getDefaultTemplateId());
		this.deleteTemplates();
	}
	@action.bound
	createTemplate() {
		this.setTemplateDefaults();
		const uuid = cuid();
		this.templateSettings = {
			...this.templateSettings,
			templateName: "",
			uuid,
		};
		organisationStore.organisation.settings.invoice.templates[uuid] = {
			...this.invoiceSettings,
		};
		this.changeTemplateId(uuid);
		this.toggleEditingName(true);
	}
	@computed
	get saving() {
		const savingItems = [
			this.savingProcessTemplates,
			this.deletingProcessTemplates,
			this.savingProcessDefault,
			this.savingProcessProjects,
			this.savingProcessContacts,
		];
		return savingItems.includes("saving")
			? "saving"
			: savingItems.includes("saved")
			? "saved"
			: null;
	}
	@action.bound
	saveTemplates() {
		if (
			this.dirtyTemplates.size &&
			this.savingProcessTemplates !== "saving"
		) {
			this.savingProcessTemplates = "saving";
			this.savingTemplates = this.dirtyTemplates;
			this.dirtyTemplates = new Map();
			this.request = apiRequest({
				path: `/api/v2/templates`,
				method: "post",
				data: {
					templates: [...this.savingTemplates.values()],
				},
				success: (data) => {
					this.savingTemplates = new Map();
					this.savingProcessTemplates = "saved";
					if (this.dirtyTemplates.size) {
						this.saveTemplates();
					} else {
						setTimeout(() => {
							this.savingProcessTemplates = null;
						}, 500);
					}
				},
			});
		}
	}
	@action.bound
	deleteTemplates() {
		if (
			this.deletedTemplates.size &&
			this.deletingProcessTemplates !== "saving"
		) {
			this.deletingProcessTemplates = "saving";
			this.deletingTemplates = this.deletedTemplates;
			this.deletedTemplates = new Map();
			this.request = apiRequest({
				path: `/api/v2/templates`,
				method: "delete",
				data: {
					templates: [...this.deletingTemplates.values()],
				},
				success: (data) => {
					this.deletingTemplates = new Map();
					this.deletingProcessTemplates = "saved";
					if (this.deletedTemplates.size) {
						this.deleteTemplates();
					} else {
						setTimeout(() => {
							this.deletingProcessTemplates = null;
						}, 500);
					}
				},
			});
		}
	}
	@action.bound
	saveDefaultTemplateId() {
		if (
			this.dirtyDefaultTemplateId &&
			this.savingProcessDefault !== "saving"
		) {
			this.savingProcessDefault = "saving";
			this.savingDefaultTemplateId = this.dirtyDefaultTemplateId;
			this.dirtyDefaultTemplateId = null;
			this.request = apiRequest({
				path: `/api/v2/template/default/${this.templateId}`,
				method: "post",
				success: (data) => {
					this.savingDefaultTemplateId = null;
					this.savingProcessDefault = "saved";
					if (this.dirtyDefaultTemplateId) {
						this.saveDefaultTemplateId();
					} else {
						setTimeout(() => {
							this.savingProcessDefault = null;
						}, 500);
					}
				},
			});
		}
	}
	@action.bound
	updateProjectAddress(val) {
		this.project.address = val;
		this.projectAddress = val;
		this.dirtyProjects.add(this.project);
		this.saveProjects();
	}
	@action.bound
	updateClientAddress(val) {
		this.client.address = val;
		this.clientAddress = val;
		this.dirtyClients.add(this.client);
		this.saveClients();
	}

	@action.bound
	saveProjects() {
		if (
			this.dirtyProjects.size &&
			this.savingProcessProjects !== "saving"
		) {
			this.savingProcessProjects = "saving";
			this.savingProjects = this.dirtyProjects;
			this.dirtyProjects = new Set();
			this.request = apiRequest({
				path: `/api/v2/project-invoice`,
				method: "post",
				data: {
					projects: [...this.savingProjects].map((p) => ({
						id: p.id,
						address: p.address,
						invoiceOpen: p.invoiceOpen,
						invoiceClose: p.invoiceClose,
					})),
				},
				success: (data) => {
					this.savingProjects = new Set();
					this.savingProcessProjects = "saved";
					if (this.dirtyProjects.size) {
						this.saveProjects();
					} else {
						setTimeout(() => {
							this.savingProcessProjects = null;
						}, 500);
					}
				},
			});
		}
	}

	@action.bound
	saveClients() {
		if (this.dirtyClients.size && this.savingProcessContacts !== "saving") {
			this.savingProcessContacts = "saving";
			this.savingClients = this.dirtyClients;
			this.dirtyClients = new Set();
			this.request = apiRequest({
				path: `/api/v2/contact-address`,
				method: "post",
				data: {
					contacts: [...this.savingClients].map((c) => ({
						id: c.id,
						address: c.address,
					})),
				},
				success: (data) => {
					this.savingClients = new Set();
					this.savingProcessContacts = "saved";
					if (this.dirtyClients.size) {
						this.saveClients();
					} else {
						setTimeout(() => {
							this.savingProcessContacts = null;
						}, 500);
					}
				},
			});
		}
	}
}

export default new pdfStore();
