import React from 'react';
import CreateReactClass from 'create-react-class';
import PropTypes from "prop-types";


export var IntercomMessageLink = CreateReactClass({
  propTypes: {
    label: PropTypes.string.isRequired,
    message: PropTypes.string
  },

  render: function() {
    return <a href="javascript:void(0)" onClick={this.openIntercom}>{this.props.label}</a>;
  },

  openIntercom: function() {
    window.window.Intercom("showNewMessage", this.props.message);
  }
});



