import {
	simplifyDuration,
	durationBetween,
	subDurationFromDate,
	subDurations,
	subRates,
	simplifyRate,
} from "@ryki/datemath";

export default ({ left, right }, context) => {
	const subberId = left.type + right.type;
	if (!subberValues[subberId]) {
		throw {
			value: `Cannot subtract a ${right.type} from a ${left.type}.`,
			type: "error",
		};
	}
	return ({
		type: subberTypes[subberId],
		value: subberValues[subberId] && subberValues[subberId](left, right),
	});
};

const subberTypes = {
	numbernumber: "number",
	texttext: "text",
	dateTimeduration: "dateTime",
	dateTimedateTime: "duration",
	durationduration: "duration",
	raterate: "rate",
};

const subberValues = {
	numbernumber: (left, right) => left.value - right.value,
	texttext: (left, right) => left.value.replace(right.value, ""),
	dateTimeduration: (left, right) =>
		subDurationFromDate(left.value, right.value).getTime(),
	dateTimedateTime: (left, right) =>
		simplifyDuration(durationBetween(right.value, left.value)),
	durationduration: (left, right) =>
		simplifyDuration(subDurations(left.value, right.value)),
	raterate: (left, right) => simplifyRate(subRates(left.value, right.value)),
};
