import {
	addDurationToDate,
	simplifyDuration,
	addDurations,
	simplifyRate,
	addRates,
} from "@ryki/datemath";

export default ({ left, right }, context) => {
	const adderId = left.type + right.type;
    if (!adderValues[adderId]) {
		throw {
			value: `Cannot add a ${left.type} to a ${right.type}.`,
			type: "error",
			dependencies: [],
		};
	}
    return {
		type: adderTypes[adderId],
		value: adderValues[adderId](left, right),
	};
};

const adderTypes = {
	numbernumber: "number",
	texttext: "text",
	textnumber: "text",
	numbertext: "text",
	dateTimeduration: "dateTime",
	durationduration: "duration",
	raterate: "rate",
};

const adderValues = {
	numbernumber: (left, right) => left.value + right.value,
	texttext: (left, right) => left.value + right.value,
	textnumber: (left, right) => left.value + right.value,
	numbertext: (left, right) => left.value + right.value,
	dateTimeduration: (left, right) =>
		addDurationToDate(left.value, right.value).getTime(),
	durationduration: (left, right) =>
		simplifyDuration(addDurations(left.value, right.value)),
	raterate: (left, right) =>
		simplifyRate(addRates(left.value, right.value)),
};
