import React from 'react';
import CreateReactClass from 'create-react-class';
import { rootStore } from '../RootStore.js';
import { organisationStore } from '../organisation.js';


export function reportsPageWrapper(store, component) {
  function loadReport(routeParams) {
    if (routeParams.reportUuid == null) {
      store.loadReport(null);
    }
    else {
      const report = organisationStore.getReportByUuid(routeParams.reportUuid);
      if (report != null) {
        store.loadReport(report);
        rootStore.emitChanged();
      }
      else {
        store.toDefaultPage();
      }
    }
  }

  return CreateReactClass({
    componentWillMount: function() {
      loadReport(this.props.routeParams);
    },

    componentWillReceiveProps: function(nextProps) {
      if (nextProps.routeParams.reportUuid !== this.props.routeParams.reportUuid) {
        loadReport(nextProps.routeParams);
      }
    },

    render: function() {
      return React.createElement(component, this.props);
    }
  });
}


