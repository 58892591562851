import React from "react";
import CreateReactClass from "create-react-class";
import moment from "moment";
import _ from "underscore";
import classNames from "classnames";
import { Link } from "react-router";
import { Project, dateConverter } from "../models.js";
import { wrapLoading } from "../reports/wrapLoading.js";
import {
	ProgressBar2,
	LocalisedShortDate,
	CommaSeparatedList,
} from "../widgets.js";
import {
	formatPercentage0,
	compareMultiple,
	formatNumber0,
	formatNumber2,
	isNumber,
} from "../utils.js";
import { organisationStore } from "../organisation.js";
import { projectsPageStore } from "./ProjectsPageStore.js";
import { ProjectStatus } from "../models/project.js";
import { userStore } from "../user/flux.js";
import {
	permissions,
	requiresPermission,
	OperationLevel,
} from "../models/permissions.js";

function progressBar(formatNumber) {
	return function (item, i, stack, data) {
		return (
			<ProgressBar2
				width="100%"
				height="1.5em"
				numerator={data.numerator}
				denominator={data.denominator}
				formatNumber={formatNumber}
			/>
		);
	};
}

function currencyToText(item, i, stack, data) {
	// No dollar sign.
	return formatNumber2(data, { ifNull: "-" });
}

function percentToText(item, i, stack, data) {
	// No dollar sign.
	return isNumber(data) ? Math.round(data) : "-";
}

function dateToText(item, i, stack, data) {
	if (isNumber(data)) {
		return dateConverter.intToString(data);
	} else if (moment.isMoment(data)) {
		return data.format("YYYY-MM-DD");
	} else {
		return "-";
	}
}

function stringToText(item, i, stack, data) {
	return data || "-";
}

function progressToText(item, i, stack, data) {
	return `${Math.round(data.numerator)} / ${Math.round(data.denominator)}`;
}

function getCurrencyFormatter() {
	return organisationStore.organisation.currencyFormatter;
}

export let columnPresentation = {
	project: {
		header: "Project",
		content: (item, i, stack, data) => data.getTitle(),
	},
	name: {
		header: "Name",
		contentText: (item, i, stack, data) => data,
		content: function (item, i, stack, data, rowData, tableData) {
			const ganttIcon =
				item.constructor === Project &&
				permissions
					.projectFinancialsBudgets(item)
					.ok(userStore.user) === "edit" &&
				permissions.projectAdmin(item).ok(userStore.user);
			return (
				<div
					className={classNames(
						"row",
						"new-dashboard__project-list__item__project-row",
						"project-table__row__name",
						`project-id-${item?.id}`
					)}
				>
					{item.constructor === Project ? (
						<div
							style={{
								display: "inline-block",
								width: 42,
							}}
						>
							{ganttIcon && (
								<i
									className={classNames(
										"project-table__row__gantt",
										"fa",
										"fa-tasks"
									)}
									style={{
										cursor: "pointer",
									}}
									onClick={() => {
										window.open(
											`${process.env.REACT_APP_GANTT_URL}/task/import/coincraft/${item.uuid}`
										);
									}}
								/>
							)}
							{stack.length === 0 ? (
								<i
									className={classNames(
										"project-table__row__expander",
										"fa",
										_.include(
											tableData.expandedProjects,
											item
										)
											? "fa-caret-down"
											: "fa-caret-right"
									)}
								/>
							) : null}
						</div>
					) : null}

					<div
						style={{
							display: "inline-block",
							width: stack.length * 35,
						}}
					/>

					<span className="new-dashboard__project-list__item__name">
						{item.constructor === Project ? (
							<Link
								to={`/dashboard/project/${item.id}`}
								className="new-dashboard__project-list__item__link"
								// stopPropagation so clicking the link to open the project page doesn't expand the row,
								// unexpectedly leaving it expanded when the user gets back to the projects table.
								onClick={(event) => {
									event.stopPropagation();
									// projectsPageStore.timesheetDataCache.cancelRequests();
								}}
							>
								{item.getTitle()}
							</Link>
						) : (
							<span>{item.getTitle()}</span>
						)}
					</span>
				</div>
			);
		},
	},
	jobCode: {
		header: "Job code",
		contentText: stringToText,
		content: (item, i, stack, data) => data,
	},
	costCentre: {
		header: "Cost centre",
		content: (item, i, stack, data) => data.name,
	},
	contact: {
		header: "Client",
		content: (item, i, stack, data) =>
			data != null ? data.display() : "-",
	},
	invoiceContact: {
		header: "Primary Contact",
		content: (item, i, stack, data) =>
			data != null ? data.display() : "-",
	},
	owner: {
		header: "Project Owner",
		content: (row, i, stack, staffMember) =>
			staffMember != null ? staffMember.getFullName() : null,
	},
	currentPhases: {
		header: "Current Phases",
		content: (item, i, stack, data) => (data != null ? data : ""),
	},
	activePhases: {
		header: "Active Phases",
		content: (item, i, stack, data) => (data != null ? data : ""),
	},
	prospectivePhases: {
		header: "Prospective Phases",
		content: (item, i, stack, data) => (data != null ? data : ""),
	},
	staffMembers: {
		header: "Staff members assigned",
		content: function (item, i, stack, data) {
			return (
				<CommaSeparatedList
					items={data.sort(
						compareMultiple(
							(a, b) => a.firstName.localeCompare(b.firstName),
							(a, b) => a.lastName.localeCompare(b.lastName)
						)
					)}
					formatter={(sm) => `${sm.firstName} ${sm.lastName}`}
				/>
			);
		},
		contentText: function (item, i, stack, staffMembers) {
			return staffMembers
				.map((sm) => `${sm.firstName} ${sm.lastName}`)
				.join(", ");
		},
	},
	isArchived: {
		header: "Is archived",
		content: (item, i, stack, data) => (data ? "Yes" : "No"),
	},
	status: {
		header: "Status",
		// data != null if item is a project, otherwise item is a phase and
		// statuses aren't applicable.
		content: (item, i, stack, data) =>
			data != null ? ProjectStatus.formatItem(data) : "",
	},
	revenue: {
		header: "Revenue",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	revenueWithVariations: {
		header: "Revenue (variations)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	revenueWithVariationsReimbursements: {
		header: "Revenue (variations + reimbursements)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	revenuePerHour: {
		header: "Revenue per hour",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			isNumber(data)
				? `${getCurrencyFormatter().formatWithCents(data)}`
				: "N/A",
	},
	revenueVariationPerHour: {
		header: "Revenue per hour (variations)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			isNumber(data)
				? `${getCurrencyFormatter().formatWithCents(data)}`
				: "N/A",
	},
	revenueVariationReimbursementPerHour: {
		header: "Revenue per hour (variations + reimbursements)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			isNumber(data)
				? `${getCurrencyFormatter().formatWithCents(data)}`
				: "N/A",
	},
	percentRevenueVsFee: {
		header: "Percent Revenue / Fee",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	percentOfTotalRevenue: {
		header: "Percent of total revenue",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	percentOfTotalHours: {
		header: "Percent of total hours",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	percentOfTotalExpenses: {
		header: "Percent of total expenses",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	percentOfTotalExpensesLabour: {
		header: "Percent of total expenses",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	expenses: {
		header: "Expenses (cost)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	expensesVariation: {
		header: "Expenses (cost + variation)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	expensesVariationProject: {
		header: "Expenses (cost + variation + project)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	expensesProject: {
		header: "Expenses (cost + project)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectExpenses: {
		header: "Project Expenses",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	billableProjectExpenses: {
		header: "Billable Project Expenses",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	allProjectExpenses: {
		header: "All Project Expenses",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	labourExpense: {
		header: "Expenses (labour)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	chargeOut: {
		header: "Charge-out",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	chargeOutVariation: {
		header: "Charge-out (Variations)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	hoursAllocated: {
		header: "Allocated hours",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	hoursBudgeted: {
		header: "Budgeted hours",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	profit: {
		header: "Profit (cost + project expenses)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitCost: {
		header: "Profit (cost)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitCostVariationProject: {
		header: "Profit (cost + variation + project expenses)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitMarginVariationProject: {
		header: "Profit (cost + variation + project expenses)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	markupVariationProject: {
		header: "Markup (cost + variation + project expenses)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	expenseVsRevenueVariationProject: {
		header: "Expense / Revenue (cost + variation + project expenses)",
		contentText: currencyToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	revenueVsExpenseVariationProject: {
		header: "Revenue / Expense (cost + variation + project expenses)",
		contentText: currencyToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	profitVsRevenueVariationProject: {
		header: "Profit / Revenue (cost + variation + project expenses)",
		contentText: currencyToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	profitVsExpenseVariationProject: {
		header: "Profit / Expense (cost + variation + project expenses)",
		contentText: currencyToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	profitLabour: {
		header: "Profit (Labour)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitFee: {
		header: "Profit (Fee)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitFeeVariation: {
		header: "Profit (Fee, Variation, Project Expenses)",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	profitMargin: {
		header: "Profit margin (cost + project expenses)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	profitMarginCost: {
		header: "Profit margin (cost)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	profitMarginLabour: {
		header: "Profit margin (Labour)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	profitMarginFee: {
		header: "Profit margin (Fee)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	profitMarginFeeVariation: {
		header: "Profit margin (Fee, Variation, Project Expenses)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	markup: {
		header: "Markup (cost + project expenses)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	markupCost: {
		header: "Markup (cost)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	markupLabour: {
		header: "Markup (Labour)",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	netMultiplier: {
		header: "Net Multiplier",
		content: (item, i, stack, data) =>
			formatNumber2(data, { ifNull: "N/A" }),
	},
	completionDate: {
		header: "Completion date",
		contentText: dateToText,
		content: (item, i, stack, data) => (
			<LocalisedShortDate date={data} ifNull="N/A" />
		),
	},
	progress: {
		header: "Progress",
		contentText: percentToText,
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
	recordedHours: {
		header: "Recorded Hours",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	recordedHoursVariation: {
		header: "Recorded Hours (Variation)",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	latestRevenueDate: {
		header: "Latest Revenue Date",
		contentText: dateToText,
		content: (item, i, stack, data) => (
			<LocalisedShortDate date={data} ifNull="N/A" />
		),
	},
	latestInvoicedPhases: {
		header: "Latest Invoiced Phases",
		content: (item, i, stack, data) =>
			data != null ? data.map((d) => d.name).join(", ") : "",
	},
	startDate: {
		header: "Start date",
		contentText: dateToText,
		content: (item, i, stack, data) => (
			<LocalisedShortDate date={data} ifNull="N/A" />
		),
	},
	endDate: {
		header: "End date",
		contentText: dateToText,
		content: (item, i, stack, data) => (
			<LocalisedShortDate date={data} ifNull="N/A" />
		),
	},
	latestEvent: {
		header: "Latest Event",
		contentText: dateToText,
		content: (item, i, stack, data) => (
			<LocalisedShortDate date={data} ifNull="N/A" />
		),
	},
	latestNote: {
		header: "Latest Note",
		content: (item, i, stack, data) => {
			return data || "-";
		},
	},
	expenseBudget: {
		header: "Expense Budget",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	fee: {
		header: "Fee",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	remainingFee: {
		header: "Remaining fee",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	remainingExpenseProjectBudget: {
		header: "Remaining Expense Budget",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	chargeOutVsFee: {
		header: "Charge Out / Fee",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	chargeOutVariationVsFee: {
		header: "Charge Out / Fee (Variations)",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	costVsFee: {
		header: "Expense (cost) / Fee",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	costProjectVsFee: {
		header: "Expense (cost + project) / Fee",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	payVsFee: {
		header: "Expense (labour) / Fee",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	revenueVsFee: {
		header: "Revenue / Fee",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	costVsChargeOut: {
		header: "Expense (cost) / Charge Out",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	costProjectVsChargeOut: {
		header: "Expense (cost + project) / Charge Out",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	payVsChargeOut: {
		header: "Expense (labour) / Charge Out",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	revenueVsChargeOut: {
		header: "Revenue / Charge Out",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	actualVsBudgetedExpenses: {
		header: "Actual (cost) / Budgeted Expenses",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	actualVariationVsBudgetedExpenses: {
		header: "Actual (cost + variation) / Budgeted Expenses",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	actualVariationProjectVsBudgetedExpenses: {
		header: "Actual (cost + variation + project) / Budgeted Expenses",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	actualProjectVsBudgetedExpenses: {
		header: "Actual (cost + project) / Budgeted Expenses",
		contentText: progressToText,
		content: progressBar((n) => `${getCurrencyFormatter().format(n)}`),
	},
	actualVsBudgetedHours: {
		header: "Actual / Budgeted Hours",
		contentText: progressToText,
		content: progressBar((n) => formatNumber0(n)),
	},
	actualVsBudgetedHoursVariation: {
		header: "Actual (Variation) / Budgeted Hours",
		contentText: progressToText,
		content: progressBar((n) => formatNumber0(n)),
	},
	projectedHours: {
		header: "Projected hours",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	futureHours: {
		header: "Future hours",
		content: (item, i, stack, data) => formatNumber0(data),
	},
	futureRevenue: {
		header: "Future revenue",
		contentText: currencyToText,
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectedExpense: {
		header: "Projected expense",
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectedLabourExpense: {
		header: "Projected labour expense",
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectedChargeOut: {
		header: "Projected charge out",
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectedProfit: {
		header: "Projected profit",
		content: (item, i, stack, data) =>
			`${getCurrencyFormatter().format(data)}`,
	},
	projectedProfitMargin: {
		header: "Projected profit margin",
		content: (item, i, stack, data) =>
			formatPercentage0(data, { ifNull: "N/A" }),
	},
};

for (let column of projectsPageStore.reportStore.columns) {
	let cp = columnPresentation[column.identifier];

	if (_.include(cp.requires, ["timesheetCache"])) {
		cp.content = wrapLoading(cp.content);
	}
}
