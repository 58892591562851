import React from 'react';
import CreateReactClass from 'create-react-class';
import { compareObjectsWithNames } from '../utils.js';
import PropTypes from "prop-types";

export const SaveTimer = class {
			constructor(callback) {
				this.callback = callback;
				this.timer = null;
			}

			start(callback) {
				var self = this;
				if (this.timer == null) {
					this.timer = setTimeout(function() {
						self.callback();
						self.timer = null;
					}, 3000);
				}
			}

			clear() {
				if (this.timer != null) {
					clearTimeout(this.timer);
					this.timer = null;
				}
			}
		};


export var TimesheetNewEntryButton = CreateReactClass({
  propTypes: {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      disabled: false
    };
  },

  render: function() {
    // CSS pointer-events: auto; allows the tooltip to work even though the
    // button is disabled.
    return (
      <button
          onClick={this.props.onClick}
          className="timesheet__add_new_time_button btn btn-lg btn-primary"
          disabled={this.props.disabled}
          style={{pointerEvents: 'auto'}}
          title={this.props.disabled ? "Please select a project before you add more entries." : null}>
        <span><i className="fa fa-plus"></i></span>
        {' '}
        <small>Add new entry</small>
      </button>
    );
  }
});


export var SaveStatus = CreateReactClass({
  propTypes: {
    autosave: PropTypes.bool,
    status: PropTypes.string
  },

  render: function() {
    const errorStyle = {
      background: '#FF491A',
    };

    switch (this.props.status) {
      case 'error':
        return <div className="save-bar" style={errorStyle}>
          <strong>
            Looks like you've made a mistake entering your timesheets - 3 hours
            and 15 minutes should be entered as '3:15'.
            <br />
            If you really insist on doing it your way, we might be able to make
            some accommodations - just let us know :)
          </strong>
        </div>;

      case 'saveFailed':
        return <div className="save-bar" style={errorStyle}>
          <strong>
            It looks we've lost connection with the Coincraft server. Maybe try
            turning your internet off and on again? - or refreshing the page?
            <br />
            If the problem persists, get in touch.
          </strong>
        </div>;

      case 'missingTask':
        return <div className="save-bar" style={errorStyle}>
          <strong>
            Could not find task - Please make sure no entries a recorded against deleted tasks.
            <br />
            If the problem persists, get in touch.
          </strong>
        </div>;

      case 'saving':
        if (this.props.autosave) {
          return <div className="save-bar">
            <strong>
              <i className='fa fa-spinner fa-pulse fa-fw' />
              {' '}
              Saving your timesheets...
            </strong>
          </div>;
        }
        else {
          return <div className="save-bar">
            <strong>
              You have made changes to your timesheets that aren't saved yet.
            </strong>
          </div>;
        }

      case 'saved':
        if (this.props.autosave) {
          return (
            <div className="save-bar timesheet-widget__saved-message">
              <strong>
                <i className="fa fa-check" /> All saved - nice work today!
              </strong>
            </div>
          );
        }
        else {
          return null;
        }

      default:
        return null;
    }
  }
});


export function getCostCentreGroupName(group) {
  /**
   * `group` is an element of a list returned by `groupTimesheetEntriesByCostCentre`.
   */
  if (group.grouper == null) {
    return "(No cost centre)";
  }
  if (group.grouper == 'new') {
    return "New entries";
  }
  return group.grouper.name;
}


export function getTimesheetApiUrl() {
  return `/api/v1/user/timesheet`;
};


export function compareTimesheetEntries(a, b) {
  /**
   * Use this to sort time sheet entries by project and then project phase.
   */
  var c = compareObjectsWithNames(a.project, b.project);
  if (c !== 0) {
    return c;
  }
  return compareObjectsWithNames(a.projectPhase, b.projectPhase);
}
