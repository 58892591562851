import moment from 'moment';
import _ from 'underscore';
import { StoreBase, addActions, dispatcher } from "./coincraftFlux.js";
import { organisationStore } from './organisation.js';
import { userStore } from './user.js';
import { router } from './router.js';
import { Equation, step, multiply, divide, add, subtract } from './equation.js';
import { jsonHttp2 } from './jsonHttp.js';
import Immutable from 'immutable';
import apiRequest from './apiRequest.js';


const maxZero = {
  evaluate: ([a, b]) => Math.max(a - b, 0),
  display: ([a, b]) => `max(${a} - ${b}, 0)`
};

const effectiveMonthlyPrice = {
  evaluate: ([baseMonthlyPrice, monthlyDiscountPercent]) => baseMonthlyPrice * (100 - monthlyDiscountPercent) / 100,
  display: ([baseMonthlyPrice, monthlyDiscountPercent]) => `${baseMonthlyPrice} * (100 - ${monthlyDiscountPercent}) / 100`
};

const effectiveYearlyPrice = {
  evaluate: ([baseYearlyPrice, yearlyDiscountPercent]) => baseYearlyPrice * 12 * (100 - yearlyDiscountPercent) / 100,
  display: ([baseYearlyPrice, yearlyDiscountPercent]) => `${baseYearlyPrice} * 12 * (100 - ${yearlyDiscountPercent}) / 100`
};



const pricingEquation = new Equation([
  step('effectiveNumAdmins', maxZero, ['numAdminUsers', 'numFreeAdmins']),
  step('adminUsersPricePerMonth', multiply, ['effectiveNumAdmins', 'adminPricePerMonth']),
  step('effectiveNumStandardUsers', maxZero, ['numStandardUsers', 'numFreeStandardUsers']),
  step('standardUsersPricePerMonth', multiply, ['effectiveNumStandardUsers', 'standardUserPricePerMonth']),
  step('baseMonthlyPrice', add, ['adminUsersPricePerMonth', 'standardUsersPricePerMonth']),
  step('baseYearlyPrice', multiply, ['baseMonthlyPrice', 12]),
  step('effectiveMonthlyPrice', effectiveMonthlyPrice, ['baseMonthlyPrice', 'monthlyDiscountPercent']),
  step('effectiveMonthlyPricePerYear', multiply, ['effectiveMonthlyPrice', 12]),
  step('effectiveYearlyPrice', effectiveYearlyPrice, ['baseMonthlyPrice', 'yearlyDiscountPercent']),
  step('effectiveYearlyPricePerMonth', divide, ['effectiveYearlyPrice', 12]),
  step('yearlyDiscount', subtract, ['baseYearlyPrice', 'effectiveYearlyPrice']),
]);


/**
{
    <has_discount:bool>: {
        <num_months>: <discount_percent>
    }
}

Make sure this matches pricing.js.
*/
export const defaultDiscountPercents = Immutable.Map([
  [false, {
    1: 0,
    12: 15
  }],
  [true, {
    1: 10,
    12: 20
  }]
]);


class PricingStore extends StoreBase {
  constructor() {
    super();
    this.numAdminUsers = 1;
    this.numStandardUsers = 0;

    this.state = null; // `null`, 'waitingForStripe', 'processing', 'success', 'error'
    this.buyingInProgress = false; // `false`, 'monthly', 'yearly'

    this.currency = null;
    this.standardUserPricePerMonth = null;
    this.adminPricePerMonth = null;

    this.monthlyDiscountPercent = null;
    this.yearlyDiscountPercent = null;

    this.isReady = false;
    this.isOpen = false;
  }

  initialize() {
    let user = userStore.getUser();
    if (user.country === 'au' || user.country === 'nz') {
      this.currency = 'AUD';
      this.standardUserPricePerMonth = 20;
      this.adminPricePerMonth = 45;
    }
    else {
      this.currency = 'USD';
      this.standardUserPricePerMonth = 15;
      this.adminPricePerMonth = 35;
    }

    const organisation = organisationStore.organisation;

    this.trialEndsOn = organisation.getTrialEndDate();
    this.discountCutoffDate = organisation.getDiscountCutoffDate();
    this.hasDiscount = !this.discountCutoffDate.isBefore(moment().startOf('day'));
    const discounts = defaultDiscountPercents.get(this.hasDiscount);

    this.monthlyDiscountPercent = (this.hasDiscount && organisation.monthlyDiscountOverridePercent != null) ?
      organisation.monthlyDiscountOverridePercent
    : discounts[1];
    this.yearlyDiscountPercent = (this.hasDiscount && organisation.yearlyDiscountOverridePercent != null) ?
      organisation.yearlyDiscountOverridePercent
    : discounts[12];

    const unarchivedStaff = organisationStore.getVisibleStaff();
    this.numAdminUsers = unarchivedStaff.filter(sm => sm.isAdmin()).length;
    this.numStandardUsers = unarchivedStaff.length - this.numAdminUsers;

    this.buyingInProgress = false;

    this.isReady = true;
    this.emitChanged();
  }

  _getPriceContext() {
    return {
      numAdminUsers: this.numAdminUsers,
      adminPricePerMonth: this.adminPricePerMonth,
      numStandardUsers: this.numStandardUsers,
      standardUserPricePerMonth: this.standardUserPricePerMonth,
      monthlyDiscountPercent: this.monthlyDiscountPercent,
      yearlyDiscountPercent: this.yearlyDiscountPercent
    };
  }

  calculatePrice() {
    return pricingEquation.evaluate(this._getPriceContext());
  }

  displayPrice() {
    return pricingEquation.display(this._getPriceContext());
  }

  open() {
    this.isOpen = true;
    this.emitChanged();
  }

  close() {
    this.isOpen = false;
    this.emitChanged();

    if (window.location.pathname === "/trial-expired") {
      router.history.replace({pathname: '/dashboard'});
    }
  }

  setNumAdmins(numAdminUsers) {
    this.numAdminUsers = parseInt(numAdminUsers);
    this.emitChanged();
  }

  setNumStandardUsers(numStandardUsers) {
    this.numStandardUsers = parseInt(numStandardUsers);
    this.emitChanged();
  }

  buySubscription(type) {
    if (type !== 'monthly' && type !== 'yearly') {
      throw new Error("invalid subscription type");
    }

    let self = this;
    let dollars = null;
    let numMonths = null;
    const pricing = this.calculatePrice();
    if (type === 'monthly') {
      dollars = pricing.effectiveMonthlyPrice;
      numMonths = 1;
    }
    else {
      dollars = pricing.effectiveYearlyPrice;
      numMonths = 12;
    }
    apiRequest({
		  url: `/validate-payment`,
      method: "post",
      data: {
        numMonths: numMonths,
        numAdminUsers: this.numAdminUsers,
        numStandardUsers: this.numStandardUsers,
        proposedAmount: dollars
      },
      success: data => {
        let tokenFired = false;
        let handler = window.StripeCheckout.configure({
          key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          token: function(token) {
            try {
              tokenFired = true;
              self.state = "processing";
              self.emitChanged();
              organisationStore
                .addCustomer({
                  stripeToken: token.id,
                  numMonths: numMonths
                })
                .then(
                  function(data) {
                    actions.paySuccess();
                  },
                  function(error) {
                    console.error(error);
                    actions.payFailure(error);
                  }
                );
            } catch (e) {
              console.error(e);
              actions.payFailure();
            }
          },
          closed: function() {
            if (!tokenFired) {
              actions.payCancel();
            }
          }
        });

        self.state = "waitingForStripe";
        self.buyingInProgress = type;
        self.emitChanged();

        handler.open({
          name: "Coincraft subscription",
          description: self.getStripeTransactionDescription(type),
          amount: dollars * 100,
          email: userStore.getUser().email
        });
      },
      error: data => actions.payFailure()
    });
  }

  getStripeTransactionDescription(type) {
    const numFreeAdmins = 0;
    const numFreeStandard = 0;
    return [
      type + ',',
      this.numAdminUsers,
      'admin',
      '+',
      this.numStandardUsers,
      'standard'
    ].filter(s => s != null).join(' ');
  }

  paySuccess() {
    this.state = 'success';
    this.buyingInProgress = false;
    this.emitChanged();
  }

  payFailure() {
    this.state = 'error';
    this.buyingInProgress = false;
    this.emitChanged();
  }

  payCancel() {
    this.state = null;
    this.buyingInProgress = false;
    this.emitChanged();
  }
}


export var store = new PricingStore();


export var actions = {};
addActions(actions, 'PRICING_', [
  {name: 'open', args: []},
  {name: 'setNumAdmins', args: ['numAdminUsers']},
  {name: 'setNumStandardUsers', args: ['numStandardUsers']},
  {name: 'buyMonthlySubscription', args: []},
  {name: 'buyYearlySubscription', args: []},
  {name: 'paySuccess', args: []},
  {name: 'payFailure', args: []},
  {name: 'payCancel', args: []},
  {name: 'close', args: []},
  {name: 'finish', args: []},
]);


store.dispatchToken = dispatcher.register(function(action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
    case 'SAVE_USER_DETAILS_SUCCESS':
      dispatcher.waitFor([organisationStore.dispatchToken]);
      break;
    case 'PRICING_OPEN':
      store.open();
      break;
    case 'PRICING_SET_NUM_ADMINS':
      store.setNumAdmins(action.numAdminUsers);
      break;
    case 'PRICING_SET_NUM_STANDARD_USERS':
      store.setNumStandardUsers(action.numStandardUsers);
      break;
    case 'PRICING_BUY_MONTHLY_SUBSCRIPTION':
      store.buySubscription('monthly');
      break;
    case 'PRICING_BUY_YEARLY_SUBSCRIPTION':
      store.buySubscription('yearly');
      break;
    case 'PRICING_PAY_SUCCESS':
      store.paySuccess();
      break;
    case 'PRICING_PAY_FAILURE':
      store.payFailure();
      break;
    case 'PRICING_PAY_CANCEL':
      store.payCancel();
      break;
    case 'PRICING_CLOSE':
      store.close();
      break;
    case 'PRICING_FINISH':
      store.close();
      break;
  }
});


