import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { Modal, ModalContent } from '../../../modal.js';
import { SaveButton } from '../../../widgets.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PropTypes from "prop-types";


export var AddExpenseModal = CreateReactClass({
    propTypes: {
        onSubmit: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired
    },

    mixins: [
        PureRenderMixin
    ],

    getInitialState: function () {
        return {
            name: this.props.initialName || "",
            isLoaded: false
        };
    },

    render: function () {
        let self = this;

        return (
            <Modal
                className="add-expense-modal"
                closeOnClickOutside={true}
                onClosed={() => this.props.onClose()}>
                <ModalContent header={"Add Expense"} width='45em'>
                    <div>
                        <div style={{ padding: '2em' }}>
                            <div>
                                <label>
                                    Expense Name:
                                </label>
                                <input
                                    type="text"
                                    value={this.state.name}
                                    onChange={(event) => this.setState({ name: event.target.value })}
                                    onKeyUp={function (event) {
                                        if (event.key === 'Enter') {
                                            self.props.onSubmit(self.state.name);
                                        }
                                    }}
                                    ref={function (textInput) {
                                        if (!self.state.isLoaded) {
                                            setTimeout(function () {
                                                if (textInput != null) {
                                                    textInput.focus();
                                                    textInput.select();
                                                    self.setState({ isLoaded: true });
                                                }
                                            }, 0);
                                        }
                                    }}
                                    style={{ width: '20em' }}
                                />
                            </div>
                        </div>
                        <div style={{ padding: '1em', textAlign: 'right', borderTop: 'solid 1px #ccc' }}>
                            <a
                                href="javascript:void(0)"
                                onClick={() => this.props.onClose()}>
                                Cancel
              </a>
                            <SaveButton
                                text={"Add Expense"}
                                onClick={() => this.props.onSubmit(self.state.name)}
                                style={{ marginLeft: '2em' }}
                            />
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        );
    }
});
