import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import Textarea from 'react-textarea-autosize';
import { formatMinutes, isNumber, sum, formatHours0, formatPercentage0 } from "../utils.js";
import { timesheetActions } from './flux.js';
import { ProgressBar2, HoursInput, CurrencyIcon, LoadingSpinner } from '../widgets.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { userStore } from '../user.js';
import { dateConverter } from '../models.js';
import PropTypes from "prop-types";

export var TimesheetNav = CreateReactClass({
  propTypes: {
    mode: PropTypes.oneOf(['daily', 'weekly']).isRequired,
    isDisabled: PropTypes.bool,
    monday: PropTypes.object.isRequired,
    selectedDate: PropTypes.object, // Only relevant for mode='daily'
    dayTotals: PropTypes.array.isRequired
  },

  getDefaultProps: function() {
    return {
      isDisabled: false
    };
  },

  render: function(){
    let self = this;

    let weekTotal = _.all(this.props.dayTotals, t => isNumber(t)) ? sum(this.props.dayTotals) : null;

    function formatTotal(n, type) {
      return isNumber(n) ?
        formatMinutes(n)
      : n === 'loading' ?
        <i
          className="fa fa-spinner fa-spin"
          style={{margin: 0}}
        />
      :
        <i
          className="fa fa-exclamation-triangle error-highlight-color"
          style={{margin: 0}}
          title={`Some entries for this ${type} have errors.`}
        />;
    }

    return (
      <div>
        <div style={{borderBottom: 'solid 1px #aaa'}}>
          <div>
            <div className="timesheet-widget__row" style={{fontSize: '1em'}}>
              <div className="timesheet-widget__row__inner">
                <div
                    className="flexbox-container flex-align-items-center"
                    // to align buttons
                    style={{marginTop: self.props.mode === 'daily' ? '-2.4em' : '-0.6em'}}>
                  <div className="timesheet-widget__left-section">
                    <div className="timesheets__navigation flexbox-container" style={{flex: '0 0 auto'}}>
                      <div className="flex-1-0-auto" style={{textAlign: 'center'}}>
                        <button
                            onClick={this.handleTodayClick}
                            className="btn btn-default"
                            disabled={this.props.isDisabled}
                            style={{width: '100%'}}>
                          {this.props.mode === 'daily' ? 'Today' : 'This Week'}
                        </button>
                        {this.props.mode === 'weekly' ?
                          <div style={{fontWeight: 600, marginTop: '0.5em'}}>
                            Weekly Total: {formatTotal(weekTotal, 'week')}
                          </div>
                        : null}
                      </div>
                      <div className="flex-0-0-auto">
                        <button
                            onClick={this.handlePreviousWeekClick}
                            className="btn btn-default previous-week-button"
                            style={{marginLeft: '1em', marginTop: self.props.mode === 'weekly' ? '-2em' : 'inherit'}}
                            disabled={this.props.isDisabled}
                            title="Previous week">
                          <i className="fa fa-chevron-left" style={{margin: 0}} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="timesheet-widget__middle-section"
                    style={{
                      flex: '0 0 auto',
                      paddingTop: self.props.mode === 'daily' ? '1.4em' : 'inherit' /* to align buttons */
                    }}
                  >
                    <div className="timesheets__day-nav flexbox-container flex-space-around">
                      {this.props.dayTotals.map(function(dayTotal, dayIndex) {
                        let d1 = self.props.monday.clone().add(dayIndex, 'days');

                        let inner = <div className="timesheet__nav__day__date">
                          <div style={{fontSize: '0.8em'}}>
                            {d1.format("ddd")}
                          </div>
                          <div style={{fontSize: '1.5em', margin: '-0.1em 0 0 0'}}>
                            {d1.format("DD")}
                          </div>
                        </div>;

                        return (
                          <div
                              key={dayIndex}
                              className="flex-0-0-auto timesheet__nav__day"
                              data-date={d1.format("YYYY-MM-DD")}
                              style={{fontSize: '1.1em', textAlign: 'center'}}>
                            {self.props.mode === 'daily' ?
                              <button
                                  className={classNames("btn btn-default timesheets__day-nav-button", {
                                    "selected": d1.isSame(self.props.selectedDate)
                                  })}
                                  onClick={() => self.handleDateClick(d1)}
                                  disabled={self.props.isDisabled}>
                                {inner}
                              </button>
                            :
                              inner
                            }
                            <div
                                className="timesheet-widget__hours-cell timesheet__nav__total"
                                style={{fontSize: '0.9em', fontWeight: 600, marginTop: '0.2em'}}>
                              {formatTotal(dayTotal, 'day')}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="timesheet-widget__right-section" style={{flex: '0 0 auto'}}>
                    <button
                        onClick={this.handleNextWeekClick}
                        className="btn btn-default next-week-button"
                        title="Next week"
                        disabled={this.props.isDisabled}
                        style={{marginTop: self.props.mode === 'weekly' ? '-2em' : 'inherit'}}>
                      <i className="fa fa-chevron-right" style={{margin: 0}} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },

  handleTodayClick: function() {
    timesheetActions.today();
  },

  handlePreviousWeekClick: function() {
    timesheetActions.previousWeek();
  },

  handleNextWeekClick: function() {
    timesheetActions.nextWeek();
  },

  handleDateClick: function(d) {
    timesheetActions.loadDay(d);
  }
});


export var PhaseBlock = CreateReactClass({
  propTypes: {
    project: PropTypes.object,
    phase: PropTypes.object,
    costCentreGroupIndex: PropTypes.number.isRequired,
    projectPhaseGroup: PropTypes.object.isRequired,
    projectPhaseGroupIndex: PropTypes.number.isRequired,
    staffMember: PropTypes.object.isRequired,
    rows: PropTypes.object.isRequired, // Immutable.List
    mode: PropTypes.string,
    dailyDate: PropTypes.object, // moment
    budgetState: PropTypes.string, 
  },

  mixins: [PureRenderMixin],

  render: function() {
    let self = this;

    if (!this.props.rows.find(r => !r.get('isDeleted'))) {
      return null;
    }

    let phaseNameCode = (this.props.phase != null) ? this.props.phase.getTitle() : "(No phase)";

    return (
      <div
          className="phase-block timesheet-widget__row"
          data-phaseId={this.props.phase != null ? this.props.phase.id : -1}
          data-projectId={this.props.project != null ? this.props.project.id : -1}>
        <div className="timesheet-widget__phase-block timesheet-widget__row__inner">
          <div className="timesheet-widget__phase-row">
            <div className="timesheet-widget__phase-title timesheet-widget__left-section">
              <div className="inline-flexbox-container flex-align-items-center" style={{width: '100%', paddingBottom: '0.1em'}}>
                <button
                    className="btn btn-default timesheet-widget__add-task flex-0-0-auto"
                    onClick={this.handleAddButtonClick}
                    style={{
                      padding: '0.1em 0.4em',
                      margin: '0em 1em 0 0.5em',
                      fontSize: '0.6em'
                    }}>
                  <i className="fa fa-plus" style={{margin: 0}} />
                </button>
                <div className="phase-block__title flex-1-1-auto" style={{minWidth: 0}}>
                  <div
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: '0.9em'
                      }}>
                    {phaseNameCode}
                  </div>
                </div>
              </div>
            </div>
            <div
                className="timesheet-widget__progress-bar timesheet-widget__middle-section"
                style={{verticalAlign: 'bottom'}}>
              {this.props.phase != null ?
                <PhaseProgressBar
                  projectPhaseGroup={this.props.projectPhaseGroup}
                  phase={this.props.phase}
                  staffMember={this.props.staffMember}
                  monthIndex={this.props.rows.getIn([0, 'entries', 0]).monthIndex}
                  loading={this.props.budgetState !== "loaded"}
                />
              : null}
            </div>
          </div>
          <div className="timesheet-widget__tasks-block">
            {this.props.rows.map(function(row, rowIndex) {
              if (!row.get('isDeleted')) {
                return <TaskRow
                  key={rowIndex}
                  costCentreGroupIndex={self.props.costCentreGroupIndex}
                  projectPhaseGroupIndex={self.props.projectPhaseGroupIndex}
                  rowIndex={rowIndex}
                  row={row}
                  mode={self.props.mode}
                  highlightedRowPath={self.props.highlightedRowPath}
                  dailyDate={self.props.dailyDate}
                />;
              }
              else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    );
  },

  handleAddButtonClick: function() {
    timesheetActions.addRow(this.props.project, this.props.phase);
  }
});


export var TaskRow = CreateReactClass({
  propTypes: {
    costCentreGroupIndex: PropTypes.number.isRequired,
    projectPhaseGroupIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['weekly', 'daily']).isRequired,
    dailyDate: PropTypes.object // moment
  },

  mixins: [PureRenderMixin],

  componentDidMount: function() {
    /**
     * A `TaskRow` is highlighted if the user just created it, moved it, or changed its task.
     *
     * If they just created it or moved it, then that will result in a new component
     * being created and we want to focus it to show the user where it is.
     *
     * If they just changed its task, then they must have already used the edit button
     * on the actual component, so they know where it is, so there's no point focusing
     * anything.
     *
     * So putting this logic in `componentDidMount` follows the logic we want.
     */
    if (this.isHighlighted() && this.refs.firstEntry != null) {
      this.refs.firstEntry.focus();
    }
  },

  render: function() {
    let self = this;

    let entryParams = {
      costCentreGroupIndex: self.props.costCentreGroupIndex,
      projectPhaseGroupIndex: self.props.projectPhaseGroupIndex,
      rowIndex: self.props.rowIndex,
      row: self.props.row,
    };

    let isHighlighted = this.isHighlighted();
    const hasData = this.props.row
		.get("entries")
		.toJS()
		.filter(te => te.notes || te.numMinutes).length > 0;

    let entryIndex, entry;
    if (this.props.mode === 'daily') {
      entryIndex = this.props.row.get('entries').findIndex(e => e.date.isSame(self.props.dailyDate));
      entry = this.props.row.get('entries').get(entryIndex);

      if (entry.isDeleted) {
        return null;
      }
    }

    return (
		<div
			className={classNames("timesheet-widget__task-row", {
				"timesheet-widget__task-row--highlighted": isHighlighted
			})}
		>
			<div className="timesheet-widget__task-title timesheet-widget__left-section">
				{!this.props.row.get("isLocked") || !hasData ? (
					<button
						className="timesheet-widget__task-delete delete-button"
						onClick={this.handleDeleteButtonClick}
					>
						<i className="fa fa-times fa-fw" />
					</button>
				) : null}
				{!this.props.row.get("isLocked") ? (
					<button
						className="timesheet-widget__task-edit edit-button"
						onClick={this.handleEditButtonClick}
					>
						<i className="fa fa-pencil-square-o fa-fw" />
					</button>
				) : null}
				{userStore.isAdmin() && !this.props.row.get("isLocked") ? (
					<button
						className="timesheet-widget__task-lock lock-button"
						onClick={this.handleLockButtonClick}
					>
						<i className="fa fa-lock fa-fw" />
					</button>
				) : userStore.isAdmin() && this.props.row.get("isLocked") ? (
					<button
						className="timesheet-widget__task-lock lock-button"
						onClick={this.handleUnlockButtonClick}
					>
						<i className="fa fa-unlock fa-fw" />
					</button>
				) : null}
				<div>
					<span style={{ marginLeft: "1em" }}>
						{!this.props.row.get("isBillable") ? (
							<div
								style={{ display: "inline-block" }}
								className="time-entry__time-options timesheet-widget__task-row__is-not-billable"
							>
								<span
									className="fa-stack"
									style={{
										fontSize: "0.7em",
										marginRight: "0.4em"
									}}
								>
									<CurrencyIcon className="fa-stack-1x" />
									<i className="fa fa-ban fa-stack-2x" />
								</span>
								<div className="time-entry__time-options-hover">
									Non-Billable Time
								</div>
							</div>
						) : null}
						{this.props.row.get("isOvertime") ? (
							<div
								style={{ display: "inline-block" }}
								className="time-entry__time-options timesheet-widget__task-row__is-overtime"
							>
								<i
									className="fa fa-clock-o"
									style={{
										fontSize: "1.3em",
										position: "relative",
										top: "0.15em"
									}}
								/>
								<div className="time-entry__time-options-hover">
									Overtime
								</div>
							</div>
						) : null}
						{this.props.row.get("isVariation") ? (
							<div
								style={{ display: "inline-block" }}
								className="time-entry__time-options timesheet-widget__task-row__is-variation"
							>
								<i
									className="fa fa-code-fork"
									style={{
										fontSize: "1.3em",
										position: "relative",
										top: "0.15em"
									}}
								/>
								<div className="time-entry__time-options-hover">
									Variation
								</div>
							</div>
						) : null}
						{this.props.row.get("task") != null
							? this.props.row.get("task").name
							: "(No task)"}
					</span>
				</div>
			</div>
			{this.props.mode === "weekly" ? (
				<div className="timesheet-widget__task-entries timesheet-widget__middle-section">
					<div className="flexbox-container flex-space-around">
						{this.props.row
							.get("entries")
							.map(function(entry, entryIndex) {
								return (
									<WeeklyTimeEntry
										ref={
											entryIndex === 0
												? "firstEntry"
												: null
										}
										key={entryIndex}
										{...entryParams}
										entry={entry}
										entryIndex={entryIndex}
									/>
								);
							})}
					</div>
				</div>
			) : (
				<div className="timesheet-widget__task-entries timesheet-widget__middle-section">
					<DailyTimeEntry
						ref="firstEntry"
						{...entryParams}
						entry={entry}
						entryIndex={entryIndex}
					/>
				</div>
			)}
		</div>
	);
  },

  isHighlighted: function() {
    return (this.props.highlightedRowPath != null
      && this.props.highlightedRowPath.costCentreGroupIndex === this.props.costCentreGroupIndex
      && this.props.highlightedRowPath.projectPhaseGroupIndex === this.props.projectPhaseGroupIndex
      && this.props.highlightedRowPath.rowIndex === this.props.rowIndex
    );
  },

  handleEditButtonClick: function() {
    timesheetActions.editRow(
      this.props.row,
      {
        costCentreGroupIndex: this.props.costCentreGroupIndex,
        projectPhaseGroupIndex: this.props.projectPhaseGroupIndex,
        rowIndex: this.props.rowIndex
      }
    );
  },

  handleLockButtonClick: function () {
    timesheetActions.lockRow(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex
    );
  },

  handleUnlockButtonClick: function () {
    timesheetActions.unlockRow(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex
    );
  },

  handleDeleteButtonClick: function() {
    timesheetActions.deleteRow(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex
    );
  }
});


var WeeklyTimeEntry = CreateReactClass({
  propTypes: {
    costCentreGroupIndex: PropTypes.number.isRequired,
    projectPhaseGroupIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired
  },

  render: function() {
    let isWeekend = this.props.entry.date.isoWeekday() > 5;
    let locked = this.props.entry.isLocked
    let hasNote = (this.props.entry.notes != null && this.props.entry.notes !== '');

    return (
      <div className={classNames('time-entry', isWeekend ? 'weekend' : null, locked ? 'locked' : null)}>
        <HoursInput
          ref="input"
          value={this.props.entry.numMinutes}
          onChange={this.handleHoursChange}
          className='time-entry__input'
          isEditable={!locked}
        />
        <button
            className="btn btn-default time-entry__note"
            onClick={this.handleEditNoteButtonClick}
            title={hasNote ? "Edit the note for this entry" : "Add a note for this entry"}
            disabled={locked}>
          <i className={classNames("fa", hasNote ? 'fa-commenting' : 'fa-comment')} />
        </button>
        <div className="time-entry__date-hover">
          {this.props.entry.date.format('ddd DD')}
        </div>
      </div>
    );
  },

  handleHoursChange: function(time, str) {
    timesheetActions.changeEntryTime(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex,
      this.props.entryIndex,
      time
    );
  },

  handleEditNoteButtonClick: function() {
    timesheetActions.editEntryNote(
      this.props.entry,
      {
        costCentreGroupIndex: this.props.costCentreGroupIndex,
        projectPhaseGroupIndex: this.props.projectPhaseGroupIndex,
        rowIndex: this.props.rowIndex,
        entryIndex: this.props.entryIndex
      }
    );
  },

  focus: function() {
    this.refs.input.focus();
  }
});


var DailyTimeEntry = CreateReactClass({
  propTypes: {
    costCentreGroupIndex: PropTypes.number.isRequired,
    projectPhaseGroupIndex: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired
  },

  render: function(){
    return (
      <div className='time-entry time-entry--daily'>
        <HoursInput
          ref="input"
          value={this.props.entry.numMinutes}
          onChange={this.handleHoursChange}
          className='time-entry__input'
        />
        <div className="time-entry--daily__note">
          <Textarea
            value={this.props.entry.notes || ''}
            onChange={this.handleNotesChange}
            className="time-entry__note-input"
            placeholder="(No Notes)"
          />
        </div>
      </div>
    );
  },

  handleHoursChange: function(time, str) {
    timesheetActions.changeEntryTime(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex,
      this.props.entryIndex,
      time
    );
  },

  handleNotesChange: function(event) {
    timesheetActions.changeEntryNotes(
      this.props.costCentreGroupIndex,
      this.props.projectPhaseGroupIndex,
      this.props.rowIndex,
      this.props.entryIndex,
      event.target.value
    );
  },

  focus: function() {
    this.refs.input.focus();
  }
});


var PhaseProgressBar = CreateReactClass({
  propTypes: {
    projectPhaseGroup: PropTypes.object.isRequired,
    phase: PropTypes.object.isRequired,
    staffMember: PropTypes.object.isRequired,
    monthIndex: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  },

  mixins: [PureRenderMixin],

  getInitialState: function() {
    return {
      mode: this.getModeFromPhase(this.props.phase, this.props.staffMember, this.props.monthIndex)
    };
  },

  componentWillReceiveProps: function(newProps) {
    if (newProps.phase !== this.props.phase) {
      let {phase, staffMember, monthIndex} = newProps;
      this.setState({mode: this.getModeFromPhase(phase, staffMember, monthIndex)});
    }
  },

  render: function() {
    let {projectPhaseGroup, phase, staffMember, loading} = this.props;
    let mode = this.getModes()[this.state.mode];

    let currentViewTotalMinutes = sum(projectPhaseGroup.get('rows').map(r => sum(r.get('entries').map(function(e) {
      return isNumber(e.numMinutes) ? e.numMinutes : 0;
    }))));
    let numerator = mode.getNumerator(projectPhaseGroup, currentViewTotalMinutes);
    let denominator = mode.getDenominator(phase, staffMember);

    const message = mode.getMessage(numerator, denominator)

    return (
      <div
          className={classNames(
            `project-progress-bar project-progress-bar--${mode}`,
            'flexbox-container',
            'flex-align-items-center'
          )}
          style={{
            width: '95%',
            position: 'relative',
            display: 'inline-block'
          }}>
        <div style={{fontSize: '0.9em', marginBottom:'-0.6em', textAlign: 'center'}}>
          {loading ? 
            <div>
              <LoadingSpinner /> {"Loading Budgets..."}
            </div>
          : <div className="inline-flexbox-container flex-align-items-center flex-space-between" style={{width: '70%'}}>
              <i
                className={classNames("fa", "fa-fw", 'fa-chevron-left')}
                style={{margin: '0 0.6em', cursor: 'pointer', width: '1.5em', flexGrow: 0}}
                onClick={this.handlePrevButtonClick}
              />
              <div style={{display: 'inline-block', flexGrow: 1}}>
                <i
                  className={classNames("fa", "fa-fw", mode === 'specific' ? 'fa-user' : 'fa-group')}
                  style={{margin: '0 0.4em 0 0'}}
                />
                {message}
              </div>
              <i
                className={"fa fa-fw fa-chevron-right project-progress-bar__next-mode-button"}
                style={{margin: '0 0.6em', cursor: 'pointer', width: '1.5em', flexGrow: 0}}
                onClick={this.handleNextButtonClick}
              />
            </div>
          }
        </div>
        <ProgressBar2
          numerator={numerator}
          denominator={denominator}
          formatNumber={formatHours0}
          height={4}
          width="100%"
          text={''}
          onClick={this.handleClick}
        />
      </div>
    );
  },

  getModeFromPhase(phase, staffMember, monthIndex) {
    if (!phase || !staffMember) return 'team-phase'
    if (phase.getStaffAllocationsInMonthIndexSet(staffMember, monthIndex).size > 0) {
      return 'staffMonth';
    } else if (phase.getRoleAllocationsInMonthIndexSet(staffMember.role, monthIndex).size > 0) {
      return 'roleMonth';
    } else if (phase.getAllocationsInMonthIndexSet(staffMember, monthIndex).size > 0) {
      return 'teamMonth';
    } else if (phase.getStaffMemberHoursBudget(staffMember)) {
      return 'staffPhase';
    } else if (phase.getStaffRoleHoursBudget(staffMember.role)) {
      return 'rolePhase';
    } else {
      return 'teamPhase';
    }
  },

  handleNextButtonClick: function() {
    this.setState({ mode: this.getModes()[this.state.mode].nextMode});
  },

  handlePrevButtonClick: function() {
    this.setState({ mode: this.getModes()[this.state.mode].prevMode});
  },

  getModes() {
    return {
      staffMonth: {
        getDenominator: (phase, staffMember) => phase.getStaffAllocatedHoursInMonthIndex(staffMember, this.props.monthIndex),
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('staffMonthTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Your', "This Month"),
        prevMode: 'teamPhase',
        nextMode: 'roleMonth',
      },
      roleMonth: {
        getDenominator: (phase, staffMember) => phase.getRoleAllocatedHoursInMonthIndex(staffMember.role, this.props.monthIndex),
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('roleMonthTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Role', "This Month"),
        prevMode: 'staffMonth',
        nextMode: 'teamMonth'
      },
      teamMonth: {
        getDenominator: (phase, staffMember) => phase.getAllocatedHoursInMonthIndex(this.props.monthIndex),
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('organisationMonthTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Team', "This Month"),
        prevMode: 'roleMonth',
        nextMode: 'staffPhase'
      },
      staffPhase: {
        getDenominator: (phase, staffMember) => phase.getStaffMemberHoursBudget(staffMember),
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('staffTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Your', "Entire Phase"),
        prevMode: 'teamMonth',
        nextMode: 'rolePhase'
      },
      rolePhase: {
        getDenominator: (phase, staffMember) => phase.getStaffRoleHoursBudget(staffMember.role),
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('roleTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Role', "Entire Phase"),
        prevMode: 'staffPhase',
        nextMode: 'teamPhase'
      },
      teamPhase: {
        getDenominator: (phase, staffMember) => phase.manualHoursBudget,
        getNumerator: (projectPhaseGroup, currentViewTotalMinutes) => (projectPhaseGroup.get('organisationTotalNotInView') + currentViewTotalMinutes) / 60,
        getMessage: (numerator, denominator) => this.getBudgetText(numerator, denominator, 'Team', "Entire Phase"),
        prevMode: 'rolePhase',
        nextMode: 'staffMonth'
      },
    }
  },

  getBudgetText(numerator, denominator, yourTeamText, phaseMonthText) {
    if (numerator == null) {
      return `N/A - ${yourTeamText} Hours for ${phaseMonthText}`;
    }
    else if (denominator == null || denominator === 0) {
      return `${formatHours0(numerator)} - ${yourTeamText} Hours for ${phaseMonthText}`;
    }
    else {
      return `${formatHours0(numerator)} / ${formatHours0(denominator)} (${formatPercentage0((numerator / denominator) * 100)}) - ${yourTeamText} Budget for ${phaseMonthText}`;
    }
  }
});
