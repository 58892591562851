import React from 'react';
import CreateReactClass from 'create-react-class';
import { formatNumber2 } from '../utils.js';
import { NumberInputContainer, CurrencyIcon } from '../widgets.js';
import classNames from 'classnames';

import PropTypes from "prop-types";


export var HoursRow = CreateReactClass({
  propTypes: {
    item: PropTypes.object.isRequired,
    hours: PropTypes.number.isRequired,
    expenses: PropTypes.number.isRequired,
    chargeOut: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      setHours: PropTypes.func.isRequired,
      setExpenses: PropTypes.func.isRequired,
      setChargeOut: PropTypes.func.isRequired,
    }).isRequired,
    expensesDisabled: PropTypes.bool,
    chargeOutDisabled: PropTypes.bool,
    includeFinancials: PropTypes.bool,
    isEditable: PropTypes.bool.isRequired
  },

  getDefaultProps: function() {
    return {
      expensesDisabled: false,
      chargeOutDisabled: false,
    };
  },

  render: function() {
    return <div>
      <div className="info-panel__row">
        <div
            className={classNames("info-panel__cell hour", {"cell-input-field": this.props.isEditable})}
            style={{width: this.props.includeFinancials ? '33%' : '100%', borderRight: 'solid 1px #ccc'}}>
          <i className="fa fa-clock-o" />
          <NumberInputContainer
            className="sidebar-hours-row__hours"
            value={this.props.hours}
            formatFunc={n => n != null ? formatNumber2(n) : ''}
            onChange={this.props.actions.setHours}
            disabled={!this.props.isEditable}
          />
        </div>
        {this.props.includeFinancials ?
          <div
              className={classNames("info-panel__cell dollar", {"cell-input-field": this.props.isEditable})}
              style={{width: '33%', borderRight: 'solid 1px #ccc'}}>
            <CurrencyIcon />
            <NumberInputContainer
              className="sidebar-hours-row__expense"
              value={this.props.expenses}
              formatFunc={n => n != null ? formatNumber2(n) : ''}
              onChange={this.props.actions.setExpenses}
              disabled={!this.props.isEditable || this.props.expensesDisabled}
            />
          </div>
        : null}
        {this.props.includeFinancials ?
          <div
              className={classNames("info-panel__cell dollar", {"cell-input-field": this.props.isEditable})}
              style={{width: '34%', borderRight: 'solid 1px #ccc'}}>
            <CurrencyIcon />
            <NumberInputContainer
              className="sidebar-hours-row__charge-out"
              value={this.props.chargeOut}
              formatFunc={n => n != null ? formatNumber2(n) : ''}
              onChange={this.props.actions.setChargeOut}
              disabled={!this.props.isEditable || this.props.chargeOutDisabled}
            />
          </div>
        : null}
      </div>
      <div className="info-panel__row">
        <div className="info-panel__cell input-description" style={{width: this.props.includeFinancials ? '33%' : '100%', borderRight: 'solid 1px #ccc'}} >
          <span>Hours</span>
        </div>
        {this.props.includeFinancials ?
          <div className="info-panel__cell input-description" style={{width: '33%', borderRight: 'solid 1px #ccc'}}>
            <span>Expense</span>
          </div>
        : null}
        {this.props.includeFinancials ?
          <div className="info-panel__cell input-description" style={{width: '34%'}}>
            <span>Charge-Out</span>
          </div>
        : null}
      </div>
    </div>;
  }
});
