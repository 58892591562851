import { initParser } from "./core/initParser";
import { organisationStore } from "../organisation.js";
import { invoiceProperties } from "./objectProps/invoice";
import { projectProperties } from "./objectProps/project";
import { contactProperties } from "./objectProps/contact";

const getObjs = () => {
	return {
		...Object.fromEntries(
			organisationStore.objects.Invoice.list.map((i) => [
				"inv" + i.id,
				{ ...i.toJS(), type: "invoice" },
			])
		),
		...Object.fromEntries(
			organisationStore.objects.Project.list.map((i) => [
				"pr" + i.id,
				{ ...i, type: "project" },
			])
		),
		...Object.fromEntries(
			organisationStore.objects.Contact.list.map((i) => [
				"con" + i.id,
				{ ...i, type: "contact" },
			])
		),
		inv0: { type: "invoice", projectId: organisationStore.objects.Project.list[0]?.id || -1 },
	};};


const getHashObj = (ref, contextRef) => {
  const contextObj = getContextObj(contextRef);
  const refObj = contextObj?.siblingsByRef?.[ref] || getObjs()[ref];
  return refObj;
};
const getAtObj = (ref, contextRef) => {
	const contextObj = getContextObj(contextRef);
  const refObj = contextObj?.siblingsByRef?.[ref] || getObjs()[ref];
	return refObj;
};
const getContextObj = ([ref, prop]) => {
	return getObjs()[ref];
};


export default initParser({
	getHashObj,
	getAtObj,
	getContextObj,
	constants: {
		invNum: () => ({
			type: "number",
			value:
				organisationStore.objects.Invoice.list.length +
				1 +
				(organisationStore.organisation.settings.invoiceNumber?.numAdjustor ||
					0),
		}),
		projNum: () => ({
			type: "number",
			value:
				organisationStore.objects.Project.list.length +
				1 +
				(organisationStore.organisation.settings.projectNumber?.numAdjustor ||
					0),
		}),
		invNumYear: () => ({
			type: "number",
			value:
				organisationStore.objects.Invoice.list.filter(
					(i) => i.createdAt.year() === new Date().getFullYear()
				).length +
				1 +
				(organisationStore.organisation.settings.invoiceNumber?.numYearAdjustors?.[new Date().getFullYear()] || 0),
		}),
		projNumYear: () => ({
			type: "number",
			value:
				organisationStore.objects.Project.list.filter(
					(i) => i.createdAt.year() === new Date().getFullYear()
				).length +
				1 +
				(organisationStore.organisation.settings.projectNumber
					?.numYearAdjustors?.[new Date().getFullYear()] || 0),
		}),
	},
	functions: {
		// hello: {
		// 	number: (args, context) => ({
		// 		type: "text",
		// 		value: "hi",
		// 	}),
		// },
	},
	objectProps: {
		invoice: {
			propertyLookup: invoiceProperties,
			methodLookup: {},
		},
		project: {
			propertyLookup: projectProperties,
			methodLookup: {},
		},
		contact: {
			propertyLookup: contactProperties,
			methodLookup: {},
		},
	},
});