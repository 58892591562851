import React from 'react';
import CreateReactClass from 'create-react-class';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { CoincraftPage } from '../CoincraftPage.js';
import { Modal, ModalContent } from '../modal.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { FormSection, FormRow, FormLabel } from '../forms.js';
import { organisationStore } from '../organisation.js';
import { costCentrePageStore } from './flux.js';
import { BusinessCategory } from '../models/businesscategory.js';
import { TriStateSaveButton } from '../widgets/TriStateSaveButton.js';
import { ErrorPanel } from '../widgets/ErrorPanel.js';
import { EditItemControls, Checkbox } from '../widgets/generic.js';
import PropTypes from "prop-types";
export { CostCentresPage } from "./CostCentresPage.js";

export var CostCentrePageWrapper = CreateReactClass({
  componentWillMount: function() {
    const costCentreId = this.props.routeParams.costCentreId;
    const costCentre = (costCentreId === 'new') ?
      new BusinessCategory()
    : organisationStore.getCostCentreById(parseInt(costCentreId));
    costCentrePageStore.loadPage(costCentre);
  },

  mixins: [
    PureRenderMixin,
    makeMultipleStoreMixin([costCentrePageStore], function() {
      return {
        costCentre: costCentrePageStore.costCentre
      };
    })
  ],

  render: function() {
    return <CostCentrePage costCentre={this.state.costCentre} />;
  }
});


export var CostCentrePage = CreateReactClass({
  propTypes: {
    costCentre: PropTypes.object.isRequired
  },

  mixins: [
    PureRenderMixin,
    makeMultipleStoreMixin([costCentrePageStore], function() {
      const saveWithEntriesState = costCentrePageStore.saveWithEntriesOperation.state;
      const saveWithoutEntriesState = costCentrePageStore.saveWithoutEntriesOperation.state;

      return {
        saveWithEntriesState: saveWithEntriesState,
        saveWithoutEntriesState: saveWithoutEntriesState,
        isSaving: saveWithEntriesState === 'executing' || saveWithoutEntriesState === 'executing',
        isError: saveWithEntriesState === 'error' || saveWithoutEntriesState === 'error',
        isConfirmingSave: costCentrePageStore.isConfirmingSave,
        name: costCentrePageStore.name,
        isBillable: costCentrePageStore.isBillable
      };
    })
  ],

  render: function() {
    const labelWidth = '10em';

    return <CoincraftPage
      heading={this.props.costCentre.name || 'New Cost Centre'}
      body={<div>
        {this.state.isConfirmingSave ?
          <Modal>
            <ModalContent header="Saving Cost Centre" width='45em'>
              <div style={{margin: '1em'}}>
                <p>
                  {'You have changed this cost centre from being '}
                  <em>{this.state.isBillable ? 'non-billable' : 'billable'}</em>
                  {' to '}<em>{this.state.isBillable ? 'billable' : 'non-billable'}</em>.
                </p>
                <p>
                  Would you like to update the <strong>tasks & timesheets </strong>
                  within the <strong>{this.props.costCentre.name} cost centre</strong>
                  {'to be '}<strong>{this.state.isBillable ? 'billable' : 'non-billable'}</strong>?
                </p>
              </div>
              <div style={{margin: '1em'}}>
                <div>
                  <TriStateSaveButton
                    text="Save and update tasks & timesheets"
                    onClick={() => costCentrePageStore.saveWithEntries()}
                    state={this.state.saveWithEntriesState}
                    disabled={this.state.isSaving}
                  />
                </div>
                <div style={{marginTop: '0.5em'}}>
                  <TriStateSaveButton
                    text="Save without updating tasks & timesheets"
                    onClick={() => costCentrePageStore.saveWithoutEntries()}
                    state={this.state.saveWithoutEntriesState}
                    disabled={this.state.isSaving}
                  />
                </div>

                {this.state.isError ?
                  <ErrorPanel message="There was a problem saving this cost centre." />
                : null}

                <div style={{marginTop: '1em'}}>
                  {!this.state.isSaving ?
                    <a href="javascript:void(0)" onClick={() => costCentrePageStore.cancelSave()}>
                      Cancel
                    </a>
                  :
                    <span>Cancel</span>
                  }
                </div>
              </div>
            </ModalContent>
          </Modal>
        : null}

        <div>
          <FormSection>
            <FormRow>
              <FormLabel style={{width: labelWidth}}>
                Name:
              </FormLabel>
              <input
                type="text"
                style={{width: '25em'}}
                value={this.state.name}
                onChange={(event) => costCentrePageStore.setName(event.target.value)}
                className="name-input"
              />
            </FormRow>
            <FormRow>
              <FormLabel style={{width: labelWidth}}>
                Is Billable:
              </FormLabel>
              <Checkbox
                value={this.state.isBillable}
                onChange={(isBillable) => costCentrePageStore.setIsBillable(isBillable)}
              />
            </FormRow>
          </FormSection>
          <div style={{borderTop: 'solid 1px #ccc'}}>
            <EditItemControls
              objectTypeName="cost centre"
              object={this.props.costCentre}
              showCancelButton={true}
              onCancel={() => costCentrePageStore.toCostCentresPage()}
              saveButton={
                <TriStateSaveButton
                  text="Save cost centre"
                  onClick={this.handleSaveButtonClick}
                  state={this.state.saveWithoutEntriesState}
                  size='lg'
                  className="save-button"
                />
              }
              canDelete={this.props.costCentre.id != null}
            />
          </div>

          {!this.state.isConfirmingSave && this.state.isError ?
            <ErrorPanel message="There was a problem saving this cost centre." />
          : null}
        </div>
      </div>}
    />;
  },

  handleSaveButtonClick: function() {
    costCentrePageStore.clickSaveButton();
  }
});



