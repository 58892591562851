import tuple from "immutable-tuple";
import { organisationStore } from "../../organisation";

export const invoiceProperties = (prop, invoiceValue, context) => {
	const dependencies = invoicePropDepLookup[prop]?.(invoiceValue, context) || new Map();
	if (dependencies.get(context)) {
		throw {
			value: "Circular reference.",
			type: "error",
			dependencies: dependencies
		};
	}
	const val =
		invoicePropertiesLookup[prop]?.(invoiceValue, context)
	return val && ({...val, dependencies});
}

const invoicePropertiesLookup = {
	project: (invoiceValue, context) => {
		return {
			type: "project",
			value: organisationStore.getProjectById(invoiceValue.value.projectId),
		};
	},
	contact: (invoiceValue, context) => {
		return {
			type: "contact",
			value: organisationStore.getContactById(
				invoiceValue.value.contactId
			),
		};
	},
};

const invoicePropDepLookup = {
	project: (invoiceValue, context) =>
		new Map([
			...(invoiceValue.dependencies || []),
			[tuple(invoiceValue.value.ref, "project"), {}],
		]),
	contact: (invoiceValue, context) =>
		new Map([
			...(invoiceValue.dependencies || []),
			[tuple(invoiceValue.value.ref, "contact"), {}],
		]),
};
        
export const invoiceMethods = {};