import _ from 'underscore';
import { dateConverter } from './dateconverter.js';


export function getStaffExpensesInRange(staffMember, hoursPerDay, intersection, payField = 'costRate') {
  /**
   * `staffMember`: StaffMember
   * `hoursPerDay`: number
   * `intersection`: [int-date, int-date]
   * `payField`: 'payRate' | 'costRate'
   *
   * If `staffMember` is allocated `hoursPerDay` hours per weekday in the range
   * `intersection[0]` to `intersection[1]` (inclusive), what is the total cost?
   */

  let expenses = 0;
  let chargeOut = 0;

  if (staffMember && staffMember.payRates != null && !staffMember.payRates.isEmpty()) {
    const allPayRates = (staffMember.payRates
      .map(pr => ({...pr.toJS(), date: dateConverter.momentToInt(pr.date)}))
      .toJS()
    );

    const index = _.sortedIndex(allPayRates, {date: intersection[0]}, 'date');
    let initialPr;

    // If there is a pay rate with a date before the start of the range we're after,
    // start with that, else with the first pay rate.
    if (index > 0) {
      initialPr = staffMember.payRates.get(index - 1);
    }
    else {
      initialPr = staffMember.payRates.get(0);
    };
    initialPr = initialPr.set('date', intersection[0]);

    const prs = allPayRates.filter(pr => pr.date >= intersection[0] && pr.date <= intersection[1]);
    const sections = [initialPr, ...prs];

    sections.forEach(function(section, i) {
      const next = (i < sections.length - 1) ? sections[i + 1].date - 1 : intersection[1];
      const numWeekdaysInSection = dateConverter.numWeekdaysBetween(section.date, next);
      expenses += numWeekdaysInSection * hoursPerDay * section[payField];
      chargeOut += numWeekdaysInSection * hoursPerDay * section.chargeOutRate;
    });
  }

  return {
    expenses: expenses,
    chargeOut: chargeOut
  };
}
