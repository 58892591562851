import _ from 'underscore';
import moment from 'moment';
import { isNumber, sum } from '../utils.js';
import xspans from 'xspans';


export const DateConverter = class {
			/**
			 * We do lots of stuff with dates to the point that trying to update
			 * graphs and widgets in real-time takes lots of CPU if we keep them
			 * as actual date objects.
			 *
			 * So instead we store dates as numbers of days since
			 * an arbitrary epoch in order to get acceptable performance.
			 *
			 * Note we don't even need to assume that the number of days since
			 * epoch is an integer; fractional dates work just fine. Or at least
			 * they seem to.
			 */

			constructor() {
				// [[<day offset for start of month>, <day offset for end of month>, <month index>]]
				this.months = [];
				this.epoch = moment("1970-01-01");
				this.epochWeekday = this.epoch.isoWeekday() - 1; // 0: Monday, 6: Sunday.
			}

			getMonth(day) {
				let self = this;

				// `sortedIndex` "Uses a binary search to determine the index at which the
				// value *should* be inserted into the list in order to maintain the list's
				// sorted order".
				//
				// So this will find the index for the month that ends on or after our
				// date. If that month starts before or on our date, we have a hit.
				//
				// The third argument to `sortedIndex` transforms either an element
				// of the list (`this.months`), or the value we're searching for, into
				// the key used for the sort comparison. That's why the second argument is
				// `[null, day]`: so we can use the same function both on that value and
				// on the elements in the `this.months` list.

				let candidateIndex = _.sortedIndex(
					this.months,
					[null, day],
					i => i[1]
				);

				if (
					candidateIndex < this.months.length &&
					this.months[candidateIndex][0] <= day
				) {
					return this.months[candidateIndex];
				} else {
					let date = this.epoch.clone().add(day, "days");

					// Need to be careful to always round down (towards negative infinity) here.
					// By default moment.js's `diff` function rounds towards zero, which is fine
					// for positive numbers but will give a result of zero for say, 1969-12-15.
					// So we pass `true` as the third argument, meaning don't round, and apply
					// `Math.floor` ourselves.

					let monthIndex = Math.floor(
						date.diff(this.epoch, "months", true)
					);
					let month = [
						self.momentToInt(date.startOf("month")),
						self.momentToInt(date.endOf("month").startOf("day")),
						monthIndex
					];

					this.months.push(month);
					this.months.sort((a, b) => a[0] - b[0]);

					return month;
				}
			}

			get todayMoment() {
				return moment();
			}

			get todayInt() {
				return this.momentToInt(this.todayMoment);
			}

			get currentMonthIndex() {
				return this.momentToMonthIndex(this.todayMoment);
			}

			get startOfCurrentMonth() {
				return this.todayMoment.startOf("month");
			}

			get endOfCurrentMonth() {
				return this.todayMoment.endOf("month");
			}

			get startOfCurrentMonthInt() {
				return this.momentToInt(this.startOfCurrentMonth);
			}

			get endOfCurrentMonthInt() {
				return this.momentToInt(this.endOfCurrentMonth);
			}

			maxMoment(moment1, moment2) {
				const int1 = this.momentToInt(moment1);
				const int2 = this.momentToInt(moment2);
				return this.intToMoment(Math.max(int1, int2));
			}

			minMoment(moment1, moment2) {
				const int1 = this.momentToInt(moment1);
				const int2 = this.momentToInt(moment2);
				return this.intToMoment(Math.min(int1, int2));
			}

			getMonthIndex(day) {
				return this.getMonth(day)[2];
			}

			intToMonthIndex(day) {
				return this.getMonthIndex(day);
			}

			momentToMonthIndex(moment) {
				return this.getMonth(this.momentToInt(moment))[2];
			}

			momentToInt(m) {
				return m.diff(this.epoch, "days");
			}

			stringToInt(s) {
				return this.momentToInt(moment(s, "YYYY-MM-DD"));
			}

			intToMoment(day) {
				if (!isNumber(day)) {
					throw new Error("intToMoment expects a number");
				}
				return this.epoch.clone().add(day, "days");
			}

			intToString(day) {
				return this.intToMoment(day).format("YYYY-MM-DD");
			}

			startOfMonthOffset(day) {
				return this.getMonth(day)[0];
			}

			endOfMonthOffset(day) {
				return this.getMonth(day)[1];
			}

			stringToMonthIndex(s) {
				return this.getMonthIndex(this.stringToInt(s));
			}

			isStartOfMonth(day) {
				return day === this.startOfMonthOffset(day);
			}

			diffMonths(day2, day1) {
				return this.getMonthIndex(day2) - this.getMonthIndex(day1);
			}

			diffMomentMonths(moment1, moment2) {
				return moment2.clone().diff(moment1.clone(), "months", true);
			}

			momentMonthsPerMonthIndex(startMoment, endMoment) {
				let monthIndexLookup = {};
				const startMonthIndex = dateConverter.momentToMonthIndex(
					startMoment
				);
				const endMonthIndex = dateConverter.momentToMonthIndex(
					endMoment
				);
				_.range(startMonthIndex, endMonthIndex + 1).forEach(mi => {
					if (mi > startMonthIndex && mi < endMonthIndex) {
						monthIndexLookup[mi] = 1;
					} else if (mi == startMonthIndex) {
						monthIndexLookup[mi] =
							Math.round(
								this.diffMomentMonths(
									startMoment.clone(),
									this.minMoment(
										startMoment.clone().endOf("month"),
										endMoment.clone()
									)
								) * 10
							) / 10 || 1; // 1 for if start and end are the same
						// this should only fire if there's 2 or more months
					} else if (mi == endMonthIndex) {
						monthIndexLookup[mi] =
							Math.round(
								this.diffMomentMonths(
									endMoment.clone().startOf("month"),
									endMoment.clone()
								) * 10
							) / 10;
					}
				});
				return monthIndexLookup;
			}

			dayOffsetToWeekday(day) {
				/**
				 * 0: Monday, 6: Sunday
				 */
				return (day + this.epochWeekday) % 7;
			}

			monthIndexToMoment(monthIndex) {
				return this.epoch
					.clone()
					.add(monthIndex, "months")
					.startOf("month");
			}

			monthIndexToOffset(monthIndex) {
				let candidateIndex = _.sortedIndex(
					this.months,
					[null, null, monthIndex],
					i => i[2]
				);
				if (
					candidateIndex >= this.months.length ||
					this.months[candidateIndex][2] !== monthIndex
				) {
					this.getMonth(
						this.epoch
							.clone()
							.add(monthIndex, "months")
							.diff(this.epoch, "days")
					);
					candidateIndex = _.sortedIndex(
						this.months,
						[null, null, monthIndex],
						i => i[2]
					);
				}
				return this.months[candidateIndex][0];
			}

			monthIndexToEndOfMonthOffset(monthIndex) {
				return this.endOfMonthOffset(
					this.monthIndexToOffset(monthIndex)
				);
			}

			numWeekdaysBetween(day1, day2) {
				/**
				 * Returns the number of week days between `day1` and `day2`, inclusive.
				 * (That is, `this.numWeekdaysBetween(x, x)` is always equal to 1 if x is a
				 * weekday and 0 otherwise).
				 */

				if (day1 == null || day2 == null) {
					throw new Error(
						"`numWeekdaysBetween` only works with finite values."
					);
				}

				let day1Weekday = this.dayOffsetToWeekday(day1);
				let firstMondayNotBeforeDay1 =
					day1 + (day1Weekday === 0 ? 0 : 7 - day1Weekday);
				if (day2 >= firstMondayNotBeforeDay1) {
					let numDaysInFirstWeek =
						day1Weekday !== 0 && day1Weekday < 5
							? 5 - day1Weekday
							: 0;
					let daysSinceFirstMonday = day2 - firstMondayNotBeforeDay1;
					return (
						numDaysInFirstWeek +
						Math.floor(daysSinceFirstMonday / 7) * 5 +
						Math.min((daysSinceFirstMonday % 7) + 1, 5)
					);
				} else {
					let day2Weekday = this.dayOffsetToWeekday(day2);
					if (day2Weekday < 5) {
						return day2 - day1 + 1;
					} else if (day1Weekday < 5) {
						return day2 - day1 + 1 - (day2Weekday - 4);
					} else {
						return 0;
					}
				}
			}

			numWeekdaysInRangeWithoutHolidays(rangeArray, holidaysArray) {
				let totalWeekdays = 0;
				let rangeXspan = xspans(rangeArray);
				let holidaysXspan = xspans(holidaysArray);
				let nonHolidayXspan = xspans.sub(rangeXspan, holidaysXspan);
				nonHolidayXspan
					.toObjects()
					.forEach(
						range =>
							(totalWeekdays =
								totalWeekdays +
								this.numWeekdaysBetween(range.from, range.to))
					);
				return totalWeekdays;
			}

			splitValueIntoMonths(value, startMoment, endMoment) {
				let intervals = this.getEndOfMonthDateIntervals(
					this.momentToInt(startMoment),
					this.momentToInt(endMoment)
				);
				let totalDuration = sum(intervals.map(i => i.duration));
				let valueDurationRatio = value / totalDuration;
				intervals.forEach(i => {
					i.value = i.duration * valueDurationRatio;
					i.cumulativeValue =
						i.cumulativeDuration * valueDurationRatio;
				});
				return intervals;
			}

			getEndOfMonthDateIntervals(startDateInt, endDateInt) {
				let intervals = [];

				// set up first interval before loop
				let startMoment = this.intToMoment(startDateInt);
				let endMoment = startMoment.clone().endOf("month");
				let duration =
					Math.round(
						((this.momentToInt(endMoment) - startDateInt + 1) /
							startMoment.daysInMonth()) *
							10
					) / 10;
				let cumulativeDuration = 0;

				// go through adding intervals to start & end of month
				while (endMoment.isBefore(this.intToMoment(endDateInt))) {
					cumulativeDuration = cumulativeDuration + duration;
					intervals.push({
						start: this.momentToInt(startMoment),
						end: this.momentToInt(endMoment),
						duration: duration,
						cumulativeDuration: cumulativeDuration
					});
					startMoment = startMoment
						.clone()
						.add(1, "month")
						.startOf("month");
					endMoment = endMoment
						.clone()
						.add(1, "month")
						.endOf("month");
					duration = 1;
				}

				// add final interval
				duration =
					Math.round(
						((endDateInt - this.momentToInt(startMoment) + 1) /
							startMoment.daysInMonth()) *
							10
					) / 10;
				intervals.push({
					start: this.momentToInt(endMoment.clone().startOf("month")),
					end: endDateInt,
					duration: duration,
					cumulativeDuration: cumulativeDuration + duration
				});
				return intervals;
			}

			momentArrayToIntArray(momentArray) {
				return momentArray.map(m => this.momentToInt(m));
			}

			intArrayToMommentArray(intArray) {
				return intArray.map(i => this.intToMoment(i));
			}

			numWeekdaysInMonth(day) {
				return this.numWeekdaysBetween(day, this.endOfMonthOffset(day));
			}

			numWeekdaysInMonthWithoutHolidays(day, holidaysArray) {
				return this.numWeekdaysInRangeWithoutHolidays(
					[day, this.endOfMonthOffset(day)],
					holidaysArray
				);
			}

			diffDateRangesArray(rangesArray1, rangesArray2) {
				//rangesArray = [{startDate, endDate}]
				let diffedRanges = [];
				rangesArray1.forEach(function(range1) {
					rangesArray2.forEach(function(range2) {
						// breaks if array returned
						// perhaps should always return array and flatten?
						range1 = this.diffDateRanges(range1, range2);
					});
					diffedRanges.push(range1);
				});
				return diffedRanges;
			}

			diffDateRanges(range1, range2) {
				//range = {startDate, endDate}
				//returns array
				let diffedRange = {};
				// if range2 outside of range 1
				if (
					range2.endDate <= range1.startDate ||
					range2.startDate >= range1.endDate
				) {
					return [range1];
					// if range2 envelops range 1
				} else if (
					range2.endDate >= range1.endDate &&
					range2.startDate <= range1.startDate
				) {
					return [null];
					// if range2 intersects range 1 end
				} else if (
					range2.startDate <= range1.endDate &&
					range2.endDate >= range1.endDate
				) {
					return [
						{
							startDate: range1.startDate,
							endDate: range2.startDate
						}
					];
					// if range2 intersects range 1 start
				} else if (
					range2.endDate >= range1.startDate &&
					range2.startDate <= range1.startDate
				) {
					return [
						{ startDate: range2.endDate, endDate: range1.endDate }
					];
					// if range2 inside range 1
				} else if (
					range2.startDate >= range1.startDate &&
					range2.endDate <= range1.endDate
				) {
					return [
						{
							startDate: range1.startDate,
							endDate: range2.startDate
						},
						{ startDate: range2.endDate, endDate: range1.endDate }
					];
				}
			}
		};

export var dateConverter = new DateConverter();
