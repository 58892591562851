import { dispatcher, ActionCollection, registerActions } from "./coincraftFlux.js";
import { OrganisationStore } from './organisationStore.js';


export var organisationStore = window.organisationStore = new OrganisationStore();


const dashboardActionDefinitions = [
  {action: 'newProject', args: []},
  {action: 'newStaffMember', args: []},
  {action: 'editStaffMember', args: ['staffMemberId']},
];
organisationStore.actionDefinitions = dashboardActionDefinitions;
export const newOrganisationActions = registerActions("organisation", dashboardActionDefinitions, dispatcher);


organisationStore.dispatchToken = dispatcher.register(function(action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS': case 'REGISTER_SUCCESS':
      organisationStore.initializeOrganisation(action.data);
      break;

    case 'ORGANISATION_REFRESH_ORGANISATION':
      organisationStore.refreshOrganisation();
      break;
    case 'ORGANISATION_SAVE_XERO_SETTINGS_SUCCESS':
      organisationStore.saveXeroSettingsSuccess(action.xeroSettings);
      break;

    case 'SAVE_USER_DETAILS_SUCCESS':
      organisationStore.saveUserDetailsSuccess(action.data);
      break;

    case 'ORGANISATION_SAVE_PROJECT':
      organisationStore.saveProject(action.project);
      break;
    case 'ORGANISATION_DELETE_OBJECT':
      organisationStore.deleteObject(action.object);
      break;
    case 'ORGANISATION_DELETE_OBJECT_SUCCESS':
      organisationStore.deleteObjectSuccess(action.responseData);
      break;
    case 'ORGANISATION_DELETE_OBJECT_FAILURE':
      organisationStore.deleteObjectFailure(action.object, action.error);
      break;
    case 'ORGANISATION_DISMISS_DELETE_ERROR':
      organisationStore.dismissDeleteError();
      break;
    case 'ORGANISATION_LOAD_PROJECT_PAGE':
      organisationStore.loadProjectPage(action.project);
      break;
    case 'ORGANISATION_UNLOAD_PROJECT_PAGE':
      organisationStore.unloadProjectPage();
      break;
    case 'INVOICES_INVOICE_SAVE_SUCCESS':
      organisationStore.invoiceSaveSuccess(action.invoice);
      break;

    case 'INVOICES_DELETE_INVOICE_SUCCESS':
      organisationStore.invoiceDeleteSuccess(action.invoice);
      break;
    case 'INVOICES_GET_ACCOUNTING_SYSTEM_DATA_SUCCESS':
      organisationStore.getAccountingSystemDataSuccess(action.accountingSystem, action.data);
      break;

    case 'MILESTONES_SAVE_ALL_SUCCESS': {
      let milestonesStore = require("./milestones/flux.js").store;
      dispatcher.waitFor([milestonesStore.dispatchToken]);
      organisationStore.refreshProjectLookup();
      break;
    }

    case 'ORGANISATION_SAVE_COST_CENTRE_SUCCESS': {
      organisationStore.saveCostCentreSuccess(action.data);
      let costCentrePageStore = require("./costCentres/flux.js").costCentrePageStore;
      costCentrePageStore.saveCostCentreSuccess(action.data);
      break;
    }

    case 'WEEKLY_SAVE_FINISHED': case 'DAILY_SAVE_FINISHED':
      organisationStore.updatePhaseTimesheetTotals(action.data);
      break;

    case 'LOGOUT_SUCCESS':
      organisationStore.logoutSuccess();
      break;
  }
});


export var actions = new ActionCollection(
  'ORGANISATION_',
  organisationStore,
  [
    {name: 'refreshOrganisation', args: []},

    {name: 'saveProject', args: ['project']},
    {name: 'deleteObject', args: ['object']},
    {name: 'deleteObjectSuccess', args: ['responseData']},
    {name: 'deleteObjectFailure', args: ['object', 'error']},
    {name: 'dismissDeleteError', args: []},

    {name: 'openAccountingSystemSettingsPopup', args: [], callback: 'default'},
    {name: 'invoiceSettingsSelectAccountingSystem', args: ['accountingSystemId'], callback: 'default'},
    {name: 'invoiceSettingsSetSettings', args: ['settings'], callback: 'default'},

    {name: 'saveCostCentreSuccess', args: ['data'], callback: 'default'},
    {name: 'saveCostCentreFailure', args: ['error']}
  ],
  dispatcher,
  function(action) {
  }
).actionsDict;
