import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';

import PropTypes from "prop-types";

export var TablePageHeading = CreateReactClass({
  propTypes: {
    header: PropTypes.string.isRequired,
    isAddButtonVisible: PropTypes.bool.isRequired,
    hasAddButton: PropTypes.bool,

    // These are required if `isAddButtonVisible` is `true`.
    addButtonText: PropTypes.string,
    addButtonClassName: PropTypes.any,
    onAddButtonClick: PropTypes.func,

    additionalContent: PropTypes.node,
    margin: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      margin: true
    };
  },

  render: function() {
    return <div>
      <div className="new-dashboard__heading" style={this.props.margin ? {} : {margin: 0}}>
        <div className="flexbox-container flex-align-items-center">
          <h2 className="flex-0-0-auto new-dashboard__heading" style={{margin: 0, display: 'inline'}}>
            {this.props.header}
          </h2>

          {this.props.isAddButtonVisible ?
            <button
                className={classNames(
                  "btn btn-default flex-0-0-auto dont-print",
                  this.props.addButtonClassName
                )}
                style={{marginLeft: '2em'}}
                onClick={this.props.onAddButtonClick}>
              + {this.props.addButtonText}
            </button>
          : null}

          {this.props.additionalContent}
        </div>
      </div>
    </div>;
  }
});
