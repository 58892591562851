import _ from 'underscore';
import moment from 'moment';
import { Project, NoPhasePhase } from '../models.js';
import { RowFactory, RevenueRow } from './Spreadsheet.js';
import { sum } from '../utils.js';
import { dateConverter } from '../models.js';
import Immutable from 'immutable';
import { organisationStore } from '../organisation.js';


export function getRevenueSpreadsheetData({
    projects,
    startMonth,
    endMonth,
    currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment())),
    editedRevenueRowKeys = Immutable.Set()
  }) {

  const monthIndexes = _.range(startMonth, endMonth + 1);

  const rows = getRevenueSpreadsheet({projects, startMonth, endMonth, currentMonthIndex, editedRevenueRowKeys});

  const graph = monthIndexes.map(function(monthIndex) {
    const momentStartDate = dateConverter.monthIndexToMoment(monthIndex);
    const momentEndDate = momentStartDate.clone().endOf('month');
    let staffExpenses = sum(_.map(
      organisationStore.getStaffMonthlySpend(projects, momentStartDate, momentEndDate).dict,
      (v,k) => k == dateConverter.momentToInt(momentStartDate) ? v : 0
    ));
    let orgExpenses = []
    organisationStore.getNonProjectExpenses(momentStartDate, momentEndDate, (cfi) => orgExpenses.push(cfi.spend));
    return {
      date: dateConverter.monthIndexToOffset(monthIndex),
      income: sum(rows.map(function(r) {
        const cell = r.get(monthIndex);
        return cell != null ? sum(cell.cashFlowItems.map(cfi => cfi.fee)) : 0;
      })),
      spend: sum(rows.map(function(r) {
        const cell = r.get(monthIndex);
        return cell != null ? sum(cell.cashFlowItems.map(cfi => cfi.spend)) : 0;
      })) + staffExpenses + sum(orgExpenses)
    };
  });

  const columnHeaders = monthIndexes.map(function(monthIndex) {
    const columnTotal = sum(rows.map(r => r.getTotalForMonth(monthIndex)));
    return {
      header: dateConverter.intToMoment(dateConverter.monthIndexToOffset(monthIndex)).format("MMM YY"),
      type: 'currency',
      value: columnTotal
    };
  });

  return {
    rows: rows,
    graph: graph,
    columnHeaders: columnHeaders
  };
}


export function getRevenueSpreadsheet({
    projects,
    startMonth,
    endMonth,
    currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment())),
    editedRevenueRowKeys = Immutable.Set()
  }) {
  let rows = Immutable.List();

  const now = dateConverter.intToMoment(dateConverter.monthIndexToOffset(currentMonthIndex));

  projects.sort(Project.compareByTitle);

  function addRow(project, phase) {
    const row = getRevenueRow(project, phase, startMonth, endMonth, now);

    // (new NoPhasePhase()).id == null so this works fine as a key for
    // `Immutable.is` semantics.
    const key = Immutable.List([project.id, phase.id]);
    if (!row.isEmpty || editedRevenueRowKeys.includes(key)) {
      rows = rows.push(row);
    }
  }

  for (let project of projects) {
    for (let phase of project.getVisiblePhases()) {
      addRow(project, phase);
    }
    addRow(project, new NoPhasePhase({project: project}));
  }

  return rows;
}


export function getRevenueRow(project, phase, startMonth, endMonth, now) {
  /**
   * Returns a `RevenueRow` with an additional `isEmpty` property that's true
   * if all the revenues are zero. (The `isEmpty` property isn't part of the
   * immutable data structure, it's just a plain property.)
   */
  let thisMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(now));

  let thisMonthMilestones = [];    // `CashFlowItem`s that are associated with milestones.
  let thisMonthActuals = [];       // `CashFlowItem`s that aren't associated with milestones.
  let thisMonthCashFlowItems = []; // All `CashFlowItem`s for this month.

  let r = makeRevenueRow(project, phase, startMonth, endMonth);
  let isEmpty = true;
  let valueToLeft = 0;
  for (let cfi of getPhaseRevenueItems(project, phase, now)) {
    const monthIndex = dateConverter.getMonthIndex(cfi.endDate);

    if (!project.showProjections) {
      if (cfi.milestone != null) {
        continue;
      }
      if (cfi.expense != null && monthIndex > thisMonthIndex) {
        continue;
      }
    }

    if (monthIndex < startMonth) {
      valueToLeft += cfi.net;
    }

    if (startMonth <= monthIndex && monthIndex <= endMonth) {
      if (monthIndex === thisMonthIndex) {
        if (cfi.spend > 0) {
          // We always want to include expenses regardless of whether the
          // projected revenue or actual revenue is greater.
          thisMonthMilestones.push(cfi);
          thisMonthActuals.push(cfi);
        }
        else if (cfi.milestone != null) {
          thisMonthMilestones.push(cfi);
        }
        else {
          thisMonthActuals.push(cfi);
        }
        thisMonthCashFlowItems.push(cfi);
      }
      else {
        r = r.update(monthIndex, ({total, cashFlowItems}) => ({total: total + cfi.net, cashFlowItems: cashFlowItems.push(cfi)}));
        if (cfi.net !== 0) {
          isEmpty = false;
        }
      }
    }
  }

  /**
   * Spec: https://docs.google.com/document/d/1hfQuhzkDg0dXoKBeUgtn3hvIk0Xiyv6VUucNju21mtA/edit?usp=sharing
   *
   * "For the current month, show just the milestone or the sum of the
   * invoices(*) for the month, whichever is greater."
   */


  if (thisMonthMilestones.length > 0 || thisMonthCashFlowItems.length > 0) {
    const thisMonthRevenue = Math.max(
      sum(thisMonthMilestones.map(m => m.net)),
      sum(thisMonthActuals.map(cfi => cfi.net)), //TODO-archivedness_fixes
    );
    r = r.set(thisMonthIndex, {
      total: thisMonthRevenue,
      cashFlowItems: Immutable.List(thisMonthCashFlowItems)
    });
    if (thisMonthRevenue !== 0) {
      isEmpty = false;
    }
  }

  let cumSum = valueToLeft;
  for (let monthIndex of r.iterMonths()) {
    cumSum += r.get(monthIndex).total;
    r = r.update(monthIndex, m => ({...m, cumSum: cumSum}));
  }

  r.isEmpty = isEmpty;

  return r;
}


function getPhaseRevenueItems(project, phase, now) {
  if (phase.id > 0) {
    return phase.getActuals(now);
  }
  else {
    return project.getNoPhaseCashFlowItems();
  }
}



function makeRevenueRow(project, phase, startMonth, endMonth) {
  const headers = [
    ['project', project],
    ['phase', phase],
  ];
  return new RowFactory(RevenueRow, startMonth, endMonth).makeRow(headers);
}
