import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import { accountingSystems } from "./accounting.js";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import {
	BasicMySelect2,
	NumberInputContainer,
	EditItemControls,
	Alert,
} from "../widgets.js";
import { invoicePageStore } from "./InvoicePageStore.js";
import {
	organisationStore,
	actions as organisationActions,
} from "../organisation.js";
import { ConnectionState } from "./ConnectionState.js";
import { AccountingSystemAuth } from "./AccountingSystemAuth.js";

import PropTypes from "prop-types";
import Formula from "../formulas/Formula.js";
import { InvoiceFormulaInfo } from "./invoiceFormulaInfo.js";

export var InvoiceSettings = CreateReactClass({
	propTypes: {
		then: PropTypes.oneOf(["closePopup", "copyInvoice"]).isRequired,
		onSave: PropTypes.func.isRequired, // function(accountingSystemId, settings, generalSettings)

		// Required but not because we create the component without specifying
		// `onCancel` and then someone else `React.cloneElement`s the component to
		// provide it and we don't want a warning during the meanwhilst.
		onCancel: PropTypes.func,
	},

	mixins: [
		makeMultipleStoreMixin(
			[organisationStore, invoicePageStore],
			function () {
				return {
					invCode: "hello",
					...organisationStore.invoiceSettingsState,
				};
			}
		),
	],

	render: function () {
		const isSync = this.props.then === "copyInvoice";
		const isSaveButtonEnabled = this.state.accountingSystem.canSaveSettings(
			this.state.accountingSystemSettings
		);
		const accountingSystemAuthenticated =
			this.state.accountingSystem != null &&
			this.state.accountingSystem.connection != null &&
			this.state.accountingSystem.connection.state ===
				ConnectionState.connected;
		const showIfSyncing =
			this.state.accountingSystemId !== "none" &&
			accountingSystemAuthenticated;
		const latestInv =
			organisationStore.objects.Invoice.list[
				organisationStore.objects.Invoice.list.length - 1
			];
		const invCodeFormula = new Formula({
			ref: "inv" + (latestInv?.id || "0"),
			prop: "invoiceNumber",
			formula: this.state.invoiceCode,
		});

		return (
			<div className="invoice-settings">
				<Alert
					alertType={accountingSystemAuthenticated ? "success" : null}
					className={
						"flexbox-container flex-align-items-center flex-space-between"
					}
					style={{ padding: "1em", borderBottom: "solid 1px #ccc" }}
				>
					<div
						style={{ marginRight: "1em" }}
						className="flexbox-container flex-align-items-center "
					>
						<div
							style={{
								paddingRight: "1em",
								display: "inline-block",
							}}
						>
							Accounting system:
						</div>
						<BasicMySelect2
							value={this.state.accountingSystemId}
							className="accounting-system-settings__accounting-system-select"
							options={accountingSystems.map(function (
								AccountingSystemClass
							) {
								const ac = new AccountingSystemClass({});
								return {
									value: ac.identifier,
									label: ac.name,
								};
							})}
							onChange={this.handleAccountingSystemSelectChange}
						/>
					</div>
					{this.state.accountingSystemId !== "none" ? (
						<AccountingSystemAuth
							accountingSystem={this.state.accountingSystemId}
						/>
					) : null}
				</Alert>

				{isSync &&
				(this.state.accountingSystemId === "none" ||
					!accountingSystemAuthenticated) ? (
					<div style={{ padding: "2em" }}>
						Please connect to an accounting system above to sync
						your invoice.
					</div>
				) : null}

				{this.state.accountingSystem.identifier ===
					"myob-accountright" && accountingSystemAuthenticated
					? this.state.accountingSystem.getLoginComponent(
							this.state.accountingSystem.data
					  )
					: null}

				<div className="accounting-system-settings xero-settings-popup">
					<div
						style={{
							padding: !isSync || showIfSyncing ? "2em" : 0,
						}}
					>
						{!isSync ? (
							<div className="flexbox-container flex-align-items-center">
								<div
									style={{ fontWeight: "bold", width: "30%" }}
								>
									Invoice Code: <InvoiceFormulaInfo />
								</div>
								<div style={{ width: "70%" }}>
									<input
										value={this.state.invoiceCode}
										style={{
											width: "18em",
											textAlign: "left",
											margin: "1em 0",
										}}
										onChange={this.handleInvoiceCodeChange}
									/>
									{invCodeFormula.validAst
										? " eg." + invCodeFormula.value
										: "invalid code"}
								</div>
							</div>
						) : null}
						{!isSync ? (
							<div className="flexbox-container flex-align-items-center">
								<div
									style={{ fontWeight: "bold", width: "30%" }}
								>
									Invoices due:
								</div>
								<div style={{ width: "70%" }}>
									<NumberInputContainer
										value={
											this.state
												.numDaysBetweenIssueDateAndDueDate
										}
										formatFunc={(n) => n.toString()}
										style={{
											width: "3em",
											textAlign: "center",
											margin: "1em 0",
										}}
										onChange={
											this
												.handleNumDaysBetweenIssueDateAndDueDateChange
										}
									/>
									{" days after issue"}
								</div>
							</div>
						) : null}
						{/* if syncing -  show if account sys is not 'none' AND system is authenticated */}
						{!isSync || showIfSyncing ? (
							<div>
								{this.state.accountingSystem.needsData() &&
								this.state.accountingSystemData == null ? (
									<div />
								) : this.state.accountingSystemData ===
								  "loading" ? (
									<div>
										<i className="fa fa-spinner fa-spin" />{" "}
										Loading...
									</div>
								) : this.state.accountingSystemData != null &&
								  this.state.accountingSystemData.status ===
										"error" ? (
									<div>
										{/* This may not actually be reachable */}
										<p>
											There was an error getting required
											data from your{" "}
											{this.state.accountingSystem.name}{" "}
											account.
										</p>
									</div>
								) : (
									<div>
										{React.createElement(
											this.state.accountingSystem.getSettingsComponent(),
											{
												settings:
													this.state
														.accountingSystemSettings,
												data: this.state
													.accountingSystemData,
												onChange: (settings) =>
													organisationActions.invoiceSettingsSetSettings(
														settings
													),
											}
										)}
									</div>
								)}
							</div>
						) : null}
					</div>
					<div style={{ borderTop: "solid 1px #ccc" }}>
						<EditItemControls
							objectTypeName="settings"
							showCancelButton={true}
							onSave={this.handleSaveButtonClick}
							isSaveButtonDisabled={!isSaveButtonEnabled}
							onCancel={() => this.props.onCancel()}
						/>
					</div>
				</div>
			</div>
		);
	},

	handleNumDaysBetweenIssueDateAndDueDateChange: function (value) {
		this.setState({ numDaysBetweenIssueDateAndDueDate: value });
	},

	handleInvoiceCodeChange: function (e) {
		this.setState({ invoiceCode: e.target.value });
	},

	handleAccountingSystemSelectChange: function (accountingSystemId) {
		organisationActions.invoiceSettingsSelectAccountingSystem(
			accountingSystemId
		);
	},

	handleAccountingSystemSettingsChange: function (settings) {
		organisationActions.invoiceSettingsSetSettings(settings);
	},

	handleSaveButtonClick: function () {
		this.props.onSave(
			this.state.accountingSystemId,
			this.state.accountingSystemSettings,
			{
				numDaysBetweenIssueDateAndDueDate:
					this.state.numDaysBetweenIssueDateAndDueDate,
				invoiceCode: this.state.invoiceCode,
			}
		);
	},
});
