import { observable, computed, action, makeObservable } from "mobx";
import { startOfDay, format, differenceInDays } from "date-fns";
import apiRequest from "../apiRequest";
import allocationModel from "./allocationModel";
import axios from "axios";
import { sum } from "../utils";
import tuple from "immutable-tuple";

class allocationState {
	@observable loading = null;
	@observable saving = null;
	@observable allocationsById = {};
	@observable dirtyAllocations = new Set();
	@observable savingAllocations = new Set();
	@observable deletedAllocations = new Set();
	@observable deletingAllocations = new Set();
	@observable weekStart = null;
	constructor() {
		makeObservable(this);
	}
	@computed
	get allocations() {
		return Object.values(this.allocationsById);
	}
	@action.bound
	loadWeek(weekStart, staffMemberId) {
		this.weekStart = weekStart;
		this.source && this.source.cancel("New Request");
		const cancelToken = axios.CancelToken;
		this.source = cancelToken.source();
		this.request = apiRequest({
			path: "/api/v2/allocation/weekly",
			method: "post",
			cancelToken: this.source.token,
			data: {
				staff: staffMemberId,
				startDate: format(weekStart, "yyyy-MM-dd"),
				noCache: new Date().getTime(),
			},
			success: (data) => {
				this.allocationsById = {};
				data.allocations.forEach((t) => this.addAllocation(t));
			},
		});
	}
	@action.bound
	addAllocation(data) {
		const allocation = new allocationModel(data);
		this.allocationsById[allocation.uuid] = allocation;
		return allocation;
	}
	@action.bound
	createNewAllocation(data) {
		const newAllocation = this.addAllocation(data);
		this.dirtyAllocations.add(newAllocation.uuid);
		this.saveAllocations();
	}
	@action.bound
	editAllocation(allocation, data) {
		allocation.updateProps(data);
		this.dirtyAllocations.add(allocation.uuid);
		this.saveAllocations();
	}
	@action.bound
	deleteAllocation(allocationUuid) {
		this.deletedAllocations.add(allocationUuid);
		delete this.allocationsById[allocationUuid];
		this.saveAllocations();
	}
	@action.bound
	saveAllocations() {
		if (
			(this.dirtyAllocations.size || this.deletedAllocations.size) &&
			this.saving !== "saving"
		) {
			this.saving = "saving";
			this.savingAllocations = this.dirtyAllocations;
			this.dirtyAllocations = new Set();
			this.deletingAllocations = this.deletedAllocations;
			this.deletedAllocations = new Set();
			this.request = apiRequest({
				path: "/api/v2/allocation/weekly/save",
				method: "post",
				data: {
					allocations: [...this.savingAllocations].map((id) =>
						this.allocationsById[id].serialise()
					),
					deleted: [...this.deletingAllocations],
				},
				success: (data) => {
					this.savingAllocations = new Set();
					this.deletingAllocations = new Set();
					this.saving = "saved";
					if (
						this.dirtyAllocations.size ||
						this.deletedAllocations.size
					) {
						this.saveAllocations();
					} else {
						setTimeout(() => {
							this.saving = null;
						}, 500);
					}
				},
			});
		}
	}
	@computed
	get totalHours() {
		let totalHours = new Map();
		Object.values(this.allocationsById).forEach((t) => {
			// some how t can be undefined
			if(t) {
				const tTuple = tuple(t.projectId, t.projectPhaseId, undefined);
				const tDateTuple = tuple(
					t.projectId,
					t.projectPhaseId,
					t.date.getMonth()
				);
				const tTupleVal = totalHours.get(tTuple);
				const tDateTupleVal = totalHours.get(tDateTuple);
				totalHours.set(tTuple, (tTupleVal || 0) + t.numMinutes / 60);
				totalHours.set(
					tDateTuple,
					(tDateTupleVal || 0) + t.numMinutes / 60
				);
			}
		});
		return totalHours;
	}
	@computed
	get dayTotals() {
		let dayTotals = {
			0: { hours: 0, minutes: 0 },
			1: { hours: 0, minutes: 0 },
			2: { hours: 0, minutes: 0 },
			3: { hours: 0, minutes: 0 },
			4: { hours: 0, minutes: 0 },
			5: { hours: 0, minutes: 0 },
			6: { hours: 0, minutes: 0 },
		};
		Object.values(this.allocationsById).forEach((t) => {
			const diffDays = differenceInDays(t.date, this.weekStart);
			dayTotals[diffDays].hours += Math.floor(t.numMinutes / 60);
			dayTotals[diffDays].minutes += Math.round(t.numMinutes % 60);
			dayTotals[diffDays].hours += Math.floor(
				dayTotals[diffDays].minutes / 60
			);
			dayTotals[diffDays].minutes = Math.round(
				dayTotals[diffDays].minutes % 60
			);
		});
		return dayTotals;
	}
}

export default new allocationState();
