import React from 'react';
import CreateReactClass from 'create-react-class';
import { EditItemControls } from '../widgets.js';
import { ProjectTasks } from '../project/tasks.js';
import { store as milestonesStore, actions } from './flux.js';
import { rootStore } from '../RootStore.js';
import { userStore } from '../user/flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { areIntersectingKeyValuesEqual } from '../utils.js';
import { ConfirmUpdateTimesheetEntriesModal } from '../project/ConfirmUpdateTimesheetEntriesModal.js';

import PropTypes from "prop-types";

export var EditItemTasks = CreateReactClass({
  propTypes: {
    item: PropTypes.object.isRequired
  },

  getInitialState: function() {
    return {
      confirmOverwriteTimesheetPopup: false
    };
  },

  mixins: [
    makeMultipleStoreMixin([rootStore], function() {
      if (this.props.itemTypeName === 'project') {
        return {
          item: milestonesStore.projectTaskStore.project,
          taskBillabilityLookup: milestonesStore.projectTaskStore.taskBillabilityLookup
        };
      }
      else {
        return {
          item: milestonesStore.phaseTaskStore.phase,
          taskBillabilityLookup: milestonesStore.phaseTaskStore.taskBillabilityLookup
        };
      }
    })
  ],

  render: function() {
    return <div>
      <div className="new-dashboard__panel new-dashboard__panel__inner">
        <div>
          {this.props.editorComponent({item: this.state.item})}
        </div>

        {this.state.confirmOverwriteTimesheetPopup ?
          <div className="inner-modal">
            <ConfirmUpdateTimesheetEntriesModal
              itemTypeName={this.props.itemTypeName}
              onConfirm={this.handleConfirmSave}
              onCancel={this.handleCancelSave}
            />
          </div>
        : null}

        <div>
          <EditItemControls
            objectTypeName="tasks"
            canDelete={false}
            onCancel={this.handleCancelButtonClick}
            onSave={this.handleSaveButtonClick}
          />
        </div>
      </div>
    </div>;
  },

  handleSaveButtonClick: function() {
    if (!areIntersectingKeyValuesEqual(this.state.item.getTaskBillabilityLookup(), this.state.taskBillabilityLookup)) {
      this.setState({confirmOverwriteTimesheetPopup: true});
    }
    else {
      this._save();
    }
  },

  handleConfirmSave: function() {
    this.setState({confirmOverwriteTimesheetPopup: false});
    this._save();
  },

  handleCancelSave: function() {
    this.setState({confirmOverwriteTimesheetPopup: false});
  },

  _save: function() {
    this.props.onSave(this.props.modal, this.props.item, this.state.item);
  },

  handleCancelButtonClick: function() {
    this.props.onCancel(this.props.modal);
  }
});


export var EditProjectTasks = CreateReactClass({
  propTypes: {
    project: PropTypes.object.isRequired
  },

  render: function() {
    let self = this;

    return <EditItemTasks
      modal={this.props.modal}
      item={this.props.project}
      itemTypeName='project'
      editorComponent={function({item}) {
        return <ProjectTasks
          path="milestones/projectTasks"
          isEditable={userStore.user.permissions.canEditProject(self.props.project)}
          project={item}
        />;
      }}
      onSave={(modal, origItem, newItem) => actions.editProjectTasksModalSave(modal, origItem, newItem)}
      onCancel={(modal) => actions.editProjectTasksModalCancel(modal)}
    />;
  }
});



