import { observable, computed, action, makeObservable } from "mobx";
import { format } from "date-fns";
import apiRequest from "../apiRequest";
import axios from "axios";
import { organisationStore } from "../organisation";
import timeStore from "./timeStore";
import allocationStore from "./allocationStore";
import tuple from "immutable-tuple";

class budgetStore {
	@observable mode = "time";
	@observable date = null;
	@observable projectId = null;
	@observable phaseId = null;
	@observable phaseBudget = 0;
	@observable roleBudget = 0;
	@observable staffBudget = 0;
	@observable monthlyPhaseBudget = 0;
	@observable monthlyRoleBudget = 0;
	@observable monthlyStaffBudget = 0;
	@observable phaseTime = 0;
	@observable roleTime = 0;
	@observable staffTime = 0;
	@observable monthlyPhaseTime = 0;
	@observable monthlyRoleTime = 0;
	@observable monthlyStaffTime = 0;
	@observable phaseAllocations = 0;
	@observable roleAllocations = 0;
	@observable staffAllocations = 0;
	@observable monthlyPhaseAllocations = 0;
	@observable monthlyRoleAllocations = 0;
	@observable monthlyStaffAllocations = 0;
	constructor() {
		makeObservable(this);
	}

	@action.bound
	getBudget(weekStart, selectedDate, mode, staffId, projectId, phaseId) {
		this.mode = mode;
		this.selectedDate = selectedDate || weekStart;
		this.projectId = projectId;
		this.phaseId = phaseId;
		this.source && this.source.cancel("New Request");
		const cancelToken = axios.CancelToken;
		this.source = cancelToken.source();
		apiRequest({
			path: "/api/v2/budget/weekly",
			method: "post",
			cancelToken: this.source.token,
			data: {
				staff: staffId,
				project: projectId,
				phase: phaseId,
				startDate: format(weekStart, "yyyy-MM-dd"),
				selectedDate: format(selectedDate || weekStart, "yyyy-MM-dd"),
				today: format(new Date(), "yyyy-MM-dd"),
			},
			success: (data) =>
				this.getBudgetSuccess(
					data,
					selectedDate,
					mode,
					staffId,
					projectId,
					phaseId
				),
		});
	}
	@computed
	get budgetTuple() {
		return tuple(this.projectId, this.phaseId, undefined);
	}
	@computed
	get budgetDateTuple() {
		return tuple(
			this.projectId,
			this.phaseId,
			this.selectedDate?.getMonth()
		);
	}
	@computed
	get currentWeekTimeUse() {
		return timeStore.totalHours.get(this.budgetTuple) || 0;
	}
	@computed
	get currentWeekMonthlyTimeUse() {
		return timeStore.totalHours.get(this.budgetDateTuple) || 0;
	}
	@computed
	get currentWeekAllocationUse() {
		return allocationStore.totalHours.get(this.budgetTuple) || 0;
	}
	@computed
	get currentWeekMonthlyAllocationUse() {
		return allocationStore.totalHours.get(this.budgetDateTuple) || 0;
	}
	@action.bound
	getBudgetSuccess(data, selectedDate, mode, staffId, projectId, phaseId) {
		const budgets = data.budgets[0];
		const phase = organisationStore.getProjectPhaseById(phaseId);
		const staff = organisationStore.getStaffMemberById(staffId);
		this.phaseBudget = phase?.manualHoursBudget || 0;
		this.roleBudget = phase?.getBudgetedHoursForStaffRole(staff.role) || 0;
		this.staffBudget = phase?.getBudgetedHoursForStaffMember(staff) || 0;
		this.monthlyPhaseBudget = budgets?.monthly_phase_budget || 0;
		this.monthlyRoleBudget = budgets?.monthly_role_budget || 0;
		this.monthlyStaffBudget = budgets?.monthly_staff_budget || 0;
		this.phaseTime = budgets?.phase_time || 0;
		this.roleTime = budgets?.role_time || 0;
		this.staffTime = budgets?.staff_time || 0;
		this.monthlyPhaseTime = budgets?.monthly_phase_time || 0;
		this.monthlyRoleTime = budgets?.monthly_role_time || 0;
		this.monthlyStaffTime = budgets?.monthly_staff_time || 0;
		this.phaseAllocations = budgets?.phase_allocations || 0;
		this.roleAllocations = budgets?.role_allocations || 0;
		this.staffAllocations = budgets?.staff_allocations || 0;
		this.monthlyPhaseAllocations = budgets?.monthly_phase_allocations || 0;
		this.monthlyRoleAllocations = budgets?.monthly_role_allocations || 0;
		this.monthlyStaffAllocations = budgets?.monthly_staff_allocations || 0;
	}
	@computed
	get phaseBudgetUse() {
		return (
			this.phaseTime +
			this.currentWeekTimeUse +
			(this.mode === "allocation"
				? this.phaseAllocations + this.currentWeekAllocationUse
				: 0)
		);
	}
	@computed
	get roleBudgetUse() {
		return (
			this.roleTime +
			this.currentWeekTimeUse +
			(this.mode === "allocation"
				? this.roleAllocations + this.currentWeekAllocationUse
				: 0)
		);
	}
	@computed
	get staffBudgetUse() {
		return (
			this.staffTime +
			this.currentWeekTimeUse +
			(this.mode === "allocation"
				? this.staffAllocations + this.currentWeekAllocationUse
				: 0)
		);
	}
	@computed
	get monthlyPhaseBudgetUse() {
		return (
			this.monthlyPhaseTime +
			this.currentWeekMonthlyTimeUse +
			(this.mode === "allocation"
				? this.monthlyPhaseAllocations +
				  this.currentWeekMonthlyAllocationUse
				: 0)
		);
	}
	@computed
	get monthlyRoleBudgetUse() {
		return (
			this.monthlyRoleTime +
			this.currentWeekMonthlyTimeUse +
			(this.mode === "allocation"
				? this.monthlyRoleAllocations +
				  this.currentWeekMonthlyAllocationUse
				: 0)
		);
	}
	@computed
	get monthlyStaffBudgetUse() {
		return (
			this.monthlyStaffTime +
			this.currentWeekMonthlyTimeUse +
			(this.mode === "allocation"
				? this.monthlyStaffAllocations +
				  this.currentWeekMonthlyAllocationUse
				: 0)
		);
	}
}

export default new budgetStore();
