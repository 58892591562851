import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { CoincraftPage } from '../CoincraftPage.js';
import { LeaveHook } from '../utils.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { rootStore } from '../RootStore.js';
import { staffStore, actions } from './flux.js';
import { StaffMemberForm } from './StaffMemberForm.js';


export var StaffMemberPage = CreateReactClass({
  mixins: [
    LeaveHook,
    makeMultipleStoreMixin([rootStore], function() {
      return {
        staffMember: staffStore.staffMember
      };
    })
  ],

  componentWillMount: function() {
    let self = this;
    setTimeout(function() {
      actions.loadPage(self.props.params.staffId);
    }, 0);
  },

  componentWillReceiveProps: function(nextProps) {
    let self = this;
    // this is because if you create a new staff member then create another new staff member
    // the url will not change and it will keep the first staff member data
    if (nextProps.params.staffId === 'new' || nextProps.params.staffId !== this.props.params.staffId) {
      setTimeout(function() {
        actions.loadPage(self.props.params.staffId);
      }, 0);
    }
  },

  routerWillLeave(nextLocation) {
    if (staffStore.getIsDirty()) {
      return "We haven't saved your changes to this staff member yet. " +
        "If you leave the staff page without saving, you'll lose your changes.";
    }
  },

  render: function() {
    if (this.state.staffMember == null) {
      return null;
    }
    return <StaffMemberForm
      staffMember={this.state.staffMember}
      template={CoincraftPage}
    />;
  }
});
