import { generateUUID } from '../utils.js';
import { DataObject } from './dataobject.js';


export const DescriptionTemplate = class extends DataObject {
			apiTypeName() {
				// needed?
				return "description-template";
			}

			static getClassName() {
				return "DescriptionTemplate";
			}

			defaults() {
				return {
					id: null,
					name: "",
					description: ""
				};
			}

			static fieldTypes() {
				return {
					id: "int",
					name: "string",
					description: "string"
				};
			}

			static fieldsForSerialize() {
				return ["id", "name", "description"];
			}
		};
