import React from 'react';
import CreateReactClass from 'create-react-class';
import { sum, formatNumber1, formatNumber2 } from '../utils.js';
import { ItemSelection } from './flux.js';
import { NumberInputContainer, DateValue, CurrencyIcon } from '../widgets.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { dateConverter, getStaffExpensesInRange } from '../models.js';
import { actions, store } from './flux.js';
import classNames from 'classnames';
import PropTypes from "prop-types";

export { ProjectSidebar } from "./ProjectSidebar.js";
export { PhaseSidebar } from "./PhaseSidebar.js";
/**
 * Set project to manual
 *
 * - Has nonzero auto budget?
 *   - Set project manual budget to auto budget
 *   - Set phase budgets to phase auto budgets scaled to add up to project total budget
 * - Else
 *   - Set project manual budget to project fee
 *   - Set phase budgets to phase fees scaled to add up to project fee
 */





export var StaffAllocationSidebar = requiresPermission(
  permissions.noRestrictions,
  {
    isEditable: ({item}) => permissions.canEditProject(item.project),
  },
  CreateReactClass({
    propTypes: {
      item: PropTypes.instanceOf(ItemSelection),
    },

    render: function() {
      return <div className="staff-allocation-sidebar">
        {this.renderContent()}
      </div>;
    },

    renderContent: function() {
      let self = this;
      let item = this.props.item;

      if (item.rangeAllocation != null) {
        return <RangeAllocationSidebarSection
          item={item}
          title={`${item.phase.getTitle()}: ${item.staffMember.getFullName()}`}
          isEnd={false}
          isEditable={this.props.isEditable}
        />;
      }
      else {
        let children = item.phase.allocations.filter(ra => ra.staffMember.id === item.staffMember.id);
        let numChildren = children.length;

        return <div>
          <RangeAllocationSidebarSection
            item={this.props.item}
            title={`${this.props.item.phase.getTitle()}: ${this.props.item.staffMember.getFullName()}`}
            isEnd={false}
            isEditable={this.props.isEditable}
          />
          {children.length > 1 ?
              children.map(function(ra, i) {
                return <RangeAllocationSidebarSection
                  item={new ItemSelection({
                    rangeAllocation: ra,
                    phase: item.phase,
                    project: item.project,
                    staffMember: item.staffMember
                  })}
                  isEnd={i === 0 || i === numChildren - 1}
                  title={`Allocation #${i+1}`}
                  isEditable={self.props.isEditable}
                />;
              })
              : null}

              {this.props.isEditable ?
                  <div style={{textAlign: 'center'}}>
                    <button
                      style={{width: '100%'}}
                      className="btn btn-default"
                      onClick={() => actions.staffAllocationSidebarAddAllocation()}>
                      + Add allocation
                    </button>
                  </div>
                  : null}
                </div>;
      }
    }
  })
);


var RangeAllocationSidebarSection = CreateReactClass({
  propTypes: {
    item: PropTypes.instanceOf(ItemSelection),
    isEnd: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
  },

  render: function() {
    let staffMember = this.props.item.staffMember;
    let phase = this.props.item.phase;
    let startDate, endDate, hours;

    const isTotal = (this.props.item.rangeAllocation == null);

    if (isTotal) {
      const staffMemberAllocations = this.props.item.phase.allocations.filter(ra => ra.staffMember.id === staffMember.id);
      startDate = Math.min(...staffMemberAllocations.map(ra => ra.startDate));
      endDate = Math.max(...staffMemberAllocations.map(ra => ra.endDate));
      hours = sum(staffMemberAllocations.map(ra => ra.hours));
    }
    else {
      startDate = this.props.item.rangeAllocation.startDate;
      endDate = this.props.item.rangeAllocation.endDate;
      hours = this.props.item.rangeAllocation.hours;
    }

    const numWeeks = (endDate - startDate) / 7;
    const numWeekdays = dateConverter.numWeekdaysBetween(startDate, endDate);
    const {expenses, chargeOut} = getStaffExpensesInRange(staffMember, hours / numWeekdays, [startDate, endDate]);
    const staffTotalAllocation = store.getStaffAllocation(staffMember, startDate, endDate);
    const staffPhaseAllocationPercent = (hours / numWeekdays * 5) / staffMember.weeklyHoursAvailable * 100;
    const staffTotalAllocationPercent = (staffTotalAllocation / numWeekdays * 5) / staffMember.weeklyHoursAvailable * 100;

    return <div
      className={classNames(
        "range-allocation-sidebar-section",
        isTotal ?
        "range-allocation-sidebar-section--total"
        : "range-allocation-sidebar-section--section"
      )}>
      <div className="dashboard-widget info-panel">
        {isTotal ?
            <h3 className="title">{`${phase.name}: ${staffMember.firstName} ${staffMember.lastName}`}</h3>
            : null}
            <div>
              <div>
                <div style={{borderBottom: '1px #ccc solid', padding: '0.5em'}}>
                  <div style={{display: 'inline-block', width: '45%', textAlign: 'center'}}>
                    {this.renderDateValue(
                      startDate,
                      actions.setRangeAllocationSidebarStartDate,
                      "range-allocation-sidebar-section__start-date"
                    )}
                  </div>
                  <div style={{display: 'inline-block', width: '10%', textAlign: 'center'}}>
                    -
                  </div>
                  <div style={{display: 'inline-block', width: '45%', textAlign: 'center'}}>
                    {this.renderDateValue(
                      endDate,
                      actions.setRangeAllocationSidebarEndDate,
                      "range-allocation-sidebar-section__end-date"
                    )}
                  </div>
                </div>
                <div style={{textAlign: 'center', padding: '0.2em', borderBottom: '1px solid #ccc'}}>
                  {formatNumber1(numWeeks)} weeks
                </div>
                {!isTotal ?
                    <div>
                      <div style={{padding: '0.5em', borderBottom: 'solid 1px #ccc'}}>
                        <div style={{display: 'inline-block', width: '50%'}}>
                          Hours
                        </div>
                        <div style={{display: 'inline-block', width: '50%'}}>
                          <NumberInputContainer
                            className="range-allocation-sidebar-section__hours"
                            value={hours}
                            formatFunc={n => n != null ? formatNumber2(n) : ''}
                            onChange={this.handleHoursChange}
                            isEditable={this.props.isEditable}
                          />
                        </div>
                      </div>
                      <div style={{textAlign: 'center', padding: '0.2em', borderBottom: '1px solid #ccc'}}>
                        {formatNumber1(hours/numWeeks)} hrs / week
                      </div>
                      {staffMember.weeklyHoursAvailable > 0 ?
                          <div>
                            <div style={{padding: '0.5em', borderBottom: 'solid 1px #ccc'}}>
                              <div style={{display: 'inline-block', width: '75%'}}>
                                Utilisation This Phase
                              </div>
                              <div style={{display: 'inline-block', width: '25%'}}>
                                {Math.round(staffPhaseAllocationPercent)}%
                              </div>
                            </div>
                            <div style={{padding: '0.5em'}}>
                              <div style={{display: 'inline-block', width: '75%'}}>
                                Overall Utilisation
                              </div>
                              <div style={{display: 'inline-block', width: '25%'}}>
                                {Math.round(staffTotalAllocationPercent)}%
                              </div>
                            </div>
                          </div>
                          : null}
                        </div>
                    : null}
                  </div>
                </div>
                {isTotal ?
                    <div>
                      <h3 className="title" style={{fontSize: '1.2em', paddingTop: '0.8em'}}>
                        Total Utilisation This Phase
                      </h3>
            {staffMember.weeklyHoursAvailable > 0 ?
              <div>
                <div style={{textAlign:'center', fontSize:'2.5em', padding:'.75em'}}>
                  {Math.round(staffPhaseAllocationPercent)}%
                </div>
                <div style={{textAlign: 'center', padding: '0.2em', borderTop: '1px solid #ccc'}}>
                  Total Overall Utilisation: {Math.round(staffTotalAllocationPercent)}%
                </div>
              </div>
            : null}
            <table className="input-table" style={{width: '100%', borderTop: 'solid 1px #ccc'}}>
              <tbody>
                <tr>
                  <td style={{borderRight: 'solid 1px #ccc'}}>
                    Hours
                  </td>
                  <td className={classNames("dollar", {"cell-input-field": this.props.isEditable})}>
                    <i className="fa fa-clock-o" />
                    <NumberInputContainer
                      value={hours}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      onChange={this.handleHoursChange}
                      isEditable={this.props.isEditable}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{borderRight: 'solid 1px #ccc'}}>
                    Expense
                  </td>
                  <td className={classNames("dollar", {"cell-input-field": this.props.isEditable})}>
                    <CurrencyIcon />
                    <NumberInputContainer
                      value={expenses}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      action={actions.setRangeAllocationSidebarExpenses}
                      isEditable={this.props.isEditable}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{borderRight: 'solid 1px #ccc'}}>
                    Charge-Out
                  </td>
                  <td className={classNames("dollar", {"cell-input-field": this.props.isEditable})}>
                    <CurrencyIcon />
                    <NumberInputContainer
                      value={chargeOut}
                      formatFunc={n => n != null ? formatNumber2(n) : ''}
                      action={actions.setRangeAllocationSidebarChargeOut}
                      isEditable={this.props.isEditable}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        : this.props.isEditable ?
          <div style={{textAlign: 'center', padding: '0.5em', borderTop: 'solid 1px #ccc'}}>
            <button className="btn btn-default" onClick={() => actions.staffAllocationSidebarRemoveAllocation(this.props.item.rangeAllocation)}>
              {this.props.isEnd ? 'Remove Allocation' : 'Clear Allocation'}
            </button>
          </div>
        : null}
      </div>
    </div>;
  },

  renderDateValue: function(date, action, className) {
    return <DateValue
      className={className}
      value={date != null ? dateConverter.intToMoment(date) : null}
      action={action}
      actionArgs={[
        this.props.item.phase,
        this.props.item.staffMember,
        this.props.item.rangeAllocation
      ]}
      isEditable={this.props.isEditable}
      dropLeft={true}
    />;
  },

  handleHoursChange: function(hours) {
    actions.setRangeAllocationSidebarHours(hours, this.props.item.rangeAllocation);
  }
});


