import moment from 'moment';
import _ from 'underscore';
import { defaultDictGet, defaultDictModify, } from '../utils.js';
import { dateConverter } from './dateconverter.js';



export const TimeSeries = class {
			constructor(defaultValueFunc) {
				this.dict = {};
				this.defaultValueFunc = defaultValueFunc;
			}

			momentToKey(date) {
				return dateConverter.momentToInt(date);
			}

			keyToMoment(key) {
				return dateConverter.intToMoment(key);
			}

			setItem(date, value) {
				this.dict[this.momentToKey(date)] = value;
			}

			modifyItem(date, updateFunc) {
				defaultDictModify(
					this.dict,
					this.momentToKey(date),
					updateFunc,
					this.defaultValueFunc()
				);
			}

			modifyItemByKey(key, updateFunc) {
				defaultDictModify(
					this.dict,
					key,
					updateFunc,
					this.defaultValueFunc()
				);
			}

			getItem(date) {
				return defaultDictGet(
					this.dict,
					this.momentToKey(date),
					this.defaultValueFunc()
				);
			}

			getItemByKey(key) {
				return defaultDictGet(this.dict, key, this.defaultValueFunc());
			}

			mapValues(func) {
				var newTs = new TimeSeries(this.defaultValueFunc);
				_.each(this.dict, function(v, k) {
					newTs.dict[k] = func(v);
				});
				return newTs;
			}

			combine(other, combineFunc, defaultValueFunc) {
				/**

      var ts1 = new TimeSeries(() => ({income: 0}));
      ts1.modifyItem(moment(), () => ({income: 10}));
      var ts2 = new TimeSeries(() => ({spend: 0}));
      ts2.modifyItem(moment(), () => ({spend: 20}));
      >>> ts1.combine(ts2, (a, b) => ({income: a.income, spend: b.spend}), () => ({income: 0, spend: 0}))).dict
      {'2015-10-02': {income: 10, spend: 20}}

    */

				var self = this;
				var newTs = new TimeSeries(defaultValueFunc);
				_.each(this.dict, function(existingD, key) {
					newTs.dict[key] = combineFunc(
						existingD,
						other.getItemByKey(key)
					);
				});
				_.each(other.dict, function(newD, key) {
					if (self.dict[key] == null) {
						newTs.dict[key] = combineFunc(
							self.defaultValueFunc(),
							newD
						);
					}
				});
				return newTs;
			}

			toArray(
				valueFunc,
				fillInBlanks = null,
				combineFunc = null,
				fillToStartKey = null
			) {
				var self = this;
				if (valueFunc == null) {
					valueFunc = () => {};
				}

				let ts;
				if (fillInBlanks != null && fillInBlanks !== "day") {
					ts = this.combineTimeSeriesInterval(
						fillInBlanks,
						combineFunc
					);
				} else {
					ts = this;
				}

				var arr = [];
				_.each(ts.dict, function(v, keyStr) {
					var key = parseInt(keyStr);
					arr.push({
						date: key,
						...valueFunc(v, key)
					});
				});

				if (fillInBlanks != null) {
					/**
					 * If fillInBlanks is provided then we insert the default value for any missing
					 * dates between the first and last dates. Note that doing so doesn't modify
					 * the time series itself, just the result of calling `toArray`.
					 */
					if (!_.include(["month", "day"], fillInBlanks)) {
						throw new Error(
							`fillInBlanks must be 'day' or 'month', but was ${fillInBlanks}`
						);
					}

					var first = fillToStartKey,
						last = null;
					_.each(ts.dict, function(_, key) {
						if (first == null || key < first) {
							first = key;
						}
						if (last == null || key > last) {
							last = key;
						}
					});

					for (var key = first; key <= last; key++) {
						if (
							fillInBlanks === "day" ||
							(fillInBlanks === "month" &&
								dateConverter.isStartOfMonth(key))
						) {
							if (ts.dict[key] == null) {
								arr.push({
									date: key,
									...valueFunc(self.defaultValueFunc(), key)
								});
							}
						}
					}
				}

				arr.sort((a, b) => a.date - b.date);
				return arr;
			}

			static fromArray(arr, extractFunc, defaultValueFunc) {
				var ts = new TimeSeries(defaultValueFunc);
				arr.forEach(function(item) {
					ts.setItem(
						moment(item.date, "YYYY-MM-DD"),
						extractFunc(item)
					);
				});
				return ts;
			}

			combineTimeSeriesInterval(newInterval, combineFunc) {
				if (newInterval !== "month") {
					throw new Error("Sorry we only understand months");
				}
				var newTs = new TimeSeries(this.defaultValueFunc);

				_.each(this.dict, function(newD, key) {
					key = parseInt(key);
					newTs.modifyItemByKey(
						dateConverter.startOfMonthOffset(key),
						function(existingD) {
							return combineFunc(existingD, newD);
						}
					);
				});

				return newTs;
			}
		};




