import React, {useState} from "react"
import copy from "copy-to-clipboard";

export const InvoiceCodeInfo = props => {
            const {style, ...extraProps } = props;
            const [showDropdown, setShowDropdown] = useState(false)
            const [hoveredKey, setHoveredKey] = useState(null);
            const [copiedKey, setCopiedKey] = useState(null);

			return (
				<div
					style={{
						position: "relative",
						display: "inline-block",
						...style
					}}
					{...extraProps}
				>
					<i
						onClick={e => setShowDropdown(!showDropdown)}
						className="fa fa-question-circle"
					/>
					{showDropdown && (
						<div
							style={{
								position: "absolute",
								right: 0,
								top: "2em",
								textAlign: "right",
								zIndex: 100,
								fontSize: "0.9em",
								border: "1px solid #ddd",
								borderRadius: "0.5em",
								background: "white",
								boxShadow: "9px 7px 17px -4px rgb(0,0,0,0.2)"
							}}
						>
							<div
								style={{
									padding: "0.5em 1em",
									borderBottom: "1px solid #ddd"
								}}
							>
								Description Codes
							</div>
							<div
								style={{ overflowY: "scroll", height: "15em" }}
							>
								{Object.keys(invoiceCodes).map(k => {
									const hovering = hoveredKey === k;
									const copied = copiedKey === k;
									return (
										<div
											style={{
												padding: "0.5em 1em",
												borderBottom: "1px solid #eee",
												fontWeight: "normal",
												background: hovering
													? "#f9f9f9"
													: "white",
												cursor: "pointer",
												position: "relative"
											}}
											onPointerOver={e =>
												setHoveredKey(k)
											}
											onPointerOut={e =>
												hoveredKey === k &&
												setHoveredKey(null)
											}
											onClick={e => {
												setCopiedKey(k);
												copy(`[${k}]`);
												setTimeout(() => {
													setHoveredKey(null);
													setCopiedKey(null);
													setShowDropdown(false);
												}, 500);
											}}
										>
											<div>
												{copied ? "Copied!" : `[${k}]`}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{invoiceCodes[hoveredKey] && (
						<div
							style={{
								position: "absolute",
								left: "2.5em",
								top: "4.5em",
								fontSize: "0.9em",
								width: "15em",
								padding: "1em",
								border: "1px solid #ddd",
								borderRadius: "0.5em",
								background: "white",
								boxShadow: "9px 7px 17px -4px rgb(0,0,0,0.2)",
								zIndex: 100
							}}
						>
							<div>{invoiceCodes[hoveredKey]}</div>
							<div
								style={{
									fontSize: "0.9em",
									fontStyle: "italic",
									fontWeight: "normal",
									marginTop: "1em"
								}}
							>
								{"<--- Click to Copy"}
							</div>
						</div>
					)}
				</div>
			);
        };
        
const invoiceCodes = {
	project: "Project name",
	projectcode: "Project Job Number",
	phase: "Phase Name",
	phasecode: "Phase Code",
	phasefee: "Total Agreed Fee for Phase",
	phaseprogress: "Percentage Progress billed Phase Fee for This Invoice",
	quantity: "The Quantity value of this Line Item",
	remainingprogress:
		"Remaining Percentage Progress billed Phase Fee after This Invoice",
	previousprogress:
		"Previous Percentage Progress billed Phase Fee prior to This Invoice",
	currentbilled: "Current Amount charged against Phase Fee in This Invoice",
	previousbilled:
		"Previous Amount charged against Phase Fee prior to This Invoice",
	todatebilled:
		"Amount To Date charged against Phase before and including This Invoice",
	remainingbilled: "Amount Remaining to be billed after This Invoice",
	hours: "Number of Hours being billed for in Timesheet Line Item",
	staff: "Names of Staff who worked on hours in Timesheet Line Item",
	expensename: "Name of Expense",
	expensetotal: "Total Fee for this Expense",
	expenseprevious: "Previous Amount billed for Expense prior to This Invoice",
	expenseCurrent: "Current Amount billed for Expense in This Invoice",
	expensetodate:
		"Amount Billed To Date, for Expense prior and Including This Invoice",
	expenseremaining:
		"Remaining Amount to be billed for Expense after This Invoice",
	expenseprogress:
		"Percentage Progress of Total Expense Fee, including This Invoice",
	expensepreviousprogress:
		"Previous Percentage Progress of Total Expense Fee, prior to This Invoice",
	expenseremainingprogress:
		"Remaining Percentage Progress Total Expense Fee to be billed after This Invoice",
};