import React from 'react';
import CreateReactClass from 'create-react-class';
import { organisationStore } from '../organisation.js';
import { makeStoreMixin } from '../coincraftFlux';
import { MySelect2 } from './generic.js';
import { PermissionObject } from '../models/permissions.js';
import PropTypes from "prop-types";


export var PermissionItemSelector = CreateReactClass({
  propTypes: {
    value: PropTypes.oneOf([
      'everything',
      PropTypes.shape({
        project: PropTypes.object
      }),
      PropTypes.shape({
        costCentre: PropTypes.object
      })
    ]),
    onChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      isEditable: true
    };
  },

  mixins: [
    makeStoreMixin(organisationStore, function() {
      /**
       * This is conceptually like grouping projects by cost centre but we also want
       * to include items for cost centres that have no projects.
       */
      let options = [new PermissionObject({item: 'everything'})];
      for (let costCentre of organisationStore.costCentres) {
        options = options.concat([
          new PermissionObject({costCentreId: costCentre.id}),
          ...(organisationStore.getSavedProjects()
            .filter(p => p.costCentre.id === costCentre.id)
            .sort((a, b) => a.getTitle().localeCompare(b.getTitle()))
            .map(p => new PermissionObject({projectId: p.id}))
          )
        ]);
      }

      return {
        options: options
      };
    })
  ],

  render: function() {
    let { value, ...props } = this.props;
    value = value || null;

    return <MySelect2
      className="project-select"
      value={value}
      options={this.state.options}
      getObjectLabel={function(item) {
        if (item == null) {
          return <div />;
        }
        else if (item.isEverything) {
          return <div><em>Everything</em></div>;
        }
        else if (item.costCentreId != null) {
          return <div><strong>{organisationStore.getCostCentreFromPermissionObject(item).name}</strong></div>;
        }
        else {
          return <div>{organisationStore.getProjectFromPermissionObject(item) ? organisationStore.getProjectFromPermissionObject(item).getTitle() : "Missing Project"}</div>;
        }
      }}
      filter={function(dataItem, searchTerm) {
        if (dataItem.isEverything) {
          return 'everything'.indexOf(searchTerm.toLowerCase()) !== -1;
        }
        else if (dataItem.projectId != null) {
          return organisationStore.getProjectById(dataItem.projectId).matchesSearch(searchTerm);
        }
        else if (dataItem.costCentreId != null) {
          return organisationStore.getCostCentreById(dataItem.costCentreId).matchesSearch(searchTerm);
        }
     }}
     {...props}
    />;
  }
});


