import _ from "underscore";
import moment from "moment";
import { dateConverter } from "../models/dateconverter.js";
import { organisationStore } from "../organisation.js";
import {
	StoreBase,
	dispatcher,
	registerActions,
	handleAction,
} from "../coincraftFlux.js";
import Immutable from "immutable";
import { OrganisationHoliday } from "../models/organisationHoliday.js";
import { AjaxOperation2 } from "../AjaxOperation.js";

const organisationHolidayActionDefinitions = [
	{ action: "addOrganisationHoliday", args: [] },
	{ action: "deleteOrganisationHoliday", args: ["holiday"] },
	{ action: "changeOrganisationHolidayStartDate", args: ["holiday", "date"] },
	{ action: "changeOrganisationHolidayEndDate", args: ["holiday", "date"] },
	{ action: "changeOrganisationHolidayName", args: ["holiday", "name"] },
	{ action: "save", args: [] },
];

export const actions = registerActions(
	"organisation-holidays",
	organisationHolidayActionDefinitions,
	dispatcher
);

class OrganisationHolidaysStore {
	constructor() {
		this.path = "organisation-holidays";

		this.organisationHolidays = organisationStore
			? organisationStore.organisationHolidays.sort((a, b) =>
					b.startDate.diff(a.startDate)
			  )
			: [];
		this.isDirty = false;
		this.saveOperation = new AjaxOperation2(this.path + "/save");

		this.stores = {
			save: this.saveOperation,
		};

		this.actionDefinitions = organisationHolidayActionDefinitions;
	}

	handle(action) {
		if (action.path === this.path + "/save") {
			this.saveOperation.handle(action);
			if (action.type === "ajax/success") {
				this.saveSuccess(action.data);
			}
		} else {
			handleAction(action, this);
		}
	}

	save() {
		this.saveOperation.execute(
			organisationStore._save(
				`/organisation/current/org-holidays/`,
				"organisationHolidays",
				this.organisationHolidays.map((e) => e.serialize())
			)
		);
	}

	saveSuccess(data) {
		organisationStore._addObjects(data.objects);
		organisationStore._deleteObjects(data.deletedItems);
		organisationStore.emitChanged();
		this.organisationHolidays.forEach(function (organisationHoliday) {
			if (
				organisationHoliday.uuid != null &&
				organisationHoliday.id == null &&
				data.uuidToIdLookup[organisationHoliday.uuid] != null
			) {
				organisationHoliday.id =
					data.uuidToIdLookup[organisationHoliday.uuid];
			}
		});
		this.isDirty = false;
	}

	addOrganisationHoliday() {
		let organisationHolidays = [].concat(this.organisationHolidays);
		organisationHolidays.unshift(
			new OrganisationHoliday({
				endDate: null,
				id: null,
				name: "",
				startDate: null,
				uuid: null,
			})
		);
		this.organisationHolidays = organisationHolidays;
		this.isDirty = true;
	}

	deleteOrganisationHoliday(holiday) {
		let organisationHolidays = [].concat(this.organisationHolidays);
		let orgHol = organisationHolidays.filter(
			(hol) => hol.uuid == holiday.uuid
		)[0];
		orgHol.isDeleted = true;
		this.organisationHolidays = organisationHolidays;
		this.isDirty = true;
	}

	changeOrganisationHolidayStartDate(holiday, date) {
		let organisationHolidays = [].concat(this.organisationHolidays);
		let orgHol = organisationHolidays.filter(
			(hol) => hol.uuid == holiday.uuid
		)[0];
		orgHol.startDate = date;
		this.organisationHolidays = organisationHolidays;
		this.isDirty = true;
	}

	changeOrganisationHolidayEndDate(holiday, date) {
		let organisationHolidays = [].concat(this.organisationHolidays);
		let orgHol = organisationHolidays.filter(
			(hol) => hol.uuid == holiday.uuid
		)[0];
		orgHol.endDate = date;
		this.organisationHolidays = organisationHolidays;
		this.isDirty = true;
	}

	changeOrganisationHolidayName(holiday, name) {
		let organisationHolidays = [].concat(this.organisationHolidays);
		let orgHol = organisationHolidays.filter(
			(hol) => hol.uuid == holiday.uuid
		)[0];
		orgHol.name = name;
		this.organisationHolidays = organisationHolidays;
		this.isDirty = true;
	}
}

export var organisationHolidaysStore = new OrganisationHolidaysStore();
