import React from "react";
import CreateReactClass from "create-react-class";
import moment from "moment";
import _ from "underscore";
import { jsonHttp } from "./jsonHttp.js";
import {
	makeRecordClass,
	BooleanType,
	NumberType,
	DateType,
	StringType,
	ListType,
} from "./models/record.js";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import { formatCurrency, sum } from "./utils.js";
import { DateValue, BooleanValue } from "./widgets/generic.js";
import { ErrorAlert } from "./widgets/alerts.js";

import PropTypes from "prop-types";
import apiRequest from "./apiRequest.js";
import Papa from "papaparse";

class TrialOrganisation extends makeRecordClass({
	organisationId: null,
	organisationUuid: null,
	organisationName: null,
	firstName: null,
	lastName: null,
	email: null,
	adminUsers: NumberType,
	timesheetUsers: NumberType,
	stripeAdminUsers: NumberType,
	stripeTimesheetUsers: NumberType,
	adminUsers30: NumberType,
	timesheetUsers30: NumberType,
	numActiveProjects: NumberType,
	numDateProjects: NumberType,
	numExpenseBudgetedProjects: NumberType,
	numHoursBudgetedProjects: NumberType,
	numStaffBudgetedProjects: NumberType,
	invoicing: BooleanType,
	hasSubscription: BooleanType,
	nextPaymentDate: DateType,
	trialStart: DateType,
	trialEnd: DateType,
	subscriptionStart: DateType,
	useStripe: BooleanType,
	stripeId: StringType,
	monthlyAdminPlanId: StringType,
	monthlyStandardPlanId: StringType,
	yearlyAdminPlanId: StringType,
	yearlyStandardPlanId: StringType,
	yearlyCouponId: StringType,
	monthlyCouponId: StringType,
	subscriptionPeriod: StringType,
	unpaidInvoices: ListType,
	projects: ListType,
}) {}

export var TrialPage = CreateReactClass({
	mixins: [PureRenderMixin],

	componentWillMount: function () {
		let self = this;
		apiRequest({
			url: `/api/v1/trials`,
			method: "get",
			success: (data) => {
				let users = data.users;
				users.sort((a, b) =>
					(a.organisationName || "").localeCompare(
						b.organisationName || ""
					)
				);
				self.setState({
					users: Immutable.List(
						users.map((u) => TrialOrganisation.fromJson(u))
					),
					plans: data.plans,
					coupons: data.coupons,
				});
			},
			error: (data) => self.setState({ users: "error" }),
		});
	},

	getInitialState: function () {
		return {
			users: [], // `null`, [<user>], 'error'
			onlySubscribers: true,
			noStripe: false,
			trialUsers: false,
			plans: {},
			coupons: {},
		};
	},

	render: function () {
		let self = this;
		let users = Immutable.List([]);
		let yearTrialUsers = 0;
		let yearNewUsers = 0;
		if (Immutable.List.isList(this.state.users)) {
			users = this.state.users.filter(
				(u) =>
					!self.state.onlySubscribers ||
					(u.hasSubscription &&
						u.adminUsers + u.timesheetUsers > 0 &&
						(u.subscriptionPeriod === "yearly"
							? u.yearlyCouponId
							: u.monthlyCouponId) !== "FREE" &&
						u.nextPaymentDate &&
						!u.nextPaymentDate.isBefore(moment().startOf("month")))
			);
			users = users.filter((u) => !self.state.noStripe || !u.useStripe);
			users = users.filter(
				(u) =>
					!self.state.trialUsers ||
					(!u.hasSubscription &&
						u.trialEnd &&
						u.trialEnd.isAfter(moment()))
			);
			users = users.filter(
				(u) => !self.state.onlySubscribers || u.nextPaymentDate
			);
			let prevYearUsers = this.state.users
				.filter(
					(u) =>
						u.trialStart &&
						u.trialStart.isAfter(moment().subtract(1, "year")) &&
						(!u.subscriptionStart ||
							u.subscriptionStart.isAfter(
								moment().subtract(1, "year")
							))
				)
				.toJS();
			yearTrialUsers = prevYearUsers.length;
			yearNewUsers = prevYearUsers.filter(
				(u) => u.hasSubscription
			).length;
		}
		const numAdminUsers = sum(users.toJS().map((u) => u.adminUsers));
		const numStandardUsers = sum(users.toJS().map((u) => u.timesheetUsers));
		const numUsers = numAdminUsers + numStandardUsers;
		const maxUsers = Math.max(
			...users.toJS().map((u) => u.adminUsers + u.timesheetUsers)
		);
		const minUsers = Math.min(
			...users.toJS().map((u) => u.adminUsers + u.timesheetUsers)
		);
		const avgUsers = numUsers / users.size;
		let monthlyPayments = 0;
		let yearlyPayments = Object.fromEntries(
			[...Array(12).keys()].map((i) => [i, 0])
		);
		let mrrList = users.toJS().map((u) => {
			let adminPlanId =
				u.subscriptionPeriod == "yearly"
					? u.yearlyAdminPlanId
					: u.monthlyAdminPlanId;
			let standardPlanId =
				u.subscriptionPeriod == "yearly"
					? u.yearlyStandardPlanId
					: u.monthlyStandardPlanId;
			let couponId =
				u.subscriptionPeriod == "yearly"
					? u.yearlyCouponId
					: u.monthlyCouponId;
			let adminPlan = this.state.plans[adminPlanId];
			let standardPlan = this.state.plans[standardPlanId];
			let coupon = this.state.coupons[couponId];
			if (adminPlan && standardPlan) {
				let adminBase = (u.adminUsers * adminPlan.amount) / 100;
				let standardBase =
					(u.timesheetUsers * standardPlan.amount) / 100;
				let baseAmount = adminBase + standardBase;
				let division =
					u.subscriptionPeriod == "yearly"
						? 12
						: adminPlan.interval_count;
				let discount = coupon ? coupon.percent_discount : 0;
				const lastPaymentDate = u?.nextPaymentDate
					?.clone?.()
					?.subtract?.(division, "months");
				const paymentPeriod = u?.nextPaymentDate?.diff?.(
					lastPaymentDate,
					"days"
				);
				const remainingPaymentPeriod = u?.nextPaymentDate?.diff?.(
					moment(),
					"days"
				);
				const remainingPaymentRatio =
					remainingPaymentPeriod / paymentPeriod;
				const mrr = {
					user: u,
					admin: (adminBase / division) * (1 - discount / 100),
					standard: (standardBase / division) * (1 - discount / 100),
					total: (baseAmount / division) * (1 - discount / 100),
					nextPayment: baseAmount * (1 - discount / 100),
					remainingPayment:
						baseAmount *
						(1 - discount / 100) *
						remainingPaymentRatio,
				};
				if (
					self.state.onlySubscribers &&
					u.subscriptionPeriod === "yearly"
				) {
					yearlyPayments[u.nextPaymentDate.month()] += mrr.total * 12;
				} else if (
					self.state.onlySubscribers &&
					adminPlan.interval_count !== 1
				) {
					//lazy but we know this is 6
					yearlyPayments[u.nextPaymentDate.month()] +=
						mrr.total * adminPlan.interval_count;
					yearlyPayments[
						u.nextPaymentDate.month() + adminPlan.interval_count
					] += mrr.total * adminPlan.interval_count;
				} else {
					monthlyPayments += mrr.total;
				}
				return mrr;
			} else {
				return {
					admin: 0,
					standard: 0,
					total: 0,
					nextPayment: 0,
					remainingPayment: 0,
					user: u,
				};
			}
		});
		mrrList = mrrList.filter((mrr) => mrr.total);
		let mrrAdmin = sum(mrrList.map((m) => m.admin));
		let mrrStandard = sum(mrrList.map((m) => m.standard));
		let mrrTotal = sum(mrrList.map((m) => m.total));
		let remainingPayments = sum(mrrList.map((m) => m.remainingPayment));
		let numSubs = mrrList.length;
		let numProjects = sum(users.toJS().map((u) => u.numActiveProjects));
		let minProjects = Math.min(
			...users.toJS().map((u) => u.numActiveProjects)
		);
		let maxProjects = Math.max(
			...users.toJS().map((u) => u.numActiveProjects)
		);
		let avgProjects = numProjects / users.size;
		let numDateProjects = mrrList.filter(
			(m) => m.user.numDateProjects
		).length;
		let percentDateProjects = numDateProjects / numSubs;
		let numExpenseBudgetedProjects = mrrList.filter(
			(m) => m.user.numExpenseBudgetedProjects
		).length;
		let percentExpenseBudgetedProjects =
			numExpenseBudgetedProjects / numSubs;
		let numHoursBudgetedProjects = mrrList.filter(
			(m) => m.user.numHoursBudgetedProjects
		).length;
		let percentHoursBudgetedProjects = numHoursBudgetedProjects / numSubs;
		let numStaffBudgetedProjects = mrrList.filter(
			(m) => m.user.numStaffBudgetedProjects
		).length;
		let percentStaffBudgetedProjects = numStaffBudgetedProjects / numSubs;
		let numInvoices = mrrList.filter((m) => m.user.invoicing).length;
		let percentInvoices = numInvoices / numSubs;
		return (
			<div style={{ padding: "1em" }}>
				<h1>User Dashboard</h1>
				<div>
					<span style={{ fontWeight: "bold" }}>Organisations:</span>{" "}
					{numSubs}
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>Users:</span>{" "}
					{numUsers} (A: {numAdminUsers} S: {numStandardUsers}) (Max:
					{maxUsers} Min: {minUsers} Avg: {Math.round(avgUsers)})
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>Projects:</span>{" "}
					{numProjects} (Max:
					{maxProjects} Min: {minProjects} Avg:{" "}
					{Math.round(avgProjects)})
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Trial to Paid (past year):
					</span>{" "}
					{yearNewUsers} / {yearTrialUsers} (
					{Math.round((yearNewUsers / yearTrialUsers) * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>Invoices:</span>{" "}
					{numInvoices} / {numSubs} (
					{Math.round(percentInvoices * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Using Project Dates:
					</span>{" "}
					{numDateProjects} / {numSubs} (
					{Math.round(percentDateProjects * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Using Expense Budgets:
					</span>{" "}
					{numExpenseBudgetedProjects} / {numSubs} (
					{Math.round(percentExpenseBudgetedProjects * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Using Hours Budgets:
					</span>{" "}
					{numHoursBudgetedProjects} / {numSubs} (
					{Math.round(percentHoursBudgetedProjects * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Using Staff Budgets:
					</span>{" "}
					{numStaffBudgetedProjects} / {numSubs} (
					{Math.round(percentStaffBudgetedProjects * 100)}%)
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>MRR:</span> $
					{formatCurrency(mrrTotal)} (A:${formatCurrency(mrrAdmin)} S:
					${formatCurrency(mrrStandard)})
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>ARR:</span> $
					{formatCurrency(mrrTotal * 12)} (A: $
					{formatCurrency(mrrAdmin * 12)} S: $
					{formatCurrency(mrrStandard * 12)})
				</div>
				<div>
					<span style={{ fontWeight: "bold" }}>
						Remaining Payments:
					</span>
					${formatCurrency(remainingPayments)}
				</div>
				<div>
					<button
						onClick={() =>
							getCSV(
								users
									.toJS()
									.filter((u) => {
										let couponId =
											u.subscriptionPeriod === "yearly"
												? u.yearlyCouponId
												: u.monthlyCouponId;
										let coupon =
											this.state.coupons[couponId];
										let discount = coupon
											? coupon.percent_discount
											: 0;
										return discount < 100;
									})
									.map((u) => {
										let adminPlanId =
											u.subscriptionPeriod === "yearly"
												? u.yearlyAdminPlanId
												: u.monthlyAdminPlanId;
										let standardPlanId =
											u.subscriptionPeriod === "yearly"
												? u.yearlyStandardPlanId
												: u.monthlyStandardPlanId;
										let couponId =
											u.subscriptionPeriod === "yearly"
												? u.yearlyCouponId
												: u.monthlyCouponId;
										let adminPlan =
											this.state.plans[adminPlanId];
										let standardPlan =
											this.state.plans[standardPlanId];
										let coupon =
											this.state.coupons[couponId];
										let adminBase = adminPlan?.amount / 100;
										let standardBase =
											standardPlan?.amount / 100;
										let division =
											u.subscriptionPeriod === "yearly"
												? 12
												: adminPlan.interval_count;
										let discount = coupon
											? coupon.percent_discount
											: 0;
										let currency =
											adminPlan?.currency || "aud";
										return {
											id: u.organisationId,
											stripeId: u.stripeId,
											adminUsers: u.adminUsers,
											standardUsers: u.timesheetUsers,
											totalUsers:
												u.adminUsers + u.timesheetUsers,
											activeAdminUsers: u.adminUsers30,
											activeStandardUsers:
												u.timesheetUsers30,
											activeTotalUsers:
												u.adminUsers30 +
												u.timesheetUsers30,
											activeProjects: u.numActiveProjects,
											adminPlanValue: adminBase,
											standardPlanValue: standardBase,
											discount,
											currency,
											subscriptionPeriodMonths: division,
											nextPaymentAmount:
												(adminPlan.amount *
													u.adminUsers +
													standardPlan.amount *
														u.timesheetUsers) *
												(1 - discount / 100),
											nextPaymentDate: u.nextPaymentDate
												? u.nextPaymentDate.format(
														"DD MMMM YYYY"
												  )
												: "-",
											adminMRR:
												(adminBase / division) *
												u.adminUsers *
												(1 - discount / 100),
											standardMRR:
												(standardBase / division) *
												u.timesheetUsers *
												(1 - discount / 100),
											totalMRR:
												((adminBase / division) *
													u.adminUsers +
													(standardBase / division) *
														u.timesheetUsers) *
												(1 - discount / 100),
											trialStart: u.trialStart,
											subscriptionStart:
												u.subscriptionStart,
										};
									})
							)
						}
					>
						CSV
					</button>
					<button
						onClick={() =>
							getCSV(
								users
									.toJS()
									.map((u) => {
										return u.projects.map((p) => ({
											orgId: u.organisationId,
											stripeId: u.stripeId,
											...p,
										}));
									})
									.flat()
							)
						}
					>
						Project CSV
					</button>
				</div>
				{Immutable.List.isList(this.state.users) ? (
					<div style={{ margin: "2em 0" }}>
						<div
							style={{ width: "100%" }}
							className="coincraft-table trial-table"
						>
							<div className="flexbox-container coincraft-table-row header">
								{[...Array(12).keys()].map((i) => {
									const d = moment().add(i, "M");
									return (
										<div
											style={{ width: "8.33%" }}
											className="coincraft-table-cell"
										>
											{d.format("MMM YYYY")}
										</div>
									);
								})}
							</div>
							<div className="flexbox-container coincraft-table-row">
								{[...Array(12).keys()].map((i) => {
									const d = moment().add(i, "M");
									return (
										<div
											style={{ width: "8.33%" }}
											className="coincraft-table-cell"
										>
											{`$${
												monthlyPayments +
												yearlyPayments[d.month()]
											}`}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				) : null}
				Subscriptions:{" "}
				<BooleanValue
					value={this.state.onlySubscribers}
					onChange={() =>
						this.setState({
							onlySubscribers: !this.state.onlySubscribers,
						})
					}
					isEditable={true}
				/>{" "}
				Trialing:{" "}
				<BooleanValue
					value={this.state.trialUsers}
					onChange={() =>
						this.setState({ trialUsers: !this.state.trialUsers })
					}
					isEditable={true}
				/>
				{Immutable.List.isList(this.state.users) ? (
					<div>
						<div
							style={{ width: "255em" }}
							className="coincraft-table trial-table"
						>
							<div className="flexbox-container coincraft-table-row header">
								<div
									style={{ width: "20em" }}
									className="coincraft-table-cell"
								>
									Organisation name
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Name
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Migrate
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Stripe
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Intercom
								</div>
								<div
									style={{ width: "25em" }}
									className="coincraft-table-cell"
								>
									Email
								</div>
								<div
									style={{ width: "8em" }}
									className="coincraft-table-cell"
								>
									Admin Users
								</div>
								<div
									style={{ width: "8em" }}
									className="coincraft-table-cell"
								>
									Timesheet Users
								</div>
								<div
									style={{ width: "6em" }}
									className="coincraft-table-cell"
								>
									Total Users
								</div>
								<div
									style={{ width: "6em" }}
									className="coincraft-table-cell"
								>
									Active Projects
								</div>
								<div
									style={{ width: "6em" }}
									className="coincraft-table-cell"
								>
									Invoicing
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Next Payment Date
								</div>
								<div
									style={{ width: "8em" }}
									className="coincraft-table-cell"
								>
									Next Payment
								</div>
								<div
									style={{ width: "8em" }}
									className="coincraft-table-cell"
								>
									$ Remaining
								</div>
								<div
									style={{ width: "5em" }}
									className="coincraft-table-cell"
								>
									MRR
								</div>
								<div
									style={{ width: "5em" }}
									className="coincraft-table-cell"
								>
									MRR / User
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Unpaid Invoices
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Stripe ID
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Admin Plan
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Standard Plan
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Coupon Code
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Save
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Trial Start
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Trial End
								</div>
								<div
									style={{ width: "15em" }}
									className="coincraft-table-cell"
								>
									Subscription Start
								</div>
								<div
									style={{ width: "10em" }}
									className="coincraft-table-cell"
								>
									Save
								</div>
							</div>
							<div
								style={{ overflowY: "auto", height: "50em" }}
								className="trial-table-body"
							>
								{users.map(function (u, i) {
									return (
										<TrialPageUserRow
											key={u.email}
											user={u}
											plans={self.state.plans}
											coupons={self.state.coupons}
											index={i}
										/>
									);
								})}
							</div>
						</div>
					</div>
				) : this.state.users === null ? (
					<div>Loading...</div>
				) : this.state.users === "error" ? (
					<ErrorAlert className="trial-widget__couldnt-load-data">
						{`Couldn't load trial data`}
					</ErrorAlert>
				) : null}
			</div>
		);
	},
});

var TrialPageUserRow = CreateReactClass({
	propTypes: {
		user: PropTypes.object.isRequired,
		plans: PropTypes.object.isRequired,
		coupons: PropTypes.object.isRequired,
		index: PropTypes.number,
	},

	mixins: [PureRenderMixin],

	getInitialState: function () {
		return {
			user: this.props.user,
			saveState: null, // `null`, 'saved'
		};
	},

	componentWillMount: function () {
		let self = this;
		let user = this.state.user;
		if (user.stripeId) {
			apiRequest({
				url: `/api/v1/trial-user-stripe-data/`,
				method: "get",
				success: (data) => {
					let userObj = self.state.user;
					userObj = userObj.set(
						"stripeAdminUsers",
						data.stripeData["stripeAdminUsers"]
					);
					userObj = userObj.set(
						"stripeTimesheetUsers",
						data.stripeData["stripeTimesheetUsers"]
					);
					self.setState({ user: userObj });
				},
			});
		}
	},

	render: function () {
		let u = this.state.user;
		let adminPlanId =
			u.subscriptionPeriod == "yearly"
				? u.yearlyAdminPlanId
				: u.monthlyAdminPlanId;
		let standardPlanId =
			u.subscriptionPeriod == "yearly"
				? u.yearlyStandardPlanId
				: u.monthlyStandardPlanId;
		let couponId =
			u.subscriptionPeriod == "yearly"
				? u.yearlyCouponId
				: u.monthlyCouponId;
		let adminPlan = this.props.plans[adminPlanId];
		let standardPlan = this.props.plans[standardPlanId];
		let coupon = this.props.coupons[couponId];
		let adminPlanString =
			u.subscriptionPeriod == "yearly"
				? "yearlyAdminPlanId"
				: "monthlyAdminPlanId";
		let standardPlanString =
			u.subscriptionPeriod == "yearly"
				? "yearlyStandardPlanId"
				: "monthlyStandardPlanId";
		let couponString =
			u.subscriptionPeriod == "yearly"
				? "yearlyCouponId"
				: "monthlyCouponId";

		return (
			<div className="flexbox-container coincraft-table-row">
				<div style={{ width: "20em" }} className="coincraft-table-cell">
					{this.props.index + 1 + ". " + u.organisationName} - (
					{u.organisationId})
				</div>
				<div
					style={{ width: "15em" }}
					className="coincraft-table-cell"
				>{`${u.firstName || ""} ${u.lastName || ""}`}</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					<a
						href={`http://localhost:8080/migrate/${u.organisationUuid}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{u.organisationUuid}
					</a>
				</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					<a
						href={`https://dashboard.stripe.com/customers/${encodeURIComponent(
							u.stripeId
						)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Stripe
					</a>
				</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					<a
						href={`https://app.intercom.com/a/apps/k6prha3t/companies/segments/all?searchTerm=${encodeURIComponent(
							u.organisationName
						)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Intercom
					</a>
				</div>
				<div style={{ width: "25em" }} className="coincraft-table-cell">
					<a
						href={`${process.env.REACT_APP_SERVER_URL}/user/impersonate/${u.email}`}
					>
						{u.email}
					</a>
				</div>
				<div style={{ width: "8em" }} className="coincraft-table-cell">
					<div
						className="flexbox-container"
						style={{
							borderBottom: "solid 1px #eee",
							textAlign: "center",
							width: "6em",
						}}
					>
						<div
							className="flex-1-1-auto"
							style={{ borderRight: "solid 1px #eee" }}
						>
							C
						</div>
						<div
							className="flex-1-1-auto"
							style={{ borderRight: "solid 1px #eee" }}
						>
							A
						</div>
					</div>
					<div
						className="flexbox-container"
						style={{ textAlign: "center", width: "6em" }}
					>
						<div
							className="flex-1-1-auto"
							style={{
								borderRight: "solid 1px #eee",
							}}
						>
							{u.adminUsers}
						</div>
						<div
							className="flex-1-1-auto"
							style={{
								borderRight: "solid 1px #eee",
							}}
						>
							{u.adminUsers30}
						</div>
					</div>
				</div>
				<div style={{ width: "8em" }} className="coincraft-table-cell">
					<div
						className="flexbox-container"
						style={{
							borderBottom: "solid 1px #eee",
							textAlign: "center",
							width: "6em",
						}}
					>
						<div
							className="flex-1-1-auto"
							style={{ borderRight: "solid 1px #eee" }}
						>
							C
						</div>
						<div
							className="flex-1-1-auto"
							style={{ borderRight: "solid 1px #eee" }}
						>
							A
						</div>
					</div>
					<div
						className="flexbox-container"
						style={{ textAlign: "center", width: "6em" }}
					>
						<div
							className="flex-1-1-auto"
							style={{
								borderRight: "solid 1px #eee",
							}}
						>
							{u.timesheetUsers}
						</div>
						<div
							className="flex-1-1-auto"
							style={{
								borderRight: "solid 1px #eee",
							}}
						>
							{u.timesheetUsers30}
						</div>
					</div>
				</div>
				<div
					className="coincraft-table-cell"
					style={{
						width: "6em",
						color:
							u.adminUsers30 + u.timesheetUsers30 === 0
								? "#d00000"
								: "inherit",
					}}
				>
					{u.adminUsers + u.timesheetUsers} (
					{u.adminUsers30 + u.timesheetUsers30})
				</div>
				<div style={{ width: "6em" }} className="coincraft-table-cell">
					{u.numActiveProjects}
				</div>
				<div
					style={{
						width: "6em",
					}}
					className="coincraft-table-cell"
				>
					{u.invoicing ? "True" : "False"}
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					{u.nextPaymentDate
						? u.nextPaymentDate.format("DD MMMM YYYY")
						: "-"}
				</div>

				<div style={{ width: "8em" }} className="coincraft-table-cell">
					$
					{formatCurrency(
						adminPlan && standardPlan
							? ((u.adminUsers * adminPlan.amount +
									u.timesheetUsers * standardPlan.amount) /
									100) *
									(1 -
										(coupon
											? coupon.percent_discount / 100
											: 0))
							: 0
					)}
				</div>
				<div style={{ width: "8em" }} className="coincraft-table-cell">
					$
					{formatCurrency(
						adminPlan && standardPlan
							? ((u.adminUsers * adminPlan.amount +
									u.timesheetUsers * standardPlan.amount) /
									100) *
									(1 -
										(coupon
											? coupon.percent_discount / 100
											: 0)) *
									(u.nextPaymentDate
										? u.nextPaymentDate.diff(
												moment(),
												"days"
										  ) /
										  u.nextPaymentDate.diff(
												u.nextPaymentDate
													.clone()
													.subtract(
														u.subscriptionPeriod ==
															"yearly"
															? 12
															: adminPlan.interval_count,
														"months"
													),
												"days"
										  )
										: 0)
							: 0
					)}
				</div>
				<div style={{ width: "5em" }} className="coincraft-table-cell">
					$
					{formatCurrency(
						adminPlan && standardPlan
							? ((u.adminUsers * adminPlan.amount +
									u.timesheetUsers * standardPlan.amount) /
									100 /
									(u.subscriptionPeriod == "yearly"
										? 12
										: adminPlan.interval_count)) *
									(1 -
										(coupon
											? coupon.percent_discount / 100
											: 0))
							: 0
					)}
				</div>
				<div style={{ width: "5em" }} className="coincraft-table-cell">
					$
					{formatCurrency(
						adminPlan && standardPlan
							? Math.round(
									(((u.adminUsers * adminPlan.amount +
										u.timesheetUsers *
											standardPlan.amount) /
										100 /
										(u.subscriptionPeriod == "yearly"
											? 12
											: adminPlan.interval_count)) *
										(1 -
											(coupon
												? coupon.percent_discount / 100
												: 0))) /
										(u.adminUsers + u.timesheetUsers)
							  )
							: 0
					)}
				</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					{sum(u.unpaidInvoices.map((inv) => inv.amount))}
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					{u.stripeId}
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<input
						type="text"
						value={adminPlanId}
						onChange={(e) =>
							this._setField(adminPlanString, e.target.value)
						}
					/>
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<input
						type="text"
						value={standardPlanId}
						onChange={(e) =>
							this._setField(standardPlanString, e.target.value)
						}
					/>
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<input
						type="text"
						value={couponId}
						onChange={(e) =>
							this._setField(couponString, e.target.value)
						}
					/>
				</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					<button
						className="btn btn-default trial-widget__row__save-button"
						onClick={this.handleSaveClick}
					>
						Save
					</button>

					{this.state.saveState === "saved" ? (
						<div
							className="trial-widget__save-success-indicator"
							style={{
								display: "inline-block",
								height: "100%",
								marginLeft: 8,
								color: "#3c763d",
								backgroundColor: "#dff0d8",
							}}
						>
							<i className="fa fa-check" />
						</div>
					) : null}
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<DateValue
						value={u.trialStart}
						onChange={(date) => this._setField("trialStart", date)}
						isEditable={true}
						className="trial-widget__row__started-trial-on-box"
					/>
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<DateValue
						value={u.trialEnd}
						onChange={(date) => this._setField("trialEnd", date)}
						isEditable={true}
					/>
				</div>
				<div style={{ width: "15em" }} className="coincraft-table-cell">
					<DateValue
						value={u.subscriptionStart}
						onChange={(date) =>
							this._setField("subscriptionStart", date)
						}
						isEditable={true}
					/>
				</div>
				<div style={{ width: "10em" }} className="coincraft-table-cell">
					<button
						className="btn btn-default trial-widget__row__save-button"
						onClick={this.handleSaveClick}
					>
						Save
					</button>

					{this.state.saveState === "saved" ? (
						<div
							className="trial-widget__save-success-indicator"
							style={{
								display: "inline-block",
								height: "100%",
								marginLeft: 8,
								color: "#3c763d",
								backgroundColor: "#dff0d8",
							}}
						>
							<i className="fa fa-check" />
						</div>
					) : null}
				</div>
			</div>
		);
	},

	_setField: function (fieldName, value) {
		this.setState({ user: this.state.user.set(fieldName, value) });
	},

	_setNumberField: function (fieldName, value) {
		this._setField(fieldName, value !== "" ? parseInt(value) : null);
	},

	handleUseStripeChange: function (s) {
		this.setState({ user: this.state.user.set("useStripe", s) });
	},

	handleSaveClick: function () {
		let self = this;
		let user = this.state.user;

		apiRequest({
			url: `/api/v1/trial/${user.organisationId}`,
			method: "post",
			data: user.serialize(),
			success: (data) => {
				self.setState({ saveState: "saved" });
				setTimeout(function () {
					self.setState({ saveState: null });
				}, 2000);
			},
			error: (data) => alert("fail"),
		});
	},
});

let download = function (content, fileName, mimeType) {
	let a = document.createElement("a");
	mimeType = mimeType || "application/octet-stream";

	if (navigator.msSaveBlob) {
		// IE10
		navigator.msSaveBlob(
			new Blob([content], {
				type: mimeType,
			}),
			fileName
		);
	} else if (URL && "download" in a) {
		//html5 A[download]
		a.href = URL.createObjectURL(
			new Blob([content], {
				type: mimeType,
			})
		);
		a.setAttribute("download", fileName);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	} else {
		window.location.href =
			"data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
	}
};

const getCSV = (subs) => {
	let csvContent = Papa.unparse(subs);
	download(csvContent, "subs.csv", "text/csv;encoding:utf-8");
};
