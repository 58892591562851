import _ from "underscore";
import { organisationStore } from "../organisation.js";
import { makeObservable, observable, computed, action } from "mobx";
import apiRequest from "../apiRequest";

const settingDefaults = {
	allowNoPhase: true,
	useTasks: true,
	allowAfterPhaseEnd: true,
	timeEntryAllocations: ["noAllocations", "budgets", "allocations"],
	timeEntryStatus: ["active", "prospective", "onHold", "archived"],
	timeEntryFlags: ["billable", "variation", "overtime"],
	autoPopulate: [], // ["budgets", "allocations"]
	updateHoursFromRevenue: false,
	updateRevenueFromHours: false,
	autoUpdateRevenue: {
		action: "ask", // "automatic", "never"
		adjustOnLoad: false,
		budget: "remaining",
		start: "now",
		end: "endDate",
	},
	autoUpdateHours: {
		action: "ask", // "automatic", "never"
		adjustOnLoad: false,
		budget: "remaining",
		start: "now",
		end: "endDate",
	},
	savingInvoices: ["markTimeInvoiced"], // "lockTime", "automatic"
};

class SettingsStore {
	@observable _settings = {};
	@observable isDirty = false;
	@observable saveState = null;
	constructor() {
		makeObservable(this);
		Object.keys(settingDefaults).forEach((k) => {
			if (!this.settings[k]) {
				this.changeSetting(k, settingDefaults[k]);
			}
		});
		this.isDirty = false;
	}

	get organisation() {
		return organisationStore.organisation;
	}

	@computed
	get settings() {
		return this.organisation
			? { ...this._settings, ...this.organisation.settings }
			: this._settings;
	}

	set settings(settings) {
		this.organisation && (this.organisation.settings = settings);
		this._settings = settings;
	}

	@action
	changeSetting(name, value) {
		let settings = { ...this.settings };
		settings[name] = value;
		this.settings = { ...settings };
		this.isDirty = true;
	}

	@action
	save() {
		this.saveState = "saving";
		apiRequest({
			path: `/organisation/${organisationStore.id}/settings/`,
			method: "post",
			data: {
				settings: this.settings,
			},
			success: (data) => {
				this.isDirty = false;
				this.saveState = null;
			},
		});
	}
}

export var settingsStore = new SettingsStore();
