import _ from "underscore";
import { StoreBase, handleAction } from "./coincraftFlux.js";
import RSVP from "rsvp";
import {
	compareMoments,
	rangeIntersection,
	getAllSubintervals,
	sum,
	imap,
	ifilter,
	iterDict,
	deepLookup,
} from "./utils.js";
import { StaffMember } from "./models/staffmember.js";
import { OrganisationHoliday } from "./models/organisationHoliday.js";
import { DescriptionTemplate } from "./models/descriptionTemplate.js";
import { RevenueForecastReport } from "./models/revenueForecastReport.js";
import { ResourceScheduleReport } from "./models/resourceScheduleReport.js";
import { StaffRole } from "./models/staffRole.js";
import { actions, organisationStore } from "./organisation.js";
import { PermissionLevel, permissions } from "./models/permissions.js";
import { router } from "./router.js";
import { ConnectionState } from "./invoices/ConnectionState.js";
import * as modalTypes from "./modalTypes.js";
import Immutable from "immutable";
import xspans from "xspans";
import { BusinessCategory } from "./models/businesscategory.js";
import { ProjectPhase } from "./models/projectphase.js";
import { Expense } from "./models/expense.js";
import { Project } from "./models/project.js";
import { Organisation } from "./models/organisation.js";
import { Contact } from "./models/Contact.js";
import { dateConverter } from "./models/dateconverter.js";
import { RangeAllocation } from "./models/rangeallocation.js";
import { NoPhasePhase } from "./models/nophasephase.js";
import moment from "moment";
import { getStaffExpensesInRange } from "./models/getStaffExpensesInRange.js";
import { DateRange } from "./models/daterange.js";
import { TimeSeries } from "./models/timeseries.js";
import { CashFlowItem } from "./models/cashflowitem.js";
import apiRequest, { chainRequests } from "./apiRequest.js";

export const OrganisationStore = class extends StoreBase {
	constructor() {
		super();
		this._reset();
		this.modals = [];
	}

	_reset() {
		this.isReady = false;
		this.isRefreshing = false;

		this.typeNames = [
			"BusinessCategory",
			"Project",
			"ProjectPhase",
			"StaffMember",
			"StaffRole",
			"Expense",
			"Organisation",
			"Contact",
			"Invoice",
			"Report",
			"OrganisationHoliday",
			"DescriptionTemplate",
			"RevenueForecastReport",
			"ResourceScheduleReport",
		];

		this.objects = {};
		for (let typeName of this.typeNames) {
			this.objects[typeName] = {
				list: [],
				lookup: {},
			};
		}

		this.staffMembers = this.objects.StaffMember.list;
		this.staffRoles = this.objects.StaffRole.list;
		this.costCentres = this.objects.BusinessCategory.list;
		this.projects = this.objects.Project.list;
		this.expenses = this.objects.Expense.list;
		this.contacts = this.objects.Contact.list;
		this.reports = this.objects.Report.list;
		this.invoices = this.objects.Invoice.list;
		this.organisationHolidays = this.objects.OrganisationHoliday.list;
		this.descriptionTemplates = this.objects.DescriptionTemplate.list;
		this.revenueForecastReports = this.objects.RevenueForecastReport.list;
		this.resourceScheduleReports = this.objects.ResourceScheduleReport.list;

		this.genericStaffMember = new StaffMember({
			id: -1,
			minutesPerWeek: 0,
			payRate: 0,
			overtimeRate: 0,
			chargeOutRate: 0,
			weeklyAvailability: 0,
		});
		this.genericStaffMember.getFullName = function () {
			return "(No staff member)";
		};
	}

	_initialize(data) {
		this._addObjects(data.objects);

		this.organisation = this.objects.Organisation.list[0];

		// Bunch of semi-hacky cleanups for existing data for milestones stuff.
		this.projects.forEach(function (project) {
			project.mergeChangeLog();
		});

		this.editingProject = null;
		this.projectPageOpen = null;

		// {object: object, error: error (eg. string)}
		this.deleteError = null;

		this.deletedObjects = {};

		this.isReady = true;
		this.isRefreshing = false;

		// State for the "Invoice settings" popup.
		this.invoiceSettingsState = {
			// null (not initialised yet), 'none', 'xero', or 'quickbooks'.
			accountingSystemId: null,
			accountingSystem: null,
			accountingSystemSettings: null,
			numDaysBetweenIssueDateAndDueDate: null,
			invoiceCode: null,
		};

		this.organisationHolidays = this.organisationHolidays.sort((a, b) =>
			b.startDate.diff(a.endDate)
		);
	}

	handle(action) {
		handleAction(action, this);
	}

	_addAndSetupObjects(data) {
		/**
		 * For tests
		 */
		this._addObjects(data.objects);
		for (let p of this.projects) {
			p.mergeChangeLog();
		}
	}

	initializeOrganisation(data) {
		this._initialize(data);
		this.emitChanged();
	}

	refreshOrganisation() {
		let self = this;
		this.isRefreshing = true;
		this.emitChanged();
		apiRequest({
			path: "/user/status",
			method: "get",
			data: { noCache: new Date().getTime() },
			success: (data) => {
				self.initializeOrganisation(data);
			},
		});
	}

	emitChanged() {
		this.id = this.objects.Organisation?.list[0]?.id;
		this.name = this.objects.Organisation?.list[0]?.name;
		this.startedTrialOn =
			this.objects.Organisation?.list[0]?.startedTrialOn;
		this.hasSubscription =
			this.objects.Organisation?.list[0]?.hasSubscription;
		super.emitChanged();
	}

	_addObjects(newObjects = {}) {
		let self = this;

		// Avoid circular import
		let Invoice = require("./invoices.js").Invoice;
		let reportModule = require("./reports/Report.js");
		let Report = reportModule.Report;

		let types = {
			BusinessCategory: BusinessCategory,
			StaffMember: StaffMember,
			StaffRole: StaffRole,
			ProjectPhase: ProjectPhase,
			Project: Project,
			Expense: Expense,
			Organisation: Organisation,
			Contact: Contact,
			Invoice: Invoice,
			Report: Report,
			OrganisationHoliday: OrganisationHoliday,
			DescriptionTemplate: DescriptionTemplate,
			RevenueForecastReport: RevenueForecastReport,
			ResourceScheduleReport: ResourceScheduleReport,
		};

		let newObjectLists = {};
		this.typeNames.forEach(function (typeName) {
			newObjectLists[typeName] = [];
		});

		this.typeNames.forEach(function (typeName) {
			let klass = types[typeName];
			if (newObjects[typeName] != null) {
				newObjects[typeName].forEach(function (objectData) {
					if (objectData.isDeleted !== true) {
						newObjectLists[typeName].push(
							self._setObject(klass, objectData)
						);
					}
				});
			}
		});

		_.each(newObjectLists.Project, function (project) {
			self._setupProject(project);
		});

		_.each(newObjectLists.ProjectPhase, function (phase) {
			var project = self.objects.Project.lookup[phase.projectId];
			phase.project = project;
			if (_.find(project.phases, (p) => p.uuid === phase.uuid) == null) {
				project.phases.push(phase);
			}
			if (phase.startDate != null) {
				phase.startDate = dateConverter.momentToInt(phase.startDate);
			}
			if (phase.endDate != null) {
				phase.endDate = dateConverter.momentToInt(phase.endDate);
			}

			phase.setupMilestones();

			phase.allocations = phase.allocations
				.filter((a) =>
					a.staffMemberId
						? !self.getStaffMemberById(a.staffMemberId).isArchived
						: a.staffRoleId
				)
				.map(function (allocationData) {
					return new RangeAllocation({
						startDate: allocationData.startDate,
						endDate: allocationData.endDate,
						staffMember: self.getStaffMemberById(
							allocationData.staffMemberId
						),
						staffRole: self.getStaffRoleById(
							allocationData.staffRoleId
						),
						hours: allocationData.hours,
						phase: phase,
					});
				});
			phase.staffMemberBudgetedHours.forEach((b) => (b.phase = phase));
			phase.staffRoleBudgetedHours.forEach((b) => (b.phase = phase));
			phase.mergeAllocations();

			delete phase.projectId;
		});

		for (let pp of newObjectLists.ProjectPhase) {
			pp.staffMemberBudgetedHours = pp.staffMemberBudgetedHours
				.filter((b) => this.getStaffMemberById(b.staffMember))
				.map((b) => ({
					staffMember: this.getStaffMemberById(b.staffMember),
					hours: b.hours,
					phase: pp,
				}));
			pp.staffRoleBudgetedHours = pp.staffRoleBudgetedHours
				.filter((b) => this.getStaffRoleById(b.staffRole))
				.map((b) => ({
					staffRole: this.getStaffRoleById(b.staffRole),
					hours: b.hours,
					phase: pp,
				}));
			//there may be discrepencies due to changes in staff rates
			// if (
			// 	pp.fee &&
			// 	pp.feeLinked &&
			// 	pp.getTotalChargeOutFromStaffBudgets()
			// ) {
			// 	pp.updateStaffHoursBudgetsFromPhaseFee();
			// } else if (
			// 	pp.manualBudget &&
			// 	pp.getTotalExpenseFromStaffBudgets()
			// ) {
			// 	pp.updateStaffHoursBudgetsFromPhaseExpenseBudget();
			// }
		}
	}

	openModal(modal) {
		this.modals = [...this.modals, modal];
		this.emitChanged();
	}

	closeModalByType(type) {
		this.modals = this.modals.filter((m) => m.type !== type);
		this.emitChanged();
	}

	_deleteObject(objectTypeName, objectId) {
		let o = this.objects[objectTypeName];
		if (o.lookup[objectId] != null) {
			delete o.lookup[objectId];
			o.list.splice(
				_.findIndex(o.list, (ob) => ob.id === objectId),
				1
			);
		}
		this.emitChanged();
	}

	_deleteObjectByUuid(objectTypeName, uuid) {
		let objects = this.objects[objectTypeName];
		let ix = _.findIndex(objects.list, (ob) => ob.uuid === uuid);
		if (ix > 0) {
			let ob = objects.list[ix];
			delete objects.lookup[ob.id];
			objects.list.splice(ix, 1);
		}
		this.emitChanged();
	}

	_deleteObjects(deletedItems) {
		/**
		 * deletedItems: {itemTypeName::str : [id::int]}
		 */
		var self = this;
		_.each(deletedItems, function (itemIds, itemTypeName) {
			var o = self.objects[itemTypeName];
			itemIds.forEach(function (id) {
				if (o.lookup[id] != null) {
					delete o.lookup[id];
					o.list.splice(
						_.findIndex(o.list, (ob) => ob.id === id),
						1
					);
				}
			});
		});
	}

	_makeObject(objectClass, objectData) {
		return new objectClass(objectClass.transformArgs(objectData));
	}

	_setObject(klass, objectData) {
		let typeName = klass.getClassName();
		let obs = this.objects[typeName];
		if (typeName === "Invoice") {
			const invoicesModule = require("./invoices.js");
			const Invoice = invoicesModule.Invoice;
			const inv = Invoice.fromJson(objectData);
			this.setObjectById("Invoice", inv, { emitChanged: false });
			return inv;
		} else if (typeName === "StaffMember") {
			const sm = StaffMember.fromJson(objectData);
			this.setObjectById("StaffMember", sm, { emitChanged: false });
			return sm;
		} else if (typeName === "StaffRole") {
			const sr = StaffRole.fromJson(objectData);
			this.setObjectById("StaffRole", sr, { emitChanged: false });
			return sr;
		} else if (typeName === "RevenueForecastReport") {
			const sr = RevenueForecastReport.fromJson(objectData);
			this.setObjectById("RevenueForecastReport", sr, {
				emitChanged: false,
			});
			return sr;
		} else if (typeName === "ResourceScheduleReport") {
			const sr = ResourceScheduleReport.fromJson(objectData);
			this.setObjectById("ResourceScheduleReport", sr, {
				emitChanged: false,
			});
			return sr;
		} else if (obs.lookup[objectData.id] == null) {
			const ob = this._makeObject(klass, objectData);
			this.setObjectById(typeName, ob, { emitChanged: false });
			return ob;
		} else {
			if (typeName === "Report") {
				// Reports are immutable
				let index = _.findIndex(
					this.objects.Report.list,
					(r) => r.uuid === objectData.uuid
				);
				let newOb = new klass(klass.transformArgs(objectData, this));
				this.objects.Report.list[index] = newOb;
				this.objects.Report.lookup[newOb.id] = newOb;
				return newOb;
			} else {
				let existingObject = obs.lookup[objectData.id];
				_.each(klass.transformArgs(objectData, this), function (v, k) {
					existingObject[k] = v;
				});
				return existingObject;
			}
		}
	}

	_setupProject(project) {
		let self = this;
		if (project.costCentreId == null) {
			throw new Error("Project must have a costCentreId");
		}

		if (project.contactId !== undefined) {
			// Remember `null` is a valid contact; if we edited and saved a project
			// setting the contact back to null, make sure the object reflects that
			// change.
			if (project.contactId != null) {
				project.contact =
					this.objects.Contact.lookup[project.contactId];
			} else {
				project.contact = null;
			}
			// delete project.contactId;
		}

		if (project.invoiceContactId !== undefined) {
			// Remember `null` is a valid contact; if we edited and saved a project
			// setting the contact back to null, make sure the object reflects that
			// change.
			if (project.invoiceContactId != null) {
				project.invoiceContact =
					this.objects.Contact.lookup[project.invoiceContactId];
			} else {
				project.invoiceContact = null;
			}
			// delete project.invoiceContactId;
		}

		project.changeLog = project.changeLog.map(function (cli) {
			cli = cli.setProject(project);
			if (cli.phaseId != null) {
				cli = cli.setPhase(
					self.getProjectPhaseById(cli.phaseId) ||
						self.getProjectPhaseByUuid(cli.phaseId)
				);
			}
			return cli;
		});
	}

	getCostCentreById(id) {
		return id != null ? this.objects.BusinessCategory.lookup[id] : null;
	}

	getProjectById(id) {
		return id != null ? this.objects.Project.lookup[id] : null;
	}

	getProjectByName(name) {
		return _.find(this.projects, (p) => p.name === name);
	}

	getProjectPhaseById(id) {
		if (id === -1) {
			return new NoPhasePhase();
		}
		return id != null ? this.objects.ProjectPhase.lookup[id] : null;
	}

	getProjectPhaseByName(name) {
		return _.find(this.objects.ProjectPhase.list, (p) => p.name === name);
	}

	getProjectPhaseByUuid(uuid) {
		return _.find(this.objects.ProjectPhase.list, (p) => p.uuid === uuid);
	}

	getProjectPhaseByNames(projectName, phaseName) {
		return _.find(
			this.getProjectByName(projectName).phases,
			(p) => p.name === phaseName
		);
	}

	getStaffMemberById(id) {
		if (id === -1) {
			return this.genericStaffMember;
		} else {
			return id != null ? this.objects.StaffMember.lookup[id] : null;
		}
	}

	getExpenseById(id) {
		return id != null ? this.objects.Expense.lookup[id] : null;
	}

	getStaffMembersByRoleId(roleId) {
		return roleId != null
			? this.staffMembers.filter((sm) => sm.roleId === roleId)
			: [];
	}

	getStaffRoleById(id) {
		if (id === -1) {
			return null;
		} else {
			return id != null ? this.objects.StaffRole.lookup[id] : null;
		}
	}

	getInvoiceById(id) {
		return id != null ? this.objects.Invoice.lookup[id] : null;
	}

	getContactById(id) {
		return id != null ? this.objects.Contact.lookup[id] : null;
	}

	getSavedProjects() {
		return this.projects.filter((p) => p.isSaved);
	}

	getUnarchivedProjects() {
		return this.projects.filter((p) => !p.isArchived);
	}

	getSelectableProjects() {
		return this.projects.filter((p) => p.isSelectable);
	}

	getSortedProjects() {
		return this.getSelectableProjects().sort((a, b) =>
			a.getTitle().localeCompare(b.getTitle())
		);
	}

	getProjectsByCostCentre(costCentre) {
		return this.projects.filter((p) => p.costCentre.id === costCentre.id);
	}

	getVisibleProjectsByCostCentre(costCentre) {
		return this.projects.filter(
			(p) => p.costCentre.id === costCentre.id && !p.isArchived
		);
	}

	getVisibleCostCentres() {
		return Immutable.OrderedSet(
			imap(
				ifilter(this.projects, (p) => !p.isArchived),
				(p) => p.costCentre
			)
		).toJS();
	}

	getCurrentAccountingSystem() {
		return this.getAccountingSystemByIdentifier(
			this.organisation.accountingSystem
		);
	}

	getAccountingSystemByIdentifier(identifier) {
		return this.organisation.getAccountingSystemByIdentifier(identifier);
	}

	getVisibleProjects(matchesSearch = null) {
		var projects = this.getUnarchivedProjects();
		if (matchesSearch != null && matchesSearch !== "") {
			projects = projects.filter((p) => p.matchesSearch(matchesSearch));
		}
		return projects.sort((a, b) =>
			a.getTitle().localeCompare(b.getTitle())
		);
	}

	getUnarchivedStaff() {
		return this.staffMembers.filter((sm) => !sm.isArchived);
	}

	getVisibleStaff(matchesSearch = null) {
		var staff = this.getUnarchivedStaff();
		if (matchesSearch != null && matchesSearch !== "") {
			staff = staff.filter((sm) => sm.matchesSearch(matchesSearch));
		}
		return staff.sort((a, b) => a.lastName.localeCompare(b.lastName));
	}

	getHolidaysXspans() {
		return xspans.or(this.organisationHolidays.map((oh) => oh.xspan.data));
	}

	getAllocations() {
		let allocations = [];
		this.objects.ProjectPhase.list.forEach((pp) =>
			allocations.push(...pp.allocations)
		);
		return allocations;
	}

	getAllocationsByStaffRole(staffRole) {
		let allocations = [];
		this.objects.ProjectPhase.list.forEach((pp) => {
			allocations = [
				...allocations,
				...pp.allocations.filter(
					(a) => a.staffRole.id === staffRole.id
				),
			];
		});
		return allocations;
	}

	getAllocationsByStaffRole(staffMember) {
		let allocations = [];
		this.objects.ProjectPhase.list.forEach((pp) => {
			allocations = [
				...allocations,
				...pp.allocations.filter(
					(a) => a.staffMember.id === staffMember.id
				),
			];
		});
		return allocations;
	}

	_save(url, dataProp, items) {
		return new RSVP.Promise(function (resolve, reject) {
			var data = {};
			data[dataProp] = items;
			apiRequest({
				url: url,
				method: "post",
				data: data,
				success: (data) => resolve(data),
				error: (data) => reject(data),
			});
		});
	}

	saveStaffMemberSuccess(staffMemberData) {
		this._addObjects({ StaffMember: [staffMemberData] });
		this.emitChanged();
	}

	saveUserDetailsSuccess(data) {
		this.setObjectById("StaffMember", data.userData);
		window.location = "/";
	}

	saveProject(project, updateProjectData, serializedProject) {
		var self = this;
		return new RSVP.Promise(function (resolve, reject) {
			const isNew = project.id == null;

			if (isNew) {
				project.setupDefaultTasks();
			}

			self._save(
				`/organisation/${self.id}/project/${project.id || ""}`,
				"project",
				serializedProject || project.serialize()
			).then(function (data) {
				try {
					if (updateProjectData) {
						self._addObjects(data.objects);
					}

					if (updateProjectData && data.phaseUuidToIdLookup != null) {
						_.each(data.phaseUuidToIdLookup, function (id, uuid) {
							let phase = _.find(
								project.phases,
								(p) => p.uuid === uuid
							);
							if (phase != null) {
								phase.id = id;
							}
						});
					}

					let organisationProject = self.getProjectById(
						data.objects.Project[0].id
					);
					if (updateProjectData) {
						organisationProject.mergeChangeLog();
					}

					resolve({
						project: organisationProject,
						isNew: isNew,
					});
					self.emitChanged();
				} catch (e) {
					reject(e);
				}
			});
		});
	}

	saveExpenses(expenses) {
		var self = this;
		return this._save(
			`/organisation/${this.id}/expenses/`,
			"expenses",
			expenses.map((e) => e.serialize())
		).then(function (data) {
			try {
				self._addObjects(data.objects);
				self._deleteObjects(data.deletedItems);
				self.emitChanged();
				return data;
			} catch (e) {
				console.error(e);
			}
		});
	}

	_deleteObjectFromServer(object, success, error) {
		return apiRequest({
			url: `/organisation/${this.id}/${object.apiTypeName()}/${
				object.id
			}`,
			method: "delete",
			success,
			error,
		});
	}

	deleteObject(object) {
		this._deleteObjectFromServer(
			object,
			function (responseData) {
				if (responseData.status === "ok") {
					actions.deleteObjectSuccess(responseData);
				} else {
					actions.deleteObjectFailure(object, responseData);
				}
			},
			function (e) {
				actions.deleteObjectFailure(object, e);
			}
		);
	}

	deleteObjectSuccess(responseData) {
		var self = this;
		_.each(responseData.delete, function (ids, objectType) {
			if (self.deletedObjects[objectType] == null) {
				self.deletedObjects[objectType] = {};
			}
			ids.forEach(function (id) {
				delete self.objects[objectType].lookup[id];
				var ix = _.findIndex(
					self.objects[objectType].list,
					function (ob) {
						return ob.id === id;
					}
				);
				self.objects[objectType].list.splice(ix, 1);
				self.deletedObjects[objectType][id] = true;
			});

			const lookup = {
				Project: ["/dashboard/project/", "/dashboard/project-deleted"],
				StaffMember: ["/dashboard/staff/", "/dashboard/staff-deleted"],
				BusinessCategory: [
					"/dashboard/cost-centres/",
					"/dashboard/cost-centre-deleted",
				],
				StaffRole: [
					"/dashboard/staff-roles/",
					"/dashboard/staff-roles",
				],
			};
			if (lookup[objectType] != null) {
				const [matchPath, deletedPagePath] = lookup[objectType];
				if (window.location.pathname.match(matchPath)) {
					router.history.replace({ pathname: deletedPagePath });
				}
			}
		});
		self.emitChanged();
	}

	getDeleteError(object) {
		if (
			this.deleteError != null &&
			this.deleteError.object.refersToSameObject(object)
		) {
			return this.deleteError.error;
		} else {
			return null;
		}
	}

	hasDeletedObject(ob) {
		/**
		 * `ob` is a `DataObject` or `null` (we care about `.getClassName()` and `.id`).
		 * If `ob` is null we return `false`.
		 */
		if (ob == null || ob.id == null) {
			return false;
		}
		let className = ob.constructor.getClassName();
		return (
			this.deletedObjects[className] != null &&
			this.deletedObjects[className][ob.id] != null
		);
	}

	deleteObjectFailure(object, error) {
		this.deleteError = {
			object: object,
			error: error,
		};
		this.emitChanged();
	}

	dismissDeleteError() {
		this.deleteError = null;
		this.emitChanged();
	}

	addCustomer(options) {
		var self = this;
		return new RSVP.Promise(function (resolve, reject) {
			apiRequest({
				url: `/add-customer`,
				method: "post",
				data: options,
				success: (data) => {
					self.hasSubscription = true;
					self.organisation.hasSubscription = true;
					self.organisation.credit = data.organisation.credit;
					self.organisation.receiptEmail =
						data.organisation.receiptEmail;
					self.organisation.cardBrand = data.organisation.cardBrand;
					self.organisation.cardLast4 = data.organisation.cardLast4;
					self.organisation.cardExpDate = data.organisation
						.cardExpDate
						? moment(data.organisation.cardExpDate)
						: null;
					self.organisation.subscriptionPeriod =
						data.organisation.subscriptionPeriod;
					self.organisation.nextPaymentDate = data.organisation
						.nextPaymentDate
						? moment(data.organisation.nextPaymentDate)
						: null;
					self.emitChanged();
					resolve(data);
					//lazy but it works
					window.location.reload();
				},
				error: (data) => {
					reject(data);
				},
			});
		});
	}

	updateCard(options) {
		var self = this;
		return new RSVP.Promise(function (resolve, reject) {
			apiRequest({
				url: `/update-card`,
				method: "post",
				data: options,
				success: (data) => {
					let org = self.objects.Organisation.list[0];
					org.cardBrand = data.card.cardBrand;
					org.cardLast4 = data.card.cardLast4;
					org.cardExpDate = moment(
						data.card.cardExpDate,
						"YYYY-MM-DD"
					);
					resolve(data);
				},
				error: (data) => reject(data),
			});
		});
	}

	updateReceiptEmail(options) {
		var self = this;
		return new RSVP.Promise(function (resolve, reject) {
			apiRequest({
				url: `/update-receipt-email`,
				method: "post",
				data: options,
				success: (data) => resolve(data),
				error: (data) => reject(data),
			});
		});
	}

	payInvoice(options) {
		var self = this;
		return new RSVP.Promise(function (resolve, reject) {
			apiRequest({
				url: `/pay-invoice`,
				method: "post",
				data: options,
				success: (data) => {
					let org = self.objects.Organisation.list[0];
					org.unpaidInvoices = data.organisation.unpaidInvoices;
					resolve(data);
				},
				error: (data) => reject(data),
			});
		});
	}

	invoiceSaveSuccess(invoice) {
		var ix = _.findIndex(
			this.objects.Invoice.list,
			(inv) => inv.id === invoice.id
		);
		if (ix !== -1) {
			this.objects.Invoice.list[ix] = invoice;
		} else {
			this.objects.Invoice.list.push(invoice);
		}
		this.objects.Invoice.lookup[invoice.id] = invoice;
	}

	invoiceDeleteSuccess(invoice) {
		var ix = _.findIndex(
			this.objects.Invoice.list,
			(inv) => inv.id === invoice.id
		);
		if (ix !== -1) {
			this.objects.Invoice.list.splice(ix, 1);
		}
		delete this.objects.Invoice.lookup[invoice.id];

		this.emitChanged();
	}

	updatePhaseTimesheetTotals({ objects }) {
		let self = this;
		objects.Phase.forEach(function (phase) {
			self.getProjectPhaseById(phase.id).staffMinutesLoggedToDate =
				phase.staffMinutesLoggedToDate;
		});
	}

	setObjectById(objectType, object, { emitChanged = true } = {}) {
		this._setObjectBy(objectType, object, (o) => o.id === object.id, {
			emitChanged,
		});
	}

	updateObjectById(
		objectType,
		objectId,
		updateFunc,
		{ emitChanged = true } = {}
	) {
		const matchFunc = (o) => o.id === objectId;
		let objects = this.objects[objectType];
		const ix = _.findIndex(objects.list, matchFunc);
		if (ix !== -1) {
			this._setObjectBy(
				objectType,
				updateFunc(objects.list[ix]),
				matchFunc,
				{ emitChanged: emitChanged }
			);
		} else {
			throw new Error("updateObjectById requires object to exist");
		}
	}

	setObjectByUuid(objectType, object) {
		this._setObjectBy(objectType, object, (o) => o.uuid === object.uuid);
	}

	_setObjectBy(objectType, object, matchFunc, { emitChanged = true } = {}) {
		let objects = this.objects[objectType];
		const ix = _.findIndex(objects.list, (o) => matchFunc(o, object));
		if (ix !== -1) {
			objects.list[ix] = object;
		} else {
			objects.list.push(object);
		}
		objects.lookup[object.id] = object;
		if (emitChanged) {
			this.emitChanged();
		}
	}

	contactSaveSuccess(contact) {
		var ix = _.findIndex(
			this.objects.Contact.list,
			(c) => c.id === contact.id
		);
		if (ix !== -1) {
			this.objects.Contact.list[ix] = contact;
		} else {
			this.objects.Contact.list.push(contact);
		}
		this.objects.Contact.lookup[contact.id] = contact;
		this.emitChanged();
	}

	newProject() {
		router.history.push({ pathname: "/dashboard/project/new" });
	}

	newStaffMember(staffMember) {
		router.history.push({ pathname: "/dashboard/staff/new" });
	}

	editStaffMember(staffMemberId) {
		router.history.push({ pathname: `/dashboard/staff/${staffMemberId}` });
	}

	getStaffMonthlySpend(projects, startDate, endDate) {
		// Work out the staff's monthly spend by multiplying the daily rate by the
		// number of working days in the month.

		let startDateInt = dateConverter.momentToInt(startDate);
		let endDateInt = dateConverter.momentToInt(endDate);

		let monthDates = [];
		new DateRange(startDate, endDate, "month", 1).dates(function (d) {
			monthDates.push(dateConverter.momentToInt(d));
		});

		let ts = new TimeSeries(() => 0);

		monthDates.forEach((d) => {
			let monthStart = dateConverter.startOfMonthOffset(d);
			let monthEnd = dateConverter.endOfMonthOffset(d);
			ts.modifyItemByKey(
				monthStart,
				(v) =>
					v +
					sum(
						this.getVisibleStaff().map((sm) => {
							return sm.getTotalPayInRange(monthStart, monthEnd);
						})
					)
			);
		});

		new DateRange(startDate, endDate, "month", 1).dates(function (d) {
			let monthStart = dateConverter.momentToInt(d);
			let monthEnd = dateConverter.endOfMonthOffset(monthStart);

			for (let project of projects) {
				for (let phase of project.phases) {
					for (let rangeAllocation of phase.allocations) {
						if (
							rangeAllocation.staffMember &&
							rangeAllocation.staffMember.staffType ===
								"contractor"
						) {
							let intersection = rangeIntersection(
								[
									rangeAllocation.startDate,
									rangeAllocation.endDate,
								],
								[monthStart, monthEnd]
							);
							if (intersection != null) {
								let numWeekdaysInIntersection =
									dateConverter.numWeekdaysBetween(
										intersection[0],
										intersection[1]
									);
								if (numWeekdaysInIntersection > 0) {
									let numWeekdaysInAllocation =
										dateConverter.numWeekdaysBetween(
											rangeAllocation.startDate,
											rangeAllocation.endDate
										);
									let expenses = getStaffExpensesInRange(
										rangeAllocation.staffMember,
										rangeAllocation.hours /
											numWeekdaysInAllocation,
										intersection,
										"payRate"
									).expenses;
									ts.modifyItem(d, (s) => s + expenses);
								}
							}
						}
					}
				}
			}
		});

		return ts;
	}

	getNonProjectExpenses(startDate, endDate, callback) {
		this.objects.Expense.list.forEach(function (expense) {
			var dr = new DateRange(
				expense.startDate,
				expense.endDate,
				expense.repeatUnit,
				expense.repeatQuantity
			);
			dr.dates(function (d) {
				if (d.isAfter(endDate)) {
					return false;
				} else if (d.isSameOrAfter(startDate)) {
					callback(
						new CashFlowItem({
							project: null,
							invoice: null,
							endDate: dateConverter.momentToInt(d),
							expense: expense,
							fee: 0,
							spend: expense.budget,
							title: expense.name,
						})
					);
				}
			});
		});
	}

	saveXeroSettingsSuccess(xeroSettings) {
		this.organisation.xeroSettings = xeroSettings;
		this.emitChanged();
	}

	refreshProjectLookup() {
		let self = this;
		this.projects.forEach(function (project) {
			self.objects.Project.lookup[project.id] = project;
			project.phases.forEach(function (phase) {
				if (self.objects.ProjectPhase.lookup[phase.id] == null) {
					self.objects.ProjectPhase.lookup[phase.id] = phase;
					self.objects.ProjectPhase.list.push(phase);
				}
			});
		});
		this.emitChanged();
	}

	getPhaseInvoicePercentages() {
		/**
		 * {
		 *   <phase 1 id>: [<percentage in invoice 1>, <percentage in invoice 2>, ...],
		 *   <phase 2 id>: [<percentage in invoice 1>, <percentage in invoice 2>, ...],
		 *   ...
		 * }
		 *
		 * Lists only show percentages for invoices where the phase actually appears.
		 */
		let phaseLookup = {};
		let invoices = _.clone(this.objects.Invoice.list);
		invoices.sort(compareMoments);
		invoices.forEach(function (invoice) {
			invoice.lineItems.forEach(function (li) {
				if (phaseLookup[li.phase.id] == null) {
					phaseLookup[li.phase.id] = [];
				}
				phaseLookup[li.phase.id].push(li.percentageComplete);
			});
		});
		return phaseLookup;
	}

	deleteReport(report) {
		let existingReport = _.find(
			this.reports,
			(r) => r.uuid === report.uuid
		);
		this.reports = _.without(this.reports, existingReport);
		delete this.objects.Report.lookup[existingReport.id];
		this.emitChanged();
	}

	deleteRevenueReport(reportUUID) {
		let existingReport = _.find(
			this.revenueForecastReports,
			(r) => r.uuid === reportUUID
		);
		this.revenueForecastReports = _.without(
			this.revenueForecastReports,
			existingReport
		);
		delete this.objects.RevenueForecastReport.lookup[existingReport.id];
		this.emitChanged();
	}

	deleteResourceReport(reportUUID) {
		let existingReport = _.find(
			this.resourceScheduleReports,
			(r) => r.uuid === reportUUID
		);
		this.resourceScheduleReports = _.without(
			this.resourceScheduleReports,
			existingReport
		);
		delete this.objects.ResourceScheduleReport.lookup[existingReport.id];
		this.emitChanged();
	}

	/**
	 * This is how we'd like to update objects going forward, as soon as we get
	 * rid of code that relies on reference equality to identify conceptual
	 * object equality.
	 */
	updateReport(report) {
		this.objects.Report.lookup[report.id] = report;
		let ix = _.findIndex(this.reports, (r) => r.uuid === report.uuid);
		if (ix !== -1) {
			this.objects.Report.list[ix] = report;
		} else {
			this.objects.Report.list.push(report);
		}
		this.objects.Report.list.sort((a, b) => a.name.localeCompare(b.name));
		this.emitChanged();
	}

	updateRevenueReport(reportData) {
		let report = RevenueForecastReport.fromJson(reportData);
		this.objects.RevenueForecastReport.lookup[report.id] = report;
		let ix = _.findIndex(
			this.revenueForecastReports,
			(r) => r.uuid === report.uuid
		);
		if (ix !== -1) {
			this.objects.RevenueForecastReport.list[ix] = report;
		} else {
			this.objects.RevenueForecastReport.list.push(report);
		}
		this.objects.RevenueForecastReport.list.sort((a, b) =>
			a.name.localeCompare(b.name)
		);
		this.emitChanged();
	}

	updateResourceReport(reportData) {
		let report = ResourceScheduleReport.fromJson(reportData);
		this.objects.ResourceScheduleReport.lookup[report.id] = report;
		let ix = _.findIndex(
			this.resourceScheduleReports,
			(r) => r.uuid === report.uuid
		);
		if (ix !== -1) {
			this.objects.ResourceScheduleReport.list[ix] = report;
		} else {
			this.objects.ResourceScheduleReport.list.push(report);
		}
		this.objects.ResourceScheduleReport.list.sort((a, b) =>
			a.name.localeCompare(b.name)
		);
		this.emitChanged();
	}

	getAdminNames() {
		return this.staffMembers
			.filter((sm) => permissions.limitedAdmin.ok(sm))
			.map((sm) => sm.getFullName());
	}

	logoutSuccess() {
		this._reset();
	}

	onReady(callback) {
		let self = this;
		function listener() {
			if (self.isReady) {
				callback();
				self.removeChangeListener(listener);
			}
		}

		if (this.isReady) {
			callback();
		} else {
			this.addChangeListener(listener);
		}
	}

	getReportByUuid(uuid) {
		return _.find(this.reports, (r) => r.uuid === uuid);
	}

	getRevenueForecastReportByUuid(uuid) {
		return uuid
			? _.find(this.revenueForecastReports, (r) => r.uuid === uuid)
			: null;
	}

	getResourceScheduleReportByUuid(uuid) {
		return uuid
			? _.find(this.resourceScheduleReports, (r) => r.uuid === uuid)
			: null;
	}

	saveCostCentre(costCentreData, { withEntries = false } = {}) {
		// We still have some promise-flux mismatches going on with the
		// `AjaxOperation` which is why we call the action *and* resolve/reject a
		// promise here.
		return new Promise(function (resolve, reject) {
			apiRequest({
				url: `/organisation/current/cost-centre/${
					costCentreData.id || "new"
				}`,
				method: "post",
				data: {
					costCentre: {
						id: costCentreData.id,
						uuid: costCentreData.uuid,
						name: costCentreData.name,
						isBillable: costCentreData.isBillable,
					},
					updateExistingEntryBillability: withEntries,
				},
				success: (data) => {
					actions.saveCostCentreSuccess(data);
					resolve(data);
				},
				error: (data) => {
					actions.saveCostCentreFailure(data);
					reject(data);
				},
			});
		});
	}

	saveCostCentreSuccess({ costCentre }) {
		this.setObjectByUuid(
			"BusinessCategory",
			new BusinessCategory(costCentre)
		);
	}

	get numDaysBetweenIssueDateAndDueDate() {
		return (
			deepLookup(this.organisation, [
				"settings",
				"invoice",
				"numDaysBetweenIssueDateAndDueDate",
			]) || 14
		);
	}

	get invoiceCode() {
		return (
			deepLookup(this.organisation, [
				"settings",
				"invoice",
				"invoiceCode",
			]) || `format(invNum, "00000")`
		);
	}

	getProjectReportMatcher(reportUuid) {
		return this._getReportMatcher(reportUuid, "projects-page/report");
	}

	getStaffReportMatcher(reportUuid) {
		return this._getReportMatcher(reportUuid, "staff-members-page/report");
	}

	_getReportMatcher(reportUuid, rootStoreKey) {
		const rootStore = require("./RootStore.js").rootStore;
		if (reportUuid != null) {
			return this.getReportByUuid(reportUuid).getItemMatcher(
				rootStore[rootStoreKey].columns
			);
		} else {
			return (ob) => true;
		}
	}

	getEditableCostCentres(user) {
		if (user.permissions.isAdmin) {
			return this.costCentres;
		} else {
			const ids = new Set(
				user.permissions.items
					.filter((pi) => pi.level === PermissionLevel.admin)
					.map((pi) => pi.object.costCentreId)
					.filter((id) => id != null)
			);
			return this.costCentres.filter((c) => ids.has(c.id));
		}
	}

	getCostCentreFromPermissionObject(permissionObject) {
		const costCentreId = permissionObject.costCentreId;
		return costCentreId != null
			? this.getCostCentreById(costCentreId)
			: null;
	}

	getProjectFromPermissionObject(permissionObject) {
		const projectId = permissionObject.projectId;
		return projectId != null ? this.getProjectById(projectId) : null;
	}

	_updateInvoiceSettingsState(ac) {
		this.invoiceSettingsState = {
			...this.invoiceSettingsState,
			isAuthenticated: ac.connection.state === ConnectionState.connected,
			accountingSystemId: ac != null ? ac.identifier : null,
			accountingSystem: ac,
			accountingSystemSettings: ac != null ? ac.settings : null,
			accountingSystemData: ac != null ? ac.data : null,
			numDaysBetweenIssueDateAndDueDate:
				this.numDaysBetweenIssueDateAndDueDate,
			invoiceCode: this.invoiceCode,
		};
	}

	_checkCurrentAccountingSystemAuth() {
		let ac = this.getCurrentAccountingSystem();
		let invoicePageStore =
			require("./invoices/InvoicePageStore.js").invoicePageStore;
		if (ac != null) {
			invoicePageStore._checkAuth(ac.identifier);
		}
		this._updateInvoiceSettingsState(ac);
	}

	openAccountingSystemSettingsPopup({ then = "closePopup" } = {}) {
		this._checkCurrentAccountingSystemAuth();
		this.openModal({
			type: modalTypes.accountingSystemSettings,
			then: then,
		});
		this.emitChanged();
	}

	invoiceSettingsSelectAccountingSystem(accountingSystemId) {
		let invoicePageStore =
			require("./invoices/InvoicePageStore.js").invoicePageStore;
		this._updateInvoiceSettingsState(
			this.getAccountingSystemByIdentifier(accountingSystemId)
		);
		invoicePageStore._checkAuth(accountingSystemId);
		this.emitChanged();
	}

	invoiceSettingsSetSettings(settings) {
		this.invoiceSettingsState = {
			...this.invoiceSettingsState,
			accountingSystemSettings: settings,
		};
		this.emitChanged();
	}
};

export function getAllPayRates(staffList, staffLookup) {
	let currentPayRate = {
		date: 0,
		// dict mapping staff ids to pay rates (numbers).
		rates: {},
	};
	for (let sm of staffList) {
		currentPayRate.rates[sm.id] = 0;
	}

	let prs = [currentPayRate];

	Immutable.List(staffList)
		.map(function (sm) {
			return Immutable.List(
				sm.payRates.map(function (pr) {
					return {
						...pr.toJS(),
						date: dateConverter.momentToInt(pr.date),
						staffMember: sm,
					};
				})
			);
		})
		.flatten()
		.sort(function (a, b) {
			return a.date - b.date;
		})
		.forEach(function (pr) {
			const isNewDate = pr.date !== currentPayRate.date;
			if (isNewDate) {
				currentPayRate = {
					date: pr.date,
					rates: _.clone(currentPayRate.rates),
				};
			}
			currentPayRate.rates[pr.staffMember.id] = pr.payRate;
			if (isNewDate) {
				prs.push(currentPayRate);
			}
		});

	for (let pr of prs) {
		let weeklyPay = 0;
		_.each(pr.rates, function (payRate, staffMemberId) {
			const sm = staffLookup[staffMemberId];
			//TODO fix for adjusting availability
			if (sm.staffType === "employee") {
				if (sm.weeklyAvailability != null && payRate != null) {
					weeklyPay += (sm.weeklyAvailability * payRate) / 60;
				}
			}
		});

		pr.dailyPay = weeklyPay / 5;
	}

	return prs;
}

function getCombinedRanges(startDate, endDate, staffList, staffLookup) {
	let payRates = getAllPayRates(staffList, staffLookup);

	let monthDates = [];
	new DateRange(startDate, endDate, "month", 1).dates(function (d) {
		monthDates.push(dateConverter.momentToInt(d));
	});

	return getAllSubintervals(
		payRates,
		monthDates,
		(pr) => pr.date,
		(d) => d,
		(d, pr) => ({
			date: d,
			pr: pr,
		})
	);
}

function getStaffBaseSpend(startDate, endDate, staffList, staffLookup) {
	let ts = new TimeSeries(() => 0);

	let combinedRanges = getCombinedRanges(
		startDate,
		endDate,
		staffList,
		staffLookup
	);

	let startD = dateConverter.momentToInt(startDate);
	let endD = dateConverter.momentToInt(endDate);
	for (let i = 1; i < combinedRanges.length - 1; i++) {
		let pr = combinedRanges[i];
		let d = dateConverter.startOfMonthOffset(pr.date);
		if (d >= startD) {
			ts.modifyItemByKey(
				d,
				(h) =>
					h +
					pr.pr.dailyPay *
						dateConverter.numWeekdaysBetween(
							pr.date,
							Math.min(endD, combinedRanges[i + 1].date - 1)
						)
			);
		}
	}

	let pr = _.last(combinedRanges);
	ts.modifyItemByKey(
		dateConverter.startOfMonthOffset(pr.date),
		() =>
			pr.pr.dailyPay *
			dateConverter.numWeekdaysBetween(
				pr.date,
				dateConverter.endOfMonthOffset(
					dateConverter.momentToInt(endDate)
				)
			)
	);

	return ts;
}

export function getStaffAvailabilityGraph(
	staffMembers,
	holidaysArray,
	startDate,
	endDate
) {
	/**
	 * Returns a dict mapping month offsets to number of hours available.
	 * startDate and endDate are int-dates.
	 */
	let graph = {};

	for (
		let monthStart = dateConverter.startOfMonthOffset(startDate);
		monthStart <= endDate;

	) {
		const monthEnd = dateConverter.endOfMonthOffset(monthStart);
		const totalWeeklyHoursAvailable = sum(
			staffMembers.map((sm) =>
				sm.getWeeklyAvailabilityInRange(monthStart, monthEnd)
			)
		);
		graph[monthStart] =
			(dateConverter.numWeekdaysInMonthWithoutHolidays(
				monthStart,
				holidaysArray
			) *
				totalWeeklyHoursAvailable) /
			5;
		monthStart = monthEnd + 1;
	}

	return graph;
}

export function getHoursBudget({ project, projectPhase, staffMember, task }) {
	if (task !== undefined) {
		// There is no budget for tasks. The caller will pass `task = null` for "No
		// task" which is still considered a task and hence has no budget (that's
		// why we check for `undefined` rather than null.
		return null;
	} else if (project != null && projectPhase == null && staffMember == null) {
		return project.manualHoursBudget;
	} else if (projectPhase != null && staffMember == null) {
		return projectPhase.manualHoursBudget;
	} else if (project != null && projectPhase == null && staffMember != null) {
		return sum(
			project.phases.map((p) => p.getStaffMemberHoursBudget(staffMember))
		);
	} else if (projectPhase != null && staffMember != null) {
		return projectPhase.getStaffMemberHoursBudget(staffMember);
	} else if (project == null && projectPhase == null && staffMember != null) {
		return sum(
			organisationStore.projects.map((p) =>
				sum(
					p.phases.map((pp) =>
						pp.getStaffMemberHoursBudget(staffMember)
					)
				)
			)
		);
	}
	return null;
}

export function getBudget({ project, projectPhase, task }) {
	if (task !== undefined) {
		// There is no budget for tasks. The caller will pass `task = null` for "No
		// task" which is still considered a task and hence has no budget (that's
		// why we check for `undefined` rather than null.
		return null;
	} else if (projectPhase != null) {
		return projectPhase.manualBudget;
	} else if (project != null) {
		return project.manualBudget;
	} else {
		return null;
	}
}

export function* iterStaffSpendTimeSeries(ts) {
	for (let [month, spend] of iterDict(ts.dict)) {
		yield new CashFlowItem({
			endDate: parseInt(month),
			project: null,
			invoice: null,
			title: "Staff",
			spend: parseFloat(spend),
		});
	}
}

export function getOnboardingData(organisationStore, permissions, userStore) {
	return {
		hasRevenue: organisationStore.projects.some((p) => p.hasIncome()),
		hasHours: organisationStore.projects.some((p) => p.hasHours()),
		hasProjects: organisationStore.projects.length > 0,
		canCreateRevenue: permissions.canEditRevenue.ok(userStore.user),
		canCreateProjects: permissions.canCreateProject.ok(userStore.user),
	};
}
