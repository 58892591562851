import { generateUUID } from '../utils.js';
import { makeRecordClass, BooleanType, NumberType, StringType, DateType, DateTimeType, MapType, ListOf, UUID } from './record.js';
import Immutable from 'immutable';
import _ from 'underscore';
import { organisationStore } from '../organisation.js';


export const ResourceScheduleReport = class extends makeRecordClass({
  id: null,
  uuid: UUID,
  name: StringType,
  filters: MapType,
}) {
  constructor(...args) {
    super(...args);
    if (this.uuid == null) {
      this.uuid = generateUUID();
    }
    this._cache = {};
  }


  static getClassName() {
    return "ResourceScheduleReport";
  }

  apiTypeName() {
    return "resource-schedule-report";
  }

}
