import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import { ModalFooter } from '../modal.js';
import { ProjectSelector, DateValue } from '../widgets.js';
import { invoicesActions } from './flux.js';
import { invoicePageStore } from './InvoicePageStore.js';
import { PermissionLevel } from '../models/permissions.js';
import { Invoice } from '../models/Invoice.js';

import PropTypes from "prop-types";
import apiRequest from '../apiRequest.js';
import { organisationStore } from '../organisation.js';

export var NewInvoicePopup = CreateReactClass({
  getInitialState: function() {
    return {
      project: null,
      startDate: null,
      endDate: null
    };
  },

  render: function() {
    return <NewInvoicePopupContent
      project={this.state.project}
      startDate={this.state.startDate}
      endDate={this.state.endDate}
      onSelectProject={this.handleSelectProject}
      onSetStartDate={(startDate) => this.setState({startDate: startDate})}
      onSetEndDate={(endDate) => this.setState({endDate: endDate})}
      onCancel={this.props.onCancel}
      onSubmit={this.handleSubmit}
    />;
  },

  handleSelectProject: function(project) {
    let self = this;
    apiRequest({
      path: `/organisation/${organisationStore.organisation.id}/invoices/project/${project.id}/new`,
      method: "post",
      success: data => {
        const inv = Invoice.fromJson(data.invoice);
        self.setState({
          project: project,
          startDate: inv.startDate,
          endDate: inv.endDate
        });
      }
    });
  },

  handleSubmit: function() {
    invoicesActions.createNewInvoice(
      this.state.project.id,
      this.state.startDate,
      this.state.endDate
    );
  }
});


export var NewInvoicePopupContent = CreateReactClass({
  propTypes: {
    project: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onCancel: PropTypes.func,
    onSelectProject: PropTypes.func.isRequired,
    onSetStartDate: PropTypes.func.isRequired,
    onSetEndDate: PropTypes.func.isRequired
  },

  render: function() {
    return (
      <div className="new-invoice-popup">
        <div style={{padding: '2em'}}>
          <div  style={{marginBottom: '0.25em', fontWeight: 'bold'}}>
            Select project:
          </div>
          <div>
            <div className="new-invoice-popup__project-selector" style={{display: 'inline-block', width: 300}}>
              <ProjectSelector
                value={this.props.project}
                allowNull={false}
                style={{ maxWidth: '100%' }}
                onChange={(project) => this.props.onSelectProject(project)}
                requirePermissionLevel={PermissionLevel.admin}
              />
            </div>
          </div>
          {this.props.project != null ?
            <div style={{marginTop: '1.5em'}}>
              <div style={{marginBottom: '0.25em', fontWeight: 'bold'}}>
                Invoicing for work completed
              </div>
              <div style={{display: 'inline-block', width: '10em', marginRight: '1.5em'}}>
                from:
                <DateValue
                  value={this.props.startDate}
                  onChange={this.props.onSetStartDate}
                />
              </div>
              <div style={{display: 'inline-block', width: '10em'}}>
                to:
                <DateValue
                  value={this.props.endDate}
                  onChange={this.props.onSetEndDate}
                />
              </div>
            </div>
          : null}
        </div>
        <ModalFooter
          acceptButtonText="Continue"
          acceptButtonProps={{
            disabled: this.props.project == null,
            className: "new-invoice-popup__continue-button"
          }}
          onSubmit={this.props.onSubmit}
          onCancel={this.props.onCancel}
        />
      </div>
    );
  }
});
