import _ from 'underscore';
import { enumerate, iterDict, camelCaseToSentence } from './utils.js';

const formatItem = camelCaseToSentence;

export function Enum(items) {
  let itemsDict, itemsList;

  if (!_.isArray(items)) {
    throw new Error("ValueError");
  }
  if (_.isArray(items[0])) {
    itemsDict = {};
    for (let [item, value] of items) {
      itemsDict[item] = value;
    }
    itemsList = items.map(i => i[0]);
  }
  else {
    itemsList = items;
    itemsDict = {};
    for (let [i, item] of enumerate(items)) {
      itemsDict[item] = i;
    }
  }

  let options = null;
  let multiSelectOptions = null;

  let reverseDict = {};
  for (let [item, i] of iterDict(itemsDict)) {
    reverseDict[i] = item;
  }

  const klass = class {
    constructor() {
      for (let item of itemsList) {
        this[item] = item;
      }
    }

    formatItem(item) {
      return formatItem(item);
    }

    itemToNumber(item) {
      return itemsDict[item];
    }

    numberToItem(num) {
      return reverseDict[num];
    }

    getItems() {
      return itemsList;
    }

    getOptions() {
      /**
       * Get an array of options suitable for use in a MySelect2.
       */
      if (options == null) {
        options = itemsList.map(function(item) {
          return {
            label: formatItem(item),
            value: item
          };
        });
      }
      return options;
    }

    getMultiSelectOptions() {
      /**
       * Get an array of options suitable for use in a Multiselect.
       */
      if (multiSelectOptions == null) {
        multiSelectOptions = itemsList.map(function(item) {
          return {
            label: formatItem(item),
            id: item
          };
        });
      }
      return multiSelectOptions;
    }

    isAtLeast(val1, val2) {
      return itemsDict[val1] >= itemsDict[val2];
    }
  };
  return new klass();
}

