import { textProperties } from "./properties/text";

export let Properties = {
			text: textProperties,
		};

		export const addProperties = (newProps) => {
			Properties = {
				...Properties,
				...newProps,
			};
		};