import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import ReactDOM from "react-dom";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { makeMultipleStoreMixin } from "./coincraftFlux.js";
import { organisationStore } from "./organisation.js";
import { getOnboardingData } from "./organisationStore.js";
import {
	store as milestonesStore,
	actions as milestonesActions,
} from "./milestones/flux.js";
import { authenticationStore } from "./authenticationService.js";
import { dateConverter } from "./models.js";
import { permissions, requiresPermission } from "./models/permissions.js";
import { LinearScale, capitaliseFirst } from "./utils.js";
import { Enum } from "./enum.js";
import classNames from "classnames";
import { Modal2 } from "./modal.js";
import { userStore } from "./user/flux.js";
import { wrapUserStore } from "./user/utils.js";
import { MilestonesSaveBar } from "./widgets/savebar.js";
import {
	CashFlowChart,
	SuperimposedDataRenderer,
	Legend,
	Timeline,
	ScrollBar,
} from "./cashFlowChart.js";
import { CoincraftPage } from "./CoincraftPage.js";
import { IncompleteSetupPage } from "./IncompleteSetupPage.js";
import { invoicesActions } from "./invoices/flux.js";
import { modals } from "./modals.js";
import { Modal, ModalContent } from "./modal.js";
import { router } from "./router.js";
import { Feed } from "./feed.js";
import { Tabs } from "./widgets/tabs.js";
import { StateAwareRefreshButton } from "./widgets/coincraft.js";
import { actions } from "./user/flux";
import PropTypes from "prop-types";
import LoginModal from "./LoginModal.js";

const elementResizeDetectorMaker = require("element-resize-detector");
let erd = elementResizeDetectorMaker();

const mainContentPaddingLeft = "10%";

export var Dashboard = requiresPermission(
	permissions.noRestrictions,
	{},
	CreateReactClass({
		propTypes: {
			user: PropTypes.object.isRequired,
		},

		mixins: [
			makeMultipleStoreMixin(
				[authenticationStore, userStore, organisationStore],
				function () {
					if (
						authenticationStore.isReady &&
						!authenticationStore.isLoggedIn()
					) {
						actions.setDestinationUrl(
							router.history.getCurrentLocation().pathname
						);
						router.history.replace({ pathname: "/login" });
					}
					if (organisationStore.isReady) {
						this._checkForLockedAccount(
							organisationStore.organisation
						);
					}

					return {
						isReady: organisationStore.isReady,
						organisation: organisationStore,
						organisationStore: organisationStore,
						highlight: organisationStore.highlight,
						user: userStore.user,
					};
				}
			),
		],

		componentWillMount: function () {
			this.listenerSetup = false;
		},

		componentDidMount: function () {
			this.setupResizeListener();
		},

		componentDidUpdate: function () {
			this.setupResizeListener();
		},

		setupResizeListener: function () {
			// The sidebar is `position: fixed` but we want it to pretend it's part of
			// its column, so use Javascript to set its width appropriately.
			if (
				!this.listenerSetup &&
				this.refs.sidebarContainerContainer != null
			) {
				erd.listenTo(
					ReactDOM.findDOMNode(this.refs.sidebarContainerContainer),
					this.updateSidebarSize
				);
				this.listenerSetup = true;
			}
		},

		componentWillUnmount: function () {
			erd.removeListener(this.updateSidebarSize);
		},

		updateSidebarSize: function (element) {
			var width = ReactDOM.findDOMNode(
				this.refs.sidebarContainerContainer
			).offsetWidth;
			this.setState({ sidebarWidth: width });
		},

		render: function () {
			if (!this.state.organisation.isReady) {
				return null;
			}

			return (
				<div>
					<LoginModal />
					<Modals />
					{this.state.organisationStore != null &&
					this.state.organisationStore.isReady
						? React.cloneElement(this.props.children, {
								organisation: this.state.organisationStore,
								saveProject: this.saveProject,
								onEvent: this.handleEvent,
								params: this.props.params,
						  })
						: null}
				</div>
			);
		},

		componentWillUpdate: function (nextProps, nextState) {
			if (nextState.organisation != null) {
				this._checkForLockedAccount(
					nextState.organisation.organisation
				);
			}
		},

		_checkForLockedAccount: function (organisation) {
			if (organisation != null && organisation.isAccountLocked()) {
				router.history.replace({ pathname: "/billing" });
			}
		},

		saveProject: function (project) {
			var self = this;
			return this.state.organisationStore
				.saveProject(project)
				.then(function () {
					self.setState({
						organisation: self.state.organisationStore,
					});
				});
		},

		handleEvent: function (eventName, arg1, arg2) {
			// Allow the intro component to send us events telling us to highlight menu
			// items in the sidebar.
			if (eventName === "highlight") {
				var item = arg1,
					isHighlighted = arg2;
				if (isHighlighted) {
					this.setState({ highlight: item });
				} else {
					this.setState({ highlight: null });
				}
			}
		},
	})
);

export var DashboardWrapper = wrapUserStore(Dashboard);

var Modals = CreateReactClass({
	mixins: [
		makeMultipleStoreMixin([organisationStore], function () {
			return {
				modals: organisationStore.modals,
			};
		}),
		PureRenderMixin,
	],

	render: function () {
		return (
			<div>
				{this.state.modals.map(function (modal, i) {
					const modalData = modals[modal.type];
					if (modalData != null) {
						const { content, ...props } = modalData(modal);
						return (
							<Modal2
								key={i}
								{...props}
								onCancel={() =>
									invoicesActions.cancelModal(modal.type)
								}
							>
								{content}
							</Modal2>
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	},
});

const GraphTab = Enum(["revenue", "utilisation"]);

export var DashboardHomePageWrapper = wrapUserStore(
	CreateReactClass({
		mixins: [
			makeMultipleStoreMixin([organisationStore, userStore], function () {
				return {
					onboardingData: getOnboardingData(
						organisationStore,
						permissions,
						userStore
					),
				};
			}),
		],

		render: function () {
			if (this.state.onboardingData.hasRevenue) {
				return <DashboardHomePageWithRevenue user={this.props.user} />;
			} else {
				return (
					<IncompleteSetupPage
						className="revenue-forecast-page"
						heading="Dashboard: Revenue Forecast"
						onboardingData={this.state.onboardingData}
					/>
				);
			}
		},
	})
);

export var DashboardHomePageWithRevenue = requiresPermission(
	permissions.dashboard,
	{
		showFinancials: permissions.financialVisibilityRevenue,
		showStaff: permissions.staffTable,
	},
	CreateReactClass({
		mixins: [
			makeMultipleStoreMixin(
				[organisationStore, userStore, milestonesStore],
				function () {
					if (!milestonesStore.isReady) {
						return {
							milestonesReady: false,
						};
					}

					return {
						organisationStore: organisationStore,
						milestonesReady: true,
						graphMode: milestonesStore.graphMode,
						cashFlowMode: milestonesStore.cashFlowMode,
						graphData: milestonesStore.getGraphData(),
						startDate: milestonesStore.startDate,
						endDate: milestonesStore.endDate,
						isMilestonesRefreshing: milestonesStore.isRefreshing,
						selectedTab:
							milestonesStore.graphMode === "staff" ||
							!this.props.showFinancials
								? GraphTab.utilisation
								: GraphTab.revenue,
						firstSelectedMonth: milestonesStore.firstSelectedMonth,
						lastSelectedMonth: milestonesStore.lastSelectedMonth,
					};
				}
			),
		],

		componentWillMount: function () {
			setTimeout(function () {
				milestonesActions.initializePage();
			}, 0);
		},

		componentDidMount: function () {
			this.setupResizeHandler();
		},

		componentDidUpdate: function () {
			this.setupResizeHandler();
		},

		setupResizeHandler() {
			if (!this.isResizeHandlerSetup && this.refs.content != null) {
				var el = ReactDOM.findDOMNode(this.refs.content);
				erd.listenTo(el, this.handleResize);
				this.handleResize(el);
				this.isResizeHandlerSetup = true;
			}
		},

		handleResize: function (element) {
			this.setState({
				offsetWidth: element.offsetWidth,
				innerOffsetWidth:
					element.offsetWidth *
					((100 - parseInt(mainContentPaddingLeft)) / 100),
				offsetHeight: element.offsetHeight,
			});
		},

		render: function () {
			var self = this;

			if (!this.state.milestonesReady) {
				return null;
			}

			const data = this.state.graphData;

			if (data.length > 0) {
				// TODO-new_project_creator this should probably be done in the store.
				// (this is basically copied in milestones.js)
				//
				// It's safer to assume all years are leap years for the purposes of this scale.
				var xScale = new LinearScale({
					minX: data[0].date,
					maxX: data[0].date + 365,
					minY: 0,
					maxY: this.state.innerOffsetWidth,
				});

				var minX = data[0].date;
				var maxX = data[data.length - 1].date + 31;
			}

			return (
				<CoincraftPage
					heading={`Dashboard: ${capitaliseFirst(
						this.state.selectedTab
					)} Forecast`}
					body={
						<div className="revenue-forecast-page">
							<div
								className="new-dashboard__panel"
								style={{
									maxWidth: "65em",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								<div style={{ padding: "0.5em 0 2em" }}>
									<div
										style={{
											borderBottom: "solid 1px #aaa",
											height: "4em",
										}}
									>
										<div
											style={{
												display: "inline-block",
												width: "65%",
												verticalAlign: "top",
												padding: "0.5em",
											}}
										>
											<div
												style={{
													display: "inline-block",
												}}
											>
												<button
													className="btn btn-default"
													onClick={() =>
														self.setState({
															pastDataHelpOpen: true,
														})
													}
												>
													<i
														style={{ margin: 0 }}
														className="fa fa-question-circle"
													/>
												</button>

												{this.state.pastDataHelpOpen ? (
													<Modal
														closeOnClickOutside={
															true
														}
														onClosed={function () {
															self.setState({
																pastDataHelpOpen: false,
															});
														}}
													>
														<ModalContent
															width={600}
														>
															<div>
																<h3>
																	Cash flow
																	past data
																	modes
																</h3>
																<Legend
																	incomeAxisName="Income"
																	spendAxisName="Spend"
																/>
															</div>

															<div
																style={{
																	maxWidth: 710,
																}}
															>
																<p>
																	Coincraft
																	gives you
																	two ways to
																	view your
																	cash flow
																	data for
																	dates in the
																	past.
																</p>

																<ul
																	style={{
																		listStylePosition:
																			"inside",
																		listStyleType:
																			"none",
																	}}
																>
																	<li>
																		<strong>
																			Actuals:
																		</strong>{" "}
																		Where
																		available,
																		the
																		graph
																		will use
																		data
																		from
																		your
																		actual
																		Coincraft
																		invoices
																		to
																		determine
																		your
																		past
																		income.
																	</li>
																	<li>
																		<strong>
																			Projected:
																		</strong>{" "}
																		The
																		graph
																		won't
																		use
																		invoice
																		data; it
																		will
																		just use
																		the
																		milestones
																		as
																		defined
																		in your
																		projects.
																	</li>
																</ul>

																<p>
																	Note: for
																	any projects
																	that don't
																	have
																	Coincraft
																	invoices,
																	the graph
																	will always
																	show the
																	projected
																	data. So if
																	you have no
																	Coincraft
																	invoices,
																	your{" "}
																	<em>
																		Actuals
																	</em>{" "}
																	and{" "}
																	<em>
																		Projected
																	</em>{" "}
																	graphs will
																	be
																	identical.
																	Dates in the
																	future will
																	also show
																	only
																	projected
																	data.
																</p>
															</div>

															<div
																style={{
																	width: "100%",
																	padding:
																		"0 12px",
																	height: 30,
																	textAlign:
																		"right",
																}}
															>
																<a
																	href="javascript:void(0)"
																	onClick={function () {
																		self.setState(
																			{
																				pastDataHelpOpen: false,
																			}
																		);
																	}}
																>
																	Close
																</a>
															</div>
														</ModalContent>
													</Modal>
												) : null}
											</div>
											<StateAwareRefreshButton
												onClick={
													this
														.handleGraphRefreshButtonClick
												}
												iconOnly={true}
												smallButton={false}
											/>
											{this.state.selectedTab ===
											GraphTab.revenue ? (
												<span>
													{"Show: "}
													<span className="btn-group">
														<button
															className={classNames(
																"btn",
																"btn-default",
																"dashboard-graph__projected-button",
																this.state
																	.graphMode ===
																	"projected"
																	? "active"
																	: null
															)}
															onClick={
																this
																	.handleDisplayProjectedButtonClick
															}
														>
															Projected
														</button>
														<button
															className={classNames(
																"btn btn-default",
																"dashboard-graph__actuals-button",
																this.state
																	.graphMode ===
																	"actuals"
																	? "active"
																	: null
															)}
															onClick={
																this
																	.handleDisplayActualsButtonClick
															}
														>
															Actuals
														</button>
													</span>
												</span>
											) : null}
										</div>
									</div>
								</div>

								<div
									style={{ maxWidth: 710, marginLeft: "3%" }}
								>
									<div ref="content">
										<CashFlowChart
											data={data}
											showLegend={false}
											renderer={
												new SuperimposedDataRenderer()
											}
											enableHoverTooltips={false}
											firstSelectedMonth={
												this.state.firstSelectedMonth !=
												null
													? dateConverter.momentToInt(
															this.state
																.firstSelectedMonth
													  )
													: null
											}
											lastSelectedMonth={
												this.state.lastSelectedMonth !=
												null
													? dateConverter.momentToInt(
															this.state
																.lastSelectedMonth
													  )
													: null
											}
											min={minX}
											max={maxX}
											viewportScrollLeft={
												this.state.startDate
											}
											manageOwnScrolling={false}
											showXAxis={false}
											showScrollbar={false}
											onScroll={
												self.handleCashFlowGraphScroll
											}
											xScale={xScale}
											yValueType={
												this.state.graphMode === "staff"
													? "hours"
													: "dollars"
											}
											padding={{
												top: "0%",
												left: "10%",
												bottom: "0%",
												right: "0%",
											}}
											onClick={(selectedMonth) =>
												milestonesActions.graphSetMonth(
													selectedMonth
												)
											}
										/>
									</div>

									<div style={{ marginLeft: "10%" }}>
										<div style={{ height: 20 }}>
											<svg
												style={{
													width: "100%",
													height: "100%",
												}}
											>
												<ScrollBar
													viewportWidth={
														this.state
															.innerOffsetWidth
													}
													scrollX={
														milestonesStore.startDate
													}
													min={minX}
													max={maxX}
													onScroll={
														self.handleCashFlowGraphScroll
													}
													xScale={xScale}
												/>
											</svg>
										</div>
										<div style={{ height: 40 }}>
											<svg
												style={{
													width: "100%",
													height: "100%",
												}}
											>
												<Timeline
													min={minX}
													max={maxX}
													startDate={
														milestonesStore.startDate
													}
													endDate={
														milestonesStore.endDate
													}
													offsetWidth={
														this.state
															.innerOffsetWidth
													}
													offsetHeight={40}
													data={this.state.graphData}
													xScale={xScale}
												/>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					sidebar={
						<div className="coincraft-page-layout__sidebar">
							<div className="coincraft-page-layout__sidebar-content">
								<Feed
									firstSelectedMonth={
										this.state.firstSelectedMonth
									}
									lastSelectedMonth={
										this.state.lastSelectedMonth
									}
									selectedDisplay={this.state.selectedTab}
									showFinancials={this.props.showFinancials}
									user={this.props.user}
								/>
							</div>
						</div>
					}
					saveBar={<MilestonesSaveBar />}
				/>
			);
		},

		handleDisplayProjectedButtonClick: function () {
			milestonesActions.setGraphMode("projected");
		},

		handleDisplayActualsButtonClick: function () {
			milestonesActions.setGraphMode("actuals");
		},

		handleGraphTabChange: function (tabValue) {
			milestonesActions.setGraphMode(
				tabValue === GraphTab.utilisation
					? "staff"
					: this.state.cashFlowMode
			);
		},

		handleCashFlowGraphScroll: function (x) {
			milestonesActions.scrollTo(x);
		},

		handleGraphRefreshButtonClick: function () {
			milestonesActions.refreshPage();
		},

		handleGraphModeChange: function (option) {
			milestonesActions.setGraphMode(option.value);
		},
	})
);
