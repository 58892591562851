import moment from 'moment';
import React from 'react';
import CreateReactClass from 'create-react-class';
import _ from 'underscore';
import { Spreadsheet, RevenueRow, AllocationRow } from './Spreadsheet.js';
import { store, actions, TYPE } from './flux.js';
import { ITEM_COLUMN_WIDTH, CONTENT_WIDTH } from './Spreadsheet.js';
import { store as milestonesStore } from '../milestones/flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { dateConverter } from '../models.js';
import { permissions, PermissionLevel } from '../models/permissions.js';
import { LinearScale, formatPercentage0 } from '../utils.js';
import { IncompleteSetupPage } from '../IncompleteSetupPage.js';
import {
	PageLoadingSpinner,
	MilestonesSaveBar,
	ErrorAlert,
	IntercomMessageLink
} from "../widgets.js";
import { CashFlowChart, SuperimposedDataRenderer } from "../cashFlowChart.js";
import { CoincraftPage } from '../CoincraftPage.js';
import { Modal, ModalContent, ModalFooter } from '../modal.js';
import { ProjectRow, ProjectPhaseRow, StaffMemberRow } from '../timesheets/EditEntryModal.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { organisationStore } from '../organisation.js';
import { getOnboardingData } from "../organisationStore.js";
import { ContextMenu } from './contextMenu/ContextMenu.js';
import { userStore } from '../user/flux.js';
import { getGradient } from './gradient.js';
import PropTypes from "prop-types";


import elementResizeDetectorMaker from "element-resize-detector";
const erd = elementResizeDetectorMaker();


function getRevenueContainerProps(props) {
  let phase = props.row.get('phase');
  let project = props.row.get('project');
  let revenueToDate = props.value.cumSum;

  let backgroundColor = props.value.total > 0 ?
    '#ffc700'
  : props.value.total <= 0 ?
    '#e6e6e6'
  : // props.value.total == null (eg. item column)
    '#fff';

  let color = '#444';

  if (props.value.total > 0) {
    const fee = (phase != null) ?
      phase.fee
    : (project != null) ?
      project.fee
    : null;

    if (fee > 0 && Math.round(revenueToDate) > Math.round(fee)) {
      backgroundColor = "#f15a29";
      color = '#fff';
    }
  }

  return {
    style: {
      borderRight: '2px solid white',
      padding: 3,
      lineHeight: '17px',
      backgroundColor: backgroundColor,
      color: color
    }
  };
}


function getAllocationContainerProps(props) {
  let staffMember = props.row.get('staffMember');

  let val;
  if (staffMember != null && props.value >= 0) {
    const availability = staffMember.getNumHoursAvailableInMonth(props.column.key, organisationStore.getHolidaysXspans().data);
    val = (availability > 0) ? props.value / availability : 0;
  }

  return {
    style: {
      borderRight: '2px solid white',
      padding: 3,
      lineHeight: '17px',
      ...getGradient(val)
    }
  };
}


export var SpreadsheetPage = CreateReactClass({
  propTypes: {
    spreadsheetType: PropTypes.oneOf(['revenue', 'allocation']).isRequired
  },

  mixins: [
    makeMultipleStoreMixin([store, organisationStore, userStore], function() {
      return {
        spreadsheetType: store.spreadsheetType,
        heading: (store.spreadsheetType === 'revenue') ? 'Revenue Forecast' : 'Resource Schedule',
        onboardingData: getOnboardingData(organisationStore, permissions, userStore),
      };
    })
  ],

  render: function() {
    if ((this.state.spreadsheetType === 'revenue' && !this.state.onboardingData.hasRevenue)
        || (this.state.spreadsheetType === 'allocation' && !this.state.onboardingData.hasProjects)) {
      return <IncompleteSetupPage
        heading={this.state.heading}
        onboardingData={this.state.onboardingData}
      />;
    }
    else {
      return <SpreadsheetPageWithRequirements spreadsheetType={this.props.spreadsheetType} />;
    }
  }
});


var SpreadsheetPageWithRequirements = CreateReactClass({
  propTypes: {
    spreadsheetType: PropTypes.oneOf(['revenue', 'allocation']).isRequired
  },

  mixins: [
    makeMultipleStoreMixin([store, milestonesStore, organisationStore, userStore], function() {
      if (!milestonesStore.isReady) {
        return {isReady: false};
      }
      if (!store.isReady) {
        return {isReady: false};
      }

      const mode = store.spreadsheetType;
      let graphData = store.spreadsheet.graph;
      let columns = store.spreadsheet.columns;

      return {
        isReady: graphData != null && columns != null,
        startMonth: store.startMonth,
        endMonth: store.endMonth,
        mode: mode,
        allocation: {
          groupByKey: store.allocationGroupBy
        },
        navigatorColumns: store.navigatorColumns,
        timesheetDataError: store.timesheetDataError,

        graphData: graphData,
        columnHeaders: store.spreadsheet.columnHeaders,
        columns: columns,
        rows: store.spreadsheet.rows,

        ...(mode === 'revenue' ?
          {
            heading: 'Revenue Forecast',
            rowClass: RevenueRow,
            cellType: TYPE.REVENUE,
            getCellContainerProps: getRevenueContainerProps,
          }
        :
          {
            heading: 'Resource Schedule',
            rowClass: AllocationRow,
            cellType: TYPE.HOURS,
            getCellContainerProps: getAllocationContainerProps,
          }
        ),

        groupedColumns: store.groupedColumns,
        leafColumn: store.leafColumn,

        /**
         * Rows are raw ungrouped rows as generated by `getAllocationSpreadsheet` and `getRevenueSpreadsheet`.
         * Processed rows are rows possibly augmented with grouping data.
         */
        selectedRow: store.selectedRow,
        selectedProcessedRow: store.selectedProcessedRow,
        selectedRowIndex: store.selectedRowIndex,
        selectedCellIndex: store.selectedCellIndex,

        user: userStore.user,
        organisationStore: organisationStore
      };
    })
  ],

  componentWillMount: function() {
    store.initialize();
    if (this.props.spreadsheetType === 'revenue') {
      store.setSpreadsheetType('revenue');
    }
    else {
      store.setSpreadsheetType('allocation', 'staff');
    }
  },

  componentDidMount: function() {
    this.setupResizeHandler();
  },

  setupResizeHandler() {
    let el = this.refs.body;
    erd.listenTo(el, this.handleResize);
    this.handleResize(el);
  },

  handleResize: function(element) {
    this.setState({
      hasSize: true,
      offsetWidth: element.offsetWidth,
      contentOffsetWidth: element.offsetWidth * parseInt(CONTENT_WIDTH) / 100,
      offsetHeight: element.offsetHeight
    });
  },

  render: function() {
    const isAllocation = (this.props.spreadsheetType === 'allocation');
    const rows = this.state.rows;

    let row, cellData, currentMonthIndex, selectedMonthIndex;
    if (rows != null && this.state.selectedRowIndex != null) {
      row = rows.get(this.state.selectedRowIndex);
      if (this.state.selectedCellIndex != null && this.state.selectedCellIndex > 0) {
        cellData = row.getCell(this.state.selectedCellIndex);
      }
      currentMonthIndex = dateConverter.getMonthIndex(dateConverter.momentToInt(moment()));
      selectedMonthIndex = row.getMonthIndexFromCellIndex(this.state.selectedCellIndex);
    }

    return <CoincraftPage
      heading={this.state.heading}
      body={
        <div>
          <div
              ref="body"
              style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '80em'}}>
            <SpreadsheetModals />

            {this.state.isReady && rows != null ?
              <div>
                <div style={{backgroundColor: 'white', maxHeight: '50%'}}>
                  {this.state.isReady && this.state.hasSize ?
                    <CashFlowChartContainer
                      contentOffsetWidth={this.state.contentOffsetWidth}
                      graphData={this.state.graphData}
                      startMonth={this.state.startMonth}
                      yAxisWidth={ITEM_COLUMN_WIDTH}
                      contentWidth={CONTENT_WIDTH}
                      yValueType={this.state.mode === 'allocation' ? 'hours' : 'dollars'}
                    />
                  : null}
                </div>

                <DisembodiedHeader
                  columnHeaders={this.state.columnHeaders}
                  columns={this.state.navigatorColumns}
                  rows={rows}
                  mode={this.state.mode}
                />

                <div>
                  {this.state.timesheetDataError ?
                    <ErrorAlert>
                      There was a problem retrieving your timesheet data. Try refreshing the page,
                      or if the problem persists, <IntercomMessageLink label="chat with us" />.
                    </ErrorAlert>
                  : null}
                  <Spreadsheet
                    offsetWidth={this.state.offsetWidth}
                    rows={this.state.rows}
                    navigatorColumns={this.state.navigatorColumns}
                    columns={this.state.columns}
                    startMonth={this.state.startMonth}
                    endMonth={this.state.endMonth}
                    groupedColumns={this.state.groupedColumns}
                    leafColumn={this.state.leafColumn}
                    cellType={this.state.cellType}
                    rowClass={this.state.rowClass}
                    getCellContainerProps={this.state.getCellContainerProps}
                    onCellUpdated={this.handleCellUpdated}
                    showStaffSelector={this.props.spreadsheetType === 'allocation'}
                  />
                </div>
              </div>
            :
              <div style={{marginTop: '7em'}}>
                <div className="flex-1-0-auto" style={{textAlign: 'center'}}>
                  <PageLoadingSpinner
                    text={isAllocation ? "Loading Resource Schedule" : "Loading Revenue Forecast"}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      }
      sidebar={
        <div>
          {/*
             Ideally we would like to show context for group rows too but we haven't implemented
             all the logic for the possibly-missing data in group rows yet.
          */}

          {this.state.isReady && this.state.selectedRowIndex != null && !this.state.selectedProcessedRow.isGroup ?
            <ContextMenu
              row={row}
              rowIndex={this.state.selectedRowIndex}
              cellData={cellData}
              cellKey={row.getMonthIndexFromCellIndex(this.state.selectedCellIndex)}
              cellType={this.state.cellType}
              project={row.get('project')}
              phase={row.get('phase')}
              monthIndex={selectedMonthIndex}
              selectedCellTense={selectedMonthIndex === currentMonthIndex ? 'present'
                                  : selectedMonthIndex > currentMonthIndex ? 'future' : 'past'}
              user={this.state.user}
              holidaysArray={this.state.organisationStore.getHolidaysXspans().data}
            />
          : null}
        </div>
      }
      saveBar={
        <MilestonesSaveBar />
      }
    />;
  },

  handleCellUpdated: function(rowIndex, cellKey, newValue) {
    if (this.state.mode === 'revenue') {
      actions.setRevenueCellValue(rowIndex, cellKey, newValue);
    }
    else {
      actions.setAllocationCellValue(rowIndex, cellKey, newValue);
    }
  }
});


var SpreadsheetModals = CreateReactClass({
  mixins: [
    makeMultipleStoreMixin([store], function() {
      return {
        modals: store.modals
      };
    }),
    PureRenderMixin
  ],

  render: function() {
    return <div>
      {this.state.modals.map(function(modal, i) {
        return <Modal key={i}>
          <ModalContent header="Add New Row" width="40em">
            {modal.type === 'addRevenueRow' ?
              <NewRevenueRowForm
                modal={modal}
                initialProject={modal.project}
              />
            : modal.type === 'addAllocationRow' ?
              <NewAllocationRowForm
                modal={modal}
                initialStaffMember={modal.staffMember}
                initialProject={modal.project}
              />
            : null}
          </ModalContent>
        </Modal>;
      })}
    </div>;
  }
});


var ProjectAndPhaseRows = CreateReactClass({
  propTypes: {
    project: PropTypes.object,
    phase: PropTypes.object,
    // function(project, phase)
    onChange: PropTypes.func,
    requirePermissionLevel: PropTypes.string
  },

  render: function() {
    return <div>
      <ProjectRow
        value={this.props.project}
        onChange={this.handleProjectChange}
        requirePermissionLevel={this.props.requirePermissionLevel}
      />
      <ProjectPhaseRow
        project={this.props.project}
        value={this.props.phase}
        onChange={this.handleProjectPhaseChange}
      />
    </div>;
  },

  handleProjectChange: function(project) {
    this.props.onChange(project, null);
  },

  handleProjectPhaseChange: function(phase) {
    this.props.onChange(this.props.project, phase);
  }
});


var NewRevenueRowForm = CreateReactClass({
  propTypes: {
    modal: PropTypes.object.isRequired
  },

  getInitialState: function() {
    return {
      project: this.props.initialProject,
      phase: null
    };
  },

  render: function() {
    return <div>
      <ProjectAndPhaseRows
        project={this.state.project}
        phase={this.state.phase}
        onChange={this.handleProjectAndPhaseChange}
        requirePermissionLevel={PermissionLevel.projectManager}
      />
      <ModalFooter
        acceptButtonText="Save"
        acceptButtonProps={{
          disabled: this.state.project == null || this.state.phase == null
        }}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
      />
    </div>;
  },

  handleProjectAndPhaseChange: function(project, phase) {
    this.setState({project: project, phase: phase});
  },

  handleSubmit: function() {
    actions.submitAddRevenueRowForm(this.props.modal, this.state.project, this.state.phase);
  },

  handleCancel: function() {
    actions.cancelModal(this.props.modal);
  }
});


var NewAllocationRowForm = CreateReactClass({
  propTypes: {
    initialStaffMember: PropTypes.object,
    initialProject: PropTypes.object
  },

  getInitialState: function() {
    return {
      staffMember: this.props.initialStaffMember || null,
      project: this.props.initialProject || null,
      phase: null,
    };
  },

  render: function() {
    return <div>
      <StaffMemberRow
        value={this.state.staffMember}
        onChange={this.handleStaffMemberChange}
      />
      <ProjectAndPhaseRows
        project={this.state.project}
        phase={this.state.phase}
        onChange={this.handleProjectAndPhaseChange}
        requirePermissionLevel={PermissionLevel.projectManager}
      />
      <ModalFooter
        acceptButtonText="Save"
        acceptButtonProps={{
          disabled: this.state.staffMember == null || this.state.project == null || this.state.phase == null
        }}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
      />
    </div>;
  },

  handleProjectAndPhaseChange: function(project, phase) {
    this.setState({project: project, phase: phase});
  },

  handleStaffMemberChange: function(staffMember) {
    this.setState({staffMember: staffMember});
  },

  handleSubmit: function() {
    actions.submitAddAllocationRowForm(
      this.props.modal,
      this.state.staffMember,
      this.state.project,
      this.state.phase
    );
  },

  handleCancel: function() {
    actions.cancelModal(this.props.modal);
  }
});




var CashFlowChartContainer = CreateReactClass({
  propTypes: {
    contentOffsetWidth: PropTypes.number.isRequired,
    graphData: PropTypes.array.isRequired,
    startMonth: PropTypes.number.isRequired,
    yAxisWidth: PropTypes.string.isRequired, // percent-string
    contentWidth: PropTypes.string.isRequired, // percent-string
    yValueType: PropTypes.oneOf(['dollars', 'hours']).isRequired
  },

  render: function() {
    const data = this.props.graphData;
    const minX = data[0].date;
    const maxX = _.last(data).date + 31; //TODO-next_scheduler_fiddling

    const xScale = new LinearScale({
      minX: data[0].date,
      maxX: data[0].date + 365,
      minY: 0,
      maxY: this.props.contentOffsetWidth
    });

    return <CashFlowChart
      data={data}
      paddingBottom="20%"
      showLegend={false}
      renderer={new SuperimposedDataRenderer()}
      enableHoverTooltips={false}
      min={minX}
      max={maxX}
      viewportScrollLeft={dateConverter.monthIndexToOffset(this.props.startMonth)}
      manageOwnScrolling={false}
      showXAxis={false}
      showScrollbar={false}
      onScroll={this.handleCashFlowGraphScroll}
      xScale={xScale}
      yValueType={this.props.yValueType}
      padding={{
        top: '0%',
        left: this.props.yAxisWidth,
        bottom: '0%',
        right: (100 - parseFloat(this.props.contentWidth) - parseFloat(this.props.yAxisWidth)) + '%'
      }}
    />;
  }
});



var DisembodiedHeader = CreateReactClass({
  propTypes: {
    columnHeaders: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired
  },

  mixins: [
    makeMultipleStoreMixin([organisationStore], function() {
      return {
        currencyFormatter: organisationStore.organisation.currencyFormatter
      };
    })
  ],

  render: function() {
    let self = this;

    function cellStyle(rowIndex) {
      const border = 'solid 1px #ccc';
      return {
        width: (100 / 12) + '%',
        textAlign: 'center',
        borderTop: rowIndex === 0 ? border : null,
        borderBottom: border,
        borderRight: border
      };
    }

    return <div style={{margin: "2em 0"}}>
      <div className="flexbox-container flex-align-items-stretch">
        <div className="flex-0-0-auto" style={{width: parseInt(this.props.columns[0].width) - parseInt(this.props.columns[1].width) + '%'}} />
        <div
            className="flex-0-0-auto flexbox-container flex-align-items-stretch flex-justify-content-end"
            style={{width: this.props.columns[1].width}}>
          <div className="flex-0-0-auto">
            {this.renderButton('left')}
          </div>
        </div>
        <div className="flex-0-0-auto" style={{width: CONTENT_WIDTH}}>
          <div className="flexbox-container flex-align-items-justify">
            {this.props.columnHeaders.map(function(header, i) {
              return <div
                  className="flex-0-0-auto"
                  style={cellStyle(0)}
                  key={i}>
                {header.header}
              </div>;
            })}
          </div>
          <div className="flexbox-container flex-align-items-justify" style={{backgroundColor: 'white'}}>
            {this.props.columnHeaders.map(function(header, i) {
              return <div
                  key={i}
                  className="flex-0-0-auto"
                  style={{
                    opacity: 0.9,
                    ...cellStyle(1),
                    ...(
                      header.type === 'ratio' ?
                        getGradient(header.numerator / header.denominator)
                      : {backgroundColor: '#e6e6e6'}
                    )
                  }}>
                {header.type === 'ratio' ?
                  <span>{formatPercentage0(header.numerator / header.denominator * 100, {ifNull: '-'})}</span>
                :
                  <span>{self.state.currencyFormatter.format(header.value)}</span>
                }
              </div>;
            })}
          </div>
        </div>
        <div
            className="flex-0-0-auto flexbox-container flex-align-items-stretch"
            style={{width: _.last(this.props.columns).width}}>
          <div className="flex-0-0-auto">
            {this.renderButton('right')}
          </div>
        </div>
      </div>
    </div>;
  },

  renderButton: function(direction) {
    return <button
        className="btn btn-sm btn-default"
        style={{padding: '1px 7px', width: '100%', height: '100%'}}
        onClick={direction === 'left' ? actions.moveLeft : actions.moveRight}>
      {direction === 'left' ?
        <i className="fa fa-chevron-left" style={{margin: 0}} />
      : <i className="fa fa-chevron-right" style={{margin: 0}} />
      }
    </button>;
  }
});
