import _ from "underscore";
import React, { useEffect } from "react";
import CreateReactClass from "create-react-class";
import classNames from "classnames";
import { CoincraftHeader } from "./CoincraftHeader.js";
import PropTypes from "prop-types";
import { version as appVersion } from "../package.json";

export var CoincraftPage = (props) => {
	let {
		className,
		header,
		heading,
		saveBar,
		errorBar,
		body,
		bodyStyle,
		sidebar,
		tabs,
		rightButtons,
		extraContent,
		renderHeader,
		bodyClass,
		...otherProps
	} = props;
	renderHeader ??= true;
	// const { visibilityState } = useVisible();
	// useEffect(() => {
	// 	if (visibilityState === "visible") {
	// 		console.log("login-checker");
	// 		apiRequest({
	// 			path: "/user/logged-in",
	// 			method: "get",
	// 			params: { noCache: new Date().getTime(), newApi: true },
	// 			success: (data) => {
	// 				if (!data.loggedIn) authenticationActions.logout();
	// 			},
	// 		});
	// 	}
	// }, [visibilityState]);
	return (
		<div className={"coincraft-page-layout"}>
			<div className="coincraft-page-layout__header">
				<CoincraftHeader />
				{errorBar != null ? (
					<div className="flex-0-0-auto">{errorBar}</div>
				) : saveBar != null ? (
					<div
						style={{
							position: "fixed",
							top: "2.75em",
							width: "100%",
							zIndex: 101,
							textAlign: "center",
							pointerEvents: "none",
						}}
					>
						{saveBar}
					</div>
				) : null}
			</div>
			<div
				className={classNames(
					"coincraft-page-layout__content",
					className
				)}
				{...otherProps}
			>
				<div className="coincraft-page-layout__page">
					{renderHeader && (
						<div className="coincraft-page-layout__page-header">
							{header}
							{heading != null ? (
								<CoincraftPageHeader
									heading={heading}
									rightButtons={rightButtons}
									extraContent={extraContent}
								/>
							) : null}
							{tabs != null ? (
								<div className="page-layout__tabs">{tabs}</div>
							) : null}
						</div>
					)}
					<div
						className={`coincraft-page-layout__page-body ${bodyClass}`}
						style={bodyStyle}
					>
						{body}
					</div>
				</div>
				{sidebar != null ? (
					<div className="coincraft-page-layout__sidebar">
						<div className="coincraft-page-layout__sidebar-content">
							{sidebar}
						</div>
					</div>
				) : null}
			</div>
			<div
				className="dont-print"
				style={{
					backgroundColor: "#ffffffcc",
					fontWeight: "bold",
					fontSize: "0.75em",
					position: "absolute",
					bottom: "0",
					right: "2em",
					height: "1.5em",
					padding: "0 0.5em",
					borderRadius: "0.25em",
					display: "flex",
					alignItems: "center",
					zIndex: 100,
				}}
			>
				v {appVersion}
			</div>
		</div>
	);
};

export var CoincraftPageHeader = CreateReactClass({
	propTypes: {
		heading: PropTypes.node,
		leftButtons: PropTypes.array,
		filter: PropTypes.node,
		rightButtons: PropTypes.node,
		extraContent: PropTypes.node,
	},

	render: function () {
		return (
			<div>
				<div className="flexbox-container flex-space-between">
					<div className="flex-1-1-auto flexbox-container flex-align-items-center">
						<div>
							<div className="heading">{this.props.heading}</div>
							<div>{this.props.subHeading}</div>
						</div>
						{this.props.leftButtons != null &&
						this.props.leftButtons.length > 0
							? this.props.leftButtons
							: null}
					</div>
					<div className="buttons flex-0-0-auto">
						{this.props.filter != null ? (
							<div className="search-field">
								{this.props.filter}
							</div>
						) : null}
						{this.props.rightButtons}
					</div>
				</div>

				{this.props.extraContent}
			</div>
		);
	},
});
