import React from 'react';
import CreateReactClass from 'create-react-class';
import PropTypes from "prop-types";


export var CommaSeparatedList = CreateReactClass({
  propTypes: {
    items: PropTypes.array.isRequired,
    formatter: PropTypes.func.isRequired
  },

  render: function() {
    return <span>
      {this.props.items.map(this.props.formatter).join(", ")}
    </span>;
  }
});
