import {parseFormula} from "./parseFormula";
import tuple from "immutable-tuple";
import { adjustFormulaToValue, formulaDefaults, formulaFormatters, refToString } from "./FormulaUtils";
import cuid from "cuid";
import { getDependants, prepareFormulaForUpdate, storeFormula, updateDependencies, updateFormulas } from "./stores/FormulaStore";
// import { unitDefinitions } from "../../TimelineHelpers";
// // import CanvasStore from "../../CanvasStore";
// import { roundDateToNearestUnit } from "@ryki/datemath";
// import { getReferenceObject } from "./core/ReferenceLookup";

class Formula {
	ref = cuid();
	// context = null;
	prop = null;
	expectedType = null;
	_formula = "";
	cachedAst = {};
	cachedValidAst = {};
	updateListeners = new Set();
	needsUpdate = true;

	constructor({ /*context,*/ ref, prop, expectedType, formula }) {
		// this.context = context;
		this.ref = ref;
		this.prop = prop;
		this._formula = formula ?? formulaDefaults[expectedType] ?? "";
		storeFormula(this);
		this.update = this.update.bind(this);
		this.prepareUpdate = this.prepareUpdate.bind(this);
	}

	get formula() {
		return this._formula;
	}

	set formula(newFormula) {
		this._formula = newFormula;
		this.prepareUpdate();
		updateFormulas();
	}

	update() {
		for (const listenter of this.updateListeners) {
			listenter();
		}
	}

	prepareUpdate() {
		prepareFormulaForUpdate(this);
		this.needsUpdate = true;
		for (const dep of getDependants(this)) {
			dep.prepareUpdate();
		}
	}

	get context() {
		return tuple(this.ref, this.prop);
	}

	get ast() {
		if (!this.needsUpdate) return this.cachedAst;
		const oldDeps = this.cachedValidAst.dependencies || new Map();
		this.cachedAst = parseFormula(this.formula, this.context);
		if (this.cachedAst?.type && this.cachedAst?.type !== "error")
			this.cachedValidAst = { ...this.cachedAst };
		const newDeps = this.cachedValidAst.dependencies || new Map();
		if (
			!(
				oldDeps.size === newDeps.size &&
				[...newDeps.keys()].every((dep) => oldDeps.has(dep))
			)
		) {
			updateDependencies(this, oldDeps, newDeps);
		}
		this.needsUpdate = false;
		return this.cachedAst;
	}

	get validAst() {
		return this.ast?.type && this.ast?.type !== "error";
	}

	get type() {
		return (this.validAst && this.ast.type) || this.cachedValidAst.type;
	}

	get value() {
		return (this.validAst && this.ast.value) || this.cachedValidAst.value;
	}

	get dependencies() {
		return (
			(this.validAst && this.ast.dependencies) ||
			this.cachedValidAst.dependencies ||
			new Map()
		);
	}

	adjustToValue(newVal) {
		const newFormula = adjustFormulaToValue(this.ast, newVal, this.context);
		this.formula = newFormula;
		return newFormula;
	}
	// roundValue() {
	// 	if (this.validAst && this.ast.type === "dateTime") {
	// 		const roundingUnit = unitDefinitions[CanvasStore.lineUnit];
	// 		const roundedVal = roundDateToNearestUnit(
	// 			this.value,
	// 			roundingUnit.base,
	// 			roundingUnit.multiple
	// 		);
	// 		return this.adjustToValue({
	// 			type: "dateTime",
	// 			value: roundedVal.getTime(),
	// 		});
	// 	}
	// }
	// addDependency(depRef) {
	// 	const oldAst = this.ast;
	// 	const depFormula = `#${refToString(
	// 		getReferenceObject(this.context).getLocalRef(
	// 			getReferenceObject(depRef)
	// 		)
	// 	)}.${depRef[2]}`;
	// 	const depAst = parseFormula(depFormula, this.context);
	// 	const newFormula = adjustFormulaToValue(depAst, oldAst, this.context);
	// 	this.formula = newFormula;
	// 	return newFormula;
	// }
	// removeDependencies() {
	// 	this.formula = formulaFormatters[this.type](this.value);
	// 	return this.formula;
	// }
}

export default Formula;
