import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import { DragController, LinearScale } from './utils.js';
import classNames from 'classnames';
import PropTypes from "prop-types";


export var Slider = CreateReactClass({
  propTypes: {
    min: PropTypes.number,
    max: PropTypes.number,

    // Number or percentage as string (eg. "25%").
    width: PropTypes.any,

    height: PropTypes.number,

    padding: PropTypes.object,

    // If provided, the user will not be able to drag any item to the left of this value.
    clampLeft: PropTypes.number,

    // TODO clampRight.

    /**
     * 'none': don't draw a connecting line
     * 'valueRange': draw a connecting line between the leftmost and rightmost values
     * 'fromLeft': draw a connecting line between the leftmost point on the slider
     *             and the first value.
     */
    connectingLineType: PropTypes.oneOf(['none', 'valueRange', 'fromLeft']),

    selectedConnectingLineItemIndex: PropTypes.number,
    onConnectingLineItemClick: PropTypes.func,

    getConnectingLineProps: PropTypes.func,
    // The height of the line connecting the items on the slider.
    connectingLineHeight: PropTypes.number,

    getWidgetLineProps: PropTypes.func,
    // The height of the line covering the whole width of the slider.
    widgetLineHeight: PropTypes.number,

    itemRadius: PropTypes.number,

    /**
     * Normal use case is `values` is an array of numbers (between `min` and `max`).
     * Alternatively, `values` can be an array of arbitrary objects and `getItemValue`
     * can be provided as a function that transforms an object from the `values` array
     * into a number between `min` and `max`.
     */
    values: PropTypes.arrayOf(PropTypes.any),

    throttleMs: PropTypes.number,

    onMove: PropTypes.func,
    onBarMove: PropTypes.func,

    drawItem: PropTypes.func.isRequired,

    getItemValue: PropTypes.func,

    onChange: PropTypes.func,
    onClick: PropTypes.func,

    isEditable: PropTypes.bool
  },

  getInitialState: function() {
    return {
      selected: false,
    };
  },

  getDefaultProps: function() {
    return {
      min: 0,
      max: 100,
      width: 400,

      padding: {
        left: 0,
        right: 0
      },

      clampLeft: null,
      clampRight: null,

      height: 30,

      connectingLineType: 'valueRange',
      selectedConnectingLineItemIndex: null,
      onConnectingLineItemClick: function(index) { },
      connectingLineHeight: 6,
      widgetLineHeight: 2,

      itemRadius: 10,

      throttleMs: null,

      values: [25, 50, 75],
      onChange: function(values) { },
      onClick: function() { },
      onMouseUp: function() { },
      onBarMove: function(itemValue, itemIndex, diff, newX) { },
      getItemValue: item => item,

      getConnectingLineProps: (slider) => ({}),

      onMove: function(currentValues, movedItemValue, movedItemIndex, diff) { },

      isEditable: true
    };
  },

  getWidgetLineProps() {
    var defaults = {
      width: "100%", //this.innerWidth(),
      height: this.props.widgetLineHeight,
      y: (this.props.height - this.props.widgetLineHeight) / 2,
      fill: '#ccc'
    };

    if (this.props.getWidgetLineProps != null) {
      return {...defaults, ...this.props.getWidgetLineProps(this)};
    }
    else {
      return defaults;
    }
  },

  getConnectingLineProps(x1, x2, isSelected) {
    /**
     * `x1`, `x2`: domain values.
     */
    let x = this.valueToCoord(x1);
    let width = this.valueToCoord(x2) - x;

    return {
      x: x + '%',
      width: width + '%',
      y: (this.props.height - this.props.connectingLineHeight) / 2,
      height: this.props.connectingLineHeight,
      fill: "#aaa",
      ...this.props.getConnectingLineProps(this, x1, x2, isSelected)
    };
  },

  setupScale: function(props = this.props) {
    this.scale = new LinearScale({
      minX: props.min,
      maxX: props.max,
      minY: 0,
      maxY: 100
    });
  },

  componentWillMount: function() {
    let self = this;

    this.setupScale();

    function makeDragController(func) {
      return new DragController({
        getSvgNodeFunc: function() {
          return ReactDOM.findDOMNode(self.refs.svg);
        },
        callback: function(pos, origPos, args) {
          var svgCoordsToLogical = new LinearScale({
            minX: 0,
            maxX: ReactDOM.findDOMNode(self.refs.container).offsetWidth,
            minY: self.props.min,
            maxY: self.props.max
          });
          let newX = svgCoordsToLogical.xToY(pos.x);
          let diff = newX - svgCoordsToLogical.xToY(origPos.x);

          if (self.props.clampLeft != null
              && (
                (self.coordToValue(pos.x) < self.props.clampLeft && self.coordToValue(origPos.x) < self.props.clampLeft)
                || self.props.values[0] + diff < self.props.clampLeft
              )) {
            diff = self.props.clampLeft - self.props.values[0];
          }
          func(args.itemValue, args.itemIndex, diff, newX);
        },
        mouseUpCallback: function() {
          self.props.onMouseUp();
        },
        throttleMs: self.props.throttleMs
      });
    };

    this.dragController = makeDragController(function(itemValue, itemIndex, diff, newX) {
      if (self.props.isEditable) {
        self.updateValues(itemValue, itemIndex, diff, newX);
      }
    });
    this.barDragController = makeDragController(function(itemValue, itemIndex, diff, newX) {
      if (self.props.isEditable) {
        self.props.onBarMove(itemValue, itemIndex, diff, newX);
      }
    });
  },

  componentWillReceiveProps: function(newProps) {
    this.setupScale(newProps);
  },

  updateValues: function(movedItemValue, movedItemIndex, diff, newX) {
    this.props.onMove(this.props.values, movedItemValue, movedItemIndex, diff, newX);
  },

  innerWidth: function(props = this.props) {
    return props.width - props.padding.left - props.padding.right;
  },

  valueToCoord: function(val) {
    // Convert an item value (in between this.props.min and this.props.max) to
    // a number of horizontal pixels on the widget.
    return this.scale.xToY(this.props.getItemValue(val));
  },

  coordToValue: function(x) {
    // Inverse of `valueToCoord`
    return this.scale.yToX(x);
  },

  render: function() {
    let self = this;

    let values = this.props.values || [];
    let showConnectingLine = (
      (this.props.connectingLineType === 'valueRange' && values.length >= 2) ||
      (this.props.connectingLineType === 'fromLeft' && values.length >= 1)
    );

    return (
      <div
          ref="container"
          style={{width: this.props.width, height: this.props.height, position: 'relative'}}>
        <svg
            ref="svg"
            style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}}
            className={classNames("slider", this.state.selected ? "slider--selected" : null)}
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}
            onClick={this.handleClick}>
          <g transform={`translate(${this.props.padding.left}, 0)`}>
            <rect x={0} {...this.getWidgetLineProps()} />

            {showConnectingLine ? (
              this.props.connectingLineType === 'valueRange' ?
                values.map(function(itemValue, itemIndex) {
                  if (itemIndex < values.length - 1) {
                    return <rect
                      key={itemIndex}
                      onClick={function(event) {
                        self.handleConnectingLineItemClick(itemIndex, event);
                      }}
                      {...self.getConnectingLineProps(
                        itemValue,
                        values[itemIndex + 1],
                        itemIndex === self.props.selectedConnectingLineItemIndex
                      )}
                      {...self.barDragController.getMouseEventProperties(function() {
                        return {itemValue: itemValue, itemIndex: itemIndex};
                      })}
                    />;
                  }
                  else {
                    return null;
                  }
                })
              : this.props.connectingLineType === 'fromLeft' && values.length > 0 ?
                <rect {...self.getConnectingLineProps(this.props.min, values[0])} />
              : null
            ): null}

            {values.map(function(itemValue, itemIndex) {
              let x = self.valueToCoord(itemValue) + '%';
              return self.props.drawItem(itemValue, itemIndex, x, self);
            })}
          </g>
        </svg>
      </div>
    );
  },

  handleClick: function() {
    this.props.onClick();
  },

  handleMouseOver: function() {
    this.setState({selected: true});
  },

  handleMouseOut: function() {
    this.setState({selected: false});
  },

  handleConnectingLineItemClick: function(itemIndex, event) {
    this.props.onConnectingLineItemClick(itemIndex);
    event.stopPropagation();
  }
});



