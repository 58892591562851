var RSVP = require("rsvp");


export var myHttp = {
  // TODO merge this with jsonHttp
  request: function(options) {
    var deferred = RSVP.defer();
    let xhr = window.$.ajax(options).success(function(data) {
      if (data.status === 'ok') {
        deferred.resolve(data);
      }
      else {
        if (data.error != null) {
          deferred.reject(data.error);
        }
        else {
          deferred.reject("Response was not 'ok'");
        }
      }
    }).error(function() {
      deferred.reject();
    });
    deferred.promise.abort = () => xhr.abort();
    return deferred.promise;
  },

  get: function(options) {
    options.method = "GET";
    return this.request(options);
  },

  post: function(options) {
    options.method = "POST";
    return this.request(options);
  },

  postJson: function(options) {
    options.method = "POST";
    options.data = JSON.stringify(options.data);
    options.contentType = "application/json; charset=utf-8";
    options.dataType = "json";
    return this.request(options);
  },

  del: function(options) {
    options.method = "DELETE";
    return this.request(options);
  }
};


