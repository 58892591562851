import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';
import { formatMinutes, parseTime, isNumber } from "../utils.js";
import PropTypes from "prop-types";


export var HoursInput = CreateReactClass({
	/**
	 * Required behaviour:
	 *
	 * - Never overwrites a value while the user has the box focused (eg. if they type "4",
	 *   don't change it till "4:00" until after blur).
	 * - Calls `onChange` immediately on every key press.
	 *   > If valid, pass a number value (the number of minutes entered) as the
	 *     argument of `onChange`
	 *   > otherwise, pass the exact string entered
	 */
	propTypes: {
		value: PropTypes.oneOfType([
			PropTypes.number, // Valid state
			PropTypes.string, // Invalid state
		]).isRequired,
		className: PropTypes.any,
		onChange: PropTypes.func.isRequired,
		isEditable: PropTypes.bool,
		style: PropTypes.object,
	},

	getDefaultProps: function () {
		return {
			isEditable: true,
		};
	},

	getInitialState: function () {
		return { value: this.formatFunc(this.props.value) };
	},

	componentWillReceiveProps: function (nextProps) {
		if (document.activeElement !== this.refs.input) {
			this.setState({ value: this.formatFunc(nextProps.value) });
		} else {
			// Note that we can get out of sync here if we get a value we didn't
			// expect.
		}
	},

	render: function () {
		let [_, isValid] = this.parseFunc(this.state.value);
		if (this.props.isEditable) {
			return (
				<input
					ref="input"
					type="text"
					value={this.state.value}
					onKeyUp={this.handleKeyUp}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					className={classNames(this.props.className, {
						classNameInvalid: !isValid,
					})}
					disabled={!this.props.isEditable}
					style={{ textAlign: "right", ...this.props.style }}
				/>
			);
		} else {
			return <span>{this.state.value}</span>;
		}
	},

	handleChange: function (event) {
		let text = event.target.value;
		this.setState({ value: text });

		let time = parseTime(text);

		// Don't use `time || text` because `time === 0` is valid.
		this.props.onChange(time != null ? time : text);
	},

	handleBlur: function () {
		let [time, isValid] = this.parseFunc(this.state.value);
		if (isValid) {
			this.setState({ value: this.formatFunc(time) });
		}
	},

	handleFocus: function () {
		let self = this;
		// Select the text box's contents on focus. For some reason if we don't
		// do this in a $timeout the contents are momentarily selected and then
		// deselected.
		setTimeout(function () {
			let node = self.refs.input;

			// If the user tabs really quickly then they might have unfocused the
			// element by the time this callback is called (yes, that is a real thing
			// that happens). So make sure to check we're still the active element.
			if (node === document.activeElement) {
				window.$(node).select();
			}
		});
	},

	handleKeyUp: function (event) {
		let [val, isValid] = this.parseFunc(this.state.value);

		if (isValid) {
			let newTime = null;
			if (event.keyCode === 38) {
				// Up arrow: + 15 minutes
				newTime = val + 15;
			} else if (event.keyCode === 40) {
				// Down arrow: - 15 minutes
				if (val >= 15) {
					newTime = val - 15;
				}
			}
			if (newTime != null) {
				this.setState({ value: this.formatFunc(newTime) });
				this.props.onChange(newTime);
			}
		}
	},

	formatFunc: function (n) {
		return isNumber(n) ? formatMinutes(n) : n;
	},

	parseFunc: function (text) {
		let time = parseTime(text);
		return [time, time != null];
	},

	focus: function () {
		if (this.refs.input) {
			this.refs.input.focus();
		}
	},
});
