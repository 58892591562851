import React from "react";
import CreateReactClass from "create-react-class";
import { makeMultipleStoreMixin } from "../coincraftFlux.js";
import {
	TriStateSaveButton,
	DateValue,
	SmallDeleteButton,
	SaveBar,
	BasicMySelect2,
} from "../widgets.js";
import { userStore } from "../user.js";
import { Table, Column } from "../table.js";
import { rootStore } from "../RootStore.js";
import { CoincraftPage } from "../CoincraftPage.js";
import { settingsStore } from "./settingsStore.js";
import { Checkbox } from "../widgets/generic.js";
import { observer } from "mobx-react";

export var SettingsPage = observer(
	CreateReactClass({
		render: function () {
			return (
				<CoincraftPage
					header={
						<div>
							<div>
								<div className="heading">
									Organisation Settings
								</div>
							</div>
						</div>
					}
					body={
						<div
							style={{
								maxWidth: "60em",
								padding: "2em 4em",
							}}
						>
							<div>
								<Checkbox
									value={settingsStore.settings.allowNoPhase}
									onChange={(value) => {
										settingsStore.changeSetting(
											"allowNoPhase",
											value
										);
									}}
									label="Allow time to be logged without a selecting a phase."
								/>
							</div>
							<div style={{ marginTop: "2em" }}>
								<Checkbox
									value={settingsStore.settings.useTasks}
									onChange={function (value) {
										settingsStore.changeSetting(
											"useTasks",
											value
										);
									}}
									label="Allow time to be logged against tasks."
								/>
							</div>
							<div style={{ marginTop: "2em" }}>
								<Checkbox
									value={
										settingsStore.settings
											.allowAfterPhaseEnd
									}
									onChange={(value) => {
										settingsStore.changeSetting(
											"allowAfterPhaseEnd",
											value
										);
									}}
									label="Allow time to be logged against a phase that has ended."
								/>
							</div>
							<div style={{ marginTop: "3em" }}>
								<p>
									Allow phases with the following statuses to
									be visible when entering time:
								</p>
							</div>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.timeEntryStatus.includes(
										"active"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryStatus",
												[
													"active",
													...settingsStore.settings
														.timeEntryStatus,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryStatus",
												settingsStore.settings.timeEntryStatus.filter(
													(s) => s !== "active"
												)
											);
										}
									}}
									label="Active"
								/>
							</div>
							<div style={{ marginLeft: "2em" }}>
								<Checkbox
									value={settingsStore.settings.timeEntryStatus.includes(
										"prospective"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryStatus",
												[
													"prospective",
													...settingsStore.settings
														.timeEntryStatus,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryStatus",
												settingsStore.settings.timeEntryStatus.filter(
													(s) => s !== "prospective"
												)
											);
										}
									}}
									label="Prospective"
								/>
							</div>
							<div style={{ marginLeft: "2em" }}>
								<Checkbox
									value={settingsStore.settings.timeEntryStatus.includes(
										"onHold"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryStatus",
												[
													"onHold",
													...settingsStore.settings
														.timeEntryStatus,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryStatus",
												settingsStore.settings.timeEntryStatus.filter(
													(s) => s !== "onHold"
												)
											);
										}
									}}
									label="On Hold"
								/>
							</div>
							<div style={{ marginLeft: "2em" }}>
								<Checkbox
									value={settingsStore.settings.timeEntryStatus.includes(
										"archived"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryStatus",
												[
													"archived",
													...settingsStore.settings
														.timeEntryStatus,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryStatus",
												settingsStore.settings.timeEntryStatus.filter(
													(s) => s !== "archived"
												)
											);
										}
									}}
									label="Archived"
								/>
							</div>
							<p style={{ marginTop: "3em" }}>
								Allow staff to flag time entries with the
								following:
							</p>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.timeEntryFlags.includes(
										"variation"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryFlags",
												[
													"variation",
													...settingsStore.settings
														.timeEntryFlags,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryFlags",
												settingsStore.settings.timeEntryFlags.filter(
													(s) => s !== "variation"
												)
											);
										}
									}}
									label="Variation"
								/>
							</div>
							<div style={{ marginLeft: "2em" }}>
								<Checkbox
									value={settingsStore.settings.timeEntryFlags.includes(
										"billable"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryFlags",
												[
													"billable",
													...settingsStore.settings
														.timeEntryFlags,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryFlags",
												settingsStore.settings.timeEntryFlags.filter(
													(s) => s !== "billable"
												)
											);
										}
									}}
									label="Billable"
								/>
							</div>
							<div style={{ marginLeft: "2em" }}>
								<Checkbox
									value={settingsStore.settings.timeEntryFlags.includes(
										"overtime"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryFlags",
												[
													"overtime",
													...settingsStore.settings
														.timeEntryFlags,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryFlags",
												settingsStore.settings.timeEntryFlags.filter(
													(s) => s !== "overtime"
												)
											);
										}
									}}
									label="Overtime"
								/>
							</div>
							{[6, 33].includes(
								settingsStore.organisation.id
							) && (
								<div style={{ marginLeft: "2em" }}>
									<Checkbox
										value={settingsStore.settings.timeEntryFlags.includes(
											"remote"
										)}
										onChange={function (value) {
											if (value) {
												settingsStore.changeSetting(
													"timeEntryFlags",
													[
														"remote",
														...settingsStore
															.settings
															.timeEntryFlags,
													]
												);
											} else {
												settingsStore.changeSetting(
													"timeEntryFlags",
													settingsStore.settings.timeEntryFlags.filter(
														(s) => s !== "remote"
													)
												);
											}
										}}
										label="Remote"
									/>
								</div>
							)}
							{[6, 33].includes(
								settingsStore.organisation.id
							) && (
								<div style={{ marginLeft: "2em" }}>
									<Checkbox
										value={settingsStore.settings.timeEntryFlags.includes(
											"flexi"
										)}
										onChange={function (value) {
											if (value) {
												settingsStore.changeSetting(
													"timeEntryFlags",
													[
														"flexi",
														...settingsStore
															.settings
															.timeEntryFlags,
													]
												);
											} else {
												settingsStore.changeSetting(
													"timeEntryFlags",
													settingsStore.settings.timeEntryFlags.filter(
														(s) => s !== "flexi"
													)
												);
											}
										}}
										label="Flexi"
									/>
								</div>
							)}
							<p style={{ marginTop: "3em" }}>
								Allow staff to enter time against phases:
							</p>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.timeEntryAllocations.includes(
										"noAllocations"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												[
													"noAllocations",
													...settingsStore.settings
														.timeEntryAllocations,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												settingsStore.settings.timeEntryAllocations.filter(
													(s) => s !== "noAllocations"
												)
											);
										}
									}}
									label="Without budgets or allocations for that staff member."
								/>
							</div>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.timeEntryAllocations.includes(
										"budgets"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												[
													"budgets",
													...settingsStore.settings
														.timeEntryAllocations,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												settingsStore.settings.timeEntryAllocations.filter(
													(s) => s !== "budgets"
												)
											);
										}
									}}
									label="With budgets for that staff member."
								/>
							</div>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.timeEntryAllocations.includes(
										"allocations"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												[
													"allocations",
													...settingsStore.settings
														.timeEntryAllocations,
												]
											);
										} else {
											settingsStore.changeSetting(
												"timeEntryAllocations",
												settingsStore.settings.timeEntryAllocations.filter(
													(s) => s !== "allocations"
												)
											);
										}
									}}
									label="With monthly allocations for that staff member."
								/>
							</div>
							<p style={{ marginTop: "3em" }}>
								Auto-populate timesheets for phases:
							</p>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.autoPopulate.includes(
										"budgets"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"autoPopulate",
												[
													"budgets",
													...settingsStore.settings
														.autoPopulate,
												]
											);
										} else {
											settingsStore.changeSetting(
												"autoPopulate",
												settingsStore.settings.autoPopulate.filter(
													(s) => s !== "budgets"
												)
											);
										}
									}}
									label="With time budgeted for that staff member."
								/>
							</div>
							<div
								style={{
									marginTop: "1em",
									marginLeft: "2em",
								}}
							>
								<Checkbox
									value={settingsStore.settings.autoPopulate.includes(
										"allocations"
									)}
									onChange={function (value) {
										if (value) {
											settingsStore.changeSetting(
												"autoPopulate",
												[
													"allocations",
													...settingsStore.settings
														.autoPopulate,
												]
											);
										} else {
											settingsStore.changeSetting(
												"autoPopulate",
												settingsStore.settings.autoPopulate.filter(
													(s) => s !== "allocations"
												)
											);
										}
									}}
									label="With monthly allocations assigned to that staff member."
								/>
							</div>
							<div style={{ marginTop: "2em" }}>
								<Checkbox
									value={
										settingsStore.settings
											.updateHoursFromRevenue
									}
									onChange={function (value) {
										settingsStore.changeSetting(
											"updateHoursFromRevenue",
											value
										);
									}}
									label="Automatically adjust Staff Hours when editing Revenue in Forecasts"
								/>
								<Checkbox
									value={
										settingsStore.settings
											.updateRevenueFromHours
									}
									onChange={function (value) {
										settingsStore.changeSetting(
											"updateRevenueFromHours",
											value
										);
									}}
									label="Automatically adjust Revenue when editing Staff Hours in Forecasts"
								/>
							</div>
							<div style={{ marginTop: "4em" }}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "0.5em",
									}}
								>
									<BasicMySelect2
										style={{
											width: "8em",
											marginRight: "1em",
										}}
										value={
											settingsStore.settings
												.autoUpdateRevenue.action
										}
										onChange={function (value) {
											settingsStore.changeSetting(
												"autoUpdateRevenue",
												{
													...settingsStore.settings
														.autoUpdateRevenue,
													action: value,
												}
											);
										}}
										options={[
											{
												label: "Ask Me to",
												value: "ask",
											},
											{
												label: "Always",
												value: "automatic",
											},
											{
												label: "Never",
												value: "never",
											},
										]}
									/>
									<span>
										Automatically adjust the Revenue
										Forecast when saving Projects and
										Invoices.
									</span>
								</div>
								<Checkbox
									value={
										settingsStore.settings.autoUpdateRevenue
											.adjustOnLoad
									}
									onChange={function (value) {
										settingsStore.changeSetting(
											"autoUpdateRevenue",
											{
												...settingsStore.settings
													.autoUpdateRevenue,
												adjustOnLoad: value,
											}
										);
									}}
									label="Automatically adjust Revenue when loading the Revenue Forecast"
								/>
								<div
									style={{
										padding: "2em",
										borderTop: "1px solid #eee",
									}}
									className="flexbox-container flex-align-items-center"
								>
									<span style={{ display: "inline-block" }}>
										Distribute
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "12em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateRevenue.budget
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateRevenue",
													{
														...settingsStore
															.settings
															.autoUpdateRevenue,
														budget: value,
													}
												);
											}}
											options={[
												{
													label: "Total Budget",
													value: "total",
												},
												{
													label: "Remaining Budget",
													value: "remaining",
												},
											]}
										/>
									</div>
									<span style={{ display: "inline-block" }}>
										between
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "9em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateRevenue.start
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateRevenue",
													{
														...settingsStore
															.settings
															.autoUpdateRevenue,
														start: value,
													}
												);
											}}
											options={[
												{
													label: "Start Date",
													value: "startDate",
												},
												{
													label: "Current Date",
													value: "now",
												},
												{
													label: "End Date",
													value: "endDate",
												},
											]}
										/>
									</div>
									<span style={{ display: "inline-block" }}>
										and
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "9em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateRevenue.end
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateRevenue",
													{
														...settingsStore
															.settings
															.autoUpdateRevenue,
														end: value,
													}
												);
											}}
											options={[
												{
													label: "Start Date",
													value: "startDate",
												},
												{
													label: "End Date",
													value: "endDate",
												},
											]}
										/>
									</div>
								</div>
							</div>
							<div style={{ marginTop: "3em" }}>
								<divs
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "0.5em",
									}}
								>
									<BasicMySelect2
										style={{
											width: "8em",
											marginRight: "1em",
										}}
										value={
											settingsStore.settings
												.autoUpdateHours.action
										}
										onChange={function (value) {
											settingsStore.changeSetting(
												"autoUpdateHours",
												{
													...settingsStore.settings
														.autoUpdateHours,
													action: value,
												}
											);
										}}
										options={[
											{
												label: "Ask Me to",
												value: "ask",
											},
											{
												label: "Always",
												value: "automatic",
											},
											{
												label: "Never",
												value: "never",
											},
										]}
									/>
									<span>
										Automatically adjust the Resource
										Schedule when saving Projects and Time
										entries.
									</span>
								</divs>
								<Checkbox
									value={
										settingsStore.settings.autoUpdateHours
											.adjustOnLoad
									}
									onChange={function (value) {
										settingsStore.changeSetting(
											"autoUpdateHours",
											{
												...settingsStore.settings
													.autoUpdateHours,
												adjustOnLoad: value,
											}
										);
									}}
									label="Automatically adjust Staff Hours when loading the Resource Schedule"
								/>
								<div
									style={{
										padding: "2em",
										borderTop: "1px solid #eee",
									}}
									className="flexbox-container flex-align-items-center"
								>
									<span style={{ display: "inline-block" }}>
										Distribute
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "12em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateHours.budget
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateHours",
													{
														...settingsStore
															.settings
															.autoUpdateHours,
														budget: value,
													}
												);
											}}
											options={[
												{
													label: "Total Budget",
													value: "total",
												},
												{
													label: "Remaining Budget",
													value: "remaining",
												},
											]}
										/>
									</div>
									<span style={{ display: "inline-block" }}>
										between
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "9em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateHours.start
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateHours",
													{
														...settingsStore
															.settings
															.autoUpdateHours,
														start: value,
													}
												);
											}}
											options={[
												{
													label: "Start Date",
													value: "startDate",
												},
												{
													label: "Current Date",
													value: "now",
												},
												{
													label: "End Date",
													value: "endDate",
												},
											]}
										/>
									</div>
									<span style={{ display: "inline-block" }}>
										and
									</span>
									<div
										style={{
											margin: "0 1em",
											width: "9em",
										}}
									>
										<BasicMySelect2
											style={{ width: "100%" }}
											value={
												settingsStore.settings
													.autoUpdateHours.end
											}
											onChange={function (value) {
												settingsStore.changeSetting(
													"autoUpdateHours",
													{
														...settingsStore
															.settings
															.autoUpdateHours,
														end: value,
													}
												);
											}}
											options={[
												{
													label: "Start Date",
													value: "startDate",
												},
												{
													label: "End Date",
													value: "endDate",
												},
											]}
										/>
									</div>
								</div>
								<p style={{ marginTop: "2em" }}>
									When saving Invoices:
									{
										<span style={{ marginLeft: "1em" }}>
											<Checkbox
												value={settingsStore.settings.savingInvoices.includes(
													"automatic"
												)}
												onChange={function (value) {
													if (value) {
														settingsStore.changeSetting(
															"savingInvoices",
															[
																"automatic",
																...settingsStore
																	.settings
																	.savingInvoices,
															]
														);
													} else {
														settingsStore.changeSetting(
															"savingInvoices",
															settingsStore.settings.savingInvoices.filter(
																(s) =>
																	s !==
																	"automatic"
															)
														);
													}
												}}
												label="Automatically"
											/>
										</span>
									}
								</p>
								<div
									style={{
										marginTop: "1em",
										marginLeft: "2em",
									}}
								>
									<Checkbox
										value={settingsStore.settings.savingInvoices.includes(
											"markTimeInvoiced"
										)}
										onChange={function (value) {
											if (value) {
												settingsStore.changeSetting(
													"savingInvoices",
													[
														"markTimeInvoiced",
														...settingsStore
															.settings
															.savingInvoices,
													]
												);
											} else {
												settingsStore.changeSetting(
													"savingInvoices",
													settingsStore.settings.savingInvoices.filter(
														(s) =>
															s !==
															"markTimeInvoiced"
													)
												);
											}
										}}
										label="Mark time entries as invocied."
									/>
								</div>
								<div
									style={{
										marginTop: "1em",
										marginLeft: "2em",
									}}
								>
									<Checkbox
										value={settingsStore.settings.savingInvoices.includes(
											"lockTime"
										)}
										onChange={function (value) {
											if (value) {
												settingsStore.changeSetting(
													"savingInvoices",
													[
														"lockTime",
														...settingsStore
															.settings
															.savingInvoices,
													]
												);
											} else {
												settingsStore.changeSetting(
													"savingInvoices",
													settingsStore.settings.savingInvoices.filter(
														(s) => s !== "lockTime"
													)
												);
											}
										}}
										label="Lock time entries."
									/>
								</div>
							</div>
						</div>
					}
					saveBar={
						<SaveBar
							unsavedChangesMessage="Your settings have unsaved changes."
							isDirty={settingsStore.isDirty}
							saveState={settingsStore.saveState}
							onSaveClick={() => settingsStore.save()}
						/>
					}
				/>
			);
		},
	})
);
