import React from 'react';
import CreateReactClass from 'create-react-class';
import { Link } from 'react-router';
import PropTypes from "prop-types";


export var DashboardPage = CreateReactClass({
  propTypes: {
    heading: PropTypes.string,
    style: PropTypes.object,

    // Optional (but if either is provided both should be provided)
    backButtonPath: PropTypes.string,
    backButtonText: PropTypes.string
  },

  getDefaultProps: function(){
    return {
      style: {},
      padding: true
    };
  },

  render: function() {
    return <div style={this.props.style}>
      {this.props.heading != null ?
        <h2 className="new-dashboard__heading">{this.props.heading}</h2>
      : null}

      {this.props.backButtonPath != null && this.props.backButtonText != null ?
        <div style={{margin: '0.5em 0 1em 0'}}>
          <Link to={this.props.backButtonPath} style={{color: '#d4d4d4', fontSize: '0.9em'}}>
            <i className="fa fa-caret-left" style={{margin: '0 0.4em 0 0'}} />
            {this.props.backButtonText}
          </Link>
        </div>
      : null}

      <div
          className="new-dashboard__panel new-dashboard__panel__inner"
          style={this.props.padding ? null : {padding: 0}}>
        {this.props.children}
      </div>
    </div>;
  }
});
