import React from 'react';
import CreateReactClass from 'create-react-class';
import { actions } from './flux.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import { DateValue, NumberInputContainer, CurrencySymbol, SmallDeleteButton} from '../widgets.js';
import { dateConverter, ProjectPhase } from '../models.js';
import { formatCurrencyWithCents, formatPercentage0, formatNumber2, sum } from "../utils.js";
import PropTypes from "prop-types";


export var PhaseMilestonesList = CreateReactClass({
  propTypes: {
    phase: PropTypes.object.isRequired,
  },

  render: function() {
    return (
      <div style={{margin: '3em 2em'}}>
        <h4>{this.props.phase.getTitle()} - ${formatNumber2(sum(this.props.phase.milestones.map(m => m.revenue)))} / ${formatNumber2(this.props.phase.fee)}</h4>

        <PhaseMilestoneTable
          phase={this.props.phase}
        />

        <button
            onClick={() => actions.createMilestone(this.props.phase)}
            className="btn btn-default">
          <i className="fa fa-plus fa-fw" style={{marginRight: 0, fontSize: '.9em'}} /> Add Milestone
        </button>
      </div>
    );
  }

})


var PhaseMilestoneTable = CreateReactClass({
  propTypes: {
    phase: PropTypes.instanceOf(ProjectPhase).isRequired,
  },

  render: function() {
    let self = this;
    const milestones = this.props.phase.milestones.filter(m => !(m.endDate == this.props.phase.startDate && m.revenue == 0));
    return <div className="coincraft-table" style={{width:'48em', margin: '1em'}}>
      <div className="flexbox-container coincraft-table-row header">
        <div className="coincraft-table-cell" style={{width:'15em'}}>Date</div>
        <div className="coincraft-table-cell" style={{width:'15em', textAlign: 'right'}}>Percent of Phase Fee</div>
        <div className="coincraft-table-cell" style={{width:'15em', textAlign: 'right'}}>Revenue</div>
        <div className="coincraft-table-cell" style={{width:'3em'}}></div>
      </div>
      {milestones.map(function(milestone, i) {
        return <MilestoneRow
          key={i}
          milestone={milestone}
          min={i > 0 ?
            dateConverter.intToMoment(milestones[i - 1].endDate).toDate()
          : dateConverter.intToMoment(self.props.phase.startDate || 0).toDate()}
          max={i < milestones.length - 1 ? dateConverter.intToMoment(milestones[i + 1].endDate).toDate() : null}
        />;
      })}
    </div>;
  }
});


var MilestoneRow = CreateReactClass({
  propTypes: {
    milestone: PropTypes.object.isRequired,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  },

  render: function() {
    const milestone = this.props.milestone;

    return <div className="flexbox-container coincraft-table-row">
      <div className="coincraft-table-cell coincraft-table-cell--date" style={{width:'15em'}}>
        <DateValue
          value={milestone.getEndDate()}
          isEditable={true}
          onChange={(endDate) => actions.setMilestoneDate(milestone.phase, milestone, endDate)}
        />
      </div>
      <div className="coincraft-table-cell coincraft-table-cell--input" style={{width:'15em'}}>
        <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
          <NumberInputContainer
            className="coincraft-table-cell__input"
            value={milestone.percent}
            isEditable={true}
            onChange={(percent) => actions.setMilestonePercent(milestone.phase, milestone, percent)}
            executeActionOn={"blur"}
            formatFunc={n => n != null ? formatNumber2(n) : ''}
          />
          <span className="coincraft-table-cell__symbol">%</span>
        </div>
      </div>
      <div className="coincraft-table-cell coincraft-table-cell--input" style={{width:'15em'}}>
        <div className="flex-1-0-auto flexbox-container flex-align-items-center" style={{width: '100%'}}>
          <span className="coincraft-table-cell__symbol"><CurrencySymbol /></span>
            <NumberInputContainer
              className="coincraft-table-cell__input"
              value={milestone.revenue}
              isEditable={true}
              onChange={(revenue) => actions.setMilestoneRevenue(milestone.phase, milestone, revenue)}
              formatFunc={n => n != null ? formatNumber2(n) : ''}
            />
        </div>
      </div>
      <div className="coincraft-table-cell coincraft-table-cell--btn" style={{width:'3em'}}>
        {milestone.phase.milestones.length > 2 ?
          <SmallDeleteButton onClick={() => actions.deleteMilestone(milestone.phase, milestone)} />
        : null}
      </div>
    </div>;
  }
});
