import _ from "underscore";
import React from "react";
import CreateReactClass from "create-react-class";
import moment from "moment";
import { StoreBase, dispatcher, ActionCollection } from "../coincraftFlux.js";
import { ReportType } from "../reports/Report.js";
import { sum } from "../utils.js";
import { dateConverter, NoProjectProject, NoPhasePhase } from "../models.js";
import {
	ProjectPermissionChecker,
	getProjectPermissionLevel,
} from "../models/permissions.js";
import { PermissionLevel } from "../models/permissions.js";
import { organisationStore } from "../organisation.js";
import { jsonHttp } from "../jsonHttp.js";
import Immutable from "immutable";
import Papa from "papaparse";
import { userStore } from "../user.js";
import { permissions, requiresPermission } from "../models/permissions.js";
import { generateUUID } from "../utils.js";
import { AjaxOperation } from "../AjaxOperation.js";
import { jsonHttp2 } from "../jsonHttp.js";
import { TimesheetItem } from "../models/timesheetitem.js";
import { TimesheetRow } from "./rows/timesheetRow.js";
import apiRequest, { chainRequests } from "../apiRequest.js";
import Axios from "axios";
import { capitalCase } from "change-case";
import { autoAdjustHours } from "../project/AutoAdjustForecasts.js";

export const ScheduleStore = class extends StoreBase {
	constructor() {
		super();
		this.isReady = false;
		// Start at, say, three months before the current month.
		this.currentMonthIndex = dateConverter.momentToMonthIndex(moment());
		this.startMonth = this.currentMonthIndex - 3;
		this.endMonth = this.startMonth + 11;
		this.fetchedStartMonth = this.startMonth;
		this.fetchedEndMonth = this.endMonth;

		this.showFilters = false;
		this.showSidebar = false;
		this.selectedRowId = null;
		this.selectedCellInputText = null;
		this.selectedHoursInputText = null;
		this.selectedBudgetInputText = null;
		this.selectedUtilisationInputText = null;
		this.selectedMonthIndex = null;
		this.timesheetItems = [];
		this.hiddenStaff = new Set();

		this.addRowStaff = [];
		this.addRowProjects = [];
		this.addRowId = null;
		this.dateColumns = this.getDateColumns();

		this.projectSaveState = null;
		this.modal = null;
		this.isDirty = false;
		this.dirtyProjects = [];

		this.reports = [];
		this.defaultReport = null;
		this.selectedReport = null;
		this.loadingData = false;
	}

	initialize() {
		this.showSidebar = false;
		this.selectedRowId = null;
		this.selectedCellInputText = null;
		this.selectedHoursInputText = null;
		this.selectedBudgetInputText = null;
		this.selectedUtilisationInputText = null;
		this.selectedMonthIndex = null;

		this.reports = organisationStore.resourceScheduleReports;
		this.defaultReport =
			organisationStore.organisation.defaultResourceScheduleReport;
		this.selectedReport = this.defaultReport;
		this.isPrinting = false;

		if (this.selectedReport) {
			const selectedReport = organisationStore
				.getResourceScheduleReportByUuid(this.selectedReport)
				.toJS();
			this.reportName = selectedReport.name;
			this.filters = {
				costCentres: selectedReport.filters.costCentres
					.map((ccId) => organisationStore.getCostCentreById(ccId))
					.filter((v) => v),
				projectStatus: selectedReport.filters.projectStatus,
				projects: selectedReport.filters.projects
					.map((pId) => organisationStore.getProjectById(pId))
					.filter((v) => v),
				staff: selectedReport.filters.staff
					.map((sId) => organisationStore.getStaffMemberById(sId))
					.filter((v) => v),
				projectOwners: (
					selectedReport.filters.projectOwners?.map((sId) =>
						organisationStore.getStaffMemberById(sId)
					) || []
				).filter((v) => v),
				roles: selectedReport.filters.roles
					.map((eId) => organisationStore.getStaffRoleById(eId))
					.filter((v) => v),
				futureRows: selectedReport.filters.futureRows || false,
				phaseStatus: selectedReport.filters.phaseStatus || false,
			};
			this.dataType = selectedReport.filters.hoursData;
			this.groups = selectedReport.filters.groups || [
				"role",
				"staff",
				"project",
				"phase",
			];
			this.totalType = selectedReport.filters.totalData || "budgetUse";
			this.percentType =
				selectedReport.filters.percentData || "utilisation";
		} else {
			this.reportName = "New Report";
			this.filters = {
				costCentres: [],
				projectStatus: [],
				projects: [],
				staff: [],
				projectOwners: [],
				roles: [],
				futureRows: false,
				phaseStatus: false,
			};
			this.dataType = "actualsProjected";
			this.groups = ["role", "staff", "project", "phase"];
			this.totalType = "budgetUse";
			this.percentType = "utilisation";
		}

		// this.setGrouping()
		this.staffAvailability = {};
		this.roleAvailability = {};

		this.hoursRows = Immutable.Map({});
		this.expandedItems = [];

		this.save = new AjaxOperation({
			emitChanged: function () {
				let rootStore = require("../RootStore").rootStore;
				rootStore.emitChanged();
			},
		});
		this.emitChanged();

		this.getTimesheetData((data) => {
			if (Object.keys(data).length > 0) {
				this.moveMonths = 0;
				this.fetchedStartMonth = this.startMonth - 12;
				this.fetchedEndMonth = this.endMonth + 12;
				this.setupRows(data.rows);
				if (
					this.hoursRows.get("total") &&
					Object.keys(data).length > 0
				) {
					this.generateSpreadsheetRows();
				}
				this.isReady = true;
				this.emitChanged();
			}
		});
	}

	getTimesheetData(callback) {
		let self = this;
		apiRequest({
			path: `/api/v1/schedule/rows`,
			method: "post",
			data: {
				currentMonthIndex: this.currentMonthIndex,
				monthRange: [this.startMonth - 12, this.endMonth + 12],
				groups: this.groups,
				filters: {
					projectStatus: this.filters.projectStatus,
					costCentres: this.filters.costCentres
						.map((item) => item?.id)
						.filter((v) => v),
					projects: this.filters.projects
						.map((item) => item?.id)
						.filter((v) => v),
					staff: this.filters.staff
						.map((item) => item?.id)
						.filter((v) => v),
					projectOwners: this.filters.projectOwners
						.map((item) => item?.id)
						.filter((v) => v),
					roles: this.filters.roles
						.map((item) => item?.id)
						.filter((v) => v),
					futureRows: this.filters.futureRows || false,
					phaseStatus: this.filters.phaseStatus || false,
				},
				alphabetical: true,
			},
			success: callback,
		});
	}

	get saveState() {
		return this.save.state;
	}

	get selectedRow() {
		return this.hoursRows.get(this.selectedRowId);
	}

	getDateColumns() {
		let columns = [];
		let monthIndex = this.startMonth;
		while (monthIndex <= this.endMonth) {
			let date = dateConverter.monthIndexToMoment(monthIndex); //has funny results
			columns.push({
				date: date,
				dateInt: dateConverter.monthIndexToOffset(monthIndex),
				string: date.format("MMM YY"),
				value: date.format("MMM YY"),
				monthIndex: monthIndex,
				isColumnHeader: true,
				isEditable: false,
			});
			monthIndex += 1;
		}
		return columns;
	}

	getStaffAvailabilityInMonth(staffMember, monthIndex) {
		if (!staffMember) return 0;
		if ([...this.hiddenStaff].includes(staffMember?.uuid)) return 0;
		this.staffAvailability[staffMember?.uuid] =
			this.staffAvailability[staffMember?.uuid] || {};
		if (this.staffAvailability[staffMember?.uuid][monthIndex])
			return this.staffAvailability[staffMember?.uuid][monthIndex];
		this.staffAvailability[staffMember?.uuid][monthIndex] =
			staffMember.getNumHoursAvailableInMonth(
				monthIndex,
				organisationStore.getHolidaysXspans().data
			);
		return this.staffAvailability[staffMember?.uuid][monthIndex];
	}

	getRoleAvailabilityInMonth(staffRole, monthIndex) {
		const uuid = staffRole ? staffRole.uuid : "noRole";
		this.roleAvailability[uuid] = this.roleAvailability[uuid] || {};
		if (this.roleAvailability[uuid][monthIndex])
			return this.roleAvailability[uuid][monthIndex];
		this.roleAvailability[uuid][monthIndex] = sum(
			this.getRoleStaffMembers(staffRole, true).map((sm) => {
				return this.getStaffAvailabilityInMonth(sm, monthIndex);
			})
		);
		return this.roleAvailability[uuid][monthIndex];
	}

	getRoleStaffMembers(staffRole, filterDisplayed) {
		if (staffRole) {
			return organisationStore.staffMembers.filter(
				(sm) =>
					sm.role &&
					sm.role.id === staffRole.id &&
					(filterDisplayed ? this.staffDisplayed(sm) : true)
			);
		} else {
			return organisationStore.staffMembers.filter(
				(sm) =>
					!sm.role &&
					(filterDisplayed ? this.staffDisplayed(sm) : true)
			);
		}
	}

	getAllStaffAvailabilityInMonth(monthIndex) {
		this.roleAvailability["allStaff"] =
			this.roleAvailability["allStaff"] || {};
		if (this.roleAvailability["allStaff"][monthIndex])
			return this.roleAvailability["allStaff"][monthIndex];
		this.roleAvailability["allStaff"][monthIndex] = sum(
			organisationStore.staffMembers
				.filter((sm) => this.staffDisplayed(sm))
				.map((sm) => {
					return this.getStaffAvailabilityInMonth(sm, monthIndex);
				})
		);
		return this.roleAvailability["allStaff"][monthIndex];
	}

	projectDisplayed(project) {
		if (!permissions.canViewProject(project).ok(userStore.user))
			return false;
		if (
			this.filters.projects.length !== 0 &&
			!this.filters.projects.map((p) => p.id).includes(project.id)
		)
			return false;
		if (
			this.filters.costCentres.length !== 0 &&
			!this.filters.costCentres
				.map((cc) => cc.id)
				.includes(project.costCentre.id)
		)
			return false;
		if (
			this.filters.projectStatus.length !== 0 &&
			!this.filters.projectStatus.includes(project.status)
		)
			return false;
		if (
			this.filters.projectOwners.length !== 0 &&
			!this.filters.projectOwners
				.map((po) => po.id)
				.includes(project.owner?.id)
		)
			return false;
		return true;
	}

	staffDisplayed(staffMember) {
		if (!(staffMember.id > 0)) return false;
		if (
			this.filters.staff.length !== 0 &&
			!this.filters.staff.map((s) => s.id).includes(staffMember.id)
		)
			return false;
		if (
			this.filters.costCentres.length !== 0 &&
			!this.filters.costCentres
				.map((cc) => cc.id)
				.includes(staffMember.costCentre.id)
		)
			return false;
		if (
			this.filters.roles.length !== 0 &&
			(!staffMember.role ||
				!this.filters.roles
					.map((r) => r.id)
					.includes(staffMember.role.id))
		)
			return false;
		return true;
	}

	roleDisplayed(staffRole) {
		if (
			this.filters.roles.length !== 0 &&
			(!staffRole ||
				!this.filters.roles.map((r) => r.id).includes(staffRole.id))
		)
			return false;
		return true;
	}

	getDisplayedTimesheetItems() {
		return this.timesheetItems.filter((tsi) => {
			return (
				(tsi.staffMember
					? this.staffDisplayed(tsi.staffMember)
					: true) &&
				tsi.project &&
				this.projectDisplayed(tsi.project) &&
				(tsi.staffRole ? this.roleDisplayed(tsi.staffRole) : true)
			);
		});
	}

	setupRows(rowData) {
		if (!rowData.total) {
			rowData.total = {};
		}
		this.hoursRows = Immutable.Map(
			_.mapObject(rowData, (val, key) => new TimesheetRow(this, val))
		);
	}

	toggleItemExpand(uuid) {
		if (!this.expandedItems.includes(uuid)) {
			this.expandedItems.push(uuid);
		} else {
			this.expandedItems = _.without(this.expandedItems, uuid);
		}
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	generateSpreadsheetRows() {
		this.projectSpreadsheetRows = this.getProjectRows();
		this.graphData = this.getGraphData();
		this.totalSpreadsheetRows = this.getTotalRows();
	}

	toggleItemVisibility(uuid) {
		const itemRow = this.hoursRows.get(uuid);
		itemRow.toggleVisibility();
		const isRole = itemRow.isEntireRole;
		const isStaffMember = itemRow.isEntireStaffMember;
		const visible = itemRow.visible;
		if (isRole && !visible) {
			this.getRoleStaffMembers(itemRow.staffRole).forEach((sm) => {
				this.hiddenStaff.add(sm.uuid);
				this.roleAvailability = {};
			});
		} else if (isRole && visible) {
			this.getRoleStaffMembers(itemRow.staffRole).forEach((sm) => {
				this.hiddenStaff.delete(sm.uuid);
				this.roleAvailability = {};
			});
		} else if (isStaffMember && !visible) {
			this.hiddenStaff.add(itemRow.staffMember?.uuid);
			this.roleAvailability = {};
		} else if (isStaffMember && visible) {
			this.hiddenStaff.delete(itemRow.staffMember?.uuid);
			this.roleAvailability = {};
		}
		//TODO make more effecient
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	setSelectedCellHours(hours) {
		this.selectedHoursInputText = hours;
		this.emitChanged();
		_.debounce(() => {
			this.selectedRow.setHoursMonthIndex(
				this.selectedMonthIndex,
				this.selectedHoursInputText
			);
			this.selectedBudgetInputText = Math.round(
				this.selectedRow.getDisplayedBudgetUseMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.selectedUtilisationInputText = Math.round(
				this.selectedRow.getDisplayedPercentUtilisationMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.generateSpreadsheetRows();
			this.isDirty = true;
			this.emitChanged();
		}, 300)();
	}

	setSelectedBudgetUse(budgetPercent) {
		this.selectedBudgetInputText = budgetPercent;
		this.emitChanged();
		_.debounce(() => {
			this.selectedRow.setBudgetUseMonthIndex(
				this.selectedMonthIndex,
				this.selectedBudgetInputText
			);
			this.selectedHoursInputText = Math.round(
				this.selectedRow.getDisplayedHoursMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.selectedUtilisationInputText = Math.round(
				this.selectedRow.getDisplayedPercentUtilisationMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.generateSpreadsheetRows();
			this.isDirty = true;
			this.emitChanged();
		}, 300)();
	}

	setSelectedUtilisation(utilisationPercent) {
		this.selectedUtilisationInputText = utilisationPercent;
		this.emitChanged();
		_.debounce(() => {
			this.selectedRow.setUtilisationMonthIndex(
				this.selectedMonthIndex,
				this.selectedUtilisationInputText
			);
			this.selectedHoursInputText = Math.round(
				this.selectedRow.getDisplayedHoursMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.selectedBudgetInputText = Math.round(
				this.selectedRow.getDisplayedBudgetUseMonthIndex(
					this.selectedMonthIndex
				)
			);
			this.generateSpreadsheetRows();
			this.isDirty = true;
			this.emitChanged();
		}, 300)();
	}

	setInputText(cell, text) {
		this.selectedCellInputText = text;
		//TODO make more effecient
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	rollbackInputText(cell) {
		this.selectedCellInputText = null;
		//TODO make more effecient
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	commitInputText(cell, text) {
		this.setSelectedCellHours(parseFloat(text));
	}

	changeLikelihood(uuid, val) {
		let row = this.hoursRows.get(uuid);
		let project = row.project;
		let likelihood = parseFloat(val);

		row.updateLikelihood(likelihood);
		project.likelihood = likelihood;

		this.generateSpreadsheetRows();

		this.dirtyProjects.push(project);
		this.isDirty = true;
		this.emitChanged();
	}

	changeSelectedReport(uuid) {
		this.selectedReport = uuid;
		this.isReady = false;
		this.emitChanged();
		if (this.selectedReport) {
			const selectedReport = organisationStore
				.getResourceScheduleReportByUuid(this.selectedReport)
				.toJS();
			this.reportName = selectedReport.name;
			this.filters = {
				costCentres: selectedReport.filters.costCentres
					.map((ccId) => organisationStore.getCostCentreById(ccId))
					.filter((v) => v),
				projectStatus: selectedReport.filters.projectStatus,
				projects: selectedReport.filters.projects
					.map((pId) => organisationStore.getProjectById(pId))
					.filter((v) => v),
				staff: selectedReport.filters.staff
					.map((sId) => organisationStore.getStaffMemberById(sId))
					.filter((v) => v),
				projectOwners: (
					selectedReport.filters.projectOwners?.map((sId) =>
						organisationStore.getStaffMemberById(sId)
					) || []
				).filter((v) => v),
				roles: selectedReport.filters.roles
					.map((eId) => organisationStore.getStaffRoleById(eId))
					.filter((v) => v),
				futureRows: selectedReport.filters.futureRows || false,
				phaseStatus: selectedReport.filters.phaseStatus || false,
			};
			this.dataType = selectedReport.filters.hoursData;
			this.groups = selectedReport.filters.groups || [
				"role",
				"staff",
				"project",
				"phase",
			];
			this.totalType = selectedReport.filters.totalData || "budgetUse";
			this.percentType =
				selectedReport.filters.percentData || "utilisation";
		} else {
			this.reportName = "New Report";
			this.filters = {
				costCentres: [],
				projectStatus: [],
				projects: [],
				staff: [],
				projectOwners: [],
				roles: [],
				futureRows: false,
				phaseStatus: false,
			};
			this.dataType = "actualsProjected";
			this.groups = ["role", "staff", "project", "phase"];
			this.totalType = "budgetUse";
			this.percentType = "utilisation";
		}
		this.staffAvailability = {};
		this.roleAvailability = {};
		this.getTimesheetData((data) => {
			this.fetchedStartMonth = this.startMonth - 12;
			this.fetchedEndMonth = this.endMonth + 12;
			this.setupRows(data.rows);
			this.generateSpreadsheetRows();
			this.isReady = true;
			this.emitChanged();
		});
		this.emitChanged();
	}

	clickAddStaffRowButton(rowId) {
		this.addRowId = rowId;
		this.openAddStaffRowModal();
		this.emitChanged();
	}

	clickAddProjectRowButton(rowId) {
		this.addRowId = rowId;
		this.openAddProjectRowModal();
		this.emitChanged();
	}

	updateAddRowStaff(options) {
		this.addRowStaff = options;
		this.emitChanged();
	}

	updateAddRowProjects(options) {
		this.addRowProjects = options;
		this.emitChanged();
	}

	addProjectsToStaff() {
		const row = this.hoursRows.get(this.addRowId);
		row.addChildProjects(this.addRowProjects);
		this.generateSpreadsheetRows();
		this.addRowProjects = [];
		this.addRowId = null;
		this.isDirty = true;
		this.closeModal();
		this.emitChanged();
	}

	addStaffToProjects() {
		const row = this.hoursRows.get(this.addRowId);
		row.addChildStaff(this.addRowStaff);
		this.generateSpreadsheetRows();
		this.addRowStaff = [];
		this.addRowId = null;
		this.isDirty = true;
		this.closeModal();
		this.emitChanged();
	}

	clickSaveReportButton() {
		if (this.selectedReport) {
			this.saveReport();
		} else {
			this.openSaveReportModal();
		}
		this.emitChanged();
	}

	clickSaveAsReportButton() {
		this.selectedReport = null;
		this.openSaveReportModal();
		this.emitChanged();
	}

	clickRenameReportButton() {
		this.openRenameReportModal();
		this.emitChanged();
	}

	clickDeleteReportButton() {
		this.openDeleteReportModal();
		this.emitChanged();
	}

	saveReport(name = null) {
		let self = this;
		const UUID = this.selectedReport || generateUUID();
		const selectedReport = this.selectedReport
			? organisationStore
					.getResourceScheduleReportByUuid(this.selectedReport)
					.toJS()
			: null;

		this.save.execute(
			apiRequest({
				path: `/organisation/current/res-report/${UUID}`,
				method: "post",
				data: {
					report: {
						uuid: UUID,
						name: name || this.reportName,
						filters: {
							hoursData: this.dataType,
							groups: this.groups,
							totalData: this.totalType,
							percentData: this.percentType,
							projectStatus: this.filters.projectStatus,
							costCentres: this.filters.costCentres.map(
								(item) => item?.id
							),
							projects: this.filters.projects.map(
								(item) => item?.id
							),
							staff: this.filters.staff.map((item) => item?.id),
							projectOwners: this.filters.projectOwners.map(
								(item) => item?.id
							),
							roles: this.filters.roles.map((item) => item?.id),
							futureRows: this.filters.futureRows || false,
							phaseStatus: this.filters.phaseStatus || false,
						},
					},
				},
				success: (data) => self.saveReportSuccess(data),
				error: (data) => self.saveReportFailure(data),
			})
		);
	}

	saveReportSuccess(data) {
		organisationStore.updateResourceReport(data.report);
		this.reports = organisationStore.resourceScheduleReports;
		this.modal = null;
		this.changeSelectedReport(data.report.uuid);
		// router.history.replace({pathname: this.report.getPath()});
	}

	saveReportFailure() {
		alert(
			"There was a problem saving this report. Try again, or let us know if the problem persists."
		);
		this.reportSaveState = null;
	}

	deleteReport() {
		let self = this;
		apiRequest({
			path: `/organisation/current/res-report/${this.selectedReport}`,
			method: "delete",
			success: (data) => self.deleteReportSuccess(data),
			error: (data) =>
				alert(
					"There was a problem deleting this report. Try again, or let us know if the problem persists."
				),
		});
	}

	setDefaultReport() {
		let self = this;
		apiRequest({
			path: `/organisation/current/default-res-report/${this.selectedReport}`,
			method: "post",
			success: (data) => self.setDefaultReportSuccess(data),
			error: (data) =>
				alert(
					"There was a problem setting this report as the default. Try again, or let us know if the problem persists."
				),
		});
	}

	setDefaultReportSuccess(data) {
		organisationStore.organisation.defaultResourceScheduleReport =
			data.reportUUID;
		this.defaultReport = data.reportUUID;
		this.emitChanged();
	}

	deleteReportSuccess(data) {
		organisationStore.deleteResourceReport(data.reportUUID);
		this.reports = organisationStore.resourceScheduleReports;
		this.selectedReport = null;
		this.modal = null;
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	openSaveReportModal() {
		this.modal = "saveReport";
		this.emitChanged();
	}

	openRenameReportModal() {
		this.modal = "rename";
		this.emitChanged();
	}

	openDeleteReportModal() {
		this.modal = "delete";
		this.emitChanged();
	}

	openAddStaffRowModal() {
		this.modal = "addStaffRow";
		this.emitChanged();
	}

	openAddProjectRowModal() {
		this.modal = "addProjectRow";
		this.emitChanged();
	}

	closeModal() {
		this.modal = null;
		this.emitChanged();
	}

	setFilteredProjects(projects) {
		this.filters.projects = projects.filter((v) => v);
		this.emitChanged();
	}

	setFilteredProjectStatus(projectStatuses) {
		this.filters.projectStatus = projectStatuses;
		this.emitChanged();
	}

	setFilteredStaff(staff) {
		this.filters.staff = staff.filter((v) => v);
		this.roleAvailability = {};
		this.emitChanged();
	}

	setFilteredProjectOwners(projectOwners) {
		this.filters.projectOwners = projectOwners.filter((v) => v);
		// this.roleAvailability = {};
		this.emitChanged();
	}

	setFilteredRoles(roles) {
		this.filters.roles = roles.filter((v) => v);
		this.roleAvailability = {};
		this.emitChanged();
	}

	setFilteredCostCenters(costCentres) {
		this.filters.costCentres = costCentres.filter((v) => v);
		this.emitChanged();
	}

	setFutureRows(futureRows) {
		this.filters.futureRows = futureRows;
		this.emitChanged();
	}

	setPhaseStatusFilter(usePhaseStatus) {
		this.filters.phaseStatus = usePhaseStatus;
		this.emitChanged();
	}

	setDataType(dataType) {
		this.dataType = dataType;
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	setGroups(groups) {
		this.groups = groups;
		this.emitChanged();
	}

	updateSpreadsheet() {
		this.isReady = false;
		this.emitChanged();
		this.getTimesheetData((data) => {
			this.fetchedStartMonth = this.startMonth - 12;
			this.fetchedEndMonth = this.endMonth + 12;
			this.setupRows(data.rows);
			this.generateSpreadsheetRows();
			this.isReady = true;
			this.emitChanged();
		});
	}

	setTotalType(totalType) {
		this.totalType = totalType;
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	setPercentType(percentType) {
		this.percentType = percentType;
		this.generateSpreadsheetRows();
		this.emitChanged();
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
		this.emitChanged();
	}

	selectCell(cell) {
		this.selectedRowId = cell ? cell.uuid : null;
		this.selectedMonthIndex = cell ? cell.monthIndex : null;
		this.selectedHoursInputText = Math.round(
			this.selectedRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedBudgetInputText = Math.round(
			this.selectedRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedUtilisationInputText = Math.round(
			this.selectedRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.showSidebar = cell ? true : false;
		this.emitChanged();
	}

	selectHistoryMenuMonth(monthIndex) {
		this.selectedMonthIndex = monthIndex;
		this.selectedHoursInputText = Math.round(
			this.selectedRow.getDisplayedHoursMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedBudgetInputText = Math.round(
			this.selectedRow.getDisplayedBudgetUseMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.selectedUtilisationInputText = Math.round(
			this.selectedRow.getDisplayedPercentUtilisationMonthIndex(
				this.selectedMonthIndex
			)
		);
		this.emitChanged();
	}

	saveAll(thenFunc) {
		let projects = [...new Set(this.dirtyProjects)];
		this.projectSaveState = "saving";
		this.emitChanged();
		chainRequests(
			projects.map((p) => {
				const serializedProject = p.serialize();
				return () =>
					apiRequest({
						url: `/organisation/current/project/${p.id}`,
						method: "post",
						data: {
							project: {
								id: serializedProject.id,
								phases: serializedProject.phases.map((ph) => {
									return {
										id: ph.id,
										uuid: ph.uuid,
										allocations: ph.allocations,
										milestones: ph.milestones,
									};
								}),
							},
						},
					});
			})
		).then((responseArr) => {
			thenFunc && thenFunc();
			actions.saveAllSuccess(projects, responseArr);
		});
	}

	saveAllSuccess(projects, { projectIds, objects, phaseUuidToIdLookup }) {
		let self = this;
		this.dirtyProjects = [];
		this.isDirty = false;
		this.projectSaveState = null;
		this.emitChanged();
	}

	*iterVisibleProjects() {
		const user = userStore.user;

		for (let p of organisationStore.projects) {
			if (user.isAdmin || getProjectPermissionLevel(p, user) != null) {
				yield p;
			}
		}
	}

	moveBy(numMonths) {
		this.moveMonths += numMonths;
		_.debounce(() => {
			if (!this.moveMonths) return true;
			this.startMonth += this.moveMonths;
			this.endMonth += this.moveMonths;
			this.dateColumns = this.getDateColumns();
			this.moveMonths = 0;
			if (
				this.startMonth < this.fetchedStartMonth ||
				this.endMonth > this.fetchedEndMonth
			) {
				this.loadingData = true;
				this.emitChanged();
				this.getTimesheetData((data) => {
					this.fetchedStartMonth = this.startMonth - 12;
					this.fetchedEndMonth = this.endMonth + 12;
					this.setupRows(data.rows);
					this.generateSpreadsheetRows();
					this.loadingData = false;
					this.emitChanged();
				});
			} else {
				this.generateSpreadsheetRows();
				this.emitChanged();
			}
		}, 300)();
	}

	moveLeft() {
		this.moveBy(-1);
	}

	moveRight() {
		this.moveBy(+1);
	}

	getColumns() {
		const rowHeadings = [
			"Title",
			<div style={{ width: "100%" }} className="flexbox-container">
				<div className="flex-1-0-auto">
					{capitalCase(this.totalType)}
				</div>
				<div className="flex-0-1-auto" style={{ fontSize: "1.6em" }}>
					<i
						className={`fa fa-caret-left`}
						style={{ cursor: "pointer" }}
						onClick={() => actions.moveLeft()}
					/>
					<i
						className={`fa fa-caret-right`}
						style={{ marginRight: 0, cursor: "pointer" }}
						onClick={() => actions.moveRight()}
					/>
				</div>
			</div>,
		];
		const columns = [
			...rowHeadings.map((v) => {
				return {
					value: v,
					isColumnHeader: true,
					isRowHeader: true,
					isEditable: false,
				};
			}),
			...this.dateColumns,
		];
		return columns;
	}

	getProjectRows() {
		const rowData = this.hoursRows
			.get("total")
			.children.filter((c) => c.isDisplayed);
		if (rowData.length === 0) return [];
		const columns = this.getColumns();
		let rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
		];
		rowData.forEach((tsRow) => {
			rows = [...rows, ...this.getRowData(tsRow)];
		});
		return rows;
	}

	getRowData(tsRow, shadow = false) {
		let rows = [];
		rows.push(this.generateSpreasheetRow(tsRow, shadow));
		if (
			(!tsRow.expandable || tsRow.expanded) &&
			tsRow.childrenIds.size > 0
		) {
			// can't get index when iterating through set()
			// so convert to array
			[...tsRow.children]
				.filter((childRow) => childRow.isDisplayed)
				.forEach((childRow, i) => {
					rows = [...rows, ...this.getRowData(childRow, i === 0)];
				});
		}
		return rows;
	}

	generateSpreasheetRow(tsRow, shadow = false) {
		const dateColumns = this.dateColumns;
		let totalString, error;
		if (this.totalType === "budgetUse") {
			const totalHours = Math.round(tsRow.totalProjectedHours);
			const budget = Math.round(tsRow.budget);
			const percent = Math.round(
				budget ? (totalHours / budget) * 100 : 0
			);
			totalString = `${totalHours} / ${budget} (${percent}%)`;
			error = budget && percent > 100;
		} else if (this.totalType === "remainingBudget") {
			const totalHours = Math.round(tsRow.totalProjectedHours);
			const budget = Math.round(tsRow.budget);
			const remainingBudget = Math.round(budget - totalHours);
			totalString = `${remainingBudget}`;
			error = budget && remainingBudget < 0;
		} else {
			const totalHours = Math.round(tsRow.averageDisplayedHours);
			const budget = Math.round(tsRow.averageAvailableHours);
			const percent = Math.round(
				budget ? (totalHours / budget) * 100 : 0
			);
			totalString = `${totalHours} / ${budget} (${percent}%)`;
			error = budget && percent > 100;
		}
		return {
			rowType: tsRow.rowDisplayType + (shadow ? " shadow" : ""),
			cells: [
				{
					value: tsRow.title,
					uuid: tsRow.uuid,
					cellType: "title",
					isRowHeader: true,
					isEditable: false,
					expandable: tsRow.expandable && tsRow.childrenIds.size > 0,
					hideable: tsRow.hideable,
					expanded: tsRow.expanded,
					visible: tsRow.visible,
					addStaffRowButton: tsRow.addStaffRowButton,
					addProjectRowButton: tsRow.addProjectRowButton,
				},
				{
					value: totalString,
					isRowHeader: true,
					isEditable: false,
					error: error,
					visible: tsRow.visible,
				},
				...dateColumns.map((d) =>
					this.getMonthIndexCell(tsRow, d.monthIndex)
				),
			],
		};
	}

	getMonthIndexCell(row, monthIndex) {
		if (!row || !monthIndex) return null;
		const selectedCell =
			row.selected && this.selectedMonthIndex === monthIndex;
		const monthInput = selectedCell ? this.selectedCellInputText : "";
		const monthTotal = row.getDisplayedHoursMonthIndex(monthIndex);
		const percentFuncs = {
			utilisation: (row) =>
				row.getDisplayedPercentUtilisationMonthIndex(monthIndex),
			budgetUse: (row) => row.getDisplayedBudgetUseMonthIndex(monthIndex),
			monthlyBudgetUse: (row) =>
				row.getDisplayedMonthlyBudgetUseMonthIndex(monthIndex),
			remainingMonthlyBudgetUse: (row) =>
				row.getDisplayedRemainingMonthlyBudgetUseMonthIndex(monthIndex),
			remainingMonthlyBudgetUseCapped: (row) =>
				row.getDisplayedRemainingMonthlyBudgetUseCappedMonthIndex(
					monthIndex
				),
		};
		const percent = percentFuncs[this.percentType](row);
		return {
			value: `${Math.round(monthTotal)} (${Math.round(percent)}%)`,
			percent: percent,
			numValue: monthTotal,
			uuid: row.uuid,
			inputText: monthInput,
			monthIndex: monthIndex,
			row: row,
			visible: row.visible,
			inRange:
				row.startMonthIndex &&
				row.endMonthIndex &&
				monthIndex >= row.startMonthIndex &&
				monthIndex <= row.endMonthIndex,
			isProject: true,
			isEditable: row.editable,
			error: false,
		};
	}

	get selectedCell() {
		return this.getMonthIndexCell(
			this.selectedRow,
			this.selectedMonthIndex
		);
	}

	getTotalRows() {
		const dateColumns = this.dateColumns;
		const columns = this.getColumns();
		const tsRow = this.hoursRows.get("total");
		const rows = [
			{
				rowType: "header",
				cells: [...columns],
			},
			this.generateSpreasheetRow(tsRow),
			// {cells:[
			//   {
			//     value: "Total Hours",
			//     isRowHeader: true,
			//     isEditable: false
			//   },
			//   {value: '', isRowHeader: true, isEditable: false},
			//   ...dateColumns.map(d => this.getMonthIndexCell(tsRow, d.monthIndex))
			// ]},
		];
		return rows;
	}

	getGraphData() {
		return this.dateColumns.map((dc, i) => {
			return {
				date: dc.dateInt,
				monthIndex: dc.monthIndex,
				income: this.hoursRows
					.get("total")
					.getTotalAvailableHoursInMonth(dc.monthIndex),
				spend: this.hoursRows
					.get("total")
					.getDisplayedHoursMonthIndex(dc.monthIndex),
				prospectiveSpend: this.hoursRows
					.get("total")
					.getProspectiveHoursMonthIndex(dc.monthIndex),
			};
		});
	}

	printSpreadsheet(isPrinting) {
		this.isPrinting = isPrinting;
		this.emitChanged();
	}

	openAutoAdjustModal() {
		this.modal = "autoAdjust";
		this.emitChanged();
	}

	autoAdjustHours() {
		this.isReady = false;
		this.dirtyProjects.push(...autoAdjustHours());
		this.isDirty = true;
		this.emitChanged();
		this.saveAll(() => this.initialize());
	}

	exportSpreadsheet() {
		let data = [
			[
				"Project",
				"Phase",
				"StaffMember",
				"Role",
				...this.dateColumns.map((dc) => dc.string),
			],
			...Object.values(this.hoursRows.toJS())
				.filter((row) => row.isLeaf)
				.map((row) => [
					row.project ? row.project.getTitle() : "No Project",
					row.phase ? row.phase.getTitle() : "No Phase",
					row.staffMember
						? row.staffMember.getFullName()
						: "No Staff Member",
					row.staffRole ? row.staffRole.name : "No Role",
					...this.dateColumns.map((dc) =>
						row.getDisplayedHoursMonthIndex(dc.monthIndex)
					),
				]),
		];

		let csvContent = Papa.unparse(data);

		let download = function (content, fileName, mimeType) {
			let a = document.createElement("a");
			mimeType = mimeType || "application/octet-stream";

			if (navigator.msSaveBlob) {
				// IE10
				navigator.msSaveBlob(
					new Blob([content], {
						type: mimeType,
					}),
					fileName
				);
			} else if (URL && "download" in a) {
				//html5 A[download]
				a.href = URL.createObjectURL(
					new Blob([content], {
						type: mimeType,
					})
				);
				a.setAttribute("download", fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} else {
				window.location.href =
					"data:application/octet-stream," +
					encodeURIComponent(content); // only this mime type is supported
			}
		};

		download(
			csvContent,
			"resource-schedule.csv",
			"text/csv;encoding:utf-8"
		);
	}
};

export let scheduleStore = new ScheduleStore();

export let actions = new ActionCollection(
	"NEW_SCHEDULE_",
	scheduleStore,
	[
		{ name: "moveLeft", args: [], callback: "default" },
		{ name: "moveRight", args: [], callback: "default" },
		{ name: "toggleItemExpand", args: ["uuid"], callback: "default" },
		{ name: "toggleItemVisibility", args: ["uuid"], callback: "default" },
		{ name: "setDataType", args: ["dataType"], callback: "default" },
		{ name: "setGroups", args: ["groups"], callback: "default" },
		{ name: "updateSpreadsheet", args: [], callback: "default" },
		{ name: "setTotalType", args: ["totalType"], callback: "default" },
		{ name: "setPercentType", args: ["percentType"], callback: "default" },

		{ name: "setSelectedCellHours", args: ["hours"], callback: "default" },
		{
			name: "setSelectedBudgetUse",
			args: ["percentBudgetUse"],
			callback: "default",
		},
		{
			name: "setSelectedUtilisation",
			args: ["percentUtilisation"],
			callback: "default",
		},

		{ name: "setInputText", args: ["cell", "text"], callback: "default" },
		{ name: "rollbackInputText", args: ["cell"], callback: "default" },
		{
			name: "commitInputText",
			args: ["cell", "text"],
			callback: "default",
		},

		{
			name: "changeLikelihood",
			args: ["uuid", "text"],
			callback: "default",
		},
		{ name: "changeSelectedReport", args: ["uuid"], callback: "default" },

		{ name: "saveAll", args: [], callback: "default" },
		{
			name: "saveAllSuccess",
			args: ["projects", "data"],
			callback: "default",
		},

		{
			name: "setFilteredProjects",
			args: ["projects"],
			callback: "default",
		},
		{
			name: "setFilteredCostCenters",
			args: ["costCentres"],
			callback: "default",
		},
		{ name: "setFutureRows", args: ["futureRows"], callback: "default" },
		{
			name: "setPhaseStatusFilter",
			args: ["usePhaseStatus"],
			callback: "default",
		},
		{ name: "setFilteredStaff", args: ["staff"], callback: "default" },
		{
			name: "setFilteredProjectOwners",
			args: ["staff"],
			callback: "default",
		},
		{
			name: "setFilteredProjectStatus",
			args: ["projectStatuses"],
			callback: "default",
		},
		{ name: "setFilteredRoles", args: ["roles"], callback: "default" },
		{ name: "toggleFilters", args: [], callback: "default" },
		{ name: "selectCell", args: ["cell"], callback: "default" },
		{
			name: "selectHistoryMenuMonth",
			args: ["monthIndex"],
			callback: "default",
		},
		{ name: "exportSpreadsheet", args: ["cell"], callback: "default" },
		{ name: "printSpreadsheet", args: ["isPrinting"], callback: "default" },

		{
			name: "clickAddStaffRowButton",
			args: ["rowId"],
			callback: "default",
		},
		{
			name: "clickAddProjectRowButton",
			args: ["rowId"],
			callback: "default",
		},
		{
			name: "updateAddRowProjects",
			args: ["options"],
			callback: "default",
		},
		{ name: "updateAddRowStaff", args: ["options"], callback: "default" },
		{ name: "addProjectsToStaff", args: [], callback: "default" },
		{ name: "addStaffToProjects", args: [], callback: "default" },

		{ name: "clickSaveReportButton", args: [], callback: "default" },
		{ name: "clickSaveAsReportButton", args: [], callback: "default" },
		{ name: "clickRenameReportButton", args: [], callback: "default" },
		{ name: "clickDeleteReportButton", args: [], callback: "default" },
		{ name: "closeModal", args: [], callback: "default" },
		{ name: "saveReport", args: ["name"], callback: "default" },
		{ name: "deleteReport", args: [], callback: "default" },
		{ name: "setDefaultReport", args: [], callback: "default" },
		{ name: "openAutoAdjustModal", args: [], callback: "default" },
		{ name: "autoAdjustHours", args: [], callback: "default" },
	],
	dispatcher,
	function (action) {}
).actionsDict;
