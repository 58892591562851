import { organisationStore } from "../../organisation";

export const projectProperties = (prop, projectValue, context) => {
	const dependencies = projectPropDepLookup[prop]?.(projectValue, context) || new Map();
	if (dependencies.get(context)) {
		throw {
			value: "Circular reference.",
			type: "error",
			dependencies: dependencies
		};
	}
	const val =
		projectPropertiesLookup[prop]?.(projectValue, context)
	return val && ({...val, dependencies});
}

const projectPropertiesLookup = {
	code: (projectValue, context) => {
		return {
			type: "text",
			value: projectValue.value.jobCode,
		};
	},
	name: (projectValue, context) => {
		return {
			type: "text",
			value: projectValue.value.name,
		};
	},
	invNum: (projectValue, context) => {
		return {
			type: "number",
			value:
				organisationStore.objects.Invoice.list.filter(
					(i) => i.projectId === projectValue.value.id
				).length +
				1 +
				(organisationStore.organisation.settings.invoiceNumber
					?.numProjAdjustors?.[projectValue.value.id] || 0),
		};
	},
	invNumYear: (projectValue, context) => {
		return {
			type: "number",
			value:
				organisationStore.objects.Invoice.list.filter(
					(i) =>
						i.projectId === projectValue.value.id &&
						i.createdAt.year() === new Date().getFullYear()
				).length +
				1 +
				(organisationStore.organisation.settings.invoiceNumber
					?.numProjAdjustors?.[projectValue.value.id]?.[
					new Date().getFullYear()
				] || 0),
		};
	},
};

const projectPropDepLookup = {
	code: (projectValue, context) =>
		new Map([...(projectValue.dependencies || [])]),
	name: (projectValue, context) =>
		new Map([...(projectValue.dependencies || [])]),
	numInvoices: (projectValue, context) =>
		new Map([...(projectValue.dependencies || [])]),
	numInvoicesThisYear: (projectValue, context) =>
		new Map([...(projectValue.dependencies || [])]),
};
        
export const projectMethods = {};