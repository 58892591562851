export { StateAwareRefreshButton, CostCentreSelector,
  StaffMemberSelector, StaffMemberRoleSelector, TaskSelector, ProjectReportSelector,
  StaffMemberReportSelector, LocalisedShortDate } from './widgets/coincraft.js';
export { ContactSelector } from './widgets/ContactSelector.js';
export { Checkbox, RadioButton, MySelect2, BasicMySelect2, SmallDeleteButton, CurrencyValue,
  TextValue, DateValue, NumberInputContainer, SelectValue, BooleanValue,
  ActionTextBox, ActionButton, RefreshButton,
  SaveButton, FilterTextBox, DeleteButton, AlertBar,
  ProgressBar, ProgressBar2, Dropdown3, Dropdown3ListItem,
  EditItemControls, ItemExpandedState, ItemExpandedToggle,
  OptionallyEditableValue, AutosizeNumber, LoadingSpinner, ConfirmableDeleteButton
} from './widgets/generic.js';
export { ProjectSelector } from './widgets/ProjectSelector.js';
export { ProjectPhaseSelector } from './widgets/ProjectPhaseSelector.js';
export { MilestonesSaveBar } from './widgets/savebar.js';
export { PageLoadingSpinner } from './widgets/PageLoadingSpinner.js';
export { CheckboxList } from './widgets/checkboxlist.js';
export { actionMixin } from './widgets/utils.js';
export { SaveBar } from './widgets/savebar.js';
export { TriStateSaveButton } from './widgets/TriStateSaveButton.js';
export { HoursInput } from './widgets/HoursInput.js';
export { TablePageHeading } from './widgets/TablePageHeading.js';
export { CommaSeparatedList } from './widgets/CommaSeparatedList.js';
export { ErrorPanel } from './widgets/ErrorPanel.js';
export { IntercomMessageLink } from './widgets/IntercomMessageLink.js';
export { DashboardPage } from './widgets/DashboardPage.js';
export { CurrencyIcon, CurrencySymbol } from './widgets/CurrencyIcon.js';
export { Tabs } from './widgets/tabs.js';
export { Alert, ErrorAlert, WarningAlert, InfoAlert, SuccessAlert } from './widgets/alerts.js';

// This seems to be required for `DateTimePicker`.
var Globalize = require('globalize');
var globalizeLocalizer = require('react-widgets/lib/localizers/globalize');
globalizeLocalizer(Globalize);
