import React from 'react';
import CreateReactClass from 'create-react-class';
import { sum } from '../utils.js';
import { ActionButton, NumberInputContainer, CurrencyIcon } from '../widgets.js';
import { Project, dateConverter, organisationHolidays } from '../models.js';
import { actions, store } from './flux.js';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { LargeSidebarInput } from './SidebarUtils.js';
import { SidebarStaffMember } from './SidebarStaffMember.js';
import { HoursRow } from './HoursRow.js';
import { permissions, requiresPermission } from '../models/permissions.js';
import classNames from 'classnames';
import { organisationStore } from '../organisation.js';
import PropTypes from "prop-types";


export var StaffExpensesPanel = requiresPermission(
  ({item}) => permissions.projectAllocations(item.getProject()),
  {
    isEditable: ({item}) => permissions.canEditProject(item.getProject())
  },
  CreateReactClass({
    propTypes: {
      item: PropTypes.object.isRequired,

      // If `true`, user can switch between grouping by phase or staff member
      // (makes sense if `item` is a `Project`). Otherwise only grouping by
      // staff member is available (makes sense if `item` is a `ProjectPhase`).
      hasGroupBy: PropTypes.bool.isRequired,
      permissionLevel: PropTypes.string.isRequired
    },

    mixins: [
      makeMultipleStoreMixin([store, organisationStore], function() {
        return {
          projectSidebar: store.projectSidebar,
          groupBy: store.projectSidebarGroupBy,
          holidaysArray: organisationStore.getHolidaysXspans().data
        };
      })
    ],

    render: function() {
      let self = this;
      const payField = 'costRate';

      const item = this.props.item;
      const staffMembers = this.props.item.getStaffMembers();
      const totalChargeOutRate = sum(staffMembers.map(s => s != null ? s.chargeOutRate : 0));
      const totalPayRate = sum(staffMembers.map(s => s != null ? s.payRate : 0));

      const numWeekdays = (item.endDate >= item.startDate) ?
        dateConverter.numWeekdaysInRangeWithoutHolidays([item.startDate, item.endDate], this.state.holidaysArray)
      : 0;

      return (
        <div className="dashboard-widget info-panel">
          <h3 className="title">
            Staff Expenses
          </h3>

          {this.props.permissionLevel === 'expenses' ?
            <LargeSidebarInput
              value={item.getStaffExpensesFromAllocations(null, payField).expenses}
              action={
                item.constructor === Project ?
                  actions.setProjectSidebarProjectStaffExpenses
                : actions.setPhaseSidebarPhaseStaffExpenses
              }
              isEditable={
                this.props.isEditable
                && totalPayRate > 0
                && item.startDate != null
                && item.endDate != null
              }
            />
          : null}

          {this.renderTotals(totalChargeOutRate)}

          {(this.props.hasGroupBy && item.allocation.hasSpecificStaffAllocation()) ?
            <table className="input-table">
              <tbody>
                <tr>
                  <td colSpan="2" style={{textAlign: 'left', fontSize: '.9em', padding: '.5em', backgroundColor: '#fcfcfc'}}>
                    Group by:
                  </td>
                </tr>
                <tr>
                  <td style={{padding: 0, borderRight: 'solid 1px #ccc'}}>
                    <ActionButton
                      className={this.state.groupBy === 'staffMember' ? "button-100 selected" : "button-100"}
                      action={actions.projectSidebarGroupByButtonClick}
                      actionArgs={['staffMember']}>
                      Staff Member
                    </ActionButton>
                  </td>
                  <td style={{padding: 0}}>
                    <ActionButton
                      className={this.state.groupBy === 'phase' ? "button-100 selected" : "button-100"}
                      action={actions.projectSidebarGroupByButtonClick}
                      actionArgs={['phase']}>
                      Phase
                    </ActionButton>
                  </td>
                </tr>
              </tbody>
            </table>
          : null}

          {(!this.props.hasGroupBy || (this.props.hasGroupBy && this.state.groupBy === 'staffMember')) ?
            <div>
              {item.allocation.hasSpecificStaffAllocation() ?
                <div>
                  {item.getStaffMemberAllocations().map(function({staffMember, hours}) {
                    return <SidebarStaffMember
                      key={staffMember.id}
                      staffMember={staffMember}
                      hours={hours}
                      payField={payField}
                      item={item}
                      numWeekdays={numWeekdays}
                      includeFinancials={self.props.permissionLevel === 'expenses'}
                      isEditable={self.props.isEditable}
                    />;
                  })}
                </div>
              :
                <div
                    style={{
                      borderBottom: '1px solid #aaa',
                      borderTop: '1px solid #aaa',
                      padding: '1em', textAlign: 'center',
                      backgroundColor: '#fcfcfc',
                      fontWeight: 600
                    }}>
                  <i>Please click the button below to allocate staff to this item.</i>
                </div>
              }

              {self.props.isEditable ?
                <div>
                  <button
                    className="button-100 allocate-staff-button"
                    onClick={this.handleAddStaffMemberButtonClick}>
                    <i className="fa fa-user" />
                    Allocate Staff Members
                  </button>
                </div>
              : null}
            </div>
          : null}

          {(this.props.hasGroupBy && this.state.groupBy === 'phase') ?
            <div style={{width: '100%'}} className="input-table">
              {item.getVisiblePhases().map(function(phase) {
                return <div key={phase.id || phase.uuid}>
                  <div className="info-panel__row">
                    <div style={{textAlign: 'left', fontWeight: 600, padding: '.75em'}}>
                      {phase.name}
                    </div>
                  </div>
                  <HoursRow
                    item={phase}
                    hours={phase.hours}
                    expenses={phase.staffExpenses}
                    chargeOut={phase.staffChargeOut}
                    actions={{
                      setHours: hours => actions.setProjectSidebarPhaseHours(phase, hours),
                      setExpenses: hours => actions.setProjectSidebarPhaseStaffExpenses(phase, hours),
                      setChargeOut: hours => actions.setProjectSidebarPhaseStaffChargeOut(phase, hours)
                    }}
                    payField={payField}
                    includeFinancials={self.props.permissionLevel === 'expenses'}
                    expensesDisabled={totalPayRate === 0}
                    chargeOutDisabled={totalChargeOutRate === 0}
                    isEditable={self.props.isEditable}
                  />
                </div>;
              })}
            </div>
          : null}
        </div>
      );
    },

    renderTotals: function(totalChargeOutRate) {
      const item = this.props.item;
      const isHoursEditable = this.props.isEditable && item.startDate != null && item.endDate != null;

      return (
        <table className="input-table scheduler__sidebar__staff-expenses" style={{width: '100%'}}>
          <tbody>
            {this.props.permissionLevel === 'expenses' ?
              <tr>
                <td style={{borderRight: 'solid 1px #ccc'}}>
                  Charge-Out
                </td>
                <td
                    className={classNames(
                      "dollar",
                      {"cell-input-field": this.props.isEditable && item.allocation.hasSpecificStaffAllocation()}
                    )}>
                  <CurrencyIcon />
                  <NumberInputContainer
                    className="staff-expenses-panel__total-charge-out"
                    value={Math.round(item.staffChargeOut)}
                    action={
                      item.constructor === Project ?
                        actions.setProjectSidebarProjectChargeOut
                      : actions.setPhaseSidebarPhaseChargeOut
                    }
                    disabled={!this.props.isEditable || totalChargeOutRate === 0}
                  />
                </td>
              </tr>
            : null}
            <tr>
              <td style={{borderRight: 'solid 1px #ccc'}}>
                Hours
              </td>
              <td className={classNames("dollar", {'cell-input-field': isHoursEditable})}>
                <i className="fa fa-clock-o" />
                {item.constructor === Project ?
                  <NumberInputContainer
                    className="total-staff-hours"
                    value={Math.round(item.hours)}
                    action={actions.setProjectSidebarProjectHours}
                    disabled={!isHoursEditable}
                  />
                :
                  <NumberInputContainer
                    className="total-staff-hours"
                    value={Math.round(item.hours)}
                    action={actions.setPhaseSidebarPhaseHours}
                    disabled={!isHoursEditable}
                  />
                }
              </td>
            </tr>
          </tbody>
        </table>
      );
    },

    handleAddStaffMemberButtonClick: function() {
      actions.openAddStaffMemberToItemPopup(this.props.item);
    }
  })
);
