import { observable, computed, action, makeObservable } from "mobx";
import apiRequest from "../apiRequest";

class tokenFetcher {
	@observable token = null;
	@observable fetchState = "idle";
	@observable emailState = "idle";
	@observable staffId = null;
	constructor() {
		makeObservable(this);
	}
	@action.bound
	fetchToken(staffId) {
		this.staffId = staffId;
		this.fetchState = "pending";
		apiRequest({
			url: `/user/${this.staffId}/get-token`,
			method: "get",
			success: (data) => {
				this.token = data.token;
				this.fetchState = "success";
			},
			error: (data) => {
				this.fetchState = "error";
			},
		});
	}
	@action.bound
	emailToken() {
		this.emailState = "pending";
		apiRequest({
			url: `/user/${this.staffId}/email-token/${this.token}`,
			method: "get",
			success: (data) => {
				this.emailState = "success";
			},
			error: (data) => {
				this.emailState = "error";
			},
		});
	}
	@computed
	get activationUrl() {
		return `${process.env.REACT_APP_SERVER_URL}/user/activate-account/${this.token}`;
	}
}

export default new tokenFetcher();
