import React from 'react';
import CreateReactClass from 'create-react-class';
import { formatNumber1 } from '../utils.js';
import { Slider } from "../slider.js";
import { Project } from '../models.js';
import { actions, store } from './flux.js';
import { HoursRow } from './HoursRow.js';
import PropTypes from "prop-types";


// TODO-project_architect now works if we can only see hours but it looks
// really silly.


export var SidebarStaffMember = CreateReactClass({
  propTypes: {
    staffMember: PropTypes.object,
    item: PropTypes.object,

    // Ideally the caller shouldn't be responsible for these properties.
    hours: PropTypes.number,
    numWeekdays: PropTypes.number,

    payField: PropTypes.string.isRequired,
    includeFinancials: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
  },

  render: function() {
    const staffMember = this.props.staffMember;
    const hours = this.props.hours;
    const numWeekdays = this.props.numWeekdays;
    const item = this.props.item;

    let expenses, chargeOut, staffTotalAllocation;
    if (numWeekdays > 0) {
      const data = item.getStaffExpensesFromAllocations(staffMember, this.props.payField);
      expenses = data.expenses;
      chargeOut = data.chargeOut;

      staffTotalAllocation = store.getStaffAllocation(staffMember, item.startDate, item.endDate);
    }
    else {
      expenses = 0;
      chargeOut = 0;
      staffTotalAllocation = 0;
    }

    const isUnallocatedHours = (staffMember.id === -1);

    return <div className="sidebar-staff-member">
      <div className="input-table">
        <div style={{textAlign: 'center'}}>
          <div
              className="sidebar-staff-member__name"
              style={{fontWeight: 600, fontSize: '1.2em', padding: '.5em'}}>
            {!isUnallocatedHours ? staffMember.name : "Unallocated hours" }
          </div>
          {!isUnallocatedHours
              && staffMember.weeklyHoursAvailable != null
              && staffMember.weeklyHoursAvailable !== 0
              && numWeekdays > 0 ?
            <div>
              <div>
                Usage:
                {' '}
                {Math.round(((hours / numWeekdays * 5) / staffMember.weeklyHoursAvailable) * 100)}%
                {` this ${item.constructor === Project ? 'project' : 'phase'}, `}
                {Math.round(((staffTotalAllocation / numWeekdays * 5) / staffMember.weeklyHoursAvailable) * 100)}%
                {' overall'}
              </div>
              <div>
                {formatNumber1(hours / numWeekdays * 5)} / {formatNumber1(staffMember.weeklyHoursAvailable)} hours per week
              </div>
              <div style={{textAlign: 'center'}}>
                <Slider
                  width="100%"
                  height={15}
                  min={0}
                  max={100}
                  values={[hours / numWeekdays * 5 / staffMember.weeklyHoursAvailable * 100]}
                  connectingLineType='fromLeft'
                  getConnectingLineProps={function(slider) {
                    return {
                      y: (slider.props.height - 2) / 2,
                      height: 2,
                      fill: '#ffca01',
                      className: "phase-slider__connecting-line"
                    };
                  }}
                  drawItem={function(itemValue, itemIndex, x, slider) {
                    return <circle
                      key={itemIndex}
                      cx={x}
                      cy={slider.props.height / 2}
                      r={6}
                      stroke="#aaa"
                      strokeWidth={2}
                      fill="white"
                      className="phase-slider__circle phase-slider__circle--small"
                      {...slider.dragController.getMouseEventProperties({itemValue: itemValue, itemIndex: itemIndex})}
                    />;
                  }}
                  onMove={function(currentValues, movedItemValue, movedItemIndex, diff) {
                    let val = currentValues[0] + diff; // There's only one value.
                    let hours = val * numWeekdays / 5 * staffMember.weeklyHoursAvailable / 100;
                    if (item.constructor === Project) {
                      actions.setProjectStaffMemberAllocationSliderValue(item, staffMember, hours);
                    }
                    else {
                      actions.setPhaseStaffMemberAllocationSliderValue(item, staffMember, hours);
                    }
                  }}
                  isEditable={this.props.isEditable}
                />
              </div>
            </div>
          : !isUnallocatedHours ?
            <div>
              <em>{staffMember.name} does not have weekly availability set.</em>
            </div>
          : null}
        </div>
        <div>
          <HoursRow
            item={item}
            hours={hours}
            expenses={expenses}
            chargeOut={chargeOut}
            payField={this.props.payField}
            includeFinancials={this.props.includeFinancials}
            actions={
              item.constructor === Project ?
                {
                  setHours: (hours) => actions.setProjectSidebarStaffMemberHours(staffMember, hours),
                  setExpenses: (expenses) => actions.setProjectSidebarStaffMemberExpenses(staffMember, expenses),
                  setChargeOut: (chargeOut) => actions.setProjectSidebarStaffMemberChargeOut(staffMember, chargeOut)
                }
              :
                {
                  setHours: (hours) => actions.setPhaseSidebarStaffMemberHours(staffMember, hours),
                  setExpenses: (expenses) => actions.setPhaseSidebarStaffMemberExpenses(staffMember, expenses),
                  setChargeOut: (chargeOut) => actions.setPhaseSidebarStaffMemberChargeOut(staffMember, chargeOut)
                }
            }
            isEditable={this.props.isEditable}
          />
        </div>
      </div>
    </div>;
  }
});



