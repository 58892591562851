import { observer } from "mobx-react";
import React from "react";
import { CoincraftPage } from "../CoincraftPage";
import { Calendar } from "./calendarComponent";
import { CalendarSidebar } from "./calendarSidebarComponent";
import calendarState from "./calendarState";

export default observer(() => (
	<CoincraftPage
		renderHeader={false}
		bodyStyle={{ backgroundColor: "#eeeeee" }}
		saveBar={
			calendarState.saving &&
			(calendarState.saving === "saving" ? (
				<div className="save-bar">
					<strong>
						<i className="fa fa-spinner fa-pulse fa-fw" /> Saving
						your timesheets...
					</strong>
				</div>
			) : (
				<div className="save-bar">
					<strong>Saved!</strong>
				</div>
			))
		}
		body={
			<div style={{ maxWidth: "75em", margin: "0 auto" }}>
				<Calendar mode="time" />
			</div>
		}
		sidebar={<CalendarSidebar />}
	/>
));
