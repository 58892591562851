import React from "react";
import CreateReactClass from "create-react-class";
import { MyTable } from "./myTable.js";
import { organisationStore } from "./organisation.js";
import { makeMultipleStoreMixin } from "./coincraftFlux.js";
import { CoincraftPage } from "./CoincraftPage.js";
import classNames from "classnames";
import { Expense } from "./models.js";
import { Checkbox } from "./widgets/Checkbox.js";
import {
	MySelect2,
	SaveButton,
	DateValue,
	SmallDeleteButton,
} from "./widgets/generic.js";
import { CostCentreSelector } from "./widgets/coincraft.js";
import PropTypes from "prop-types";

export var ExpensesPage = CreateReactClass({
	propTypes: {
		// `organisation` is "required" but we get false-positive warnings about it
		// not being specified because of how react-router works.
		organisation: PropTypes.object,
	},

	render: function () {
		return (
			<CoincraftPage
				heading="Overhead Expenses"
				body={<Expenses organisation={this.props.organisation} />}
			/>
		);
	},
});

export var Expenses = CreateReactClass({
	propTypes: {
		organisation: PropTypes.object.isRequired,
	},

	mixins: [
		makeMultipleStoreMixin([organisationStore], function () {
			return {
				currencyFormatter:
					organisationStore.organisation.currencyFormatter,
			};
		}),
	],

	getInitialState: function () {
		var expenses = this.props.organisation.expenses;
		if (expenses.length === 0) {
			expenses = [new Expense()];
		}
		return {
			expenses: expenses,
			saveState: null, // null, 'saving', 'saved'
		};
	},

	render: function () {
		let self = this;
		return (
			<div className="expenses-panel" style={{ maxWidth: "80em" }}>
				<MyTable
					items={this.state.expenses}
					shouldItemBeDisplayed={(e) => !e.isDeleted}
					ref="myTable"
					columns={[
						{
							prop: "name",
							heading: "Expense name",
							tdProps: {
								style: { width: "16%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return myTable.makeTextInput(
									column,
									expense,
									isLastFirst,
									{
										className:
											myTable.state.submitAttempted &&
											expense.errors != null &&
											expense.errors.name != null
												? "field-with-error"
												: null,
										afterChange: function (expense) {
											myTable.validate();
										},
									}
								);
							},
						},
						{
							prop: "costCentre",
							heading: "Cost Centre",
							tdProps: {
								style: { width: "10%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return (
									<CostCentreSelector
										value={expense.costCentre}
										style={{ maxWidth: "100%" }}
										onChange={(costCentre) => {
											myTable.handleChange(
												expense,
												"costCentre",
												costCentre
											);
											myTable.validate();
										}}
									/>
								);
							},
						},
						{
							prop: "budget",
							heading: `Cost (${this.state.currencyFormatter.symbol})`,
							tdProps: {
								style: { width: "14%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return myTable.makeTextInput(
									column,
									expense,
									isLastFirst,
									{
										maxLength: 7,
										className:
											myTable.state.submitAttempted &&
											expense.errors != null &&
											expense.errors.budget != null
												? "field-with-error"
												: null,
										afterChange: function (expense) {
											myTable.validate();
										},
									}
								);
							},
						},
						{
							prop: "date",
							heading: "Start date",
							tdProps: {
								style: { width: "14%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return (
									<div>
										<DateValue
											value={expense.startDate}
											onChange={function (newDate) {
												myTable.handleChange(
													expense,
													"startDate",
													newDate
												);
												myTable.validate();
											}}
											className={
												myTable.state.submitAttempted &&
												expense.errors != null &&
												expense.errors.startDate != null
													? "field-with-error"
													: null
											}
										/>
									</div>
								);
							},
						},
						{
							prop: "date",
							heading: "End date",
							tdProps: {
								style: { width: "14%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return (
									<div>
										<DateValue
											value={expense.endDate}
											onChange={function (newDate) {
												myTable.handleChange(
													expense,
													"endDate",
													newDate
												);
												myTable.validate();
											}}
										/>
									</div>
								);
							},
						},
						{
							prop: "repeat",
							heading: "Once off / regular",
							tdProps: {
								style: {
									width: "28%",
									verticalAlign: "middle",
								},
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return (
									<div
										className="flexbox-container flex-align-items-center"
										style={{ maxWidth: "100%" }}
									>
										<Checkbox
											className="flex-1-1-auto"
											value={expense.hasRepeat}
											onChange={function (value) {
												expense.hasRepeat = value;
												myTable.updateState();
											}}
											label="Repeat"
										/>
										{expense.hasRepeat ? (
											<span
												className="flex-1-1-auto flexbox-container flex-align-items-center"
												style={{ lineHeight: "25px" }}
											>
												<span className="flex-1-1-auto">
													{" every "}
												</span>
												<input
													type="number"
													style={{
														maxWidth: "6em",
														marginLeft: "0.5em",
													}}
													value={
														expense.repeatQuantity
													}
													min="1"
													placeholder="number"
													className={classNames(
														"flex-1-1-auto",
														{
															"field-with-error":
																myTable.state
																	.submitAttempted &&
																expense.errors !=
																	null &&
																expense.errors
																	.repeatQuantity !=
																	null,
														}
													)}
													onChange={function (event) {
														var val =
															event.target.value;
														expense.repeatQuantity =
															parseInt(val);
														myTable.updateState();
														myTable.validate();
													}}
												/>
												<MySelect2
													value={expense.repeatUnit}
													outerClassName="flex-0-0-auto"
													style={{
														marginLeft: "0.5em",
														lineHeight: "20px",
													}}
													options={[
														"days",
														"weeks",
														"months",
														"years",
													]}
													getObjectLabel={function (
														o
													) {
														if (o != null) {
															return (
																<span>{o}</span>
															);
														} else {
															return (
																<span>
																	Select...
																</span>
															);
														}
													}}
													className={
														myTable.state
															.submitAttempted &&
														expense.errors !=
															null &&
														expense.errors
															.repeatUnit != null
															? "field-with-error"
															: null
													}
													onChange={function (value) {
														expense.repeatUnit =
															value;
														myTable.updateState();
														myTable.validate();
													}}
												/>
											</span>
										) : null}
									</div>
								);
							},
						},
						{
							tdProps: {
								style: { width: "4%" },
							},
							makeCustomComponent: function (
								expense,
								column,
								myTable,
								isLastFirst
							) {
								return (
									<SmallDeleteButton
										onClick={function () {
											self.deleteExpense(
												expense,
												myTable
											);
										}}
									/>
								);
							},
						},
					]}
					addButtonText="Add expense"
					hasSaveButton={false}
					createNewItem={function () {
						return new Expense();
					}}
					validate={function (expenses) {
						var isValid = true;
						expenses.forEach(function (e) {
							isValid = e.validate() && isValid;
						});
						return isValid;
					}}
					onChange={function (expenses) {
						self.setState({ expenses: expenses });
					}}
				/>
				<div style={{ textAlign: "right" }}>
					<SaveButton
						text={
							this.state.saveState === "saving"
								? "Saving expenses..."
								: "Save expenses"
						}
						disabled={this.state.saveState === "saving"}
						style={{ marginLeft: 20 }}
						onClick={this.saveAndClose}
					/>
				</div>
				<div style={{ textAlign: "right", paddingRight: 10 }}>
					{/* Make the container always there and taking up height so the
            content doesn't move up and down */}
					{self.state.saveState === "saved" ? (
						<strong>Expenses saved</strong>
					) : (
						<span>&nbsp;</span>
					)}
				</div>
			</div>
		);
	},

	deleteExpense: function (expense, myTable) {
		expense.isDeleted = true;
		this.setState({ expenses: this.state.expenses });
		myTable.updateState();
	},

	saveAndClose: function () {
		// Use the MyTable's click validator because it hooks into its own validation
		// mechanics. The closing is then handled by the `onSave` prop of the `MyTable`.
		var self = this;
		this.refs.myTable.handleSaveButtonClick().then(
			function () {
				self.setState({ saveState: "saving" });
				self.props.organisation.saveExpenses(self.state.expenses).then(
					function (data) {
						self.state.expenses.forEach(function (expense) {
							if (
								expense.uuid != null &&
								expense.id == null &&
								data.uuidToIdLookup[expense.uuid] != null
							) {
								expense.id = data.uuidToIdLookup[expense.uuid];
							}
						});

						self.setState({ saveState: "saved" });
						setTimeout(function () {
							self.setState({ saveState: null });
						}, 2500);
					},
					function (err) {
						throw new Error(err);
					}
				);
			},
			function () {
				// Validation failed.
			}
		);
	},
});
