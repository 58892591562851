import React from 'react';
import CreateReactClass from 'create-react-class';
import ReactDataGrid from 'react-data-grid';
import { makeMultipleStoreMixin } from '../coincraftFlux.js';
import { store, actions, TYPE } from './flux.js';
import { chain, imap, range, groupBy, mergeMaps, isNumber, formatNumber2} from '../utils.js';
import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { ProjectReportSelector, StaffMemberReportSelector } from '../widgets.js';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { organisationStore } from '../organisation.js';
import { userStore } from '../user.js';
import { ContactSelectorStore } from '../widgets/ContactSelectorStore.js';
import PropTypes from "prop-types";

const Row = ReactDataGrid.Row;

const RowHeight = 25;


export var Spreadsheet = CreateReactClass({
  propTypes: {
    rows: PropTypes.object.isRequired,
    navigatorColumns: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    startMonth: PropTypes.number.isRequired,
    endMonth: PropTypes.number.isRequired,
    groupedColumns: PropTypes.array.isRequired,
    leafColumn: PropTypes.string.isRequired,
    cellType: PropTypes.string.isRequired,
    getCellContainerProps: PropTypes.func.isRequired,
    rowClass: PropTypes.func.isRequired,
    showStaffSelector: PropTypes.bool,

    // function(rowIndex, cellKey, newValue)
    onCellUpdated: PropTypes.func.isRequired
  },

  getDefaultProps: function() {
    return {
      showStaffSelector: false
    };
  },

  mixins: [
    PureRenderMixin,
    makeMultipleStoreMixin([store, organisationStore, userStore], function() {
      return {
        reports: organisationStore.reports.filter(r => r.reportType === 'project'),
        projectReportUuid: store.projectReportUuid,
        hasAllProjectsReport: store.hasAllProjectsReport,
        staffReportUuid: store.staffReportUuid,

        /**
         * Rows are raw ungrouped rows as generated by `getAllocationSpreadsheet` and `getRevenueSpreadsheet`.
         * Processed rows are rows possibly augmented with grouping data.
         */
        selectedRow: store.selectedRow,
        selectedProcessedRow: store.selectedProcessedRow,
        selectedRowIndex: store.selectedRowIndex,
        selectedCellIndex: store.selectedCellIndex,

        user: userStore.user
      };
    })
  ],

  getInitialState: function() {
    return {
      isContextExpanded: true,
    };
  },

  render: function() {
    return (
      <div>
        <div
            className="flexbox-container flex-align-items-center"
            style={{backgroundColor: 'white', padding: '0.5em 1em', borderBottom: '1px solid #ccc'}}>
          <strong className="flex-0-0-auto">
            Show:
          </strong>
          <ProjectReportSelector
            reportUuid={this.state.projectReportUuid}
            onChange={(reportUuid) => actions.setProjectReport(reportUuid)}
            allowNull={!this.state.hasAllProjectsReport}
            nullOptionText="(All projects)"
          />
          {this.props.showStaffSelector ?
            <StaffMemberReportSelector
              reportUuid={this.state.staffReportUuid}
              onChange={(reportUuid) => actions.setStaffReport(reportUuid)}
              allowNull={true}
              nullOptionText="(All staff)"
            />
          : null}
          {this.state.user.permissions.isAtLeastProjectManager ?
            <button
                onClick={this.handleAddRowButtonClick}
                className="btn btn-default btn-sm"
                style={{marginLeft: '3.5em'}}>
              + Add Project / Phase
            </button>
          : null}
        </div>
        <div style={{backgroundColor: 'white', overflowX: 'hidden'}}>
          <SpreadsheetBody
            offsetWidth={this.props.offsetWidth}
            style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', overflowY: 'scroll'}}
            rows={this.props.rows}
            navigatorColumns={this.props.navigatorColumns}
            columns={this.props.columns}
            groupedColumns={this.props.groupedColumns}
            leafColumn={this.props.leafColumn}
            startMonth={this.props.startMonth}
            endMonth={this.props.endMonth}
            onRowClick={this.handleRowClick}
            onCellSelected={this.handleCellSelected}
            onCellUpdated={this.handleCellUpdated}
            rowFactory={new RowFactory(this.props.rowClass, this.props.startMonth, this.props.endMonth)}
            isContextOpen={this.state.selectedRowIndex != null && this.state.isContextExpanded}
            getCellContainerProps={this.props.getCellContainerProps}
          />
        </div>
      </div>
    );
  },

  handleRowClick: function(rowIndex, row) {
    actions.selectRow(rowIndex, row);
  },

  handleCellSelected: function(rowIndex, cellIndex) {
    actions.selectCell(rowIndex, cellIndex);
  },

  handleCellUpdated: function(rows, rowIndex, cellKey, newValue) {
    this.props.onCellUpdated(rowIndex, cellKey, newValue);
  },

  handleAddRowButtonClick: function() {
    const row = (this.state.selectedRowIndex != null) ? this.props.rows.get(this.state.selectedRowIndex) : null;

    if (this.props.cellType === TYPE.REVENUE) {
      actions.addRevenueRow(row != null ? row.get('project') : null);
    }
    else {
      actions.addAllocationRow(
        row != null ? row.get('staffMember') : null,
        row != null ? row.get('project') : null
      );
    }
  }
});



export var SpreadsheetBody = CreateReactClass({
  propTypes: {
    // One of these.
    initialRows: PropTypes.object,
    rows: PropTypes.object,

    isContextExpanded: PropTypes.bool,

    navigatorColumns: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,

    groupedColumns: PropTypes.array.isRequired,
    leafColumn: PropTypes.string.isRequired,
    startMonth: PropTypes.number.isRequired,
    endMonth: PropTypes.number.isRequired,

    rowFactory: PropTypes.object.isRequired,

    // It's better for callers to make use of the `rowIndex` argument rather
    // than `row` because the `row` will become out of sync if the data
    // subsequently changes. With `rowIndex` you can always look up a fresh
    // copy.
    onRowClick: PropTypes.func, // function(rowIndex, row) { }

    onCellSelected: PropTypes.func, // function(rowIndex, cellIndex)
    onCellUpdated: PropTypes.func,  // function(rows, rowIndex, cellKey, newValue) { }
    getCellContainerProps: PropTypes.func  // function(rows, rowIndex, cellKey, newValue) { }
  },

  getDefaultProps: function() {
    return {
      isContextExpanded: false,
      onRowClick: function(rowIndex, row) { },
      onCellSelected: function(rowIndex, cellIndex) { },
      getCellContainerProps: function(props) {
        const border = 'solid 1px #ddd';
        return {
          style: {
            borderLeft: border,
            borderBottom: border,
            borderTop: props.rowIdx === 0 ? border : null,
            padding: 3,
            lineHeight: '17px'
          }
        };
      }
    };
  },

  mixins: [
    PureRenderMixin
  ],

  getInitialState: function() {
    return {
      rows: null,

      /**
       Immutable.Set(
         Immutable.List( // For each row
           // For each `columnName` in `this.props.groupedColumns` for which the row has a value
           Immutable.List([columnName, columnValue])
         )
       )
      */
      expandedRows: Immutable.Set(),

      selectedRow: null,
    };
  },

  componentWillMount: function() {
    this._setState({
      rows: this.props.rows || Immutable.fromJS(this.props.initialRows)
    });
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.rows !== this.props.rows
        || nextProps.startMonth !== this.props.startMonth
        || nextProps.endMonth !== this.props.endMonth) {
      this._setState(
        {
          rows: nextProps.rows,
        },
        {
          startMonth: nextProps.startMonth,
          endMonth: nextProps.endMonth,
          rowFactory: nextProps.rowFactory,
          groupedColumns: nextProps.groupedColumns,
          leafColumn: nextProps.leafColumn
        }
      );
    }
  },

  render: function() {
    const numRows = this.state.processedRows.count();

    return <ReactDataGrid
      width={this.props.offsetWidth}
      enableCellSelect={true}
      columns={this.props.columns}
      headerRows={[]}
      includesHeader={false}
      rowHeight={RowHeight}
      rowGetter={ix => this.state.processedRows.get(ix)}
      rowsCount={numRows}
      onRowExpandToggle={this.handleRowExpandToggle}
      onRowUpdated={this.handleRowUpdated}
      onRowClick={this.handleRowClick}

      // + 1 for the extra dummy row thingy that react-data-grid appends.
      // + 8 to rowHeight for margin
      minHeight={(numRows + 1) * (RowHeight + 8)}

      getCellContainerProps={this.props.getCellContainerProps}
      onCellSelected={this.handleCellSelected}
      rowGroupRenderer={RowGroup}
    />;
  },

  handleRowClick: function(rowIdx, row) {
    this.setState({selectedRow: row});

    // Convert from processed row index to row index.
    let rowIndex = this.state.processedRows.get(rowIdx).get('rowIndex');
    this.props.onRowClick(rowIndex, row);
  },

  handleCellSelected: function({idx: columnIndex, rowIdx: processedRowIndex}) {
    const rowIndex = this.state.processedRows.get(processedRowIndex).get('rowIndex');
    this.props.onCellSelected(rowIndex, columnIndex);
  },

  handleRowUpdated : function({rowIdx, cellKey, updated}) {
    // `rowIdx` given by react-data-grid is the index in the list of processed rows,
    // `rowIndex` is ours and is the index into `this.state.rows`.
    const rowIndex = this.state.processedRows.get(rowIdx).get('rowIndex');
    const newValue = Object.values(updated)[0];
    let newValueFloat = parseFloat(newValue);
    if (!isNumber(newValueFloat)) {
      newValueFloat = 0;
    }

    if (this.props.onCellUpdated != null) {
      this.props.onCellUpdated(
        this.state.rows,
        rowIndex,
        cellKey,
        newValueFloat
      );
    }
    else {
      this._setState({
        rows: this.state.rows.setIn([rowIndex, cellKey], newValueFloat)
      });
    }
  },

  handleRowExpandToggle: function({rowIdx, columnGroupName, name: value, shouldExpand}) {
    const row = this.state.processedRows.get(rowIdx);

    let lst = [];
    for (let c of this.props.groupedColumns) {
      const val = row.get(c);
      if (val != null) {
        lst.push(Immutable.List([c, val]));
      }
    }
    const key = Immutable.List(lst);

    const expandedRows = this.state.expandedRows;
    this._setState({
      expandedRows: expandedRows.includes(key) ? expandedRows.remove(key) : expandedRows.add(key)
    });
  },

  _setState: function(state, props) {
    let newState = {...this.state, ...state};
    let newProps = {...this.props, ...props};

    newState.processedRows = getRows({
      rows: newState.rows,
      expandedRows: newState.expandedRows,
      groupedColumns: newProps.groupedColumns,
      leafColumn: newProps.leafColumn,
      startMonth: newProps.startMonth,
      endMonth: newProps.endMonth,
      rowFactory: newProps.rowFactory
    });
    this.setState(newState);
  }
});


var RowGroup = CreateReactClass({
  propTypes: {
    idx: PropTypes.any,
    row: PropTypes.object,
    columns: PropTypes.array,
    expandedRows: PropTypes.array,
    onRowExpandClick: PropTypes.func,
    getCellContainerProps: PropTypes.func
  },

  mixins: [
    PureRenderMixin
  ],

  render: function() {
    let props = this.props;

    return <div className="disable-focus-outline" style={{cursor: 'pointer'}}>
      <Row
        key={props.idx}
        idx={props.idx}
        row={props.row}

        height={RowHeight}

        // Note that we only have `props.columns` and `props.row` because
        // we are using a patched version of `react-data-grid`.
        // Note also that `props.columns` is different from the `columns` prop
        // passed to the `ReactDataGrid` because by the time it gets here
        // the `ReactDataGrid` has calculated absolute widths of the columns.
        columns={props.columns}

        isSelected={false}
        expandedRows={props.expandedRows}
        cellMetaData={props.cellMetaData}
        onClick={props.onRowExpandClick}
        getCellContainerProps={props.getCellContainerProps}
      />
    </div>;
  }
});

function makeEditorClass(getValue) {
  return CreateReactClass({
    /**
     * These four functions are used by the `ReactDataGrid`. This is why we're
     * making two different components from scratch (the two calls to `makeEditorClass
     * below) rather than using composition.
     */
    getStyle() {
      return {
        display: 'inline-block',
        width: '100%',
        height: 25,
        minHeight: 25,
        textAlign: 'right',
        padding: '3 0'
      };
    },

    getValue() {
      return {
        [this.props.column.key]: this.refs.input.value
      };
    },

    getInputNode() {
      return this.refs.input;
    },

    inheritContainerStyles() {
      return false;
    },

    render: function() {
      return <input
        style={this.getStyle()}
        ref="input"
        type="text"

        // react-data-grid's `EditorContainer` component hooks `onBlur` up to
        // the apparatus that commits the new cell value.
        onBlur={this.props.onBlur}

        // Not sure how the null value gets here or why turning it into `NaN`
        // (as `Math.round` does) never sees a `NaN` value displayed to the user,
        // but that makes things ok somehow.
        defaultValue={formatNumber2(getValue(this.props.value), {ifNull: NaN})}
      />;
    }
  });
}


export const MyEditor = makeEditorClass(v => v);
export const RevenueCellEditor = makeEditorClass(v => v.total);


function _groupRows(
    rows,
    groupedColumns,
    expandedRows,
    makeHeaderRow,
    makeLeafRow,
    origRows,
    columnIndex = 0,
    isVisible = true,
    groupStack = Immutable.List()) {

  if (columnIndex >= groupedColumns.length) {
    let rs = rows.map(r => makeLeafRow(r, groupStack, origRows.findIndex(r1 => r1 === r)));
    return [rs, isVisible ? rs : []];
  }
  else {
    let rs = Immutable.List(), visibleRows = Immutable.List();
    let columnName = groupedColumns[columnIndex];
    let groups = groupBy(rows, r => r.get(columnName), o => o.id);
    for (let {grouper, items} of groups) {
      const newGroupStack = groupStack.push(Immutable.List([columnName, grouper]));
      const isExpanded = (expandedRows != null && expandedRows.includes(newGroupStack));

      let [childRows, childVisibleRows] = _groupRows(
        items,
        groupedColumns,
        expandedRows,
        makeHeaderRow,
        makeLeafRow,
        origRows,
        columnIndex + 1,
        isExpanded,
        newGroupStack
      );

      let headerRow = makeHeaderRow(columnName, grouper, childRows, groupStack);
      headerRow.name = grouper;
      headerRow.__metaData = {
        isGroup: true,
        childRows: childRows,
        treeDepth: columnIndex,
        isExpanded: isExpanded,
        columnGroupName: columnName
      };
      rs = rs.push(headerRow);
      rs = rs.concat(childRows);
      if (isVisible) {
        visibleRows = visibleRows.push(headerRow);
        visibleRows = visibleRows.concat(childVisibleRows);
      }
    }
    return [rs, visibleRows];
  }
};


export const RowFactory = class {
  constructor(RowClass, startMonth, endMonth) {
    if (!isNumber(startMonth) || !isNumber(endMonth)) {
      throw new Error("`startMonth` and `endMonth` should be month indexes");
    }

    this.RowClass = RowClass;
    this.startMonth = startMonth;
    this.endMonth = endMonth;
  }

  makeRow(headers) {
    return new (this.RowClass)(
      null,
      this.startMonth,
      this.endMonth,
      headers
    );
  }

  makeLeafRow(row, groupStack, leafColumn, rowIndex) {
    let d = {};
    for (let [columnName, columnValue] of groupStack) {
      d[columnName] = columnValue;
    }
    return row.merge({
      rowIndex: rowIndex,
      ...d,
      item: row.get(leafColumn),
      itemType: leafColumn
    });
  }
}


class SpreadsheetRow {
  constructor(map, startMonth, endMonth, headers) {
    if (map != null) {
      this.map = map;
    }
    else {
      this.map = Immutable.Map(chain(
        headers,
        imap(range(startMonth, endMonth + 1), i => [i, this.makeZeroCell()])
      ));
    }

    this.startMonth = startMonth;
    this.endMonth = endMonth;
  }

  mergeChildRows(childRows) {
    let self = this;
    let row = this.map;
    for (let childRow of childRows) {
      if (childRow.__metaData == null) {
        row = mergeMaps(
          row,
          childRow,
          function(k, v1, v2) {
            if (isNumber(k)) {
              return self.mergeCellData(v1, v2);
            }
            else {
              return v1;
            }
          }
        );
      }
    }
    return new (this.constructor)(row);
  }

  makeZeroCell() {
    throw new Error("Not implemented");
  }

  mergeCellData(v1, v2) {
    throw new Error("Not implemented");
  }

  getMonthIndexFromCellIndex(cellIndex) {
    // Minus one for the header cell and another one for the 'move left' cell.
    return this.startMonth + cellIndex - 1;
  }

  getCell(cellIndex) {
    return this.map.get(this.getMonthIndexFromCellIndex(cellIndex));
  }

  get isGroup() {
    return this.__metaData != null && this.__metaData.isGroup;
  }

  get(k) {
    return this.map.get(k);
  }

  set(k, v) {
    return new (this.constructor)(this.map.set(k, v), this.startMonth, this.endMonth);
  }

  update(k, f) {
    return new (this.constructor)(this.map.update(k, f), this.startMonth, this.endMonth);
  }

  merge(ob) {
    return new (this.constructor)(this.map.merge(ob), this.startMonth, this.endMonth);
  }

  forEach(f) {
    this.map.forEach(f);
  }

  toJS() {
    return this.map.toJS();
  }

  * iterMonths() {
    for (let m = this.startMonth; m <= this.endMonth; m++) {
      yield m;
    }
  }
}


export const RevenueRow = class extends SpreadsheetRow {
			total() {
				let totalRevenue = 0;
				this.map.forEach(function(v, k) {
					if (isNumber(k)) {
						totalRevenue += v.total;
					}
				});
				return totalRevenue;
			}

			makeZeroCell() {
				return {
					total: 0,
					cumSum: 0,
					cashFlowItems: Immutable.List([])
				};
			}

			mergeCellData(v1, v2) {
				return {
					total: v1.total + v2.total,
					cumSum: v1.cumSum + v2.cumSum,
					cashFlowItems: v1.cashFlowItems.concat(v2.cashFlowItems)
				};
			}

			getTotalForMonth(monthIndex) {
				return this.map.get(monthIndex).total;
			}
		};


export const AllocationRow = class extends SpreadsheetRow {
			makeZeroCell() {
				return 0;
			}

			mergeCellData(v1, v2) {
				return v1 + v2;
			}

			getTotalForMonth(monthIndex) {
				return this.map.get(monthIndex);
			}
		};


const getRows = createSelector(
  /**
   * Takes a flat Immutable.List of ungrouped rows, and returns a grouped list
   * of visible rows as determined by `groupedColumns` and `expandedRows`.
   */
  [
    state => state.rows,
    state => state.groupedColumns,
    state => state.expandedRows,
    state => state.leafColumn,
    state => state.startMonth,
    state => state.endMonth,
    state => state.rowFactory,
  ],
  function(rows, groupedColumns, expandedRows, leafColumn, startMonth, endMonth, rowFactory) {
    function makeHeaderRow(columnName, columnValue, childRows, groupStack) {
      let row = rowFactory.makeRow([...groupStack, [columnName, columnValue]], startMonth, endMonth).merge({
        item: columnValue,
        itemType: columnName
      });
      return row.mergeChildRows(childRows);
    }

    function makeLeafRow(row, groupStack, rowIndex) {
      return rowFactory.makeLeafRow(row, groupStack, leafColumn, rowIndex);
    }

    return _groupRows(
      rows,
      groupedColumns,
      expandedRows,
      makeHeaderRow,
      makeLeafRow,
      rows
    )[1];
  }
);


export const ITEM_COLUMN_WIDTH = "18%";
export const NAVIGATION_COLUMN_WIDTH = "3%";
export const CONTENT_WIDTH = '76%';
export const PADDING_RIGHT = '6%';


export function itemColumn(groupedColumns) {
  return {
    key: 'item',
    name: 'Item',
    editable: false,

    /**
     * If two columns differ only by a property whose value is a function,
     * react-data-grid's comparison function will think they are the same column.
     * So although we don't actually use the `groupedColumns` property of the column,
     * it's there to get around that.
     */
    groupedColumns: groupedColumns,

    formatter: function(props) {
      // The `column` value here is accessible because of patched react-data-grid.

      let value = props.value;
      if (value == null) {
        return null;
      }
      let key = props.row.get('itemType');

      let isGroupRow = (props.row.__metaData != null);
      let depth = isGroupRow ? props.row.__metaData.treeDepth : groupedColumns.length;
      let arrow = <i
        className="fa fa-angle-right"
        style={{
          marginLeft: depth + 'em',
          visibility: !isGroupRow ? 'hidden': null
        }}
      />;
      if (key === 'project' || key === 'phase') {
        return <div className="item-cell-text" style={{overflow:'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{arrow} {value.getTitle()}</div>;
      }
      else if (key === 'staffMember') {
        return <div className="item-cell-text" style={{overflow:'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{arrow} {value.getFullName()}</div>;
      }
      else {
        throw new Error("What is this");
      }
    },
    // Note that these percentage widths also require a patch to react-data-grid
    width: ITEM_COLUMN_WIDTH
  };
}

