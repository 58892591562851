import _ from 'underscore';
import React from 'react';
import CreateReactClass from 'create-react-class';
import classNames from 'classnames';

import PropTypes from "prop-types";

export var Tabs = CreateReactClass({
  propTypes: {
    value: PropTypes.any,
    initialValue: PropTypes.any,
    onChange: PropTypes.func,

    // One of the objects down the bottom of this file...
    styles: PropTypes.object.isRequired,

    tabs: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
      props: PropTypes.object,
      disabled: PropTypes.bool
    }))
  },

  getInitialState: function() {
    if (this.props.initialValue != null) {
      return {value: this.props.initialValue};
    }
    else if (this.props.value != null) {
      return {value: this.props.value};
    }
    else {
      return {value: this.props.tabs[0].value};
    }
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.value != null && nextProps.value !== this.state.value) {
      this.setState({value: nextProps.value});
    }
  },

  render: function() {
    const selectedTab = _.find(this.props.tabs, t => t != null && t.value === this.state.value);

    if (selectedTab.content != null) {
      return <div>
        <div>
          {this.renderTabStrip()}
        </div>
        <div>
          {selectedTab.content}
        </div>
      </div>;
    }
    else {
      return this.renderTabStrip();
    }
  },

  renderTabStrip() {
    let self = this;

    const styles = this.props.styles || underlinedTabStyles;

    return <div style={{display: 'inline-block'}}>
      {this.props.tabs.map(function(tab, i) {
        if (tab == null) {
          return null;
        }
        const {className, ...props} = (tab.props || {});
        return <div
            key={i}
            style={{
              ...styles.tab,
              ...(tab.value === self.state.value ? styles.activeTab : null),
              ...(tab.disabled ? styles.disabledTab : null)
            }}
            onClick={() => self.handleTabClick(tab)}
            className={classNames('tab', {highlighted: tab.value === self.state.value}, className)}
            {...props}>
          {tab.label}
        </div>;
      })}
    </div>;
  },

  handleTabClick: function(tab) {
    if (!tab.disabled) {
      if (this.props.onChange != null) {
        this.props.onChange(tab.value);
      }
      if (this.props.value == null) {
        this.setState({value: tab.value});
      }
    }
  }
});


export var BlockTabs = CreateReactClass({
  render: function() {
    return <Tabs
      styles={blockTabStyles}
      {...this.props}
    />;
  }
});



const underlinedTabStyles = {
  tab: {
    display: 'inline-block',
    padding: '0.8em 1.6em',
    borderBottom: 'solid 3px #fff',
    cursor: 'pointer',
    fontSize: '14px'
  },
  activeTab: {
    borderBottom: 'solid 3px gold',
    fontWeight: 600,
  },
  disabledTab: {
    color: '#ccc'
  }
};


// The block tab styles are all handled by the css classes.
const blockTabStyles = {
  tab: {},
  activeTab: {},
  disabledTab: {}
};


